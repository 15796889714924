import React, { useState, useEffect, useContext } from 'react'
import { Avatar, Table, Icon, Tooltip, Divider, Popconfirm } from 'antd'
import { ThemeContext } from 'styled-components'
import Flex from '../override/Flex'
import { Span1 } from '../override/Typography'
import api from '../../lib/api'
import { onError } from '../../lib/sentry'
import { useSelector } from 'react-redux'
import { CONTACT_TYPES } from './../../share/Constants'
import { ChangePermissionModal } from '../modals/ChangePermissionModal'

export function UserWithIcon({ id, index, name }) {
  const theme = useContext(ThemeContext)

  const colors = [
    theme.secondaryYellow,
    theme.secondaryRed,
    theme.secondaryBlue,
    theme.secondaryOrange,
    theme.secondaryLightGreen,
    theme.secondaryCyan,
    theme.secondaryGreenMedium,
    theme.secondaryPurple
  ]
  const [user, setUser] = useState()

  useEffect(() => {
    const getUser = async () => {
      try {
        const rs = await api.getUser(id, true)
        if (rs.data) {
          setUser({
            shortName: rs.data.fullName.charAt(0).toUpperCase(),
            fullName: rs.data.fullName
          })
        }
      } catch (err) {
        onError(err)
      }
    }

    if (id) {
      getUser()
    }
  }, [id])

  return (
    <div className="asset-liability-title">
      <Flex>
        <Avatar
          size={20}
          style={{
            backgroundColor: colors[index % colors.length],
            fontSize: 10,
            marginRight: 6,
            minWidth: 20
          }}
          key={id}
        >
          {name ? name.charAt(0).toUpperCase() : user?.shortName}
        </Avatar>
        <Span1> {name ? name : user?.fullName} </Span1>
      </Flex>
    </div>
  )
}

export function SharingDetailMapping({ records, email, revoke }) {
  const theme = useContext(ThemeContext)
  const { activeFiles, activeFolders } = useSelector(state => state.documents)
  const { activeContacts } = useSelector(state => state.contacts)
  const { activePasswords } = useSelector(state => state.passwords)
  const { activeLocations } = useSelector(state => state.locations)
  const { activeEvents } = useSelector(state => state.events)
  const [data, setData] = useState([])
  const [selectedId, setSelectedId] = useState()
  const [permissionModalVisible, setPermissionModalVisible] = useState(false)
  const [permission, setPermission] = useState()

  const getFileExtension = filename => {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
  }

  useEffect(() => {
    const getRecord = (dataSource, id) => {
      return dataSource.find(d => d._id === id)
    }

    const mappingItems = record => {
      const fileRecord = getRecord(activeFiles, record.id)
      const folderRecord = getRecord(activeFolders, record.id)
      const contactRecord = getRecord(activeContacts, record.id)
      const locationRecord = getRecord(activeLocations, record.id)
      const passwordRecord = getRecord(activePasswords, record.id)
      const eventRecord = getRecord(activeEvents, record.id)
      return fileRecord
        ? {
            id: record.id,
            name: fileRecord.fileName,
            type: 'file-text',
            permission: record.permission,
            linkedItems: record.linkedItems,
            isParent: record.isParent
          }
        : folderRecord
        ? {
            id: record.id,
            name: folderRecord?.path?.split('/')[
              folderRecord?.path?.split('/').length - 2
            ],
            type: 'folder',
            permission: record.permission,
            linkedItems: record.linkedItems,
            isParent: record.isParent
          }
        : contactRecord
        ? {
            id: record.id,
            name:
              contactRecord.type === CONTACT_TYPES.INDIVIDUAL
                ? contactRecord.givenName + ' ' + contactRecord.surName
                : contactRecord.displayName,
            type: 'contact',
            permission: record.permission,
            fullName:
              contactRecord.type === CONTACT_TYPES.INDIVIDUAL
                ? contactRecord.givenName + ' ' + contactRecord.surName
                : contactRecord.displayName,
            linkedItems: record.linkedItems,
            isParent: record.isParent
          }
        : locationRecord
        ? {
            id: record.id,
            name:
              locationRecord.level1 +
              (locationRecord.level2 ? `- ${locationRecord.level2}` : '') +
              (locationRecord.level3 ? `- ${locationRecord.level3}` : ''),
            type: 'environment',
            permission: record.permission,
            linkedItems: record.linkedItems,
            isParent: record.isParent
          }
        : passwordRecord
        ? {
            id: record.id,
            permission: record.permission,
            type: 'lock',
            name: passwordRecord?.title,
            linkedItems: record.linkedItems,
            isParent: record.isParent
          }
        : {
            id: record.id,
            permission: record.permission,
            type: 'calendar',
            name: eventRecord?.description,
            linkedItems: record.linkedItems,
            isParent: record.isParent
          }
    }

    const getRecords = async () => {
      const data = records.map(record => {
        if (!record.linkedItems?.length) {
          return mappingItems(record)
        } else {
          const mappedRecord = mappingItems(record)
          const linkedItemsMappedRecords = record.linkedItems.map(item => {
            return mappingItems(item)
          })
          return {
            ...mappedRecord,
            children: linkedItemsMappedRecords
          }
        }
      })
      setData(data)
    }

    getRecords()
  }, [
    activeContacts,
    activeFiles,
    activeFolders,
    activeEvents,
    activeLocations,
    activePasswords,
    records
  ])

  const changePermission = record => {
    setPermissionModalVisible(true)
    setSelectedId(record.id)
    setPermission(record.permission)
  }

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      width: '70%',
      render: (text, record, index) => (
        <>
          {record.type === 'contact' ? (
            <UserWithIcon name={text} index={index} />
          ) : (
            <div className="asset-liability-title">
              <Flex className="icon-name">
                <Span1>
                  <Icon
                    type={record.type}
                    style={{
                      fontSize: '20px',
                      color: record.type === 'folder' ? '#0061d4' : theme.dark1
                    }}
                    theme={record.type === 'folder' ? 'filled' : ''}
                  />
                </Span1>

                <Span1 className="name" style={{ whiteSpace: 'nowrap' }}>
                  {text}
                </Span1>
              </Flex>
            </div>
          )}
        </>
      ),
      sorter: (a, b) =>
        a.type === b.type
          ? getFileExtension(a.name) === getFileExtension(b.name)
            ? a.name === b.name
              ? ''
              : a.name.localeCompare(b.name)
            : getFileExtension(a.name).localeCompare(getFileExtension(b.name))
          : b.type.localeCompare(a.type),
      defaultSortOrder: 'ascend'
    },
    {
      key: 'permission',
      dataIndex: 'permission',
      title: 'Permission'
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      width: 80,
      render: (text, record) => (
        <>
          <Tooltip
            title="Change permission"
            arrowPointAtCenter
            placement="topRight"
          >
            <Icon
              theme="twoTone"
              type="edit"
              onClick={() => changePermission(record)}
            />
          </Tooltip>
          {record.isParent && (
            <>
              <Divider type="vertical" />

              <Popconfirm
                title="Are you sure to revoke?"
                onConfirm={() => revoke(email, record.id)}
                okText="Yes"
                cancelText="No"
                arrowPointAtCenter
                placement="bottomRight"
              >
                <Tooltip
                  title="Revoke sharing"
                  arrowPointAtCenter
                  placement="topRight"
                >
                  <Icon style={{ color: 'red' }} type="delete" />
                </Tooltip>
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ]

  return (
    <>
      <Table
        className="expand-table"
        dataSource={data}
        columns={columns}
        rowKey="id"
      />
      <ChangePermissionModal
        visible={permissionModalVisible}
        setVisible={setPermissionModalVisible}
        selectedId={selectedId}
        permission={permission}
        setPermission={setPermission}
        email={email}
        setSelectedId={setSelectedId}
      />
    </>
  )
}
