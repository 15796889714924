import Asset, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class Vehicle extends Asset {
  /**
   * Construct a new Vehicle
   * @param {object} params - Vehicle creation options:
   * @param {string} [params.vehicleRegistrationNumber]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    valuationDate,
    currency,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    vehicleRegistrationNumber,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    status,
    events,
    passwords
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      currency,
      valuationDate,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords
    })

    this.subType = ASSET_TYPES.VEHICLE
    this.vehicleRegistrationNumber = vehicleRegistrationNumber
  }
}

export default Vehicle

export const vehicleColumns = () => [
  ...assetColumns(),
  {
    key: 'purchaseDate',
    title: i18next.t('ASSET_PURCHASE_DATE'),
    type: IMPORT_COL_TYPES.DATE,
    required: true
  },
  {
    key: 'disposalDate',
    title: i18next.t('DISPOSAL_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'vehicleRegistrationNumber',
    title: i18next.t('VEHICLE_REGISTRATION_NUMBER')
  },
  ...referenceColumns()
]
