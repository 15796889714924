import { createSlice } from '@reduxjs/toolkit'
import api from '../../lib/api'

const initialState = {
  customer: {},
  subscription: {},
  allowedStorage: 2 * 1024 * 1024 * 1024,
  allowSharePersons: 3,
  isLoading: false,
  error: null,
  schedule: null
}

const customer = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomerStart(state) {
      state.isLoading = true
      state.error = null
    },
    getCustomerSuccess(state, action) {
      const { customer, schedule } = action.payload
      state.customer = customer || {}
      state.subscription = customer.subscriptions?.data[0] || {}
      state.schedule = schedule
      const storageFromPlan =
        state.subscription?.items?.data[0].plan.metadata?.storage
      if (storageFromPlan) {
        state.allowedStorage = storageFromPlan * 1024 * 1024 * 1024
      }

      const personsFromPlan =
        state.subscription?.items?.data[0].plan.metadata?.persons
      if (personsFromPlan) {
        state.allowSharePersons = personsFromPlan
      }

      state.isLoading = false
      state.error = null
    },
    getCustomerFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const { getCustomerStart, getCustomerSuccess, getCustomerFailure } =
  customer.actions

export default customer.reducer

export const fetchCustomer = userId => async dispatch => {
  try {
    dispatch(getCustomerStart())
    const res = await api.getCustomer(userId)
    dispatch(getCustomerSuccess(res.data.message ? {} : res.data))
  } catch (err) {
    dispatch(getCustomerFailure(err.toString()))
  }
}

export const updateCancelSchedule = async (
  subscriptionId,
  userId,
  dispatch,
  cancelAtPeriodEnd = false
) => {
  try {
    const res = await api.updateSubscription(
      subscriptionId,
      JSON.stringify({
        cancel_at_period_end: cancelAtPeriodEnd
      })
    )
    if (res.data.message) throw res.data

    dispatch(fetchCustomer(userId))
  } catch (err) {
    throw err
  }
}
