import React, { useState } from 'react'
import { Row, Col, Divider, Icon, Tooltip } from 'antd'
import { H4, Span, B } from '../override/Typography'
import { useTranslation } from 'react-i18next'

function Field({ title, value }) {
  return (
    <Row style={{ fontSize: 12, padding: '3px 0' }} gutter={8}>
      <Col span={8}>
        <Span>{title}</Span>
      </Col>
      <Col span={16} style={{ textAlign: 'left' }}>
        <B>{value}</B>
      </Col>
    </Row>
  )
}

export default function PasswordInformation({ record, extra }) {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)

  const passwordLength = record.password.length
  let hidenPassword = ''
  for (let i = 0; i < passwordLength; i++) {
    hidenPassword += '*'
  }

  const url = record.url
  let showURL
  if (url?.length > 100) {
    showURL = url.slice(0, 97).concat('...')
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10
        }}
      >
        <H4>{t('PASSWORD_INFORMATION')}</H4>
        {extra}
      </div>
      <Divider />
      <Field title={t('TITLE')} value={record.title} />
      <Field title={t('USERNAME')} value={record.username} />
      <Field
        title={t('PASSWORD')}
        value={
          showPassword ? (
            <>
              <span style={{ marginRight: '5px' }}>{record.password}</span>
              <Icon
                onClick={() => setShowPassword(false)}
                type="eye-invisible"
              />
            </>
          ) : (
            <>
              <span style={{ marginRight: '5px' }}>{hidenPassword}</span>
              <Icon onClick={() => setShowPassword(true)} type="eye" />
            </>
          )
        }
      />
      <Field
        title={t('URL')}
        value={
          url?.length > 100 ? (
            <Tooltip placement="bottom" title={url}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {showURL}
              </a>
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title={url}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            </Tooltip>
          )
        }
      />
    </>
  )
}
