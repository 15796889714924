import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Button,
  Icon,
  message,
  Empty,
  Dropdown,
  Menu,
  Popconfirm,
  Tooltip,
  Table,
  Spin
} from 'antd'
import { useTranslation } from 'react-i18next'
import { onError } from '../../lib/sentry'
import AuthContext from '../../contexts/AuthContext'
import api from '../../lib/api'
import { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'
import { fetchUser } from '../../features/user/userSlice'
import CustomIcon from '../override/Icon'
import TableHeader from '../common/TableHeader'

const AssistedUsers = props => {
  const { userInfo, resetRequests } = props
  const theme = useContext(ThemeContext)
  const { user } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [assistedUsers, setAssistedUsers] = useState([])
  const [pendingRequests, setPendingRequests] = useState([])

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await Promise.all([
        api.getAssistedUsers(user.username),
        api.getAssistantRequests(user.username)
      ])
      if (response[0].data) {
        setAssistedUsers(response[0].data)
      }
      if (response[1].data) {
        setPendingRequests(response[1].data)
      }
    } catch (err) {
      onError(err)
    } finally {
      setIsLoading(false)
    }
  }, [user])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleAssistantRequest = (isAccepted, assistedUserId) => {
    setIsLoading(true)

    api
      .handleAssistantRequest(
        user.username,
        JSON.stringify({
          isAccepted,
          assistedUserId,
          ...userInfo
        })
      )
      .then(response => {
        if (response.data?.success) {
          fetchData()
          dispatch(fetchUser(user.username))
        }
      })
      .catch(err => {
        message.error(t('Failed to handle assistant request'))
        onError(err)
      })
      .finally(() => setIsLoading(false))
  }

  const handleDisconectUser = assistedUserId => {
    setIsLoading(true)
    api
      .disconnectAssistedUser(
        user.username,
        JSON.stringify({
          assistedUserId
        })
      )
      .then(response => {
        if (response.data?.success) {
          fetchData()
          dispatch(fetchUser(user.username))
        }
      })
      .catch(err => {
        message.error(t('Failed to disconnect user'))
        onError(err)
      })
      .finally(() => setIsLoading(false))
  }

  const pendingRequestsColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('EMAIL')
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <Button
                  type="link"
                  icon="check-circle"
                  onClick={() => handleAssistantRequest(true, record.key)}
                >
                  {t('ACCEPT')}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  type="link"
                  icon="minus-circle"
                  style={{ color: theme.red }}
                  onClick={() => handleAssistantRequest(false, record.key)}
                >
                  {t('DECLINE')}
                </Button>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <CustomIcon type="more" />
        </Dropdown>
      )
    }
  ]

  const assistedUsersColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('EMAIL')
    },
    {
      key: 'status',
      title: t('STATUS'),
      render: (text, record) =>
        record.shareKey
          ? t('RECEIVED_BACKUP_KEY')
          : t('NOT_YET_RECEIVED_BACKUP_KEY')
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) =>
        !resetRequests?.map(rr => rr.email).includes(record.email) && (
          <Popconfirm
            title={t('ARE_YOU_SURE_TO_DISCONNECT_THIS_USER')}
            onConfirm={() => handleDisconectUser(record.key)}
            okText={t('YES')}
            cancelText={t('NO')}
            arrowPointAtCenter
            placement="bottomRight"
          >
            <Tooltip title={t('DISCONNECT')}>
              <Icon type="delete" />
            </Tooltip>
          </Popconfirm>
        )
    }
  ]

  return (
    <Spin spinning={isLoading}>
      <TableHeader
        title={t('Pending my acceptance to be assistant')}
        count={pendingRequests.length}
      />
      <Table
        rowKey="key"
        dataSource={pendingRequests}
        scroll={{ x: true }}
        columns={pendingRequestsColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_PENDING_REQUESTS')}
            />
          )
        }}
        showHeader={!!pendingRequests.length}
      />

      <TableHeader
        title={t('I am currently a assistant for these assisted users')}
        count={assistedUsers.length}
      />
      <Table
        rowKey="key"
        dataSource={assistedUsers}
        scroll={{ x: true }}
        columns={assistedUsersColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('No assisted users')}
            />
          )
        }}
        showHeader={!!assistedUsers.length}
      />
    </Spin>
  )
}

export default AssistedUsers
