import React from 'react'

export default function Flex({
  className,
  style,
  children,
  alignItems,
  justifyContent,
  flexDirection
}) {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        alignItems: alignItems || 'center', // default to center since it's more common in our app
        justifyContent: justifyContent || 'flex-start',
        flexDirection: flexDirection || 'row',
        ...style
      }}
    >
      {children}
    </div>
  )
}
