import React, { useContext } from 'react'
import { DATE_FORMAT } from '../../share/Constants'
import { useTranslation } from 'react-i18next'
import Button from '../../components/override/Button'
import moment from 'moment'
import { useSelector } from 'react-redux'
import VaultContext from '../../contexts/VaultContext'
import { ThemeContext } from 'styled-components'
import { Modal, Tag, Spin } from 'antd'

const EventDetails = props => {
  const {
    isViewMode,
    visible,
    record,
    setVisible,
    isLoading,
    handleEditItem,
    handleDeleteItem
  } = props
  const { isReadonly } = useContext(VaultContext)
  const theme = useContext(ThemeContext)

  const { activeContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )

  const { activeDocuments } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )

  // const { activeAssetsLiabilities } = useSelector(state =>
  //   isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  // )
  const { activePasswords } = useSelector(state => state.passwords)
  const { t } = useTranslation()
  return (
    <Modal
      title={t('EVENT_DETAILS')}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        !isViewMode && (
          <Button
            key="edit"
            type="primary"
            onClick={() => {
              handleEditItem(record)
              setVisible(false)
            }}
          >
            {t('EDIT')}
          </Button>
        ),
        !isViewMode && (
          <Button
            key="delete"
            onClick={() => handleDeleteItem(record)}
            style={{ color: theme.red }}
          >
            {t('DELETE')}
          </Button>
        ),
        <Button key="cancel" onClick={() => setVisible(false)}>
          {t('CANCEL')}
        </Button>
      ]}
    >
      <Spin spinning={isLoading}>
        <p>
          {t('DESCRIPTION')}
          {': '}
          {record.description}
        </p>
        <p>
          {t('DATE')}
          {': '}
          {moment(record.date).format(DATE_FORMAT)}{' '}
        </p>
        <p>
          {t('TAGS')}:{' '}
          {record.tags?.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </p>
        <p>
          {t('CONTACTS')}:{' '}
          {record.contacts &&
            record.contacts.map(cId =>
              activeContacts.map(contact =>
                cId === contact._id ? (
                  <Tag key={contact._id}>{contact.name}</Tag>
                ) : null
              )
            )}
        </p>
        {/* <p>
          {t('ASSETS_LIABILITIES')}:{' '}
          {record.assetsLiabilities &&
            record.assetsLiabilities.map(alId =>
              activeAssetsLiabilities.map(al =>
                alId === al._id ? <Tag key={al._id}>{al.title}</Tag> : null
              )
            )}
        </p> */}
        <p>
          {t('DOCUMENTS')}:{' '}
          {record.documents &&
            record.documents.map(dId =>
              activeDocuments.map(d =>
                dId === d._id ? <Tag key={d._id}>{d.fileName}</Tag> : null
              )
            )}
        </p>
        <p>
          {t('PASSWORDS')}:{' '}
          {record.passwords &&
            record.passwords.map(passwordId =>
              activePasswords.map(password =>
                passwordId === password._id ? (
                  <Tag key={password._id}>{password.title}</Tag>
                ) : null
              )
            )}
        </p>
      </Spin>
    </Modal>
  )
}

export default EventDetails
