import React, { useState, useContext, useEffect } from 'react'
import { Modal } from 'antd'
//import { StringResources } from '../../share/StringResources'
import Files from '../file/Files'
import TourContext, { TOUR_STEP_INDEXES } from '../../contexts/TourContext'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'

const DocumentsLinksModal = props => {
  const { visible, setVisible, record, linkDocument, filteredDocIds } = props
  const [docToLink, setDocToLink] = useState()
  const [loading, setLoading] = useState(false)
  const { nextTourStep, tourStepIndex, tourRun } = useContext(TourContext)
  const { t } = useTranslation()

  useEffect(() => {
    if (
      visible &&
      tourRun &&
      tourStepIndex === TOUR_STEP_INDEXES.LINK_EXISTED_DOCS
    ) {
      setTimeout(() => {
        nextTourStep()
      }, 300)
    }
  }, [tourStepIndex, nextTourStep, visible, tourRun])

  const reset = () => {
    setDocToLink(null)
    setVisible(false)
  }

  const handleOk = async () => {
    try {
      setLoading(true)
      await linkDocument(docToLink)
      reset()

      if (tourStepIndex === TOUR_STEP_INDEXES.SAVE_SELECTED_DOCS) {
        nextTourStep()
      }
    } catch (e) {
      onError(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title={t('LINK_DOCUMENTS')}
      visible={visible}
      width={'90vw'}
      onCancel={reset}
      okText={t('SELECT')}
      cancelText={t('CANCEL')}
      onOk={handleOk}
      okButtonProps={{
        disabled: !docToLink,
        loading,
        className: 'tour-save-selected-docs'
      }}
      maskClosable={false}
      style={{ top: '30px', maxWidth: 1200 }}
    >
      <Files
        filteredDocIds={filteredDocIds}
        setDocToLink={setDocToLink}
        linkedAssetLiabilityId={record._id}
        isModal
      />
    </Modal>
  )
}

export default DocumentsLinksModal
