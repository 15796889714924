import React, { useState, useContext, useRef, useEffect } from 'react'
import api from '../../lib/api'
import { Modal, Form, Icon, message, Slider, Alert } from 'antd'
import VaultContext from './../../contexts/VaultContext'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import uuidv4 from 'uuid/v4'

const AddLogoModal = ({
  visible,
  setVisible,
  file,
  getLogo,
  isEditMode,
  setIsEditMode
}) => {
  const { userId } = useContext(VaultContext)
  const [isSaving, setIsSaving] = useState(false)
  const [image, setImage] = useState('')
  const [scale, setScale] = useState(1)
  const [error, setError] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    if (isEditMode && visible) {
      setImage(file)
    }
  }, [file, visible, isEditMode])

  const handleMinScale = value => {
    const ratio =
      value.width < value.height
        ? value.width / value.height
        : value.height / value.width
    setScale(ratio)
  }

  const handleDrop = dropped => {
    setImage(dropped)
  }

  const handleSave = () => {
    setIsSaving(true)
    if (!ref) return

    ref.current.getImage().toBlob(async blob => {
      const file = new File([blob], image.name, { type: image.type })
      const fileId = uuidv4() // used as S3 file path, is encrypted
      const sub = uuidv4() // used for matching with S3 file metadata, is encrypted

      const urlRes = await api.getSignedUrl(
        userId,
        `avatar/${fileId}`,
        'putObject',
        { sub }
      )
      if (urlRes.data.message) throw Error(urlRes.data.message)
      
      await api.uploadToS3Url(urlRes.data, file, {
        headers: {
          'Content-Type': file.type
        }
      })

      await api.updateAvatar(
        userId,
        JSON.stringify({
          name: file.name,
          type: file.type,
          fileId,
          sub
        })
      )
      await getLogo()
      message.success(`Successfully uploaded logo`)
      setIsSaving(false)
      closeModal()
    }, 'image/png')
  }

  const closeModal = async () => {
    setVisible(false)

    setIsEditMode(false)
    setImage('')
  }

  return (
    <Modal
      width={400}
      title={`${isEditMode ? 'Edit' : 'Upload'} logo`}
      visible={visible}
      maskClosable={false}
      okText="Save"
      onOk={handleSave}
      onCancel={closeModal}
      okButtonProps={{
        loading: isSaving,
        disabled: error || !image
      }}
    >
      <Form>
        <Form.Item label="Logo">
          {error && (
            <Alert
              type="error"
              message="Image failed to upload. Only supports PNG, JPEG at a maximum size of 2MB. Please
              try again."
            />
          )}

          <Dropzone
            onDrop={dropped => handleDrop(dropped[0])}
            accept="image/png, image/jpeg"
            maxSize={2000000}
            onDropRejected={() => setError(true)}
            onDropAccepted={() => setError(false)}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                {image ? (
                  <AvatarEditor
                    width={300}
                    height={300}
                    image={image}
                    onLoadSuccess={handleMinScale}
                    borderRadius={150}
                    scale={scale}
                    ref={ref}
                  />
                ) : (
                  <div
                    style={{
                      width: 355,
                      height: 355,
                      border: ' 1px dashed #94A3B8',
                      borderRadius: 5,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontWeight: 'normal',
                      fontSize: 14,
                      lineHeight: '22px',
                      cursor: 'pointer'
                    }}
                  >
                    <Icon
                      type="upload"
                      style={{
                        marginBottom: 12,
                        fontSize: 40,
                        color: '#0061D4'
                      }}
                    />
                    <div>Drag & drop image here or</div>
                    <div>Browse from your computer</div>
                    <input {...getInputProps()} />
                  </div>
                )}
              </div>
            )}
          </Dropzone>
          {/* )} */}
        </Form.Item>
        {image && (
          <Form.Item label="Zoom">
            <Slider
              step={0.01}
              value={scale}
              min={0.1}
              max={2}
              onChange={e => setScale(e)}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

const WrappedAddLogoModalForm = Form.create({
  name: 'AddLogoModal'
})(AddLogoModal)
export default WrappedAddLogoModalForm
