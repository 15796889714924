import api from '../lib/api'
import { getUserAttributeValue } from '../lib/cognito'
import { onError } from '../lib/sentry'

export async function logAccessAttempt(
  userId,
  userAttributes,
  accessedByUserId
) {
  try {
    // const ipStackRes = await api.getIpStackInfo()

    const accessAttemptData = {
      userId,
      email: getUserAttributeValue(userAttributes, 'email'),
      name: getUserAttributeValue(userAttributes, 'custom:full_name'),
      accessedByUserId,
      // TODO: re-enable this once we have the alternative replacement for IpStack
      // ipStack: ipStackRes.data
      ipStack: {}
    }

    await api.logAccessAttempt(accessAttemptData)
  } catch (err) {
    onError(err)
  }
}

export async function logDocumentActivity(
  userId,
  userAttributes,
  accessedByUserId,
  documentId,
  detail = {}
) {
  try {
    // const ipStackRes = await api.getIpStackInfo()
    const accessAttemptData = {
      userId: userId,
      email: getUserAttributeValue(userAttributes, 'email'),
      name: getUserAttributeValue(userAttributes, 'custom:full_name'),
      accessedByUserId: accessedByUserId,
      documentId: documentId,
      detail: detail,
      ipStack: {}
    }
    
    await api.logAccessAttempt(accessAttemptData)
  } catch (err) {
    onError(err)
  }
}
