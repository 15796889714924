import React, { useState, useContext } from 'react'
import CardModal from './CardModal'
import DeleteCard from './DeleteCard'
import SetDefaultCard from './SetDefaultCard'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { Table, Empty, Spin, Descriptions } from 'antd'
import config from '../../config'
import AuthContext from '../../contexts/AuthContext'
import Button from '../override/Button'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'
import SubscriptionModal from './SubscriptionModal'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomer } from '../../features/payment/customerSlice'
import Invoices from '../payment/Invoices'
import moment from 'moment'
// import PromoCodeModal from './PromoCodeModal'
import { useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function Payment() {
  const { user } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const [cardModalVisible, setCardModalVisible] = useState(false)
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(
    false
  )
  // const [promoCodeModalVisible, setPromoCodeModalVisible] = useState(false)

  const { customer, isLoading, subscription } = useSelector(
    state => state.customer
  )
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const discount = subscription?.discount
  const promotionInfo = discount?.coupon

  const dispatch = useDispatch()

  const cardColumns = [
    {
      key: 'last4',
      dataIndex: 'last4',
      title: t('CARD_NO'),
      render: text => `...${text}`
    },
    {
      key: 'expiredOn',
      title: t('EXPIRES_ON'),
      render: (text, record) => `${record.exp_month}/${record.exp_year}`
    },
    {
      key: 'actions',
      title: t('ACTIONS'),
      render: (text, record) =>
        record.id === customer.default_source ? (
          <span>Current default</span>
        ) : (
          <div className="asset-liability-actions">
            <SetDefaultCard
              customerId={customer.id}
              cardId={record.id}
              fetchCustomer={() => dispatch(fetchCustomer(user.username))}
            />
            <DeleteCard
              customerId={customer.id}
              cardId={record.id}
              fetchCustomer={() => dispatch(fetchCustomer(user.username))}
            />
          </div>
        )
    }
  ]

  return (
    <Spin spinning={isLoading}>
      <SimpleHeader
        className="payment-header"
        title={
          <>
            <H4 display="inline-block">
              {t('CURRENT_SUBSCRIPTION')}:{' '}
              <span style={{ color: theme.primary }}>
                {subscription.items?.data[0].plan.nickname || 'Free'}
              </span>
            </H4>
            {subscription?.current_period_start &&
              subscription?.current_period_end && (
                <span style={{ marginLeft: 5 }}>
                  (
                  {moment(subscription.current_period_start * 1000).format(
                    'LL'
                  )}{' '}
                  -{' '}
                  {moment(subscription.current_period_end * 1000).format('LL')})
                </span>
              )}
          </>
        }
        extra={
          <>
            {/* <Tooltip
              title={
                promotionInfo ? (
                  <div>{t('CURRENT_SUBSCRIPTION_APPLIED_PROMOTION_CODE')}</div>
                ) : null
              }
            >
              <Button
                onClick={() => setPromoCodeModalVisible(true)}
                style={{ marginRight: 8 }}
                disabled={subscription.discount}
              >
                {t('PROMOTION_CODE')}
              </Button>
            </Tooltip> */}
            <Button onClick={() => setSubscriptionModalVisible(true)}>
              {t('CHANGE_SUBSCRIPTION')}
            </Button>
          </>
        }
        size="small"
      />
      {promotionInfo && (
        <Descriptions
          title={t('APPLIED_PROMOTION')}
          column={1}
          size="small"
          style={{ marginBottom: 16 }}
        >
          <Descriptions.Item label={t('PROMOTION_CODE')}>
            {promotionInfo.id}
          </Descriptions.Item>
          <Descriptions.Item label={t('PROMOTION_NAME')}>
            {promotionInfo.name}
          </Descriptions.Item>
          {promotionInfo.percent_off && (
            <Descriptions.Item label={t('PERCENTAGE_DISCOUNT')}>
              {promotionInfo.percent_off}%
            </Descriptions.Item>
          )}
          {promotionInfo.amount_off && (
            <Descriptions.Item label={t('FIXED_AMOUNT_DISCOUNT')}>
              ${promotionInfo.amount_off / 100}
            </Descriptions.Item>
          )}
          <Descriptions.Item label={t('DURATION')}>
            {promotionInfo.duration === 'repeating'
              ? `${promotionInfo.duration_in_months} ${t('MONTHS')}`
              : promotionInfo.duration === 'once'
              ? t('ONCE')
              : promotionInfo.duration === 'forever'
              ? t('FOREVER')
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label={t('APPLIED_DATE')}>
            {moment(discount.start * 1000).format('LL')}
          </Descriptions.Item>
        </Descriptions>
      )}
      {subscription?.id && (
        <>
          <SimpleHeader
            title={<H4>{t('PAYMENT_METHOD')}</H4>}
            extra={
              <Button onClick={() => setCardModalVisible(true)}>
                {t('ADD_A_CARD')}
              </Button>
            }
            size="small"
          />
          <Table
            dataSource={customer.sources?.data || []}
            columns={cardColumns}
            rowKey="id"
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('NO_CARDS')}
                />
              )
            }}
            size={isMdUp ? '' : 'middle'}
          />
          <div style={{ marginTop: 25 }}>
            <Invoices />
          </div>
        </>
      )}
      <SubscriptionModal
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
      />
      {/* <PromoCodeModal
        visible={promoCodeModalVisible}
        setVisible={setPromoCodeModalVisible}
        fetchCustomer={() => dispatch(fetchCustomer(user.username))}
      /> */}
      <StripeProvider apiKey={config.stripe.PUBLISHABLE_KEY}>
        <Elements>
          <CardModal
            visible={cardModalVisible}
            setVisible={setCardModalVisible}
            customerId={customer.id}
            handleOkComplete={() => dispatch(fetchCustomer(user.username))}
          />
        </Elements>
      </StripeProvider>
    </Spin>
  )
}
