import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  Steps,
  Descriptions,
  Alert,
  Result,
  Icon,
  Tooltip
} from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { getUserPool } from '../../lib/cognito'
//import { StringResources } from '../../share/StringResources'
import { randomBytes } from 'crypto'
import { AES } from 'crypto-js'
import NodeRSA from 'node-rsa'
import PersonalInfo from '../common/PersonalInfo'
import Button from '../override/Button'
import FormItem from '../override/FormItem'
import { P1, H3 } from '../override/Typography'
import shortid from 'shortid'
import { H4 } from '../override/Typography'
import SubscriptionPlans from '../payment/SubscriptionPlans'
import usePlans from '../../hooks/usePlans'
import api from '../../lib/api'
import { GApageView, GAmodalView, GAevent } from '../../lib/ga'
import { onError } from '../../lib/sentry'
import { useTranslation, Trans } from 'react-i18next'
import { sanitizeValue } from './../../share/helpers'
import i18next from 'i18next'

const { Step } = Steps
const modalName = currentStep => {
  switch (currentStep) {
    case 0:
      return 'Register details'
    case 1:
      return 'Register subscription plans'
    case 2:
      return 'Register review'
    default:
      return null
  }
}

function SignupForm(props) {
  const [errMsg, setErrMsg] = useState('')
  const [hasAgreed, setHasAgreed] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [personalInfo, setPersonalInfo] = useState({})
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState({
    id: '',
    nickname: '',
    amount: 0,
    interval: '',
    currency: ''
  })
  const [appliedReferralCode, setAppliedReferralCode] = useState('')
  const [preselectedPlan, setPreselectedPlan] = useState('')
  const [promoCode, setPromoCode] = useState('')
  const [promotionInfo, setPromotionInfo] = useState(null)
  const [isCheckingCodeValidity, setIsCheckingCodeValidity] = useState(false)
  const [marketingConsent, setMarketingConsent] = useState([])
  const [countryCode, setCountryCode] = useState('')
  const [giftInfo, setGiftInfo] = useState(null)
  const [referredProfessionalDeputyId, setReferredProfessionalDeputyId] =
    useState('')
  // const [rawPrivateKey, setRawPrivateKey] = useState()
  // const [rawPublicKey, setRawPublicKey] = useState()
  const { t } = useTranslation()

  const urlSearchParams = new URLSearchParams(useLocation().search)
  const primaryUserName = urlSearchParams.get('username')
  const assistedUser = urlSearchParams.get('assistedUser')
  const email = urlSearchParams.get('email')
  const professionalDeputyId = urlSearchParams.get('professionalDeputyId')
  const planParam = urlSearchParams.get('plan')
  const referralCodeParam = urlSearchParams.get('referralCode')
  const referredProfessionalDeputyIdParam = urlSearchParams.get(
    'referredProfessionalDeputyId'
  )
  const discountCode = urlSearchParams.get('discountCode')
  const partnerCode = urlSearchParams.get('partnerCode')
  const promoCodeParam = urlSearchParams.get('promotionCode')
  const sender = urlSearchParams.get('sender')
  const giftId = urlSearchParams.get('giftId')

  const plans = usePlans(discountCode)

  let personalInfoForm
  const personalInfoFormRef = fr => {
    personalInfoForm = fr
  }

  useEffect(() => {
    // const getAllKeys = async () => {
    //   const res = await api.getAllKeys(primaryUserName, email)

    //   setRawPrivateKey(res.data?.privateKey)
    //   setRawPublicKey(res.data?.publicKey)
    // }

    if ((primaryUserName || professionalDeputyId) && email) {
      GApageView('deputy-register')
      // getAllKeys()
    } else {
      GApageView('register')
    }
  }, [primaryUserName, professionalDeputyId, email])

  useEffect(() => {
    GAmodalView(modalName(currentStep))
  }, [currentStep])

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const res = await api.getIpStackInfo()

        setCountryCode(res.data.country_code)
      } catch (error) {
        onError(error)
        console.log('Fail to get country code')
      }
    }

    fetchCountryCode()
  }, [])

  useEffect(() => {
    if (planParam && planParam !== 'free') {
      const vaultboxPlans = plans.customPlans?.length
        ? plans.customPlans
        : plans.defaultPlans
      const preselectedPlan = vaultboxPlans.find(
        p => p.nickname?.toLowerCase() === planParam.toLowerCase()
      )
      if (preselectedPlan) {
        setSelectedPlan(preselectedPlan)
        setPreselectedPlan(planParam)
      }
    }
  }, [planParam, plans])

  useEffect(() => {
    // this can't be merged with the similar useEffect above
    // as it will introduce an endless loop of re-rendering
    if (planParam === 'free') {
      setSelectedPlan({
        id: 'free',
        nickname: 'Deputy only'
      })
      setPreselectedPlan(planParam)
    }
  }, [planParam])

  useEffect(() => {
    if (referralCodeParam) {
      setAppliedReferralCode(referralCodeParam)
    }
  }, [referralCodeParam])

  useEffect(() => {
    if (promoCodeParam) {
      setPromoCode(promoCodeParam)
    }
  }, [promoCodeParam])

  useEffect(() => {
    const fetchGiftInfo = async () => {
      try {
        const res = await api.getGift(sender, giftId)
        const gift = res?.data
        if (gift?.receiver) {
          setPersonalInfo({ email: gift.receiver })
          setGiftInfo(gift)
        }
      } catch (err) {
        onError(err)
      }
    }
    if (sender && giftId) {
      fetchGiftInfo()
    }
  }, [sender, giftId])

  useEffect(() => {
    if (referredProfessionalDeputyIdParam)
      setReferredProfessionalDeputyId(referredProfessionalDeputyIdParam)
  }, [referredProfessionalDeputyIdParam])

  const handlePersonalInfoNext = () => {
    personalInfoForm.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return
      }

      if (sender && giftId) {
        if (!giftInfo) {
          setErrMsg(t('GIFT_INFO_NOT_FOUND'))
          return
        }
        if (giftInfo.receiver !== values.email) {
          setErrMsg(t('GIFT_RECEIVER_EMAIL_MISSMATCH'))
          return
        }
        if (giftInfo.plan !== selectedPlan.nickname) {
          setErrMsg(t('GIFT_PLAN_MISSMATCH'))
          return
        }
      }

      setErrMsg('')
      GAevent({
        category: 'Register',
        action: 'Register details completed'
      })
      setPersonalInfo({ ...personalInfo, ...values })
      setCurrentStep(currentStep + 1)
    })
  }

  const handlePreviewPrevious = () => {
    setErrMsg('')
    setCurrentStep(currentStep - 1)
    GAevent({
      category: 'Register',
      action: 'Clicked Previous',
      label: 'Review step'
    })
  }

  const handleSubscriptionNext = async () => {
    if (!selectedPlan.id) {
      setErrMsg(t('SUBSCRIPTION_PLAN_EMPTY_MSG'))
    } else {
      if (appliedReferralCode || promoCode) {
        try {
          setIsCheckingCodeValidity(true)
          if (appliedReferralCode) {
            const res = await api.getReferralCodeInfo(appliedReferralCode)

            if (res.data?.professionalDeputyId)
              setReferredProfessionalDeputyId(res.data.professionalDeputyId)
          }

          if (promoCode) {
            const res = await api.getPromotionCode(
              promoCode,
              personalInfo.email
            )
            if (!res?.data?.valid) {
              throw Error(t('INVALID_PROMOTION_CODE'))
            }
            if (
              res?.data?.appliesToPlan &&
              res.data.appliesToPlan !== selectedPlan.id
            ) {
              throw Error(t('PROMOTION_CODE_MSG'))
            }

            setPromotionInfo(res.data)
          }

          setErrMsg('')
          setIsCheckingCodeValidity(false)
          GAevent({
            category: 'Register',
            action: 'Register plan completed'
          })
          setCurrentStep(currentStep + 1)
        } catch (error) {
          setIsCheckingCodeValidity(false)
          setErrMsg(t('FAILED_TO_CHECK_CODE_VALIDATE'))
          onError(error)
        }
      } else {
        setPromotionInfo(null)
        setErrMsg('')
        GAevent({
          category: 'Register',
          action: 'Register plan completed'
        })
        setCurrentStep(currentStep + 1)
      }
    }
  }

  const handleSubscriptionPrevious = () => {
    setCurrentStep(currentStep - 1)
    GAevent({
      category: 'Register',
      action: 'Clicked Previous',
      label: 'Subscription plans step'
    })
  }

  const handleSubmit = async (values = null) => {
    setErrMsg('')
    setIsSubmitting(true)
    const info = values || personalInfo

    const userPool = getUserPool()
    // generating user's master encryption key
    const masterKey = randomBytes(20).toString('hex')
    const encryptedMasterKey = AES.encrypt(masterKey, info.password).toString()

    // generating public & private keys
    const key = new NodeRSA()
    key.generateKeyPair(1024) // while it's recommended to have key size as 2048, need to use this size to fit the max length of Cognito's user attribute

    const publicKey = key.exportKey('public')
    const privateKey = key.exportKey('pkcs8')
    const encryptedPrivateKey = AES.encrypt(privateKey, masterKey).toString()

    const formData = [
      {
        Name: 'email',
        Value: info.email
      },
      {
        Name: 'phone_number',
        Value: info.prefix + info.phone
      },
      {
        Name: 'custom:preferred_name',
        Value: sanitizeValue(info.fullname)
      },
      {
        Name: 'custom:full_name',
        Value: sanitizeValue(info.fullname)
      },
      {
        Name: 'custom:master_key',
        Value: encryptedMasterKey
      },
      {
        Name: 'custom:public_key',
        Value: publicKey
      },
      {
        Name: 'custom:raw_master_key',
        Value: primaryUserName ? masterKey : undefined
      },
      {
        Name: 'custom:private_key',
        Value: encryptedPrivateKey
      },
      {
        Name: 'custom:pending_primary',
        Value: primaryUserName
      },
      {
        Name: 'custom:government_id',
        Value: info.govId
      },
      {
        Name: 'custom:prefix',
        Value: info.prefix
      },
      {
        Name: 'custom:base_currency',
        Value: info.baseCurrency
      },
      {
        Name: 'custom:referral_code',
        Value: shortid.generate()
      },
      {
        Name: 'custom:applied_referral',
        Value: appliedReferralCode
      },
      {
        Name: 'custom:plan_id',
        Value: selectedPlan.id
      },
      {
        Name: 'custom:professional_deputy',
        Value: professionalDeputyId
      },
      {
        Name: 'custom:created_at',
        Value: new Date().toJSON()
      },
      {
        Name: 'custom:referred_pd',
        Value: referredProfessionalDeputyId
      },
      {
        Name: 'custom:discount_code',
        Value: discountCode
      },
      {
        Name: 'custom:applied_promo',
        Value: promotionInfo?.id
      },
      {
        Name: 'custom:marketing_consent',
        Value: JSON.stringify(
          marketingConsent.reduce((obj, key) => ({ ...obj, [key]: true }), {})
        )
      },
      {
        Name: 'custom:country',
        Value: JSON.stringify(info.country)
      },
      {
        Name: 'custom:gift_info',
        Value: JSON.stringify(
          giftId && sender
            ? {
                id: giftId,
                sender
              }
            : {}
        )
      },
      {
        Name: 'custom:default_language',
        Value: i18next.language
      },
      {
        Name: 'custom:partner_code',
        Value: partnerCode
      },
      {
        Name: 'custom:assisted_user',
        Value: assistedUser
      },
    ]

    const attributeList = formData.map(item => {
      return new CognitoUserAttribute(item)
    })

    userPool.signUp(
      info.email,
      info.password,
      attributeList,
      null,
      function (err, result) {
        setIsSubmitting(false)
        if (err) {
          console.log(err);
          setErrMsg(t('FAILED_TO_REGISTER_USER'))
          return
        }

        setHasSubmitted(true)
        GAevent({
          category: 'Register',
          action: 'Register successfully submitted'
        })

        GAmodalView('Account created')
      }
    )
  }

  const handleQuickSubmit = () => {
    GAevent({
      category: 'Register',
      action: 'Clicked Submit (for deputy)'
    })
    personalInfoForm.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return
      }

      handleSubmit(values)
    })
  }

  const handleAgreementChange = e => {
    setHasAgreed(e.target.checked)
  }

  const steps = [
    {
      title: t('DETAILS'),
      content: (
        <>
          <PersonalInfo
            wrappedComponentRef={personalInfoFormRef}
            email={email}
            personalInfo={personalInfo}
            countryCode={countryCode}
          />
          {/* <P1>
            <Trans i18nKey="PERSONAL_INFO_DESCRIPTION"></Trans>
          </P1> */}
          {errMsg && (
            <Alert
              style={{ marginTop: 10 }}
              description={errMsg}
              closable
              afterClose={() => setErrMsg('')}
              type="error"
            />
          )}
          <div className="steps-actions">
            <Button
              size="large"
              type="primary"
              onClick={handlePersonalInfoNext}
            >
              {t('NEXT')}
            </Button>
          </div>
        </>
      )
    },
    {
      title: t('SUBSCRIPTION_PLANS'),
      hidden: !!preselectedPlan,
      content: (
        <>
          <SubscriptionPlans
            showReferralCodeInput
            showPromoCodeInput
            plans={plans}
            currentPlanId={selectedPlan.id}
            setReferralCode={setAppliedReferralCode}
            referralCode={appliedReferralCode}
            setPromoCode={setPromoCode}
            promoCode={promoCode}
            setSelectedPlan={setSelectedPlan}
            selectedPlan={selectedPlan}
          />
          {errMsg && (
            <Alert
              style={{ marginTop: 10 }}
              description={errMsg}
              closable
              afterClose={() => setErrMsg('')}
              type="error"
            />
          )}
          <div className="steps-actions">
            <Button
              size="large"
              type="default"
              onClick={handleSubscriptionPrevious}
            >
              {t('PREVIOUS')}
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={handleSubscriptionNext}
              loading={isCheckingCodeValidity}
            >
              {t('NEXT')}
            </Button>
          </div>
        </>
      )
    },
    {
      title: t('REVIEW'),
      content: (
        <>
          <Descriptions
            title={t('PERSONAL_INFORMATION')}
            column={{ xs: 1, sm: 1, md: 2 }}
          >
            <Descriptions.Item label={t('NAME')}>
              {personalInfo.fullname}
            </Descriptions.Item>
            <Descriptions.Item label={t('COUNTRY_TERRITORY')}>
              {personalInfo.country?.name}
            </Descriptions.Item>
            {personalInfo.baseCurrency && (
              <Descriptions.Item label={t('BASE_CURRENCY')}>
                {personalInfo.baseCurrency}
              </Descriptions.Item>
            )}
            <Descriptions.Item label={t('EMAIL')}>
              {personalInfo.email}
            </Descriptions.Item>
            <Descriptions.Item label={t('PHONE_NUMBER')}>
              {personalInfo.prefix} {personalInfo.phone}
            </Descriptions.Item>
            {personalInfo.govId && (
              <Descriptions.Item label={t('PASSPORT_NUMBER')}>
                {personalInfo.govId}
              </Descriptions.Item>
            )}
          </Descriptions>
          <Descriptions
            title={t('SUBSCRIPTION')}
            column={1}
            style={{ marginTop: 16 }}
          >
            <Descriptions.Item label={t('PLAN')}>
              {t(selectedPlan.nickname)}
            </Descriptions.Item>
            <Descriptions.Item label={t('PRICE')}>
              {selectedPlan.id === 'free' ? (
                t('FREE')
              ) : (
                <>
                  {selectedPlan.currency.toUpperCase()}{' '}
                  <H4 display="inline">{selectedPlan.amount / 100}</H4> /{' '}
                  {t(selectedPlan.interval)}
                </>
              )}
            </Descriptions.Item>
            {appliedReferralCode && selectedPlan.id !== 'free' && (
              <Descriptions.Item label={t('APPLIED_REFERRAL_CODE')}>
                {appliedReferralCode}
              </Descriptions.Item>
            )}
          </Descriptions>
          {promotionInfo && (
            <Descriptions
              title={t('APPLIED_PROMOTION')}
              column={1}
              style={{ marginTop: 16 }}
            >
              <Descriptions.Item label={t('PROMOTION_NAME')}>
                {promotionInfo.name}
              </Descriptions.Item>
              {promotionInfo.percent_off && (
                <Descriptions.Item label={t('PERCENTAGE_DISCOUNT')}>
                  {promotionInfo.percent_off}%
                </Descriptions.Item>
              )}
              {promotionInfo.amount_off && (
                <Descriptions.Item label={t('FIXED_AMOUNT_DISCOUNT')}>
                  ${promotionInfo.amount_off / 100}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t('DURATION')}>
                {promotionInfo.duration === 'repeating'
                  ? `${promotionInfo.duration_in_months} ${t('MONTHS')}`
                  : promotionInfo.duration === 'once'
                  ? t('ONCE')
                  : promotionInfo.duration === 'forever'
                  ? t('FOREVER')
                  : ''}
              </Descriptions.Item>
            </Descriptions>
          )}
          <FormItem>
            <Checkbox onChange={handleAgreementChange} checked={hasAgreed}>
              {t('READ_AND_AGREE_TERM_OF_SERVICE')}{' '}
              <a
                href="https://www.bantexapp.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('TERMS_OF_SERVICE')}
              </a>
            </Checkbox>
          </FormItem>
          <FormItem>
            <Checkbox.Group
              onChange={setMarketingConsent}
              value={marketingConsent}
            >
              <div>
                <Checkbox
                  value="fromVaultbox"
                  onChange={event => {
                    GAevent({
                      category: 'Register',
                      action:
                        (event.target.checked ? 'Check' : 'Uncheck') +
                        ' marketing from bantex',
                      label: 'Marketing consent checkboxes'
                    })
                  }}
                >
                  <Trans i18nKey="MARKETING_CONSENT_FROM_VAULTBOX"></Trans>
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  value="fromThirdParties"
                  onChange={event => {
                    GAevent({
                      category: 'Register',
                      action:
                        (event.target.checked ? 'Check' : 'Uncheck') +
                        ' marketing from third parties',
                      label: 'Marketing consent checkboxes'
                    })
                  }}
                >
                  {t('MARKETING_CONSENT_FROM_THIRT_PARTY')}
                </Checkbox>
              </div>
            </Checkbox.Group>
          </FormItem>
          {errMsg && (
            <Alert
              message={errMsg}
              type="error"
              closable
              style={{ marginBottom: 24 }}
              afterClose={() => setErrMsg('')}
            />
          )}
          <div className="steps-actions">
            <Button size="large" onClick={handlePreviewPrevious}>
              {t('PREVIOUS')}
            </Button>
            <div style={{ float: 'right' }}>
              <Tooltip
                title={hasAgreed ? null : t('NOT_AGREE_TERMS_OF_SERVICE_MSG')}
              >
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    GAevent({
                      category: 'Register',
                      action: 'Clicked Submit'
                    })
                    handleSubmit()
                  }}
                  disabled={!hasAgreed}
                  loading={isSubmitting}
                >
                  {t('SUBMIT')}
                </Button>
              </Tooltip>
            </div>
          </div>
        </>
      )
    }
  ]

  return !hasSubmitted ? (
    <div className="signup-form">
      <div className="form-header">
        <H3>{t('REGISTER')}</H3>
        <P1>{t('REGISTER_DESCRIPTION')}</P1>
      </div>
      {(primaryUserName || professionalDeputyId) && email ? (
        <>
          <PersonalInfo
            wrappedComponentRef={personalInfoFormRef}
            email={email}
            personalInfo={personalInfo}
            countryCode={countryCode}
          />
          <FormItem>
            <Checkbox onChange={handleAgreementChange} checked={hasAgreed}>
              {t('READ_AND_AGREE_TERM_OF_SERVICE')}{' '}
              <a
                href="https://www.bantexapp.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('TERMS_OF_SERVICE')}
              </a>
            </Checkbox>
          </FormItem>

          {errMsg && (
            <Alert
              message={errMsg}
              type="error"
              closable
              style={{ marginBottom: 24 }}
              afterClose={() => setErrMsg('')}
            />
          )}
          <Button
            size="large"
            type="primary"
            block
            onClick={handleQuickSubmit}
            disabled={!hasAgreed}
            loading={isSubmitting}
          >
            {t('CREATE_A_NEW_ACCOUNT')}
          </Button>
        </>
      ) : (
        <>
          <Steps current={currentStep} style={{ marginBottom: 32 }}>
            {steps
              .filter(s => !s.hidden)
              .map(item => (
                <Step key={item.title} title={item.title} />
              ))}
          </Steps>
          <div className="steps-content">
            {steps.filter(s => !s.hidden)[currentStep].content}
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="submitted-result">
      <Result
        icon={
          <Icon
            type="check-circle"
            theme="filled"
            style={{ color: '#0061D4' }}
          />
        }
        title={t('ACCOUNT_CREATED')}
        subTitle={
          <span>
            <Trans
              i18nKey="ACCOUNT_CREATED_SUCCESSFULLY_MSG"
              values={{ email: personalInfo.email }}
            ></Trans>
          </span>
        }
        extra={
          <Button size="large" type="primary">
            <Link to="/login">{t('RETURN_TO_HOMEPAGE')}</Link>
          </Button>
        }
      />
    </div>
  )
}

export default SignupForm
