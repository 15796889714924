import React, { useContext } from 'react'
import { List, Icon } from 'antd'
import { ThemeContext } from 'styled-components'

const FilesFoldersList = props => {
  const { selectedFilesFolders, setSelectedFilesFolders } = props
  const theme = useContext(ThemeContext)
  const dataSource = selectedFilesFolders?.map(sf => {
    return {
      id: sf.id,
      type: sf.type,
      title: sf.path
        ? sf.type === 'folder'
          ? sf.path.slice(0, sf.path.lastIndexOf('/'))
          : sf.path + sf.name
        : sf.name
    }
  })

  return (
    <List
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={item => (
        <List.Item
          actions={
            !!setSelectedFilesFolders
              ? [
                  <Icon
                    key="remove"
                    type="minus-circle"
                    onClick={() => {
                      const newSelectedFilesFolders = selectedFilesFolders.filter(
                        f => f.id !== item.id
                      )
                      setSelectedFilesFolders(newSelectedFilesFolders)
                    }}
                  />
                ]
              : []
          }
        >
          <List.Item.Meta
            title={
              <span>
                {item.type === 'folder' ? (
                  <Icon
                    type={item.type}
                    style={{ fontSize: '20px', color: theme.primary }}
                    theme={'filled'}
                  />
                ) : (
                  <Icon type={item.type} style={{ fontSize: '20px' }} />
                )}
                <span className="name" style={{ marginLeft: 10 }}>
                  {item.title}
                </span>
              </span>
            }
          />
        </List.Item>
      )}
    />
  )
}

export default FilesFoldersList
