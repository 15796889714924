import React, { useState, useContext, useEffect } from 'react'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
// import AssetLiabilityModal from '../assets-liabilities/AssetLiabilityModal'
import ContactModal from '../contacts/ContactModal'
import FileModal from '../file/FileModal'
import { Icon } from 'antd'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { subscribeToDBChanges } from '../../lib/pouchDb'
import { ENTITY_TYPES } from '../../share/Constants'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchContacts,
  // fetchPendingContacts
} from '../../features/contacts/contactsSlice'
// import {
//   fetchAssetsLiabilities,
//   fetchPendingAssetsLiabilities,
//   fetchValuations
// } from '../../features/assets-liabilities/assetsLiabilitiesSlice'
import { fetchDocuments } from '../../features/documents/documentsSlice'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import AddEventModal from '../../components/calendar/AddEventModal'
import { fetchEvents } from './../../features/events/eventsSlice'
import { fetchPasswords } from './../../features/passwords/PasswordSlice'
// import { fetchPendingDocuments } from './../../features/documents/documentsSlice'
import { fetchLocations } from './../../features/location/LocationSlice';

export default function SpeedDials({ disabled, hidden }) {
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const [open, setOpen] = useState(false)
  const [visibleModal, setVisibleModal] = useState('')
  const [eventvisibleModal, setEventVisibleModal] = useState(false)
  const dispatch = useDispatch()
  const { activeContacts } = useSelector(state => state.contacts)
  const { t } = useTranslation()

  useEffect(() => {
    // need to debounce on changes, otherwise when the user logs in and the records are loaded into DBs the first time
    // onChange callback will run on each record being inserted into DB, causing too much re-rendering
    // const assetsLiabilitiesChanges = subscribeToDBChanges(
    //   'assetsLiabilities',
    //   user.username,
    //   debounce(
    //     () => dispatch(fetchAssetsLiabilities(user.username, masterKey)),
    //     500
    //   )
    // )

    // const pendingAssetsLiabilitiesChanges = subscribeToDBChanges(
    //   'pendingAssetsLiabilities',
    //   user.username,
    //   debounce(
    //     () => dispatch(fetchPendingAssetsLiabilities(user.username, masterKey)),
    //     500
    //   )
    // )

    const contactsChanges = subscribeToDBChanges(
      'contacts',
      user.username,
      debounce(() => dispatch(fetchContacts(user.username, masterKey)), 500)
    )

    // const pendingContactsChanges = subscribeToDBChanges(
    //   'pendingContacts',
    //   user.username,
    //   debounce(
    //     () => dispatch(fetchPendingContacts(user.username, masterKey)),
    //     500
    //   )
    // )

    const documentsChanges = subscribeToDBChanges(
      'documents',
      user.username,
      debounce(() => dispatch(fetchDocuments(user.username, masterKey)), 500)
    )
    // const pendingDocumentsChanges = subscribeToDBChanges(
    //   'pendingDocuments',
    //   user.username,
    //   debounce(
    //     () => dispatch(fetchPendingDocuments(user.username, masterKey)),
    //     500
    //   )
    // )
    const eventsChanges = subscribeToDBChanges(
      'events',
      user.username,
      debounce(() => dispatch(fetchEvents(user.username, masterKey)), 500)
    )

    // const valuationsChanges = subscribeToDBChanges(
    //   'assetsLiabilitiesValuations',
    //   user.username,
    //   debounce(() => dispatch(fetchValuations(user.username, masterKey)), 500)
    // )

    const passwordsChanges = subscribeToDBChanges(
      'passwords',
      user.username,
      debounce(() => dispatch(fetchPasswords(user.username, masterKey)), 500)
    )

    const locationsChanges = subscribeToDBChanges(
      'locations',
      user.username,
      debounce(() => dispatch(fetchLocations(user.username, masterKey)), 500)
    )
    return () => {
      // assetsLiabilitiesChanges.cancel()
      // pendingAssetsLiabilitiesChanges.cancel()
      contactsChanges.cancel()
      // pendingContactsChanges.cancel()
      documentsChanges.cancel()
      // valuationsChanges.cancel()
      eventsChanges.cancel()
      passwordsChanges.cancel()
      // pendingDocumentsChanges.cancel()
      locationsChanges.cancel()
    }
  }, [user, dispatch, masterKey])

  useEffect(() => {
    setEventVisibleModal(visibleModal === ENTITY_TYPES.EVENT)
  }, [visibleModal])

  const actions = [
    {
      icon: <Icon type="contacts" />,
      name: t('ADD_CONTACT'),
      key: ENTITY_TYPES.CONTACT
    },
    {
      icon: <Icon type="calendar" />,
      name: t('ADD_EVENT'),
      key: ENTITY_TYPES.EVENT
    },
    {
      icon: <Icon type="file" />,
      name: t('UPLOAD_FILE'),
      key: ENTITY_TYPES.DOCUMENT
    }
    // {
    //   icon: <Icon type="home" />,
    //   name: t('ADD_ASSET_LIABILITY'),
    //   key: ENTITY_TYPES.ASSET_LIABILITY
    // }
  ]

  const handleClose = () => {
    if (!disabled) {
      setOpen(false)
    }
  }

  const handleOpen = () => {
    if (!disabled) {
      setOpen(true)
    }
  }

  const handleClick = key => {
    setOpen(false)
    setVisibleModal(key)
  }

  return (
    <>
      <SpeedDial
        ariaLabel="Speed dials"
        className="speed-dial"
        style={{
          position: 'fixed',
          bottom: 20,
          right: 10,
          // zIndex: disabled && 990
          zIndex: 990
        }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        hidden={hidden}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action.key)}
            tooltipOpen
          />
        ))}
      </SpeedDial>
      {/* <AssetLiabilityModal
        visible={visibleModal === ENTITY_TYPES.ASSET_LIABILITY}
        setVisible={setVisibleModal}
        record={{}}
        contactDataSource={activeContacts}
      /> */}
      <ContactModal
        visible={visibleModal === ENTITY_TYPES.CONTACT}
        setVisible={setVisibleModal}
        selectedRecord={{}}
      />
      <FileModal
        visible={visibleModal === ENTITY_TYPES.DOCUMENT}
        setVisible={setVisibleModal}
        contactDataSource={activeContacts}
      />

      <AddEventModal
        isEditMode={false}
        isFullModal={true}
        visible={eventvisibleModal}
        setVisible={setEventVisibleModal}
        handleClose={() => {
          setEventVisibleModal(false)
          setVisibleModal('')
        }}
        data={[]}
        handleReturn={() => {}}
        eventDetails={{}}
        linkedEvents={[]}
        setLinkedEvents={() => {}}
      />
    </>
  )
}
