import React from 'react'
import { Form, Input, Select, Row, Col } from 'antd'
import { NUMBER_ONLY_REGEX } from '../../share/Constants'
//import { StringResources } from '../../share/StringResources'
import { compareToPassword } from '../../share/formHelpers'
import FormItem from '../override/FormItem'
import { countries } from 'country-data'
import PasswordInputWithRules from './PasswordInputWithRules'
// import CurrencySelect from '../common/CurrencySelect'
import TextInput from '../common/TextInput'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import i18nIsoCountries from 'i18n-iso-countries'
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'))
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/vi.json'))
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/zh.json'))
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/id.json'))

const { Option } = Select

class PersonalInfoForm extends React.Component {
  state = {
    confirmDirty: false,
    phoneNumberDirty: false,
    defaultCountry: {}
  }

  componentDidUpdate(previousProps) {
    if (
      this.props.countryCode &&
      previousProps.countryCode !== this.props.countryCode
    ) {
      const defaultCountry = countries.all.find(
        c => c.alpha2 === this.props.countryCode
      )
      if (defaultCountry) {
        this.setState({
          defaultCountry
        })
      }
    }
  }

  handleConfirmBlur = e => {
    const { value } = e.target
    this.setState({ confirmDirty: this.state.confirmDirty || !!value })
  }

  handlePhoneNumberBlur = e => {
    const { value } = e.target
    this.setState({ phoneNumberDirty: this.state.phoneNumberDirty || !!value })
  }

  render() {
    const { form, email, personalInfo, isEditing } = this.props
    const { defaultCountry } = this.state
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form
    const countryCallingCodes = countries.all.reduce((allCodes, country) => {
      return [...allCodes, ...country.countryCallingCodes]
    }, [])

    const CountryVn = i18nIsoCountries.getNames('vi', { select: 'official' })
    const CountryEn = i18nIsoCountries.getNames('en', { select: 'official' })
    const CountryCn = i18nIsoCountries.getNames('zh', { select: 'official' })
    const CountryId = i18nIsoCountries.getNames('id', { select: 'official' })

    const uniqueCountryCallingCodes = [...new Set(countryCallingCodes)].sort(
      (a, b) => a.localeCompare(b)
    )

    const validatePhoneNumber = (rule, value, callback) => {
      if (value) {
        const prefix = getFieldValue('prefix')
        if (!prefix) {
          callback(<Trans i18nKey="SELECT_COUNTRY_CODE"></Trans>)
        } else if (!value.match(NUMBER_ONLY_REGEX)) {
          callback(<Trans i18nKey="INVALID_MOBLIE_NUMBER"></Trans>)
        }
      }

      callback()
    }

    const handleCountryChange = code => {
      const selectedCountry = countries.all.find(c => c.alpha2 === code)
      if (!selectedCountry) return

      setFieldsValue({
        'country.name': selectedCountry.name
      })
      if (!isEditing) {
        setFieldsValue({
          prefix: selectedCountry.countryCallingCodes[0]
        })
      }
    }

    const validateToPhoneNumber = (rule, value, callback) => {
      if (value && this.state.phoneNumberDirty) {
        form.validateFields(['phone'], { force: true })
      }
      callback()
    }

    const prefixSelector = getFieldDecorator('prefix', {
      initialValue:
        personalInfo.prefix ||
        (defaultCountry?.countryCallingCodes &&
          defaultCountry.countryCallingCodes[0]),
      rules: [
        {
          validator: validateToPhoneNumber
        }
      ]
    })(
      <Select
        style={{ width: 85 }}
        showSearch
        showAction={['click', 'focus']}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .includes(input.trim().toLowerCase())
        }
      >
        {uniqueCountryCallingCodes.map(code => (
          <Option key={code} value={code.replace(/ /g, '')}>
            {code}
          </Option>
        ))}
      </Select>
    )

    return (
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={20}>
          <Col sm={12}>
            <FormItem label={<Trans i18nKey="NAME"></Trans>}>
              {getFieldDecorator('fullname', {
                initialValue: personalInfo.fullname,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: <Trans i18nKey="INPUT_FULLNAME_MSG"></Trans>
                  }
                ]
              })(
                <TextInput
                  name="fullname"
                  setFieldsValue={setFieldsValue}
                  autoFocus
                />
              )}
            </FormItem>
          </Col>
          {isEditing && (
            <Col sm={12}>
              <FormItem label={<Trans i18nKey="PREFERRED_NAME"></Trans>}>
                {getFieldDecorator('preferredName', {
                  initialValue: personalInfo.preferredName
                })(
                  <TextInput
                    name="preferredName"
                    setFieldsValue={setFieldsValue}
                  />
                )}
              </FormItem>
            </Col>
          )}
          <Col sm={12}>
            <FormItem label={<Trans i18nKey="COUNTRY_TERRITORY"></Trans>}>
              {getFieldDecorator('country.code', {
                initialValue:
                  personalInfo.country?.code || defaultCountry?.alpha2
              })(
                <Select
                  showSearch
                  showAction={['click', 'focus']}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(input.trim().toLowerCase())
                  }
                  onChange={handleCountryChange}
                >
                  {countries.all
                    .filter(c => c.status === 'assigned')
                    .map(c => (
                      <Select.Option key={c.alpha2} value={c.alpha2}>
                        {i18next.language === 'vn'
                          ? CountryVn[c.alpha2]
                          : i18next.language === 'cn'
                          ? CountryCn[c.alpha2]
                          : i18next.language === 'id'
                          ? CountryId[c.alpha2]
                          : CountryEn[c.alpha2]}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </FormItem>

            {getFieldDecorator('country.name', {
              initialValue: personalInfo.country?.name || defaultCountry?.name
            })(<TextInput type="hidden" />)}
          </Col>

          {/* {!email && !isEditing && (
            <Col sm={12}>
              <FormItem
                label={
                  <span>
                    {<Trans i18nKey="BASE_CURRENCY"></Trans>}{' '}
                    <Tooltip
                      title={<Trans i18nKey="BASE_CURRENCY_TOOLTIP"></Trans>}
                    >
                      <Icon type="question-circle" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('baseCurrency', {
                  initialValue: personalInfo.baseCurrency,
                  rules: [
                    {
                      required: true,
                      message: <Trans i18nKey="INPUT_BASE_CURRENCY_MSG"></Trans>
                    }
                  ]
                })(<CurrencySelect />)}
              </FormItem>
            </Col>
          )} */}
        </Row>

        {/* TODO: add debouncing for email validation, probably need to implement custom validation with our own regex*/}
        <Row gutter={20}>
          <Col sm={12}>
            <FormItem label={<Trans i18nKey="EMAIL"></Trans>}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: <Trans i18nKey="INVALID_EMAIL_MSG"></Trans>
                  },
                  {
                    required: true,
                    message: <Trans i18nKey="INPUT_EMAIL_MSG"></Trans>
                  }
                ],
                initialValue: email || personalInfo.email
              })(<TextInput disabled={email ? true : false} />)}
            </FormItem>
          </Col>
          <Col sm={12}>
            <FormItem label={<Trans i18nKey="MOBLIE_NUMBER_WITH_CODE"></Trans>}>
              {getFieldDecorator('phone', {
                initialValue: personalInfo.phone,
                rules: [
                  {
                    required: true,
                    message: <Trans i18nKey="INPUT_PHONE_NUMBER_MSG"></Trans>
                  },
                  {
                    validator: validatePhoneNumber
                  }
                ]
              })(
                <TextInput
                  addonBefore={prefixSelector}
                  style={{ width: '100%' }}
                  onBlur={this.handlePhoneNumberBlur}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        {!isEditing && (
          <Row gutter={20}>
            <Col sm={12}>
              <PasswordInputWithRules
                password={personalInfo.password}
                confirmDirty={this.state.confirmDirty}
                title={<Trans i18nKey="PASSWORD"></Trans>}
                form={form}
              />
            </Col>
            <Col sm={12}>
              <FormItem
                label={<Trans i18nKey="CONFIRM_PASSWORD"></Trans>}
                hasFeedback
              >
                {getFieldDecorator('confirm', {
                  initialValue: personalInfo.confirm,
                  rules: [
                    {
                      required: true,
                      message: <Trans i18nKey="CONFIRM_PASSWORD_MSG"></Trans>
                    },
                    {
                      validator: (rule, value, callback) =>
                        compareToPassword(rule, value, callback, form)
                    }
                  ]
                })(
                  <Input.Password
                    onBlur={this.handleConfirmBlur}
                    maxLength={30}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        )}
        {/* GovernmentId */}
        {isEditing && personalInfo.govId && (
          <Row gutter={20}>
            <Col sm={12}>
              <FormItem label={<Trans i18nKey="PASSPORT_NUMBER"></Trans>}>
                {getFieldDecorator('govId', {
                  initialValue: personalInfo.govId,
                  rules: [
                    {
                      required: true,
                      message: (
                        <Trans i18nKey="INPUT_PASSPORT_NUMBER_MSG"></Trans>
                      )
                    }
                  ]
                })(
                  <TextInput
                    name="govId"
                    setFieldsValue={setFieldsValue}
                    allowClear
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        )}
      </Form>
    )
  }
}

const WrappedPersonalInfoForm = Form.create({ name: 'personalInfo' })(
  PersonalInfoForm
)
export default WrappedPersonalInfoForm
