import React from 'react'
import { Icon } from 'antd'
import { ReactComponent as ValidSvg } from '../../assets/ic/10/valid.svg'
import { ReactComponent as TrashSvg } from '../../assets/ic/12/trash.svg'
import { ReactComponent as BirthdaySvg } from '../../assets/ic/12/birthday.svg'
import { ReactComponent as LinkSvg } from '../../assets/ic/12/link.svg'
import { ReactComponent as LocationSvg } from '../../assets/ic/12/location.svg'
import { ReactComponent as PageSvg } from '../../assets/ic/12/page.svg'
import { ReactComponent as WorldSvg } from '../../assets/ic/12/world.svg'
import { ReactComponent as EmailSvg } from '../../assets/ic/16/email-black.svg'
import { ReactComponent as IdSvg } from '../../assets/ic/16/ID.svg'
import { ReactComponent as SimpleKeySvg } from '../../assets/ic/16/key.svg'
import { ReactComponent as MoneySvg } from '../../assets/ic/16/money.svg'
import { ReactComponent as MoreSvg } from '../../assets/ic/16/more.svg'
import { ReactComponent as PersonalSvg } from '../../assets/ic/16/personal.svg'
import { ReactComponent as MobileSvg } from '../../assets/ic/16/smartphone.svg'
import { ReactComponent as SocialSvg } from '../../assets/ic/16/social.svg'
import { ReactComponent as TickSvg } from '../../assets/ic/16/tick-3.svg'
import { ReactComponent as UnlockSvg } from '../../assets/ic/16/unlock.svg'
import { ReactComponent as GeneralSvg } from '../../assets/ic/20/general.svg'
import { ReactComponent as RealEstateSvg } from '../../assets/ic/20/real-estate.svg'
import { ReactComponent as CashSvg } from '../../assets/ic/20/cash.svg'
import { ReactComponent as EquitySvg } from '../../assets/ic/20/equity.svg'
import { ReactComponent as LoanSvg } from '../../assets/ic/20/loan.svg'
import { ReactComponent as InsuranceSvg } from '../../assets/ic/20/insurance.svg'
import { ReactComponent as MortgageSvg } from '../../assets/ic/20/mortgage.svg'
import { ReactComponent as AddSvg } from '../../assets/ic/20/add.svg'
import { ReactComponent as RetirementSvg } from '../../assets/ic/20/retirement.svg'
import { ReactComponent as VehicleSvg } from '../../assets/ic/20/vehicle.svg'
import { ReactComponent as KeySvg } from '../../assets/ic/64/key.svg'
import { ReactComponent as ContactSvg } from '../../assets/ic/64/contact.svg'
import { ReactComponent as TextSvg } from '../../assets/ic/64/text.svg'
import { ReactComponent as VaultSvg } from '../../assets/ic/bi_safe.svg'

const iconMap = {
  add: AddSvg,
  trash: TrashSvg,
  general: GeneralSvg,
  realEstate: RealEstateSvg,
  cash: CashSvg,
  equity: EquitySvg,
  loan: LoanSvg,
  insurance: InsuranceSvg,
  mortgage: MortgageSvg,
  key: KeySvg,
  simpleKey: SimpleKeySvg,
  more: MoreSvg,
  money: MoneySvg,
  personal: PersonalSvg,
  email: EmailSvg,
  mobile: MobileSvg,
  id: IdSvg,
  contact: ContactSvg,
  birthday: BirthdaySvg,
  link: LinkSvg,
  location: LocationSvg,
  page: PageSvg,
  world: WorldSvg,
  social: SocialSvg,
  valid: ValidSvg,
  tick: TickSvg,
  unlock: UnlockSvg,
  retirement: RetirementSvg,
  vehicle: VehicleSvg,
  text: TextSvg,
  vault: VaultSvg
}

export default function CustomIcon({ type, ...restProps }) {
  return <Icon component={iconMap[type]} {...restProps} />
}
