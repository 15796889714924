import React, { useState, useEffect, useContext } from 'react'
import {
  Form,
  Input,
  Radio,
  DatePicker,
  message,
  Select,
  Divider,
  Icon,
  Tooltip,
  Row,
  Col,
  Breadcrumb,
  Layout
} from 'antd'
import SimpleHeader from '../override/SimpleHeader'
import { withRouter, useParams } from 'react-router-dom'
import { H4 } from '../override/Typography'
import FormItem from '../override/FormItem'
//import { StringResources } from '../../share/StringResources'
import uuidv4 from 'uuid/v4'
import PouchDB from 'pouchdb'
import {
  getRecord,
  uploadEncryptedData,
  getLatestVersion,
  getRecords,
  linkContactsToAnother,
  unlinkContactsFromAnother
} from '../../lib/pouchDb'
import {
  DATE_FORMAT,
  FIELDS,
  EMAIL_DEFAULT_OPTIONS,
  ADDRESS_DEFAULT_OPTIONS,
  PHONE_DEFAULT_OPTIONS,
  ENTITY_TYPES,
  ACCESS_LEVEL
} from '../../share/Constants'
import ContactModel from '../../model/ContactModel'
import AddMultipleFields from '../common/AddMultipleFields'
import {
  filterEmptyEls,
  getOptions,
  getReferencesTooltip,
  sanitizeValue
} from '../../share/helpers'
import moment from 'moment'
import VaultContext from '../../contexts/VaultContext'
import EventLinks from '../common/EventLinks'
import AddItemModal from '../modals/AddItemModal'
import ContactLinks from '../assets-liabilities/ContactLinks'
import SiderActionButtons from '../common/SiderActionButtons'
import FileTags from '../file/FileTags'
import { countries } from 'country-data'
import TourContext, { TOUR_STEP_INDEXES } from '../../contexts/TourContext'
import { onError } from '../../lib/sentry'
import { ThemeContext } from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import TextInput from '../common/TextInput'
import { useTranslation } from 'react-i18next'
import { removeHtmlTags } from './../../share/helpers'
import { PHONE_NUMBER_REGEX } from './../../share/Constants'
import AuthContext from '../../contexts/AuthContext'
import {
  fetchOtherPendingContacts,
  fetchOtherContacts
} from './../../features/contacts/otherContactsSlice'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../lib/api'
import AddEventModal from '../calendar/AddEventModal'
import ConvertToEvent from '../common/ConvertToEvent'
import { uniq } from 'lodash'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import { logDocumentActivity } from '../../share/logs'
import { getUserData } from '../../lib/cognito'

const { Content, Sider } = Layout

function ContactAddEdit({ history, form }) {
  const theme = useContext(ThemeContext)
  const { isProfessionalDeputy, isDelegateByPD, user } = useContext(AuthContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  const { contactId } = useParams()
  const [record, setRecord] = useState({})
  const [selectedType, setSelectedType] = useState('')
  const [linkedEvents, setLinkedEvents] = useState([])
  const [linkedContacts, setLinkedContacts] = useState([])
  const [unlinkedContacts, setUnlinkedContacts] = useState([])
  const [dynamicFieldsValue, setDynamicFieldsValue] = useState({
    emails: [],
    addresses: [],
    references: [],
    phoneNumbers: []
  })
  const [dynamicFieldsOptions, setDynamicFieldsOptions] = useState({
    emails: [],
    addresses: [],
    phoneNumbers: []
  })
  const [activeDynamicField, setActiveDynamicField] = useState({ name: '' })
  const [isSaving, setIsSaving] = useState(false)
  const [isMinor, setIsMinor] = useState(false)
  const [tags, setTags] = useState([])
  const [addEventModalVisible, setAddEventModalVisible] = useState(false)
  const [eventDate, setEventDate] = useState()
  const [eventRecurringValue, setEventRecurringValue] = useState()
  const [name, setName] = useState()
  const [eventDescription, setEventDescription] = useState()

  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const { accessLevel } = useSelector(state => state.settings)

  const { userId, masterKey, isReadonly, recordIds } = useContext(VaultContext)
  const { tourRun, nextTourStep, tourStepIndex } = useContext(TourContext)
  const db = new PouchDB(`${userId}_contacts`)
  const pendingDb = new PouchDB(`${userId}_pendingContacts`)
  let addItemFormRef
  const { activeContacts, pendingContacts } = useSelector(state =>
    isProfessionalDeputy ? state.otherContacts : state.contacts
  )

  const [addS3Change] = useMutation(createS3Change)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setIsLoading(true)
        const record = contactId
          ? await getRecord(
              userId,
              isProfessionalDeputy || (isDelegateByPD && isReadonly)
                ? 'pendingContacts'
                : 'contacts',
              contactId,
              masterKey
            )
          : {}

        const linkedEvents = record.events?.length
          ? await getRecords(userId, 'events', masterKey, {
              keys: record.events
            })
          : []

        const linkedContacts =
          record.links && record.links.length
            ? [
                ...(await getRecords(userId, 'contacts', masterKey)).filter(c =>
                  record.links?.includes(c._id)
                ),
                ...(
                  await getRecords(userId, 'pendingContacts', masterKey)
                ).filter(c => record.links?.includes(c._id))
              ]
            : []
        setRecord(record)
        setLinkedEvents(linkedEvents)
        setLinkedContacts(linkedContacts)
        setSelectedType(record.type || 'Individual')
        setTags(record.tags || [])
        // setIsLoading(false)
      } catch (err) {
        onError(err)
        // setIsLoading(false)
      }
    }

    if (masterKey) {
      fetchData()
    }
  }, [
    userId,
    contactId,
    masterKey,
    isProfessionalDeputy,
    isDelegateByPD,
    isReadonly
  ])

  useEffect(() => {
    const { emails, addresses, references, phoneNumbers } = record
    setDynamicFieldsValue({
      emails: emails || [],
      addresses: addresses || [],
      references: references || [],
      phoneNumbers: phoneNumbers || []
    })
    setDynamicFieldsOptions({
      emails: getOptions(EMAIL_DEFAULT_OPTIONS, emails),
      addresses: getOptions(ADDRESS_DEFAULT_OPTIONS, addresses),
      phoneNumbers: getOptions(PHONE_DEFAULT_OPTIONS, phoneNumbers)
    })
  }, [record])

  useEffect(() => {
    const givenName = form.getFieldValue('givenName')
    const surName = form.getFieldValue('surName')
    const name = givenName && surName ? givenName + ' ' + surName : givenName
    setName(name)
  }, [form])

  useEffect(() => {
    if (tourRun && tourStepIndex === TOUR_STEP_INDEXES.CREATE_CONTACT_BUTTON) {
      setRecord({
        givenName: 'Dummy'
      })
      nextTourStep()
    }
  }, [tourStepIndex, tourRun, nextTourStep])

  const fieldsWithDefaultNames = FIELDS.map(field =>
    field.key === 'name'
      ? {
          ...field,
          node: (name, rowId) => (
            <Select
              showAction={['click', 'focus']}
              key={`${field.key}`}
              style={{ minWidth: 180 }}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: 0 }} />
                  <div
                    style={{ padding: '5px 12px', cursor: 'pointer' }}
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setActiveDynamicField({ name, rowId })}
                  >
                    <Icon type="plus" /> {t('CUSTOM')}
                  </div>
                </div>
              )}
            >
              {dynamicFieldsOptions[name].map(option => (
                <Select.Option key={option} value={option}>
                  {t(option)}
                </Select.Option>
              ))}
            </Select>
          ),
          default: name => dynamicFieldsOptions[name][0]
        }
      : field
  )

  const handleOk = e => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      removeHtmlTags(values)
      setIsSaving(true)
      const contact = new ContactModel({
        ...values,
        type: selectedType,
        emails: filterEmptyEls(values.emails),
        addresses: filterEmptyEls(values.addresses),
        references: filterEmptyEls(values.references),
        phoneNumbers: filterEmptyEls(values.phoneNumbers),
        events: linkedEvents.map(doc => doc?._id),
        links: linkedContacts.map(doc => doc?._id),
        status:
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? 'Draft'
            : ''
      })

      const record = contactId
        ? await getRecord(
            userId,
            isProfessionalDeputy || (isDelegateByPD && isReadonly)
              ? 'pendingContacts'
              : 'contacts',
            contactId,
            masterKey
          )
        : {}

      const newRecord = {
        ...record,
        ...contact,
        reasonReject: undefined,
        tags: removeHtmlTags(tags),
        _id: record._id || uuidv4()
      }

      try {
        ;(isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
        accessLevel === ACCESS_LEVEL.NEED_APPROVAL
          ? pendingDb.crypto(masterKey)
          : db.crypto(masterKey)
        const putResult =
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? await pendingDb.put(newRecord)
            : await db.put(newRecord)
        const historyDb = new PouchDB(`${userId}_contactsHistory`)

        let latestVersion
        if (isMinor) {
          latestVersion = await getLatestVersion(
            'contactsHistory',
            userId,
            record._id,
            masterKey
          )
        }

        const versionId =
          (latestVersion && latestVersion._id) ||
          `${putResult.id}_${putResult.rev}`
        delete newRecord._rev

        const versionRecord = {
          ...latestVersion,
          ...newRecord,
          _id: versionId,
          time: new Date()
        }

        historyDb.crypto(masterKey)
        await historyDb.put(versionRecord)

        await Promise.all([
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? uploadEncryptedData(pendingDb, userId, 'pendingContacts')
            : uploadEncryptedData(db, userId, 'contacts'),
          uploadEncryptedData(historyDb, userId, 'contactsHistory')
        ])

        //update event links
        const addedEvents = linkedEvents
          ?.map(e => e._id)
          .filter(eventId => !record.events?.includes(eventId))

        const removedEvents =
          record.events?.filter(
            contactId => !linkedEvents?.map(e => e?._id).includes(contactId)
          ) || []

        const updatedEvents = [...addedEvents, ...removedEvents]
        if (updatedEvents?.length) {
          const eventsDb = new PouchDB(`${userId}_events`)
          await updateEventsLinks(
            updatedEvents,
            newRecord,
            addedEvents,
            removedEvents,
            eventsDb,
            'events'
          )
        }

        //update contact links
        await linkContactsToAnother(
          userId,
          newRecord._id,
          linkedContacts
            .map(doc => doc._id)
            .filter(cid => activeContacts.map(ac => ac._id).includes(cid)),
          masterKey,
          'contacts'
        )
        await unlinkContactsFromAnother(
          userId,
          newRecord._id,
          unlinkedContacts.filter(cid =>
            activeContacts.map(ac => ac._id).includes(cid)
          ),
          masterKey,
          'contacts'
        )
        await linkContactsToAnother(
          userId,
          newRecord._id,
          linkedContacts
            .map(doc => doc._id)
            .filter(cid => pendingContacts.map(ac => ac._id).includes(cid)),
          masterKey,
          'pendingContacts'
        )
        await unlinkContactsFromAnother(
          userId,
          newRecord._id,
          unlinkedContacts.filter(cid =>
            pendingContacts.map(ac => ac._id).includes(cid)
          ),
          masterKey,
          'pendingContacts'
        )

        if (isProfessionalDeputy || (isDelegateByPD && isReadonly)) {
          if (accessLevel === ACCESS_LEVEL.NEED_APPROVAL) {
            await api.sendAddRecordNotification(
              JSON.stringify({
                userId,
                recordType: 'contact'
              })
            )
            dispatch(fetchOtherPendingContacts(userId, masterKey))
          } else {
            dispatch(fetchOtherContacts(userId, masterKey))
          }
        }

        if (isReadonly && record?._id) {
          getUserData(
            user,
            async (err, data) => {
              if (err) {
                onError(err)
                return
              }

              await logDocumentActivity(
                userId,
                data.UserAttributes,
                user.username,
                record._id,
                {
                  id: record._id,
                  type: 'contact',
                  name: record.name,
                  action: 'edited',
                }
              )
            },
            { bypassCache: true }
          )
        }

        // update sharing record
        if (record._id && recordIds.includes(record._id)) {
          const linkedItems = [
            ...linkedContacts?.map(doc => doc?._id),
            ...linkedEvents?.map(doc => doc?._id)
          ]

          await api.updateSharingRecord(
            userId,
            JSON.stringify({
              recordId: record._id,
              linkedItems
            })
          )
        }
        setIsSaving(false)
        message.success(
          record._id ? t('EDIT_CONTACT_SUCCESS') : t('ADD_CONTACT_SUCCESS')
        )
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message: 'contacts, pendingContacts, events',
            userId: userId
          }
        })
        reset()

        if (tourStepIndex === TOUR_STEP_INDEXES.SAVE_CONTACT_BUTTON) {
          setTimeout(() => {
            nextTourStep()
          })
        }
      } catch (err) {
        setIsSaving(false)
        onError(err)
      }
    })
  }

  const updateEventsLinks = async (
    updatedEvents,
    newRecord,
    addedEvents,
    removedEvents,
    db,
    dbName
  ) => {
    db.crypto(masterKey)
    const docs = await db.allDocs({
      keys: updatedEvents,
      include_docs: true
    })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row

        if (addedEvents.includes(doc._id)) {
          const newContacts = uniq([...(doc.contacts || []), newRecord._id])

          return { ...doc, contacts: newContacts }
        } else if (removedEvents.includes(doc._id)) {
          const newContacts = doc.contacts
            ? doc.contacts.filter(cId => cId !== newRecord._id)
            : []
          return { ...doc, contacts: newContacts }
        } else {
          return { ...doc }
        }
      })

    await db.bulkDocs(updatedDocs)
    await uploadEncryptedData(db, userId, dbName)
  }

  const handleLinkEvents = async eventIds => {
    try {
      const allEventIds = linkedEvents?.map(doc => doc?._id).concat(eventIds)

      const newLinkedEvents = allEventIds?.length
        ? await getRecords(userId, 'events', masterKey, {
            keys: allEventIds
          })
        : []

      setLinkedEvents(newLinkedEvents)
    } catch (error) {
      onError(error)
    }
  }

  const handleUnlinkEvents = eventId => {
    setLinkedEvents(linkedEvents?.filter(doc => doc?._id !== eventId))
  }

  const handleAddNewOption = () => {
    const { name, rowId } = activeDynamicField
    addItemFormRef.props.form.validateFields((err, values) => {
      if (err) return

      setDynamicFieldsOptions({
        ...dynamicFieldsOptions,
        [name]: [...dynamicFieldsOptions[name], values.itemName]
      })
      setActiveDynamicField({ name: '' })
      addItemFormRef.props.form.resetFields()
      form.setFieldsValue({
        [`${name}[${rowId}][name]`]: values.itemName
      })
    })
  }

  const handleLinkContacts = async contactIds => {
    try {
      const allContactIds = linkedContacts
        .map(doc => doc._id)
        .concat(contactIds)

      const newLinkedContacts =
        allContactIds && allContactIds.length
          ? [
              ...(await await getRecords(userId, 'contacts', masterKey)).filter(
                c => allContactIds.includes(c._id)
              ),
              ...(
                await getRecords(userId, 'pendingContacts', masterKey)
              ).filter(c => allContactIds.includes(c._id))
            ]
          : []

      setLinkedContacts(newLinkedContacts)
    } catch (e) {
      onError(e)
    }
  }

  const handleUnlinkContact = linkedContactId => {
    setUnlinkedContacts([...unlinkedContacts, linkedContactId])
    setLinkedContacts(linkedContacts.filter(doc => doc._id !== linkedContactId))
  }

  const reset = () => {
    form.resetFields()
    setSelectedType(record.type || 'Individual')
    history.goBack()
  }

  return (
    <Layout>
      <Content style={{ backgroundColor: '#fff', padding: '0 20px 20px' }}>
        <SimpleHeader
          title={
            <Breadcrumb separator=">">
              <Breadcrumb.Item
                href=""
                onClick={e => {
                  e.preventDefault()
                  history.push('/contacts')
                }}
              >
                <H4 display="inline-block" style={{ color: '#9AA0B3' }}>
                  {t('CONTACTS')}
                </H4>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <H4 display="inline-block">
                  {record.name
                    ? `${t('EDIT')} ${record.name}`
                    : t('CREATE_NEW')}
                </H4>
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          extra={
            <Radio.Group
              onChange={e => setSelectedType(e.target.value)}
              value={selectedType || 'Individual'}
              disabled={!!record._id}
            >
              <Radio value={'Individual'}>{t('Individual')}</Radio>
              <Radio value={'Organisation'}>{t('Organisation')}</Radio>
            </Radio.Group>
          }
        />
        <Form className="tour-create-contact-form">
          <Row gutter={40}>
            <Col md={6} style={{ marginTop: 8 }}>
              <H4>{t('GENERAL_INFO')}</H4>
            </Col>
            <Col md={18}>
              {selectedType === 'Individual' ? (
                <>
                  <Row gutter={20}>
                    <Col md={12}>
                      <FormItem label={t('GIVEN_NAME')}>
                        {getFieldDecorator('givenName', {
                          rules: [
                            {
                              required: true,
                              message: t('INPUT_GIVEN_NAME_MSG')
                            }
                          ],
                          initialValue: record.givenName
                        })(
                          <TextInput
                            name="givenName"
                            setFieldsValue={setFieldsValue}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col md={12}>
                      <FormItem label={t('SURNAME')}>
                        {getFieldDecorator('surName', {
                          initialValue: record.surName
                        })(
                          <TextInput
                            name="surName"
                            setFieldsValue={setFieldsValue}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col md={12}>
                      <FormItem
                        label={
                          <span>
                            {t('DATE_OF_BIRTH')}
                            {!isReadonly && (
                              <ConvertToEvent
                                date={getFieldValue('dateOfBirth')}
                                setEventDate={setEventDate}
                                setEventRecurringValue={setEventRecurringValue}
                                name={name}
                                setAddEventModalVisible={
                                  setAddEventModalVisible
                                }
                                setEventDescription={setEventDescription}
                                label={t('DATE_OF_BIRTH')}
                              />
                            )}
                          </span>
                        }
                      >
                        {getFieldDecorator('dateOfBirth', {
                          initialValue: record.dateOfBirth
                            ? moment(record.dateOfBirth)
                            : null
                        })(
                          <DatePicker
                            placeholder={t('SELECT_DATE')}
                            format={DATE_FORMAT}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col md={12}>
                      <FormItem label={t('ID_PASSPORT_NUMBER')}>
                        {getFieldDecorator('idPassportNumber', {
                          initialValue: record.idPassportNumber
                        })(
                          <TextInput
                            name="idPassportNumber"
                            setFieldsValue={setFieldsValue}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row gutter={20}>
                    <Col md={12}>
                      <FormItem label={t('DISPLAY_NAME')}>
                        {getFieldDecorator('displayName', {
                          rules: [
                            {
                              required: true,
                              message: t('INPUT_GIVEN_NAME_MSG')
                            }
                          ],
                          initialValue: record.displayName
                        })(
                          <TextInput
                            name="displayName"
                            setFieldsValue={setFieldsValue}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col md={12}>
                      <FormItem label={t('LEGAL_NAME')}>
                        {getFieldDecorator('legalName', {
                          rules: [
                            {
                              required: true,
                              message: t('INPUT_GIVEN_NAME_MSG')
                            }
                          ],
                          initialValue: record.legalName
                        })(
                          <TextInput
                            name="legalName"
                            setFieldsValue={setFieldsValue}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col md={12}>
                      <FormItem label={t('COMPANY_REGISTRATION_NUMBER')}>
                        {getFieldDecorator('companyRegistrationNumber', {
                          initialValue: record.companyRegistrationNumber
                        })(
                          <TextInput
                            name="companyRegistrationNumber"
                            setFieldsValue={setFieldsValue}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col md={12}>
                      <FormItem label={t('COUNTRY_TERRITORY_OF_INCORPORATION')}>
                        {getFieldDecorator('countryTerritoryOfIncorporation', {
                          initialValue: record.countryTerritoryOfIncorporation
                        })(
                          <Select allowClear showSearch>
                            {countries.all
                              .filter(c => c.status === 'assigned')
                              .map(c => (
                                <Select.Option key={c.name} value={c.name}>
                                  {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </>
              )}
              <Divider />
            </Col>
          </Row>
          <Row gutter={40}>
            <Col md={6} style={{ marginTop: 8 }}>
              <H4>{t('CONTACT_INFO')}</H4>
            </Col>
            <Col md={18}>
              <FormItem label={t('EMAILS')}>
                <AddMultipleFields
                  name="emails"
                  fields={fieldsWithDefaultNames}
                  customPlaceholders={{ value: 'email@example.com' }}
                  getFieldDecorator={getFieldDecorator}
                  setFieldsValue={setFieldsValue}
                  value={dynamicFieldsValue.emails}
                  customRules={[
                    {
                      fieldKey: 'value',
                      rules: [
                        {
                          type: 'email',
                          message: t('INVALID_EMAIL_MSG')
                        },
                        {
                          validator: async (rule, value) => {
                            const listEmailFromContact = activeContacts
                              .reduce((accumulator, currentValue) => {
                                return [
                                  ...accumulator,
                                  ...(currentValue.emails?.map(
                                    email => email.value
                                  ) || [])
                                ]
                              }, [])
                              .filter(
                                email =>
                                  !record.emails
                                    ?.map(e => e.value)
                                    .includes(email)
                              )
                            if (value && listEmailFromContact.includes(value)) {
                              throw new Error('Email already exists!')
                            }
                          }
                        }
                      ]
                    }
                  ]}
                />
              </FormItem>
              <FormItem label={t('ADDRESSES')}>
                <AddMultipleFields
                  name="addresses"
                  fields={fieldsWithDefaultNames}
                  customPlaceholders={{ value: t('ADDRESS') }}
                  getFieldDecorator={getFieldDecorator}
                  value={dynamicFieldsValue.addresses}
                  setFieldsValue={setFieldsValue}
                  isTextArea={true}
                />
              </FormItem>
              <FormItem label={t('PHONE_NUMBERS')}>
                <AddMultipleFields
                  name="phoneNumbers"
                  title="phone numbers"
                  fields={fieldsWithDefaultNames}
                  customPlaceholders={{ value: t('PHONE_NUMBER') }}
                  getFieldDecorator={getFieldDecorator}
                  value={dynamicFieldsValue.phoneNumbers}
                  setFieldsValue={setFieldsValue}
                  customRules={[
                    {
                      fieldKey: 'value',
                      rules: [
                        {
                          validator: (rule, value, callback) => {
                            if (value && !value.match(PHONE_NUMBER_REGEX)) {
                              callback(t('INVALID_MOBLIE_NUMBER'))
                            } else {
                              callback()
                            }
                          }
                        }
                      ]
                    }
                  ]}
                />
              </FormItem>
              <FormItem
                label={
                  <span>
                    {t('REFERENCES')}&nbsp;
                    <Tooltip
                      title={getReferencesTooltip(ENTITY_TYPES.CONTACT)}
                      overlayClassName="references-tooltip"
                    >
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                <AddMultipleFields
                  name="references"
                  fields={FIELDS}
                  customPlaceholders={{
                    name: t('REFERENCE_LABEL'),
                    value: t('REFERENCE_INFO')
                  }}
                  getFieldDecorator={getFieldDecorator}
                  value={dynamicFieldsValue.references}
                  setFieldsValue={setFieldsValue}
                />
              </FormItem>
              <FormItem label={t('URL')}>
                {getFieldDecorator('url', {
                  initialValue: record.url
                })(<TextInput name="url" setFieldsValue={setFieldsValue} />)}
              </FormItem>
              <FormItem label={t('TAGS')}>
                <FileTags tags={tags} setTags={setTags} />
              </FormItem>
              <Divider />
            </Col>
          </Row>
          <Row gutter={40}>
            <Col md={6} style={{ marginTop: 8 }}>
              <H4>{t('NOTE')}</H4>
            </Col>
            <Col md={18}>
              <FormItem label={t('DESCRIPTION')}>
                {getFieldDecorator('description', {
                  initialValue: record.description
                })(
                  <Input.TextArea
                    onBlur={e =>
                      setFieldsValue({
                        description: sanitizeValue(e.target.value)
                      })
                    }
                    rows={4}
                    maxLength={2000}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>

        {!isLgUp && (
          <SiderActionButtons
            handleOk={handleOk}
            handleCancel={reset}
            isSaving={isSaving}
            width={280}
            isEdit={false && record._id}
            isMinor={isMinor}
            setIsMinor={setIsMinor}
            isScrolled={false}
            preExtra={
              <>
                <ContactLinks
                  linkedContacts={linkedContacts}
                  handleUnlinkContact={handleUnlinkContact}
                  handleLinkContacts={handleLinkContacts}
                  isReadonly={false}
                  isDisableAdd={true}
                  record={record}
                />
                <Divider />
                {!isReadonly && (
                  <>
                    <EventLinks
                      linkedEvents={linkedEvents}
                      handleUnlinkEvents={handleUnlinkEvents}
                      handleLinkEvents={handleLinkEvents}
                      isReadonly={false}
                      setLinkedEvents={setLinkedEvents}
                    />
                    <Divider />
                  </>
                )}
              </>
            }
          />
        )}
      </Content>

      {isLgUp && (
        <Sider
          width={320}
          theme="light"
          style={{
            background: 'white',
            borderLeft: '1px solid #E6E7EC',
            padding: '20px 20px 80px'
          }}
        >
          {/* Temporary hide the minor checkbox until there is versions list for Contact */}
          <SiderActionButtons
            handleOk={handleOk}
            handleCancel={reset}
            isSaving={isSaving}
            width={280}
            isEdit={false && record._id}
            isMinor={isMinor}
            setIsMinor={setIsMinor}
            preExtra={
              <>
                <ContactLinks
                  linkedContacts={linkedContacts}
                  handleUnlinkContact={handleUnlinkContact}
                  handleLinkContacts={handleLinkContacts}
                  isReadonly={false}
                  isDisableAdd={true}
                  record={record}
                />
                <Divider />
                {!isReadonly && (
                  <>
                    <EventLinks
                      linkedEvents={linkedEvents}
                      handleUnlinkEvents={handleUnlinkEvents}
                      handleLinkEvents={handleLinkEvents}
                      isReadonly={false}
                      setLinkedEvents={setLinkedEvents}
                    />
                    <Divider />
                  </>
                )}
              </>
            }
          />
        </Sider>
      )}
      <AddItemModal
        wrappedComponentRef={fr => (addItemFormRef = fr)}
        visible={!!activeDynamicField.name}
        handleOk={handleAddNewOption}
        handleCancel={() => setActiveDynamicField({ name: '' })}
        item={t('LABEL')}
        existingItems={dynamicFieldsOptions[activeDynamicField.name]}
      />
      <AddEventModal
        visible={addEventModalVisible}
        setVisible={setAddEventModalVisible}
        handleClose={() => setAddEventModalVisible(false)}
        linkedEvents={linkedEvents}
        setLinkedEvents={setLinkedEvents}
        eventDate={eventDate}
        eventRecurringValue={eventRecurringValue}
        eventDescription={eventDescription}
        fetchEvents={() => {}}
      />
    </Layout>
  )
}

const WrappedContactForm = Form.create({ name: 'ContactAddEdit' })(
  ContactAddEdit
)
export default withRouter(WrappedContactForm)
