export default [
  { code: 'XNYS', name: 'New York Stock Exchange' },
  { code: 'XNAS', name: 'NASDAQ Stock Exchange' },
  { code: 'XLON', name: 'London Stock Exchange' },
  { code: 'XTAM', name: 'Tokyo Stock Exchange' },
  { code: 'XSHG', name: 'Shanghai Stock Exchange' },
  { code: 'XHKG', name: 'Hong Kong Stock Exchange' },
  { code: 'XAMS', name: 'Euronext Amsterdam Stock Exchange' },
  { code: 'XSHE', name: 'Shenzhen Stock Exchange' },
  { code: 'XTSE', name: 'Toronto Stock Exchange' },
  { code: 'XBOM', name: 'Bombay Stock Exchange' },
  { code: 'XNSE', name: 'India National Stock Exchange' },
  { code: 'XSWX', name: 'Swiss Stock Exchange' },
  { code: 'XASX', name: 'Australia Stock Exchange' },
  { code: 'XKRX', name: 'South Korea Stock Exchange' },
  { code: 'XSTO', name: 'Stockholm Stock Exchange' },
  { code: 'XJSE', name: 'Johannesburg Stock Exchange' },
  { code: 'XMAD', name: 'Madrid Stock Exchange' },
  { code: 'XTAI', name: 'Taiwan Stock Exchange' },
  { code: 'BVMF', name: 'Brazil Stock Exchange' },
  { code: 'XFRA', name: 'Frankfurt Stock Exchange' },
  { code: 'XKLS', name: 'Malaysia Stock Exchange' },
  { code: 'XJAM', name: 'Jamaica Stock Exchange' },
  { code: 'XSGO', name: 'Santiago Stock Exchange' },
  { code: 'XBOG', name: 'Colombia Stock Exchange' },
  { code: 'XLIM', name: 'Peruvian Stock Exchange' },
  { code: 'XMEX', name: 'Mexican Stock Exchange' },
  { code: 'XBUE', name: 'Argentinian Stock Exchange' },
  { code: 'XCHG', name: 'Chittagong Stock Exchange' },
  { code: 'XCOL', name: 'Colombo Stock Exchange' },
  { code: 'XDHA', name: 'Dhaka Stock Exchange' },
  { code: 'XSTC', name: 'Ho Chi Minh Stock Exchange' },
  { code: 'XIDX', name: 'Indonesia Stock Exchange' },
  { code: 'XNZE', name: 'New Zealand Stock Exchange' },
  { code: 'XPHS', name: 'Philippine Stock Exchange' },
  { code: 'XSES', name: 'Singapore Exchange' },
  { code: 'XBKK', name: 'Stock Exchange of Thailand' },
  { code: 'XADS', name: 'Abu Dhabi Securities Exchange' },
  { code: 'XAMM', name: 'Amman Stock Exchange' },
  { code: 'ASEX', name: 'Athens Stock Exchange' },
  { code: 'XIST', name: 'Istanbul Stock Exchange' },
  { code: 'XCAS', name: 'Casablanca Stock Exchange' },
  { code: 'XBSE', name: 'Bucharest Stock Exchange' },
  { code: 'XBUD', name: 'Budapest Stock Exchange' },
  { code: 'XDFM', name: 'Dubai Financial Market' },
  { code: 'XCAI', name: 'Egyptian Exchange' },
  { code: 'XDUB', name: 'Irish Stock Exchange' },
  { code: 'XKAZ', name: 'Kazakhstan Stock Exchange' },
  { code: 'XLUX', name: 'Luxembourg Stock Exchange' },
  { code: 'MISX', name: 'Moscow Exchange' },
  { code: 'XNSA', name: 'Nigerian Stock Exchange' },
  { code: 'XOSL', name: 'Oslo Stock Exchange' },
  { code: 'XPAE', name: 'Palestine Securities Exchange' },
  { code: 'DSMD', name: 'Qatar Stock Exchange' },
  { code: 'XSAU', name: 'Saudi Stock Exchange' },
  { code: 'XMAU', name: 'Stock Exchange of Mauritius' },
  { code: 'XTEH', name: 'Tehran Stock Exchange' },
  { code: 'XTAE', name: 'Tel-Aviv Stock Exchange' },
  { code: 'UKEX', name: 'Ukrainian Exchange' },
  { code: 'XWAR', name: 'Warsaw Stock Exchange' },
  { code: 'XWBO', name: 'Vienna Stock Exchange' },
  { code: 'XTAG', name: 'Zagreb Stock Exchagne' },
  { code: 'XMAL', name: 'Malta Stock Exchange' },
  { code: 'XBAB', name: 'Barbados Stock Exchange' },
  { code: 'XBDA', name: 'Bermuda Stock Exchange' },
  { code: 'HSTC', name: 'Hanoi Stock Exchange' },
  { code: 'XBAH', name: 'Bahrain Stock Exchange' },
  { code: 'XBEY', name: 'Beirut Stock Exchange' },
  { code: 'XEUR', name: 'Eurex Germany' },
  { code: 'XKAR', name: 'Pakistan Stock Exchange' },
  { code: 'XNAI', name: 'Nairobi Securities Exchange' },
  { code: 'XHEL', name: 'Helsinki Stock Exchange' },
  { code: 'XRIS', name: 'Riga Stock Exchange' },
  { code: 'XPAR', name: 'Euronext Paris Exchange' },
  { code: 'XBRN', name: 'BX Swiss' },
  { code: 'XMIL', name: 'Italian Stock Exchange' },
  { code: 'XCSE', name: 'Copenhagen Stock Exchange' },
  { code: 'OTCM', name: 'OTC Markets U.S.' },
  { code: 'XASE', name: 'NYSE American Equities' },
  { code: 'AIMX', name: 'AIM Stock Exchange' },
  { code: 'ROCO', name: 'Taipei Exchange' },
  { code: 'XBEL', name: 'Belgrade Stock Exchange' },
  { code: 'XBUL', name: 'Bulgarian Stock Exchange' }
]
