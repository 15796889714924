import React, { useState, useContext } from 'react'
import { Form, Modal, Upload, Icon, Alert, message } from 'antd'
//import { StringResources } from '../../share/StringResources'
import FormItem from '../override/FormItem'
import moment from 'moment'
import uuidv4 from 'uuid/v4'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { importContacts } from '../../lib/pouchDb'
import { CONTACT_TYPES } from '../../share/Constants'
import ContactModel from '../../model/ContactModel'
import { onError } from '../../lib/sentry'
import * as vCard from 'vcf'
import { vcfToJsonMapper } from '../../share/importHelpers'
import { draggerProps, normFile, getFileParts } from '../../share/formHelpers'
import ContactListPreviewModal from './ContactListPreviewModal'
import { useTranslation, Trans } from 'react-i18next'
import { removeHtmlTags } from './../../share/helpers'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'

const { Dragger } = Upload

function ImportVCardModal({
  visible,
  setVisible,
  sourceContacts,
  setContactId,
  form
}) {
  const [isParsing, setIsParsing] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [contactListPreviewModalVisible, setContactListPreviewModalVisible] =
    useState(false)
  const [previewRecords, setPreviewRecords] = useState([])
  const [invalidRecordsCount, setInvalidRecordsCount] = useState(0)
  const { t } = useTranslation()

  const [addS3Change] = useMutation(createS3Change)

  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)

  const { getFieldDecorator } = form

  const handleVCardLoad = async e => {
    try {
      setIsParsing(true)
      const cards = vCard.parse(e.target.result).map(vcfToJsonMapper)

      const validCards = cards.filter(item => item?.names?.givenName)

      const records = validCards.map(item => {
        const mappedItem = {
          ...item,
          type: CONTACT_TYPES.INDIVIDUAL,
          dateOfBirth: item.dateOfBirth && moment(item.dateOfBirth),
          givenName: item.names.givenName,
          surName: item.names.surName
        }

        const newRecord = { ...mappedItem, _id: uuidv4() }

        return {
          // keep the _id, _rev, and some other fields which are not yet available in ContactModel (fullName, names),
          // might be added in the future
          ...newRecord,
          ...new ContactModel(newRecord)
        }
      })
      setIsParsing(false)
      removeHtmlTags(records)

      if (cards.length) {
        setPreviewRecords(records)
        setContactListPreviewModalVisible(true)
        setInvalidRecordsCount(cards.length - validCards.length)
      } else {
        resetModal()
      }
    } catch (err) {
      onError(err)
      setErrMsg(t('FAILED_TO_IMPORT_DATA'))
    }
  }

  const handleOk = () => {
    setErrMsg('')
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      const file = values.file[0].originFileObj
      const reader = new FileReader()
      reader.onload = e => handleVCardLoad(e)
      reader.readAsText(file)
    })
  }

  const resetModal = () => {
    form.resetFields()
    setVisible(false)
    setErrMsg('')
    setContactListPreviewModalVisible(false)
  }

  const handlePreviewOk = async selectedContacts => {
    if (selectedContacts.length) {
      await importContacts(user.username, selectedContacts, masterKey)
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'contacts',
          userId: user.username
        }
      })
      resetModal()
      setContactId('')
      message.success(
        <Trans
          i18nKey="SUCCESSFULLY_IMPORT_CONTACTS"
          values={{ selectedContacts: selectedContacts?.length }}
        ></Trans>
      )
    } else {
      resetModal()
    }
  }

  return (
    <>
      <Modal
        title={t('IMPORT_FROM_VCARD')}
        visible={visible}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={handleOk}
        onCancel={resetModal}
        okButtonProps={{ loading: isParsing }}
        maskClosable={false}
      >
        <Form className="upload-form">
          <FormItem label={t('FILE')}>
            {getFieldDecorator('file', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              rules: [
                {
                  required: true,
                  message: t('SELECT_FILE_TO_UPLOAD_MSG')
                },
                {
                  validator: (rule, value, callback) => {
                    if (!value[0]?.originFileObj?.name) callback()

                    const { extension } = getFileParts(
                      value[0].originFileObj.name
                    )

                    if (extension !== 'vcf') {
                      callback(t('INVALID_FILE_TYPE'))
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <Icon type="upload" />
                </p>
                <p className="ant-upload-hint">
                  {t('UPLOAD_FILE_INSTRUCTION')}
                </p>
              </Dragger>
            )}
          </FormItem>
        </Form>
        {errMsg && (
          <Alert
            style={{ marginTop: 10 }}
            description={errMsg}
            closable
            afterClose={() => setErrMsg('')}
            type="error"
          />
        )}
      </Modal>
      <ContactListPreviewModal
        visible={contactListPreviewModalVisible}
        contacts={previewRecords}
        invalidRecordsCount={invalidRecordsCount}
        handleOk={handlePreviewOk}
        handleCancel={e => {
          setContactListPreviewModalVisible(false)
        }}
      />
    </>
  )
}

const WrappedImportVCardModal = Form.create({ name: 'ImportVCardModal' })(
  ImportVCardModal
)
export default WrappedImportVCardModal
