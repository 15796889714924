import React, { useState, useEffect } from 'react'
import { Input, Icon } from 'antd'
import Button from '../override/Button'
import FormItem from '../override/FormItem'
import TextInput from '../common/TextInput'
import { useTranslation } from 'react-i18next'
import { sanitizeValue } from '../../share/helpers'

export default function AddMultipleFields(props) {
  const {
    fields,
    name,
    title,
    getFieldDecorator,
    setFieldsValue,
    value,
    customRules,
    customPlaceholders,
    isTextArea
  } = props
  const [rowIds, setRowIds] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    setRowIds(value ? value.map((item, index) => index) : [])
  }, [value])

  const add = () => {
    const newRowId = rowIds.length ? rowIds[rowIds.length - 1] + 1 : 0
    setRowIds([...rowIds, newRowId])
  }

  const remove = rowId => {
    setRowIds(rowIds.filter(id => id !== rowId))
  }

  return (
    <>
      {rowIds.map(rowId => {
        return (
          <div key={rowId} className="dynamic-field-row">
            {fields.map((field, index) => {
              const customRule =
                customRules &&
                customRules.find(rule => rule.fieldKey === field.key)

              return (
                <FormItem className="dynamic-field" key={index}>
                  {getFieldDecorator(`${name}[${rowId}][${field.key}]`, {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: `${t('ENTER')} ${field.key}.`
                      },
                      ...((customRule && customRule.rules) || [])
                    ],
                    initialValue:
                      (value && value[rowId] && value[rowId][field.key]) ||
                      (field.default && field.default(name))
                  })(
                    field.node ? (
                      field.node(name, rowId)
                    ) : isTextArea ? (
                      <Input.TextArea
                        onBlur={e =>
                          setFieldsValue({
                            [`${name}[${rowId}][${field.key}]`]: sanitizeValue(
                              e.target.value
                            )
                          })
                        }
                        maxLength={2000}
                        rows={4}
                        key={field.key}
                        placeholder={
                          (customPlaceholders &&
                            customPlaceholders[field.key]) ||
                          field.placeholder
                        }
                      />
                    ) : (
                      <TextInput
                        key={field.key}
                        placeholder={
                          (customPlaceholders &&
                            customPlaceholders[field.key]) ||
                          field.placeholder
                        }
                        name={`${name}[${rowId}][${field.key}]`}
                        setFieldsValue={setFieldsValue}
                      />
                    )
                  )}
                </FormItem>
              )
            })}
            <Icon type="minus-circle-o" onClick={() => remove(rowId)} />
          </div>
        )
      })}

      <Button
        size="large"
        type="dashed"
        onClick={add}
        style={{ width: '100%' }}
      >
        <Icon type="plus" /> {t('ADD')} {title || name}
      </Button>
    </>
  )
}
