import React, { Fragment, useContext, useEffect, useState } from 'react'
import { message, Icon, Modal, Input, Alert } from 'antd'
import { useTranslation } from 'react-i18next'
import { onError } from '../../lib/sentry'
import copy from 'copy-to-clipboard'
import { AES, enc } from 'crypto-js'
import VaultContext from '../../contexts/VaultContext'
import { H3, P, Span, StatusText } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import { useSelector } from 'react-redux'
import FormItem from '../override/FormItem'
import MfaForm from '../common/MfaVerificationForm'
import WrappedForm from '../common/WrappedForm'
import { errorMessage } from '../../share/helpers'
import AuthContext from '../../contexts/AuthContext'
import { AUTH_FLOW, MFA_TYPES } from '../../share/Constants'
import { getAuthenticationDetails } from '../../lib/cognito'

let formRef, passwordFormRef, authDetails, cognitoUser, callbacks
const ByRecoveryKey = () => {
  const theme = useContext(ThemeContext)
  const { masterKey } = useContext(VaultContext)
  const { user } = useContext(AuthContext)
  const [errMsg, setErrMsg] = useState('')
  const [mfaType, setMfaType] = useState()
  const [recoveryKey, setRecoveryKey] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [mfaModalVisible, setMfaModalVisible] = useState(false)
  const [isResendEmailCode, setIsResendEmailCode] = useState(false)
  const [isShowRecoveryKey, setIsShowRecoveryKey] = useState(false)
  const [passwordModalVisible, setPasswordModalVisible] = useState(false)

  const { t } = useTranslation()

  const { encryptedRecoveryKey, currentFlow } = useSelector(
    state => state.user
  ).user

  useEffect(() => {
    if (masterKey && encryptedRecoveryKey) {
      const recoveryKey = AES.decrypt(encryptedRecoveryKey, masterKey).toString(
        enc.Latin1
      )
      setRecoveryKey(recoveryKey)
    }
  }, [masterKey, encryptedRecoveryKey])

  const copyToClipboard = () => {
    try {
      copy(recoveryKey)
      message.success(t('Copied!'))
    } catch (error) {
      onError(error)
    }
  }

  const authenticateUser = (resolve, reject) => {
    const authenticationDetails = getAuthenticationDetails(
      authDetails.username,
      authDetails.password
    )

    if (currentFlow === AUTH_FLOW.CUSTOM_FLOW) {
      setIsResendEmailCode(true)
      user.setAuthenticationFlowType('CUSTOM_AUTH')
    }

    user.authenticateUser(authenticationDetails, {
      onSuccess: async function (result) {
        try {
          setIsShowRecoveryKey(true)
          resetFields()
          setMfaModalVisible(false)
        } catch (err) {
          reject(new Error(t('Failed to reveal recovery key')))
          onError(err)
        } finally {
          setIsSubmitting(false)
        }
      },

      onFailure: function (err) {
        setErrMsg(t('FAILED_TO_AUTHENTICATE_USER'))
        setIsSubmitting(false)
        reject(err)
      },
      customChallenge: function () {
        callbacks = this
        setMfaType(MFA_TYPES.EMAIL)
        setIsSubmitting(false)
        setMfaModalVisible(true)
        setIsResendEmailCode(false)
      },
      selectMFAType: function () {
        user.sendMFASelectionAnswer(MFA_TYPES.TOTP, this)
      },

      mfaRequired: function () {
        callbacks = this
        setMfaType(MFA_TYPES.SMS)
        setMfaModalVisible(true)
      },

      // Require if user have setup MFA successfully
      totpRequired: function () {
        callbacks = this
        setMfaType(MFA_TYPES.TOTP)
        setMfaModalVisible(true)
      }
    })
  }

  const handleConfirmPassword = () => {
    setErrMsg('')

    return new Promise((resolve, reject) => {
      passwordFormRef.props.form.validateFields((err, values) => {
        if (err) {
          // TODO: this will close the modal immediately and can't see the validation error
          resolve(t('INVALID_FORM'))
          return
        }

        cognitoUser = user
        authDetails = { username: user.username, password: values.password }

        authenticateUser(resolve, reject)
      })
    })
  }

  const resetFields = () => {
    setErrMsg('')
    formRef && formRef.props.form.resetFields()
    passwordFormRef && passwordFormRef.props.form.resetFields()
    passwordModalVisible && setPasswordModalVisible(false)
    mfaModalVisible && setMfaModalVisible(false)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <H3>{t('By recovery key')}</H3>
        <div>
          {isShowRecoveryKey && (
            <Span
              color={theme.primary}
              style={{ cursor: 'pointer' }}
              onClick={() => setIsShowRecoveryKey(false)}
            >
              <Icon type="eye-invisible" style={{ marginRight: 8 }} />
              {t('Hide key')}
            </Span>
          )}

          {!isShowRecoveryKey && (
            <Span
              color={theme.primary}
              style={{ cursor: 'pointer', marginRight: 10 }}
              onClick={() => setPasswordModalVisible(true)}
            >
              <Icon type="eye" style={{ marginRight: 8 }} />
              {t('Reveal key')}
            </Span>
          )}
        </div>
      </div>
      <StatusText color={theme.dark2}>
        {isShowRecoveryKey ? (
          <Fragment>
            <Span>{recoveryKey}</Span>
            <Span
              color={theme.primary}
              style={{ cursor: 'pointer', marginLeft: 5 }}
              onClick={() => copyToClipboard()}
            >
              <Icon type="copy" style={{ marginRight: 8 }} />
            </Span>
          </Fragment>
        ) : (
          '***************'
        )}
      </StatusText>

      {/* mfa authentication modal */}
      <Modal
        visible={mfaModalVisible}
        footer={null}
        closable
        onCancel={resetFields}
      >
        <MfaForm
          wrappedComponentRef={ref => (formRef = ref)}
          cognitoUser={cognitoUser}
          mfaType={mfaType}
          callbacks={callbacks}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          handleResend={authenticateUser}
          isResending={isResendEmailCode}
        />
      </Modal>

      {/* Password modal */}
      <Modal
        visible={passwordModalVisible}
        title={t('Reveal recovery key')}
        okText={t('Reveal')}
        onOk={async e => {
          try {
            await handleConfirmPassword()
          } catch (e) {
            onError(e)
            setErrMsg(errorMessage(e))
          }
        }}
        onCancel={resetFields}
      >
        <WrappedForm wrappedComponentRef={fr => (passwordFormRef = fr)}>
          {getFieldDecorator => (
            <Fragment>
              <P>{t('ENTER_YOUR_PASSWORD_TO_PROCEED')}: </P>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Password is required!'
                    }
                  ]
                })(<Input.Password placeholder={t('ENTER_PASSWORD')} />)}
              </FormItem>
            </Fragment>
          )}
        </WrappedForm>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            style={{ marginBottom: 16 }}
          />
        )}
      </Modal>
    </>
  )
}

export default ByRecoveryKey
