import React, { useState, useContext } from 'react'
import { Select, Tooltip } from 'antd'
import Button from '../override/Button'
import FormItem from '../override/FormItem'
import LocationModal from '../location/LocationModal'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// import { fetchLocations } from './../../features/location/LocationSlice'
import VaultContext from '../../contexts/VaultContext'

export default function LocationSelect({
  label,
  placeholder,
  required,
  getFieldDecorator,
  fieldName,
  initialValue,
  mode,
  onAddComplete,
  disabled,
  setLocation
}) {
  const [locationModalVisibile, setLocationModalVisibile] = useState(false)

  const { isReadonly } = useContext(VaultContext)
  const { t } = useTranslation()

  const { activeLocations } = useSelector(state =>
    isReadonly ? state.otherLocations : state.locations
  )
  // const dispatch = useDispatch()

  const setValue = value => {
    if (value && activeLocations?.length) {
      const locationRecord = activeLocations.find(item => item._id === value)
      if (locationRecord && setLocation) {
        setLocation(locationRecord)
      }
    } else {
      setLocation && setLocation({})
    }
  }

  return (
    <div>
      <FormItem label={label}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 'calc(100% - 58px)' }}>
            {getFieldDecorator(fieldName, {
              initialValue,
              rules: [
                {
                  required: required,
                  message: label && `${t('ENTER')} ${label.toLowerCase()}`
                }
              ]
            })(
              <Select
                mode={mode}
                placeholder={
                  placeholder || `${t('SELECT')} ${label.toLowerCase()}`
                }
                allowClear={!mode}
                showSearch
                onChange={value => setValue(value)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .includes(input.trim().toLowerCase())
                }
                disabled={disabled}
              >
                {[...activeLocations]
                  .sort((a, b) => a.level1.localeCompare(b.level1))
                  .map(item => (
                    <Select.Option key={item._id}>
                      {item.level1}
                      {item.level2 && `- ${item.level2}`}
                      {item.level3 && `- ${item.level3}`}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </div>
          <Tooltip title={t('ADD_NEW')}>
            <Button
              size="large"
              onClick={() => setLocationModalVisibile(true)}
              className={`${label ? '' : 'no-label'}`}
              style={{ marginLeft: 8 }}
              icon="plus"
              disabled={disabled}
            />
          </Tooltip>
        </div>
      </FormItem>
      <LocationModal
        locations={activeLocations}
        selectedLocation={{}}
        visible={locationModalVisibile}
        setVisible={setLocationModalVisibile}
        handleClose={() => {
          setLocationModalVisibile(false)
        }}
        onAddComplete={onAddComplete}
      />
    </div>
  )
}
