import React from 'react'
import { validateInputCode } from '../../share/formHelpers'
import FormItem from '../override/FormItem'
import TextInput from '../common/TextInput'
import { useTranslation } from 'react-i18next'

export default function CodeInput(props) {
  const { getFieldDecorator, handleKeyUp, extra, style } = props
  const { t } = useTranslation()

  return (
    <FormItem>
      {getFieldDecorator('code', {
        rules: [
          {
            required: true,
            message: t('CODE_IS_REQUIRED')
          },
          {
            validator: validateInputCode
          }
        ]
      })(
        <TextInput
          placeholder={t('CODE')}
          onKeyUp={handleKeyUp}
          allowClear
          autoFocus
          style={style}
        />
      )}
      {extra}
    </FormItem>
  )
}
