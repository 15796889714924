import React from 'react'
import { Row, Col, Divider } from 'antd'
import { H4, Span, B } from '../override/Typography'
import { useTranslation } from 'react-i18next'
import ShowIamge from '../common/ShowImage'

function Field({ title, value, recordId, level }) {
  return (
    <Row style={{ fontSize: 12, padding: '3px 0' }} gutter={8}>
      <Col span={8}>
        <Span>{title}</Span>
      </Col>
      <Col span={16} style={{ textAlign: 'left' }}>
        <ShowIamge width={40} recordId={recordId} level={level} />
        <span style={{ marginRight: 10 }}></span>
        <B>{value}</B>
      </Col>
    </Row>
  )
}

export default function LocationInformation({ record, extra }) {
  const { t } = useTranslation()
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10
        }}
      >
        <H4>{t('LOCATION_INFORMATION')}</H4>
        {extra}
      </div>
      <Divider />
      <Field
        title={'Level 1'}
        recordId={record._id}
        level={1}
        value={record.level1}
      />
      <Field
        title={'Level 2'}
        recordId={record._id}
        level={2}
        value={record.level2}
      />
      <Field
        title={'Level 3'}
        recordId={record._id}
        level={3}
        value={record.level3}
      />
    </>
  )
}
