import { createSlice } from '@reduxjs/toolkit'
import api from '../../lib/api'
import NodeRSA from 'node-rsa'
import { message } from 'antd'
import { onError } from '../../lib/sentry'
import i18next from 'i18next'
import { fetchUser } from '../user/userSlice'

const initialState = {
  assistants: [],
  error: null,
  isLoading: false,
  distributeError: null
}

const assistants = createSlice({
  name: 'assistants',
  initialState,
  reducers: {
    getAssistantsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getAssistantsSuccess(state, action) {
      state.assistants = action.payload.assistants
      state.isLoading = false
      state.error = null
    },
    getAssistantsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    distributeRecoveryShareKeysStart(state) {
      state.isLoading = true
      state.distributeError = null
    },
    distributeRecoveryShareKeysSuccess(state) {
      state.isLoading = false
      state.distributeError = null
    },
    distributeRecoveryShareKeysFailure(state, action) {
      state.isLoading = false
      state.distributeError = action.payload
    },
    assistantActionStart(state) {
      state.isLoading = true
    },
    assistantActionSuccess(state, action) {
      state.isLoading = false
      message.success(action.payload)
    },
    assistantActionFailure(state, action) {
      state.isLoading = false
      message.error(action.payload)
    }
  }
})

export const {
  getAssistantsStart,
  getAssistantsSuccess,
  getAssistantsFailure,
  distributeRecoveryShareKeysStart,
  distributeRecoveryShareKeysSuccess,
  distributeRecoveryShareKeysFailure,
  assistantActionStart,
  assistantActionSuccess,
  assistantActionFailure
} = assistants.actions

export default assistants.reducer

export const fetchAssistants = userId => async dispatch => {
  try {
    dispatch(getAssistantsStart())
    const response = await api.getUser(userId)
    const { assistants } = response.data

    dispatch(getAssistantsSuccess({ assistants }))
  } catch (err) {
    onError(err)
    dispatch(
      getAssistantsFailure(i18next.t('Failed to load recovery assistant'))
    )
  }
}

export const distributeRecoveryShareKey =
  (userId, masterKey, assistantsInfo) => async dispatch => {
    try {
      dispatch(distributeRecoveryShareKeysStart())

      let listInfo = []

      await assistantsInfo.forEach(d => {
        const key = new NodeRSA()
        key.importKey(d.publicKey, 'public')
        const shareKey = key.encrypt(masterKey, 'base64')
        listInfo.push({ shareKey, userId: d.userId, email: d.email })
      })

      await api.distributeRecoveryShare(userId, JSON.stringify({ listInfo }))

      dispatch(distributeRecoveryShareKeysSuccess())
      dispatch(fetchAssistants(userId))
    } catch (err) {
      onError(err)
      dispatch(
        distributeRecoveryShareKeysFailure(
          i18next.t('FAILED_TO_LOAD_DISTRIBUTE_KEYS')
        )
      )
    }
  }

export const revokeAssistant = (userId, assistant) => async dispatch => {
  try {
    dispatch(assistantActionStart())

    const revokeData = {
      email: assistant.email
    }

    const response = await api.revokeAssistant(
      userId,
      JSON.stringify(revokeData)
    )
    if (!response.data || !response.data.success) {
      throw response.data.message
    }

    dispatch(fetchAssistants(userId))
    dispatch(fetchUser(userId))
    dispatch(assistantActionSuccess(i18next.t('SUCCESSFULLY_REVOKED_DEPUTY')))
  } catch (err) {
    onError(err)
    dispatch(assistantActionFailure(i18next.t('FAILED_TO_REVOKE_DEPUTY')))
  }
}

export const resendAssistantRequest =
  (userId, email) => async dispatch => {
    try {
      dispatch(assistantActionStart())
      await api.requestAssistant(
        userId,
        JSON.stringify({ emails: [email] })
      )

      dispatch(
        assistantActionSuccess(i18next.t('SUCCESSFULLY_RESENT_DEPUTY_REQUEST'))
      )
    } catch (err) {
      onError(err)
      dispatch(
        assistantActionFailure(i18next.t('FAILED_TO_RESENT_DEPUTY_REQUEST'))
      )
    }
  }
