import React from 'react'
import { Icon } from 'antd'
import { useTranslation } from 'react-i18next'
import { RECURRING } from '../../share/Constants'

const ConvertToEvent = props => {
  const {
    name,
    date,
    setEventDate,
    setAddEventModalVisible,
    setEventRecurringValue,
    setEventDescription,
    label
  } = props

  const { t } = useTranslation()
  const handleClick = () => {
    if(label === t('DATE_OF_BIRTH')){
      setEventRecurringValue(RECURRING.YEARLY)
    }
    if(label === t('RECURRING_PAYMENT_DUE_DAY')){
      setEventRecurringValue(RECURRING.MONTHLY)
    }
    setAddEventModalVisible(true)
    setEventDescription(label + ' - ' + name)
    setEventDate(date)
  }

  return (
    <>
      {date && name && (
        <>
          {' '}
          <Icon onClick={handleClick} type="calendar" />
        </>
      )}
    </>
  )
}

export default ConvertToEvent
