import React, { useContext } from 'react'
import { DATE_FORMAT } from '../../share/Constants'
import { ThemeContext } from 'styled-components'
import { Modal, Col, Row, DatePicker, Spin } from 'antd'
import { disabledDate, renderDateTime, showLog } from '../../share/helpers'
import { StatusText } from '../override/Typography'
import Button from '../override/Button'
import ShowLogIcon from '../common/ShowLogIcon'

const LogModal = props => {
  const {
    visible,
    setVisible,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    documentActivities,
    isLoading
  } = props
  const theme = useContext(ThemeContext)

  return (
    <Modal
      title={'Activities'}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button type="primary" onClick={() => setVisible(false)}>
          {'OK'}
        </Button>
      ]}
    >
      <Spin spinning={isLoading}>
        <Row gutter={10} style={{ marginLeft: 0, marginBottom: 20 }}>
          <Col span={12}>
            <DatePicker
              onChange={setStartDate}
              placeholder={'FROM'}
              value={startDate}
              disabledDate={startDate => disabledDate(startDate, endDate)}
              format={DATE_FORMAT}
            />
          </Col>
          <Col span={12}>
            <DatePicker
              onChange={setEndDate}
              placeholder={'TO'}
              value={endDate}
              disabledDate={endDate => disabledDate(startDate, endDate)}
              format={DATE_FORMAT}
            />
          </Col>
        </Row>
        {documentActivities.map(a => (
          <div
            key={a.Id}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 20
            }}
          >
            <div
              style={{
                height: 36,
                width: 36,
                minWidth: 36,
                backgroundColor: theme.dark3,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 14
              }}
            >
              <ShowLogIcon item={a} />
            </div>
            <div>
              {showLog(a)}
              <StatusText color={theme.dark2}>
                {renderDateTime(a.Time)}
              </StatusText>
            </div>
          </div>
        ))}
      </Spin>
    </Modal>
  )
}

export default LogModal
