import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'

const initialState = {
  passwords: [],
  deletedPasswords: [],
  activePasswords: [],
  isLoading: false,
  error: null
}

const passwords = createSlice({
  name: 'passwords',
  initialState,
  reducers: {
    getOtherPasswordsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getOtherPasswordsSuccess(state, action) {
      const { passwords } = action.payload
      state.passwords = passwords
      state.deletedPasswords = passwords.filter(item => item.deleted)
      state.activePasswords = passwords.filter(item => !item.deleted)
      state.isLoading = false
      state.error = null
    },
    getOtherPasswordsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const {
  getOtherPasswordsStart,
  getOtherPasswordsSuccess,
  getOtherPasswordsFailure
} = passwords.actions

export default passwords.reducer

export const fetchOtherPasswords = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getOtherPasswordsStart())
    const passwords = await getRecords(userId, 'passwords', masterKey)
    dispatch(getOtherPasswordsSuccess({ passwords }))
  } catch (err) {
    onError(err)
    dispatch(getOtherPasswordsFailure(err.toString()))
  }
}
