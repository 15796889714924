import React, { Fragment, useContext } from 'react'
import { Button, Empty, message, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { onError } from '../../lib/sentry'
import AuthContext from '../../contexts/AuthContext'
import api from '../../lib/api'
import { ThemeContext } from 'styled-components'
import TableHeader from '../common/TableHeader'
import { AES, enc } from 'crypto-js'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import VaultContext from '../../contexts/VaultContext'
import NodeRSA from 'node-rsa'
import { randomBytes } from 'crypto'

const RecoveryRequest = props => {
  const {
    fetchResetRequests,
    resetRequests,
    resetRequestsLoading,
    setResetRequestsLoading
  } = props
  const theme = useContext(ThemeContext)
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)

  const { t } = useTranslation()

  const handleApprove = record => {
    getUserData(user, (err, data) => {
      if (err) {
        onError(err)
        return
      }

      setResetRequestsLoading(true)

      const encryptedPrivateKey = getUserAttributeValue(
        data.UserAttributes,
        'custom:private_key'
      )

      const privateKey = AES.decrypt(encryptedPrivateKey, masterKey).toString(
        enc.Latin1
      )

      const key = new NodeRSA()

      key.importKey(privateKey, 'pkcs8')

      const rawMasterKey = key.decrypt(record.shareKey, 'ascii')

      // encrypt raw master key with generated temp password
      const tempPassword = randomBytes(8).toString('hex')
      const encryptedMasterKey = AES.encrypt(
        rawMasterKey,
        tempPassword
      ).toString()

      handleResetRequest(true, record.key, encryptedMasterKey, tempPassword)
    })
  }

  const handleResetRequest = async (
    isApproved,
    assistedUserId,
    masterKey,
    tempPassword
  ) => {
    const handleData = {
      isApproved,
      assistedUserId,
      masterKey,
      tempPassword
    }

    setResetRequestsLoading(true)
    
    api
      .handleRecoveryResetRequest(user.username, JSON.stringify(handleData))
      .then(response => {
        if (response.data && response.data.success) {
          message.success(
            isApproved ? t('REQUEST_APPROVED') : t('REQUEST_REJECTED')
          )
          fetchResetRequests()
        }
      })
      .catch(err => {
        message.error(
          isApproved
            ? t('FAILED_TO_APPROVE_REQUEST')
            : t('FAILED_TO_REJECT_REQUEST')
        )
        console.log('Failed to handle reset request: ', err)
        onError(err)
      })
  }

  const resetRequestsColumns = [
    {
      title: t('FROM'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      key: 'action',
      render: (text, record) => (
        <div style={{ textAlign: 'right' }}>
          <Button
            style={{ color: theme.primay }}
            onClick={() => handleApprove(record)}
          >
            {t('APPROVE')}
          </Button>
          <Button
            onClick={() => handleResetRequest(false, record.key, record.email)}
            style={{ color: theme.red, marginLeft: 8 }}
          >
            {t('REJECT')}
          </Button>
        </div>
      )
    }
  ]

  return (
    <Fragment>
      <TableHeader
        title={t('REQUESTS_TO_RESET_THEIR_PASSWORD')}
        count={resetRequests.length}
      />
      <Table
        columns={resetRequestsColumns}
        dataSource={resetRequests}
        scroll={{ x: true }}
        loading={resetRequestsLoading}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_REQUESTS')}
            />
          )
        }}
        showHeader={!!resetRequests.length}
      />
    </Fragment>
  )
}

export default RecoveryRequest
