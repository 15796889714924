import React, { useContext, useState, useEffect } from 'react'
import { Card, Alert, Tooltip, Radio } from 'antd'
import { H4 } from '../override/Typography'
import Button from '../override/Button'
import CustomIcon from '../override/Icon'
import { ThemeContext } from 'styled-components'
import config from '../../config'
// import FormItem from '../override/FormItem'
//import { StringResources } from './../../share/StringResources'
import moment from 'moment'
import { GAevent } from '../../lib/ga'
import { useTranslation, Trans } from 'react-i18next'
// import TextInput from './../common/TextInput'
// import { sanitizeValue } from '../../share/helpers'
import { currencyFormat, getPlanSubTitle } from './../../share/helpers'

export default function SubscriptionPlans(props) {
  const {
    // showReferralCodeInput,
    // showPromoCodeInput,
    plans,
    currentPlanId,
    handleCancelSubscription,
    handleSubscribe,
    subscriptionStatus,
    defaultSource,
    // referralCode,
    // setReferralCode,
    // promoCode,
    // setPromoCode,
    setSelectedPlan,
    isCancelAtEnd,
    handleReactivate,
    handleRelease,
    nextPhase,
    selectedPlan
  } = props
  const { defaultPlans } = plans
  const theme = useContext(ThemeContext)
  const { Meta } = Card
  const { t } = useTranslation()
  const [value, setValue] = useState()

  useEffect(() => {
    setValue(
      selectedPlan?.id ||
        Object.values(defaultPlans)
          .flat()
          .find(item => item.id === currentPlanId)?.id
    )
  }, [currentPlanId, defaultPlans, selectedPlan])

  const getPlanDescription = plan => {
    // const customPdPrice = customPdPlans.find(
    //   p => p.nickname === plan.nickname
    // )?.amount
    // const defaultPdPrice = defaultPdPlans.find(
    //   p => p.nickname === plan.nickname
    // )?.amount

    return [
      t('Includes everything in the Free plan'),
      // t('ASSETS_AND_LIABILITY_REGISTRY'),
      // t('TRACKING_NETWORTH'),
      t(
        `${
          plan.metadata?.storage === '1000'
            ? '1TB'
            : `${plan.metadata?.storage}GB`
        } secure storage`
      ),
      // t('SAVE_YOUR_IMPORTANT_CONTACT'),
      t(`Access sharing up to ${plan.metadata?.persons} users`)
      // t('BE_APPOINTED_AS_A_DEPUTY'),
      // <span>
      //   {t('ABILITY_TO_APPOINT_PD_AS_ADDON_AT')}
      //   {plan.metadata.discountCode && customPdPrice !== undefined ? (
      //     <>
      //       <span style={{ textDecoration: 'line-through' }}>
      //         US$ {defaultPdPrice / 100}
      //       </span>{' '}
      //       <span>US$ {customPdPrice / 100}</span>
      //     </>
      //   ) : (
      //     <span>US$ {defaultPdPrice / 100}</span>
      //   )}{' '}
      //   / {t(plan.interval)}
      // </span>
    ]
  }

  const PlanCard = ({
    title,
    isCurrentPlan,
    metaTitle,
    description,
    plan,
    isFree,
    isSignupPage,
    isCancelAtEnd,
    isComingSoon
  }) => {
    return (
      <Card
        title={
          // isComingSoon ? (
          //   <div>
          //     <i>{isComingSoon && 'Coming soon...'}</i>
          //   </div>
          // ) : (
          <>
            <H4 display="inline" color={theme.white}>
              {title}
            </H4>
            <span style={{ fontSize: '14px' }}>
              {!isSignupPage && isCurrentPlan && ' (current)'}
            </span>
            <span>
              {!isSignupPage &&
                !!nextPhase?.plans?.find(pl => pl.plan === plan?.id) &&
                ' (next)'}
            </span>
          </>
          // )
        }
        className="plan__cards"
        headStyle={{
          textAlign: 'center',
          backgroundColor: theme.primary,
          borderBottomColor: theme.primary,
          color: theme.white
        }}
        bodyStyle={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {!isComingSoon ? (
          <>
            <Meta
              style={{ flexGrow: 1 }}
              title={
                <div
                  style={{
                    textAlign: 'left',
                    margin: '20px 0px'
                  }}
                >
                  {metaTitle}
                </div>
              }
              description={description}
            />

            <div style={{ textAlign: 'center', marginTop: 20 }}>
              {isSignupPage ? (
                <Action isCurrentPlan={isCurrentPlan} plan={plan} />
              ) : (
                !isFree && (
                  <ModalAction
                    isCurrentPlan={isCurrentPlan}
                    planId={plan.id}
                    isCancelAtEnd={isCancelAtEnd}
                    changeDate={
                      !!nextPhase?.plans?.find(pl => pl.plan === plan.id) &&
                      nextPhase?.start_date
                    }
                  />
                )
              )}
            </div>
          </>
        ) : (
          <>
            <div style={{ lineHeight: '60px', textAlign: 'center' }}>
              <span>
                Please contact us at{' '}
                <a href="mailto:support@bantexapp.com">support@bantexapp.com</a>{' '}
                for customised plan
              </span>
            </div>
          </>
        )}
      </Card>
    )
  }

  const CardDescription = ({ content }) => {
    return (
      <div style={{ paddingBottom: 6 }}>
        <CustomIcon
          type="tick"
          style={{ color: theme.primary, marginRight: 8 }}
        />
        {content}
      </div>
    )
  }

  const ModalAction = ({
    isCurrentPlan,
    planId,
    isCancelAtEnd,
    changeDate
  }) => {
    return isCurrentPlan ? (
      isCancelAtEnd ? (
        <Button type="primary" onClick={handleReactivate}>
          {t('REACTIVATE')}
        </Button>
      ) : (
        <Button style={{ color: theme.red }} onClick={handleCancelSubscription}>
          {t('CANCEL')}
        </Button>
      )
    ) : changeDate ? (
      <Tooltip
        title={
          <Trans
            i18nKey="CHANGE_SUBSCRIPTION_PLAN_ON"
            values={{ changeDate: moment(changeDate * 1000).format('LL') }}
          ></Trans>
        }
      >
        <Button onClick={handleRelease}>{t('CANCEL_CHANGE')}</Button>
      </Tooltip>
    ) : (
      <Button
        type="primary"
        onClick={() => handleSubscribe(planId)}
        disabled={subscriptionStatus === 'past_due' && !defaultSource}
      >
        {t('SUBSCRIBE')}
      </Button>
    )
  }

  const Action = ({ isCurrentPlan, plan }) => (
    <Button
      type={isCurrentPlan ? 'primary' : 'default'}
      onClick={() => {
        if (!isCurrentPlan) {
          GAevent({
            category: 'Register',
            action: `Clicked ${plan?.nickname || 'Deputy Only'}`,
            label: 'Subscription plans'
          })
        }
        setSelectedPlan(
          isCurrentPlan
            ? {
                id: '',
                nickname: '',
                amount: 0,
                interval: '',
                currency: ''
              }
            : plan
            ? {
                id: plan.id,
                nickname: plan.nickname,
                amount: plan.amount,
                interval: plan.interval,
                currency: plan.currency
              }
            : {
                id: 'free',
                nickname: '2 GB Storage'
              }
        )

        setValue(plan?.id || 'free')
      }}
      icon={isCurrentPlan ? 'check' : null}
    >
      {isCurrentPlan ? t('SELECTED') : t('SELECT')}
    </Button>
  )

  const changePlan = (value, nextPlan, defaultPlan) => {
    setValue(value)
    if (setSelectedPlan) {
      const plan = nextPlan.id === value ? nextPlan : defaultPlan
      setSelectedPlan({
        id: plan.id,
        nickname: plan.nickname,
        amount: plan.amount,
        interval: plan.interval,
        currency: plan.currency
      })
    } else {
      handleSubscribe(value)
    }
  }

  return (
    <>
      {config.env !== 'prod' && (
        <Alert
          style={{
            marginBottom: 20
          }}
          description={<div>{t('SUBSCRIPTION_TESTING_NOTE')}</div>}
          type="info"
          showIcon
        />
      )}

      {/* {showReferralCodeInput && (
        <FormItem
          label={
            <span>
              {t('REFERRAL_CODE')}{' '}
              <Tooltip
                title={
                  <>
                    <div>{t('REFERRAL_CODE_TOOLTIP_FIRST_SUMMARY')}</div>
                    <div>{t('REFERRAL_CODE_TOOLTIP_SECOND_SUMMARY')}</div>
                    <div>{t('THIS_IS_NOT_APPLIED_TO_THE_FREE_PLAN')}</div>
                  </>
                }
              >
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          <TextInput
            type="text"
            placeholder={t('ENTER_REFERRAL_CODE')}
            onChange={e => setReferralCode(e.target.value)}
            onBlur={e => setReferralCode(sanitizeValue(e.target.value).trim())}
            value={referralCode}
            allowClear
          />
        </FormItem>
      )}
      {showPromoCodeInput && (
        <FormItem label={t('PROMOTION_CODE')}>
          <TextInput
            type="text"
            placeholder={t('ENTER_PROMOTION_CODE')}
            onChange={e => setPromoCode(e.target.value)}
            onBlur={e => setPromoCode(sanitizeValue(e.target.value).trim())}
            value={promoCode}
            allowClear
          />
        </FormItem>
      )} */}
      <div className="plan">
        {defaultPlans && (
          <PlanCard
            key="free"
            title={<span style={{ height: '99px' }}>{t('2 GB Storage ')}</span>}
            isCurrentPlan={currentPlanId === 'free'}
            metaTitle={t('FREE')}
            description={
              <>
                <CardDescription content={t('2GB secure storage')} />
                <CardDescription content={t('Free-to-use')} />
                <CardDescription content={t('Access sharing up to 3 users')} />
                <CardDescription content={t('Contacts Management')} />
                <CardDescription content={t('Calendar feature')} />
                <CardDescription content={t('Password vault')} />
                <CardDescription content={t('Location support')} />
              </>
            }
            isFree={true}
            isSignupPage={!!setSelectedPlan}
          />
        )}
        {Object.values(defaultPlans).map(plan => {
          const defaultPlan = plan[0]
          const nextPlan = plan[1]
          const style = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            fontSize: 15,
            fontWeight: 'bold'
          }

          return (
            <PlanCard
              style={{ padding: 10 }}
              key={defaultPlan.id}
              title={
                <>
                  <span>{t(defaultPlan.nickname)}</span>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 'normal',
                      marginBottom: 0
                    }}
                  >
                    {getPlanSubTitle(defaultPlan)}
                  </p>
                </>
              }
              isCurrentPlan={
                defaultPlan.id === currentPlanId ||
                nextPlan?.id === currentPlanId
              }
              metaTitle={
                <>
                  {defaultPlan.amount !== 0 ? (
                    <>
                      {/* <div>
                        {defaultPlan.currency.toUpperCase()}{' '}
                        <H4 display="inline">{defaultPlan.amount / 100}</H4> /{' '}
                        {t(defaultPlan.interval)}
                      </div>
                      <Button onClick={() => setCheckedPlan(nextPlan)} type="link">
                        Or {nextPlan.currency.toUpperCase()}{' '}
                        {nextPlan.amount / 100}/ {t(nextPlan.interval)}
                      </Button> */}
                      <Radio.Group
                        onChange={e =>
                          changePlan(e.target.value, nextPlan, defaultPlan)
                        }
                        value={value}
                      >
                        <Radio style={style} value={defaultPlan.id}>
                          {defaultPlan.currency.toUpperCase()}{' '}
                          {currencyFormat(defaultPlan.amount / 100)} /{' '}
                          {t(defaultPlan.interval)}
                        </Radio>
                        <Radio style={style} value={nextPlan.id}>
                          {nextPlan.currency.toUpperCase()}{' '}
                          {currencyFormat(nextPlan.amount / 100)} /{' '}
                          {t(nextPlan.interval)}
                        </Radio>
                      </Radio.Group>
                    </>
                  ) : (
                    <H4 display="inline">Free</H4>
                  )}

                  {/* {!!plan[0].metadata.discountCode && (
                  <div
                    style={{
                      textDecoration: 'line-through',
                      color: theme.dark2
                    }}
                  >
                    US${' '}
                    {defaultPlans.find(p => p.nickname === plan[0].nickname)
                      .amount / 100}{' '}
                    / {t(plan.interval)}
                  </div>
                )} */}
                </>
              }
              description={getPlanDescription(defaultPlan).map(
                (item, index) => (
                  <CardDescription content={item} key={index} />
                )
              )}
              isSignupPage={!!setSelectedPlan}
              // checkedPlan={checkedPlan}
              plan={defaultPlan}
              isCancelAtEnd={isCancelAtEnd}
              isComingSoon={false}
            />
          )
        })}

        {/* {(customPlans?.length ? customPlans : defaultPlans).map(plan => (
          <PlanCard
            key={plan.id}
            title={t(plan.nickname)}
            isCurrentPlan={plan.id === currentPlanId}
            metaTitle={
              <>
                <div>
                  US$ <H2 display="inline">{plan.amount / 100}</H2> /{' '}
                  {t(plan.interval)}
                </div>
                {!!plan.metadata.discountCode && (
                  <div
                    style={{
                      textDecoration: 'line-through',
                      color: theme.dark2
                    }}
                  >
                    US${' '}
                    {defaultPlans.find(p => p.nickname === plan.nickname)
                      .amount / 100}{' '}
                    / {t(plan.interval)}
                  </div>
                )}
              </>
            }
            description={getPlanDescription(plan).map((item, index) => (
              <CardDescription content={item} key={index} />
            ))}
            isSignupPage={!!setSelectedPlan}
            plan={plan}
            isCancelAtEnd={isCancelAtEnd}
          />
        ))} */}
      </div>
      <div className="custom_plan_card" style={{ marginTop: 20 }}>
        <PlanCard
          key="custom"
          title={<span>{t('Custom')}</span>}
          // description={
          //   <>
          //     <span>
          //       Please contact us at{' '}
          //       <a href="mailto:support@bantexapp.com">support@bantexapp.com</a>
          //       for customised plan
          //     </span>
          //   </>
          // }
          isFree={true}
          isSignupPage={!!setSelectedPlan}
          isComingSoon={true}
        />
      </div>
    </>
  )
}
