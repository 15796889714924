import React, { useState } from 'react'
import { Form, Checkbox, Alert } from 'antd'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import { P } from '../override/Typography'
import ConfigInactivityModal from '../settings/ConfigInactivityModal'
import { useTranslation } from 'react-i18next'

const LegacyReleaseUpon = props => {
  const {
    form,
    effectiveUpon,
    setEffectiveUpon,
    prevStep,
    setErrMsg,
    nextStep,
    inactivityStateConfig
  } = props
  const { getFieldDecorator } = form
  const [configModalVisible, setConfigModalVisible] = useState(false)
  const { t } = useTranslation()

  const handleLegacyInfoNext = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) return

      if (
        !values.effectiveUpon.requestByUser &&
        !values.effectiveUpon.deathOrIncapacitated
      ) {
        setErrMsg(t('PLEASE_SELECT_AT_LEAST_ONE_OPTION'))
        return
      }

      setEffectiveUpon(values.effectiveUpon)
      nextStep()
    })
  }

  return (
    <>
      <b>{t('WHEN_DO_YOU_WANT_TO_RELEASE_YOUR_VAULTBOX_CONTENT')}</b>
      <br />
      <Form>
        <FormItem style={{ marginBottom: 0 }}>
          {getFieldDecorator('effectiveUpon.requestByUser', {
            valuePropName: 'checked',
            initialValue: effectiveUpon?.requestByUser
          })(<Checkbox>{t('UPON_REQUEST_BY_ME')}</Checkbox>)}
        </FormItem>
        <FormItem style={{ marginBottom: 0 }}>
          {getFieldDecorator('effectiveUpon.deathOrIncapacitated', {
            valuePropName: 'checked',
            initialValue: effectiveUpon?.deathOrIncapacitated
          })(
            <Checkbox
              disabled={
                !inactivityStateConfig.inactivityDuration ||
                !inactivityStateConfig.inactivityReminderResponseDuration
              }
            >
              {t('UPON_MY_DEATH_OR_INCAPACITATED_SATE')}
            </Checkbox>
          )}
        </FormItem>
      </Form>
      {(!inactivityStateConfig.inactivityDuration ||
        !inactivityStateConfig.inactivityReminderResponseDuration) && (
        <Alert
          type="warning"
          description={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <P style={{ flexGrow: 1 }}>
                {t('INACTIVITY_STATE_CONFIGURATION')}
              </P>
              <Button
                onClick={() => setConfigModalVisible(true)}
                style={{ marginLeft: 30 }}
                type="primary"
              >
                {t('CONFIGURE')}
              </Button>
            </div>
          }
          showIcon={true}
        />
      )}
      <ConfigInactivityModal
        configModalVisible={configModalVisible}
        setConfigModalVisible={setConfigModalVisible}
        inactivityStateConfig={inactivityStateConfig}
      />
      <div className="steps-actions">
        <Button size="large" onClick={prevStep}>
          {t('BACK')}
        </Button>
        <div style={{ float: 'right' }}>
          <Button size="large" type="primary" onClick={handleLegacyInfoNext}>
            {t('NEXT')}
          </Button>
        </div>
      </div>
    </>
  )
}

const WrapLegacyReleaseUpon = Form.create({
  name: 'LegacyReleaseUpon'
})(LegacyReleaseUpon)
export default WrapLegacyReleaseUpon
