import Asset, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class RetirementAccount extends Asset {
  /**
   * Construct a new RetirementAccount instance
   * @param {object} params - RetirementAccount creation options:
   * @param {string} [params.retirementAccountType]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    valuationDate,
    currency,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    retirementAccountType,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    status,
    events,
    passwords
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      currency,
      valuationDate,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords
    })

    this.subType = ASSET_TYPES.RETIREMENT_ACCOUNT
    this.retirementAccountType = retirementAccountType
  }
}

export default RetirementAccount

export const retirementAccountColumns = () => [
  ...assetColumns(),
  {
    key: 'retirementAccountType',
    title: i18next.t('RETIREMENT_ACCOUNT_TYPE'),
    type: IMPORT_COL_TYPES.LIST,
    isCustomList: true
  },
  ...referenceColumns()
]
