import React from 'react'
import { Form, Modal } from 'antd'
import FormItem from '../override/FormItem'
import { withTranslation } from 'react-i18next'
import TextInput from './../common/TextInput'
class AddCryptoCurrencyModal extends React.Component {
  render() {
    const {
      form,
      visible,
      handleOk,
      handleCancel,
      existingItems,
      isAddingCrypto,
      t
    } = this.props
    const { getFieldDecorator, setFieldsValue } = form

    return (
      <Modal
        visible={visible}
        title={t('NEW_CRYPTOCURRENCY')}
        onCancel={() => {
          form.resetFields()
          handleCancel()
        }}
        onOk={handleOk}
        okText="Save"
        maskClosable={false}
        okButtonProps={{ loading: isAddingCrypto }}
      >
        <Form layout="vertical" hideRequiredMark>
          <FormItem label={t('NAME')}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: t('INPUT_NAME_MSG')
                },
                {
                  validator: (rule, value, callback) => {
                    if (
                      value &&
                      existingItems.some(item => item.name === value)
                    ) {
                      callback(t('THE_SAME_NAME_ALREADY_EXISTS'))
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(
              <TextInput
                type="text"
                name="name"
                setFieldsValue={setFieldsValue}
              />
            )}
          </FormItem>
          <FormItem label={t('SYMBOL')}>
            {getFieldDecorator('code', {
              rules: [
                {
                  required: true,
                  message: t('ENTER_SYMBOL')
                },
                {
                  validator: (rule, value, callback) => {
                    if (
                      value &&
                      existingItems.some(item => item.code === value)
                    ) {
                      callback(t('THE_SAME_SYMBOL_ALREADY_EXISTS'))
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(
              <TextInput
                type="text"
                name="code"
                setFieldsValue={setFieldsValue}
              />
            )}
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const WrappedAddCryptoCurrencyModal = Form.create({
  name: 'AddCryptoCurrencyModal'
})(AddCryptoCurrencyModal)
export default withTranslation()(WrappedAddCryptoCurrencyModal)
