export const BUSINESS_PATHS = [
  // 'Identification/',
  // 'Identification/Birth certificates/',
  // 'Identification/Identity cards/',
  // 'Identification/Driving licences/',
  // 'Identification/Passports/',
  // 'Identification/Death certificates/',
  // 'Finances/',
  // 'Finances/Bank accounts/',
  // 'Finances/Equity holdings/',
  // 'Finances/Loans/',
  // 'Finances/Credit cards/',
  // 'Finances/Debit cards/',
  // 'Finances/Credit reports/',
  // 'Finances/Budgets/',
  // 'Finances/Taxes/',
  // 'Property/',
  // 'Property/Titles/',
  // 'Property/Property taxes/',
  // 'Property/Leases/',
  // 'Property/Mortgages/',
  // 'Property/Utilities/',
  // 'Property/Property photos/',
  // 'Legal/',
  // 'Legal/Wills/',
  // 'Legal/Trusts/',
  // 'Legal/Powers of attorney/',
  // 'Legal/Marriage/',
  // 'Legal/Adoption/',
  // 'Work and business/',
  // 'Work and business/Grants/',
  // 'Work and business/Contracts/',
  // 'Work and business/Intellectual property/',
  // 'Work and business/Claims and expenses/',
  // 'Work and business/Invoices/',
  // 'Work and business/Employment records/',
  // 'Health/',
  // 'Health/Medical reports/',
  // 'Health/Health insurance/',
  // 'Health/Prescriptions/',
  // 'Health/Medical certificates/',
  // 'Health/Vaccinations/',
  // 'Insurance/',
  // 'Insurance/Life/',
  // 'Insurance/Property/',
  // 'Insurance/Art/',
  // 'Insurance/Driving/',
  // 'Insurance/Disability/',
  // 'Education/',
  // 'Education/Certificates/',
  // 'Education/Resume/',
  // 'Education/Awards/',
  // 'Education/Courses/',
  // 'Education/Classmates/',
  // 'Personal/',
  // 'Personal/Family photos/',
  // 'Personal/Jewellery/',
  // 'Personal/Mementos/',
  // 'Personal/Artworks/',
  // 'Personal/Travels/',
  // 'Personal/Music/',
  // 'Personal/Entertainment/',
  // 'Personal/Sports/'
  'Finance & Accounting/',
  'Finance & Accounting/Bank/',
  'Finance & Accounting/Account Receiveable (AR)/',
  'Finance & Accounting/Account Payable (AP)/',
  'Finance & Accounting/Tax/',
  'Finance & Accounting/Report/',
  'Finance & Accounting/Jurnal Voucher/',
  'Sales & Marketing/',
  'Sales & Marketing/Sales/',
  'Sales & Marketing/Marketing/',
  'Dokumen Legal/',
  'Dokumen Legal/Perijinan Usaha/',
  'Dokumen Legal/Perijinan Usaha/OSS (Online Single Submission)/',
  'Dokumen Legal/Perijinan Usaha/DPMPTSP (Dinas Penanaman Modal & Pelayanan Terpadu Satu Pintu)/',
  'Dokumen Legal/Perijinan Usaha/License & Sertifikasi/',
  'Dokumen Legal/Perijinan Usaha/Laporan/',
  'Dokumen Legal/Perijinan Usaha/Rekomendasi/',
  'Dokumen Legal/Perijinan Usaha/Ketenagakerjaan/',
  'Dokumen Legal/Anggaran Dasar/',
  'Dokumen Legal/Anggaran Dasar/Akta Perusahaan/',
  'Dokumen Legal/Anggaran Dasar/RUPS (Rapat Umum Pemegang Saham)/',
  'Dokumen Legal/Anggaran Dasar/Rapat Direksi/',
  'Dokumen Legal/Perjanjian/',
  'Dokumen Legal/Perjanjian/Distributor/',
  'Dokumen Legal/Perjanjian/Hutang & Bank/',
  'Dokumen Legal/Perjanjian/Sewa Menyewa/',
  'Dokumen Legal/Aset/',
  'Dokumen Legal/Aset/Kendaraan/',
  'Dokumen Legal/Aset/Bangunan/',
  'Dokumen Legal/Aset/Mesin/',
  'Dokumen Legal/Aset/Inventaris/',
  'Dokumen Legal/Aset/Intangible Aset',
  'Dokumen Legal/Asuransi/',
  'Dokumen Legal/Asuransi/Bangunan/',
  'Dokumen Legal/Asuransi/Kendaraan/',
  'Dokumen Legal/Asuransi/Mesin & Inventory/',
  'Human Resources/',
  'Human Resources/Personalia/',
  'Human Resources/Personalia/Data Utama Karyawan/',
  'Human Resources/Personalia/Data Ketenagakerjaan/',
  'Human Resources/Personalia/Data Ketenagakerjaan/Perjanjian Kerja/',
  'Human Resources/Personalia/Data Ketenagakerjaan/Surat Keputusan/',
  'Human Resources/Personalia/Data Ketenagakerjaan/Penilaian Kerja/',
  'Human Resources/Personalia/Data Ketenagakerjaan/BPJS Ketenagakerjaan & BPJS Kesehatan/',
  'Human Resources/Personalia/Data Ketenagakerjaan/Asuransi Karyawan/',
  'Human Resources/Personalia/Data Karyawan Keluar/',
  'Human Resources/Personalia/Data Karyawan Keluar/Mengundurkan Diri/',
  'Human Resources/Personalia/Data Karyawan Keluar/Pensiun/',
  'Human Resources/Personalia/Data Karyawan Keluar/PHK Selain Mengundurkan Diri & Pensiun/',
  'Human Resources/Payroll & Penggajian/',
  'Human Resources/Payroll & Penggajian/Absensi/',
  'Human Resources/Payroll & Penggajian/Absensi/Surat Cuti/',
  'Human Resources/Payroll & Penggajian/Absensi/Surat Dokter/',
  'Human Resources/Payroll & Penggajian/Pinjaman/',
  'Human Resources/Payroll & Penggajian/Data Gaji/',
  'Human Resources/Recruitment/',
  'Human Resources/Recruitment/Data Kandidat Recruit/',
  'Human Resources/Recruitment/Data Kandidat  Diproses/',
  'Human Resources/Recruitment/Data Kandidat  Diproses/Hasil Psikotes/',
  'Human Resources/Recruitment/Data Kandidat  Diproses/Hasil Interview/',
  'Human Resources/Recruitment/Data Kandidat  Diproses/Surat Penawaran Kerja/',
  'Human Resources/Recruitment/Data Kandidat Bergabung/',
  'General Afair/',
  'General Afair/Office Boy/',
  'General Afair/Office Boy/Data Rutinitas/',
  'General Afair/Office Boy/Data Kejadian umum/',
  'General Afair/Security/',
  'General Afair/Security/Data Rutinitas/',
  'General Afair/Security/Data Kejadian/',
  'General Afair/Belanja Consumable/',
  'General Afair/Belanja Consumable/Data Barang/',
  'General Afair/Belanja Consumable/Data Pengadaan Barang Consumable/',
  'General Afair/Kendaraan operasional/',
  'General Afair/Kendaraan operasional/Data Kelengkapan Kendaraan/',
  'General Afair/Kendaraan operasional/Data Penggunaan/',
  'General Afair/Kendaraan operasional/Data Service Rutin/',
  'General Afair/Asuransi/',
  'General Afair/Asuransi/Polis/',
  'General Afair/Asuransi/Data Asset Ter & Asuransi/'
]

export const SCHOOL_PATHS = [
  'Administrasi Sekola/',
  'Administrasi Kepegawaian/',
  'Administrasi Keuangan/',
  'Administrasi Sarana dan Prasarana/',
  'Administrasi Hubungan Sekolah dan Masyarakat/',
  'Administrasi Perusratan dan Pengarsipan/',
  'Administrasi Layanan Khusus/',
  'Administrasi Kesiswaan/',
  'Administrasi Kurikulum/',
  'Administrasi Dapodik/'
]

export const PERSONAL_PATHS = [
  'Dokumen Pribadi/',
  'Dokumen Kepemilikan Rumah/',
  'Dokumen Kepemilikan Kendaraan/',
  'Dokumen Pendidikan/',
  'Dokumen Bank Untuk Kepemilikan Rumah/',
  'Dokumen Bank Untuk Kepemilikan Kendaraan/',
  'Dokumen Usaha & Pekerjaan/',
  'Dokumen Finansial/'
]

export const STUDENT_PATHS = [
  'Ijazah/',
  'Ijazah/TK/',
  'Ijazah/SD/',
  'Ijazah/SMP/',
  'Ijazah/D3/',
  'Ijazah/Sarjana/',
  'Buku Raport/',
  'Buku Raport/SD/',
  'Buku Raport/SMP/',
  'Buku Raport/SMA & SMK/',
  'Transkrip Nilai/',
  'Sertifikat/',
  'Sertifikat/Pelatihan/',
  'Sertifikat/Keterampilan/',
  'Sertifikat/Seminar/',
  'Sertifikat/Organisasi/',
  'Sertifikat/Magang/',
  'Tugas/',
  'Catatan Penting/'
]

export const BLANK = [
]

export const FOLDER_TYPES = {
  FOLDER: 'Folder',
  EXPANDING: 'Expanding File',
  DISPLAY_BOOK: 'Display Book',
  LAF: 'LAF',
  RING_BINDER: 'Ring Binder',
  SUSPENSION: 'Suspension File'
}

export const TABLE_NAMES = {
  DOCUMENTS: 'documents',
  CONTACTS: 'contacts',
  LOCATIONS: 'locations',
  PASSWORDS: 'passwords',
  EVENTS: 'events'
}

// export const PREDEFINED_FOLDERS = PREDEFINED_PATHS.map(path => ({
//   path,
//   idPrefix: 'folder',
//   folderIcon: 'folderGeneric',
//   folderType: FOLDER_TYPES.FOLDER
// }))

export const DATE_FORMAT = 'DD/MM/YYYY'

export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss'

export const EXPORT_DATE_TIME_FORMAT = 'YYYYMMDDHHmmss'

export const END_OF_TIME = '9999-12-31T23:59:59.999Z'

export const CORE_TYPES = { ASSET: 'Asset', LIABILITY: 'Liability' }

export const CHART_GROUP_BY = { SUBTYPE: 'subType', CURRENCY: 'currency' }

// Asset/liability sub-types:
export const ASSET_TYPES = {
  CASH: 'Cash',
  PROPERTY: 'Property',
  VEHICLE: 'Vehicle',
  BROKERAGE_ACCOUNT: 'Brokerage account',
  EQUITY_LISTED: 'Equity - listed',
  EQUITY_UNLISTED: 'Equity - unlisted',
  INSURANCE: 'Insurance',
  LOAN_TO_OTHERS: 'Loan to others',
  RETIREMENT_ACCOUNT: 'Retirement account',
  TRUST: 'Trust',
  CRYPTO: 'Crypto',
  OTHER_ASSETS: 'Other assets'
}

export const LIABILITY_TYPES = {
  PROPERTY_LOAN: 'Property loan',
  VEHICLE_LOAN: 'Vehicle loan',
  CREDIT_CARD: 'Credit card',
  LOAN_FROM_OTHERS: 'Loan from others',
  OTHER_LIABILITY: 'Other liability'
}

export const ASSET_TYPES_WITHOUT_PURCHASE_DATE = [
  ASSET_TYPES.CASH,
  ASSET_TYPES.EQUITY_LISTED,
  ASSET_TYPES.EQUITY_UNLISTED,
  ASSET_TYPES.BROKERAGE_ACCOUNT,
  ASSET_TYPES.RETIREMENT_ACCOUNT,
  ASSET_TYPES.TRUST,
  ASSET_TYPES.CRYPTO
]

export const ASSET_TYPES_WITHOUT_DISPOSAL_DATE = [
  ASSET_TYPES.CASH,
  ASSET_TYPES.BROKERAGE_ACCOUNT,
  ASSET_TYPES.RETIREMENT_ACCOUNT,
  ASSET_TYPES.TRUST,
  ASSET_TYPES.CRYPTO
]

export const ALPHA_NUMERIC_DASH_REGEX = /^[0-9a-zA-Z-]+$/

export const CURRENCY_FORMAT_REGEX = /\B(?=(\d{3})+(?!\d))/g

export const CURRENCY_PARSE_REGEX = /\$\s?|(,*)/g

export const UPPERCASE_REGEX = /[A-Z]/

export const LOWERCASE_REGEX = /[a-z]/

export const NUMBER_REGEX = /[0-9]/

export const SPECIAL_CHAR_REGEX = /[!-/:-@[-`{-~]/

export const NUMBER_ONLY_REGEX = /^[0-9]+$/

export const PHONE_NUMBER_REGEX =
  /^((\(\+?(\d{0,2}[ .-]?)?\d{1,4}\))|(\+?(\d{0,2}[ .-]?)?\d{1,4}))?(([.\-() ]|( \()|(\) ))?\d{1,4})+$/

export const ID_TYPES = { NRIC: 'NRIC', FIN: 'FIN', PASSPORT: 'Passport' }

export const EMAIL_DEFAULT_OPTIONS = ['Personal', 'Work', 'Other']

export const ADDRESS_DEFAULT_OPTIONS = ['Home', 'Work', 'Other']

export const PHONE_DEFAULT_OPTIONS = ['Mobile', 'Home', 'Work', 'Other']

export const TYPE_OF_POLICY_DEFAULT_OPTIONS = [
  'Life - Term',
  'Life - Universal',
  'Total and Permanent Disability',
  'Critical Illness',
  'Hospitalisation',
  'Other medical',
  'Personal Accident',
  'Annuity/Retirement',
  'Life – Whole',
  'Education',
  'Travel',
  'Income/Endowment/Savings',
  'Investment-Linked Policy'
]

export const BUILDING_TYPES = {
  HOUSE: 'House',
  APARTMENT: 'Apartment',
  COMMERCIAL: 'Commercial'
}

export const HOLDING_TYPES = {
  FREEHOLD: 'Freehold',
  LEASEHOLD: 'Leasehold'
}

export const SQUARE_UNITS = { M2: 'sq m', SQ_FT: 'sq ft' }

export const LOAN_PERIOD_UNITS = {
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years'
}

export const FIELDS = [
  { key: 'name', placeholder: 'name' },
  { key: 'value', placeholder: 'value' }
]

export const RENDER_TYPES = {
  TITLE_WITH_ICON: 'Title with icon',
  CURRENCY: 'Currency',
  ITEMS: 'Items',
  VALUATION: 'Valuation'
}

export const IMPORT_COL_TYPES = {
  DATE: 'Date',
  CURRENCY: 'Currency',
  LIST: 'List',
  BOOLEAN: 'Boolean',
  STRING: 'String',
  PERCENTAGE: 'Percentage',
  NUMBER: 'Number',
  DAY_IN_MONTH: 'Day in month',
  COMMA_SEPARATED: 'Comma-separated values'
}

export const SHARE_PERMISSIONS = {
  READ_ACCESS: 'Read Access',
  WRITE_ACCESS: 'Write Access'
}

export const EQUITY_LISTED_DEFAULT_REFERENCES = ['Ticker', 'ISIN']

export const ENTITY_TYPES = {
  ASSET_LIABILITY: 'AssetLiability',
  CONTACT: 'Contact',
  DOCUMENT: 'Document',
  EVENT: 'Event',
  PASSWORD: 'Password',
  LOCATION: 'Location'
}

export const CONTACT_TYPES = {
  INDIVIDUAL: 'Individual',
  ORGANISATION: 'Organisation'
}

export const PREMIUM_UNITS = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  ANNUALLY: 'Annually'
}

export const RENT_AMOUNT_UNITS = {
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  ANNUALLY: 'Annually'
}
export const DEFAULT_ASSET_COLUMNS = [
  'title',
  'percentageOwnership',
  'purchaseDate',
  'valuationDate',
  'valuationInAssetCurrency',
  'valuationInBaseCurrency'
]

export const DEFAULT_LIABILITY_COLUMNS = [
  'title',
  'percentageOwnership',
  'startDate',
  'maturityDate',
  'valuationDate',
  'outstandingValueInLiabilityCurrency',
  'outstandingValueInBaseCurrency'
]

export const DEFAULT_ASSET_LIABILITY_COLUMNS = [
  'title',
  'percentageOwnership',
  'valuationDate',
  'valuationInAssetLiabilityCurrency',
  'valuationInBaseCurrency'
]

export const LOCATION_LV1_OPTIONS = [
  'Accounting room',
  'Marketing room',
  'Sales room'
]

export const LOCATION_LV2_OPTIONS = ['Cabinet A', 'Cabinet B', 'Cabinet C']
export const LOCATION_LV3_OPTIONS = ['Shelf 1', 'Shelf 2', 'Shelf 3']
export const COLUMNS_NOT_SHOW_IN_GROUP = [
  'outstandingValueInLiabilityCurrency',
  'outstandingValueInBaseCurrency',
  'valuationInAssetCurrency'
]

export const SAMPLE_TEMPLATE_EXCLUDED_COLUMNS = [
  'valuationInAssetCurrency',
  'outstandingValueInLiabilityCurrency',
  'valuationInBaseCurrency',
  'outstandingValueInBaseCurrency',
  'contacts',
  'links',
  'documents',
  // TODO: should include the following columns as well
  // 'floorSize', // key-value
  'holdingType', // dropdown
  'leaseholdDuration',
  'tenant', // contact
  // 'rentAmount', // key-value
  'exchange', // dropdown
  'company', // contact
  // 'classOfShares', // dropdown
  'nameAssured', // contact
  'insuranceAdvisor', // contact
  'beneficiaries', // contact
  'borrower', // contact
  'lender', // contact
  'references'
]

export const TEXT_FILE_TYPES = {
  DOC: {
    name: 'Word Document',
    extension: '.doc',
    mimeType: 'application/msword'
  },
  TXT: { name: 'Plain Text', extension: '.txt', mimeType: 'text/plain' }
}

export const ACCOUNT_TYPES = {
  SAVINGS: 'Savings',
  CURRENT: 'Current',
  FIXED_DEPOSIT: 'Fixed deposit',
  STRUCTURED_DEPOSIT: 'Structured deposit'
}
export const DEFAULT_OTHER_ASSET_TYPES = [
  'Jewellery',
  'Furnishing',
  'Antiques',
  'Art',
  'Collectible',
  'Precious stone'
]
export const INTEREST_RATE_UNITS = {
  PER_MONTH: 'per month',
  PER_YEAR: 'per year'
}

export const LENGTH_OF_LOAN_UNITS = {
  DAYS: 'Days',
  MONTHS: 'Months',
  YEARS: 'Years'
}

export const TAB_KEY = {
  ACTIVED: 'Actived',
  PENDING: 'Pending',
  REJECTED: 'Rejected'
}

export const DEFAULT_TYPE_OF_TRUST_INTEREST = [
  'Settlor',
  'Trustee',
  'Beneficiary',
  'Protector'
]

export const TYPE_OF_TRUST_INTEREST_PLURAL_MAP = {
  Settlor: 'Settlors',
  Trustee: 'Trustees',
  Beneficiary: 'Beneficiaries',
  Protector: 'Protectors'
}

export const TRUST_REVOCABILITY = ['Irrevocable', 'Revocable']

export const DEFAULT_TRUST_TYPE = [
  'Private',
  'Bare',
  'Charitable',
  'Investment'
]

export const DEFAULT_INTEREST_RATE_TYPES = ['Fixed', 'Variable']
export const DEFAULT_CLASS_OF_SHARES = ['Ordinary', 'Preference', 'Convertible']

export const PRODUCT_NAME = {
  VAULTBOX: 'vaultbox',
  PROFESSIONAL_DEPUTY: 'Professional Deputy',
  BANTEX: 'Bantex'
}
export const MFA_TYPES = {
  TOTP: 'SOFTWARE_TOKEN_MFA',
  SMS: 'SMS_MFA',
  EMAIL: 'EMAIL_MFA'
}

export const AUTH_FLOW = {
  MFA_FLOW: 'MFA_FLOW',
  CUSTOM_FLOW: 'CUSTOM_FLOW',
  NONE: 'NONE'
}

export const LANGUAGES = {
  ENGLISH: 'en',
  VIETNAMESE: 'vn',
  INDONESIA: 'id',
  CHINESE: 'cn'
}

export const ACCESS_LEVEL = {
  ALLOW: 'Allow',
  NEED_APPROVAL: 'Need approval',
  DENY: 'Deny'
}

export const FOLDER_STRUCTURE = {
  BUSINESS: 'Business',
  SCHOOL: 'School',
  STUDENT: 'Student',
  PERSONAL: 'Personal',
  BLANK:  'Blank'
}

export const RECURRING = {
  NONE: 'None',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly'
}

export const EDIT_DELETE_EVENT_TYPE = {
  THIS_EVENT: 'This event',
  THIS_AND_FOLLOWING_EVENTS: 'This and following events',
  ALL_EVENTS: 'All events'
}

export const DEFAULT_RETIREMENT_ACCOUNT_TYPES = [
  'CPF',
  'SRS',
  'Superannuation',
  'Pension'
]

export const INACTIVITY_REMINDER_DURATIONS = [
  '5 minutes',
  '3 days',
  '7 days',
  '14 days',
  '30 days'
]

export const INACTIVITY_DURATIONS = [
  '5 minutes',
  '3 months',
  '6 months ',
  '12 months',
  '24 months'
]

export const CSV_TEMPLATES = {
  // VB: 'bantex',
  IB: 'Interactive Brokers',
  DBS: 'DBS - Brokerage account'
}

export const VAULTBOX_SAMPLE_DATA = [
  {
    title: 'DBS saving account',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    valuationInAssetCurrency: 10000,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Cash',
    bank: 'DBS',
    accountNumber: '1234567890',
    accountType: 'Savings',
    contacts: [],
    documents: []
  },
  {
    title: 'House in Singapore',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    purchaseDate: '2020-01-01T00:00:00.000Z',
    disposalDate: null,
    valuationInAssetCurrency: 10000,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Property',
    buildingType: 'House',
    address: '01 Main Road',
    buildDate: '2020-01-01T00:00:00.000Z',
    holdingType: 'Freehold',
    rentalStartDate: '2020-01-01T00:00:00.000Z',
    rentalEndDate: '2025-01-01T23:59:59.999',
    contacts: [],
    documents: [],
    floorSize: {
      unit: 'sq m',
      value: '100'
    },
    tenant: [],
    rentAmount: {
      unit: 'Monthly',
      value: 1000
    }
  },
  {
    title: 'Car',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    purchaseDate: '2020-01-01T00:00:00.000Z',
    disposalDate: null,
    valuationInAssetCurrency: 10000,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Vehicle',
    vehicleRegistrationNumber: '12345678',
    contacts: [],
    documents: []
  },
  {
    title: 'IB account',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    valuationInAssetCurrency: 10000,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Brokerage account',
    brokerageName: 'IB',
    accountNumber: '1234567890',
    accountType: 'Cash',
    jointAccount: true,
    contacts: [],
    documents: []
  },
  {
    title: 'BAC',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    disposalDate: null,
    valuationInAssetCurrency: 10000,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Equity - listed',
    exchange: 'XNYS',
    price: 50,
    quantity: 200,
    contacts: [],
    references: [
      {
        name: 'Ticker',
        value: 'BAC'
      }
    ],
    documents: []
  },
  {
    title: 'Equity at ABC Corp',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    disposalDate: null,
    valuationInAssetCurrency: 10000,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Equity - unlisted',
    company: undefined,
    price: 50,
    quantity: 200,
    classOfShares: 'Ordinary',
    shareCertificateNumber: '1234567890',
    percentageOfShares: 100,
    contacts: [],
    documents: []
  },
  {
    title: 'Life insurance',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    purchaseDate: '2020-01-01T00:00:00.000Z',
    disposalDate: null,
    valuationInAssetCurrency: 10000,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Insurance',
    typeOfPolicy: 'Life - Term',
    sumAssuredInAssetCurrency: 10000,
    sumAssuredInBaseCurrency: 10000,
    insurer: 'Manulife',
    policyNumber: '1234567890',
    entitlement: 100,
    otherNotes: 'Sample note',
    contacts: [],
    documents: [],
    premium: {
      unit: 'Annually',
      value: 1000
    },
    insuranceAdvisor: [],
    beneficiaries: []
  },
  {
    title: 'Loan to family',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    purchaseDate: '2020-01-01T00:00:00.000Z',
    disposalDate: null,
    valuationInAssetCurrency: 10000,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Loan to others',
    numberOfRepayments: 2,
    contacts: [],
    documents: [],
    interestRate: {
      value: 10,
      unit: 'per year'
    },
    loanPeriod: {
      unit: 'months',
      value: '12'
    }
  },
  {
    title: 'CPF retirement account',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    valuationInAssetCurrency: 10000,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Retirement account',
    retirementAccountType: 'CPF',
    contacts: [],
    documents: []
  },
  {
    title: 'Crypto sample',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'BTC',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    valuationInAssetCurrency: 1,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Crypto',
    cryptoExchange: 'Binance',
    walletAddress: '1234567890',
    contacts: [],
    documents: []
  },
  {
    title: 'Trust sample',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    valuationInAssetCurrency: 10000,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: false,
    subType: 'Trust',
    trustRevocability: 'Revocable',
    trustType: 'Private',
    typeOfTrustInterest: {
      key: 'Settlor'
    },
    contacts: [],
    documents: []
  },
  {
    title: 'Diamond ring',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Asset',
    purchaseDate: '2020-01-01T00:00:00.000Z',
    disposalDate: null,
    valuationInAssetCurrency: 10000,
    valuationInBaseCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Other assets',
    otherAssetType: 'Jewellery',
    contacts: [],
    documents: []
  },
  {
    title: 'House in Singapore',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Liability',
    startDate: '2020-01-01T00:00:00.000Z',
    maturityDate: null,
    outstandingValueInBaseCurrency: 10000,
    outstandingValueInLiabilityCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Property loan',
    address: '01 Main Road',
    accountNumber: '1234567890',
    yearOfLoan: 1,
    monthlyPayment: 1000,
    typeOfInterestRate: 'Fixed',
    contacts: [],
    documents: [],
    interestRate: {
      value: 10,
      unit: 'per year'
    },
    loanPeriod: {
      unit: 'Years',
      value: '2'
    }
  },
  {
    title: 'Car',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Liability',
    startDate: '2020-01-01T00:00:00.000Z',
    maturityDate: null,
    outstandingValueInBaseCurrency: 10000,
    outstandingValueInLiabilityCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Vehicle loan',
    vehicleRegistrationNumber: '12345678',
    contacts: [],
    documents: [],
    loanPeriod: {
      unit: 'Years',
      value: '2'
    },
    interestRate: {
      value: 10,
      unit: 'per year'
    }
  },
  {
    title: 'Visa',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Liability',
    outstandingValueInBaseCurrency: 10000,
    outstandingValueInLiabilityCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Credit card',
    expiryDate: '2025-01-01T00:00:00.000Z',
    paymentDueDate: '2025-01-01T00:00:00.000Z',
    issuingBank: 'DBS',
    creditLimit: 10000,
    recurringPaymentDueDay: 20,
    contacts: [],
    documents: [],
    interestRate: {
      value: 5,
      unit: 'per month'
    }
  },
  {
    title: 'Loan from family',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Liability',
    startDate: '2020-01-01T00:00:00.000Z',
    maturityDate: null,
    outstandingValueInBaseCurrency: 10000,
    outstandingValueInLiabilityCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Loan from others',
    numberOfRepayments: 2,
    contacts: [],
    documents: [],
    interestRate: {
      value: 10,
      unit: 'per year'
    },
    loanPeriod: {
      unit: 'years',
      value: '2'
    }
  },
  {
    title: 'Other liability',
    description: 'Sample description',
    descriptionWithMarkup: 'Sample description',
    percentageOwnership: 100,
    currency: 'USD',
    valuationDate: '2020-01-01T00:00:00.000Z',
    type: 'Liability',
    startDate: '2020-01-01T00:00:00.000Z',
    maturityDate: null,
    outstandingValueInBaseCurrency: 10000,
    outstandingValueInLiabilityCurrency: 10000,
    includeValueInNetWorth: true,
    subType: 'Other liability',
    contacts: [],
    documents: []
  }
]

export const CONTACTS_SAMPLE_DATA = [
  {
    type: CONTACT_TYPES.INDIVIDUAL,
    givenName: 'John',
    surName: 'Doe',
    dateOfBirth: '1990-06-15T00:00:00.000Z',
    idPassportNumber: '0123456789',
    emails: [
      { name: 'Personal', value: 'example1@gmail.com' },
      { name: 'Work', value: 'example2@gmail.com' },
      { name: 'Other', value: 'example3@gmail.com' }
    ],
    addresses: [
      { name: 'Home', value: 'Address 1' },
      { name: 'Work', value: 'Address 2' },
      { name: 'Other', value: 'Address 3' }
    ],
    phoneNumbers: [
      { name: 'Mobile', value: '0123456789' },
      { name: 'Home', value: '0123456789' },
      { name: 'Work', value: '0123456789' }
    ],
    references: [
      { name: 'Ref1', value: 'Example Reference 1' },
      { name: 'Ref2', value: 'Example Reference 2' },
      { name: 'Ref3', value: 'Example Reference 3' }
    ],
    tags: 'Tag1, Tag2',
    url: 'example.com',
    description: 'Sample description'
  },
  {
    type: CONTACT_TYPES.ORGANISATION,
    displayName: 'Sample organisation',
    legalName: 'Sample legal name',
    companyRegistrationNumber: '0123456789',
    countryTerritoryOfIncorporation: 'Singapore',
    emails: [
      { name: 'Personal', value: 'example1@gmail.com' },
      { name: 'Work', value: 'example2@gmail.com' },
      { name: 'Other', value: 'example23gmail.com' }
    ],
    addresses: [
      { name: 'Home', value: 'Address 1' },
      { name: 'Work', value: 'Address 2' },
      { name: 'Other', value: 'Address 3' }
    ],
    phoneNumbers: [
      { name: 'Mobile', value: '0123456789' },
      { name: 'Home', value: '0123456789' },
      { name: 'Work', value: '0123456789' }
    ],
    references: [
      { name: 'Ref1', value: 'Example Reference 1' },
      { name: 'Ref2', value: 'Example Reference 2' },
      { name: 'Ref3', value: 'Example Reference 3' }
    ],
    tags: 'Tag1, Tag2',
    url: 'example.com',
    description: 'Sample description'
  }
]

export const CURRENCY_NUM_FMT = '_(#,##0.00_);_((#,##0.00);_("-"??_);_(@_)'
export const CURRENCY_WITH_SYMBOL_NUM_FMT =
  '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)'

export const LEGACY_ACCESSED_BY = {
  PROFESSIONAL_DEPUTY: 'Professional Deputy',
  OTHER_SPECIFIC_PERSON: 'Other specific person'
}

export const MAX_FILE_SIZE = Math.pow(1024, 3) // 1GB

export const RECOVERY_OPTION = {
  BY_RECOVERY_KEY: 'By recovery key',
  BY_ASSISTANT: 'By assistant'
}
