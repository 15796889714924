import React, { useState, useEffect } from 'react'
import { Tag, Tooltip, Icon } from 'antd'
import TextInput from '../common/TextInput'
import { useTranslation } from 'react-i18next'
import { sanitizeValue } from '../../share/helpers'

export default function FileTags(props) {
  const { tags, setTags, disabled } = props
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const { t } = useTranslation()

  let tagInput

  const handleClose = removedTag => {
    setTags(tags.filter(tag => tag !== removedTag))
  }

  const showInput = () => {
    setInputVisible(true)
  }
  useEffect(() => {
    if (inputVisible) tagInput.focus()
  }, [inputVisible, tagInput])

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    if (inputValue.trim() && tags.indexOf(inputValue.trim().toLowerCase()) === -1) {
      setTags([...tags, sanitizeValue(inputValue).trim()])
    }
    setInputVisible(false)
    setInputValue('')
  }

  return (
    <div>
      {tags.map((tag, index) => {
        const isLongTag = tag.length > 20
        const tagElem = (
          <Tag style={{opacity: disabled ? 0.5 : 1,cursor: disabled ? "not-allowed" : "auto" }} key={tag} closable={!disabled} onClose={() => handleClose(tag)}>
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        )
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
      {inputVisible && (
        <TextInput
          ref={el => (tagInput = el)}
          type="text"
          size="small"
          style={{ width: 80 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
          disabled={disabled}
        />
      )}
      {!inputVisible && (
        <Tag
          onClick={!disabled && showInput}
          style={{ background: '#fff', borderStyle: 'dashed',opacity: disabled ? 0.5 : 1,cursor: disabled ? "not-allowed" : "auto" }}
        >
          <Icon type="plus" /> {t('NEW_TAG')}
        </Tag>
      )}
    </div>
  )
}
