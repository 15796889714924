import { renderDate, renderLinkedItemsPlainText } from '../../share/helpers'
import i18next from 'i18next'
import api from '../../lib/api'
import { getRecords, permanentlyDeleteItems } from '../../lib/pouchDb'
import { uniq } from 'lodash'

export const restoreEvents = async (userId, records, restoreAll = false) => {
  const response = await api.getEvents(userId)
  const lstEvents = response.data?.events || []
  let newReminderEvents

  if (restoreAll) {
    newReminderEvents = lstEvents.map(e => {
      return { ...e, deleted: false }
    })
  } else {
    records.forEach(record => {
      newReminderEvents = lstEvents.map(e =>
        e.id === record.id && e.date === record.date
          ? { ...e, deleted: false }
          : e
      )
    })
  }

  await api.updateEvents(
    userId,
    JSON.stringify({
      events: newReminderEvents
    })
  )
}

export const permanentlyDeleteEvents = async (userId, records, masterKey) => {
  const response = await api.getEvents(userId)
  const lstEvents = response.data?.events || []

  const newReminderEvents = lstEvents.filter(
    e => !records.some(record => record._id === e.id && record.date === e.date)
  )

  await api.updateEvents(
    userId,
    JSON.stringify({
      events: newReminderEvents
    })
  )

  const events = await getRecords(userId, 'events', masterKey)

  const deletedItems =
    events?.filter(
      e => !uniq(newReminderEvents.map(ne => ne.id)).includes(e._id)
    ) || []

  if (deletedItems.length) {
    await permanentlyDeleteItems('events', userId, deletedItems, masterKey)
  }
}

export const eventColumns = (
  contacts = [],
  // assetsLiabilities = [],
  documents = [],
  passwords = []
) => {
  return [
    {
      key: 'description',
      title: i18next.t('DESCRIPTION'),
      exportWidth: 40,
      dataIndex: 'description',
      style: { alignment: { wrapText: true } },
      render: description => description
    },
    {
      key: 'date',
      title: i18next.t('DATE'),
      exportWidth: 40,
      dataIndex: 'date',
      style: { alignment: { wrapText: true } },
      render: renderDate
    },
    {
      key: 'tags',
      title: i18next.t('TAGS'),
      exportWidth: 40,
      dataIndex: 'tags',
      style: { alignment: { wrapText: true } },
      render: tags => tags?.join(',')
    },
    {
      key: 'contacts',
      title: i18next.t('CONTACTS'),
      exportWidth: 40,
      dataIndex: 'contacts',
      style: { alignment: { wrapText: true } },
      render: items => {
        return renderLinkedItemsPlainText(items, contacts, 'name')
      }
    },
    // {
    //   key: 'assetsLiabilities',
    //   title: i18next.t('ASSETS').concat('/').concat(i18next.t('LIABILITIES')),
    //   exportWidth: 40,
    //   dataIndex: 'assetsLiabilities',
    //   style: { alignment: { wrapText: true } },
    //   render: items =>
    //     renderLinkedItemsPlainText(items, assetsLiabilities, 'title')
    // },
    {
      key: 'documents',
      title: i18next.t('DOCUMENTS'),
      exportWidth: 40,
      dataIndex: 'documents',
      style: { alignment: { wrapText: true } },
      render: items => renderLinkedItemsPlainText(items, documents, 'fileName')
    },
    {
      key: 'passwords',
      title: i18next.t('PASSWORDS'),
      exportWidth: 40,
      dataIndex: 'passwords',
      style: { alignment: { wrapText: true } },
      render: items => renderLinkedItemsPlainText(items, passwords, 'title')
    }
  ]
}
