import React, { useState, useContext } from 'react'
import { Modal, Radio } from 'antd'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Elements, StripeProvider } from 'react-stripe-elements'
import CardModal from '../../components/payment/CardModal'
import config from '../../config'
import { fetchCustomer } from '../../features/payment/customerSlice'
import AuthContext from '../../contexts/AuthContext'
import moment from 'moment'
import CurrentFilesModal from './CurrentFilesModal'

const SubscriptionTrialEndModal = props => {
  const { visible, setVisible } = props
  const { user } = useContext(AuthContext)
  const { customer, subscription } = useSelector(state => state.customer)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [cardModalVisible, setCardModalVisible] = useState(false)
  const [tableFilesVisible, setTableFilesVisible] = useState(false)
  const [value, setValue] = useState('')

  return (
    <Modal
      width={700}
      visible={visible}
      title={t('Trial period ended')}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div>
        <Trans
          i18nKey="THE_PAYMENT_BECAME_PAST_DUE"
          values={{
            billing_cycle_anchor: moment(
              subscription.billing_cycle_anchor * 1000
            ).format('LL')
          }}
        ></Trans>
      </div>
      <div style={{ marginBottom: 10 }}>
        To continue using this plan, please add a <b>payment method</b> with in
        one week, otherwise your current subscription will be canceled and
        downgraded to a free plan. Please reduce your file size below 2 GB to
        use the free plan.
      </div>

      <Radio.Group
        onChange={e => {
          if (e.target.value === 'payment') {
            setCardModalVisible(true)
            setTableFilesVisible(false)
          } else {
            setTableFilesVisible(true)
            setCardModalVisible(false)
          }
          setValue(e.target.value)
        }}
        value={value}
      >
        <Radio value="payment">Add payment method</Radio>
        <Radio value="delete">Delete files</Radio>
      </Radio.Group>
      <br />
      {tableFilesVisible && (
        <CurrentFilesModal
          visible={tableFilesVisible}
          setVisible={setTableFilesVisible}
          setValue={setValue}
        />
      )}

      <StripeProvider apiKey={config.stripe.PUBLISHABLE_KEY}>
        <Elements>
          <CardModal
            visible={cardModalVisible}
            setVisible={setCardModalVisible}
            customerId={customer.id}
            handleOkComplete={() => dispatch(fetchCustomer(user.username))}
            handleCancelComplete={() => setValue()}
          />
        </Elements>
      </StripeProvider>
    </Modal>
  )
}
export default SubscriptionTrialEndModal
