import Asset, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class Property extends Asset {
  /**
   * Construct a new Property instance
   * @param {object} params - Property creation options:
   * @param {string} [params.buildingType]
   * @param {string} [params.address]
   * @param {string} [params.floorSize]
   * @param {date} [params.buildDate]
   * @param {string} [params.holdingType]
   * @param {string} [params.leaseholdDuration]
   * @param {any} [params.tenant]
   * @param {date} [params.rentalStartDate]
   * @param {date} [params.rentalEndDate]
   * @param {number} [params.rentAmount]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    currency,
    valuationDate,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    contacts,
    references,
    buildingType,
    address,
    floorSize,
    buildDate,
    holdingType,
    leaseholdDuration,
    tenant,
    rentalStartDate,
    rentalEndDate,
    rentAmount,
    links,
    documents,
    includeValueInNetWorth,
    status,
    events,
    passwords
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      currency,
      valuationDate,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords
    })

    this.subType = ASSET_TYPES.PROPERTY
    this.buildingType = buildingType
    this.address = address
    this.floorSize = floorSize
    this.buildDate = buildDate
    this.holdingType = holdingType
    this.leaseholdDuration = leaseholdDuration
    this.tenant = tenant
    this.rentalStartDate = rentalStartDate
    this.rentalEndDate = rentalEndDate
    this.rentAmount = rentAmount
  }
}

export default Property

export const propertyColumns = () => [
  ...assetColumns(),
  {
    key: 'purchaseDate',
    title: i18next.t('ASSET_PURCHASE_DATE'),
    type: IMPORT_COL_TYPES.DATE,
    required: true
  },
  {
    key: 'disposalDate',
    title: i18next.t('DISPOSAL_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'address',
    title: i18next.t('ADDRESSES'),
    required: true
  },
  {
    key: 'buildingType',
    title: i18next.t('BUILDING_TYPE'),
    type: IMPORT_COL_TYPES.LIST,
    required: true
  },
  {
    key: 'floorSizeValue',
    title: i18next.t('FLOOR_SIZE_VALUE'),
    dataIndex: 'floorSize',
    objectKey: 'value',
    type: IMPORT_COL_TYPES.NUMBER
  },
  {
    key: 'floorSizeUnit',
    title: i18next.t('FLOOR_SIZE_UNIT'),
    dataIndex: 'floorSize',
    objectKey: 'unit',
    type: IMPORT_COL_TYPES.LIST
  },
  {
    key: 'buildDate',
    title: i18next.t('BUILD_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'holdingType',
    title: i18next.t('HOLDING_TYPE'),
    type: IMPORT_COL_TYPES.LIST
  },
  {
    key: 'leaseholdDuration',
    title: i18next.t('LEASEHOLD_DURATION')
  },
  {
    key: 'rentAmountValue',
    title: i18next.t('RENT_AMOUNT_VALUE'),
    dataIndex: 'rentAmount',
    objectKey: 'value',
    type: IMPORT_COL_TYPES.CURRENCY
  },
  {
    key: 'rentAmountUnit',
    title: i18next.t('RENT_AMOUNT_UNIT'),
    dataIndex: 'rentAmount',
    objectKey: 'unit',
    type: IMPORT_COL_TYPES.LIST
  },
  {
    key: 'rentalStartDate',
    title: i18next.t('RENTAL_START_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'rentalEndDate',
    title: i18next.t('RENTAL_END_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  ...referenceColumns()
]
