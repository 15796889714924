import styled, { css } from 'styled-components'

const defaultMixin = css`
  color: ${props => props.color || props.theme.dark};
  margin-bottom: 0;
  display: ${props => props.display || 'block'};
`
const P = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  ${defaultMixin}
`

const P1 = styled(P)`
  color: ${props => props.theme.dark1};
`

const Span = styled.span`
  color: ${props => props.color || props.theme.dark};
`

const Span1 = styled.span`
  color: ${props => props.theme.dark1};
`

const Span2 = styled.span`
  color: ${props => props.theme.dark2};
`

const B = styled.b`
  color: ${props => props.color || props.theme.dark};
`

const H1 = styled.h1`
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  ${defaultMixin}
`

const H2 = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  ${defaultMixin}
`

const H3 = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  ${defaultMixin}
`

const H4 = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  ${defaultMixin}
`

const H5 = styled.h5`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  ${defaultMixin}
`

const StatusText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  ${defaultMixin}
`

export { P, P1, Span, Span1, Span2, B, H1, H2, H3, H4, H5, StatusText }
