import { Form } from 'antd'
import styled from 'styled-components'

const StyledFormItem = styled(Form.Item)`
  & .ant-input,
  .ant-input-number {
    height: 50px;
    border-radius: 5px;
    &:focus {
      box-shadow: none;
    }
  }
  & .ant-input-number-input,
  .ant-select-auto-complete.ant-select .ant-input {
    height: 50px;
  }
  & .ant-input-number-focused,
  .ant-form-item-control.has-success .ant-input,
  .ant-form-item-control.has-success .ant-input-number,
  .ant-select-focused .ant-select-selection,
  .ant-form-item-control.has-success .ant-select-selection {
    box-shadow: none;
  }
  & .has-error .ant-input-affix-wrapper {
    .ant-input,
    .ant-input:hover {
      border-color: ${props => props.theme.red};
    }
  }
  & .has-error .ant-input-affix-wrapper .ant-input:focus {
    box-shadow: none;
  }
  &
    .has-error
    .ant-input-affix-wrapper:hover
    .ant-input:not(.ant-input-disabled),
  .has-error .ant-select-selection {
    border-color: ${props => props.theme.red};
  }
  .has-error .ant-input-group-addon .ant-select .ant-select-selection {
    border-color: transparent;
  }
  & .has-error .ant-form-explain {
    font-size: 12px;
    line-height: 22px;
    color: ${props => props.theme.red};
  }
  & .ant-input-clear-icon {
    font-size: 16px;
    color: ${props => props.theme.dark2};
  }
  & textarea.ant-input {
    height: auto;
  }
  & .ant-input-group-addon .ant-select .ant-select-selection--single {
    background-color: inherit;
  }
  & .ant-select .ant-select-selection--single,
  .ant-select .ant-select-selection--multiple {
    min-height: 50px;
    border-radius: 5px;
  }
  & .ant-select-selection__rendered,
  .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
    height: 50px;
    line-height: 50px;
  }
  & .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    margin-top: 4px;
  }
  .custom-addon-after .ant-select-selection--single {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-left: unset;
  }
  .custom-addon-after .ant-select-selection--single:hover,
  .custom-addon-after .ant-select-selection--single:focus,
  .custom-addon-before:hover,
  .custom-addon-before:focus {
    border-color: #d9d9d9;
  }
  .custom-addon-before {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-right: unset;
  }
  .ant-tag {
    height: 36px;
    line-height: 36px;
  }
  .ant-form-item-label {
    max-width: 100%;
    text-overflow: ellipsis;
  }
`
export default StyledFormItem
