import React, { useState, useEffect } from 'react'
import { Select, Divider, Icon } from 'antd'
import AddItemModal from '../modals/AddItemModal'
import FormItem from '../override/FormItem'
import { useTranslation } from 'react-i18next'
import { removeHtmlTags } from './../../share/helpers'

export default function SelectWithOther({
  defaultOptions,
  initialValue,
  label,
  fieldName,
  getFieldDecorator,
  required,
  onAddComplete
}) {
  const [addItemModalVisible, setAddItemModalVisible] = useState(false)
  const [options, setOptions] = useState([])
  let addItemFormRef

  const { t } = useTranslation()

  useEffect(() => {
    setOptions(
      initialValue && !defaultOptions.includes(initialValue)
        ? [...defaultOptions, initialValue]
        : defaultOptions
    )
  }, [initialValue, defaultOptions])

  const handleAddItem = () => {
    addItemFormRef.props.form.validateFields((err, values) => {
      if (err) return

      removeHtmlTags(values)

      setOptions([...options, values.itemName])
      setAddItemModalVisible(false)
      addItemFormRef.props.form.resetFields()
      onAddComplete(values.itemName)
    })
  }

  return (
    <FormItem label={label}>
      {getFieldDecorator(fieldName, {
        initialValue,
        rules: [
          {
            required,
            message: `${t('SELECT')} ${label.toLowerCase()}`
          }
        ]
      })(
        <Select
          allowClear
          showAction={['click', 'focus']}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: 0 }} />
              <div
                style={{ padding: '5px 12px', cursor: 'pointer' }}
                onMouseDown={e => e.preventDefault()}
                onClick={() => setAddItemModalVisible(true)}
              >
                <Icon type="plus" /> {t('OTHER')}
              </div>
            </div>
          )}
        >
          {options
            .map(opt => {
              return { key: opt, value: t(opt) }
            })
            .sort((a, b) => a.value.localeCompare(b.value))
            .map(option => (
              <Select.Option key={option.key} value={option.key}>
                {option.value}
              </Select.Option>
            ))}
        </Select>
      )}
      <AddItemModal
        wrappedComponentRef={fr => (addItemFormRef = fr)}
        visible={addItemModalVisible}
        handleOk={handleAddItem}
        handleCancel={() => setAddItemModalVisible(false)}
        item={label}
        existingItems={options}
      />
    </FormItem>
  )
}
