import React from 'react'
import { Table } from 'antd'
import { P1 } from '../override/Typography'
import { useTranslation } from 'react-i18next'

export default function AuthenticatorAppsInstructions() {
  const { t } = useTranslation()
  const columns = [
    {
      key: 'app',
      dataIndex: 'app',
      title: t('AUTHENTICATOR_APP')
    },
    {
      key: 'steps',
      dataIndex: 'steps',
      title: t('BRIEF_STEPS')
    }
  ]

  const dataSource = [
    {
      app: t('GOOGLE_AUTHENTICATOR'),
      steps: (
        <ul style={{ paddingLeft: '1em' }}>
          <li>{t('OPEN_THE_APP')}</li>
          <li>{t('TAP_BEGIN_SETUP')}</li>
          <li>{t('TAP_SCAN_BARCODE')}</li>
          <li>{t('SCAN_THE_QR_CODE')}</li>
          <li>{t('ENTER_THE_AUTHENTICATION_CODE')}</li>
        </ul>
      ),
      key: 'ggSteps'
    },
    {
      app: t('LASTPASS_AUTHENTICATOR'),
      steps: (
        <ul style={{ paddingLeft: '1em' }}>
          <li>{t('OPEN_THE_APP')}</li>
          <li>{t('TAP_PLUS_BUTTON')}</li>
          <li>{t('TAP_SCAN_BARCODE')}</li>
          <li>{t('SCAN_THE_QR_CODE')}</li>
          <li>{t('ENTER_THE_AUTHENTICATION_CODE')}</li>
        </ul>
      ),
      key: 'lpSteps'
    },
    {
      app: t('MICROSOFT_AUTHENTICATOR'),
      steps: (
        <ul style={{ paddingLeft: '1em' }}>
          <li>{t('OPEN_THE_APP')}</li>
          <li>{t('TAP_PLUS_BUTTON')}</li>
          <li>{t('TAP_OTHER')}</li>
          <li>{t('SCAN_THE_QR_CODE')}</li>
          <li>{t('ENTER_THE_AUTHENTICATION_CODE')}</li>
        </ul>
      ),
      key: 'msSteps'
    }
  ]

  return (
    <>
      <P1>{t('MFA_FIRST_CONTENT')}</P1>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        style={{ margin: '10px 0' }}
      />
      <P1>{t('MFA_SECOND_CONTENT')}</P1>
    </>
  )
}
