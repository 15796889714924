import React from 'react'
import { Modal, Form, Select, Radio } from 'antd'
//import { StringResources } from '../../share/StringResources'
import FormItem from '../override/FormItem'
import TextInput from '../common/TextInput'
import { withTranslation } from 'react-i18next'
import { folderIcons } from './FolderIcons'
import LocationSelect from './../common/LocationSelect'
import { FOLDER_TYPES } from './../../share/Constants'
import ShowImage from '../common/ShowImage'
class CreateFolderModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    return {
      locationRecord: {}
    }
  }

  componentWillReceiveProps = nextProps => {
    const locationId = !!nextProps.form.getFieldValue('location')
      ? nextProps.form.getFieldValue('location')
      : nextProps.location
    const activeLocations = nextProps.activeLocations
    if (locationId && activeLocations?.length) {
      const locationRecord = activeLocations.find(
        item => item._id === locationId
      )
      if (
        locationRecord &&
        locationRecord._id !== this.state.locationRecord._id
      ) {
        this.setState({ locationRecord: locationRecord })
      }
    }
  }

  render() {
    const {
      form,
      visible,
      handleSaveFolder,
      handleCancel,
      isEditFolder,
      folderName,
      folderIcon,
      folderType,
      folders,
      breadcrumb,
      location,
      isSaving,
      // setIsChanged,
      t,
      isReadonly
    } = this.props
    const { getFieldDecorator, setFieldsValue, getFieldValue } = form
    const { locationRecord } = this.state

    return (
      <Modal
        visible={visible}
        title={isEditFolder ? t('RENAME_FOLDER') : t('CREATE_NEW_FOLDER')}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={() => {
          handleSaveFolder(() => {
            this.setState({ locationRecord: {} })
          })
        }}
        onCancel={() => {
          handleCancel()
          this.setState({ locationRecord: {} })
        }}
        maskClosable={false}
        okButtonProps={{ loading: isSaving }}
        keyboard={false}
      >
        <Form>
          <FormItem label={t('FOLDER_NAME')}>
            {getFieldDecorator('folderName', {
              initialValue: isEditFolder ? folderName : '',
              rules: [
                {
                  required: true,
                  message: t('INPUT_FOLDER_NAME_MSG')
                },
                {
                  validator: (rule, value, callback) => {
                    if (
                      value &&
                      value !== folderName &&
                      folders.find(
                        f => f.path === `${breadcrumb}${value.trim()}/`
                      )
                    ) {
                      callback(t('FOLDER_NAME_EXISTS'))
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(
              <TextInput
                onKeyUp={event => {
                  event.key === 'Enter' && handleSaveFolder()
                }}
                placeholder={t('FOLDER_NAME')}
                name="folderName"
                setFieldsValue={setFieldsValue}
              />
            )}
          </FormItem>
          {!isReadonly && (
            <LocationSelect
              label={t('Location')}
              placeholder={t('Select location')}
              required={false}
              getFieldDecorator={getFieldDecorator}
              fieldName="location"
              initialValue={location}
              // setIsChanged={setIsChanged}
              onAddComplete={locationId =>
                setFieldsValue({
                  location: getFieldValue('location') || locationId
                })
              }
              disabled={isReadonly}
              setLocation={value => this.setState({ locationRecord: value })}
            />
          )}

          {locationRecord && (
            <>
              {locationRecord?.image?.find(image => image.level === 1) && (
                <>
                  <div style={{ marginBottom: 5 }}>{locationRecord.level1}</div>
                  <ShowImage
                    recordId={locationRecord._id}
                    level={1}
                    width="100%"
                  />
                  <div style={{ marginBottom: 10 }}></div>
                </>
              )}
              {locationRecord?.image?.find(image => image.level === 2) && (
                <>
                  <div style={{ marginBottom: 5 }}>{locationRecord.level2}</div>
                  <ShowImage
                    recordId={locationRecord._id}
                    level={2}
                    width="100%"
                  />
                  <div style={{ marginBottom: 10 }}></div>
                </>
              )}
              {locationRecord?.image?.find(image => image.level === 3) && (
                <>
                  <div style={{ marginBottom: 5 }}>{locationRecord.level3}</div>
                  <ShowImage
                    recordId={locationRecord._id}
                    level={3}
                    width="100%"
                  />
                </>
              )}
            </>
          )}

          <FormItem label="Folder type">
            {getFieldDecorator('folderType', {
              initialValue: folderType || FOLDER_TYPES.FOLDER
            })(
              <Select showAction={['click', 'focus']}>
                {Object.values(FOLDER_TYPES).map(item => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>

          {getFieldValue('folderType') !== FOLDER_TYPES.FOLDER && (
            <FormItem label="Folder Icon">
              {getFieldDecorator('folderIcon', {
                initialValue: folderIcon || 'folderBlue'
              })(
                <Radio.Group>
                  {folderIcons
                    .filter(item => item.type === getFieldValue('folderType'))
                    .map(item => (
                      <Radio
                        key={item.value}
                        value={item.value}
                        style={{ paddingRight: 45 }}
                      >
                        {item.label}
                        <div style={{ width: '100px' }}>{item.icon}</div>
                      </Radio>
                    ))}
                </Radio.Group>
              )}
            </FormItem>
          )}
        </Form>
      </Modal>
    )
  }
}
const WrappedCreateFolderForm = Form.create({ name: 'CreateFolderModal' })(
  CreateFolderModal
)
export default withTranslation()(WrappedCreateFolderForm)
