import BaseModel from './BaseModel'
import { CONTACT_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class ContactModel extends BaseModel {
  /**
   * Construct a new ContactModel instance
   * @param {object} params - ContactModel creation options:
   * @param {string} [params.type]
   * @param {string} [params.displayName]
   * @param {string} [params.legalName]
   * @param {string} [params.givenName]
   * @param {string} [params.surName]
   * @param {string} [params.description]
   * @param {string} [params.idPassportNumber]
   * @param {string} [params.url]
   * @param {date} [params.dateOfBirth]
   * @param {any} [params.references]
   * @param {any} [params.addresses]
   * @param {any} [params.emails]
   * @param {any} [params.phoneNumbers]
   * @param {string} [params.companyRegistrationNumber]
   * @param {string} [params.countryTerritoryOfIncorporation]
   */
  constructor({
    type = 'Individual',
    displayName = '',
    legalName = '',
    givenName = '',
    surName = '',
    description = '',
    idPassportNumber,
    references,
    url,
    dateOfBirth,
    addresses,
    phoneNumbers,
    companyRegistrationNumber,
    countryTerritoryOfIncorporation,
    status,
    emails,
    createdBy,
    updatedBy,
    createdTime,
    updatedTime,
    version,
    events,
    links
  }) {
    super({
      createdBy,
      updatedBy,
      createdTime,
      updatedTime,
      version
    })

    this.type = type
    this.displayName = displayName
    this.legalName = legalName
    this.givenName = givenName
    this.surName = surName
    this.name =
      type === 'Individual'
        ? givenName + (surName ? ` ${surName}` : '')
        : displayName
    this.description = description
    this.idPassportNumber = idPassportNumber
    this.references = references
    this.url = url
    this.dateOfBirth = dateOfBirth
    this.emails = emails
    this.addresses = addresses
    this.phoneNumbers = phoneNumbers
    this.companyRegistrationNumber = companyRegistrationNumber
    this.countryTerritoryOfIncorporation = countryTerritoryOfIncorporation
    this.createdBy = createdBy
    this.updatedBy = updatedBy
    this.createdTime = createdTime
    this.updatedTime = updatedTime
    this.version = version
    this.status = status
    this.events = events
    this.links = links
  }
}

export default ContactModel

const CONTACT_GENERAL_COLUMNS = () => [
  {
    key: 'email1Name',
    dataIndex: 'emails',
    title: i18next.t('EMAIL_LABEL_WITH_INDEX', {
      index: 1
    }),
    type: IMPORT_COL_TYPES.LIST,
    arrayIndex: 0,
    objectKey: 'name',
    isCustomList: true
  },
  {
    key: 'email1Value',
    dataIndex: 'emails',
    title: i18next.t('EMAIL_VALUE_WITH_INDEX', {
      index: 1
    }),
    arrayIndex: 0,
    objectKey: 'value'
  },
  {
    key: 'email2Name',
    dataIndex: 'emails',
    title: i18next.t('EMAIL_LABEL_WITH_INDEX', {
      index: 2
    }),
    type: IMPORT_COL_TYPES.LIST,
    arrayIndex: 1,
    objectKey: 'name',
    isCustomList: true
  },
  {
    key: 'email2Value',
    dataIndex: 'emails',
    title: i18next.t('EMAIL_VALUE_WITH_INDEX', {
      index: 2
    }),
    arrayIndex: 1,
    objectKey: 'value'
  },
  {
    key: 'email3Name',
    dataIndex: 'emails',
    title: i18next.t('EMAIL_LABEL_WITH_INDEX', {
      index: 3
    }),
    type: IMPORT_COL_TYPES.LIST,
    arrayIndex: 2,
    objectKey: 'name',
    isCustomList: true
  },
  {
    key: 'email3Value',
    dataIndex: 'emails',
    title: i18next.t('EMAIL_VALUE_WITH_INDEX', {
      index: 3
    }),
    arrayIndex: 2,
    objectKey: 'value'
  },
  {
    key: 'address1Name',
    dataIndex: 'addresses',
    title: i18next.t('ADDRESS_LABEL_WITH_INDEX', {
      index: 1
    }),
    type: IMPORT_COL_TYPES.LIST,
    arrayIndex: 0,
    objectKey: 'name',
    isCustomList: true
  },
  {
    key: 'address1Value',
    dataIndex: 'addresses',
    title: i18next.t('ADDRESS_VALUE_WITH_INDEX', {
      index: 1
    }),
    arrayIndex: 0,
    objectKey: 'value'
  },
  {
    key: 'address2Name',
    dataIndex: 'addresses',
    title: i18next.t('ADDRESS_LABEL_WITH_INDEX', {
      index: 2
    }),
    type: IMPORT_COL_TYPES.LIST,
    arrayIndex: 1,
    objectKey: 'name',
    isCustomList: true
  },
  {
    key: 'address2Value',
    dataIndex: 'addresses',
    title: i18next.t('ADDRESS_VALUE_WITH_INDEX', {
      index: 2
    }),
    arrayIndex: 1,
    objectKey: 'value'
  },
  {
    key: 'address3Name',
    dataIndex: 'addresses',
    title: i18next.t('ADDRESS_LABEL_WITH_INDEX', {
      index: 3
    }),
    type: IMPORT_COL_TYPES.LIST,
    arrayIndex: 2,
    objectKey: 'name',
    isCustomList: true
  },
  {
    key: 'address3Value',
    dataIndex: 'addresses',
    title: i18next.t('ADDRESS_VALUE_WITH_INDEX', {
      index: 3
    }),
    arrayIndex: 2,
    objectKey: 'value'
  },
  {
    key: 'phoneNumber1Name',
    dataIndex: 'phoneNumbers',
    title: i18next.t('PHONE_NUMBER_LABEL_WITH_INDEX', {
      index: 1
    }),
    type: IMPORT_COL_TYPES.LIST,
    arrayIndex: 0,
    objectKey: 'name',
    isCustomList: true
  },
  {
    key: 'phoneNumber1Value',
    dataIndex: 'phoneNumbers',
    title: i18next.t('PHONE_NUMBER_VALUE_WITH_INDEX', {
      index: 1
    }),
    arrayIndex: 0,
    objectKey: 'value'
  },
  {
    key: 'phoneNumber2Name',
    dataIndex: 'phoneNumbers',
    title: i18next.t('PHONE_NUMBER_LABEL_WITH_INDEX', {
      index: 1
    }),
    type: IMPORT_COL_TYPES.LIST,
    arrayIndex: 1,
    objectKey: 'name',
    isCustomList: true
  },
  {
    key: 'phoneNumber2Value',
    dataIndex: 'phoneNumbers',
    title: i18next.t('PHONE_NUMBER_VALUE_WITH_INDEX', {
      index: 2
    }),
    arrayIndex: 1,
    objectKey: 'value'
  },
  {
    key: 'phoneNumber3Name',
    dataIndex: 'phoneNumbers',
    title: i18next.t('PHONE_NUMBER_LABEL_WITH_INDEX', {
      index: 1
    }),
    type: IMPORT_COL_TYPES.LIST,
    arrayIndex: 2,
    objectKey: 'name',
    isCustomList: true
  },
  {
    key: 'phoneNumber3Value',
    dataIndex: 'phoneNumbers',
    title: i18next.t('PHONE_NUMBER_VALUE_WITH_INDEX', {
      index: 3
    }),
    arrayIndex: 2,
    objectKey: 'value'
  },
  {
    key: 'reference1Name',
    dataIndex: 'references',
    title: i18next.t('REFERENCE_LABEL_WITH_INDEX', {
      index: 1
    }),
    arrayIndex: 0,
    objectKey: 'name'
  },
  {
    key: 'reference1Value',
    dataIndex: 'references',
    title: i18next.t('REFERENCE_VALUE_WITH_INDEX', {
      index: 1
    }),
    arrayIndex: 0,
    objectKey: 'value'
  },
  {
    key: 'reference2Name',
    dataIndex: 'references',
    title: i18next.t('REFERENCE_LABEL_WITH_INDEX', {
      index: 2
    }),
    arrayIndex: 1,
    objectKey: 'name'
  },
  {
    key: 'reference2Value',
    dataIndex: 'references',
    title: i18next.t('REFERENCE_VALUE_WITH_INDEX', {
      index: 2
    }),
    arrayIndex: 1,
    objectKey: 'value'
  },
  {
    key: 'reference3Name',
    dataIndex: 'references',
    title: i18next.t('REFERENCE_LABEL_WITH_INDEX', {
      index: 3
    }),
    arrayIndex: 2,
    objectKey: 'name'
  },
  {
    key: 'reference3Value',
    dataIndex: 'references',
    title: i18next.t('REFERENCE_VALUE_WITH_INDEX', {
      index: 3
    }),
    arrayIndex: 2,
    objectKey: 'value'
  },
  {
    key: 'url',
    title: i18next.t('URL')
  },
  {
    key: 'tags',
    title: i18next.t('TAGS'),
    type: IMPORT_COL_TYPES.COMMA_SEPARATED
  },
  {
    key: 'description',
    title: i18next.t('DESCRIPTION')
  }
]

export const CONTACT_COLUMNS_BASE_TYPE = type => {
  return type === CONTACT_TYPES.INDIVIDUAL
    ? [
        {
          key: 'givenName',
          title: i18next.t('GIVEN_NAME'),
          required: true
        },
        {
          key: 'surName',
          title: i18next.t('SURNAME')
        },
        {
          key: 'dateOfBirth',
          title: i18next.t('DATE_OF_BIRTH'),
          type: IMPORT_COL_TYPES.DATE
        },
        {
          key: 'idPassportNumber',
          title: i18next.t('ID_PASSPORT_NUMBER')
        },
        ...CONTACT_GENERAL_COLUMNS()
      ]
    : [
        {
          key: 'displayName',
          title: i18next.t('DISPLAY_NAME'),
          required: true
        },
        {
          key: 'legalName',
          title: i18next.t('LEGAL_NAME'),
          required: true
        },
        {
          key: 'companyRegistrationNumber',
          title: i18next.t('COMPANY_REGISTRATION_NUMBER')
        },
        {
          key: 'countryTerritoryOfIncorporation',
          title: i18next.t('COUNTRY_TERRITORY_OF_INCORPORATION'),
          type: IMPORT_COL_TYPES.LIST
        },
        ...CONTACT_GENERAL_COLUMNS()
      ]
}
