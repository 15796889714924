import React, { useContext, useEffect, useState, useCallback } from 'react'
import { H4 } from '../override/Typography'

import Vaults from '../deputy/Vaults'
import AuthContext from '../../contexts/AuthContext'
// import TourContext from '../../contexts/TourContext'
import { onError } from '../../lib/sentry'
import api from '../../lib/api'
import { useTranslation } from 'react-i18next'

export default function SharedData() {
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [vaults, setVaults] = useState([])
  const [isVaultsLoading, setIsVaultsLoading] = useState(false)

  const fetchVaults = useCallback(async () => {
    try {
      setIsVaultsLoading(true)
      const response = await api.getPrimaryUsers(user.username)
      if (response.data?.length) {
        setVaults(response.data.filter(record => record.shareKey))
      }
    } catch (err) {
      onError(err)
    } finally {
      setIsVaultsLoading(false)
    }
  }, [user])

  useEffect(() => {
    fetchVaults()
  }, [fetchVaults])

  return (
    <div className="main-wrapper">
      <H4 style={{ paddingTop: 20 }}>{t('Shared data')}</H4>

      <Vaults
        professionalDeputies={[]}
        vaults={vaults}
        fetchVaults={fetchVaults}
        isLoading={isVaultsLoading}
      />
    </div>
  )
}
