import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import Files from '../file/Files'
import { useTranslation } from 'react-i18next'

const SelectFilesFoldersModal = props => {
  const {
    visible,
    setVisible,
    filteredDocIds,
    selectedFilesFolders,
    setSelectedFilesFolders
  } = props

  const { t } = useTranslation()
  const [newSelectedFilesFolders, setNewSelectedFilesFolders] = useState([])

  useEffect(() => {
    setNewSelectedFilesFolders(selectedFilesFolders)
  }, [selectedFilesFolders])

  const reset = () => {
    setVisible(false)
    setNewSelectedFilesFolders(selectedFilesFolders)
  }

  const handleSave = () => {
    setVisible(false)
    setSelectedFilesFolders(newSelectedFilesFolders)
  }

  return (
    <Modal
      title={t('CHOSSE_FILES_FOLDERS')}
      visible={visible}
      onCancel={reset}
      width={1024}
      okText={t('SAVE')}
      cancelText={t('CANCEL')}
      onOk={handleSave}
      maskClosable={false}
    >
      <Files
        filteredDocIds={filteredDocIds}
        isModal
        isSelectedMutilpleFiles
        setSelectedFilesFolders={setNewSelectedFilesFolders}
        selectedFilesFolders={newSelectedFilesFolders}
      />
    </Modal>
  )
}

export default SelectFilesFoldersModal
