import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'

const initialState = {
  locations: [],
  deletedLocations: [],
  activeLocations: [],
  isLoading: false,
  error: null
}

const location = createSlice({
  name: 'location',
  initialState,
  reducers: {
    getOtherLocationStart(state) {
      state.isLoading = true
      state.error = null
    },
    getOtherLocationSuccess(state, action) {
      const { locations } = action.payload
      state.locations = locations
      state.deletedLocations = locations.filter(item => item.deleted)
      state.activeLocations = locations.filter(item => !item.deleted)
      state.isLoading = false
      state.error = null
    },
    getOtherLocationFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const {
  getOtherLocationStart,
  getOtherLocationSuccess,
  getOtherLocationFailure
} = location.actions

export default location.reducer

export const fetchOtherLocations = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getOtherLocationStart())
    const locations = await getRecords(userId, 'locations', masterKey)
    dispatch(getOtherLocationSuccess({ locations }))
  } catch (err) {
    onError(err)
    dispatch(getOtherLocationFailure(err.toString()))
  }
}
