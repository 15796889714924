import React, { useState, useEffect, useContext } from 'react'
import { s3Get } from '../../lib/awsSDK'
import { decryptFilePromise } from '../../lib/crypto'
import VaultContext from '../../contexts/VaultContext'
import { Modal, Spin } from 'antd'
import { useSelector } from 'react-redux'
import noImage from '../../assets/no-image.png'

const ShowLocationImageModal = ({
  recordId,
  setRecordId,
  level,
  setLevel,
  visible,
  setVisible
}) => {
  const { userId, masterKey, isReadonly } = useContext(VaultContext)
  const [imageUrl, setImageUrl] = useState('')
  const { locations } = useSelector(state =>
    isReadonly ? state.otherLocations : state.locations
  )
  const [isLoading, setIsLoading] = useState(false)
  const [record, setRecord] = useState('')

  useEffect(() => {
    const getUrl = async record => {
      setIsLoading(true)
      if (level && record?.image?.length) {
        const img = record.image.find(img => img.level === level)
        if (img && img.fileId && img.sub) {
          const resBody = await s3Get(
            userId,
            img.fileId,
            { sub: img.sub },
            { responseType: 'blob' }
          )
          const uint8Array = await decryptFilePromise(resBody, masterKey)
          const blob = new Blob([uint8Array])
          const file = new File([blob], img.name, {
            type: img.type
          })
          setImageUrl(URL.createObjectURL(file))
        }
      }
      setIsLoading(false)
    }

    const record = locations.find(item => item._id === recordId)
    if (record && visible) {
      setRecord(record)
      getUrl(record)
    }
  }, [userId, masterKey, locations, recordId, level, visible])

  const onCancel = () => {
    setVisible(false)
    setLevel('')
    setRecordId('')
    setImageUrl('')
  }

  return (
    <>
      <Modal
        visible={visible}
        width={750}
        centered
        style={{ display: 'inline-flex', justifyContent: 'center' }}
        footer={null}
        onCancel={onCancel}
      >
        <Spin spinning={isLoading}>
          {imageUrl ? (
            <>
              <div style={{ fontWeight: 'bold', marginBottom: 5 }}>
                Level {level} -{' '}
                {level === 1
                  ? record.level1
                  : level === 2
                  ? record.level2
                  : record.level3}
              </div>
              <img
                style={{ maxHeight: '80vh', maxWidth: '100%' }}
                src={imageUrl}
                alt="Location"
              />
            </>
          ) : (
            <img
              style={{ maxHeight: '80vh', maxWidth: '100%' }}
              src={noImage}
              alt="Location"
            />
          )}
        </Spin>
      </Modal>
    </>
  )
}

export default ShowLocationImageModal
