import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Modal, Form, Select, Tooltip } from 'antd'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import { getRecords } from '../../lib/pouchDb'
import VaultContext from '../../contexts/VaultContext'
import { DATE_FORMAT } from '../../share/Constants'
import moment from 'moment'
import AddEventModal from './AddEventModal'

const EventLinksModal = props => {
  const { Option } = Select
  const {
    visible,
    setVisible,
    handleLinkEvents,
    form,
    handleClose,
    linkedEvents,
    setLinkedEvents
  } = props
  const { getFieldDecorator } = form

  const [isSaving, setIsSaving] = useState(false)
  const [eventModalVisibile, setEventModalVisibile] = useState(false)
  const { userId, masterKey } = useContext(VaultContext)
  const [events, setEvents] = useState([])
  const { t } = useTranslation()

  const fetchEvents = useCallback(async () => {
    try {
      const events = await getRecords(userId, 'events', masterKey)
      setEvents(events)
    } catch (err) {
      onError(err)
    }
  }, [userId, masterKey])

  useEffect(() => {
    fetchEvents()
  }, [fetchEvents])

  const handleOk = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      setIsSaving(true)
      try {
        if (values.events && values.events.length) {
          await handleLinkEvents(values.events)
        }

        setIsSaving(false)
        form.resetFields()
        setVisible(false)
      } catch (e) {
        setIsSaving(false)
        onError(e)
      }
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setVisible(false)
  }

  return (
    <Modal
      title={t('EVENT_LINKS')}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t('SAVE')}
      cancelText={t('CANCEL')}
      okButtonProps={{ loading: isSaving }}
      maskClosable={false}
    >
      <Form>
        <FormItem style={{ flexGrow: 1 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: 'calc(100% - 58px)' }}>
              {getFieldDecorator('events')(
                <Select mode="multiple" placeholder={t('SELECT_EVENT')}>
                  {events.map(e => (
                    <Option
                      key={e._id}
                      disabled={!!linkedEvents.find(l => l._id === e._id)}
                    >
                      {moment(e.date).format(DATE_FORMAT)} - {e.description}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
            <Tooltip title={t('ADD_NEW_EVENT')}>
              <Button
                size="large"
                onClick={() => setEventModalVisibile(true)}
                style={{ marginLeft: 8 }}
                icon="plus"
              />
            </Tooltip>
          </div>
        </FormItem>
      </Form>
      <AddEventModal
        visible={eventModalVisibile}
        setVisible={setEventModalVisibile}
        handleClose={handleClose}
        selectedRecord={{}}
        fetchEvents={fetchEvents}
        linkedEvents={linkedEvents}
        setLinkedEvents={setLinkedEvents}
      />
    </Modal>
  )
}

const WrappedEventLinksForm = Form.create({
  name: 'EventLinksModal'
})(EventLinksModal)

export default WrappedEventLinksForm
