import React, { useContext, useState } from 'react'
import { Form, Input, Alert, Modal, message } from 'antd'
import {
  getCognitoUser,
  getAuthenticationDetails,
  getUserAttributeValue
} from '../../lib/cognito'
//import { StringResources } from '../../share/StringResources'
import { AES, enc } from 'crypto-js'
import { compareToPassword } from '../../share/formHelpers'
import FormItem from '../override/FormItem'
import { errorMessage } from '../../share/helpers'
import PasswordInputWithRules from '../common/PasswordInputWithRules'
import MfaForm from '../common/MfaVerificationForm'
import { AUTH_FLOW, MFA_TYPES } from '../../share/Constants'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SharedRecoverKey from '../modals/SharedRecoverKeyModal'
import VaultContext from '../../contexts/VaultContext'

let cognitoUser, callbacks, formRef, authDetails

function ChangePasswordForm(props) {
  const { form, visible, setVisible } = props
  const { getFieldDecorator } = props.form
  const { userId } = useContext(VaultContext)
  const [errMsg, setErrMsg] = useState('')
  const [confirmDirty, setConfirmDirty] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [mfaType, setMfaType] = useState()
  const [mfaModalVisible, setMfaModalVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isResendEmailCode, setIsResendEmailCode] = useState(false)
  const [masterKey, setMasterKey] = useState('')
  const [recoverKeyModalVisible, setRecoverKeyModalVisible] = useState(false)

  const { t } = useTranslation()
  const { currentFlow } = useSelector(state => state.user).user

  const authenticateUser = () => {
    const authenticationDetails = getAuthenticationDetails(
      authDetails.username,
      authDetails.currentPassword
    )

    if (currentFlow === AUTH_FLOW.CUSTOM_FLOW) {
      setIsResendEmailCode(true)
      cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')
    }

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function () {
        cognitoUser.changePassword(
          authDetails.currentPassword,
          authDetails.newPassword,
          function (err, result) {
            if (err) {
              setIsSaving(false)
              setErrMsg(t('FAILED_TO_CHANGE_PASSWORD'))
            } else {
              cognitoUser.getUserAttributes((err, data) => {
                const encryptedMasterKey = getUserAttributeValue(
                  data,
                  'custom:master_key'
                )

                const masterKey = AES.decrypt(
                  encryptedMasterKey,
                  authDetails.currentPassword
                ).toString(enc.Latin1)

                setMasterKey(masterKey)

                const newEncryptedMasterKey = AES.encrypt(
                  masterKey,
                  authDetails.newPassword
                ).toString()

                const masterKeyAttribute = {
                  Name: 'custom:master_key',
                  Value: newEncryptedMasterKey
                }

                cognitoUser.updateAttributes(
                  [masterKeyAttribute],
                  (err, data) => {
                    if (err) {
                      setErrMsg(t('FAILED_TO_UPDATE_USER_ATTRIBUTES'))
                    } else {
                      resetFields()
                      handleCancel()
                      message.success(t('SUCCESSFULLY_CHANGED_PASSWORD'))
                    }
                    setIsSaving(false)
                  }
                )
              })

              setRecoverKeyModalVisible(true)
            }
          }
        )
      },
      onFailure: err => {
        setIsSaving(false)
        setIsSubmitting(false)
        setErrMsg(errorMessage(err))
      },
      customChallenge: function () {
        callbacks = this
        setMfaType(MFA_TYPES.EMAIL)
        setIsSubmitting(false)
        setMfaModalVisible(true)
        setIsResendEmailCode(false)
      },
      selectMFAType: function () {
        cognitoUser.sendMFASelectionAnswer(MFA_TYPES.TOTP, this)
      },

      mfaRequired: function () {
        callbacks = this
        setMfaModalVisible(true)
        setMfaType(MFA_TYPES.SMS)
      },

      totpRequired: function () {
        callbacks = this
        setMfaModalVisible(true)
        setMfaType(MFA_TYPES.TOTP)
      }
    })
  }

  const handleSubmit = e => {
    setErrMsg('')
    e.preventDefault()
    form.validateFields((err, values) => {
      if (err) {
        return
      }

      setIsSaving(true)
      cognitoUser = getCognitoUser()

      authDetails = {
        username: cognitoUser.getUsername(),
        currentPassword: values.currentPwd,
        newPassword: values.password
      }

      authenticateUser()
    })
  }

  const handleConfirmBlur = e => {
    const { value } = e.target
    setConfirmDirty(confirmDirty || !!value)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const resetFields = () => {
    setErrMsg('')
    formRef && formRef.props.form.resetFields()
    setMfaModalVisible(false)
    setIsSaving(false)
    setIsSubmitting(false)
  }

  return (
    <>
      <Modal
        title={t('CHANGE_PASSWORD')}
        visible={visible}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okButtonProps={{ loading: isSaving }}
        maskClosable={false}
      >
        <Form className="password-form" layout="vertical" hideRequiredMark>
          {errMsg && (
            <Alert
              message={errMsg}
              type="error"
              closable
              style={{ marginBottom: 16 }}
            />
          )}
          <FormItem label={t('CURRENT_PASSWORD')}>
            {getFieldDecorator('currentPwd', {
              rules: [
                {
                  required: true,
                  message: t('INPUT_CURRENT_PASSWORD_MSG')
                }
              ]
            })(
              <Input.Password
                placeholder={t('CURRENT_PASSWORD')}
                maxLength={30}
              />
            )}
          </FormItem>

          <PasswordInputWithRules
            confirmDirty={confirmDirty}
            title={t('NEW_PASSWORD')}
            form={form}
          />

          <FormItem label={t('CONFIRM_NEW_PASSWORD')} hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: t('CONFIRM_NEW_PASSWORD_MSG')
                },
                {
                  validator: (rule, value, callback) =>
                    compareToPassword(rule, value, callback, form)
                }
              ]
            })(
              <Input.Password
                maxLength={30}
                onBlur={handleConfirmBlur}
                placeholder={t('CONFIRM_NEW_PASSWORD')}
              />
            )}
          </FormItem>
        </Form>
      </Modal>
      <Modal
        visible={mfaModalVisible}
        footer={null}
        closable
        onCancel={resetFields}
      >
        <MfaForm
          wrappedComponentRef={ref => (formRef = ref)}
          cognitoUser={cognitoUser}
          mfaType={mfaType}
          callbacks={callbacks}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          handleResend={authenticateUser}
          isResending={isResendEmailCode}
        />
      </Modal>
      <SharedRecoverKey
        visible={recoverKeyModalVisible}
        setVisible={setRecoverKeyModalVisible}
        userId={userId}
        masterKey={masterKey}
        isChangedPassword={true}
      />
    </>
  )
}

const ChangePassword = Form.create({ name: 'ChangePassword' })(
  ChangePasswordForm
)
export default ChangePassword
