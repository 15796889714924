import React, { useState } from 'react'
import { Form, Alert, Steps } from 'antd'
import { getUserPool } from '../../lib/cognito'
import { CognitoUser } from 'amazon-cognito-identity-js'
//import { StringResources } from '../../share/StringResources'
import ResetPassword from './ResetPassword'
import { P1, H3 } from '../override/Typography'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import { withRouter } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import TextInput from './../common/TextInput'
import { onError } from '../../lib/sentry'

let cognitoUser

function ForgotPasswordForm(props) {
  const { setIsAuthenticated, history, setUser, user } = props
  const { getFieldDecorator } = props.form
  const [errMsg, setErrMsg] = useState('')
  const [currentStep, setCurrentStep] = useState(0)
  const [withAssistantApprovals, setWithAssistantApprovals] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const { Step } = Steps

  const handleSubmit = e => {
    setErrMsg('')
    e.preventDefault()
    props.form.validateFields(async (err, values) => {
      if (err) {
        return err.message
      }
      setIsLoading(true)

      cognitoUser = new CognitoUser({
        Username: values.email,
        Pool: getUserPool()
      })

      cognitoUser.forgotPassword({
        onSuccess: function (result) {},

        onFailure: function (err) {
          setIsLoading(false)
          onError(err)
          //setErrMsg(err.message)
        },

        inputVerificationCode() {
          setIsLoading(false)
          setCurrentStep(1)
        }
      })
    })
  }

  const steps = [
    {
      title: t('EMAIL'),
      content: (
        <Form onSubmit={handleSubmit} layout="vertical" hideRequiredMark={true}>
          {errMsg && (
            <Alert
              message={errMsg}
              type="error"
              closable
              style={{ marginBottom: 16 }}
            />
          )}
          <FormItem label={t('ENTER_YOUR_EMAIL_ADDRESS')}>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: t('INVALID_EMAIL_MSG')
                },
                {
                  required: true,
                  message: t('INPUT_EMAIL_MSG')
                }
              ]
            })(<TextInput placeholder="email@example.com" />)}
          </FormItem>
          <div className="steps-actions">
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={isLoading}
            >
              {t('NEXT')}
            </Button>
          </div>
        </Form>
      )
    },
    {
      title: t('RESET_PASSWORD_BY_EAMIL'),
      content: (
        <ResetPassword
          user={cognitoUser}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          setCurrentStep={setCurrentStep}
          setWithAssistantApprovals={setWithAssistantApprovals}
          setIsAuthenticated={setIsAuthenticated}
          setUser={setUser}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )
    },
    {
      title: t('COMPLETE'),
      content: (
        <>
          {withAssistantApprovals ? (
            <P1>
              Your account recovery request has been sent to your Assistant. You
              will be able to sign in with your new password after your
              Assistant have unlocked your account.
            </P1>
          ) : (
            <>
              <p
                style={{
                  fontWeight: 'bolder',
                  color: 'black',
                  fontSize: '17px'
                }}
              >
                {t('YOUR_PASSWORD_HAS_BEEN_RESET')}
              </p>
              <P1>
                <Trans i18nKey="CLICK_TO_GO_TO_HOMEPAGE"></Trans>
              </P1>
            </>
          )}

          <div className="steps-actions">
            <Button
              type="primary"
              size="large"
              onClick={() => {
                if (user && user.username) {
                  setIsAuthenticated(true)
                }
                history.push('/')
              }}
            >
              Finish
            </Button>
          </div>
        </>
      )
    }
  ]

  return (
    <div style={{ margin: 15, flexGrow: 1 }}>
      <div className="form-header">
        <H3>{t('ACCOUNT_RECOVERY')}</H3>
        <P1>{t('RECOVER_ACCESS_TO_YOUR_ACCOUNT')}</P1>
      </div>
      <Steps className="reset-password-steps" current={currentStep}>
        {steps.map(step => (
          <Step title={step.title} key={step.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[currentStep].content}</div>
    </div>
  )
}

const WrappedForgotPasswordForm = Form.create({ name: 'forgotPassword' })(
  ForgotPasswordForm
)
export default withRouter(WrappedForgotPasswordForm)
