import React, { useState, useContext, useRef, useEffect } from 'react'
import { Modal, Form, AutoComplete, message, Alert, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { uploadEncryptedData, updateExistedLocation } from '../../lib/pouchDb'
import Button from '../override/Button'
import PouchDB from 'pouchdb'
import uuidv4 from 'uuid/v4'
import VaultContext from '../../contexts/VaultContext'
import FormItem from '../override/FormItem'
import { onError } from '../../lib/sentry'
import { uniq } from 'lodash'
import { useDispatch } from 'react-redux'
import { fetchLocations } from '../../features/location/LocationSlice'
import { toBase64, uploadPictureLocation } from './../../share/helpers'
import WrappedLevelPictureForm from './LevelPictureModal'
import { logDocumentActivity } from '../../share/logs'
import { getUserData } from '../../lib/cognito'
import AuthContext from '../../contexts/AuthContext'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import { s3Get } from '../../lib/awsSDK'
import { decryptFilePromise } from '../../lib/crypto'

const LocationModal = props => {
  const {
    isEditMode,
    setIsEditMode,
    setVisible,
    visible,
    selectedLocation,
    setSelectedLocation,
    onAddComplete,
    locations,
    form
  } = props
  const { getFieldDecorator } = form
  const { userId, masterKey, isReadonly } = useContext(VaultContext)
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [imageLevel1, setImageLevel1] = useState('')
  const [imageLevel2, setImageLevel2] = useState('')
  const [imageLevel3, setImageLevel3] = useState('')
  const [levelPicture, setLevelPicture] = useState('')
  const [urlLevel1, setUrlLevel1] = useState('')
  const [urlLevel2, setUrlLevel2] = useState('')
  const [urlLevel3, setUrlLevel3] = useState('')
  const [autoCompleteLevel1, setAutoCompleteLevel1] = useState('')
  const [autoCompleteLevel2, setAutoCompleteLevel2] = useState('')
  const [autoCompleteLevel3, setAutoCompleteLevel3] = useState('')
  const [levelPictureModalVisible, setLevelPictureModalVisible] =
    useState(false)
  const levelPictureFormRef = useRef()

  const [addS3Change] = useMutation(createS3Change)

  useEffect(() => {
    const getUrl = async img => {
      const resBody = await s3Get(
        userId,
        img.fileId,
        { sub: img.sub },
        { responseType: 'blob' }
      )
      const uint8Array = await decryptFilePromise(resBody, masterKey)
      const blob = new Blob([uint8Array])
      const file = new File([blob], img.name, {
        type: img.type
      })
      return await toBase64(file)
    }

    const setUrl = async () => {
      if (locations?.length && autoCompleteLevel1 && !imageLevel1) {
        const existedLocation = locations.find(
          location =>
            location.level1 === autoCompleteLevel1 &&
            location.image?.some(img => img.level === 1)
        )

        if (!!existedLocation) {
          const img = existedLocation.image.find(img => img.level === 1)
          const url = await getUrl(img)
          setUrlLevel1(url)
        }
      }

      if (locations?.length && autoCompleteLevel2 && !imageLevel2) {
        const existedLocation = locations.find(
          location =>
            location.level2 === autoCompleteLevel2 &&
            location.image?.some(img => img.level === 2)
        )

        if (!!existedLocation) {
          const img = existedLocation.image.find(img => img.level === 2)
          const url = await getUrl(img)
          setUrlLevel2(url)
        }
      }

      if (locations?.length && autoCompleteLevel3 && !imageLevel3) {
        const existedLocation = locations.find(
          location =>
            location.level3 === autoCompleteLevel3 &&
            location.image?.some(img => img.level === 3)
        )

        if (!!existedLocation) {
          const img = existedLocation.image.find(img => img.level === 3)
          const url = await getUrl(img)
          setUrlLevel3(url)
        }
      }
    }

    if (visible) {
      setUrl()
    }
  }, [
    visible,
    locations,
    masterKey,
    userId,
    imageLevel1,
    imageLevel2,
    imageLevel3,
    autoCompleteLevel1,
    autoCompleteLevel2,
    autoCompleteLevel3
  ])

  const handelSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      const existedLocation =
        !isEditMode &&
        locations.find(
          location =>
            location.level1 === values.level1 &&
            location.level2 === values.level2 &&
            location.level3 === values.level3
        )

      if (existedLocation) {
        setErrMsg('This location already exists!')
        return
      }

      let listImage = []
      let fileIdLevel1,
        fileIdLevel2,
        fileIdLevel3,
        subLevel1,
        subLevel2,
        subLevel3
      try {
        setIsSaving(true)
        const db = new PouchDB(`${userId}_locations`)

        if (imageLevel1) {
          fileIdLevel1 = uuidv4()
          subLevel1 = uuidv4()
          await uploadPictureLocation(
            imageLevel1,
            fileIdLevel1,
            subLevel1,
            userId,
            masterKey
          )

          const imageRecord = {
            level: 1,
            fileId: fileIdLevel1,
            name: imageLevel1[0]?.name,
            type: imageLevel1[0]?.type,
            sub: subLevel1
          }

          const updateRecordIds =
            locations
              .filter(
                location =>
                  location.level1 === values.level1 &&
                  location._id !== selectedLocation._id
              )
              .map(location => location._id) || []

          if (updateRecordIds.length) {
            await updateExistedLocation(
              userId,
              masterKey,
              updateRecordIds,
              imageRecord,
              1
            )
          }

          listImage.push(imageRecord)
        } else if (autoCompleteLevel1 && urlLevel1) {
          const existedLocation = locations.find(
            location =>
              location.level1 === autoCompleteLevel1 &&
              location.image?.some(img => img.level === 1)
          )
          if (existedLocation) {
            const img = existedLocation?.image?.find(img => img.level === 1)
            img && listImage.push(img)
          } else {
            const image = selectedLocation?.image?.find(img => img.level === 1)
            image && listImage.push(image)
          }
        } else {
          const image = selectedLocation?.image?.find(img => img.level === 1)
          image && listImage.push(image)
        }

        if (imageLevel2) {
          fileIdLevel2 = uuidv4()
          subLevel2 = uuidv4()
          await uploadPictureLocation(
            imageLevel2,
            fileIdLevel2,
            subLevel2,
            userId,
            masterKey
          )

          const imageRecord = {
            level: 2,
            fileId: fileIdLevel2,
            name: imageLevel2[0]?.name,
            type: imageLevel2[0]?.type,
            sub: subLevel2
          }

          const updateRecordIds = locations
            .filter(
              location =>
                location.level2 === values.level2 &&
                location._id !== selectedLocation._id
            )
            .map(location => location._id)

          if (updateRecordIds.length) {
            await updateExistedLocation(
              userId,
              masterKey,
              updateRecordIds,
              imageRecord,
              2
            )
          }

          listImage.push(imageRecord)
        } else if (autoCompleteLevel2 && urlLevel2) {
          const existedLocation = locations.find(
            location =>
              location.level2 === autoCompleteLevel2 &&
              location.image?.some(img => img.level === 2)
          )
          if (existedLocation) {
            const img = existedLocation?.image?.find(img => img.level === 2)
            img && listImage.push(img)
          } else {
            const image = selectedLocation?.image?.find(img => img.level === 2)
            image && listImage.push(image)
          }
        } else {
          const image = selectedLocation?.image?.find(img => img.level === 2)
          image && listImage.push(image)
        }

        if (imageLevel3) {
          fileIdLevel3 = uuidv4()
          subLevel3 = uuidv4()
          await uploadPictureLocation(
            imageLevel3,
            fileIdLevel3,
            subLevel3,
            userId,
            masterKey
          )

          const imageRecord = {
            level: 3,
            fileId: fileIdLevel3,
            name: imageLevel3[0]?.name,
            type: imageLevel3[0]?.type,
            sub: subLevel3
          }
          const updateRecordIds = locations
            .filter(
              location =>
                location.level3 === values.level3 &&
                location._id !== selectedLocation._id
            )
            .map(location => location._id)

          if (updateRecordIds.length) {
            await updateExistedLocation(
              userId,
              masterKey,
              updateRecordIds,
              imageRecord,
              3
            )
          }

          listImage.push(imageRecord)
        } else if (autoCompleteLevel3 && urlLevel3) {
          const existedLocation = locations.find(
            location =>
              location.level3 === autoCompleteLevel3 &&
              location.image?.some(img => img.level === 3)
          )
          if (existedLocation) {
            const img = existedLocation?.image?.find(img => img.level === 3)
            img && listImage.push(img)
          } else {
            const image = selectedLocation?.image?.find(img => img.level === 3)
            image && listImage.push(image)
          }
        } else {
          const image = selectedLocation?.image?.find(img => img.level === 3)
          image && listImage.push(image)
        }

        const newRecord = {
          _id: selectedLocation?._id || uuidv4(),
          ...values,
          image: listImage.length
            ? listImage
            : selectedLocation?.image
            ? selectedLocation?.image
            : [],
          _rev: selectedLocation?._rev
        }

        db.crypto(masterKey)
        await db.put(newRecord)
        await uploadEncryptedData(db, userId, 'locations')
        dispatch(fetchLocations(userId, masterKey))
        onAddComplete()
        handleCancel()

        if (isReadonly) {
          getUserData(
            user,
            async (err, data) => {
              if (err) {
                onError(err)
                return
              }

              await logDocumentActivity(
                userId,
                data.UserAttributes,
                user.username,
                newRecord._id,
                {
                  id: newRecord._id,
                  type: 'location',
                  name: newRecord.level3
                    ? `${newRecord.level1} - ${newRecord.level2} - ${newRecord.level3}`
                    : newRecord.level2
                    ? `${newRecord.level1} - ${newRecord.level2}`
                    : `${newRecord.level1}`,
                  action: 'edited'
                }
              )
            },
            { bypassCache: true }
          )
        }

        message.success(t('Successfully saved location'))
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message: 'locations',
            userId: userId
          }
        })
      } catch (error) {
        message.error(t('Failed to save location'))
        onError(error)
      } finally {
        setVisible(false)
        form.resetFields()
        levelPictureFormRef.current.resetFields()
        setSelectedLocation && setSelectedLocation({})
        setIsSaving(false)
        setImageLevel1('')
        setImageLevel2('')
        setImageLevel3('')
        setUrlLevel1('')
        setUrlLevel2('')
        setUrlLevel3('')
        setAutoCompleteLevel1('')
        setAutoCompleteLevel2('')
        setAutoCompleteLevel3('')
        if (isEditMode) {
          setIsEditMode(false)
        }
      }
    })
  }

  const handleCancel = () => {
    setErrMsg('')
    setVisible(false)
    form.resetFields()
    setSelectedLocation && setSelectedLocation({})
    setImageLevel1('')
    setImageLevel2('')
    setImageLevel1('')
    setUrlLevel1('')
    setUrlLevel2('')
    setUrlLevel3('')
    setLevelPicture('')
    setAutoCompleteLevel1('')
    setAutoCompleteLevel2('')
    setAutoCompleteLevel3('')
    if (isEditMode) {
      setIsEditMode(false)
    }
  }

  return (
    <>
      <Modal
        title={t('Location Details')}
        visible={visible}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button key="cancle" onClick={handleCancel}>
            {t('CANCEL')}
          </Button>,
          <Button
            key="save"
            type="primary"
            loading={isSaving}
            onClick={handelSave}
          >
            {t('SAVE')}
          </Button>
        ]}
      >
        <Form>
          {errMsg && (
            <Alert
              type="error"
              message={errMsg}
              closable
              onClose={() => setErrMsg('')}
            />
          )}
          <FormItem label={t('Level 1')}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 'calc(100% - 58px)', marginRight: '8px' }}>
                {getFieldDecorator('level1', {
                  initialValue: selectedLocation.level1,
                  rules: [
                    { required: true, message: t('Enter location level 1!') }
                  ]
                })(
                  <AutoComplete
                    onChange={value => setAutoCompleteLevel1(value)}
                    dataSource={uniq(
                      locations.filter(lc => lc.level1).map(lc => lc.level1)
                    )}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                )}
              </div>
              {urlLevel1 ? (
                <img
                  style={{
                    cursor: 'pointer',
                    maxHeight: '50px',
                    maxWidth: '50px',
                    display: 'block',
                    margin: 'auto'
                  }}
                  src={urlLevel1}
                  alt="Location"
                  onClick={() => {
                    setLevelPictureModalVisible(true)
                    setLevelPicture(1)
                  }}
                />
              ) : (
                <Tooltip title={t('ADD_NEW')}>
                  <Button
                    size="large"
                    onClick={() => {
                      setLevelPictureModalVisible(true)
                      setLevelPicture(1)
                    }}
                    icon="picture"
                    disabled={!form.getFieldValue('level1')}
                  />
                </Tooltip>
              )}
            </div>
          </FormItem>
          {form.getFieldValue('level1') && (
            <FormItem label={t('Level 2')}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: 'calc(100% - 58px)', marginRight: '8px' }}>
                  {getFieldDecorator('level2', {
                    initialValue: selectedLocation.level2
                  })(
                    <AutoComplete
                      onChange={value => setAutoCompleteLevel2(value)}
                      dataSource={uniq(
                        locations.filter(lc => lc.level2).map(lc => lc.level2)
                      )}
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  )}
                </div>
                {urlLevel2 ? (
                  <img
                    style={{
                      cursor: 'pointer',
                      maxHeight: '50px',
                      maxWidth: '50px',
                      display: 'block',
                      margin: 'auto'
                    }}
                    src={urlLevel2}
                    alt="Location"
                    onClick={() => {
                      setLevelPictureModalVisible(true)
                      setLevelPicture(2)
                    }}
                  />
                ) : (
                  <Tooltip title={t('ADD_NEW')}>
                    <Button
                      size="large"
                      onClick={() => {
                        setLevelPictureModalVisible(true)
                        setLevelPicture(2)
                      }}
                      icon="picture"
                      disabled={!form.getFieldValue('level2')}
                    />
                  </Tooltip>
                )}
              </div>
            </FormItem>
          )}
          {form.getFieldValue('level2') && (
            <FormItem label={t('Level 3')}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: 'calc(100% - 58px)', marginRight: '8px' }}>
                  {getFieldDecorator('level3', {
                    initialValue: selectedLocation.level3
                  })(
                    <AutoComplete
                      onChange={value => setAutoCompleteLevel3(value)}
                      dataSource={uniq(
                        locations.filter(lc => lc.level3).map(lc => lc.level3)
                      )}
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  )}
                </div>
                {urlLevel3 ? (
                  <img
                    style={{
                      cursor: 'pointer',
                      maxHeight: '50px',
                      maxWidth: '50px',
                      display: 'block',
                      margin: 'auto'
                    }}
                    src={urlLevel3}
                    alt="Location"
                    onClick={() => {
                      setLevelPictureModalVisible(true)
                      setLevelPicture(3)
                    }}
                  />
                ) : (
                  <Tooltip title={t('ADD_NEW')}>
                    <Button
                      size="large"
                      onClick={() => {
                        setLevelPictureModalVisible(true)
                        setLevelPicture(3)
                      }}
                      icon="picture"
                      disabled={!form.getFieldValue('level3')}
                    />
                  </Tooltip>
                )}
              </div>
            </FormItem>
          )}
        </Form>
      </Modal>
      <WrappedLevelPictureForm
        ref={levelPictureFormRef}
        userId={userId}
        masterKey={masterKey}
        selectedLocation={selectedLocation}
        visible={levelPictureModalVisible}
        setVisible={setLevelPictureModalVisible}
        levelPicture={levelPicture}
        setLevelPicture={setLevelPicture}
        setImageLevel1={setImageLevel1}
        setImageLevel2={setImageLevel2}
        setImageLevel3={setImageLevel3}
        urlLevel1={urlLevel1}
        urlLevel2={urlLevel2}
        urlLevel3={urlLevel3}
        setUrlLevel1={setUrlLevel1}
        setUrlLevel2={setUrlLevel2}
        setUrlLevel3={setUrlLevel3}
      />
    </>
  )
}

const WrappedPasswordForm = Form.create({ name: 'LocationModal' })(
  LocationModal
)

export default WrappedPasswordForm
