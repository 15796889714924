import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import {
  PageHeader,
  Layout,
  Table,
  Input,
  Divider,
  Icon,
  Menu,
  message,
  Dropdown,
  Button,
  Modal,
  Tooltip
} from 'antd'
import { ThemeContext } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { H4, Span } from '../override/Typography'
import AssetLiabilityInformation from '../assets-liabilities/AssetLiabilityInformation'
import ContactInformation from '../contacts/ContactInformation'
import FileInformation from '../file/FileInformation'
import { search } from '../../share/helpers'
import VaultContext from '../../contexts/VaultContext'
import AuthContext from '../../contexts/AuthContext'
import {
  permanentlyDeleteItems,
  restoreItems,
  permanentlyDeleteDocument,
  //restoreDocument,
  restoreValuation,
  permanentlyDeleteDocuments,
  restoreDocuments,
  permanentlyDeleteLocations
} from '../../lib/pouchDb'
import { DATE_FORMAT, ENTITY_TYPES } from '../../share/Constants'
import { fetchPendingUnlockFiles } from '../../features/documents/documentsSlice'
import { onError } from '../../lib/sentry'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Flex from '../override/Flex'
import { useTranslation, Trans } from 'react-i18next'
import i18next from 'i18next'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import moment from 'moment'
import PasswordInformation from '../passwords/PasswordInformation'
import LocationInformation from '../location/LocationInformation'
import EventInformation from '../calendar/EventInformation'
import {
  permanentlyDeleteEvents,
  restoreEvents
} from '../calendar/eventHelpers'
import { fetchEvents } from './../../features/events/eventsSlice'
import { uniq } from 'lodash'
import ShowImage from '../common/ShowImage'
import { fetchUser } from '../../features/user/userSlice'

const { Content, Sider } = Layout

const Trash = () => {
  const { userId, isReadonly, masterKey } = useContext(VaultContext)
  const { user } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const { deletedAssetsLiabilities } = useSelector(state =>
    isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { deletedContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )
  const { deletedPasswords } = useSelector(state => state.passwords)
  const { deletedLocations } = useSelector(state => state.locations)
  const { deletedEvents } = useSelector(state => state.events)

  const { deletedDocuments, activeFolders, documents } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )

  const dispatch = useDispatch()
  const [selectedRecord, setSelectedRecord] = useState()
  const [selectedRecordType, setSelectedRecordType] = useState('')
  // const [sourceAssetsLiabilities, setSourceAssetsLiabilities] = useState([])
  const [sourceContacts, setSourceContacts] = useState([])
  const [sourcePasswords, setSourcePasswords] = useState([])
  const [sourceLocations, setSourceLocations] = useState([])
  const [sourceEvents, setSourceEvents] = useState([])
  const [sourceDocuments, setSourceDocuments] = useState([])
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [showPassword, setShowPassword] = useState('')

  const isUpMD = useMediaQuery(theme.breakpoints.up('md'))

  const [addS3Change] = useMutation(createS3Change)

  // useEffect(() => {
  //   setSourceAssetsLiabilities([...deletedAssetsLiabilities])
  // }, [deletedAssetsLiabilities])

  useEffect(() => {
    setSourceContacts([...deletedContacts])
    setSourcePasswords([...deletedPasswords])
    setSourceLocations([...deletedLocations])
    setSourceEvents([...deletedEvents])
  }, [deletedContacts, deletedPasswords, deletedLocations, deletedEvents])

  useEffect(() => {
    const sourceDocuments = deletedDocuments
      .filter(df => !df.deletedFolderId)
      .map(doc => {
        if (doc.fileName) {
          return {
            _id: doc._id,
            name: doc.fileName,
            description: doc.description,
            tags: doc.tags,
            type: 'file-text',
            size: doc.file[0].size,
            path: doc.path,
            fileId: doc.fileId,
            uploadTime: doc.uploadTime
          }
        } else {
          const folders = doc.path.split('/').slice(0, -1)
          const subItems = deletedDocuments.filter(
            df => df.deletedFolderId === doc._id
          )
          const filesCount = subItems.filter(df => df.fileName).length
          const foldersCount = subItems.filter(df => !df.fileName).length

          return {
            name: folders[folders.length - 1],
            type: 'folder',
            path: doc.path,
            _id: doc._id,
            description: `${foldersCount} ${i18next.t(
              'FOLDERS'
            )} & ${filesCount} ${i18next.t('FILES')}`
          }
        }
      })

    setSourceDocuments(sourceDocuments)
    setFilteredDocuments(sourceDocuments)
  }, [deletedDocuments])

  // const assetsLiabilitiesColumns = [
  //   {
  //     title: t('TITLE'),
  //     dataIndex: 'title',
  //     key: 'title'
  //   },
  //   {
  //     title: t('DESCRIPTION'),
  //     dataIndex: 'description',
  //     key: 'description'
  //   },
  //   {
  //     key: 'actions',
  //     dataIndex: 'actions',
  //     align: 'right',
  //     render: (text, record) => actionsDropdown('assetsLiabilities', record)
  //   }
  // ]

  const contactsColumns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('DESCRIPTION'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => actionsDropdown('contacts', record)
    }
  ]

  const documentsColumns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('DETAILS'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) =>
        actionsDropdown('documents', record, deletedDocuments, activeFolders)
    }
  ]

  const passwordColumns = [
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: t('USERNAME'),
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: t('PASSWORD'),
      dataIndex: 'password',
      key: 'password',
      render: (text, record) => {
        const passwordLength = record.password.length
        let hidenPassword = ''
        for (let i = 0; i < passwordLength; i++) {
          hidenPassword += '*'
        }
        if (showPassword === record._id) {
          return (
            <>
              <span style={{ marginRight: '5px' }}>{record.password}</span>
              <Icon onClick={() => setShowPassword('')} type="eye-invisible" />
            </>
          )
        } else {
          return (
            <>
              <span style={{ marginRight: '5px' }}>{hidenPassword}</span>
              <Icon onClick={() => setShowPassword(record._id)} type="eye" />
            </>
          )
        }
      }
    },
    {
      title: t('URL'),
      dataIndex: 'url',
      key: 'url',
      render: (text, record) => {
        const url = record.url
        if (url?.length > 100) {
          const showURL = url.slice(0, 97).concat('...')
          return (
            <Tooltip placement="bottom" title={url}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {showURL}
              </a>
            </Tooltip>
          )
        } else {
          return (
            <Tooltip placement="bottom" title={url}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            </Tooltip>
          )
        }
      }
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => actionsDropdown('passwords', record)
    }
  ]

  const locationColumns = [
    {
      key: 'level1',
      dataIndex: 'level1',
      title: t('Level 1'),
      filters: uniq(
        deletedLocations.filter(lc => lc.level1).map(lc => lc.level1)
      ).map(value => {
        return {
          value: value,
          text: value
        }
      }),
      onFilter: (value, record) => record.level1.indexOf(value) === 0,
      sorter: (a, b) => a.level1?.localeCompare(b.level1),
      render: (text, record) =>
        text && (
          <>
            <ShowImage recordId={record._id} level={1} />
            <span style={{ marginLeft: 5 }}>{text}</span>
          </>
        )
    },
    {
      key: 'level2',
      dataIndex: 'level2',
      title: 'Level 2',
      filters: uniq(
        deletedLocations.filter(lc => lc.level2).map(lc => lc.level2)
      ).map(value => {
        return {
          value: value,
          text: value
        }
      }),
      onFilter: (value, record) => record.level2.indexOf(value) === 0,
      sorter: (a, b) => a.level2?.localeCompare(b.level2),
      render: (text, record) =>
        text && (
          <>
            <ShowImage recordId={record._id} level={2} />
            <span style={{ marginLeft: 5 }}>{text}</span>
          </>
        )
    },
    {
      key: 'level3',
      dataIndex: 'level3',
      title: 'Level 3',
      filters: uniq(
        deletedLocations.filter(lc => lc.level3).map(lc => lc.level3)
      ).map(value => {
        return {
          value: value,
          text: value
        }
      }),
      onFilter: (value, record) => record.level3.indexOf(value) === 0,
      sorter: (a, b) => a.level3?.localeCompare(b.level3),
      render: (text, record) =>
        text && (
          <>
            <ShowImage recordId={record._id} level={3} />
            <span style={{ marginLeft: 5 }}>{text}</span>
          </>
        )
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => actionsDropdown('locations', record)
    }
  ]

  const eventColumns = [
    {
      key: 'description',
      dataIndex: 'description',
      title: t('DESCRIPTION')
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: t('DATE'),
      render: (text, record) => moment(record.date).format(DATE_FORMAT)
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => actionsDropdown('events', record)
    }
  ]

  const handleRestore = async (e, dbName, records = []) => {
    try {
      switch (dbName) {
        case 'documents':
          await restoreDocuments(
            user,
            userId,
            deletedDocuments,
            documents.filter(d => !d.fileName) || [],
            records,
            masterKey,
            activeFolders
          )
          break
        case 'contacts':
          await restoreItems(
            dbName,
            userId,
            !!records.length ? records : deletedContacts,
            masterKey
          )
          break
        case 'passwords':
          await restoreItems(
            dbName,
            userId,
            !!records.length ? records : deletedPasswords,
            masterKey
          )
          break
        case 'locations':
          await restoreItems(
            dbName,
            userId,
            !!records.length ? records : deletedLocations,
            masterKey
          )
          break
        case 'events':
          if (!!records.length) {
            await restoreEvents(userId, records)
          } else {
            await restoreEvents(userId, deletedEvents, true)
          }
          dispatch(fetchEvents(userId, masterKey))
          break
        case 'assetsLiabilities':
          await restoreItems(
            dbName,
            userId,
            !!records.length ? records : deletedAssetsLiabilities,
            masterKey
          )
          await restoreValuation(
            userId,
            !!records.length ? records : deletedAssetsLiabilities,
            masterKey
          )
          break
        default:
          break
      }

      setSelectedRecord()
      setSelectedRecordType('')
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: dbName,
          userId: userId
        }
      })
      message.success(t('SUCCESSFULLY_RESTORED_ITEMS'))
    } catch (err) {
      message.error(t('FAILED_TO_RESTORE_ITEMS'))
      onError(err)
    }
  }

  const handlePermanentlyDelete = (e, dbName, record) => {
    Modal.confirm({
      title: t('PERMANENTLY_DELETE'),
      content: t('PERMANENTLY_DELETE_ITEM_CONFIRM'),
      async onOk() {
        try {
          if (dbName === 'documents') {
            await permanentlyDeleteDocument(
              userId,
              record,
              deletedDocuments,
              masterKey
            )
          } else if (dbName === 'events') {
            await permanentlyDeleteEvents(userId, [record], masterKey)
          } else if (dbName === 'locations') {
            await permanentlyDeleteLocations(userId, [record], masterKey)
          } else {
            await permanentlyDeleteItems(dbName, userId, [record], masterKey)
          }

          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message: dbName,
              userId: userId
            }
          })
          dispatch(fetchEvents(userId, masterKey))
          dispatch(fetchUser(userId))
          dispatch(fetchPendingUnlockFiles(userId))
          setSelectedRecord()
          setSelectedRecordType('')

          message.success(t('SUCCESSFULLY_DELETED_ITEM'))
        } catch (err) {
          message.error(t('FAILED_TO_DELETE_ITEM'))
          onError(err)
        }
      }
    })
  }

  const handlePermanentlyDeleteDocuments = e => {
    Modal.confirm({
      title: t('PERMANENTLY_DELETE'),
      content: t('PERMANENTLY_DELETE_ITEMS_CONFIRM'),
      async onOk() {
        try {
          await permanentlyDeleteDocuments(
            userId,
            selectedRows,
            deletedDocuments,
            masterKey
          )
          dispatch(fetchPendingUnlockFiles(userId))
          setSelectedRows([])
          setSelectedRecord()
          setSelectedRecordType('')
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message: 'documents',
              userId: userId
            }
          })
          message.success(
            <Trans
              i18nKey="SUCCESSFULLY_DELETED_ITEMS"
              values={{ count: selectedRows.length }}
            ></Trans>
          )
        } catch (err) {
          message.error(t('FAILED_TO_DELETE_ITEMS'))
          onError(err)
        }
      }
    })
  }

  const actionsDropdown = (dbName, record) => {
    return (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item>
              <Button
                type="link"
                onClick={e => handleRestore(e, dbName, [record])}
              >
                {t('RESTORE')}
              </Button>
            </Menu.Item>
            <Menu.Item>
              <Button
                type="link"
                onClick={e => handlePermanentlyDelete(e, dbName, record)}
              >
                {t('DELETE')}
              </Button>
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <Icon type="ellipsis" onClick={e => e.stopPropagation()} />
      </Dropdown>
    )
  }

  const showModalDeleteAll = dbName => {
    Modal.confirm({
      title: t('PERMANENTLY_DELETE_ALL'),
      content: t('PERMANENTLY_DELETE_ALL_CONFIRM'),
      async onOk() {
        try {
          switch (dbName) {
            case 'documents':
              await permanentlyDeleteDocuments(
                userId,
                deletedDocuments,
                deletedDocuments,
                masterKey
              )
              break
            case 'contacts':
              await permanentlyDeleteItems(
                dbName,
                userId,
                deletedContacts,
                masterKey
              )
              break
            case 'passwords':
              await permanentlyDeleteItems(
                dbName,
                userId,
                deletedPasswords,
                masterKey
              )
              break
            case 'locations':
              await permanentlyDeleteLocations(
                userId,
                deletedLocations,
                masterKey
              )
              break
            case 'events':
              await permanentlyDeleteEvents(userId, deletedEvents, masterKey)
              break
            default:
              break
          }

          dispatch(fetchEvents(userId, masterKey))
          dispatch(fetchUser(userId))
          dispatch(fetchPendingUnlockFiles(userId))
          setSelectedRows([])
          setSelectedRecord()
          setSelectedRecordType('')
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message: dbName,
              userId: userId
            }
          })
          message.success(t('SUCCESSFULLY_DELETED_ALL_ITEMS'))
        } catch (err) {
          message.error(t('FAILED_TO_DELETE_ITEMS'))
          onError(err)
        }
      }
    })
  }

  const showModalRestoreAll = dbName => {
    Modal.confirm({
      title: t('RESTORE_ALL'),
      content: t('RESTORE_ALL_CONFIRM'),
      async onOk() {
        try {
          await handleRestore(null, dbName)
          // message.success(`Successfully restored all items`)
        } catch (err) {
          // message.error('Failed to restore items')
          onError(err)
        }
      }
    })
  }

  const bulkActionsMenu = dbName => (
    <Menu>
      <Menu.Item key="1" onClick={() => showModalDeleteAll(dbName)}>
        {t('PERMANENTLY_DELETE_ALL')}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => showModalRestoreAll(dbName)}>
        {t('RESTORE_ALL')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout style={{ height: '100%' }}>
      <Content style={{ backgroundColor: '#fff', padding: 20 }}>
        {/* <PageHeader
          title={
            <H4 display="inline-block">{t('DELETE_ASSETS_AND_LIABILITIES')}</H4>
          }
          subTitle={
            <H4 display="inline-block" color={theme.dark2}>
              {sourceAssetsLiabilities.length}
            </H4>
          }
          extra={
            <Flex className="auto-gap" style={{ flexWrap: 'wrap' }}>
              {!isReadonly && !!deletedAssetsLiabilities?.length && (
                <Dropdown overlay={() => bulkActionsMenu('assetsLiabilities')}>
                  <Button icon="down">{t('ACTIONS')}</Button>
                </Dropdown>
              )}

              <Input.Search
                placeholder={t('SEARCH_ASSET_OR_LIABILITY')}
                allowClear
                onSearch={value => {
                  const assetsLiabilities = search(
                    deletedAssetsLiabilities,
                    ['title'],
                    value
                  )
                  setSourceAssetsLiabilities(assetsLiabilities)
                }}
                style={{ width: 300 }}
              />
            </Flex>
          }
          style={{ padding: '0 0 20px' }}
        />
        <Table
          columns={
            isReadonly
              ? assetsLiabilitiesColumns.filter(col => col.key !== 'actions')
              : assetsLiabilitiesColumns
          }
          dataSource={sourceAssetsLiabilities}
          rowKey="_id"
          onRow={record => ({
            onClick: () => {
              setSelectedRecord(record)
              setSelectedRecordType(ENTITY_TYPES.ASSET_LIABILITY)
            }
          })}
          rowClassName={record =>
            `list-item ${
              selectedRecord && record._id === selectedRecord._id
                ? 'selected-item'
                : ''
            }`
          }
        />
        <Divider type="horizontal"></Divider> */}
        <PageHeader
          title={<H4 display="inline-block">{t('DELETED_CONTACTS')}</H4>}
          subTitle={
            <H4 display="inline-block" color={theme.dark2}>
              {sourceContacts.length}
            </H4>
          }
          extra={
            <Flex className="auto-gap" style={{ flexWrap: 'wrap' }}>
              {!isReadonly && !!deletedContacts?.length && (
                <Dropdown overlay={() => bulkActionsMenu('contacts')}>
                  <Button icon="down">{t('ACTIONS')}</Button>
                </Dropdown>
              )}

              <Input.Search
                placeholder={t('SEARCH_CONTACT')}
                allowClear
                onSearch={value => {
                  const contacts = search(deletedContacts, ['name'], value)
                  setSourceContacts(contacts)
                }}
                style={{ width: 300 }}
              />
            </Flex>
          }
          style={{ padding: '0 0 20px' }}
        />
        <Table
          columns={
            isReadonly
              ? contactsColumns.filter(col => col.key !== 'actions')
              : contactsColumns
          }
          dataSource={sourceContacts}
          rowKey="_id"
          onRow={record => ({
            onClick: () => {
              setSelectedRecord(record)
              setSelectedRecordType(ENTITY_TYPES.CONTACT)
            }
          })}
          rowClassName={record =>
            `list-item ${
              selectedRecord && record._id === selectedRecord._id
                ? 'selected-item'
                : ''
            }`
          }
        />

        <Divider type="horizontal"></Divider>
        <PageHeader
          title={
            <H4 display="inline-block">{t('DELETED_FILE_AND_FOLDERS')}</H4>
          }
          subTitle={
            <H4 display="inline-block" color={theme.dark2}>
              {filteredDocuments.length}
            </H4>
          }
          extra={
            <Flex className="auto-gap" style={{ flexWrap: 'wrap' }}>
              {!isReadonly && selectedRows.length > 1 && (
                <>
                  <Span>
                    {t('SELECTED_RECORDS')}: {selectedRows.length}
                  </Span>
                  <Button
                    type="primary"
                    onClick={handlePermanentlyDeleteDocuments}
                  >
                    {t('BULK_DELETE')}
                  </Button>
                </>
              )}

              {!isReadonly && !!deletedDocuments?.length && (
                <Dropdown overlay={() => bulkActionsMenu('documents')}>
                  <Button icon="down">{t('ACTIONS')}</Button>
                </Dropdown>
              )}

              <Input.Search
                placeholder={t('SEARCH_FIL_OR_FOLDER')}
                allowClear
                onSearch={value => {
                  const filteredDocuments = search(
                    sourceDocuments,
                    ['name'],
                    value
                  )
                  setFilteredDocuments(filteredDocuments)
                }}
                style={{ width: 300 }}
              />
            </Flex>
          }
          style={{ padding: '0 0 20px' }}
        />
        <Table
          rowSelection={{
            selectedRowKeys: selectedRows
          }}
          columns={
            isReadonly
              ? documentsColumns.filter(col => col.key !== 'actions')
              : documentsColumns
          }
          dataSource={filteredDocuments}
          scroll={{ x: true }}
          rowKey="_id"
          onRow={record => ({
            onClick: () => {
              const selected = selectedRows.find(sr => sr._id === record._id)
                ? selectedRows.filter(sr => sr._id !== record._id)
                : [...selectedRows, record]
              setSelectedRows(selected)

              if (record.type !== 'folder') {
                setSelectedRecord(record)
                setSelectedRecordType(ENTITY_TYPES.DOCUMENT)
              }
            }
          })}
          rowClassName={record =>
            `list-item ${
              selectedRows.find(sr => sr._id === record._id)
                ? 'selected-item'
                : ''
            }`
          }
        />

        {!isReadonly && (
          <>
            <Divider type="horizontal"></Divider>
            <PageHeader
              title={<H4 display="inline-block">{t('DELETED_PASSWORDS')}</H4>}
              subTitle={
                <H4 display="inline-block" color={theme.dark2}>
                  {sourcePasswords.length}
                </H4>
              }
              extra={
                <Flex className="auto-gap" style={{ flexWrap: 'wrap' }}>
                  {!!deletedPasswords?.length && (
                    <Dropdown overlay={() => bulkActionsMenu('passwords')}>
                      <Button icon="down">{t('ACTIONS')}</Button>
                    </Dropdown>
                  )}

                  <Input.Search
                    placeholder={t('SEARCH_PASSWORD')}
                    allowClear
                    onSearch={value => {
                      const passwords = search(
                        deletedPasswords,
                        ['title', 'username', 'url'],
                        value
                      )
                      setSourcePasswords(passwords)
                    }}
                    style={{ width: 300 }}
                  />
                </Flex>
              }
              style={{ padding: '0 0 20px' }}
            />
            <Table
              columns={passwordColumns}
              dataSource={sourcePasswords}
              rowKey="_id"
              onRow={record => ({
                onClick: () => {
                  setSelectedRecord(record)
                  setSelectedRecordType(ENTITY_TYPES.PASSWORD)
                }
              })}
              rowClassName={record =>
                `list-item ${
                  selectedRecord && record._id === selectedRecord._id
                    ? 'selected-item'
                    : ''
                }`
              }
            />

            <Divider type="horizontal"></Divider>
            <PageHeader
              title={<H4 display="inline-block">{t('DELETED_LOCATIONS')}</H4>}
              subTitle={
                <H4 display="inline-block" color={theme.dark2}>
                  {sourceLocations.length}
                </H4>
              }
              extra={
                <Flex className="auto-gap" style={{ flexWrap: 'wrap' }}>
                  {!!deletedLocations?.length && (
                    <Dropdown overlay={() => bulkActionsMenu('locations')}>
                      <Button icon="down">{t('ACTIONS')}</Button>
                    </Dropdown>
                  )}

                  <Input.Search
                    placeholder={t('SEARCH_LOCATION')}
                    allowClear
                    onSearch={value => {
                      const locations = search(
                        deletedLocations,
                        ['level1', 'level2', 'level3'],
                        value
                      )
                      setSourceLocations(locations)
                    }}
                    style={{ width: 300 }}
                  />
                </Flex>
              }
              style={{ padding: '0 0 20px' }}
            />
            <Table
              columns={locationColumns}
              dataSource={sourceLocations}
              rowKey="_id"
              onRow={record => ({
                onClick: () => {
                  setSelectedRecord(record)
                  setSelectedRecordType(ENTITY_TYPES.LOCATION)
                }
              })}
              rowClassName={record =>
                `list-item ${
                  selectedRecord && record._id === selectedRecord._id
                    ? 'selected-item'
                    : ''
                }`
              }
            />

            <Divider type="horizontal"></Divider>
            <PageHeader
              title={<H4 display="inline-block">{t('DELETED_EVENTS')}</H4>}
              subTitle={
                <H4 display="inline-block" color={theme.dark2}>
                  {sourceEvents.length}
                </H4>
              }
              extra={
                <Flex className="auto-gap" style={{ flexWrap: 'wrap' }}>
                  {!!deletedEvents?.length && (
                    <Dropdown overlay={() => bulkActionsMenu('events')}>
                      <Button icon="down">{t('ACTIONS')}</Button>
                    </Dropdown>
                  )}

                  <Input.Search
                    placeholder={t('SEARCH_EVENT')}
                    allowClear
                    onSearch={value => {
                      const events = search(
                        deletedEvents,
                        ['description'],
                        value
                      )
                      setSourceEvents(events)
                    }}
                    style={{ width: 300 }}
                  />
                </Flex>
              }
              style={{ padding: '0 0 20px' }}
            />
            <Table
              columns={eventColumns}
              dataSource={sourceEvents}
              rowKey={record => record._id.concat(record.date)}
              onRow={record => ({
                onClick: () => {
                  setSelectedRecord(record)
                  setSelectedRecordType(ENTITY_TYPES.EVENT)
                }
              })}
              rowClassName={record =>
                `list-item ${
                  selectedRecord &&
                  record._id.concat(record.date) ===
                    selectedRecord._id.concat(selectedRecord.date)
                    ? 'selected-item'
                    : ''
                }`
              }
            />
          </>
        )}
      </Content>
      {isUpMD && selectedRecord && (
        <Sider
          width={350}
          theme="light"
          style={{
            background: 'rgba(242, 243, 247, 0.5)',
            padding: 20
          }}
        >
          {selectedRecordType === ENTITY_TYPES.ASSET_LIABILITY && (
            <AssetLiabilityInformation record={selectedRecord} />
          )}
          {selectedRecordType === ENTITY_TYPES.CONTACT && (
            <ContactInformation record={selectedRecord} />
          )}
          {selectedRecordType === ENTITY_TYPES.DOCUMENT && (
            <FileInformation record={selectedRecord} />
          )}
          {selectedRecordType === ENTITY_TYPES.PASSWORD && (
            <PasswordInformation record={selectedRecord} />
          )}
          {selectedRecordType === ENTITY_TYPES.LOCATION && (
            <LocationInformation record={selectedRecord} />
          )}
          {selectedRecordType === ENTITY_TYPES.EVENT && (
            <EventInformation record={selectedRecord} />
          )}
        </Sider>
      )}
    </Layout>
  )
}

export default withRouter(Trash)
