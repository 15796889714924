import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import CurrencySelect from '../common/CurrencySelect'
import currencyData from 'world-currencies'
import {
  Table,
  // Popconfirm,
  Form,
  InputNumber,
  // message,
  Tooltip,
  Icon,
  // Divider,
  // Modal,
  Select,
  PageHeader
} from 'antd'
import {
  renderDateTime,
  currencyInputFormat,
  currencyParse,
  exportToExcel
} from '../../share/helpers'
import api from '../../lib/api'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
// import PouchDB from 'pouchdb'
// import { uploadEncryptedData } from '../../lib/pouchDb'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import { H4 } from '../override/Typography'
//import { StringResources } from '../../share/StringResources'
import BaseCurrencyModal from './BaseCurrencyModal'
import { ThemeContext } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchBaseCurrency,
  fetchLatestRatesAndUpdateValuation
} from '../../features/settings/settingsSlice'
import { sortBy } from 'lodash'
import { onError } from '../../lib/sentry'
import { useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

// const { confirm } = Modal
const EditableContext = React.createContext()

const validateInputRate = (rule, value, callback) => {
  if (value === 0) {
    callback("Rate can't be 0")
  } else {
    callback()
  }
}

const EditableCell = props => {
  const { editing, dataIndex, title, record, children, ...restProps } = props

  const getInput = () => {
    switch (dataIndex) {
      case 'rate':
        return (
          <InputNumber
            min={0}
            formatter={currencyInputFormat}
            parser={currencyParse}
            style={{ width: '100%' }}
          />
        )
      case 'baseCurrency':
      case 'quoteCurrency':
        return <CurrencySelect />
      case 'lastUpdated':
      default:
        return null
    }
  }

  const renderCell = ({ getFieldDecorator }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <FormItem style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Enter ${title}!`
                },
                {
                  required: dataIndex === 'rate',
                  validator: validateInputRate
                }
              ],
              initialValue: record[dataIndex]
            })(getInput())}
          </FormItem>
        ) : (
          children
        )}
      </td>
    )
  }

  return <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
}

// const isSameRate = (rate, comparedRate) =>
//   rate.rate === comparedRate.rate &&
//   rate.quoteCurrency === comparedRate.quoteCurrency &&
//   rate.baseCurrency === comparedRate.baseCurrency

const Rates = props => {
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const [rates, setRates] = useState([])
  const [selectedQuoteCurrency, setSelectedQuoteCurrency] = useState('')
  const [editingKey, setEditingKey] = useState('')
  // const [loading, setLoading] = useState(false)
  const [currencyModalVisible, setCurrencyModalVisible] = useState(false)
  const [isSavingBaseCurrency, setIsSavingBaseCurrency] = useState(false)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  // const masterKey = localStorage.getItem(user.username)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
    state => state.assetsLiabilities
  )

  const {
    baseCurrency,
    allRates,
    ratesLastUpdated,
    ratesLoading
  } = useSelector(state => state.settings)

  let baseCurrencyForm
  const baseCurrencyFormRef = fr => {
    baseCurrencyForm = fr
  }

  useEffect(() => {
    if (allRates) {
      const rates = Object.keys(allRates).map(key => ({
        quoteCurrency: key,
        rate: allRates[key],
        key,
        baseCurrency,
        lastUpdated: ratesLastUpdated * 1000
      }))

      setRates(sortBy(rates, a => a.quoteCurrency))
    }
  }, [user, allRates, baseCurrency, ratesLastUpdated])

  const columns = [
    {
      title: t('BASE_CURRENCY'),
      key: 'baseCurrency',
      dataIndex: 'baseCurrency',
      editable: true,
      width: '25%'
    },
    {
      title: t('QUOTE_CURRENCY'),
      key: 'quoteCurrency',
      dataIndex: 'quoteCurrency',
      editable: true,
      width: '25%'
    },
    {
      title: t('RATE'),
      key: 'rate',
      dataIndex: 'rate',
      editable: true,
      width: '20%',
      render: (value, record) => value && currencyInputFormat(value)
    },
    {
      title: t('LAST_UPDATE'),
      key: 'lastUpdated',
      dataIndex: 'lastUpdated',
      width: '20%',
      render: renderDateTime
    }
    // {
    //   dataIndex: 'actions',
    //   width: '10%',
    //   render: (text, record) => {
    //     const editable = isEditing(record)
    //     return editable ? (
    //       <div className="row-edit-actions">
    //         <EditableContext.Consumer>
    //           {form => (
    //             <Button
    //               type="link"
    //               href=""
    //               onClick={e => save(e, form, record.key)}
    //               style={{ marginRight: 10 }}
    //             >
    //               Save
    //             </Button>
    //           )}
    //         </EditableContext.Consumer>
    //         <Popconfirm
    //           title="Are you sure to cancel?"
    //           okText="Yes"
    //           onConfirm={() => cancel(record.key)}
    //           cancelText="No"
    //           arrowPointAtCenter
    //           placement="bottomRight"
    //         >
    //           <Button type="link" href="">
    //             Cancel
    //           </Button>
    //         </Popconfirm>
    //       </div>
    //     ) : (
    //       <>
    //         <Tooltip title="Edit" arrowPointAtCenter placement="topRight">
    //           {editingKey ? (
    //             <Icon type="edit" />
    //           ) : (
    //             <Icon
    //               type="edit"
    //               theme="twoTone"
    //               onClick={() => edit(record.key)}
    //             />
    //           )}
    //         </Tooltip>
    //         <Divider type="vertical"></Divider>
    //         <Tooltip title="Delete">
    //           {editingKey ? (
    //             <Icon type="delete" />
    //           ) : (
    //             <Popconfirm
    //               title="Are you sure to delete this rate"
    //               okText="Yes"
    //               onConfirm={() => remove(record.key)}
    //               cancelText="No"
    //               arrowPointAtCenter
    //               placement="bottomRight"
    //             >
    //               <Icon theme="twoTone" twoToneColor="#eb4444" type="delete" />
    //             </Popconfirm>
    //           )}
    //         </Tooltip>
    //       </>
    //     )
    //   }
    // }
  ]

  const editableColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    }
  })

  const components = {
    body: {
      cell: EditableCell
    }
  }

  const isEditing = record => record.key === editingKey

  const cancel = () => {
    setRates(rates.filter(d => d.lastUpdated))
    setEditingKey('')
  }

  // const add = () => {
  //   const id = uuidv4()
  //   setRates([
  //     ...rates.filter(d => d.lastUpdated),
  //     {
  //       key: id,
  //       baseCurrency
  //     }
  //   ])
  //   setEditingKey(id)
  // }

  // const save = (e, form, key) => {
  //   e.preventDefault()
  //   form.validateFields(async (error, row) => {
  //     if (error) {
  //       return
  //     }

  //     if (row.baseCurrency === row.quoteCurrency) {
  //       message.error('Base currency & quote currency must be different!')
  //       return
  //     }

  //     const existingRate = rates.some(
  //       rate =>
  //         rate.key !== key &&
  //         rate.baseCurrency === row.baseCurrency &&
  //         rate.quoteCurrency === row.quoteCurrency
  //     )
  //     if (existingRate) {
  //       message.error('This rate already existed!')
  //       return
  //     }

  //     const newRates = rates.map(rate =>
  //       rate.key === key ? { ...rate, ...row, lastUpdated: new Date() } : rate
  //     )

  //     const editedRate = rates.find(rate => rate.key === key)
  //     const db = new PouchDB(`${user.username}_assetsLiabilities`)
  //     db.crypto(masterKey)
  //     const historyDb = new PouchDB(`${user.username}_assetsLiabilitiesHistory`)
  //     historyDb.crypto(masterKey)
  //     let updatedAssetsLiabilities = []

  //     if (
  //       row.baseCurrency === baseCurrency &&
  //       (!editedRate || !isSameRate(editedRate, row))
  //     ) {
  //       const assetsLiabilities = await db.allDocs({
  //         include_docs: true
  //       })
  //       updatedAssetsLiabilities = assetsLiabilities.rows
  //         .map(row => row.doc)
  //         .filter(
  //           al =>
  //             al.currency === row.quoteCurrency &&
  //             (al.valuationInAssetCurrency ||
  //               al.outstandingValueInLiabilityCurrency)
  //         )
  //     }

  //     if (updatedAssetsLiabilities.length) {
  //       confirm({
  //         title: 'Are you sure to save this rate?',
  //         content: `There are ${updatedAssetsLiabilities.length} assets/liabilities with matching currency. Their valuation in base currency will be updated accordingly.`,
  //         async onOk() {
  //           const updatedDocs = updatedAssetsLiabilities.map(record => ({
  //             ...record,
  //             valuationInBaseCurrency:
  //               record.valuationInAssetCurrency &&
  //               record.valuationInAssetCurrency / row.rate,
  //             outstandingValueInBaseCurrency:
  //               record.outstandingValueInLiabilityCurrency &&
  //               record.outstandingValueInLiabilityCurrency / row.rate,
  //             sumAssuredInBaseCurrency:
  //               record.sumAssuredInAssetCurrency &&
  //               record.sumAssuredInAssetCurrency / row.rate
  //           }))

  //           const results = await db.bulkDocs([...updatedDocs])

  //           //update version
  //           const newVersions = results.map(result => {
  //             const doc = updatedDocs.find(doc => doc._id === result.id)
  //             delete doc._rev

  //             return {
  //               ...doc,
  //               _id: `${result.id}_${result.rev}`,
  //               time: new Date()
  //             }
  //           })
  //           await historyDb.bulkDocs(newVersions)

  //           await Promise.all([
  //             uploadEncryptedData(db, user.username, 'assetsLiabilities'),
  //             uploadEncryptedData(
  //               historyDb,
  //               user.username,
  //               'assetsLiabilitiesHistory'
  //             )
  //           ])

  //           handleRatesChange(newRates, true)
  //         }
  //       })
  //     } else {
  //       handleRatesChange(newRates, true)
  //     }
  //   })
  // }

  // const edit = key => {
  //   setEditingKey(key)
  // }

  // const handleRatesChange = (rates, isSaving?) => {
  //   setLoading(true)

  //   api
  //     .saveRates(user.username, JSON.stringify(rates))
  //     .then(response => {
  //       if (response.data && response.data.success) {
  //         setRates(rates)
  //         setEditingKey('')
  //         message.success(
  //           isSaving ? 'Saved rate successfully!' : 'Deleted rate successfully!'
  //         )
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Failed to save rates to db: ', err)
  //       message.error(
  //         isSaving ? 'Failed to save rate!' : 'Failed to delete rate!'
  //       )
  //     })
  //     .finally(() => {
  //       setLoading(false)
  //     })
  // }

  // const remove = key => {
  //   handleRatesChange(rates.filter(d => d.key !== key))
  // }

  const handleSaveBaseCurrency = () => {
    const { form } = baseCurrencyForm.props
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      setIsSavingBaseCurrency(true)
      try {
        const postRes = await api.setBaseCurrency(
          user.username,
          JSON.stringify({ baseCurrency: values.currency })
        )
        if (!postRes.data || !postRes.data.success) {
          throw new Error(t('FAILE_TO_SET_CURRENCY'))
        }

        setIsSavingBaseCurrency(false)
        dispatch(fetchBaseCurrency(user.username))
        dispatch(
          fetchLatestRatesAndUpdateValuation(
            user.username,
            values.currency,
            masterKey,
            activeAssetsLiabilities.filter(al => al.currency),
            pendingAssetsLiabilities.filter(pal => pal.currency)
          )
        )

        setCurrencyModalVisible(false)
      } catch (e) {
        console.log(t('FAILED_TO_UPDATE_BASE_CURRENCY'), e)
        onError(e)
        setIsSavingBaseCurrency(false)
      }
    })
  }

  const handleCancelBaseCurrency = () => {
    baseCurrencyForm.props.form.resetFields()
    setCurrencyModalVisible(false)
  }

  return (
    <>
      <div style={{ margin: '20px 0 10px' }}>
        <H4 display="inline-block">
          {t('BASE_CURRENCY')}{' '}
          <Tooltip
            title={t(
              'BASE_CURRENCY_MEANS_YOUR_REPORTING_CURRENCY_OR_FUNCTIONAL_CURRENCY'
            )}
          >
            <Icon type="question-circle-o" />
          </Tooltip>{' '}
          :
        </H4>
        <H4
          display="inline-block"
          style={{
            color: baseCurrency ? theme.primary : theme.dark1,
            marginLeft: 10
          }}
        >
          {baseCurrency || 'n/a'}
        </H4>
        <Tooltip title={t('SET_BASE_CURRENCY')}>
          <Button onClick={e => setCurrencyModalVisible(true)} type="link">
            <Icon type="edit" />
          </Button>
        </Tooltip>
      </div>

      <PageHeader
        title={<H4>{t('EXCHANGE_RATES')}</H4>}
        style={{ padding: '20px 0' }}
        extra={
          <>
            {/* <Button onClick={add} style={{ marginRight: 10 }}>
              Add rate
            </Button> */}
            {/* TODO: refactor to use CurrencySelect */}
            <Select
              allowClear
              showSearch
              placeholder={t('SELECT_QUOTE_CURRENCY')}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .includes(input.trim().toLowerCase())
              }
              style={{
                maxWidth: 240,
                width: '80vw',
                marginRight: 10,
                marginBottom: 10
              }}
              onChange={setSelectedQuoteCurrency}
            >
              {Object.values(currencyData).map(c => (
                <Select.Option key={c.iso.code} value={c.iso.code}>
                  {c.iso.code} - {c.name}
                </Select.Option>
              ))}
            </Select>
            <Button
              onClick={() => exportToExcel('Rates', columns, rates)}
              style={{ marginLeft: 0 }}
            >
              {t('EXPORT_TO_EXCEL')}
            </Button>
          </>
        }
        size="small"
      />
      <EditableContext.Provider value={props.form}>
        <Table
          components={components}
          dataSource={rates.filter(
            rate =>
              !selectedQuoteCurrency ||
              rate.quoteCurrency === selectedQuoteCurrency
          )}
          scroll={{ x: true }}
          columns={editableColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel
          }}
          loading={ratesLoading}
          size={isMdUp ? '' : 'middle'}
        />
      </EditableContext.Provider>

      <BaseCurrencyModal
        wrappedComponentRef={baseCurrencyFormRef}
        visible={currencyModalVisible}
        baseCurrency={baseCurrency}
        handleSave={handleSaveBaseCurrency}
        handleCancel={handleCancelBaseCurrency}
        isSaving={isSavingBaseCurrency}
      />
    </>
  )
}
const EditableFormTable = Form.create()(Rates)
export default withRouter(EditableFormTable)
