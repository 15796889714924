import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Elements, StripeProvider } from 'react-stripe-elements'
import api from '../../lib/api'
import { getUserData, getUserAttributeValue } from '../../lib/cognito'
import { Modal, Spin, message, notification } from 'antd'
import Button from '../override/Button'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import CardModal from './CardModal'
import config from '../../config'
import {
  fetchCustomer,
  updateCancelSchedule
} from '../../features/payment/customerSlice'
import { fetchUser } from '../../features/user/userSlice'
import { downloadAllDataAsZip } from '../../share/helpers'
import SubscriptionPlans from '../payment/SubscriptionPlans'
//import { StringResources } from '../../share/StringResources'
import moment from 'moment'
import usePlans from '../../hooks/usePlans'
import { onError } from '../../lib/sentry'
import { useTranslation, Trans } from 'react-i18next'

export default function SubcriptionModal({ visible, setVisible, closable }) {
  const { user, isDeputyOnly } = useContext(AuthContext)
  const { masterKey, fullName } = useContext(VaultContext)
  const { t } = useTranslation()

  const [isSaving, setIsSaving] = useState(false)
  const [cardModalVisible, setCardModalVisible] = useState(false)
  const [subscribeCustomer, setSubscribeCustomer] = useState({})
  const [selectedPlanId, setSelectedPlanId] = useState('')
  const [referralCode, setReferralCode] = useState('')
  const [userInfo, setUserInfo] = useState({
    fullname: '',
    email: '',
    discountCode: ''
  })
  const { customer, subscription, schedule } = useSelector(
    state => state.customer
  )
  const { appliedReferralCode, hasAppliedReferralDiscount, trialEnd } =
    useSelector(state => state.user).user
  const { activeContacts } = useSelector(state => state.contacts)
  const { activeAssetsLiabilities } = useSelector(
    state => state.assetsLiabilities
  )
  const { activeFolders, activeFiles, usedStorage } = useSelector(
    state => state.documents
  )

  const { deputies } = useSelector(state => state.deputies)

  const dispatch = useDispatch()

  const plans = usePlans(userInfo.discountCode, visible)
  const {
    defaultPlans,
    // defaultPdPlans,
    // customPlans,
    // customPdPlans,
    isLoading
  } = plans
  const vaultboxPlans = Object.values(defaultPlans).flat()
  // const deputyPlans = customPdPlans?.length ? customPdPlans : defaultPdPlans

  useEffect(() => {
    if (visible) setIsSaving(false)
  }, [visible])

  useEffect(() => {
    getUserData(user, async (err, data) => {
      if (err) {
        onError(err)
        return
      }
      const fullname = getUserAttributeValue(
        data.UserAttributes,
        'custom:full_name'
      )
      const email = getUserAttributeValue(data.UserAttributes, 'email')
      const discountCode = getUserAttributeValue(
        data.UserAttributes,
        'custom:discount_code'
      )

      setUserInfo({ fullname, email, discountCode })
    })
  }, [user])

  const handleSubscribe = async planId => {
    getUserData(user, async (err, data) => {
      if (err) {
        onError(err)
        return
      }

      const allowedStorageInGB = vaultboxPlans.find(p => p.id === planId)
        .metadata?.storage
      const allowedStorage = allowedStorageInGB
        ? allowedStorageInGB * 1024 * 1024 * 1024
        : null

      if (!allowedStorage || usedStorage > allowedStorage) {
        Modal.warn({
          title: t('INVALID_SUBSCRIPTION'),
          content: t('INVALID_SUBSCRIPTION_SUMMARY')
        })
        return
      }

      setIsSaving(true)

      try {
        let subscribeCustomer

        if (referralCode) {
          const res = await api.getReferrer(referralCode)

          if (res.data.id) {
            const refereeEmails = res.data.refereeEmails || []
            const email = getUserAttributeValue(data.UserAttributes, 'email')

            if (refereeEmails.find(de => de === email)) {
              Modal.warn({
                title: t('INVALID_REFERRAL_CODE'),
                content: t('CODE_USED'),
                onOk() {
                  setIsSaving(false)
                }
              })
              return
            } else {
              await api.saveAppliedReferralCode(
                user.username,
                JSON.stringify({ appliedReferralCode: referralCode })
              )
              dispatch(fetchUser(user.username))
            }
          } else {
            Modal.warn({
              title: t('INVALID_REFERRAL_CODE'),
              content: t('TRY_ANOTHER_ONE'),
              onOk() {
                setIsSaving(false)
              }
            })
            return
          }
        }

        setIsSaving(true)

        if (!customer?.id) {
          const email = getUserAttributeValue(data.UserAttributes, 'email')
          const res = await api.createCustomer(
            user.username,
            JSON.stringify({ email })
          )
          if (res.data?.message) throw Error(res.data.message)

          subscribeCustomer = res.data
        } else {
          subscribeCustomer = customer
        }

        setSubscribeCustomer(subscribeCustomer)
        setSelectedPlanId(planId)

        if (!subscribeCustomer.default_source) {
          setCardModalVisible(true)
        } else {
          await handleSaveSubscription(
            planId,
            subscribeCustomer.id,
            subscribeCustomer.subscriptions?.data[0]?.status === 'active'
          )
        }
      } catch (err) {
        onError(err)
        setIsSaving(false)
        message.error(t('FAILED_TO_SUBSCRIBE'))
      }
    })
  }

  const handleSaveSubscription = async (
    planId,
    customerId,
    isActive = false,
    withoutPaymentInfo = false
  ) => {
    let activePromoCode, removedPromoCode

    if (subscription.discount?.coupon?.id) {
      try {
        activePromoCode = subscription.discount.coupon.id
        const promotionCodeInfo = await api.getPromotionCode(
          activePromoCode,
          userInfo.email,
          true,
          true
        )

        // remove active promo code if it can't be applied to the new selected plan
        removedPromoCode =
          !!promotionCodeInfo.data?.appliesToPlan &&
          promotionCodeInfo.data.appliesToPlan !== planId &&
          activePromoCode
      } catch (err) {
        onError(err)
        message.error(t('FAILED_TO_GET_PROMOTION_CODE_INFO'))
        setIsSaving(false)
        return
      }
    }

    if (removedPromoCode || isActive) {
      Modal.confirm({
        title: t('CHANGE_SUBSCRIPTION_PLAN'),
        content: (
          <>
            {isActive && (
              <p>
                <Trans
                  i18nKey="CHANGE_SUBSCRIPTION_PLAN_FIRST_SUMMARY"
                  values={{
                    current_period_end: moment(
                      subscription.current_period_end * 1000
                    ).format('LL')
                  }}
                ></Trans>
              </p>
            )}
            {removedPromoCode && (
              <p>
                <Trans
                  i18nKey="CHANGE_SUBSCRIPTION_PLAN_SECOND_SUMMARY"
                  values={{
                    removedPromoCode,
                    nickname: subscription.items.data[0].plan.nickname
                  }}
                ></Trans>
              </p>
            )}
            <div>{t('ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE')}</div>
          </>
        ),
        onCancel() {
          setIsSaving(false)
        },
        async onOk() {
          try {
            await saveSubscription(
              planId,
              customerId,
              withoutPaymentInfo,
              removedPromoCode
            )
            message.success(t('Successfully change subscription plan'))
          } catch (error) {
            message.error(t('FAILED_TO_SAVE_SUBSCRIPTION'))
          }
        }
      })
    } else {
      Modal.confirm({
        title: t('CHANGE_SUBSCRIPTION_PLAN'),
        content: t('ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE'),
        async onOk() {
          try {
            await saveSubscription(
              planId,
              customerId,
              withoutPaymentInfo,
              removedPromoCode
            )
            message.success(t('Successfully change subscription plan'))
          } catch (error) {
            message.error(t('FAILED_TO_SAVE_SUBSCRIPTION'))
          }
        },
        onCancel() {}
      })
    }
  }

  const saveSubscription = async (
    planId,
    customerId,
    withoutPaymentInfo,
    removedPromoCode
  ) => {
    try {
      let res

      if (!subscription?.items?.data[0].plan.id) {
        if (withoutPaymentInfo) {
          const current = Math.floor(new Date().getTime() / 1000)

          if (trialEnd && trialEnd < current) {
            message.error(t('TRIAL_EXPIRES'))
            setIsSaving(false)
            return
          }
        }

        let items = [{ plan: planId }]
        const deputyRes = await api.getDeputies(user.username)
        if (deputyRes.data.message)
          throw Error(
            t('FAILED_TO_GET_DEPUTIES_LIST_TO_CHECK_PROFESSTIONAL_DEPUTY_PLAN')
          )
        // const { deputies } = deputyRes.data

        // if (
        //   deputies?.filter(d => d.publicKey && d.professionalDeputyId).length >
        //   1
        // ) {
        //   const matchingPlan = vaultboxPlans.find(p => p.id === planId)
        //   items.push({
        //     plan: deputyPlans.find(
        //       dp =>
        //         dp.interval === matchingPlan?.interval &&
        //         dp.interval_count === matchingPlan?.interval_count
        //     )?.id
        //   })
        // }

        const createData = {
          userId: user.username,
          customerId,
          items
        }
        res = await api.createSubscription(JSON.stringify(createData))
        if (res?.data.message) {
          throw Error(res.data.message)
        }
      } else if (subscription?.id) {
        const subscriptionItems = subscription.items.data

        const mappedSubItem = subscriptionItems.find(item =>
          vaultboxPlans.map(p => p.id).includes(item.plan.id)
        )

        // const mappedDeputySubItem = subscriptionItems.find(item =>
        //   deputyPlans.map(p => p.id).includes(item.plan.id)
        // )

        let updateItems = [
          {
            id: mappedSubItem.id,
            plan: planId // bantex plan
          }
        ]
        // if (mappedDeputySubItem) {
        //   const matchingPlan = vaultboxPlans.find(p => p.id === planId)
        //   updateItems.push({
        //     id: mappedDeputySubItem.id,
        //     plan: deputyPlans.find(
        //       dp =>
        //         dp.interval === matchingPlan?.interval &&
        //         dp.interval_count === matchingPlan?.interval_count
        //     )?.id // Professional Deputy plan
        //   })
        // }

        if (subscription.status === 'trialing') {
          const updateData = {
            items: updateItems,
            cancel_at_period_end: false,
            proration_behavior: 'none',
            coupon: removedPromoCode
              ? null // have to set it to null to remove the coupon
              : undefined // set to undefined will make no change (i.e. if the coupon was added, it would still be there)
          }

          res = await api.updateSubscription(
            subscription.id,
            JSON.stringify(updateData)
          )
          if (res?.data.message) throw Error(res.data.message)
        } else {
          const currentPlans = subscriptionItems.map(item => ({
            plan: item.plan.id
          }))

          res = await api.updateSubscriptionSchedule(
            subscription.id,
            JSON.stringify({
              scheduleId: subscription.schedule,
              isRelease: false,
              phases: [
                {
                  plans: currentPlans,
                  start_date: subscription?.current_period_start,
                  end_date: "now",
                  proration_behavior: 'none',
                  coupon: removedPromoCode
                    ? undefined
                    : subscription?.discount?.coupon?.id
                },
                {
                  plans: updateItems.map(item => ({ plan: item.plan })),
                  start_date: 'now',
                  proration_behavior: 'none',
                  coupon: removedPromoCode
                    ? undefined
                    : subscription?.discount?.coupon?.id
                }
              ]
            })
          )
        }
      }
      if (res?.data.message) throw Error(res.data.message)

      if (removedPromoCode) {
        const updateData = {
          code: removedPromoCode,
          end: moment().unix()
        }

        await api.updateAppliedPromotionCodes(
          user.username,
          JSON.stringify(updateData)
        )
      }

      setIsSaving(false)
      setVisible(false)
      dispatch(fetchCustomer(user.username))

      if (isDeputyOnly) {
        // reload to update the whole app state, as the account has switched from deputy only to normal
        window.location.reload()
      }
    } catch (err) {
      onError(err)
      message.error(t('FAILED_TO_SAVE_SUBSCRIPTION'))
    }
  }

  const handleCancelSubscription = async () => {
    Modal.confirm({
      title: t('CANCEL_SUBSCRIPTION'),
      content: (
        <>
          <p>{t('CANCEL_SUBSCRIPTION_FIRST_SUMMARY')}</p>
          <p>
            <Trans i18nKey="CANCEL_SUBSCRIPTION_SECOND_SUMMARY">
              After your subscription ends, you will no longer have access to
              your Registry and Files, which may be deleted some time after and
              cannot be recovered.{' '}
              <Button
                type="link"
                style={{ padding: 0, height: 'auto', lineHeight: '18px' }}
                onClick={() =>
                  downloadAllDataAsZip(
                    user.username,
                    activeAssetsLiabilities,
                    activeContacts,
                    activeFiles,
                    activeFolders,
                    masterKey,
                    fullName
                  )
                }
              >
                <b>Remember to download a copy first.</b>
              </Button>
            </Trans>
          </p>
          <p>
            <Trans i18nKey="YOU_CAN_STILL_USE_YOUR_ACCOUNT_AS_DEPUTY"></Trans>
          </p>
          <div>{t('ARE_YOU_SURE_YOU_WISH_TO_CANCEL_SUBSCRIPTION')}</div>
        </>
      ),
      width: 500,
      cancelText: t('CANCEL_SUBSCRIPTION'),
      cancelButtonProps: { style: { color: 'rgb(200, 0, 0)' } },
      okText: t('KEEP_SUBSCRIPTION'),
      onCancel: async () => {
        try {
          setIsSaving(true)
          if (schedule?.id) {
            await api.updateSubscriptionSchedule(
              subscription.id,
              JSON.stringify({
                scheduleId: schedule?.id,
                isRelease: true
              })
            )
          }

          await updateCancelSchedule(
            subscription.id,
            user.username,
            dispatch,
            true
          )

          const professionalDeputyEmails = deputies
            ?.filter(d => d.professionalDeputyId)
            .map(e => e.email)

          if (professionalDeputyEmails?.length) {
            await api.sendUnsubscribeNotification(
              JSON.stringify({
                deputyEmails: professionalDeputyEmails,
                fullname: userInfo.fullname,
                email: userInfo.email,
                cancelDate: moment(
                  subscription.current_period_end * 1000
                ).format('LL')
              })
            )
          }

          setVisible(false)

          notification.info({
            message: t('SUBSCRIPTION_CANCELLED'),
            description: (
              <Trans
                i18nKey="SUBSCRIPTION_CANCELLED_SUMMARY"
                values={{
                  nickname: subscription.items.data[0].plan.nickname,
                  current_period_end: moment(
                    subscription.current_period_end * 1000
                  ).format('LL')
                }}
              ></Trans>
            ),
            duration: 5
          })
        } catch (err) {
          setIsSaving(false)
          message.error(t('FAILED_TO_CANCEL_SUBSCRIPTION'))
          onError(err)
        }
      }
    })
  }

  return (
    <>
      <Modal
        visible={visible}
        title={t('SUBSCRIPTION_PLANS')}
        maskClosable={false}
        closable={closable}
        keyboard={closable}
        footer={null}
        style={{ maxWidth: 1080 }}
        width={'90vw'}
        onCancel={() => setVisible(false)}
        okText={t('OK')}
        cancelText={t('CANCEL')}
      >
        <Spin spinning={isLoading || isSaving}>
          <SubscriptionPlans
            showReferralCodeInput={
              !appliedReferralCode && !hasAppliedReferralDiscount
            }
            plans={plans}
            currentPlanId={subscription?.items?.data[0].plan.id}
            handleCancelSubscription={handleCancelSubscription}
            handleSubscribe={handleSubscribe}
            subscriptionStatus={subscription?.status}
            defaultSource={customer?.default_source}
            setReferralCode={setReferralCode}
            referralCode={referralCode}
            isCancelAtEnd={subscription?.cancel_at_period_end}
            handleReactivate={async () => {
              try {
                setIsSaving(true)
                await updateCancelSchedule(
                  subscription.id,
                  user.username,
                  dispatch
                )
                setVisible(false)
                message.success(t('REACTIVATE_SUBSCRIPTION_SUCCESS'))
              } catch (err) {
                message.error(t('REACTIVATE_SUBSCRIPTION_FAIL'))
                onError(err)
              } finally {
                setIsSaving(false)
              }
            }}
            nextPhase={schedule?.phases[1]}
            handleRelease={async () => {
              try {
                setIsSaving(true)

                await api.updateSubscriptionSchedule(
                  subscription.id,
                  JSON.stringify({
                    scheduleId: schedule?.id,
                    isRelease: true
                  })
                )

                if (subscription.discount?.coupon?.id) {
                  const activePromoCode = subscription.discount.coupon.id
                  const promotionCodeInfo = await api.getPromotionCode(
                    activePromoCode,
                    userInfo.email,
                    true,
                    true
                  )

                  // remove active promo code if it can't be applied to the current plan
                  const shouldRemovePromoCode =
                    !!promotionCodeInfo.data?.appliesToPlan &&
                    promotionCodeInfo.data.appliesToPlan !==
                      subscription.items?.data[0].plan.id

                  if (shouldRemovePromoCode) {
                    await api.updateSubscription(
                      subscription.id,
                      JSON.stringify({
                        coupon: null
                      })
                    )
                  }
                }

                dispatch(fetchCustomer(user.username))
                setVisible(false)
                message.success(t('SUCCESSFULLY_CANCELLED_SUBSCRIPTION_CHANGE'))
              } catch (err) {
                message.error(t('FAILED_TO_CANCEL_SUBSCRIPTION_CHANGE'))
                onError(err)
              }
            }}
          />
        </Spin>
      </Modal>
      <StripeProvider apiKey={config.stripe.PUBLISHABLE_KEY}>
        <Elements>
          <CardModal
            visible={cardModalVisible}
            setVisible={setCardModalVisible}
            customerId={subscribeCustomer.id}
            cancelText={t('NOT_NOW')}
            handleOkComplete={() => {
              // fetch customer info, so in case users cancel in the following steps, then try to change subscription plan again,
              // the default_source is updated so the CardModal won't be displayed again.
              dispatch(fetchCustomer(user.username))

              // Assumption: CardModal is only shown when the subscription is not yet active
              // so the isActive arg is always false
              handleSaveSubscription(selectedPlanId, subscribeCustomer.id)
            }}
            handleCancelComplete={() =>
              handleSaveSubscription(
                selectedPlanId,
                subscribeCustomer.id,
                false,
                true
              )
            }
          />
        </Elements>
      </StripeProvider>
    </>
  )
}
