import Asset, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class OtherAssets extends Asset {
  /**
   * Construct a new OtherAssets instance
   * @param {object} params - OtherAssets creation options:
   * @param {string} [params.otherAssetType]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    valuationDate,
    currency,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    otherAssetType,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    status,
    events,
    passwords
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      currency,
      valuationDate,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords
    })

    this.subType = ASSET_TYPES.OTHER_ASSETS
    this.otherAssetType = otherAssetType
  }
}

export default OtherAssets

export const otherAssetsColumns = () => [
  ...assetColumns(),
  {
    key: 'purchaseDate',
    title: i18next.t('ASSET_PURCHASE_DATE'),
    type: IMPORT_COL_TYPES.DATE,
    required: true
  },
  {
    key: 'disposalDate',
    title: i18next.t('DISPOSAL_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'otherAssetType',
    title: i18next.t('OTHER_ASSET_TYPE'),
    type: IMPORT_COL_TYPES.LIST,
    isCustomList: true
  },
  ...referenceColumns()
]
