import React, { useState, useEffect, useContext, useCallback } from 'react'
import FileDirectory from '../file/FileDirectory'
import FileNav from '../file/FileNav'
import FileList from '../file/FileList'
import PouchDB from 'pouchdb'
import CryptoPouch from 'crypto-pouch'
import { withRouter } from 'react-router-dom'
import SearchForm from '../file/SearchModal'
import {
  Spin,
  PageHeader,
  Layout,
  Input,
  Icon,
  Popover,
  Tooltip,
  Menu,
  Progress
} from 'antd'
import {
  formatBytes,
  exportFilesToExcel,
  exportFilesToPDF,
  sortTree,
  updatePathsTree
} from '../../share/helpers'
import { useSelector, useDispatch } from 'react-redux'
import VaultContext from '../../contexts/VaultContext'
import TextFileEditor from '../../components/file/TextFileEditor'
import { H4 } from '../override/Typography'
import {
  setBreadcrumb,
  setShowStarredDocsOnly
} from '../../features/documents/documentsSlice'
//import { StringResources } from '../../share/StringResources'
import CustomIcon from '../override/Icon'
import { ThemeContext } from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation, Trans } from 'react-i18next'
import { ACCESS_LEVEL } from './../../share/Constants'
import AuthContext from './../../contexts/AuthContext'
import PendingList from './PendingList'
import { search } from '../../share/helpers'
import { onError } from '../../lib/sentry'
import api from '../../lib/api'
import { SharingDetails } from '../users/SharingDetails'
import moment from 'moment'

PouchDB.plugin(CryptoPouch)

const { Content, Sider } = Layout
// TODO: overall
// - config S3 bucket correctly (public access, permission, CORS policy, etc.)
// - all requests should be authenticated
// - show proper error UI for all console.log
function Files(props) {
  const {
    filteredDocIds,
    setDocToLink,
    linkedAssetLiabilityId,
    isStarred,
    isModal,
    isSelectedMutilpleFiles,
    setSelectedFilesFolders,
    selectedFilesFolders,
    isPending,
    isRejected
  } = props
  const { userId, isReadonly, fullName, recordIds } = useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'))
  const { t } = useTranslation()

  const { deputies } = useSelector(state => state.deputies)

  const { activeContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )
  const { backupSize } = useSelector(state => state.user).user

  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { accessLevel } = useSelector(state => state.settings)
  const [content, setContent] = useState([])
  const [filteredContent, setFilteredContent] = useState([])
  const [rootFolder, setRootFolder] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [isSearchMode, setSearchMode] = useState(false)
  const [filesCount, setFilesCount] = useState(0)
  const [foldersCount, setFoldersCount] = useState(0)
  const [pendingFilesCount, setPendingFilesCount] = useState(0)
  const [pendingFoldersCount, setPendingFoldersCount] = useState(0)
  const [viewMode, setViewMode] = useState('list')
  const [enableCreateFile, setEnableCreateFile] = useState(false)
  const [storageStatus, setStorageStatus] = useState('normal')
  const [selectedTextFile, setSelectedTextFile] = useState({})
  const [selectedFolder, setSelectedFolder] = useState()
  const [selectedDoc, setSelectedDoc] = useState()
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(null)
  const [documentActivities, setDocumentActivities] = useState([])
  const [isShowSider, setIsShowSider] = useState(false)

  const dispatch = useDispatch()

  const {
    activeDocuments,
    activeFiles,
    activeFolders,
    isLoading,
    usedStorage,
    deletedStorage,
    pendingDocuments,
    pendingFiles,
    pendingFolders
  } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )

  const { allowedStorage } = useSelector(state => state.customer)
  const { breadcrumb, showStarredDocsOnly } = useSelector(
    state => state.documents
  )

  let formRef
  const saveFormRef = fr => {
    formRef = fr
  }

  useEffect(() => {
    setStorageStatus(
      allowedStorage > 0 && usedStorage / allowedStorage >= 0.95
        ? 'exception'
        : 'normal'
    )
  }, [usedStorage, allowedStorage])

  useEffect(() => {
    dispatch(setShowStarredDocsOnly(isStarred))
  }, [isStarred, dispatch])

  useEffect(() => {
    const getDocumentActivities = () => {
      let params = {
        ...(startDate
          ? { startTime: startDate.startOf('day').toISOString() }
          : endDate
          ? { startTime: new Date(0).toISOString() }
          : {})
      }

      if (endDate) {
        const now = new Date()
        const endTime = endDate.endOf('day').isAfter(now)
          ? now
          : endDate.endOf('day')

        params = { ...params, endTime: endTime.toISOString() }
      }

      api
        .getAccessAttempts(userId, params)
        .then(res => {
          const data = res.data.length
            ? res.data
                .filter(
                  r =>
                    (!startDate ||
                      startDate
                        .startOf('day')
                        .isSameOrBefore(r.metadata.txTime)) &&
                    r.data.DocumentId
                )
                .map(record => ({
                  ...record.data,
                  Detail: JSON.parse(record.data.Detail),
                  Id: record.metadata.txId,
                  Time: record.metadata.txTime
                }))
                .reverse()
            : []

          setDocumentActivities(data)
        })
        .catch(err => {
          setDocumentActivities([])
          onError(err)
        })
    }

    if (userId && !isReadonly) {
      getDocumentActivities()
    }
  }, [userId, startDate, endDate, isReadonly])

  const [users, setUsers] = useState([])

  const colors = [
    theme.secondaryYellow,
    theme.secondaryRed,
    theme.secondaryBlue,
    theme.secondaryOrange,
    theme.secondaryLightGreen,
    theme.secondaryCyan,
    theme.secondaryGreenMedium,
    theme.secondaryPurple
  ]

  const viewDetail = async id => {
    try {
      const sharedUsers = deputies?.filter(d =>
        d.records?.map(r => r.id).includes(id)
      )

      const lstUsers = sharedUsers?.length
        ? await Promise.all(
            sharedUsers?.map(async (u, i) => {
              let res
              if (u.id) {
                res = await api.getUser(u.id, true)
              }

              return {
                id: u.id,
                shortName:
                  res?.data?.fullName.charAt(0).toUpperCase() ||
                  u.email.charAt(0).toUpperCase(),
                fullName: res?.data?.fullName || <i> Pending</i>,
                background: colors[i % colors.length],
                email: u.email,
                permission: u.records?.find(r => r.id === id)?.permission
              }
            })
          )
        : []
      setUsers(lstUsers)
    } catch (error) {
      onError(error)
    }
  }

  const folderInfo = useCallback(
    folder => {
      const childItems = (
        accessLevel === ACCESS_LEVEL.NEED_APPROVAL
          ? [...pendingDocuments, ...activeDocuments]
          : activeDocuments
      ).filter(doc => doc.path.indexOf(folder.path) === 0)
      const allFilesInFolder = childItems.filter(item => item.fileId)
      const totalSizeInBytes = allFilesInFolder.reduce(
        (total, currentValue) => {
          return total + currentValue.file[0].size
        },
        0
      )

      return {
        totalSize: formatBytes(totalSizeInBytes),
        filesCount: childItems.filter(
          f =>
            f.fileName &&
            !f.status &&
            (recordIds?.length ? recordIds.includes(f._id) : true)
        ).length,
        foldersCount: childItems.filter(
          f =>
            !f.fileName &&
            f.path !== folder.path &&
            !f.status &&
            (recordIds?.length ? recordIds.includes(f._id) : true)
        ).length,
        pendingFilesCount: childItems.filter(f => f.fileName && f.status)
          .length,
        pendingFoldersCount: childItems.filter(
          f => !f.fileName && f.path !== folder.path && f.status
        ).length
      }
    },
    [accessLevel, activeDocuments, pendingDocuments, recordIds]
  )

  const resetFields = () => {
    formRef && formRef.props.form.resetFields()
    clearSearchResults()
  }

  const handleFolderSelect = useCallback(
    key => {
      // if (isSearchMode) {
      //   resetFields()
      // }

      const selectedFolder = activeFolders.find(d => d.path === key)
      if (!showStarredDocsOnly) {
        let filteredFolders = []
        const currentFiles = (
          (!isModal ||
            isProfessionalDeputy ||
            (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? [...pendingFiles, ...activeFiles]
            : activeFiles
        )
          .filter(doc => doc.path === key)
          .map(doc => {
            return {
              name: doc.fileName,
              id: doc._id,
              type: 'file-text', // TODO: map the icon according to file type
              path: doc.path,
              fileId: doc.fileId,
              size: formatBytes(doc.file[0].size),
              starred: doc.starred,
              isContentEditable: doc.isContentEditable,
              sub: doc.sub,
              location: doc.location,
              status: doc.status,
              contacts: doc.contacts,
              events: doc.events,
              passwords: doc.passwords
            }
          })

        const lstFolders =
          (!isModal ||
            isProfessionalDeputy ||
            (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? [...pendingFolders, ...activeFolders]
            : activeFolders

        lstFolders.forEach(folder => {
          if (folder.path?.indexOf(key) !== 0) {
            return false
          }
          const remainingPath = folder.path.replace(key, '')
          if (!remainingPath) {
            return false
          }
          const slashCount = remainingPath.replace(/[^/]/g, '').length
          if (
            slashCount === 0 ||
            (slashCount === 1 && remainingPath.endsWith('/'))
          ) {
            filteredFolders.push(folder)
          }
        })

        const currentFolders = filteredFolders.map(folder => {
          const info = folderInfo(folder)
          return {
            filesCount: info.filesCount,
            folderSize: info.totalSize,
            foldersCount: info.foldersCount,
            pendingFilesCount: info.pendingFilesCount,
            pendingFoldersCount: info.pendingFoldersCount,
            name: folder.path.replace(key, '').slice(0, -1),
            type: 'folder',
            path: folder.path,
            id: folder._id,
            location: folder.location,
            starred: folder.starred,
            status: folder.status,
            folderIcon: folder.folderIcon,
            folderType: folder.folderType
          }
        })

        let activeFoldersCount = currentFolders.filter(
          folder =>
            !folder.status &&
            (recordIds?.length ? recordIds.includes(folder.id) : true)
        ).length
        let pendingFoldersCount = currentFolders.filter(
          folder => folder.status
        ).length
        let activeFilesCount = currentFiles.filter(
          file =>
            !file.status &&
            (recordIds?.length ? recordIds.includes(file.id) : true)
        ).length
        let pendingFilesCount = currentFiles.filter(file => file.status).length
        currentFolders.forEach(folder => {
          activeFoldersCount += folder.foldersCount
          activeFilesCount += folder.filesCount
          pendingFoldersCount += folder.pendingFoldersCount
          pendingFilesCount += folder.pendingFilesCount
        })

        setFilesCount(activeFilesCount)
        setFoldersCount(activeFoldersCount)
        setPendingFilesCount(pendingFilesCount)
        setPendingFoldersCount(pendingFoldersCount)
        setContent(currentFolders.concat(currentFiles))
        setFilteredContent(currentFolders.concat(currentFiles))
      } else {
        const starredFolders = activeFolders
          .filter(
            f =>
              f.starred &&
              (recordIds?.length ? recordIds.includes(f._id) : true)
          )
          .map(folder => {
            // folder path always ends with "/", so the last item in the array
            // after splitting is an empty string and can be sliced out
            const itemPathFolders = folder.path.split('/').slice(0, -1)
            const info = folderInfo(folder)

            return {
              filesCount: info.filesCount,
              foldersCount: info.foldersCount,
              folderSize: info.folderSize,
              name: itemPathFolders[itemPathFolders.length - 1],
              type: 'folder',
              path: folder.path,
              id: folder._id,
              starred: folder.starred,
              folderIcon: folder.folderIcon,
              folderType: folder.folderType
            }
          })

        const starredFiles = activeFiles
          .filter(
            f =>
              f.starred &&
              (recordIds?.length ? recordIds.includes(f._id) : true)
          )
          .map(doc => {
            return {
              name: doc.fileName,
              id: doc._id,
              fileId: doc.fileId,
              type: 'file-text', // TODO: map the icon according to file type
              path: doc.path,
              size: formatBytes(doc.file[0].size),
              starred: doc.starred,
              location: doc.location,
              sub: doc.sub,
              contacts: doc.contacts
            }
          })

        setFilesCount(starredFiles.length)
        setFoldersCount(starredFolders.length)
        setContent(starredFolders.concat(starredFiles))
      }
      setSelectedFolder(selectedFolder)
      dispatch(setBreadcrumb(key))
    },
    [
      accessLevel,
      activeFiles,
      activeFolders,
      dispatch,
      folderInfo,
      //isSearchMode,
      pendingFiles,
      pendingFolders,
      showStarredDocsOnly,
      isModal,
      isProfessionalDeputy,
      isDelegateByPD,
      isReadonly,
      recordIds
      //resetFields
    ]
  )

  useEffect(() => {
    setRootFolder(userId + '/')
  }, [userId])

  useEffect(() => {
    // TODO: handleFolderSelect & breadcrumb are also dependencies
    handleFolderSelect(breadcrumb)
  }, [activeDocuments, breadcrumb, pendingDocuments, handleFolderSelect])

  const handleSearch = () => {
    formRef.props.form.validateFields((err, values) => {
      if (err) return
      if (
        !(
          values.fileName ||
          values.tags ||
          values.description ||
          values.startTime ||
          values.endTime
        )
      ) {
        return
      }

      setSearchMode(true)

      // TODO: Show file path for items found in the current's child folders
      // filter by path to search files in a specified folder
      let filteredFiles = activeFiles
        .filter(doc =>
          showStarredDocsOnly ? doc.starred : doc.path.indexOf(breadcrumb) === 0
        )
        .map(doc => {
          return {
            ...doc,
            name: doc.fileName,
            id: doc._id,
            type: 'file-text', // TODO: map the icon according to file type
            tags: doc.tags,
            desc: doc.description,
            size: formatBytes(doc.file[0].size)
          }
        })

      if (values.fileName) {
        filteredFiles = filteredFiles.filter(doc =>
          doc.name.toLowerCase().includes(values.fileName.trim().toLowerCase())
        )
      }

      if (values.tags) {
        filteredFiles = filteredFiles.filter(
          doc =>
            doc.tags &&
            doc.tags
              .map(tag => tag.toLowerCase())
              .includes(values.tags.trim().toLowerCase())
        )
      }

      if (values.description) {
        filteredFiles = filteredFiles.filter(
          doc =>
            doc.desc &&
            doc.desc
              .toLowerCase()
              .includes(values.description.trim().toLowerCase())
        )
      }

      // toISOString(): convert from local time to UTC, in ISO format
      if (values.startTime) {
        filteredFiles = filteredFiles.filter(
          doc => doc.uploadTime >= values.startTime.startOf('day').toISOString()
        )
      }

      if (values.endTime) {
        filteredFiles = filteredFiles.filter(
          doc => doc.uploadTime <= values.endTime.endOf('day').toISOString()
        )
      }

      setSearchResults(filteredFiles)
    })
  }

  const clearSearchResults = () => {
    setSearchResults([])
    setSearchMode(false)
  }

  const searchFileOrFolder = value => {
    const oldData = filteredContent

    if (value) {
      const allFiles = activeFiles.map(doc => {
        return {
          name: doc.fileName,
          id: doc._id,
          type: 'file-text',
          path: doc.path,
          fileId: doc.fileId,
          size: formatBytes(doc.file[0].size),
          starred: doc.starred,
          isContentEditable: doc.isContentEditable,
          sub: doc.sub,
          location: doc.location,
          status: doc.status
        }
      })

      const allFolders = activeFolders.map(folder => {
        const itemPathFolders = folder.path.split('/').slice(0, -1)
        const info = folderInfo(folder)
        return {
          filesCount: info.filesCount,
          folderSize: info.totalSize,
          foldersCount: info.foldersCount,
          pendingFilesCount: info.pendingFilesCount,
          pendingFoldersCount: info.pendingFoldersCount,
          name: itemPathFolders[itemPathFolders.length - 1],
          type: 'folder',
          path: folder.path,
          id: folder._id,
          location: folder.location,
          starred: folder.starred,
          status: folder.status,
          folderIcon: folder.folderIcon,
          folderType: folder.folderType
        }
      })

      const results = search(
        [...allFiles, ...allFolders],
        ['name', 'desc'],
        value
      )
      setContent(results)
    } else {
      setContent(oldData)
    }
  }

  const searchPopover = (
    <div className="user-popover">
      <SearchForm
        wrappedComponentRef={saveFormRef}
        handleSearch={handleSearch}
        resetFields={resetFields}
      />
    </div>
  )

  const dispatchSetBreadcrumb = breadcrumb => {
    dispatch(setBreadcrumb(breadcrumb))
  }

  const dispatchSetShowStarredDocsOnly = isStarred =>
    dispatch(setShowStarredDocsOnly(isStarred))

  let paths = activeDocuments.map(doc => {
    if (doc._id.includes('folder')) {
      return {
        path: doc.path,
        type: 'folder'
      }
    } else {
      return {
        path: doc.path.concat(doc.fileName),
        type: 'file'
      }
    }
  })

  let fileArr = paths.filter(e => e.type === 'file')
  const filterPaths = paths.reduce((acc, element) => {
    element.type === 'file'
      ? acc.push(element)
      : fileArr.forEach(item => {
          if (item.path.includes(element.path)) {
            acc.push(element)
          }
        })
    return acc
  }, [])

  // build an array tree from flat array
  let pathsTree = filterPaths.reduce((r, p) => {
    let names = p.path.split('/')
    names.reduce((q, name) => {
      let temp = q.find(o => o.name === name)
      if (!temp) q.push((temp = { name, children: [] }))
      return temp.children
    }, r)
    return r
  }, [])

  updatePathsTree(pathsTree)
  sortTree(pathsTree)

  const exportMenu = (
    <Menu>
      <Menu.Item onClick={() => exportFilesToPDF(fullName, pathsTree)}>
        {t('TO_PDF')}
      </Menu.Item>
      <Menu.Item onClick={() => exportFilesToExcel(fullName, pathsTree)}>
        {t('TO_EXCEL')}
      </Menu.Item>
    </Menu>
  )

  return enableCreateFile ? (
    <TextFileEditor
      breadcrumb={breadcrumb}
      setEnableCreateFile={setEnableCreateFile}
      setBreadcrumb={dispatchSetBreadcrumb}
      selectedTextFile={selectedTextFile}
      setSelectedTextFile={setSelectedTextFile}
    ></TextFileEditor>
  ) : (
    <Layout style={{ height: '100%' }}>
      <Content style={{ backgroundColor: theme.white }}>
        <PageHeader
          title={
            isStarred
              ? t('FAVOURITES')
              : isPending
              ? t('PENDING')
              : isRejected
              ? t('REJECTED')
              : t('ALL_FILES')
          }
          subTitle={
            !isStarred &&
            !isPending &&
            !isRejected && (
              <Popover
                placement="bottomLeft"
                title={t('SELECT_FOLDER')}
                content={
                  <FileDirectory
                    onFolderSelect={handleFolderSelect}
                    isReadonly={isReadonly}
                  />
                }
                trigger="click"
                arrowPointAtCenter
              >
                <Tooltip title={t('SHOW_FOLDER_TREE')} placement="right">
                  <Icon type="down" />
                </Tooltip>
              </Popover>
            )
          }
          extra={
            !isReadonly && (
              <div>
                <H4>
                  {t('STORAGE')}{' '}
                  {deletedStorage > 0 && (
                    <Tooltip
                      title={
                        <Trans
                          i18nKey="YOUR_STORAGE_INCLUDES_IN_TRASH"
                          values={{
                            deletedStorage: formatBytes(deletedStorage)
                          }}
                        ></Trans>
                      }
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  )}
                </H4>
                <Progress
                  percent={
                    allowedStorage + (backupSize || 0) > 0
                      ? ((usedStorage + (backupSize || 0)) / allowedStorage) *
                        100
                      : 0
                  }
                  successPercent={
                    storageStatus === 'normal' && allowedStorage > 0
                      ? ((usedStorage + (backupSize || 0) - deletedStorage) /
                          allowedStorage) *
                        100
                      : 0
                  }
                  status={storageStatus}
                  format={() =>
                    `${formatBytes(usedStorage + (backupSize || 0))} ${t(
                      'OF'
                    )} ${formatBytes(allowedStorage)} ${t(
                      'USED'
                    ).toLowerCase()}`
                  }
                  className="storage-progress"
                />
              </div>
            )
          }
        />
        {!isPending && !isRejected && (
          <FileNav
            breadcrumb={breadcrumb}
            setBreadcrumb={dispatchSetBreadcrumb}
            searchPopover={searchPopover}
            isSearchMode={isSearchMode}
            searchResults={searchResults}
            isReadonly={isReadonly}
            filesCount={filesCount}
            foldersCount={foldersCount}
            pendingFilesCount={pendingFilesCount}
            pendingFoldersCount={pendingFoldersCount}
            viewMode={viewMode}
            setViewMode={setViewMode}
            contactDataSource={activeContacts}
            showStarredDocsOnly={showStarredDocsOnly}
            setShowStarredDocsOnly={dispatchSetShowStarredDocsOnly}
            isStarred={isStarred}
            setEnableCreateFile={setEnableCreateFile}
            exportMenu={exportMenu}
            selectedFolder={selectedFolder}
            setIsShowSider={setIsShowSider}
            isShowSider={isShowSider}
          />
        )}
        <div style={{ padding: 20 }}>
          <Input.Search
            placeholder={t('Search file or folder')}
            allowClear
            onSearch={value => searchFileOrFolder(value)}
            className="search-asset-liability-input"
            style={{ marginRight: 20 }}
          />
        </div>

        <Spin spinning={isLoading}>
          {isPending || isRejected ? (
            <PendingList
              isRejected={isRejected}
              isPending={isPending}
              pendingPathsTree={[]}
            />
          ) : (
            <FileList
              breadcrumb={breadcrumb}
              setBreadcrumb={dispatchSetBreadcrumb}
              content={isSearchMode ? searchResults : content}
              rootFolder={rootFolder}
              isReadonly={isReadonly}
              userId={userId}
              linkedAssetLiabilityId={linkedAssetLiabilityId}
              filteredDocIds={filteredDocIds}
              setDocToLink={setDocToLink}
              viewMode={viewMode}
              showStarredDocsOnly={showStarredDocsOnly}
              setShowStarredDocsOnly={dispatchSetShowStarredDocsOnly}
              setEnableCreateFile={setEnableCreateFile}
              setSelectedTextFile={setSelectedTextFile}
              isModal={isModal}
              isSelectedMutilpleFiles={isSelectedMutilpleFiles}
              setSelectedFilesFolders={setSelectedFilesFolders}
              selectedFilesFolders={selectedFilesFolders}
              viewDetail={viewDetail}
              selectedDoc={selectedDoc}
              setSelectedDoc={setSelectedDoc}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setIsShowSider={setIsShowSider}
            />
          )}
        </Spin>
      </Content>
      {isXlUp && !isReadonly && (
        <Sider
          collapsible
          collapsedWidth={0}
          collapsed={!isShowSider}
          width={320}
          theme="light"
          style={{
            background: 'linear-gradient(270deg, #FAFBFE 0%, #F4F6FA 100%)'
          }}
        >
          {!selectedDoc ? (
            <div className="sider-details-empty">
              <div
                style={{
                  width: 120,
                  height: 120,
                  backgroundColor: theme.white,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 25
                }}
              >
                <CustomIcon
                  type="text"
                  style={{ fontSize: 64, color: 'transparent' }}
                />
              </div>
              <div style={{ color: theme.dark2 }}>
                {t('SELECT_FILE_OR_FOLDER_TO_SEE_DETAILS')}
              </div>
            </div>
          ) : (
            <div className="sider-details">
              <SharingDetails
                selectedDoc={selectedDoc}
                users={users}
                viewDetail={viewDetail}
                setSelectedDoc={setSelectedDoc}
                documentActivities={documentActivities}
                setDocumentActivities={setDocumentActivities}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </div>
          )}
        </Sider>
      )}
    </Layout>
  )
}
export default withRouter(Files)
