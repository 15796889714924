import React from 'react'
import {
  RENDER_TYPES,
  ASSET_TYPES,
  LIABILITY_TYPES,
  CORE_TYPES,
  VAULTBOX_SAMPLE_DATA,
  TYPE_OF_TRUST_INTEREST_PLURAL_MAP,
  SQUARE_UNITS,
  LOAN_PERIOD_UNITS
} from '../../share/Constants'
import {
  renderDate,
  currencyFormat,
  getAssetLiabilityValueWithSign,
  renderSignedNumber,
  renderLinkedItemsPlainText,
  renderItems,
  pngToBase64
} from '../../share/helpers'
import banner from '../../assets/banner.png'
import { AssetLiabilityTitleWithIcon } from './AssetLiabilitySubtypes'
import DateRangeFilter from '../common/DateRangeFilter'
import Cash, { cashColumns } from '../../model/CashModel'
import Property, { propertyColumns } from '../../model/PropertyModel'
import LoanFromOthers, {
  loanFromOtherColumns
} from '../../model/LoanFromOthersModel'
import LoanToOthers, {
  loanToOthersColumns
} from '../../model/LoanToOthersModel'
import EquityListed, {
  equityListedColumns
} from '../../model/EquityListedModel'
import Insurance, { insuranceColumns } from './../../model/InsuranceModel'
import PropertyLoan, {
  propertyLoanColumns
} from '../../model/PropertyLoanModel'
import CreditCard, { creditCardColumns } from './../../model/CreditCardModel'
import EquityUnlisted, {
  equityUnlistedColumns
} from '../../model/EquityUnlistedModel'
import OtherAssets, { otherAssetsColumns } from '../../model/OtherAssetsModel'
import OtherLiablity, {
  otherLiabilityColumns
} from '../../model/OtherLiabilityModel'
import BrokerageAccount, {
  brokerageAccountColumns
} from '../../model/BrokerageAccountModel'
import Vehicle, { vehicleColumns } from '../../model/VehicleModel'
import VehicleLoan, { vehicleLoanColumns } from './../../model/VehicleLoanModel'
import RetirementAccount, {
  retirementAccountColumns
} from '../../model/RetirementAccountModel'
import { saveAs } from 'file-saver'
import currencyData from 'world-currencies'
import MICS from '../../share/CommonMICs'
import { Workbook } from 'exceljs'
import { addWorksheetContent } from '../../share/importHelpers'
import Trust, { trustColumns } from './../../model/TrustModel'
import Crypto, { cryptoColumns } from '../../model/CryptoModel'
import i18next from 'i18next'

const onDateFilter = (value, recordValue) =>
  recordValue &&
  (!value.startDate ||
    value.startDate.startOf('day').isSameOrBefore(recordValue)) &&
  (!value.endDate || value.endDate.endOf('day').isSameOrAfter(recordValue))

function Valuation({ text, record, isBaseCurrency, baseCurrency, className }) {
  const value =
    text || getAssetLiabilityValueWithSign(record, isBaseCurrency) || null

  return (
    value && (
      <span className={className}>
        <span>{baseCurrency || record.currency}</span>{' '}
        <span>{renderSignedNumber(value, currencyFormat)}</span>
      </span>
    )
  )
}

const sorterFn = (key, type, normFn = obj => obj[key]) => {
  return (a, b) => {
    const first = normFn(a)
    const second = normFn(b)

    if (type === 'string') {
      return (first || '').localeCompare(second || '')
    } else if (type === 'date') {
      return new Date(first || 0) - new Date(second || 0)
    } else {
      return (first || 0) - (second || 0)
    }
  }
}

export const getAssetLiabilityColumns = (
  contacts = [],
  assetsLiabilities = [],
  documents = [],
  events = [],
  passwords = [],
  baseCurrency = null
) => {
  //const { t } = i18next
  return [
    {
      key: 'title',
      title: <span className="dragHandler">{i18next.t('TITLE')}</span>,
      dataIndex: 'title',
      type: RENDER_TYPES.TITLE_WITH_ICON,
      // fixed: 'left',
      width: 120,
      ellipsis: true,
      className: 'td-res',
      sorter: sorterFn('title', 'string'),
      render: (text, record) => <AssetLiabilityTitleWithIcon record={record} />
    },
    {
      key: 'type',
      width: 120,
      title: <span className="dragHandler">{i18next.t('TYPE')}</span>,
      dataIndex: 'type',
      sorter: sorterFn('type', 'string')
    },
    {
      key: 'subType',
      width: 120,
      title: <span className="dragHandler">{i18next.t('SUBTYPE')}</span>,
      dataIndex: 'subType',
      sorter: sorterFn('subType', 'string')
    },
    {
      key: 'description',
      width: 120,
      title: <span className="dragHandler">{i18next.t('DESCRIPTION')}</span>,
      dataIndex: 'description',
      className: 'pre-area',
      exportWidth: 40,
      style: { alignment: { wrapText: true } },
      sorter: sorterFn('description', 'string')
    },
    {
      key: 'purchaseDate',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('ASSET_PURCHASE_DATE')}</span>
      ),
      dataIndex: 'purchaseDate',
      sorter: sorterFn('purchaseDate', 'date'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <DateRangeFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => onDateFilter(value, record.purchaseDate),
      render: renderDate
    },
    {
      key: 'disposalDate',
      width: 120,
      title: <span className="dragHandler">{i18next.t('DISPOSAL_DATE')}</span>,
      dataIndex: 'disposalDate',
      render: renderDate,
      sorter: sorterFn('disposalDate', 'date')
    },
    {
      key: 'startDate',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('LIABILITY_START_DATE')}</span>
      ),
      dataIndex: 'startDate',
      sorter: sorterFn('startDate', 'date'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <DateRangeFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => onDateFilter(value, record.startDate),
      render: renderDate
    },
    {
      key: 'maturityDate',
      width: 120,
      title: (
        <span className="dragHandler">
          {i18next.t('LIABILITY_MATURITY_DATE')}
        </span>
      ),
      dataIndex: 'maturityDate',
      sorter: sorterFn('maturityDate', 'date'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <DateRangeFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => onDateFilter(value, record.maturityDate),
      render: renderDate
    },
    {
      key: 'percentageOwnership',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('PERCENTAGE_OWNERSHIP')}</span>
      ),
      dataIndex: 'percentageOwnership',
      sorter: sorterFn('percentageOwnership', 'number'),
      render: text => text && `${text}%`
    },
    {
      key: 'valuationDate',
      width: 120,
      title: <span className="dragHandler">{i18next.t('VALUATION_DATE')}</span>,
      dataIndex: 'valuationDate',
      sorter: sorterFn('valuationDate', 'date'),
      render: renderDate
    },
    {
      key: 'currency',
      width: 120,
      title: <span className="dragHandler">{i18next.t('CURRENCY')}</span>,
      dataIndex: 'currency',
      sorter: sorterFn('currency', 'string')
    },
    {
      key: 'valuationInAssetLiabilityCurrency',
      width: 120,
      type: RENDER_TYPES.VALUATION,
      title: (
        <span className="dragHandler">
          {i18next.t('VALUATION_ASSET_LIABILITY_CURRENCY')}
        </span>
      ),
      sorter: sorterFn('valuationInAssetLiabilityCurrency', 'number', v =>
        getAssetLiabilityValueWithSign(v, false)
      ),
      render: (text, record) => <Valuation record={record} />
    },
    {
      key: 'valuationInAssetCurrency',
      width: 120,
      type: RENDER_TYPES.VALUATION,
      title: (
        <span className="dragHandler">
          {i18next.t('VALUATION_ASSET_CURRENCY')}
        </span>
      ),
      dataIndex: 'valuationInAssetCurrency',
      sorter: sorterFn('valuationInAssetCurrency', 'number'),
      render: (text, record) => <Valuation record={record} />
    },
    {
      key: 'valuationInBaseCurrency',
      width: 120,
      type: RENDER_TYPES.VALUATION,
      title: (
        <span className="dragHandler">
          {i18next.t('VALUATION_BASE_CURRENCY')}
        </span>
      ),
      dataIndex: 'valuationInBaseCurrency',
      sorter: sorterFn('valuationInBaseCurrency', 'number', v =>
        getAssetLiabilityValueWithSign(v, true)
      ),
      render: (text, record) => (
        <Valuation
          record={record}
          baseCurrency={baseCurrency}
          isBaseCurrency={true}
        />
      )
    },
    {
      key: 'outstandingValueInLiabilityCurrency',
      width: 120,
      type: RENDER_TYPES.VALUATION,
      title: (
        <span className="dragHandler">
          {i18next.t('OUTSTANDING_VALUE_LIABILITY_CURRENCY')}
        </span>
      ),
      dataIndex: 'outstandingValueInLiabilityCurrency',
      sorter: sorterFn('outstandingValueInLiabilityCurrency', 'number', v =>
        getAssetLiabilityValueWithSign(v, false)
      ),
      render: (text, record) => <Valuation record={record} />
    },
    {
      key: 'outstandingValueInBaseCurrency',
      width: 120,
      type: RENDER_TYPES.VALUATION,
      title: (
        <span className="dragHandler">
          {i18next.t('OUTSTANDING_VALUE_BASE_CURRENCY')}
        </span>
      ),
      dataIndex: 'outstandingValueInBaseCurrency',
      sorter: sorterFn('outstandingValueInBaseCurrency', 'number', v =>
        getAssetLiabilityValueWithSign(v, true)
      ),
      render: (text, record) => (
        <Valuation
          record={record}
          baseCurrency={baseCurrency}
          isBaseCurrency={true}
        />
      )
    },
    {
      key: 'bank',
      width: 120,
      title: <span className="dragHandler">{i18next.t('BANK')}</span>,
      dataIndex: 'bank',
      sorter: sorterFn('bank', 'string')
    },
    {
      key: 'accountNumber',
      width: 120,
      title: <span className="dragHandler">{i18next.t('ACCOUNT_NUMBER')}</span>,
      dataIndex: 'accountNumber',
      sorter: sorterFn('accountNumber', 'string')
    },
    {
      key: 'accountType',
      width: 120,
      title: <span className="dragHandler">{i18next.t('ACCOUNT_TYPE')}</span>,
      dataIndex: 'accountType',
      sorter: sorterFn('accountType', 'string')
    },
    {
      key: 'cryptoExchange',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('WALLET_PROVIDER')}</span>
      ),
      dataIndex: 'cryptoExchange',
      sorter: sorterFn('cryptoExchange', 'string')
    },
    {
      key: 'walletAddress',
      width: 120,
      title: <span className="dragHandler">{i18next.t('WALLET_ADDRESS')}</span>,
      dataIndex: 'walletAddress',
      sorter: sorterFn('walletAddress', 'string')
    },
    {
      key: 'address',
      width: 120,
      title: <span className="dragHandler">{i18next.t('ADDRESSES')}</span>,
      dataIndex: 'address',
      sorter: sorterFn('address', 'string')
    },
    {
      key: 'buildingType',
      width: 120,
      title: <span className="dragHandler">{i18next.t('BUILDING_TYPE')}</span>,
      dataIndex: 'buildingType',
      sorter: sorterFn('buildingType', 'string')
    },
    {
      key: 'floorSize',
      width: 120,
      title: <span className="dragHandler">{i18next.t('FLOOR_SIZE')}</span>,
      dataIndex: 'floorSize',
      sorter: sorterFn('floorSize', 'number', v => {
        if (!v?.floorSize) return 0
        const { unit, value } = v.floorSize
        return unit === SQUARE_UNITS.SQ_FT ? 0.09290304 * value : value
      }),
      render: text => text?.value && `${text.value} ${text.unit}`
    },
    {
      key: 'buildDate',
      width: 120,
      title: <span className="dragHandler">{i18next.t('BUILD_DATE')}</span>,
      dataIndex: 'buildDate',
      sorter: sorterFn('buildDate', 'date'),
      render: renderDate
    },
    {
      key: 'holdingType',
      width: 120,
      title: <span className="dragHandler">{i18next.t('HOLDING_TYPE')}</span>,
      dataIndex: 'holdingType',
      sorter: sorterFn('holdingType', 'string')
    },
    {
      key: 'leaseholdDuration',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('LEASEHOLD_DURATION')}</span>
      ),
      dataIndex: 'leaseholdDuration'
    },
    {
      key: 'tenant',
      width: 120,
      title: <span className="dragHandler">{i18next.t('TENANT')}</span>,
      dataIndex: 'tenant',
      render: items => renderLinkedItemsPlainText(items, contacts, 'name')
    },
    {
      key: 'rentAmount',
      width: 120,
      title: <span className="dragHandler">{i18next.t('RENT_AMOUNT')}</span>,
      dataIndex: 'rentAmount',
      sorter: sorterFn(
        'rentAmount',
        'number',
        obj => obj?.rentAmount?.value || 0
      ),
      render: text =>
        text?.value && `${currencyFormat(text.value)} ${text.unit}`
    },
    {
      key: 'rentalStartDate',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('RENTAL_START_DATE')}</span>
      ),
      dataIndex: 'rentalStartDate',
      sorter: sorterFn('rentalStartDate', 'date'),
      render: renderDate
    },
    {
      key: 'rentalEndDate',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('RENTAL_END_DATE')}</span>
      ),
      dataIndex: 'rentalEndDate',
      sorter: sorterFn('rentalEndDate', 'date'),
      render: renderDate
    },
    {
      key: 'vehicleRegistrationNumber',
      width: 120,
      title: (
        <span className="dragHandler">
          {i18next.t('VEHICLE_REGISTRATION_NUMBER')}
        </span>
      ),
      dataIndex: 'vehicleRegistrationNumber',
      sorter: sorterFn('vehicleRegistrationNumber', 'string')
    },
    {
      key: 'brokerageName',
      width: 120,
      title: <span className="dragHandler">{i18next.t('BROKERAGE_NAME')}</span>,
      dataIndex: 'brokerageName',
      sorter: sorterFn('brokerageName', 'string')
    },
    {
      key: 'jointAccount',
      width: 120,
      title: <span className="dragHandler">{i18next.t('JOINT_ACCOUNT')}</span>,
      dataIndex: 'jointAccount',
      render: text => (text === true ? 'Yes' : null)
    },
    {
      key: 'exchange',
      width: 120,
      title: <span className="dragHandler">{i18next.t('EXCHANGE')}</span>,
      dataIndex: 'exchange',
      sorter: sorterFn('exchange', 'string')
    },
    {
      key: 'company',
      width: 120,
      title: <span className="dragHandler">{i18next.t('COMPANY')}</span>,
      dataIndex: 'company',
      sorter: sorterFn('company', 'string'),
      render: items => renderLinkedItemsPlainText([items], contacts, 'name')
    },
    {
      key: 'price',
      width: 120,
      title: <span className="dragHandler">{i18next.t('PRICE')}</span>,
      dataIndex: 'price',
      sorter: sorterFn('price', 'number'),
      render: currencyFormat
    },
    {
      key: 'quantity',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('NUMBER_OF_SHARES')}</span>
      ),
      dataIndex: 'quantity',
      sorter: sorterFn('quantity', 'number')
    },
    {
      key: 'classOfShares',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('CLASS_OF_SHARES')}</span>
      ),
      dataIndex: 'classOfShares',
      sorter: sorterFn('classOfShares', 'string')
    },
    {
      key: 'shareCertificateNumber',
      width: 120,
      title: (
        <span className="dragHandler">
          {i18next.t('SHARE_CERTIFICATE_NUMBER')}
        </span>
      ),
      dataIndex: 'shareCertificateNumber',
      sorter: sorterFn('shareCertificateNumber', 'string')
    },
    {
      key: 'percentageOfShares',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('PERCENTAGE_OF_SHARES')}</span>
      ),
      dataIndex: 'percentageOfShares',
      sorter: sorterFn('percentageOfShares', 'number'),
      render: text => text && `${text}%`
    },
    {
      key: 'nameAssured',
      width: 120,
      title: <span className="dragHandler">{i18next.t('LIFE_ASSURED')}</span>,
      dataIndex: 'nameAssured',
      sorter: sorterFn('nameAssured', 'string'),
      render: items => renderLinkedItemsPlainText([items], contacts, 'name')
    },
    {
      key: 'typeOfPolicy',
      width: 120,
      title: <span className="dragHandler">{i18next.t('TYPE_OF_POLICY')}</span>,
      dataIndex: 'typeOfPolicy',
      sorter: sorterFn('typeOfPolicy', 'string')
    },
    {
      key: 'sumAssuredInAssetCurrency',
      width: 120,
      title: (
        <span className="dragHandler">
          {i18next.t('SUM_ASSURED_IN_ASSET_CURRENCY')}
        </span>
      ),
      type: RENDER_TYPES.CURRENCY,
      dataIndex: 'sumAssuredInAssetCurrency',
      sorter: sorterFn('sumAssuredInAssetCurrency', 'number'),
      render: (text, record) =>
        text && <Valuation text={text} record={record} />
    },
    {
      key: 'sumAssuredInBaseCurrency',
      width: 120,
      title: (
        <span className="dragHandler">
          {i18next.t('SUM_ASSURED_IN_BASE_CURRENCY')}
        </span>
      ),
      type: RENDER_TYPES.CURRENCY,
      dataIndex: 'sumAssuredInBaseCurrency',
      sorter: sorterFn('sumAssuredInBaseCurrency', 'number'),
      render: (text, record) =>
        text && (
          <Valuation
            record={record}
            baseCurrency={baseCurrency}
            isBaseCurrency={true}
          />
        )
    },
    {
      key: 'premium',
      width: 120,
      title: <span className="dragHandler">{i18next.t('PREMIUM')}</span>,
      dataIndex: 'premium',
      sorter: sorterFn('premium', 'number', obj => obj?.premium?.value || 0),
      render: text =>
        text?.value && `${currencyFormat(text.value)} ${text.unit}`
    },
    {
      key: 'insurer',
      width: 120,
      title: <span className="dragHandler">{i18next.t('INSURER')}</span>,
      dataIndex: 'insurer',
      sorter: sorterFn('insurer', 'string')
    },
    {
      key: 'policyOwner',
      width: 120,
      title: <span className="dragHandler">{i18next.t('POLICY_OWNER')}</span>,
      dataIndex: 'policyOwner',
      sorter: sorterFn('policyOwner', 'string'),
      render: items => renderLinkedItemsPlainText([items], contacts, 'name')
    },
    {
      key: 'policyNumber',
      width: 120,
      title: <span className="dragHandler">{i18next.t('POLICY_NUMBER')}</span>,
      dataIndex: 'policyNumber',
      sorter: sorterFn('policyNumber', 'string')
    },
    {
      key: 'entitlement',
      width: 120,
      title: <span className="dragHandler">{i18next.t('ENTITLEMENT')}</span>,
      dataIndex: 'entitlement',
      sorter: sorterFn('entitlement', 'number'),
      render: text => text && `${text}%`
    },
    {
      key: 'insuranceAdvisor',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('INSURANCE_ADVISOR')}</span>
      ),
      dataIndex: 'insuranceAdvisor',
      sorter: sorterFn('insuranceAdvisor', 'string'),
      render: items => renderLinkedItemsPlainText(items, contacts, 'name')
    },
    {
      key: 'beneficiaries',
      width: 120,
      title: <span className="dragHandler">{i18next.t('BENEFICIARIES')}</span>,
      dataIndex: 'beneficiaries',
      sorter: sorterFn('beneficiaries', 'string'),
      render: items => renderLinkedItemsPlainText(items, contacts, 'name')
    },
    {
      key: 'otherNotes',
      width: 120,
      title: <span className="dragHandler">{i18next.t('OTHER_NOTES')}</span>,
      dataIndex: 'otherNotes',
      sorter: sorterFn('otherNotes', 'string')
    },
    {
      key: 'interestRate',
      width: 120,
      title: <span className="dragHandler">{i18next.t('INTEREST_RATE')}</span>,
      dataIndex: 'interestRate',
      sorter: sorterFn(
        'interestRate',
        'number',
        obj => obj?.interestRate?.value || 0
      ),
      render: text => text?.value && `${text.value}% ${text.unit}`
    },
    {
      key: 'typeOfInterestRate',
      width: 120,
      title: (
        <span className="dragHandler">
          {i18next.t('TYPE_OF_INTEREST_RATE')}
        </span>
      ),
      dataIndex: 'typeOfInterestRate',
      sorter: sorterFn('typeOfInterestRate', 'string')
    },
    {
      key: 'loanPeriod',
      width: 120,
      title: <span className="dragHandler">{i18next.t('LOAN_PERIOD')}</span>,
      dataIndex: 'loanPeriod',
      sorter: sorterFn('loanPeriod', 'number', obj => {
        if (!obj?.loanPeriod) return 0

        const { unit, value } = obj.loanPeriod
        return unit === LOAN_PERIOD_UNITS.MONTHS
          ? 30 * value
          : unit === LOAN_PERIOD_UNITS.YEARS
          ? 365 * value
          : value
      }),
      render: text => text?.value && `${text.value} ${text.unit}`
    },
    {
      key: 'numberOfRepayments',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('NUMBER_OF_REPAYMENTS')}</span>
      ),
      dataIndex: 'numberOfRepayments',
      sorter: sorterFn('numberOfRepayments', 'number')
    },
    {
      key: 'borrower',
      width: 120,
      title: <span className="dragHandler">{i18next.t('BORROWER')}</span>,
      dataIndex: 'borrower',
      render: items => renderLinkedItemsPlainText([items], contacts, 'name')
    },
    {
      key: 'retirementAccountType',
      width: 120,
      title: (
        <span className="dragHandler">
          {i18next.t('RETIREMENT_ACCOUNT_TYPE')}
        </span>
      ),
      dataIndex: 'retirementAccountType',
      sorter: sorterFn('retirementAccountType', 'string')
    },
    {
      key: 'otherAssetType',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('OTHER_ASSET_TYPE')}</span>
      ),
      dataIndex: 'otherAssetType',
      sorter: sorterFn('otherAssetType', 'string')
    },
    {
      key: 'lender',
      width: 120,
      title: <span className="dragHandler">{i18next.t('LENDER')}</span>,
      dataIndex: 'lender',
      render: items => renderLinkedItemsPlainText([items], contacts, 'name')
    },
    // {
    //   key: 'lengthOfLoan',
    //   width: 120,
    //   title: <span className="dragHandler">{i18next.t('LOAN_PERIOD')}</span>,
    //   dataIndex: 'lengthOfLoan',
    //   sorter: sorterFn('lengthOfLoan', 'number', obj => {
    //     if (!obj?.lengthOfLoan) return 0

    //     const { unit, value } = obj.lengthOfLoan
    //     return unit === LENGTH_OF_LOAN_UNITS.MONTHS
    //       ? 30 * value
    //       : unit === LENGTH_OF_LOAN_UNITS.YEARS
    //       ? 365 * value
    //       : value
    //   }),
    //   render: text => text?.value && `${text.value} ${text.unit}`
    // },
    {
      key: 'yearOfLoan',
      width: 120,
      title: (
        <span className="dragHandler">
          {i18next.t('NO_OF_YEARS_REPAYMENT_COMPLETED')}
        </span>
      ),
      dataIndex: 'yearOfLoan',
      sorter: sorterFn('yearOfLoan', 'string')
    },
    {
      key: 'monthlyPayment',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('MONTHLY_PAYMENT')}</span>
      ),
      dataIndex: 'monthlyPayment',
      sorter: sorterFn('monthlyPayment', 'number'),
      render: currencyFormat
    },
    {
      key: 'securityCollateral',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('SECURITY_COLLATERAL')}</span>
      ),
      dataIndex: 'securityCollateral',
      sorter: sorterFn('securityCollateral', 'string')
    },
    {
      key: 'expiryDate',
      width: 120,
      title: <span className="dragHandler">{i18next.t('EXPIRY_DATE')}</span>,
      dataIndex: 'expiryDate',
      sorter: sorterFn('expiryDate', 'date'),
      render: renderDate
    },
    {
      key: 'paymentDueDate',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('PAYMENT_DUE_DATE')}</span>
      ),
      dataIndex: 'paymentDueDate',
      sorter: sorterFn('paymentDueDate', 'date'),
      render: renderDate
    },
    {
      key: 'issuingBank',
      width: 120,
      title: <span className="dragHandler">{i18next.t('ISSUING_BANK')}</span>,
      dataIndex: 'issuingBank',
      sorter: sorterFn('issuingBank', 'string')
    },
    {
      key: 'recurringPaymentDueDay',
      width: 120,
      title: (
        <span className="dragHandler">
          {i18next.t('RECURRING_PAYMENT_DUE_DAY')}
        </span>
      ),
      dataIndex: 'recurringPaymentDueDay',
      sorter: sorterFn('recurringPaymentDueDay', 'date')
    },
    {
      key: 'creditLimit',
      width: 120,
      title: <span className="dragHandler">{i18next.t('CREDIT_LIMIT')}</span>,
      type: RENDER_TYPES.CURRENCY,
      dataIndex: 'creditLimit',
      sorter: sorterFn('creditLimit', 'number'),
      render: currencyFormat
    },
    {
      key: 'trustRevocability',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('TRUST_REVOCABILITY')}</span>
      ),
      dataIndex: 'trustRevocability',
      sorter: sorterFn('trustRevocability', 'string')
    },
    {
      key: 'trustType',
      width: 120,
      title: <span className="dragHandler">{i18next.t('TRUST_TYPE')}</span>,
      dataIndex: 'trustType',
      sorter: sorterFn('trustType', 'string')
    },
    {
      key: 'typeOfTrustInterest',
      width: 120,
      title: (
        <span className="dragHandler">
          {i18next.t('TYPE_OF_TRUST_INTEREST')}
        </span>
      ),
      dataIndex: 'typeOfTrustInterest',
      render: item =>
        item?.key &&
        `${TYPE_OF_TRUST_INTEREST_PLURAL_MAP[item.key] || item.key}: ${
          item.values?.length
            ? renderLinkedItemsPlainText(item.values, contacts, 'name')
            : ''
        }`
    },
    {
      key: 'contacts',
      width: 120,
      title: <span className="dragHandler">{i18next.t('CONTACTS')}</span>,
      dataIndex: 'contacts',
      render: items => renderLinkedItemsPlainText(items, contacts, 'name')
    },
    {
      key: 'links',
      width: 120,
      title: (
        <span className="dragHandler">
          {i18next.t('LINK_TO_ASSETS_LIABILITIES')}
        </span>
      ),
      dataIndex: 'links',
      render: items =>
        renderLinkedItemsPlainText(items, assetsLiabilities, 'title')
    },
    {
      key: 'references',
      width: 120,
      title: <span className="dragHandler">{i18next.t('REFERENCES')}</span>,
      type: RENDER_TYPES.ITEMS,
      dataIndex: 'references',
      exportWidth: 40,
      style: { alignment: { wrapText: true } },
      render: renderItems
    },
    {
      key: 'documents',
      width: 120,
      title: (
        <span className="dragHandler">{i18next.t('LINK_TO_DOCUMENTS')}</span>
      ),
      dataIndex: 'documents',
      render: items => renderLinkedItemsPlainText(items, documents, 'fileName')
    },
    {
      key: 'passwords',
      width: 120,
      title: <span className="dragHandler">{i18next.t('PASSWORDS')}</span>,
      dataIndex: 'passwords',
      render: items => renderLinkedItemsPlainText(items, passwords, 'title')
    },
    {
      key: 'events',
      width: 120,
      title: <span className="dragHandler">{i18next.t('EVENTS')}</span>,
      dataIndex: 'events',
      render: items => renderLinkedItemsPlainText(items, events, 'description')
    }
  ]
}

const baseValuationColumns = () => [
  {
    key: 'title',
    title: <span className="dragHandler">{i18next.t('TITLE')}</span>,
    dataIndex: 'title',
    type: RENDER_TYPES.TITLE_WITH_ICON,
    width: 120,
    ellipsis: true,
    className: 'td-res',
    // fixed: 'left',
    sorter: sorterFn('title', 'string'),
    render: (text, record) => <AssetLiabilityTitleWithIcon record={record} />
  },
  {
    key: 'quantity',
    width: 120,
    dataIndex: 'quantity',
    title: <span className="dragHandler">{i18next.t('QUANTITY')}</span>,
    sorter: sorterFn('quantity', 'number')
  },
  {
    key: 'percentageOwnership',
    width: 120,
    title: (
      <span className="dragHandler">{i18next.t('PERCENTAGE_OWNERSHIP')}</span>
    ),
    dataIndex: 'percentageOwnership',
    sorter: sorterFn('percentageOwnership', 'number'),
    render: text => text && `${text}%`
  }
]

export const assetValuationColumns = baseCurrency => [
  ...baseValuationColumns(),
  {
    key: 'valuationInAssetCurrency',
    width: 120,
    type: RENDER_TYPES.VALUATION,
    title: (
      <span className="dragHandler">
        {i18next.t('VALUATION_ASSET_CURRENCY')}
      </span>
    ),
    dataIndex: 'valuationInAssetCurrency',
    sorter: sorterFn('valuationInAssetCurrency', 'number'),
    render: (text, record) => <Valuation record={record} />
  },
  {
    key: 'valuationInBaseCurrency',
    width: 120,
    type: RENDER_TYPES.VALUATION,
    title: (
      <span className="dragHandler">
        {i18next.t('VALUATION_BASE_CURRENCY')}
      </span>
    ),
    dataIndex: 'valuationInBaseCurrency',
    sorter: sorterFn('valuationInBaseCurrency', 'number', obj =>
      getAssetLiabilityValueWithSign(obj, true)
    ),
    render: (text, record) => (
      <Valuation
        record={record}
        baseCurrency={baseCurrency}
        isBaseCurrency={true}
      />
    )
  }
]

export const liabilityValuationColumns = baseCurrency => [
  ...baseValuationColumns(),
  {
    key: 'outstandingValueInLiabilityCurrency',
    width: 120,
    type: RENDER_TYPES.VALUATION,
    title: (
      <span className="dragHandler">
        {i18next.t('OUTSTANDING_VALUE_LIABILITY_CURRENCY')}
      </span>
    ),
    dataIndex: 'outstandingValueInLiabilityCurrency',
    sorter: sorterFn('outstandingValueInLiabilityCurrency', 'number', obj =>
      getAssetLiabilityValueWithSign(obj, true)
    ),
    render: (text, record) => <Valuation record={record} />
  },
  {
    key: 'outstandingValueInBaseCurrency',
    width: 120,
    type: RENDER_TYPES.VALUATION,
    title: (
      <span className="dragHandler">
        {i18next.t('OUTSTANDING_VALUE_BASE_CURRENCY')}
      </span>
    ),
    dataIndex: 'outstandingValueInBaseCurrency',
    sorter: sorterFn('outstandingValueInBaseCurrency', 'number', obj =>
      getAssetLiabilityValueWithSign(obj, true)
    ),
    render: (text, record) => (
      <Valuation
        record={record}
        baseCurrency={baseCurrency}
        isBaseCurrency={true}
      />
    )
  }
]

export const assetLiabilityValuationColumns = baseCurrency => [
  ...baseValuationColumns(),
  {
    key: 'valuationInAssetLiabilityCurrency',
    width: 120,
    type: RENDER_TYPES.VALUATION,
    title: (
      <span className="dragHandler">
        {i18next.t('VALUATION_ASSET_LIABILITY_CURRENCY')}
      </span>
    ),
    render: (text, record) => <Valuation record={record} />
  },
  {
    key: 'valuationInBaseCurrency',
    width: 120,
    type: RENDER_TYPES.VALUATION,
    title: (
      <span className="dragHandler">
        {i18next.t('VALUATION_BASE_CURRENCY')}
      </span>
    ),
    dataIndex: 'valuationInBaseCurrency',
    sorter: sorterFn('valuationInBaseCurrency', 'number', obj =>
      getAssetLiabilityValueWithSign(obj, true)
    ),
    render: (text, record) => (
      <Valuation
        record={record}
        baseCurrency={baseCurrency}
        isBaseCurrency={true}
      />
    )
  }
]

const valuationExportColumns = [
  {
    key: 'type',
    title: i18next.t('TYPE'),
    dataIndex: 'type'
  },
  {
    key: 'subType',
    title: i18next.t('SUBTYPE'),
    dataIndex: 'subType'
  }
]

export const assetValuationExportColumns = baseCurrency => [
  ...assetValuationColumns(baseCurrency),
  ...valuationExportColumns
]

export const liabilityValuationExportColumns = baseCurrency => [
  ...liabilityValuationColumns(baseCurrency),
  ...valuationExportColumns
]

export const assetLiabilityValuationExportColumns = baseCurrency => [
  ...assetLiabilityValuationColumns(baseCurrency),
  ...valuationExportColumns
]

export const currencyColumns = (type, baseCurrency) => [
  {
    key: 'currency',
    width: 120,
    title: type ? (
      <span className="dragHandler">{i18next.t('TYPE_CURRENCY')}</span>
    ) : (
      i18next.t('ASSET_LIABILITY_CURRENCY')
    ),
    dataIndex: 'currency'
  },
  {
    key: 'baseCurrency',
    width: 120,
    title: <span className="dragHandler">{i18next.t('BASE_CURRENCY')}</span>,
    render: () => baseCurrency
  }
]

export const balanceSheetColumns = baseCurrency => [
  {
    key: 'title',
    width: 300,
    title: <span className="dragHandler">{i18next.t('TITLE')}</span>,
    dataIndex: 'title',
    type: RENDER_TYPES.TITLE_WITH_ICON,
    className: 'balance-sheet-title',
    render: (text, record) =>
      !record.children ? text : <AssetLiabilityTitleWithIcon record={record} />
  },
  {
    key: 'valuationInAssetCurrency',
    width: 300,
    type: RENDER_TYPES.VALUATION,
    title: (
      <span className="dragHandler">
        {i18next.t('VALUATION_ASSET_CURRENCY')}
      </span>
    ),
    dataIndex: 'valuationInAssetCurrency',
    sorter: sorterFn('valuationInAssetCurrency', 'number', obj =>
      getAssetLiabilityValueWithSign(obj, true)
    ),
    render: (text, record) => <Valuation record={record} />
  },
  {
    key: 'valuationInBaseCurrency',
    width: 300,
    type: RENDER_TYPES.VALUATION,
    title: (
      <span className="dragHandler">
        {i18next.t('VALUATION_BASE_CURRENCY')}
      </span>
    ),
    dataIndex: 'valuationInBaseCurrency',
    sorter: sorterFn('valuationInBaseCurrency', 'number', obj =>
      getAssetLiabilityValueWithSign(obj, true)
    ),
    render: (text, record) => (
      <Valuation
        record={record}
        baseCurrency={baseCurrency}
        isBaseCurrency={true}
        className={
          record.includeValueInNetWorth === false ? 'exclude-networth' : ''
        }
      />
    )
  }
]

export const assetLiabilityColumnsSmScreen = baseCurrency => [
  {
    key: 'title',
    title: i18next.t('TITLE'),
    dataIndex: 'title',
    type: RENDER_TYPES.TITLE_WITH_ICON,
    width: 120,
    ellipsis: true,
    className: 'td-res',
    render: (text, record) => <AssetLiabilityTitleWithIcon record={record} />
  },
  {
    key: 'valuationInBaseCurrency',
    type: RENDER_TYPES.VALUATION,
    title: i18next.t('VALUATION_BASE_CURRENCY'),
    dataIndex: 'valuationInBaseCurrency',
    render: (text, record) => (
      <Valuation
        record={record}
        baseCurrency={baseCurrency}
        isBaseCurrency={true}
      />
    )
  }
]

export const mapAssetLiabilityModel = (
  record,
  selectedType,
  selectedSubType
) => {
  switch (selectedSubType) {
    case ASSET_TYPES.CASH:
      return new Cash(record)
    case ASSET_TYPES.PROPERTY:
      return new Property(record)
    case ASSET_TYPES.VEHICLE:
      return new Vehicle(record)
    case ASSET_TYPES.EQUITY_LISTED:
      return new EquityListed(record)
    case ASSET_TYPES.EQUITY_UNLISTED:
      return new EquityUnlisted(record)
    case ASSET_TYPES.INSURANCE:
      return new Insurance(record)
    case ASSET_TYPES.LOAN_TO_OTHERS:
      return new LoanToOthers(record)
    case ASSET_TYPES.BROKERAGE_ACCOUNT:
      return new BrokerageAccount(record)
    case ASSET_TYPES.RETIREMENT_ACCOUNT:
      return new RetirementAccount(record)
    case ASSET_TYPES.TRUST:
      return new Trust(record)
    case ASSET_TYPES.CRYPTO:
      return new Crypto(record)
    case ASSET_TYPES.OTHER_ASSETS:
      return new OtherAssets(record)
    case LIABILITY_TYPES.LOAN_FROM_OTHERS:
      return new LoanFromOthers(record)
    case LIABILITY_TYPES.PROPERTY_LOAN:
      return new PropertyLoan(record)
    case LIABILITY_TYPES.VEHICLE_LOAN:
      return new VehicleLoan(record)
    case LIABILITY_TYPES.CREDIT_CARD:
      return new CreditCard(record)
    case LIABILITY_TYPES.OTHER_LIABILITY:
      return new OtherLiablity(record)
    default:
      return selectedType === CORE_TYPES.ASSET
        ? new OtherAssets(record)
        : new OtherLiablity(record)
  }
}

export const downloadExcelImportTemplate = async () => {
  const workbook = new Workbook()

  const base64Banner = await pngToBase64(banner)

  const currencies = Object.values(currencyData).map(c => {
    return {
      code: c.iso.code,
      name: c.name
    }
  })

  VAULTBOX_SAMPLE_DATA.forEach(data => {
    addWorksheetContent(
      base64Banner,
      workbook,
      data,
      data.subType,
      getColumnsFromSubtype(data.subType)
    )
  })

  const currencyRefWs = workbook.addWorksheet('Currencies')
  currencyRefWs.columns = [
    {
      header: 'Currency code',
      key: 'code',
      width: 30
    },
    {
      header: 'Currency name',
      key: 'name',
      width: 30
    }
  ]

  currencyRefWs.addRows(currencies)
  const exchangeRefWs = workbook.addWorksheet('Exchanges')
  exchangeRefWs.columns = [
    {
      header: 'Exchange code',
      key: 'code',
      width: 30
    },
    {
      header: 'Exchange name',
      key: 'name',
      width: 30
    }
  ]
  exchangeRefWs.addRows(MICS)

  const data = await workbook['xlsx'].writeBuffer()

  const blob = new Blob([data])
  saveAs(blob, 'vaultbox_template.xlsx')
}

export const getColumnsFromSubtype = subtype => {
  switch (subtype) {
    case ASSET_TYPES.CASH:
      return cashColumns()
    case ASSET_TYPES.PROPERTY:
      return propertyColumns()
    case ASSET_TYPES.VEHICLE:
      return vehicleColumns()
    case ASSET_TYPES.BROKERAGE_ACCOUNT:
      return brokerageAccountColumns()
    case ASSET_TYPES.EQUITY_LISTED:
      return equityListedColumns()
    case ASSET_TYPES.EQUITY_UNLISTED:
      return equityUnlistedColumns()
    case ASSET_TYPES.INSURANCE:
      return insuranceColumns()
    case ASSET_TYPES.LOAN_TO_OTHERS:
      return loanToOthersColumns()
    case ASSET_TYPES.RETIREMENT_ACCOUNT:
      return retirementAccountColumns()
    case ASSET_TYPES.TRUST:
      return trustColumns()
    case ASSET_TYPES.CRYPTO:
      return cryptoColumns()
    case ASSET_TYPES.OTHER_ASSETS:
      return otherAssetsColumns()
    case LIABILITY_TYPES.PROPERTY_LOAN:
      return propertyLoanColumns()
    case LIABILITY_TYPES.VEHICLE_LOAN:
      return vehicleLoanColumns()
    case LIABILITY_TYPES.CREDIT_CARD:
      return creditCardColumns()
    case LIABILITY_TYPES.LOAN_FROM_OTHERS:
      return loanFromOtherColumns()
    case LIABILITY_TYPES.OTHER_LIABILITY:
      return otherLiabilityColumns()
    default:
      return []
  }
}
