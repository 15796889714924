import { CORE_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import AssetLiabilityModel, {
  baseAssetLiabilityColumns
} from './AssetLiabilityModel'
import i18next from 'i18next'

class Asset extends AssetLiabilityModel {
  /**
   * Construct a new Asset instance
   * @param {object} params - Asset creation options:
   * @param {date} [params.purchaseDate]
   * @param {date} [params.disposalDate]
   * @param {number} [params.valuationInAssetCurrency]
   * @param {number} [params.valuationInBaseCurrency]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    currency,
    valuationDate,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    status, 
    events,
    passwords
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      currency,
      valuationDate,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords
    })

    this.type = CORE_TYPES.ASSET
    this.purchaseDate = purchaseDate && purchaseDate.startOf('day')
    this.disposalDate = disposalDate && disposalDate.startOf('day')
    this.valuationInAssetCurrency = valuationInAssetCurrency
    this.valuationInBaseCurrency = valuationInBaseCurrency
  }
}

export default Asset

export const assetColumns = () => [
  ...baseAssetLiabilityColumns(),
  {
    key: 'valuationInAssetCurrency',
    type: IMPORT_COL_TYPES.CURRENCY,
    title: i18next.t('VALUATION_ASSET_CURRENCY')
  }
]
