import { Icon } from 'antd'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export default function ShowLogIcon({ item }) {
  const theme = useContext(ThemeContext)

  switch (item?.Detail?.action) {
    case 'copied':
      return (
        <Icon
          type="copy"
          style={{
            fontSize: 16,
            color: theme.dark2
          }}
        />
      )
    case 'deleted':
      return (
        <Icon
          type="delete"
          style={{
            fontSize: 16,
            color: theme.dark2
          }}
        />
      )
    case 'shared':
      return (
        <Icon
          type="share-alt"
          style={{
            fontSize: 16,
            color: theme.dark2
          }}
        />
      )
    case 'moved':
      return (
        <Icon
          type="arrow-right"
          style={{
            fontSize: 16,
            color: theme.dark2
          }}
        />
      )
    case 'restored':
      return (
        <Icon
          type="sync"
          style={{
            fontSize: 16,
            color: theme.dark2
          }}
        />
      )
    case 'uploaded':
      return (
        <Icon
          type="upload"
          style={{
            fontSize: 16,
            color: theme.dark2
          }}
        />
      )

    case 'removed':
      return (
        <Icon
          type="undo"
          style={{
            fontSize: 16,
            color: theme.dark2
          }}
        />
      )

    case 'created':
      return (
        <Icon
          type="plus"
          style={{
            fontSize: 16,
            color: theme.dark2
          }}
        />
      )

    default:
      return (
        <Icon
          type="edit"
          style={{
            fontSize: 16,
            color: theme.dark2
          }}
        />
      )
  }
}
