import ReactGA from 'react-ga'
import config from '../config'

export const initGA = () => {
  ReactGA.initialize(config.ga.TRACKING_ID)
}

export const GApageView = page => {
  ReactGA.set({ page })
  ReactGA.pageview(page)
}

export const GAmodalView = modal => {
  ReactGA.modalview(modal)
}

export const GAevent = event => {
  ReactGA.event(event)
}
