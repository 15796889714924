import React, { useState, useEffect } from 'react'
import { Icon, AutoComplete, Tooltip } from 'antd'
import Button from '../override/Button'
import FormItem from '../override/FormItem'
import { useTranslation } from 'react-i18next'
import ContactModal from '../contacts/ContactModal'

export default function AddMultipleEmails(props) {
  const {
    fields,
    name,
    title,
    getFieldDecorator,
    setFieldsValue,
    value,
    customRules,
    getFieldValue,
    sourceAutoComplete
  } = props
  const [rowIds, setRowIds] = useState([])
  const [contactModalVisibile, setContactModalVisibile] = useState(false)
  const [email, setEmail] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    setRowIds(value ? value.map((item, index) => index) : [])
  }, [value])

  const add = () => {
    const newRowId = rowIds.length ? rowIds[rowIds.length - 1] + 1 : 0
    setRowIds([...rowIds, newRowId])
  }

  const remove = rowId => {
    setRowIds(rowIds.filter(id => id !== rowId))
  }

  return (
    <>
      {rowIds.map(rowId => {
        return (
          <div key={rowId} className="dynamic-field-row">
            {fields.map((field, index) => {
              const customRule =
                customRules &&
                customRules.find(rule => rule.fieldKey === field.key)

              return (
                <FormItem className="dynamic-field" key={index}>
                  {getFieldDecorator(`${name}[${rowId}][${field.key}]`, {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: `${t('ENTER')} ${field.key}.`
                      },
                      ...((customRule && customRule.rules) || [])
                    ],
                    initialValue:
                      (value && value[rowId] && value[rowId][field.key]) ||
                      (field.default && field.default(name))
                  })(
                    field.node ? (
                      field.node(name, rowId)
                    ) : (
                      <AutoComplete
                        dataSource={sourceAutoComplete}
                        setFieldsValue={setFieldsValue}
                        filterOption={(inputValue, option) =>
                          option.props.children
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    )
                  )}
                </FormItem>
              )
            })}
            <Tooltip title={t('Add new contact')}>
              <Button
                size="large"
                style={{ marginRight: 10 }}
                icon="contacts"
                disabled={sourceAutoComplete.includes(
                  getFieldValue('emails')[rowId]['email']
                )}
                onClick={() => {
                  setEmail(getFieldValue('emails')[rowId]['email'])
                  setContactModalVisibile(true)
                }}
              />
            </Tooltip>
            <Icon type="minus-circle-o" onClick={() => remove(rowId)} />
          </div>
        )
      })}

      <Button
        size="large"
        type="dashed"
        onClick={add}
        style={{ width: '100%' }}
      >
        <Icon type="plus" /> {t('ADD')} {title || name}
      </Button>
      <ContactModal
        visible={contactModalVisibile}
        setVisible={setContactModalVisibile}
        selectedRecord={{}}
        email={email}
      />
    </>
  )
}
