import React, { useState, useEffect, useContext } from 'react'
import {
  Form,
  Radio,
  message,
  Divider,
  Row,
  Col,
  Breadcrumb,
  Layout,
  Modal
} from 'antd'
import {
  DATE_FORMAT,
  CORE_TYPES,
  ASSET_TYPES,
  LIABILITY_TYPES,
  END_OF_TIME,
  VAULTBOX_SAMPLE_DATA,
  ACCESS_LEVEL
} from '../../share/Constants'
//import { StringResources } from '../../share/StringResources'
import uuidv4 from 'uuid/v4'
import PouchDB from 'pouchdb'
import {
  getRecord,
  getRecords,
  uploadEncryptedData,
  getLatestVersion,
  getLatestValuation,
  getOldestValuation,
  linkDocumentsFromAssetsLiabilities
} from '../../lib/pouchDb'
import moment from 'moment'
import {
  checkRegistryType,
  filterEmptyEls,
  removeHtmlTags,
  sanitizeValue,
  valuationDateLabel
} from '../../share/helpers'
import VaultContext from '../../contexts/VaultContext'
import FormItem from '../override/FormItem'
import DocumentsLinksModal from './DocumentsLinksModal'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'
import AssetLiabilitySubtypes from './AssetLiabilitySubtypes'
import ContactLinks from './ContactLinks'
import EventLinks from '../common/EventLinks'
import DocumentLinks from './DocumentLinks'
import { withRouter, useParams, useLocation } from 'react-router-dom'
import SiderActionButtons from '../common/SiderActionButtons'
import TextInput from '../common/TextInput'
import { useSelector, useDispatch } from 'react-redux'
import { uniq } from 'lodash'
import AssetLiabilityValuation from '../../model/AssetLiabilityValuationModel'
import FileModal from '../file/FileModal'
import ContactModal from '../contacts/ContactModal'
import Button from '../override/Button'
import { mapAssetLiabilityModel } from './assetLiabilityHelpers'
import TourContext, { TOUR_STEP_INDEXES } from '../../contexts/TourContext'
import { onError } from '../../lib/sentry'
import { useMediaQuery } from '@material-ui/core'
import { ThemeContext } from 'styled-components'
import AssetLiabilityForm from './AssetLiabilityForm'
import { useTranslation } from 'react-i18next'
import AuthContext from '../../contexts/AuthContext'
import { fetchOtherPendingAssetsLiabilities } from '../../features/assets-liabilities/otherAssetsLiabilitiesSlice'
import api from '../../lib/api'
import { fetchOtherAssetsLiabilities } from './../../features/assets-liabilities/otherAssetsLiabilitiesSlice'
import PasswordLinks from './PasswordLinks'
import PasswordAddEdit from '../passwords/PasswordAddEdit'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'

const { Content, Sider } = Layout

const AssetLiabilityAddEdit = props => {
  const { assetLiabilityId } = useParams()
  const urlSearchParams = new URLSearchParams(useLocation().search)
  const { form, history } = props
  const { userId, masterKey, isReadonly } = useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { tourRun, nextTourStep, tourStepIndex } = useContext(TourContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { getFieldDecorator, setFieldsValue } = form
  const { activeContacts, pendingContacts } = useSelector(state =>
    (isReadonly && isDelegateByPD) || isProfessionalDeputy
      ? state.otherContacts
      : state.contacts
  )

  const { activeDocuments, pendingDocuments } = useSelector(state =>
    (isReadonly && isDelegateByPD) || isProfessionalDeputy
      ? state.otherDocuments
      : state.documents
  )

  const { accessLevel } = useSelector(state => state.settings)
  const paramsType = urlSearchParams.get('type')

  const [record, setRecord] = useState({})
  // const [propertyLoanRecord, setPropertyLoanRecord] = useState({})
  const [selectedType, setSelectedType] = useState('')
  const [selectedSubType, setSelectedSubType] = useState('')
  const [descriptionValue, setDescriptionValue] = useState('')
  const [descriptionExtraValue, setDescriptionExtraValue] = useState('')
  const [references, setReferences] = useState([])
  const [linkedDocuments, setLinkedDocuments] = useState([])
  const [linkedContacts, setLinkedContacts] = useState([])
  const [linkedEvents, setLinkedEvents] = useState([])
  const [linkedPasswords, setLinkedPasswords] = useState([])
  const [documentsLinksVisible, setDocumentsLinksVisible] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isMinor, setIsMinor] = useState(false)
  const [fileModalVisible, setFileModalVisible] = useState(false)
  const [contactModalVisible, setContactModalVisible] = useState(false)
  const [passwordAddEditVisible, setPasswordAddEditVisible] = useState(false)
  const [mockRecord, setMockRecord] = useState({})
  const [showPropertyLoan, setShowPropertyLoan] = useState(false)
  const [title, setTitle] = useState('')

  const theme = useContext(ThemeContext)
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const db = new PouchDB(`${userId}_assetsLiabilities`)
  const contactDb = new PouchDB(`${userId}_contacts`)
  const pendingContactDb = new PouchDB(`${userId}_pendingContacts`)
  const pendingDb = new PouchDB(`${userId}_pendingAssetsLiabilities`)

  useEffect(() => {
    if (
      tourRun &&
      (tourStepIndex === TOUR_STEP_INDEXES.CREATE_NEW_ASSET_BUTTON ||
        tourStepIndex === TOUR_STEP_INDEXES.EDIT_ASSET_BUTTON)
    ) {
      nextTourStep()
    }
  }, [tourStepIndex, nextTourStep, tourRun])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setIsLoading(true)
        const record = assetLiabilityId
          ? await getRecord(
              userId,
              ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
                accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? 'pendingAssetsLiabilities'
                : 'assetsLiabilities',
              assetLiabilityId,
              masterKey
            )
          : mockRecord.title
          ? mockRecord
          : {}
        const linkedDocuments =
          record.documents && record.documents.length
            ? ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL
              ? [
                  ...(await getRecords(userId, 'documents', masterKey)).filter(
                    c => record.documents?.includes(c._id)
                  ),
                  ...(
                    await getRecords(userId, 'pendingDocuments', masterKey)
                  ).filter(c => record.documents?.includes(c._id))
                ]
              : await getRecords(userId, 'documents', masterKey, {
                  keys: record.documents
                })
            : []
        const linkedEvents = record.events?.length
          ? await getRecords(userId, 'events', masterKey, {
              keys: record.events
            })
          : []
        const linkedPasswords = record.passwords?.length
          ? await getRecords(userId, 'passwords', masterKey, {
              keys: record.passwords
            })
          : []
        const linkedContacts =
          record.contacts && record.contacts.length
            ? ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL
              ? [
                  ...(await getRecords(userId, 'contacts', masterKey)).filter(
                    c => record.contacts?.includes(c._id)
                  ),
                  ...(
                    await getRecords(userId, 'pendingContacts', masterKey)
                  ).filter(c => record.contacts?.includes(c._id))
                ]
              : await getRecords(userId, 'contacts', masterKey, {
                  keys: record.contacts
                })
            : []
        setTitle(record.title)
        setRecord(record)
        setLinkedDocuments(linkedDocuments)
        setLinkedContacts(linkedContacts)
        setLinkedEvents(linkedEvents)
        setLinkedPasswords(linkedPasswords)
        // setIsLoading(false)
      } catch (err) {
        onError(err)
        // setIsLoading(false)
      }
    }

    if (masterKey) {
      fetchData()
    }
  }, [
    userId,
    assetLiabilityId,
    mockRecord,
    masterKey,
    isProfessionalDeputy,
    accessLevel,
    isDelegateByPD,
    isReadonly
  ])

  useEffect(() => {
    if (
      !assetLiabilityId &&
      tourStepIndex === TOUR_STEP_INDEXES.CREATE_ASSET_SELECT_TYPE
    ) {
      const mockRecord = VAULTBOX_SAMPLE_DATA.find(
        record => record.subType === selectedSubType
      )
      if (mockRecord)
        setMockRecord({ ...mockRecord, valuationDate: moment().startOf('day') })
    }
  }, [assetLiabilityId, selectedSubType, tourStepIndex])

  useEffect(() => {
    const { type, subType, descriptionWithMarkup } = record
    setSelectedType(paramsType || type || CORE_TYPES.ASSET)
    setSelectedSubType(
      subType ||
        (!paramsType || paramsType === CORE_TYPES.ASSET
          ? ASSET_TYPES.CASH
          : LIABILITY_TYPES.PROPERTY_LOAN)
    )
    setReferences(record.references || [])
    setDescriptionValue(descriptionWithMarkup || '')
  }, [record, paramsType])

  const addVersionToHistory = async (
    record,
    newRecord,
    putResult,
    isBackdatedAmend,
    isMinor,
    historyDb
  ) => {
    let latestVersion
    if ((isMinor && record._id) || isBackdatedAmend) {
      latestVersion = await getLatestVersion(
        'assetsLiabilitiesHistory',
        userId,
        record._id,
        masterKey
      )
    }
    const versionId =
      (latestVersion && latestVersion._id) || `${putResult.id}_${putResult.rev}`
    delete newRecord._rev

    const versionRecord = {
      ...latestVersion,
      ...newRecord,
      _id: versionId,
      time: new Date()
    }

    await historyDb.put(versionRecord)
  }

  const addVersionToValuation = async (
    newValuationDate,
    values,
    newRecord,
    record,
    putResult,
    valuationDb
  ) => {
    if (record._id) {
      if ((isReadonly && isDelegateByPD) || isProfessionalDeputy) {
        const oldestValuation = await getOldestValuation(
          userId,
          record._id,
          masterKey
        )

        const valuationRecord = new AssetLiabilityValuation({
          ...oldestValuation,
          ...newRecord,
          ...values,
          _id: oldestValuation._id,
          _rev: oldestValuation._rev,
          validFrom: newValuationDate.toJSON()
        })
        await valuationDb.put(valuationRecord)
      } else {
        const latestValuation = await getLatestValuation(
          userId,
          record._id,
          masterKey,
          newValuationDate
        )

        if (!latestValuation) {
          const oldestValuation = await getOldestValuation(
            userId,
            record._id,
            masterKey
          )
          const newValuationRecord = new AssetLiabilityValuation({
            ...newRecord,
            ...values,
            _id: `${putResult.id}_${uuidv4()}`,
            _rev: undefined,
            validFrom: newValuationDate.toJSON(),
            validTo: moment(oldestValuation.validFrom)
              .subtract(1, 'ms')
              .toJSON()
          })
          await valuationDb.put(newValuationRecord)
        } else if (moment(latestValuation.validFrom).isSame(newValuationDate)) {
          const valuationRecord = new AssetLiabilityValuation({
            ...latestValuation,
            ...newRecord,
            ...values,
            _id: latestValuation._id,
            _rev: latestValuation._rev
          })
          await valuationDb.put(valuationRecord)
        } else {
          const newValuationRecord = new AssetLiabilityValuation({
            ...latestValuation,
            ...newRecord,
            ...values,
            _id: `${putResult.id}_${uuidv4()}`,
            _rev: undefined,
            validFrom: newValuationDate.toJSON()
          })
          const updatedValuationRecord = new AssetLiabilityValuation({
            ...latestValuation,
            validTo: newValuationDate.subtract(1, 'ms').toJSON()
          })
          await valuationDb.bulkDocs([
            newValuationRecord,
            updatedValuationRecord
          ])
        }
      }
    } else {
      const valuationRecord = new AssetLiabilityValuation({
        ...newRecord,
        _id: `${putResult.id}_${uuidv4()}`,
        validFrom: (
          newRecord.valuationDate || moment().startOf('day')
        ).toJSON(),
        validTo: END_OF_TIME
      })
      await valuationDb.put(valuationRecord)
    }
  }

  const handleOk = e => {
    form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return
      }

      removeHtmlTags(values)

      const newValuationDate = (values.valuationDate || moment()).startOf('day')
      const isBackdatedAmend =
        record._id &&
        record.valuationDate &&
        newValuationDate.isBefore(record.valuationDate) &&
        !((isReadonly && isDelegateByPD) || isProfessionalDeputy)

      if (isBackdatedAmend) {
        Modal.confirm({
          title: t('BACK_DATED_AMEND_CONFIRMATION'),
          content: (
            <>
              <div>
                The position and valuation changes will be recorded as of the
                selected {valuationDateLabel(selectedSubType)} -{' '}
                {newValuationDate.format(DATE_FORMAT)}.
              </div>
              <div>Are you sure you want to continue?</div>
            </>
          ),
          onOk: async () => await handleSave(values, newValuationDate, true)
        })
      } else {
        await handleSave(values, newValuationDate)
      }
    })
  }

  const prepareData = (
    values,
    isBackdatedAmend,
    newValuationDate,
    record,
    selectedType,
    selectedSubType,
    isCallLiab = false
  ) => {
    const backdatedAmendFields = isBackdatedAmend
      ? {
          percentageOwnership: record.percentageOwnership,
          quantity: record.quantity,
          valuationInAssetCurrency: record.valuationInAssetCurrency,
          valuationInBaseCurrency: record.valuationInBaseCurrency,
          outstandingValueInLiabilityCurrency:
            record.outstandingValueInLiabilityCurrency,
          outstandingValueInBaseCurrency: record.outstandingValueInBaseCurrency,
          valuationDate: moment(record.valuationDate)
        }
      : { valuationDate: newValuationDate }

    const id = record._id || uuidv4()
    return mapAssetLiabilityModel(
      {
        ...record,
        ...values,
        _id: id,
        descriptionWithMarkup: sanitizeValue(
          isCallLiab ? descriptionExtraValue : descriptionValue
        ),
        references: filterEmptyEls(values.references),
        documents: linkedDocuments.map(doc => doc._id),
        contacts: linkedContacts.map(doc => doc._id),
        events: linkedEvents.map(doc => doc._id),
        passwords: linkedPasswords.map(doc => doc._id),
        ...backdatedAmendFields,
        status:
          ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? 'Draft'
            : undefined,
        reasonReject: undefined
      },
      selectedType,
      selectedSubType
    )
  }

  const [addS3Change] = useMutation(createS3Change, {
    variables: {
      message:
        'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events, assetsLiabilitiesHistory, assetsLiabilitiesValuations',
      userId: userId
    }
  })

  const handleSave = async (
    values,
    newValuationDate,
    isBackdatedAmend = false
  ) => {
    setIsSaving(true)
    const record = assetLiabilityId
      ? await getRecord(
          userId,
          ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? 'pendingAssetsLiabilities'
            : 'assetsLiabilities',
          assetLiabilityId,
          masterKey
        )
      : mockRecord.title
      ? mockRecord
      : {}
    const newRecord = prepareData(
      values,
      isBackdatedAmend,
      newValuationDate,
      record,
      selectedType,
      selectedSubType
    )

    let newPropertyLoanRecord
    if (showPropertyLoan) {
      newPropertyLoanRecord = prepareData(
        {
          ...values,
          title: values.titleExtra,
          description: values.descriptionExtra,
          address: values.addressExtra,
          currency: values.currencyExtra,
          includeValueInNetWorth: values.includeValueInNetWorthExtra,
          percentageOwnership: values.percentageOwnershipExtra,
          valuationDate: values.valuationDateExtra,
          links: [newRecord._id]
        },
        false,
        newValuationDate,
        {},
        CORE_TYPES.LIABILITY,
        LIABILITY_TYPES.PROPERTY_LOAN,
        true
      )
      newRecord.propertyLoanId = newPropertyLoanRecord._id
      newRecord.links = [...(newRecord.links || []), newPropertyLoanRecord._id]
    }

    try {
      ;((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
      accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        ? pendingDb.crypto(masterKey)
        : db.crypto(masterKey)

      const records = newPropertyLoanRecord?._id
        ? [newRecord, newPropertyLoanRecord]
        : [newRecord]
      const putResults =
        ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
        accessLevel === ACCESS_LEVEL.NEED_APPROVAL
          ? await pendingDb.bulkDocs(records)
          : await db.bulkDocs(records)

      // add a version record to history db
      const historyDb = new PouchDB(`${userId}_assetsLiabilitiesHistory`)
      historyDb.crypto(masterKey)
      if (newPropertyLoanRecord?._id) {
        await Promise.all([
          addVersionToHistory(
            record,
            newRecord,
            putResults[0],
            isBackdatedAmend,
            isMinor || (isReadonly && isDelegateByPD) || isProfessionalDeputy,
            historyDb
          ),
          addVersionToHistory(
            {},
            newPropertyLoanRecord,
            putResults[1],
            false,
            false,
            historyDb
          )
        ])
      } else {
        await addVersionToHistory(
          record,
          newRecord,
          putResults[0],
          isBackdatedAmend,
          isMinor || isProfessionalDeputy || (isReadonly && isDelegateByPD),
          historyDb
        )
      }

      const valuationDb = new PouchDB(`${userId}_assetsLiabilitiesValuations`)
      valuationDb.crypto(masterKey)
      if (
        (!isProfessionalDeputy && !(isDelegateByPD && isReadonly)) ||
        accessLevel !== ACCESS_LEVEL.NEED_APPROVAL
      ) {
        if (newPropertyLoanRecord?._id) {
          await Promise.all([
            addVersionToValuation(
              newValuationDate,
              values,
              newRecord,
              record,
              putResults[0],
              valuationDb
            ),
            addVersionToValuation(
              newValuationDate,
              {
                ...values,
                address: values.addressExtra,
                percentageOwnership: values.percentageOwnershipExtra,
                currency: values.currencyExtra,
                valuationDate: values.valuationDateExtra,
                includeValueInNetWorth: values.includeValueInNetWorthExtra
              },
              newPropertyLoanRecord,
              {},
              putResults[1],
              valuationDb
            )
          ])
        } else {
          await addVersionToValuation(
            newValuationDate,
            values,
            newRecord,
            record,
            putResults[0],
            valuationDb
          )
        }
      }

      await Promise.all([
        ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
        accessLevel === ACCESS_LEVEL.NEED_APPROVAL
          ? uploadEncryptedData(pendingDb, userId, 'pendingAssetsLiabilities')
          : uploadEncryptedData(db, userId, 'assetsLiabilities'),
        uploadEncryptedData(historyDb, userId, 'assetsLiabilitiesHistory'),
        (!(isDelegateByPD && isReadonly) && !isProfessionalDeputy) ||
        accessLevel !== ACCESS_LEVEL.NEED_APPROVAL
          ? uploadEncryptedData(
              valuationDb,
              userId,
              'assetsLiabilitiesValuations'
            )
          : ''
      ])

      // update contacts
      let initialLinkedContacts
      let newLinkedContacts

      initialLinkedContacts = uniq([
        ...(record.contacts || []),
        ...(record.tenant || []),
        ...(record.insuranceAdvisor || []),
        ...(record.beneficiaries || []),
        ...(record.borrower ? [record.borrower] : []),
        ...(record.nameAssured ? [record.nameAssured] : []),
        ...(record.company ? [record.company] : []),
        ...(record.lender ? [record.lender] : []),
        ...(record.typeOfTrustInterest?.values || [])
      ])

      newLinkedContacts = uniq([
        ...linkedContacts.map(c => c._id),
        ...(values.tenant || []),
        ...(values.insuranceAdvisor || []),
        ...(values.beneficiaries || []),
        ...(values.borrower ? [values.borrower] : []),
        ...(values.nameAssured ? [values.nameAssured] : []),
        ...(values.company ? [values.company] : []),
        ...(values.lender ? [values.lender] : []),
        ...(values.typeOfTrustInterest?.values || [])
      ])

      const addedContacts = newLinkedContacts.filter(
        contactId => !initialLinkedContacts.includes(contactId)
      )

      const removedContacts = initialLinkedContacts.filter(
        contactId => !newLinkedContacts.includes(contactId)
      )

      const updatedContacts = [...addedContacts, ...removedContacts]

      const updatedActivedContacts = updatedContacts.filter(cid =>
        activeContacts.map(ac => ac._id).includes(cid)
      )

      const updatedPendingContacts = updatedContacts.filter(cid =>
        pendingContacts.map(pc => pc._id).includes(cid)
      )

      if (updatedActivedContacts?.length) {
        await updateContactsLinks(
          updatedActivedContacts,
          values,
          newPropertyLoanRecord,
          newRecord,
          addedContacts,
          removedContacts,
          contactDb,
          'contacts'
        )
      }

      if (updatedPendingContacts?.length) {
        await updateContactsLinks(
          updatedPendingContacts,
          values,
          newPropertyLoanRecord,
          newRecord,
          addedContacts,
          removedContacts,
          pendingContactDb,
          'pendingContacts'
        )
      }

      //update events links
      const addedEvents = linkedEvents
        .map(e => e._id)
        .filter(eventId => !record.events?.includes(eventId))

      const removedEvents =
        record.events?.filter(
          contactId => !linkedEvents.map(e => e._id).includes(contactId)
        ) || []

      const updatedEvents = [...addedEvents, ...removedEvents]
      if (updatedEvents?.length) {
        const eventsDb = new PouchDB(`${userId}_events`)
        await updateEventsLinks(
          updatedEvents,
          newRecord,
          addedEvents,
          removedEvents,
          eventsDb,
          'events'
        )
      }

      //update passwords
      const addedPasswords = linkedPasswords
        .map(password => password._id)
        .filter(passwordId => !record.passwords?.includes(passwordId))

      const removedPasswords =
        record.passwords?.filter(
          passwordId =>
            !linkedPasswords.map(password => password._id).includes(passwordId)
        ) || []

      const updatedPasswords = [...addedPasswords, ...removedPasswords]
      if (updatedPasswords?.length) {
        const passwordsDb = new PouchDB(`${userId}_passwords`)
        await updatePasswordsLinks(
          updatedPasswords,
          newRecord,
          addedPasswords,
          removedPasswords,
          passwordsDb,
          'passwords'
        )
      }

      //update documents
      const addedDocuments =
        linkedDocuments
          .map(d => d._id)
          .filter(dId => !record.documents?.includes(dId)) || []

      const removedDocuments =
        record.documents?.filter(
          id => !linkedDocuments.map(d => d._id).includes(id)
        ) || []

      const updatedDocuments = [...addedDocuments, ...removedDocuments]

      const updatedActiveDocuments = updatedDocuments.filter(cid =>
        activeDocuments.map(ac => ac._id).includes(cid)
      )

      const updatedPendingDocuments = updatedDocuments.filter(cid =>
        pendingDocuments.map(pc => pc._id).includes(cid)
      )

      if (updatedActiveDocuments?.length) {
        await linkDocumentsFromAssetsLiabilities(
          updatedActiveDocuments,
          newRecord,
          addedDocuments,
          removedDocuments,
          'documents',
          userId,
          masterKey
        )
      }

      if (updatedPendingDocuments?.length) {
        await linkDocumentsFromAssetsLiabilities(
          updatedPendingDocuments,
          newRecord,
          addedDocuments,
          removedDocuments,
          'pendingDocuments',
          userId,
          masterKey
        )
      }

      if ((isReadonly && isDelegateByPD) || isProfessionalDeputy) {
        if (accessLevel === ACCESS_LEVEL.NEED_APPROVAL) {
          await api.sendAddRecordNotification(
            JSON.stringify({
              userId,
              recordType: 'asset / liability'
            })
          )

          dispatch(fetchOtherPendingAssetsLiabilities(userId, masterKey))
        } else {
          dispatch(fetchOtherAssetsLiabilities(userId, masterKey))
        }
      }
      setIsSaving(false)
      message.success(
        record._id
          ? t('UPDATE_ASSET_LIABILITY_SUCCESS')
          : t('ADD_ASSET_LIABILITY_SUCCESS')
      )
      localStorage.setItem('NotReload', true)
      addS3Change()
      reset()

      if (
        tourStepIndex === TOUR_STEP_INDEXES.SAVE_ASSET_BUTTON ||
        tourStepIndex === TOUR_STEP_INDEXES.SAVE_LINKED_ASSET
      ) {
        nextTourStep()
      }
    } catch (err) {
      setIsSaving(false)
      onError(err)
    }
  }

  const updateContactsLinks = async (
    updatedContacts,
    values,
    newPropertyLoanRecord,
    newRecord,
    addedContacts,
    removedContacts,
    db,
    dbName
  ) => {
    db.crypto(masterKey)
    const docs = await db.allDocs({
      keys: updatedContacts,
      include_docs: true
    })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row

        if (addedContacts.includes(doc._id)) {
          let ids = []
          if (showPropertyLoan && newPropertyLoanRecord._id) {
            if (values.lender === doc._id) {
              ids = [...ids, newPropertyLoanRecord._id]
            }
            if (values.tenant?.includes(doc._id)) {
              ids = [...ids, newRecord._id]
            }
            if (
              values.lender !== doc._id &&
              !values.tenant?.includes(doc._id)
            ) {
              ids = [newRecord._id, newPropertyLoanRecord._id]
            }
          } else ids = [newRecord._id]

          const newAssetsLiabilities = uniq([
            ...(doc.assetsLiabilities || []),
            ...ids
          ])

          return { ...doc, assetsLiabilities: newAssetsLiabilities }
        } else if (removedContacts.includes(doc._id)) {
          const newAssetsLiabilities = doc.assetsLiabilities
            ? doc.assetsLiabilities.filter(alId => alId !== newRecord._id)
            : []
          return { ...doc, assetsLiabilities: newAssetsLiabilities }
        } else {
          return { ...doc }
        }
      })

    // Only property link with lender
    if (values.lender && showPropertyLoan) {
      const doc = await db.get(values.lender)
      await db.put({
        ...doc,
        assetsLiabilities: [
          ...(doc.assetsLiabilities || []),
          newPropertyLoanRecord._id
        ]
      })
    }

    await db.bulkDocs(updatedDocs)
    await uploadEncryptedData(db, userId, dbName)
  }

  const updateEventsLinks = async (
    updatedEvents,
    newRecord,
    addedEvents,
    removedEvents,
    db,
    dbName
  ) => {
    db.crypto(masterKey)
    const docs = await db.allDocs({
      keys: updatedEvents,
      include_docs: true
    })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row

        if (addedEvents.includes(doc._id)) {
          const newAssetsLiabilities = uniq([
            ...(doc.assetsLiabilities || []),
            newRecord._id
          ])

          return { ...doc, assetsLiabilities: newAssetsLiabilities }
        } else if (removedEvents.includes(doc._id)) {
          const newAssetsLiabilities = doc.assetsLiabilities
            ? doc.assetsLiabilities.filter(alId => alId !== newRecord._id)
            : []
          return { ...doc, assetsLiabilities: newAssetsLiabilities }
        } else {
          return { ...doc }
        }
      })

    await db.bulkDocs(updatedDocs)
    await uploadEncryptedData(db, userId, dbName)
  }

  const updatePasswordsLinks = async (
    updatedPasswords,
    newRecord,
    addedPasswords,
    removedPasswords,
    db,
    dbName
  ) => {
    db.crypto(masterKey)
    const docs = await db.allDocs({
      keys: updatedPasswords,
      include_docs: true
    })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row

        if (addedPasswords.includes(doc._id)) {
          const newAssetsLiabilities = uniq([
            ...(doc.assetsLiabilities || []),
            newRecord._id
          ])

          return { ...doc, assetsLiabilities: newAssetsLiabilities }
        } else if (removedPasswords.includes(doc._id)) {
          const newAssetsLiabilities = doc.assetsLiabilities
            ? doc.assetsLiabilities.filter(alId => alId !== newRecord._id)
            : []
          return { ...doc, assetsLiabilities: newAssetsLiabilities }
        } else {
          return { ...doc }
        }
      })

    await db.bulkDocs(updatedDocs)
    await uploadEncryptedData(db, userId, dbName)
  }

  const reset = () => {
    form.resetFields()
    setSelectedType(record.type || CORE_TYPES.ASSET)
    setSelectedSubType(record.subType)
    setDescriptionValue(record.descriptionWithMarkup || '')
    isProfessionalDeputy || (isDelegateByPD && isReadonly)
      ? history.goBack()
      : history.push(
          `/?tab=${
            selectedType === CORE_TYPES.ASSET ? t('ASSETS') : t('LIABILITIES')
          }`
        )
  }

  const handleContactMention = (id, display) => {
    if (!linkedContacts.find(lc => lc._id === id)) {
      handleLinkContacts([id])
    }
  }

  const handleTypeChange = e => {
    setSelectedType(e.target.value)
    setSelectedSubType(
      e.target.value === CORE_TYPES.ASSET
        ? ASSET_TYPES.CASH
        : LIABILITY_TYPES.PROPERTY_LOAN
    )
    form.setFieldsValue({
      subType: undefined
    })
  }

  const unlinkDocument = itemId => {
    setLinkedDocuments(linkedDocuments.filter(doc => doc._id !== itemId))
  }

  const linkDocument = async documentId => {
    try {
      const documentIds = linkedDocuments.map(doc => doc._id).concat(documentId)
      const newLinkedDocuments =
        documentIds && documentIds.length
          ? ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? [
                ...(await getRecords(userId, 'documents', masterKey)).filter(
                  c => documentIds?.includes(c._id)
                ),
                ...(
                  await getRecords(userId, 'pendingDocuments', masterKey)
                ).filter(c => documentIds?.includes(c._id))
              ]
            : await getRecords(userId, 'documents', masterKey, {
                keys: documentIds
              })
          : []
      setLinkedDocuments(newLinkedDocuments)
    } catch (err) {
      onError(err)
    }
  }

  const linkDocuments = async documentIds => {
    try {
      const newDocumentIds = linkedDocuments
        .map(doc => doc._id)
        .concat(documentIds)
      const newLinkedDocuments =
        newDocumentIds && newDocumentIds.length
          ? // ? await getRecords(userId, 'documents', masterKey, {
            //     keys: newDocumentIds
            //   })
            // : []
            ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? [
                ...(await getRecords(userId, 'documents', masterKey)).filter(
                  c => newDocumentIds?.includes(c._id)
                ),
                ...(
                  await getRecords(userId, 'pendingDocuments', masterKey)
                ).filter(c => newDocumentIds?.includes(c._id))
              ]
            : await getRecords(userId, 'documents', masterKey, {
                keys: newDocumentIds
              })
          : []
      setLinkedDocuments(newLinkedDocuments)
    } catch (err) {
      onError(err)
    }
  }

  const handleLinkContacts = async contactIds => {
    try {
      const allContactIds = linkedContacts
        .map(doc => doc._id)
        .concat(contactIds)

      const newLinkedContacts =
        allContactIds && allContactIds.length
          ? ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? [
                ...(
                  await await getRecords(userId, 'contacts', masterKey)
                ).filter(c => allContactIds.includes(c._id)),
                ...(
                  await getRecords(userId, 'pendingContacts', masterKey)
                ).filter(c => allContactIds.includes(c._id))
              ]
            : await getRecords(userId, 'contacts', masterKey, {
                keys: allContactIds
              })
          : []

      setLinkedContacts(newLinkedContacts)
    } catch (err) {
      onError(err)
    }
  }

  const handleUnlinkContact = contactId => {
    setLinkedContacts(linkedContacts.filter(doc => doc._id !== contactId))

    const deselectedContact = activeContacts.find(
      record => record._id === contactId
    )
    if (deselectedContact) {
      const markUp = new RegExp(`@\\[(((?!@\\[).)*)\\]\\(${contactId}\\)`, 'g')
      const newDescriptionValue = descriptionValue.replace(markUp, '$1')
      setDescriptionValue(newDescriptionValue)
    }
  }

  const handleLinkEvents = async eventIds => {
    try {
      const allEventIds = linkedEvents.map(doc => doc._id).concat(eventIds)

      const newLinkedEvents = allEventIds?.length
        ? await getRecords(userId, 'events', masterKey, {
            keys: allEventIds
          })
        : []

      setLinkedEvents(newLinkedEvents)
    } catch (error) {
      onError(error)
    }
  }

  const handleUnlinkEvents = eventId => {
    setLinkedEvents(linkedEvents.filter(doc => doc._id !== eventId))
  }

  const handleLinkPasswords = async passwordIds => {
    try {
      const allPasswordIds = linkedPasswords
        .map(doc => doc._id)
        .concat(passwordIds)

      const newLinkedPasswords = allPasswordIds?.length
        ? await getRecords(userId, 'passwords', masterKey, {
            keys: allPasswordIds
          })
        : []

      setLinkedPasswords(newLinkedPasswords)
    } catch (error) {
      onError(error)
    }
  }

  const handleUnlinkPassword = passwordId => {
    setLinkedPasswords(linkedPasswords.filter(doc => doc._id !== passwordId))
  }

  return (
    <Layout>
      <Content style={{ backgroundColor: '#fff', padding: '0 20px 20px' }}>
        <SimpleHeader
          title={
            <Breadcrumb separator=">">
              <Breadcrumb.Item
                href=""
                onClick={e => {
                  e.preventDefault()
                  history.push('/')
                }}
              >
                <H4 display="inline-block" style={{ color: '#9AA0B3' }}>
                  {t('REGISTRY')}
                </H4>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <H4 display="inline-block">
                  {record._id && record.title
                    ? `${t('EDIT')} ${record.title}`
                    : t('CREATE_NEW')}
                </H4>
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          extra={
            <Radio.Group
              onChange={handleTypeChange}
              value={selectedType || CORE_TYPES.ASSET}
              disabled={!!record._id}
            >
              {Object.values(CORE_TYPES).map(type => (
                <Radio key={type} value={type}>
                  {checkRegistryType(type)}
                </Radio>
              ))}
            </Radio.Group>
          }
        />
        <Form>
          <Row
            gutter={{ xl: 20, xxl: 40 }}
            className="tour-create-asset-select-type"
          >
            <Col xl={4} xxl={6} style={{ marginTop: 8 }}>
              <H4>{t('TYPE')}</H4>
            </Col>
            <Col xl={20} xxl={18}>
              <AssetLiabilitySubtypes
                selectedType={selectedType}
                selectedSubType={selectedSubType}
                setSelectedSubType={v => {
                  //setShowPropertyLoan(v === ASSET_TYPES.PROPERTY && !record._id) // not allow edit
                  setSelectedSubType(v)
                }}
              />
            </Col>
          </Row>
          <Row gutter={{ xl: 20, xxl: 40 }} className="tour-create-asset-form">
            <Col xl={4} xxl={6} style={{ marginTop: 8 }}>
              <H4>{t('INFORMATION')}</H4>
            </Col>
            <Col xl={20} xxl={18}>
              <FormItem label={t('TITLE')}>
                {getFieldDecorator('title', {
                  initialValue: record.title,
                  rules: [{ required: true, message: t('INPUT_TITLE_MSG') }]
                })(
                  <TextInput
                    onChange={e => setTitle(e.target.value)}
                    name="title"
                    setFieldsValue={setFieldsValue}
                  />
                )}
              </FormItem>
              <AssetLiabilityForm
                title={title}
                record={record}
                selectedType={selectedType}
                selectedSubType={selectedSubType}
                form={form}
                references={references}
                descriptionValue={descriptionValue}
                setDescriptionValue={setDescriptionValue}
                descriptionExtraValue={descriptionExtraValue}
                setDescriptionExtraValue={setDescriptionExtraValue}
                handleContactMention={handleContactMention}
                showPropertyLoan={showPropertyLoan}
                setShowPropertyLoan={setShowPropertyLoan}
                linkedEvents={linkedEvents}
                setLinkedEvents={setLinkedEvents}
              />
              {!isReadonly ? (
                <Row gutter={10}>
                  <Col sm={8}>
                    <Button
                      size="large"
                      block
                      icon="upload"
                      onClick={() => setFileModalVisible(true)}
                      style={{ marginTop: 20 }}
                    >
                      {t('UPLOAD_DOCUMENT')}
                    </Button>
                  </Col>
                  <Col sm={8}>
                    <Button
                      size="large"
                      block
                      icon="contacts"
                      onClick={() => setContactModalVisible(true)}
                      style={{ marginTop: 20 }}
                    >
                      {t('ADD_CONTACT')}
                    </Button>
                  </Col>
                  <Col sm={8}>
                    <Button
                      size="large"
                      block
                      icon="lock"
                      onClick={() => setPasswordAddEditVisible(true)}
                      style={{ marginTop: 20 }}
                    >
                      {t('ADD_NEW_PASSWORD')}
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row gutter={20}>
                  <Col sm={12}>
                    <Button
                      size="large"
                      block
                      icon="upload"
                      onClick={() => setFileModalVisible(true)}
                      style={{ marginTop: 20 }}
                    >
                      {t('UPLOAD_DOCUMENT')}
                    </Button>
                  </Col>
                  <Col sm={12}>
                    <Button
                      size="large"
                      block
                      icon="contacts"
                      onClick={() => setContactModalVisible(true)}
                      style={{ marginTop: 20 }}
                    >
                      {t('ADD_CONTACT')}
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
        {!isLgUp && (
          <SiderActionButtons
            isScrolled={false}
            style={{
              padding: '40px 0 80px'
            }}
            handleOk={handleOk}
            handleCancel={reset}
            isSaving={isSaving}
            isEdit={!!record._id}
            isMinor={isMinor}
            setIsMinor={setIsMinor}
            preExtra={
              <>
                <DocumentLinks
                  linkedDocuments={linkedDocuments}
                  handleUnlinkDocument={unlinkDocument}
                  handleLinkDocument={linkDocument}
                  isReadonly={false}
                  record={record}
                />
                <Divider />
                <ContactLinks
                  linkedContacts={linkedContacts}
                  handleUnlinkContact={handleUnlinkContact}
                  handleLinkContacts={handleLinkContacts}
                  isReadonly={false}
                />
                <Divider />
                {!isReadonly && (
                  <>
                    <PasswordLinks
                      linkedPasswords={linkedPasswords}
                      handleUnlinkPassword={handleUnlinkPassword}
                      handleLinkPasswords={handleLinkPasswords}
                    />
                    <Divider />
                  </>
                )}

                {!isReadonly && (
                  <>
                    <EventLinks
                      linkedEvents={linkedEvents}
                      handleUnlinkEvents={handleUnlinkEvents}
                      handleLinkEvents={handleLinkEvents}
                      isReadonly={false}
                      setLinkedEvents={setLinkedEvents}
                    />
                    <Divider />
                  </>
                )}
              </>
            }
          />
        )}
      </Content>

      {isLgUp && (
        <Sider
          width={300}
          theme="light"
          style={{
            background: 'rgba(242, 243, 247, 0.5)',
            padding: '20px 20px 80px'
          }}
        >
          <SiderActionButtons
            handleOk={handleOk}
            handleCancel={reset}
            isSaving={isSaving}
            width={260}
            isEdit={!!record._id}
            isMinor={isMinor}
            setIsMinor={setIsMinor}
            preExtra={
              <>
                <DocumentLinks
                  linkedDocuments={linkedDocuments}
                  handleUnlinkDocument={unlinkDocument}
                  handleLinkDocument={linkDocument}
                  isReadonly={false}
                  record={record}
                />
                <Divider />
                <ContactLinks
                  linkedContacts={linkedContacts}
                  handleUnlinkContact={handleUnlinkContact}
                  handleLinkContacts={handleLinkContacts}
                  isReadonly={false}
                />
                <Divider />
                {!isReadonly && (
                  <>
                    <PasswordLinks
                      linkedPasswords={linkedPasswords}
                      handleUnlinkPassword={handleUnlinkPassword}
                      handleLinkPasswords={handleLinkPasswords}
                    />
                    <Divider />
                  </>
                )}

                {!isReadonly && (
                  <>
                    <EventLinks
                      linkedEvents={linkedEvents}
                      handleUnlinkEvents={handleUnlinkEvents}
                      handleLinkEvents={handleLinkEvents}
                      isReadonly={false}
                      setLinkedEvents={setLinkedEvents}
                    />
                    <Divider />
                  </>
                )}
              </>
            }
          />
        </Sider>
      )}
      <DocumentsLinksModal
        visible={documentsLinksVisible}
        setVisible={setDocumentsLinksVisible}
        record={record}
        linkDocument={linkDocument}
        filteredDocIds={linkedDocuments.map(doc => doc._id)}
      />
      <FileModal
        visible={fileModalVisible}
        setVisible={setFileModalVisible}
        contactDataSource={activeContacts}
        onUploadComplete={linkDocuments}
      />
      <ContactModal
        visible={contactModalVisible}
        setVisible={setContactModalVisible}
        selectedRecord={{}}
        onAddComplete={contactId => handleLinkContacts([contactId])}
      />
      <PasswordAddEdit
        visible={passwordAddEditVisible}
        setVisible={setPasswordAddEditVisible}
        onAddComplete={passwordId => handleLinkPasswords([passwordId])}
      />
    </Layout>
  )
}

const WrappedAssetLiabilityForm = Form.create({
  name: 'AssetLiabilityAddEdit'
})(AssetLiabilityAddEdit)

export default withRouter(WrappedAssetLiabilityForm)
