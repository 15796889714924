import Liability, { liabilityColumns } from './LiabilityModel'
import { referenceColumns } from './AssetLiabilityModel'
import { LIABILITY_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class OtherLiability extends Liability {
  /**
   * Construct a new OtherLiability instance
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    startDate,
    maturityDate,
    currency,
    valuationDate,
    outstandingValueInLiabilityCurrency,
    outstandingValueInBaseCurrency,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    status,
    events,
    passwords
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      startDate,
      maturityDate,
      currency,
      valuationDate,
      outstandingValueInLiabilityCurrency,
      outstandingValueInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords
    })

    this.subType = LIABILITY_TYPES.OTHER_LIABILITY
  }
}

export default OtherLiability

export const otherLiabilityColumns = () => [
  ...liabilityColumns(),
  {
    key: 'startDate',
    title: i18next.t('LIABILITY_START_DATE'),
    type: IMPORT_COL_TYPES.DATE,
    required: true
  },
  {
    key: 'maturityDate',
    title: i18next.t('LIABILITY_MATURITY_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  ...referenceColumns()
]
