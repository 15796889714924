import React, { useEffect, useContext, useState } from 'react'
import {
  Tabs,
  Spin,
  Icon,
  PageHeader,
  Input,
  Row,
  Col,
  Modal,
  message,
  Divider,
  Alert,
  Menu,
  Dropdown,
  Avatar
} from 'antd'
import {
  getRecord,
  deleteContact,
  uploadEncryptedData,
  getRecords,
  permanentlyDeleteItems,
  unlinkEvent,
  unlinkItemFromLinkedList,
  unlinkContactsFromAnother
} from '../../lib/pouchDb'
import { withRouter } from 'react-router-dom'
import VaultContext from '../../contexts/VaultContext'
import { H4, H5, Span, B, Span1 } from '../override/Typography'
import { CONTACT_TYPES, TAB_KEY } from '../../share/Constants'
import { ThemeContext } from 'styled-components'
import {
  formatBytes,
  renderDate,
  search,
  queryLinkedRecords,
  checkPermission
} from '../../share/helpers'
import { DATE_FORMAT } from '../../share/Constants'
import EventDetails from '../calendar/EventDetails'
import moment from 'moment'
import { StringResources } from '../../share/StringResources'
import Button from '../override/Button'
import SimpleHeader from '../override/SimpleHeader'
import CustomIcon from '../override/Icon'
import { useSelector, useDispatch } from 'react-redux'
import FileDetails from '../file/FileDetails'
import { getLegacyInfo } from '../../features/settings/settingsSlice'
import { onError } from '../../lib/sentry'
import { useTranslation, Trans } from 'react-i18next'
import PouchDB from 'pouchdb'
import api from '../../lib/api'
import { fetchPendingContacts } from '../../features/contacts/contactsSlice'
import RejectModal from '../modals/RejectModal'
import AuthContext from '../../contexts/AuthContext'
import { fetchOtherPendingContacts } from './../../features/contacts/otherContactsSlice'
import { fetchPendingAssetsLiabilities } from '../../features/assets-liabilities/assetsLiabilitiesSlice'
import { ACCESS_LEVEL } from './../../share/Constants'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import CustomTable from '../override/CustomTable'
import ShareDataModal from '../modals/SharingDataModal'

const { TabPane } = Tabs

function Field({ title, value, icon }) {
  const theme = useContext(ThemeContext)

  return (
    <div style={{ padding: '20px 0', boxShadow: theme.underlineShadow }}>
      <Row gutter={10}>
        <Col sm={8} style={{ color: theme.dark2 }}>
          {icon}
          <B color={theme.dark2} style={{ marginLeft: 10 }}>
            {title}
          </B>
        </Col>
        <Col sm={16}>
          <Span>{value}</Span>
        </Col>
      </Row>
    </div>
  )
}

function ContactDetails({
  contactId,
  contactIds,
  setContactId,
  history,
  // isPendingRecords,
  activeKey
}) {
  const theme = useContext(ThemeContext)
  const { userId, isReadonly, masterKey, fullName, permissions } =
    useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { t } = useTranslation()

  const [isDetailEventModal, setIsDetailEventModal] = useState(false)
  const [eventDetails, setEventDetails] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [record, setRecord] = useState({})
  const [linkedEvents, setLinkedEvents] = useState([])
  const [linkedDocuments, setLinkedDocuments] = useState([])
  const [linkedContacts, setLinkedContacts] = useState([])
  const [filteredLinkedDocuments, setFilteredLinkedDocuments] = useState([])
  const [fileDetailsVisible, setFileDetailsVisible] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState({})
  const [rejectModalVisible, setRejectModalVisible] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  const [rejectRecord, setRejectRecord] = useState({})
  const [loading, SetLoading] = useState(false)
  const [shareDataModalVisible, setShareDataModalVisible] = useState(false)

  const { activeDocuments, pendingDocuments } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
    state =>
      isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { activeContacts, pendingContacts } = useSelector(state =>
    isProfessionalDeputy ? state.otherContacts : state.contacts
  )
  const { accessLevel } = useSelector(state => state.settings)

  const lstPendingDocuments = pendingDocuments.filter(pd =>
    record.documents?.includes(pd._id)
  )

  const dispatch = useDispatch()
  let rejectFormRef

  const [addS3Change] = useMutation(createS3Change)

  // TODO: check again why quick add an asset with link to contact doesn't show up
  useEffect(() => {
    const documents = [...activeDocuments, ...pendingDocuments]
    const fetchData = async () => {
      try {
        setIsLoading(true)
        if (contactId) {
          const record = await getRecord(
            userId,
            // activeKey !== TAB_KEY.ACTIVED ? 'pendingContacts' : 'contacts',
            'contacts',

            contactId,
            masterKey
          )

          const linkedDocuments = record.documents
            ? record.documents
                .map(docId => documents.find(record => record._id === docId))
                .filter(doc => !!doc)
            : []
          const linkedEvents = record.events?.length
            ? await getRecords(userId, 'events', masterKey, {
                keys: record.events
              })
            : []

          const linkedContacts =
            record.links && record.links.length
              ? [
                  ...(await getRecords(userId, 'contacts', masterKey)).filter(
                    c => record.links?.includes(c._id)
                  ),
                  ...(
                    await getRecords(userId, 'pendingContacts', masterKey)
                  ).filter(c => record.links?.includes(c._id))
                ]
              : []

          setRecord(record)
          setLinkedContacts(linkedContacts)
          setLinkedDocuments(linkedDocuments)
          setLinkedEvents(linkedEvents)
          setFilteredLinkedDocuments(linkedDocuments)
        } else if (contactIds && contactIds.length) {
          const linkedDocuments = queryLinkedRecords(
            documents,
            'contacts',
            contactIds,
            'every'
          )

          setRecord({})

          setLinkedDocuments(linkedDocuments)
          setFilteredLinkedDocuments(linkedDocuments)
        }

        setIsLoading(false)
      } catch (err) {
        onError(err)
        setIsLoading(false)
      }
    }

    if (masterKey) {
      fetchData()
    }
  }, [
    userId,
    contactId,
    contactIds,
    activeAssetsLiabilities,
    pendingAssetsLiabilities,
    masterKey,
    activeKey,
    isProfessionalDeputy,
    activeDocuments,
    pendingDocuments,
    accessLevel,
    activeContacts,
    isDelegateByPD,
    isReadonly
  ])

  const documentColumns = [
    {
      key: 'name',
      width: 400,
      title: <span className="dragHandler">{t('NAME')}</span>,
      render: (text, record) => (
        <span className="icon-name">
          <Icon type="file-text" style={{ fontSize: '20px' }} />
          <span className="name">{record.fileName}</span>
        </span>
      ),
      sorter: (a, b) => a.fileName.localeCompare(b.fileName)
    },
    {
      key: 'detail',
      width: 400,
      title: <span className="dragHandler">{t('DETAIL')}</span>,
      render: (text, record) =>
        record.type === 'folder' ? (
          <span>
            <Trans
              i18nKey="DOCUMENTS_DETAIL"
              values={{
                foldersCount: record.foldersCount,
                filesCount: record.filesCount
              }}
            ></Trans>
          </span>
        ) : (
          <span>{formatBytes(record.file[0].size)}</span>
        )
    }
  ]

  const showDetailEventModal = record => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
    setEventDetails(record)
    setIsDetailEventModal(true)
  }

  // const handleRemove = async () => {
  //   try {
  //     const linkedItems = [...activeContacts, ...activeDocuments]?.filter(
  //       i => i.documents?.includes(contactId) || i.contacts?.includes(contactId)
  //     )

  //     const data = {
  //       userId: user.username,
  //       recordId: contactId
  //     }

  //     if (linkedItems?.length) {
  //       Modal.confirm({
  //         title: t('Revoke linked item'),
  //         content: `${linkedItems.length} item(s) will be unshared. Are you want to revoke?`,
  //         onCancel: () => {},
  //         onOk: async () => {
  //           await Promise.all(
  //             linkedItems.map(
  //               async item =>
  //                 await api.revokeSharing(
  //                   userId,
  //                   JSON.stringify({
  //                     userId: user.username,
  //                     recordId: item._id
  //                   })
  //                 )
  //             )
  //           )
  //           await api.revokeSharing(userId, JSON.stringify(data))
  //           dispatch(fetchDeputies(userId))
  //           setContactId('')
  //           message.success('Successfully remove item')
  //         }
  //       })
  //     } else {
  //       await api.revokeSharing(userId, JSON.stringify(data))
  //       dispatch(fetchDeputies(userId))
  //       setContactId('')
  //       message.success('Successfully remove item')
  //     }
  //   } catch (error) {
  //     message.error('Failed to remove item!')
  //   }
  // }

  const handleUnlinkEvent = async eventId => {
    Modal.confirm({
      title: t('UNLINK_CONFIRM'),
      content: <>{t('UNLINK_EVENT_CONFIRM')}</>,
      async onOk() {
        try {
          await unlinkEvent(userId, contactId, 'contacts', eventId, masterKey)

          setLinkedEvents(linkedEvents.filter(doc => doc._id !== eventId))

          message.success(t('SUCCESSFULLY_UNLINKED_CONTACT'))
        } catch (e) {
          message.error(t('FAILED_TO_UNLINK_CONTACT'))
          onError(e)
        }
      },
      onCancel() {}
    })
  }

  const eventsColumns = [
    {
      key: 'description',
      width: 400,
      dataIndex: 'description',
      title: <span className="dragHandler">{t('DESCRIPTION')}</span>,
      render: (text, record) => (
        <span
          className="item-name"
          onClick={() => showDetailEventModal(record)}
        >
          {record.description}
        </span>
      )
    },
    {
      key: 'date',
      width: 300,
      dataIndex: 'date',
      title: <span className="dragHandler">{t('DATE')}</span>,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      defaultSortOrder: 'descend',
      render: (text, record) => (
        <span>{moment(record.date).format(DATE_FORMAT)}</span>
      )
    },
    {
      key: 'actions',
      width: 300,
      render: (text, record) => (
        <div style={{ textAlign: 'right' }} onClick={e => e.stopPropagation()}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <Button
                    type="link"
                    icon="delete"
                    onClick={() => handleUnlinkEvent(record._id)}
                  >
                    {t('UNLINK')}
                  </Button>
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            trigger={['click']}
          >
            <Icon type="ellipsis" />
          </Dropdown>
        </div>
      )
    }
  ]

  const linkedContactsColumns = [
    {
      key: 'name',
      width: 250,
      dataIndex: 'name',
      title: <span className="dragHandler">{t('NAME')}</span>,
      render: (text, record) => (
        <span>
          <Avatar size={36} icon="user" style={{ minWidth: 36 }} />
          <span className="item-name" onClick={() => setContactId(record._id)}>
            {' '}
            {record.name}
          </span>
        </span>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: 'ascend'
    },
    {
      key: 'actions',
      width: 250,
      render: (text, contact) => (
        <div style={{ textAlign: 'right' }} onClick={e => e.stopPropagation()}>
          {((!isReadonly && !record.status) ||
            (record.status &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL &&
              isProfessionalDeputy)) && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      type="link"
                      icon="delete"
                      onClick={() => handleUnlinkContact(contact)}
                    >
                      {t('UNLINK')}
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={['click']}
            >
              <Icon type="ellipsis" />
            </Dropdown>
          )}
        </div>
      )
    }
  ]

  const handleUnlinkContact = contact => {
    Modal.confirm({
      title: t('UNLINK_CONFIRM'),
      content: <>{t('UNLINK_CONTACT_CONFIRM')}</>,
      async onOk() {
        await unlinkContactsFromAnother(
          userId,
          record._id,
          [contact._id],
          masterKey,
          contact.status ? 'pendingContacts' : 'contacts'
        )
        await unlinkContactsFromAnother(
          userId,
          contact._id,
          [record._id],
          masterKey,
          record.status ? 'pendingContacts' : 'contacts'
        )
        setLinkedContacts(
          linkedContacts.filter(record => record._id !== contact._id)
        )
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message:
              'contacts, pendingContacts, assetsLiabilites, pendingAssetsLiabilities, events, documents, pendingDocuments, events',
            userId: userId
          }
        })
      },
      onCancel() {}
    })
  }

  const handleDeleteContact = async () => {
    try {
      if (!isProfessionalDeputy && !isReadonly) {
        const legacyInfo = await getLegacyInfo(userId, masterKey)
        if (legacyInfo && legacyInfo.otherPersonId === record._id) {
          message.error(t('CONTACT_USED_IN_LEGACY'))
          return
        }
      }

      await deleteContact(
        userId,
        record,
        masterKey,
        isProfessionalDeputy || (isDelegateByPD && isReadonly),
        activeAssetsLiabilities,
        pendingAssetsLiabilities
      )

      if (isProfessionalDeputy || (isDelegateByPD && isReadonly)) {
        dispatch(fetchOtherPendingContacts(userId, masterKey))
      }

      setContactId('')
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message:
            'contacts, pendingContacts, assetsLiabilites, pendingAssetsLiabilities, documents, pendingDocuments, events',
          userId: userId
        }
      })
      message.success(t('REMOVE_CONTACT_SUCCESS'))
    } catch (err) {
      message.error(t('FAILED_TO_DELETE_CONTACT'))
      onError(err)
    }
  }

  const showConfirm = () => {
    let linkedItemsMessages = []
    if (record.assetsLiabilities?.length)
      linkedItemsMessages.push(
        `${record.assetsLiabilities.length} ${t('ASSET_LIABILITY_RECORDS')}`
      )

    if (record.documents?.length)
      linkedItemsMessages.push(
        `${record.documents.length} ${t('DOCUMENT_RECORDS')}`
      )

    if (record.events?.length)
      linkedItemsMessages.push(`${record.events.length} ${t('EVENT_RECORDS')}`)

    Modal.confirm({
      title: t('REMOVE_CONTACT_CONFIRM'),
      okText: t('OK'),
      cancelText: t('CANCEL'),
      content:
        !!linkedItemsMessages.length &&
        `${linkedItemsMessages.join(' & ')} ${t(
          'WILL_BE_UNLINKED_FROM_THIS_CONTACT'
        )}`,
      onOk: handleDeleteContact
    })
  }

  const handleContactRequest = async (record, isApproved) => {
    SetLoading(true)
    try {
      if (isApproved) {
        const db = new PouchDB(`${userId}_contacts`)
        db.crypto(masterKey)
        const newRecord = {
          ...record,
          status: undefined
        }
        delete newRecord._rev
        await db.put(newRecord)
        await uploadEncryptedData(db, userId, 'contacts')
        //remove pending contact
        const pendingDb = new PouchDB(`${userId}_pendingContacts`)
        const deletedRecord = {
          ...record,
          deleted: true
        }

        pendingDb.crypto(masterKey)
        await pendingDb.put(deletedRecord)
        const deletedRecords = await getRecords(
          userId,
          'pendingContacts',
          masterKey,
          {
            startkey: record._id,
            endkey: `${record._id}\ufff0`
          }
        )

        await permanentlyDeleteItems(
          'pendingContacts',
          userId,
          deletedRecords,
          masterKey
        )
        await api.handleAddRecordRequest(
          JSON.stringify({
            isApproved,
            primaryUserId: userId,
            fullname: fullName,
            recordType: 'contact'
          })
        )

        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message: 'contacts, pendingContacts',
            userId: userId
          }
        })
        setContactId('')
        dispatch(fetchPendingContacts(userId, masterKey))
      } else {
        rejectFormRef.props.form.resetFields()
        setRejectModalVisible(true)
        setRejectRecord(record)
      }
    } catch (error) {
      message.error(
        isApproved
          ? t('FAILED_TO_APPROVE_REQUEST')
          : t('FAILED_TO_REJECT_REQUEST')
      )
    } finally {
      SetLoading(false)
    }
  }

  const handleRejectRecord = () => {
    setRejecting(true)

    rejectFormRef.props.form.validateFields(async (err, values) => {
      try {
        if (err) {
          setRejecting(false)
          return
        }

        const { documents, links } = rejectRecord

        const contactIds = links?.filter(cId =>
          activeContacts.map(ac => ac._id).includes(cId)
        )

        const pendingContactIds = links?.filter(cId =>
          pendingContacts.map(pc => pc._id).includes(cId)
        )

        const pendingDb = new PouchDB(`${userId}_pendingContacts`)
        pendingDb.crypto(masterKey)
        const newRecord = {
          ...rejectRecord,
          assetsLiabilities: [],
          documents: [],
          links: [],
          status: 'Rejected',
          reasonReject: values.reasonReject
        }
        await pendingDb.put(newRecord)
        await uploadEncryptedData(pendingDb, userId, 'pendingContacts')

        //unlink document
        if (documents?.length) {
          const activeDocumentIds = rejectRecord.documents.filter(id =>
            activeDocuments.map(ad => ad._id).includes(id)
          )
          const pendingDocumentIds = rejectRecord.documents.filter(id =>
            pendingDocuments.map(pd => pd._id).includes(id)
          )

          if (activeDocumentIds?.length) {
            const documentsDb = new PouchDB(`${userId}_documents`)
            documentsDb.crypto(masterKey)
            await unlinkItemFromLinkedList(
              rejectRecord._id,
              'contacts',
              activeDocumentIds,
              documentsDb
            )
            await uploadEncryptedData(documentsDb, userId, 'documents')
          }

          if (pendingDocumentIds?.length) {
            const pendingDocumentsDb = new PouchDB(`${userId}_pendingDocuments`)
            pendingDocumentsDb.crypto(masterKey)
            await unlinkItemFromLinkedList(
              rejectRecord._id,
              'contacts',
              pendingDocumentIds,
              pendingDocumentsDb
            )

            await uploadEncryptedData(
              pendingDocumentsDb,
              userId,
              'pendingDocuments'
            )
          }
        }

        //unlink contacts
        if (contactIds?.length) {
          unlinkContactsFromAnother(
            userId,
            newRecord._id,
            contactIds,
            masterKey,
            'contacts'
          )
        }

        if (pendingContactIds?.length) {
          unlinkContactsFromAnother(
            userId,
            newRecord._id,
            pendingContactIds,
            masterKey,
            'pendingContacts'
          )
        }

        await api.handleAddRecordRequest(
          JSON.stringify({
            isApproved: false,
            primaryUserId: userId,
            fullname: fullName,
            recordType: 'contact'
          })
        )

        dispatch(fetchPendingAssetsLiabilities(userId, masterKey))
        dispatch(fetchPendingContacts(userId, masterKey))
        setRejecting(false)
        setRejectModalVisible(false)
        setContactId()
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message:
              'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events',
            userId: userId
          }
        })
      } catch (error) {
        setRejecting(false)
        message.error(t('FAILED_TO_REJECT_REQUEST'))
      }
    })
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <div style={{ marginTop: 20 }}>
          {record.name ? (
            <H4>{record.name}</H4>
          ) : (
            <>
              <H4 display="inline-block">{t('GROUP_INFORMATION')}</H4>
              <H5
                display="inline-block"
                style={{ marginLeft: 15 }}
                color={theme.dark2}
              >
                {contactIds.length} {t('PEOPLE')}
              </H5>
            </>
          )}
        </div>
        <Tabs defaultActiveKey="Information" className="custom-tabs">
          {!contactIds.length && (
            <TabPane tab={t('INFORMATION')} key="Information">
              {record.reasonReject && (
                <SimpleHeader
                  title={
                    <>
                      <H4>{t('REJECTION_REASON')}</H4>
                      <span>{record.reasonReject}</span>
                    </>
                  }
                />
              )}
              <SimpleHeader
                title={<H4>{t('CONTACT_INFORMATION')}</H4>}
                extra={
                  ((!isReadonly && !record.status) ||
                    checkPermission(permissions, contactId)) && (
                    <>
                      <Button
                        type="link"
                        icon="edit"
                        onClick={() =>
                          history.push(
                            isReadonly
                              ? `/contacts/${userId}/${contactId}/edit`
                              : `/contacts/${contactId}/edit`
                          )
                        }
                      >
                        {t('EDIT')}
                      </Button>
                      {!isReadonly && (
                        <Button
                          type="link"
                          icon="link"
                          onClick={() => setShareDataModalVisible(true)}
                        >
                          {t('Share')}
                        </Button>
                      )}
                    </>
                  )
                }
                size="small"
              />

              {!!lstPendingDocuments?.length && !isReadonly && (
                <Alert
                  type="warning"
                  showIcon
                  message={
                    <>
                      <Span1>
                        {t('PENDING_DOCUMENT_WARNING_COUNT', {
                          type: t('CONTACT'),
                          documentCount: lstPendingDocuments.length
                        })}
                        :{' '}
                        <b>
                          {lstPendingDocuments
                            .map(lpd => lpd.fileName)
                            .join(', ')}
                        </b>
                      </Span1>

                      <Button
                        type="link"
                        onClick={() => history.push('/files/pending')}
                      >
                        {' '}
                        {t('VIEW_DETAILS')}
                      </Button>
                    </>
                  }
                />
              )}

              {record.type === CONTACT_TYPES.INDIVIDUAL && (
                <>
                  <Field
                    icon={
                      <CustomIcon type="birthday" style={{ fontSize: 16 }} />
                    }
                    title={t('DATE_OF_BIRTH')}
                    value={renderDate(record.dateOfBirth)}
                  />
                  <Field
                    icon={<Icon type="idcard" style={{ fontSize: 16 }} />}
                    title={t('ID_PASSPORT_NUMBER')}
                    value={record.idPassportNumber}
                  />
                </>
              )}
              {record.type === CONTACT_TYPES.ORGANISATION && (
                <>
                  <Field
                    icon={
                      <CustomIcon type="personal" style={{ fontSize: 16 }} />
                    }
                    title={t('LEGAL_NAME')}
                    value={record.legalName}
                  />
                  <Field
                    icon={<Icon type="number" style={{ fontSize: 16 }} />}
                    title={t('COMPANY_REGISTRATION_NUMBER')}
                    value={record.companyRegistrationNumber}
                  />
                  <Field
                    icon={<Icon type="global" style={{ fontSize: 16 }} />}
                    title={t('COUNTRY_TERRITORY_OF_INCORPORATION')}
                    value={record.countryTerritoryOfIncorporation}
                  />
                </>
              )}

              <Field
                icon={<CustomIcon type="email" style={{ fontSize: 16 }} />}
                title={t('EMAILS')}
                value={record.emails?.map((email, index) => (
                  <div key={index}>
                    <Span color={theme.dark2}>{email.name}:</Span> {email.value}
                  </div>
                ))}
              />

              <Field
                icon={<CustomIcon type="location" style={{ fontSize: 16 }} />}
                title={t('ADDRESSES')}
                value={record.addresses?.map((address, index) => (
                  <div key={index} style={{ display: 'flex' }}>
                    <Span color={theme.dark2}>{address.name}:</Span>
                    <Span
                      style={{ whiteSpace: 'pre-wrap', marginLeft: '0.5em' }}
                    >
                      {address.value}
                    </Span>
                  </div>
                ))}
              />

              <Field
                icon={<CustomIcon type="mobile" style={{ fontSize: 16 }} />}
                title={t('PHONE')}
                value={record.phoneNumbers?.map((phoneNumber, index) => (
                  <div key={index}>
                    <Span color={theme.dark2}>{phoneNumber.name}:</Span>{' '}
                    {phoneNumber.value}
                  </div>
                ))}
              />

              <Field
                icon={<CustomIcon type="link" style={{ fontSize: 16 }} />}
                title={t('REFERENCES')}
                value={record.references?.map((reference, index) => (
                  <div key={index}>
                    <Span color={theme.dark2}>{reference.name}:</Span>{' '}
                    {reference.value}
                  </div>
                ))}
              />

              <Field
                icon={<CustomIcon type="social" style={{ fontSize: 16 }} />}
                title={t('URL')}
                value={record.url}
              />
              <Field
                icon={<Icon type="tags" style={{ fontSize: 16 }} />}
                title={t('TAGS')}
                value={record.tags?.join(', ')}
              />
              <Field
                icon={<CustomIcon type="page" style={{ fontSize: 16 }} />}
                title={t('NOTE')}
                value={<Span className="pre-area">{record.description}</Span>}
              />

              {((!isReadonly && activeKey === TAB_KEY.ACTIVED) ||
                (record.status &&
                  (isProfessionalDeputy ||
                    (isDelegateByPD && isReadonly)))) && (
                <Span
                  color={theme.red}
                  style={{
                    display: 'inline-block',
                    marginTop: 40,
                    cursor: 'pointer'
                  }}
                  onClick={showConfirm}
                >
                  <CustomIcon
                    type="trash"
                    style={{ fontSize: 16, color: theme.red, marginRight: 8 }}
                  />
                  {t('REMOVE_CONTACT')}
                </Span>
              )}
              {/* {isReadonly && checkPermission(permissions, contactId) && (
                <Span
                  color={theme.red}
                  style={{
                    display: 'inline-block',
                    marginTop: 40,
                    cursor: 'pointer'
                  }}
                  onClick={handleRemove}
                >
                  <CustomIcon
                    type="trash"
                    style={{ fontSize: 16, color: theme.red, marginRight: 8 }}
                  />
                  {t('Remove')}
                </Span>
              )} */}
              {activeKey === TAB_KEY.PENDING && !isReadonly && (
                <div style={{ marginTop: 20 }}>
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={() => handleContactRequest(record, true)}
                  >
                    {t('APPROVE')}
                  </Button>
                  <Divider type="vertical" />
                  <Button onClick={() => handleContactRequest(record)}>
                    {t('REJECT')}
                  </Button>
                </div>
              )}
            </TabPane>
          )}
          {!isReadonly && (
            <TabPane tab={t('EVENTS')} key={StringResources.EVENTS}>
              <PageHeader
                title={<H4 display="inline-block">{t('EVENTS')}</H4>}
                style={{ padding: '0 0 20px' }}
              />
              <CustomTable
                dataSource={linkedEvents}
                scroll={{ x: true }}
                columns={
                  isReadonly
                    ? eventsColumns.filter(col => col.key !== 'actions')
                    : eventsColumns
                }
                rowKey="_id"
              />
            </TabPane>
          )}
          {!(isDelegateByPD && isReadonly) && (
            <TabPane tab={t('CONTACT_LINKS')} key={StringResources.CONTACTS}>
              <PageHeader
                title={<H4 display="inline-block">{t('CONTACT_LINKS')}</H4>}
                style={{ padding: '0 0 20px' }}
              />
              <CustomTable
                dataSource={linkedContacts}
                scroll={{ x: true }}
                columns={linkedContactsColumns}
                rowKey="_id"
              />
            </TabPane>
          )}
          <TabPane tab={t('DOCUMENTS')} key={StringResources.DOCUMENTS}>
            <PageHeader
              title={<H4 display="inline-block">{t('DOCUMENTS')}</H4>}
              subTitle={
                <H5 display="inline-block" color={theme.dark2}>
                  {filteredLinkedDocuments.length}
                </H5>
              }
              style={{ padding: '0 0 20px' }}
              extra={
                <Input.Search
                  placeholder={t('SEARCH_FILE_OR_FOLDER_NAME')}
                  allowClear
                  onSearch={value => {
                    const filteredDocuments = search(
                      linkedDocuments,
                      ['fileName'],
                      value
                    )
                    setFilteredLinkedDocuments(filteredDocuments)
                  }}
                  style={{ width: 320 }}
                />
              }
            />
            <CustomTable
              dataSource={filteredLinkedDocuments}
              columns={documentColumns.filter(col => col.key !== 'actions')}
              rowKey="_id"
              onRow={record => ({
                onClick: () => {
                  setSelectedDoc({
                    id: record._id,
                    name: record.fileName,
                    fileId: record.fileId,
                    sub: record.sub,
                    status: record.status
                  })
                  setFileDetailsVisible(true)
                }
              })}
            />
            <FileDetails
              visible={fileDetailsVisible}
              setVisible={setFileDetailsVisible}
              docItem={selectedDoc}
            />
          </TabPane>
        </Tabs>
      </Spin>
      <RejectModal
        wrappedComponentRef={fr => (rejectFormRef = fr)}
        visible={rejectModalVisible}
        handleOk={handleRejectRecord}
        handleCancel={() => setRejectModalVisible(false)}
        rejecting={rejecting}
      />
      <EventDetails
        isLoading={isLoading}
        visible={isDetailEventModal}
        record={eventDetails}
        setVisible={setIsDetailEventModal}
        isViewMode={true}
      />
      <ShareDataModal
        visible={shareDataModalVisible}
        setVisible={setShareDataModalVisible}
        selectedDoc={record}
        viewDetail={() => {}}
      />
    </>
  )
}

export default withRouter(ContactDetails)
