import React, { useContext, useState } from 'react'
import { Modal, Icon, Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
// import { onError } from '../../lib/sentry'
import { useSelector } from 'react-redux'
import { formatBytes } from './../../share/helpers'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { permanentlyDeleteItems } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'
// import { H5 } from '../override/Typography'
import { sum } from 'lodash'

const CurrentFilesModal = props => {
  const { visible, setVisible, setValue, isDeputyOnly } = props
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const { activeFiles, usedStorage } = useSelector(state => state.documents)

  // const [selectedRows, setSelectedRows] = useState([])
  const [deletedRecords, setDeletedRecords] = useState([])

  const { t } = useTranslation()

  const handleDelete = async records => {
    Modal.confirm({
      content: <>{t('CONFIRM_DELETE_ITEMS_MSG')}</>,
      async onOk() {
        try {
          await permanentlyDeleteItems(
            'documents',
            user.username,
            records,
            masterKey
          )
          setVisible(false)
        } catch (error) {
          onError(error)
        }
      },
      onCancel() {}
    })
  }

  const deleteFile = record => {
    setDeletedRecords([...deletedRecords, record])
  }

  const restore = record => {
    setDeletedRecords(deletedRecords.filter(item => item._id !== record._id))
  }

  const getFileExtension = filename => {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
  }

  const columns = [
    {
      key: 'name',
      title: t('NAME'),
      render: (text, record) => <span>{record.fileName}</span>,
      sorter: (a, b) =>
        getFileExtension(a.fileName) === getFileExtension(b.fileName)
          ? a.fileName === b.fileName
            ? ''
            : a.fileName.localeCompare(b.nafileNameme)
          : getFileExtension(a.fileName).localeCompare(
              getFileExtension(b.fileName)
            ),
      defaultSortOrder: 'ascend'
    },
    {
      key: 'size',
      title: 'Size',
      render: (text, record) => {
        return <span>{formatBytes(record.file[0].size)}</span>
      },
      width: 100
    },
    {
      key: 'actions',
      render: (text, record) => (
        <Tooltip title={t('DELETE')}>
          <Icon type="delete" onClick={() => deleteFile(record)} />
        </Tooltip>
      ),
      width: 46,
      align: 'right'
    },
    {
      key: 'restoreActions',
      render: (text, record) => (
        <Tooltip title={t('RESTORE')}>
          <Icon type="delete" onClick={() => restore(record)} />
        </Tooltip>
      ),
      width: 46,
      align: 'right'
    }
  ]

  return (
    <Modal
      width={700}
      visible={visible}
      title={t('All files')}
      okText="Confirm"
      onOk={() => handleDelete(deletedRecords)}
      onCancel={() => {
        setVisible(false)
        if (!isDeputyOnly) setValue()
      }}
    >
      {isDeputyOnly && (
        <div>Please reduce your file size below 2 GB to use the free plan.</div>
      )}
      <div>
        <b>Total size:</b>{' '}
        {formatBytes(
          usedStorage - sum(deletedRecords.map(item => item.file[0].size))
        )}
      </div>
      <Table
        style={{ marginTop: 20 }}
        className="tour-select-docs-to-link"
        dataSource={activeFiles.filter(item => !deletedRecords.includes(item))}
        columns={columns.filter(item => item.key !== 'restoreActions')}
        rowKey="_id"
      />

      {!!deletedRecords?.length && (
        <>
          <div>
            <b>Pending deletion files size:</b>{' '}
            {formatBytes(sum(deletedRecords.map(item => item.file[0].size)))}
          </div>

          {/* <Button type="primary" onClick={() => handleDelete(deletedRecords)}>
            Delete files
          </Button> */}

          <Table
            style={{ marginTop: 20 }}
            className="tour-select-docs-to-link"
            dataSource={deletedRecords}
            columns={columns.filter(item => item.key !== 'actions')}
            rowKey="_id"
          />
        </>
      )}
    </Modal>
  )
}
export default CurrentFilesModal
