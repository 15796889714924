import gql from 'graphql-tag'

export const onCreateByUserId = gql`
  subscription OnCreateByUserId($userId: String!) {
    onCreateByUserId(userId: $userId) {
      id
      message
      userId
    }
  }
`

export const onCreateS3Change = gql`
  subscription OnCreateS3Change {
    onCreateS3Change {
      id
      message
      userId
    }
  }
`