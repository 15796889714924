import React, { useState, useContext, useEffect } from 'react'
import { Modal, Form, Input, message, List, Icon } from 'antd'
import { useTranslation } from 'react-i18next'
import { uploadEncryptedData, getRecords } from '../../lib/pouchDb'
import Button from '../override/Button'
import PouchDB from 'pouchdb'
import uuidv4 from 'uuid/v4'
import VaultContext from '../../contexts/VaultContext'
// import AssetLiabilitySelect from '../assets-liabilities/AssetLiabilitySelect'
import DocumentsLinksModal from '../assets-liabilities/DocumentsLinksModal'
import FormItem from '../override/FormItem'
import { fetchPasswords } from '../../features/passwords/PasswordSlice'
import { useDispatch } from 'react-redux'
import PasswordStrengthBar from 'react-password-strength-bar'
import { updatePasswordsOnDb } from './passwordHelpers'
import EventSelect from '../common/EventSelect'
import { onError } from '../../lib/sentry'
import { Span } from '../override/Typography'
import AuthContext from '../../contexts/AuthContext'
import { logDocumentActivity } from '../../share/logs'
import { getUserData } from '../../lib/cognito'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'

const { TextArea } = Input

const PasswordModal = props => {
  const {
    isEditMode,
    setIsEditMode,
    setVisible,
    visible,
    selectedPassword,
    onAddComplete,
    form
  } = props
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  const { userId, masterKey, isReadonly } = useContext(VaultContext)
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const [linkedDocuments, setLinkedDocuments] = useState([])
  const [documentsLinksVisible, setDocumentsLinksVisible] = useState(false)
  const [passwordField, setPasswordField] = useState('')
  const [addS3Change] = useMutation(createS3Change)

  const documents = isEditMode ? selectedPassword.documents : ''
  useEffect(() => {
    const fetchLinkedDocuments = async () => {
      try {
        const linkedDocuments =
          documents && documents.length
            ? await getRecords(userId, 'documents', masterKey, {
                keys: documents
              })
            : []
        setLinkedDocuments(linkedDocuments)
      } catch (err) {
        onError(err)
      }
    }
    if (masterKey) {
      fetchLinkedDocuments()
    }
  }, [documents, userId, masterKey])

  const passwordState = isEditMode ? selectedPassword.password : ''
  useEffect(() => {
    if (isEditMode) {
      setPasswordField(passwordState)
    }
  }, [isEditMode, passwordState])

  const unlinkDocument = itemId => {
    setLinkedDocuments(linkedDocuments.filter(doc => doc._id !== itemId))
  }

  const linkDocument = async documentId => {
    try {
      const documentIds = linkedDocuments.map(doc => doc._id).concat(documentId)
      const newLinkedDocuments =
        documentIds && documentIds.length
          ? await getRecords(userId, 'documents', masterKey, {
              keys: documentIds
            })
          : []
      setLinkedDocuments(newLinkedDocuments)
    } catch (err) {
      onError(err)
    }
  }

  const handelSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        setIsSaving(true)
        const db = new PouchDB(`${userId}_passwords`)
        db.crypto(masterKey)

        const record = isEditMode
          ? await db.get(selectedPassword._id)
          : {
              _id: uuidv4(),
              url: '',
              title: '',
              username: '',
              password: '',
              note: '',
              assetsLiabilities: [],
              events: [],
              documents: []
            }
        const newRecord = {
          ...record,
          url: values.url,
          title: values.title,
          username: values.username,
          password: values.password,
          note: values.note,
          assetsLiabilities: values.assetsLiabilities,
          events: values.events,
          documents: linkedDocuments.map(doc => doc._id)
        }

        //update AssetsLiabilities
        await updatePasswordsOnDb(
          userId,
          masterKey,
          record,
          newRecord,
          'assetsLiabilities'
        )

        //update Events
        await updatePasswordsOnDb(
          userId,
          masterKey,
          record,
          newRecord,
          'events'
        )

        //update Documents
        await updatePasswordsOnDb(
          userId,
          masterKey,
          record,
          newRecord,
          'documents'
        )

        await db.put(newRecord)
        await uploadEncryptedData(db, userId, 'passwords')
        onAddComplete && onAddComplete(newRecord._id)

        if (isReadonly && isEditMode) {
          getUserData(
            user,
            async (err, data) => {
              if (err) {
                onError(err)
                return
              }

              await logDocumentActivity(
                userId,
                data.UserAttributes,
                user.username,
                newRecord._id,
                {
                  id: newRecord._id,
                  type: 'password',
                  name: record.title,
                  action: 'edited',
                }
              )
            },
            { bypassCache: true }
          )
        }

        message.success(t('SUCCESSFULLY_SAVED_THE_PASSWORD'))
        dispatch(fetchPasswords(userId, masterKey))
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message: 'passwords, events, documents',
            userId: userId
          }
        })
      } catch (error) {
        message.error(t('FAILED_TO_SAVE_THE_PASSWORD'))
        onError(error)
      } finally {
        setVisible(false)
        form.resetFields()
        setIsSaving(false)
        setPasswordField('')
        if (isEditMode) {
          setIsEditMode(false)
        }
      }
    })
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
    if (isEditMode) {
      setIsEditMode(false)
    }
    setPasswordField('')
  }

  return (
    <>
      <Modal
        title={isEditMode ? t('EDIT_PASSWORD') : t('ADD_NEW_PASSWORD')}
        visible={visible}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button key="cancle" onClick={handleCancel}>
            {t('CANCEL')}
          </Button>,
          <Button
            key="save"
            type="primary"
            loading={isSaving}
            onClick={handelSave}
          >
            {t('SAVE')}
          </Button>
        ]}
      >
        <Form>
          <FormItem label={t('TITLE')}>
            {getFieldDecorator('title', {
              initialValue: isEditMode ? selectedPassword.title : '',
              rules: [{ required: true, message: t('INPUT_NAME_MSG') }]
            })(<Input />)}
          </FormItem>
          <FormItem label={t('URL')}>
            {getFieldDecorator('url', {
              initialValue: isEditMode ? selectedPassword.url : 'http://',
              rules: [
                { required: false, type: 'url', message: t('INPUT_URL_MSG') }
              ]
            })(<Input />)}
          </FormItem>
          <FormItem label={t('USERNAME')}>
            {getFieldDecorator('username', {
              initialValue: isEditMode ? selectedPassword.username : ''
            })(<Input />)}
          </FormItem>
          <FormItem label={t('PASSWORD')}>
            {getFieldDecorator('password', {
              initialValue: isEditMode ? selectedPassword.password : '',
              rules: [{ required: true, message: t('INPUT_PASSWORD_MSG') }]
            })(
              <Input.Password
                onChange={e => {
                  setPasswordField(e.target.value)
                }}
              />
            )}
          </FormItem>
          {!!passwordField && (
            <PasswordStrengthBar
              password={passwordField}
              minLength={1}
              scoreWords={[
                t('TOO_WEAK'),
                t('WEAK'),
                t('FAIR'),
                t('GOOD'),
                t('STRONG')
              ]}
              shortScoreWord={t('TOO_SHORT')}
            />
          )}

          <FormItem label={t('NOTE')}>
            {getFieldDecorator('note', {
              initialValue: isEditMode ? selectedPassword.note : ''
            })(<TextArea rows={4} maxLength={2000} />)}
          </FormItem>

          {/* <AssetLiabilitySelect
            label={t('ASSETS_LIABILITIES')}
            placeholder={t('SELECT_ASSETS_LIABILITIES')}
            required={false}
            getFieldDecorator={getFieldDecorator}
            fieldName="assetsLiabilities"
            initialValue={isEditMode ? selectedPassword.assetsLiabilities : []}
            mode="multiple"
            onAddComplete={alId =>
              setFieldsValue({
                assetsLiabilities: [
                  ...(getFieldValue('assetsLiabilities') || []),
                  alId
                ]
              })
            }
          /> */}

          <EventSelect
            label={t('EVENT')}
            getFieldDecorator={getFieldDecorator}
            fieldName="events"
            linkedEvents={[]}
            fetchEvents={() => {}}
            setLinkedEvents={() => {}}
            initialValue={isEditMode ? selectedPassword.events : []}
            mode="multiple"
            onAddComplete={eventId =>
              setFieldsValue({
                events: [...(getFieldValue('events') || []), eventId]
              })
            }
          />
          <List
            header={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Span>{t('LINKED_DOCUMENTS')}:</Span>
                <Button
                  icon="plus"
                  onClick={() => setDocumentsLinksVisible(true)}
                >
                  {t('ADD')}
                </Button>
              </div>
            }
            dataSource={linkedDocuments}
            renderItem={item => (
              <List.Item className="linked-document-item">
                <span>
                  <Icon type="file-text" style={{ marginRight: 10 }} />
                  {item.fileName}
                </span>
                <Icon type="delete" onClick={() => unlinkDocument(item._id)} />
              </List.Item>
            )}
            locale={{ emptyText: t('NO_DOCUMENTS') }}
          />
        </Form>
      </Modal>
      <DocumentsLinksModal
        visible={documentsLinksVisible}
        setVisible={setDocumentsLinksVisible}
        record={{}}
        linkDocument={linkDocument}
        filteredDocIds={linkedDocuments.map(doc => doc._id)}
      />
    </>
  )
}

const WrappedPasswordForm = Form.create({ name: 'PasswordModal' })(
  PasswordModal
)

export default WrappedPasswordForm
