import React, { useState, useEffect, useContext } from 'react'
import FormItem from '../override/FormItem'
import SetupMFA from '../common/SetupMFA'
import WrappedForm from '../common/WrappedForm'
import CodeInput from '../common/CodeInput'
import moment from 'moment'
import { Alert, Icon, Input, Modal } from 'antd'
import { Span, H4, StatusText, P } from '../override/Typography'
import { errorMessage } from '../../share/helpers'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUser } from '../../features/user/userSlice'
import { getAuthenticationDetails, getCognitoUser } from '../../lib/cognito'
import AuthContext from '../../contexts/AuthContext'
import { StringResources } from '../../share/StringResources'
import api from '../../lib/api'
import Button from '../override/Button'
import { ThemeContext } from 'styled-components'
import { AUTH_FLOW, MFA_TYPES } from '../../share/Constants'
import { onError } from '../../lib/sentry'
import { useTranslation, Trans } from 'react-i18next'
import AuthFlowTypeForm from '../pages/SelectAuthFlowTypeForm'

let cognitoUser, mfaSecretCode, authFormRef, authDetails

export default function MFAInfo({ userInfo, userMFASettingList }) {
  const { user } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const [authModalVisible, setAuthModalVisible] = useState(false)
  const [setupMfaModalVisible, setSetupMfaModalVisible] = useState()
  const [selectMfaModalVisible, setSelectMfaModalVisible] = useState()
  const [currentStep, setCurrentStep] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [mfaRequired, setMfaRequired] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [isSmsType, setIsSmsType] = useState(false)
  const [isEmailType, setIsEmailType] = useState(false)
  const [selectedOTPType, setSelectedOTPType] = useState()
  const [isChangedAuthFlow, setIsChangedAuthFlow] = useState(false)
  const [isReset, setIsReset] = useState(false)
  const { setupMfaDate, currentFlow } = useSelector(state => state.user).user
  const dispatch = useDispatch()

  useEffect(() => {
    if (errMsg) setIsSubmitting(false)
  }, [errMsg])

  const verifySoftwareToken = token => {
    setIsSubmitting(true)
    setErrMsg('')

    cognitoUser.verifySoftwareToken(
      token,
      StringResources.FRIENDLY_DEVICE_NAME,
      {
        onSuccess: function (session) {
          // if input token is valid, then enable MFA for specific user, with SOFTWARE_TOKEN_MFA type
          cognitoUser.setUserMfaPreference(
            { Enabled: true, PreferredMfa: false },
            { Enabled: true, PreferredMfa: false },
            function (err, result) {
              if (err) {
                setErrMsg(t('FAILED_TO_SET_USER_MFA_PREFERENCE'))
                return
              }

              api
                .updateSetupMfaDate(
                  user.username,
                  JSON.stringify({
                    setupMfaDate: Math.floor(new Date().getTime() / 1000)
                  })
                )
                .then(async res => {
                  dispatch(fetchUser(user.username))
                  setIsSubmitting(false)
                  setCurrentStep(2)
                  setIsReset(false)
                })
                .catch(err => onError(err))
            }
          )
        },
        onFailure: function (err) {
          setErrMsg(errorMessage(err))
          setIsSubmitting(false)
        }
      }
    )
  }

  const resetMfaSetupProcess = () => {
    authFormRef.props.form.resetFields()
    setErrMsg('')
    setMfaRequired(false)
    setSetupMfaModalVisible(false)
    setIsSubmitting(false)
    setCurrentStep(0)
  }

  const cognitoCallbacks = {
    onSuccess: function (session) {
      const callbacks = this
      setAuthModalVisible(false)
      if (isReset) {
        cognitoUser.setUserMfaPreference(
          { PreferredMfa: false, Enabled: true },
          { PreferredMfa: false, Enabled: false },
          function (err) {
            if (err) onError(err)
            else {
              cognitoUser.associateSoftwareToken(callbacks)
            }
          }
        )
      } else {
        if (
          (!userMFASettingList?.length && currentFlow !== AUTH_FLOW.MFA_FLOW) ||
          userMFASettingList?.length ||
          currentFlow === AUTH_FLOW.CUSTOM_FLOW ||
          currentFlow === AUTH_FLOW.NONE
        ) {
          setSelectMfaModalVisible(true)
        }

        if (
          (selectedOTPType === AUTH_FLOW.MFA_FLOW && isChangedAuthFlow) ||
          (!userMFASettingList?.length && currentFlow === AUTH_FLOW.MFA_FLOW)
        ) {
          setSelectMfaModalVisible(false)
          cognitoUser.associateSoftwareToken(callbacks)
        }

        if (
          (selectedOTPType === AUTH_FLOW.CUSTOM_FLOW ||
            selectedOTPType === AUTH_FLOW.NONE) &&
          isChangedAuthFlow
        ) {
          window.location.reload()
        }
      }
    },
    onFailure: function (err) {
      setErrMsg(errorMessage(err))
    },
    associateSecretCode: function (secretCode) {
      setIsSubmitting(false)
      // Show setup MFA modal (including generated QR and secret code)
      mfaSecretCode = secretCode
      setSetupMfaModalVisible(true)
    }
  }

  const authenticateUser = () => {
    const authenticationDetails = getAuthenticationDetails(
      authDetails.username,
      authDetails.password
    )

    if (currentFlow === AUTH_FLOW.CUSTOM_FLOW && !isChangedAuthFlow) {
      cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')
    } else {
      cognitoUser.setAuthenticationFlowType('USER_SRP_AUTH')
    }

    cognitoUser.authenticateUser(authenticationDetails, {
      ...cognitoCallbacks,
      selectMFAType: function () {
        cognitoUser.sendMFASelectionAnswer(MFA_TYPES.TOTP, this)
      },
      customChallenge: function () {
        setIsSubmitting(false)
        setMfaRequired(true)
        setIsEmailType(true)
        setSelectMfaModalVisible(false)
        setAuthModalVisible(true)
      },
      mfaRequired: function () {
        setIsSubmitting(false)
        setMfaRequired(true)
        setIsSmsType(true)
      },
      totpRequired: function () {
        setIsSubmitting(false)
        setMfaRequired(true)
      }
    })
  }

  const handlePasswordSubmit = () => {
    authFormRef.props.form.validateFields((err, values) => {
      if (err) return

      setIsSubmitting(true)
      setErrMsg('')

      cognitoUser = getCognitoUser()

      authDetails = { username: userInfo.email, password: values.password }
      authenticateUser()
    })
  }

  const handleMFACodeSubmit = () => {
    authFormRef.props.form.validateFields((err, values) => {
      if (err) return

      setIsSubmitting(true)
      setErrMsg('')

      isEmailType
        ? cognitoUser.sendCustomChallengeAnswer(values.code, cognitoCallbacks)
        : cognitoUser.sendMFACode(
            values.code,
            cognitoCallbacks,
            isSmsType ? MFA_TYPES.SMS : MFA_TYPES.TOTP
          )
    })
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <H4>{t('MULTI_FACTOR_AUTHENTICATION')}</H4>
        <div>
          <Span
            color={theme.primary}
            style={{ cursor: 'pointer' }}
            onClick={() => setAuthModalVisible(true)}
          >
            <Icon type="sync" style={{ marginRight: 8 }} />
            {t('CHANGE_AUTH_FLOW_TYPE')}
          </Span>
          {!!userMFASettingList?.length && (
            <Span
              color={theme.primary}
              style={{ cursor: 'pointer', marginLeft: 20 }}
              onClick={() => {
                setAuthModalVisible(true)
                setIsReset(true)
              }}
            >
              <Icon type="edit" style={{ marginRight: 8 }} />
              {t('SETUP_AGAIN')}
            </Span>
          )}
        </div>
      </div>
      {setupMfaDate && (
        <>
          <StatusText color={theme.dark2}>
            <Trans
              i18nKey={
                !!userMFASettingList?.length
                  ? 'MULTI_FACTOR_AUTHENTICATION_FIRST_SUMMARY'
                  : 'EMAIL_OTP_SUMMARY'
              }
              values={{
                setupMfaDate: moment(setupMfaDate * 1000).format('LLL')
              }}
            ></Trans>
          </StatusText>
          <StatusText color={theme.dark2}>
            {t('MULTI_FACTOR_AUTHENTICATION_SECOND_SUMMARY')}
          </StatusText>
        </>
      )}
      <Modal
        visible={authModalVisible}
        title={t('SETUP_MFA')}
        width={350}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          resetMfaSetupProcess()
          setAuthModalVisible(false)
        }}
      >
        <WrappedForm wrappedComponentRef={fr => (authFormRef = fr)}>
          {getFieldDecorator => (
            <>
              {mfaRequired ? (
                <>
                  <P style={{ marginBottom: '0.5em' }}>
                    {isSmsType
                      ? t('INPUT_SMS_CODE')
                      : isEmailType
                      ? t('INPUT_EMAIL_CODE')
                      : t('INPUT_TOTP_CODE')}
                    :
                  </P>
                  <CodeInput getFieldDecorator={getFieldDecorator} />
                  {(isSmsType || isEmailType) && (
                    <Button
                      type="link"
                      style={{ paddingLeft: 0 }}
                      onClick={authenticateUser}
                    >
                      {t('RESEND_CODE')}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <P style={{ marginBottom: '0.5em' }}>
                    {t('ENTER_YOUR_PASSWORD_TO_PROCEED')}:
                  </P>
                  <FormItem>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: t('INPUT_CURRENT_PASSWORD_MSG')
                        }
                      ]
                    })(
                      <Input.Password
                        placeholder={t('PASSWORD')}
                        autoFocus
                        maxLength={30}
                      />
                    )}
                  </FormItem>
                </>
              )}

              {errMsg && (
                <Alert
                  message={errMsg}
                  type="error"
                  afterClose={() => setErrMsg('')}
                  closable
                  style={{ marginBottom: 16 }}
                />
              )}
              <div style={{ textAlign: 'right' }}>
                <Button
                  onClick={() => {
                    resetMfaSetupProcess()
                    setAuthModalVisible(false)
                  }}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 8 }}
                  onClick={
                    mfaRequired ? handleMFACodeSubmit : handlePasswordSubmit
                  }
                  loading={isSubmitting}
                >
                  {t('NEXT')}
                </Button>
              </div>
            </>
          )}
        </WrappedForm>
      </Modal>

      <Modal
        visible={setupMfaModalVisible}
        footer={null}
        maskClosable={false}
        closable={false}
        onCancel={e => {
          resetMfaSetupProcess()
          setSetupMfaModalVisible(false)
        }}
        width={700}
      >
        <SetupMFA
          username={userInfo.email}
          secretCode={mfaSecretCode}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          isSubmitting={isSubmitting}
          verifySoftwareToken={verifySoftwareToken}
          setMfaSetupVisible={setSetupMfaModalVisible}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleFinish={e => {
            window.location.reload()
          }}
        />
      </Modal>

      <Modal
        visible={selectMfaModalVisible}
        footer={null}
        closable={false}
        maskClosable={false}
        onCancel={e => {
          resetMfaSetupProcess()
          setSelectMfaModalVisible(false)
        }}
      >
        <AuthFlowTypeForm
          cognitoUser={cognitoUser}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          authenticationDetails={authDetails}
          authenticateUser={authenticateUser}
          setSelectedOTPType={setSelectedOTPType}
          currentFlow={currentFlow}
          isChangedAuthFlow={isChangedAuthFlow}
          setIsChangedAuthFlow={setIsChangedAuthFlow}
          handleCancel={e => {
            resetMfaSetupProcess()
            setSelectMfaModalVisible(false)
          }}
        />
      </Modal>
    </>
  )
}
