import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Select, Tooltip } from 'antd'
import Button from '../override/Button'
import FormItem from '../override/FormItem'
import AddEventModal from '../calendar/AddEventModal'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT } from '../../share/Constants'
import moment from 'moment'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'
import VaultContext from '../../contexts/VaultContext'
import { useSelector } from 'react-redux'

export default function EventSelect({
  label,
  placeholder,
  required,
  getFieldDecorator,
  fieldName,
  initialValue,
  mode,
  onAddComplete,
  linkedEvents,
  setLinkedEvents,
  disabled
}) {
  const { isReadonly } = useContext(VaultContext)
  const [eventModalVisibile, setEventModalVisibile] = useState(false)
  const { activeEvents } = useSelector(state =>
    isReadonly ? state.otherEvents : state.events
  )
  const { t } = useTranslation()
  const [events, setEvents] = useState([])
  const { userId, masterKey } = useContext(VaultContext)

  const fetchEvents = useCallback(async () => {
    try {
      const events = await getRecords(userId, 'events', masterKey)
      setEvents(
        events.filter(e =>
          activeEvents.map(activeEvent => activeEvent.id).includes(e._id)
        )
      )
    } catch (err) {
      onError(err)
    }
  }, [userId, masterKey, activeEvents])

  useEffect(() => {
    fetchEvents()
  }, [fetchEvents])

  return (
    <div>
      <FormItem label={label}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 'calc(100% - 58px)' }}>
            {getFieldDecorator(fieldName, {
              initialValue,
              rules: [
                {
                  required: required,
                  message: label && `${t('ENTER')} ${label.toLowerCase()}`
                }
              ]
            })(
              <Select
                mode={mode}
                placeholder={
                  placeholder || `${t('SELECT')} ${label.toLowerCase()}`
                }
                allowClear={!mode}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .includes(input.trim().toLowerCase())
                }
                disabled={disabled}
              >
                {events.map(item => (
                  <Select.Option key={item._id}>
                    {moment(item.date).format(DATE_FORMAT)} - {item.description}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
          <Tooltip title={t('ADD_NEW')}>
            <Button
              size="large"
              onClick={() => setEventModalVisibile(true)}
              className={`${label ? '' : 'no-label'}`}
              style={{ marginLeft: 8 }}
              icon="plus"
              disabled={disabled}
            />
          </Tooltip>
        </div>
      </FormItem>
      <AddEventModal
        setLinkedEvents={setLinkedEvents}
        linkedEvents={linkedEvents}
        visible={eventModalVisibile}
        setVisible={setEventModalVisibile}
        handleClose={() => {
          fetchEvents()
          setEventModalVisibile(false)
        }}
        onAddComplete={onAddComplete}
      />
    </div>
  )
}
