import React from 'react'
import { Popconfirm, Icon, Tooltip, message } from 'antd'
import api from '../../lib/api'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'

export default function DeleteCard(props) {
  const { customerId, cardId, fetchCustomer } = props
  const { t } = useTranslation()

  const confirm = async () => {
    try {
      const res = await api.deleteCard(customerId, cardId)
      const { data } = res
      if (data.message) {
        throw new Error(data.message)
      }
      fetchCustomer()
      message.success(t('SUCCESSFULLY_DELETED_CARD'))
    } catch (err) {
      message.error(err.message || t('FAILED_TO_DELETED_CARD'))
      onError(err)
    }
  }

  return (
    <Popconfirm
      title={t('ARE_YOU_SURE_TO_DELETE_THIS_CARD')}
      onConfirm={confirm}
      okText={t('YES')}
      cancelText={t('NO')}
      arrowPointAtCenter
      placement="bottomRight"
    >
      <Tooltip title={t('DELETE_CARD')} arrowPointAtCenter placement="topRight">
        <Icon theme="twoTone" twoToneColor="#eb4444" type="delete" />
      </Tooltip>
    </Popconfirm>
  )
}
