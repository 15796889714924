import React, { useState, useEffect, useContext } from 'react'
import { s3Get } from '../../lib/awsSDK'
import { decryptFilePromise } from '../../lib/crypto'
import VaultContext from '../../contexts/VaultContext'
import { Modal } from 'antd'
import { useSelector } from 'react-redux'
import noImage from '../../assets/no-image.png'

const ShowImage = ({
  recordId,
  width = 50,
  isListLocation = false,
  level = null
}) => {
  const { userId, masterKey, isReadonly } = useContext(VaultContext)
  const [imageUrl, setImageUrl] = useState('')
  const [showModalVisible, setShowModalVisible] = useState(false)
  const { locations } = useSelector(state =>
    isReadonly ? state.otherLocations : state.locations
  )

  useEffect(() => {
    const getUrl = async record => {
      if (level && record?.image?.length) {
        const img = record.image.find(img => img.level === level)
        if (img && img.fileId && img.sub) {
          const resBody = await s3Get(
            userId,
            img.fileId,
            { sub: img.sub },
            { responseType: 'blob' }
          )
          const uint8Array = await decryptFilePromise(resBody, masterKey)
          const blob = new Blob([uint8Array])
          const file = new File([blob], img.name, {
            type: img.type
          })
          setImageUrl(URL.createObjectURL(file))
        }
      } else {
        if (record?.fileId && record?.sub && record.image?.length) {
          const resBody = await s3Get(
            userId,
            record.fileId,
            { sub: record.sub },
            { responseType: 'blob' }
          )
          const uint8Array = await decryptFilePromise(resBody, masterKey)
          const blob = new Blob([uint8Array])
          const file = new File([blob], record.image[0].name, {
            type: record.image[0].type
          })
          setImageUrl(URL.createObjectURL(file))
        }
      }
    }

    const record = locations.find(item => item._id === recordId)
    getUrl(record)
  }, [userId, masterKey, locations, recordId, level])

  return (
    <>
      {imageUrl ? (
        <img
          style={{
            cursor: isListLocation ? 'pointer' : '',
            maxHeight: isListLocation ? '33px' : '',
            maxWidth: isListLocation ? width : ''
          }}
          width={isListLocation ? '' : width}
          src={imageUrl}
          alt="Location"
          onClick={() => isListLocation && setShowModalVisible(true)}
        />
      ) : (
        <img
          style={{ cursor: 'pointer' }}
          src={noImage}
          width={width}
          alt="Location"
          onClick={() => setShowModalVisible(true)}
        />
      )}
      <Modal
        visible={showModalVisible}
        width={750}
        footer={null}
        onCancel={() => setShowModalVisible(false)}
        centered
        style={{ display: 'inline-flex', justifyContent: 'center' }}
      >
        <img
          style={{ maxHeight: '80vh', maxWidth: '100%' }}
          src={imageUrl ? imageUrl : noImage}
          alt="Location"
        />
      </Modal>
    </>
  )
}

export default ShowImage
