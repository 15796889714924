import React, { useState, useEffect } from 'react'
import { Radio, Form, Alert } from 'antd'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import api from '../../lib/api'
import { onError } from '../../lib/sentry'
import { H3, P1 } from '../override/Typography'
import { useTranslation } from 'react-i18next'
import { AUTH_FLOW } from '../../share/Constants'

function AuthFlowType(props) {
  const {
    cognitoUser,
    authenticationDetails,
    authenticateUser,
    errMsg,
    setErrMsg,
    className,
    handleCancel,
    setSelectedOTPType,
    currentFlow,
    isChangedAuthFlow,
    setIsNone,
    setIsChangedAuthFlow
  } = props
  const [authFlow, setAuthFlow] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setAuthFlow(
      currentFlow === AUTH_FLOW.CUSTOM_FLOW
        ? AUTH_FLOW.CUSTOM_FLOW
        : currentFlow === AUTH_FLOW.MFA_FLOW
        ? AUTH_FLOW.MFA_FLOW
        : AUTH_FLOW.NONE
    )
  }, [currentFlow])

  useEffect(() => {
    if (setSelectedOTPType) setSelectedOTPType(authFlow)
  }, [authFlow, setSelectedOTPType])

  const handleOk = async () => {
    setIsSaving(true)
    try {
      if (authFlow === AUTH_FLOW.CUSTOM_FLOW || authFlow === AUTH_FLOW.NONE) {
        if (authFlow === AUTH_FLOW.CUSTOM_FLOW)
          cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')

        if (authFlow === AUTH_FLOW.NONE && setIsNone) {
          setIsNone(true)
        }
        if (isChangedAuthFlow) {
          cognitoUser.setUserMfaPreference(
            { PreferredMfa: false, Enabled: false },
            { PreferredMfa: false, Enabled: false },
            async function (err) {
              if (err) onError(err)
              else {
                await api.updateAuthFlow(
                  JSON.stringify({
                    userId: cognitoUser.username,
                    currentFlow: authFlow
                  })
                )

                await api.updateSetupMfaDate(
                  cognitoUser.username,
                  JSON.stringify({
                    setupMfaDate: Math.floor(new Date().getTime() / 1000)
                  })
                )
              }
            }
          )
        }
      } else {
        if (isChangedAuthFlow) {
          api
            .updateAuthFlow(
              JSON.stringify({
                userId: cognitoUser.username,
                currentFlow: authFlow
              })
            )
            .then(res => {})
        }
      }

      authenticateUser(authenticationDetails)
    } catch (e) {
      setErrMsg(t('FAILED_TO_UPDATE_AUTH_FLOW'))
      setIsSaving(false)
      onError(e)
    }
  }

  return (
    <>
      <Form className={className} layout="vertical">
        <div className="form-header">
          <H3> {t('AUTHENTiCATION_FLOW_TYPE')}</H3>
          <P1> {t('SELECT_A_TYPE_TO_SETUP_FLOW')} </P1>
        </div>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            afterClose={() => setErrMsg('')}
            style={{ marginBottom: 16 }}
          />
        )}
        <FormItem>
          <Radio.Group
            onChange={e => {
              setAuthFlow(e.target.value)
              if (setIsChangedAuthFlow) setIsChangedAuthFlow(currentFlow !== e.target.value)
            }}
            value={authFlow}
          >
            <Radio value={AUTH_FLOW.MFA_FLOW}>{t('MFA_CODE')}</Radio>
            <Radio value={AUTH_FLOW.CUSTOM_FLOW}>{t('EMAIL_CODE')}</Radio>
            <Radio value={AUTH_FLOW.NONE}>{t('None')}</Radio>
          </Radio.Group>
        </FormItem>
        <FormItem>
          <div className="steps-actions">
            <Button size="large" onClick={handleCancel}>
              {t(setIsChangedAuthFlow ? 'CANCEL' : 'BACK')}
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={handleOk}
              disabled={setIsChangedAuthFlow ? !isChangedAuthFlow : false}
              loading={!errMsg && isSaving}
            >
              {t('NEXT')}
            </Button>
          </div>
        </FormItem>
      </Form>
    </>
  )
}
const AuthFlowTypeForm = Form.create({ name: 'mfaForm' })(AuthFlowType)
export default AuthFlowTypeForm
