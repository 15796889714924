import React, { useEffect, useState } from 'react'
import { Modal, Form, Alert, Upload, Icon } from 'antd'
import { useTranslation } from 'react-i18next'
import Button from '../override/Button'
import { s3Get } from '../../lib/awsSDK'
import { toBase64 } from '../../share/helpers'
import { decryptFilePromise } from '../../lib/crypto'

const { Dragger } = Upload
function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const LevelPictureModal = props => {
  const {
    selectedLocation,
    setVisible,
    visible,
    levelPicture,
    setLevelPicture,
    setImageLevel1,
    setImageLevel2,
    setImageLevel3,
    urlLevel1,
    urlLevel2,
    urlLevel3,
    setUrlLevel1,
    setUrlLevel2,
    setUrlLevel3,
    userId,
    masterKey,
    form
  } = props
  const { getFieldDecorator } = form
  const { t } = useTranslation()
  const [errMsg, setErrMsg] = useState('')
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    const getUrl = async img => {
      const resBody = await s3Get(
        userId,
        img.fileId,
        { sub: img.sub },
        { responseType: 'blob' }
      )
      const uint8Array = await decryptFilePromise(resBody, masterKey)
      const blob = new Blob([uint8Array])
      const file = new File([blob], img.name, {
        type: img.type
      })
      return await toBase64(file)
    }

    const setUrl = async () => {
      const pictureLevel1 = selectedLocation.image?.find(img => img.level === 1)
      const pictureLevel2 = selectedLocation.image?.find(img => img.level === 2)
      const pictureLevel3 = selectedLocation.image?.find(img => img.level === 3)

      if (pictureLevel1) {
        const url = await getUrl(pictureLevel1)
        setUrlLevel1(url)
      }

      if (pictureLevel2) {
        const url = await getUrl(pictureLevel2)
        setUrlLevel2(url)
      }

      if (pictureLevel3) {
        const url = await getUrl(pictureLevel3)
        setUrlLevel3(url)
      }
    }

    if (selectedLocation) {
      setUrl()
    }
  }, [
    selectedLocation,
    setUrlLevel1,
    setUrlLevel2,
    setUrlLevel3,
    userId,
    masterKey
  ])

  useEffect(() => {
    switch (levelPicture) {
      case 1:
        setImageUrl(urlLevel1)
        break
      case 2:
        setImageUrl(urlLevel2)
        break
      case 3:
        setImageUrl(urlLevel3)
        break
      default:
        break
    }
  }, [levelPicture, urlLevel1, urlLevel2, urlLevel3])

  const handelSave = () => {
    form.validateFields(async (err, values) => {
      if (values.image) {
        switch (levelPicture) {
          case 1:
            setImageLevel1(values.image)
            break
          case 2:
            setImageLevel2(values.image)
            break
          case 3:
            setImageLevel3(values.image)
            break
          default:
            break
        }
      }
      form.resetFields()
      setLevelPicture('')
      setVisible(false)
    })
  }

  const handleCancel = () => {
    setErrMsg('')
    setVisible(false)
    form.resetFields()
    switch (levelPicture) {
      case 1:
        if (!selectedLocation.image?.find(img => img.level === 1)) {
          setUrlLevel1('')
          setImageLevel1('')
        }
        break
      case 2:
        if (!selectedLocation.image?.find(img => img.level === 2)) {
          setUrlLevel2('')
          setImageLevel2('')
        }
        break
      case 3:
        if (!selectedLocation.image?.find(img => img.level === 3)) {
          setUrlLevel3('')
          setImageLevel3('')
        }
        break
      default:
        break
    }
    setLevelPicture('')
  }

  const draggerProps = {
    beforeUpload: () => {
      // this is for preventing the upload action, so we can customize the upload flow and upload to S3 later when the form is saved
      return false
    },
    onChange: info => {
      if (info.file.status === 'removed') {
        setImageUrl('')
      } else if (
        info.file.type === 'image/jpeg' ||
        info.file.type === 'image/png'
      ) {
        getBase64(info.file, imageUrl => {
          setImageUrl(imageUrl)
          switch (levelPicture) {
            case 1:
              setUrlLevel1(imageUrl)
              break
            case 2:
              setUrlLevel2(imageUrl)
              break
            case 3:
              setUrlLevel3(imageUrl)
              break
            default:
              break
          }
        })
      }
    }
  }

  const normFile = e => {
    // Temporary only allow uploading a single file at once (the last selected file) for simplicity,
    // may consider adding support for uploading multiple files later
    if (Array.isArray(e)) {
      return e.slice(-1)
    }
    return e && e.fileList.slice(-1)
  }

  return (
    <>
      <Modal
        title={t('Picture') + ' level ' + levelPicture}
        visible={visible}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button key="cancle" onClick={handleCancel}>
            {t('CANCEL')}
          </Button>,
          <Button key="save" type="primary" onClick={handelSave}>
            {t('SAVE')}
          </Button>
        ]}
      >
        <Form>
          {errMsg && (
            <Alert
              type="error"
              message={errMsg}
              closable
              onClose={() => setErrMsg('')}
            />
          )}
          <Form.Item label="Image">
            {getFieldDecorator('image', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              rules: [
                {
                  validator: (rule, value, callback) => {
                    if (!value || !value.length) {
                      callback()
                      return
                    }

                    const fileType = value[0]?.originFileObj.type
                    const isJpgOrPng =
                      fileType === 'image/jpeg' || fileType === 'image/png'

                    if (!isJpgOrPng) {
                      callback('You can only upload JPG/PNG file!')
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(
              <Dragger {...draggerProps}>
                {imageUrl ? (
                  <img src={imageUrl} alt="Location" style={{ width: 300 }} />
                ) : (
                  <>
                    <p className="ant-upload-drag-icon">
                      <Icon type="upload" />
                    </p>
                    <p className="ant-upload-hint">
                      Click or drag file to this area to upload
                    </p>
                  </>
                )}
              </Dragger>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

const WrappedLevelPictureForm = Form.create({ name: 'LevelPictureModal' })(
  LevelPictureModal
)

export default WrappedLevelPictureForm
