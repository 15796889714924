import Asset, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class Cash extends Asset {
  /**
   * Construct a new Cash instance
   * @param {object} params - Cash creation options:
   * @param {string} [params.bank]
   * @param {string} [params.accountNumber]
   * @param {string} [params.accountType]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    currency,
    valuationDate,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    bank,
    accountNumber,
    accountType,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    status,
    events,
    passwords
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      currency,
      valuationDate,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords
    })

    this.subType = ASSET_TYPES.CASH
    this.bank = bank
    this.accountNumber = accountNumber
    this.percentageOwnership = 100
    this.accountType = accountType
  }
}

export default Cash

export const cashColumns = () => [
  ...assetColumns(),
  {
    key: 'bank',
    title: i18next.t('BANK')
  },
  {
    key: 'accountNumber',
    title: i18next.t('ACCOUNT_NUMBER')
  },
  {
    key: 'accountType',
    title: i18next.t('ACCOUNT_TYPE'),
    type: IMPORT_COL_TYPES.LIST
  },
  ...referenceColumns()
]
