import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from 'react'
import {
  Layout,
  Menu,
  Icon,
  Avatar,
  Tooltip,
  Popconfirm,
  Popover,
  Alert,
  message,
  Modal,
  Badge,
  Divider
} from 'antd'
import { Link, useParams } from 'react-router-dom'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import { destroyAllDbs } from '../../lib/pouchDb'
import SpeedDials from '../common/SpeedDials'
import AuthContext from '../../contexts/AuthContext'
import logo from '../../assets/logo.svg'
import logoSmall from '../../assets/logo-small.svg'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'
import Button from '../override/Button'
// import { P } from '../override/Typography'
import { loadRecords, reloadRecords } from '../../lib/pouchDb'
import { s3Get } from '../../lib/awsSDK'
// import {
//   fetchOtherAssetsLiabilities,
//   fetchOtherPendingAssetsLiabilities,
//   fetchOtherValuations
// } from '../../features/assets-liabilities/otherAssetsLiabilitiesSlice'
import {
  fetchOtherContacts
  // fetchOtherPendingContacts
} from '../../features/contacts/otherContactsSlice'
import {
  fetchOtherDocuments
  // fetchOtherPendingDocuments
} from '../../features/documents/otherDocumentsSlice'
// import { fetchUser } from '../../features/user/userSlice'
import {
  fetchBaseCurrency
  // fetchRates
} from '../../features/settings/settingsSlice'
import api, { clearApiInterceptor } from '../../lib/api'
import moment from 'moment'
import {
  setBreadcrumb,
  setShowStarredDocsOnly
} from '../../features/documents/documentsSlice'
import { updateCancelSchedule } from '../../features/payment/customerSlice'
//import { StringResources } from '../../share/StringResources'
import { useIdleTimer } from 'react-idle-timer'
// import { downloadExcelImportTemplate } from '../assets-liabilities/assetLiabilityHelpers'
import { getUserSession, isValidSession } from '../../lib/cognito'
import VaultContext from '../../contexts/VaultContext'
import { TourContextProvider } from '../../contexts/TourContext'
import { downloadAllDataAsZip } from '../../share/helpers'
import Overlay from '../override/Overlay'
import { AES, enc } from 'crypto-js'
import { storeReset } from '../../rootReducer'
import { onError, setSentryUser } from '../../lib/sentry'
import { debounce, uniq } from 'lodash'
// import SharesThresholdModal from '../deputy/SharesThresholdModal'
import { useTranslation, Trans } from 'react-i18next'
import { fetchAccessLevel } from './../../features/settings/settingsSlice'
import { fetchOtherCustomer } from './../../features/payment/otherCustomerSlice'
// import { ACCESS_LEVEL } from './../../share/Constants'
import { useSubscription, useMutation } from 'react-apollo-hooks'
import { onCreateByUserId } from '../../graphql/subscriptions'
import { deleteS3Change } from '../../graphql/mutations'
// import DisconectUserRequestModal from './../modals/DisconectUserRequestModal'
import EmailVerificationModal from '../../components/settings/EmailVerificationModal'
import CustomIcon from '../override/Icon'
import AddLogoModal from '../modals/AddLogoModal'
// import SubscriptionTrialEndModal from '../modals/SubscriptionTrialEndModal'
import CurrentFilesModal from './../modals/CurrentFilesModal'
import { fetchOtherEvents } from '../../features/events/otherEventsSlice'
import { fetchOtherLocations } from '../../features/location/otherLocationsSlice'
import { fetchOtherPasswords } from '../../features/passwords/otherPasswordsSlice'
import { fetchEvents } from '../../features/events/eventsSlice'
import { fetchLocations } from '../../features/location/LocationSlice'
import { fetchPasswords } from '../../features/passwords/PasswordSlice'
import { distributeRecoveryShareKey } from '../../features/deputies/assistantsSlice'
import { fetchCustomer } from '../../features/payment/customerSlice'
import { Elements, StripeProvider } from 'react-stripe-elements'
import config from '../../config'
import CardModal from '../payment/CardModal'

const { SubMenu } = Menu
const { Content, Sider } = Layout

const menuItem = (menuItem, onMenuItemClick) =>
  !menuItem.hidden && (
    <Menu.Item
      key={menuItem.url}
      className={`tour-menu-${menuItem.key}`}
      onClick={onMenuItemClick}
    >
      <Link to={menuItem.url}>
        {menuItem.isCustom ? (
          <CustomIcon type={menuItem.icon} />
        ) : (
          <Icon type={menuItem.icon} />
        )}
        <span>{menuItem.title}</span>
      </Link>
    </Menu.Item>
  )

const menu = (
  menuItems,
  withQuickAccess,
  location,
  history,
  dispatch,
  setTourModalVisible,
  isReadonly,
  isDownloading,
  handleDownloadVaultbox,
  onMenuItemClick
) => (
  <Menu
    className="tour-menu-step"
    selectedKeys={[(location && location.pathname) || '/']}
    mode="inline"
    style={{marginBottom:'10px'}}
  >
    {menuItems.map(item =>
      item.subMenu ? (
        <SubMenu
          className={`tour-menu-${item.key}`}
          key={item.url}
          title={
            <span>
              {item.isCustom ? (
                <CustomIcon type="vault" />
              ) : (
                <Icon type={item.icon} />
              )}
              <span>{item.title}</span>
            </span>
          }
          onTitleClick={({ key, domEvent }) => {
            if (
              !domEvent.target?.classList.contains('ant-menu-submenu-arrow')
            ) {
              if (key.indexOf('/files') === 0) dispatch(setBreadcrumb(''))
              history.push(key)
            }
          }}
          onClick={e => {
            if (e.key.indexOf('/files') === 0) {
              dispatch(setBreadcrumb(''))
              dispatch(setShowStarredDocsOnly(e.key.includes(`/starred`)))
            }
          }}
        >
          {item.subMenu.map(sub => menuItem(sub, onMenuItemClick))}
        </SubMenu>
      ) : (
        menuItem(item, onMenuItemClick)
      )
    )}
    {/* {withQuickAccess && (
      <SubMenu
        title={
          <span>
            <Icon type="double-right" />
            <span>
              <Trans i18nKey="QUICK_ACCESS"></Trans>
            </span>
          </span>
        }
        style={{ marginTop: 30 }}
        onClick={e => {
          if (e.key === 'download-import-template') {
            downloadExcelImportTemplate()
          } else if (e.key === 'vault-tour') {
            setTourModalVisible(true)
          }
        }}
      >
        <Menu.Item key="download-import-template">
          <Icon type="download" />
          <span>
            <Trans i18nKey="IMPORT_TEMPLATE"></Trans>
          </span>
        </Menu.Item>
        <Menu.Item key="vault-tour">
          <Icon type="star" />
          <span>
            <Trans i18nKey="VAULTBOX_TOUR"></Trans>
          </span>
        </Menu.Item>
      </SubMenu>
    )} */}
    {/* {isReadonly && (
      <Menu.Item
        style={{ marginTop: 30 }}
        key="download-bantex"
        onClick={handleDownloadVaultbox}
      >
        <Icon type={isDownloading ? 'loading' : 'download'} />
        <span>
          <Trans i18nKey="DOWNLOAD_VAULTBOX"></Trans>
        </span>
      </Menu.Item>
    )} */}
  </Menu>
)

const primaryMenuItems = (
  pendingAssetsLiabilities,
  pendingContacts,
  pendingDocuments,
  isPending = false
) => [
  // {
  //   title: isPending ? (
  //     <Badge
  //       className="custom-badge-size"
  //       style={{
  //         marginRight: -15
  //       }}
  //       count={pendingAssetsLiabilities?.length || 0}
  //     >
  //       <Trans i18nKey="REGISTRY"></Trans>
  //     </Badge>
  //   ) : (
  //     <Trans i18nKey="REGISTRY"></Trans>
  //   ),
  //   icon: 'home',
  //   url: '/',
  //   key: 'registry'
  // },
  {
    title: isPending ? (
      <Badge
        className="custom-badge-size"
        style={{ marginRight: -15 }}
        count={pendingDocuments?.length || 0}
      >
        <Trans i18nKey="FILES"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="FILES"></Trans>
    ),
    icon: 'file',
    url: `/files`,
    key: 'files',
    subMenu: [
      ...[
        {
          title: <Trans i18nKey="ALL_FILES"></Trans>,
          icon: 'folder',
          url: `/files`,
          key: 'allFiles'
        },
        // {
        //   title: <Trans i18nKey="Vault"></Trans>,
        //   icon: 'vault',
        //   url: `/files/vault`,
        //   key: 'vault',
        //   isCustom: true
        // },
        {
          title: <Trans i18nKey="FAVOURITES"></Trans>,
          icon: 'star',
          url: `/files/starred`,
          key: 'starred'
        }
      ]
    ]
  },
  {
    title: <Trans i18nKey="CONTACTS"></Trans>,
    icon: 'contacts',
    url: '/contacts',
    key: 'contacts'
  },
  // {
  //   title: <Trans i18nKey="DEPUTIES"></Trans>,
  //   icon: 'user',
  //   url: '/deputy',
  //   key: 'deputies'
  // },
  {
    title: <Trans i18nKey="User Management"></Trans>,
    icon: 'user',
    url: '/users'
  },
  {
    title: <Trans i18nKey="PASSWORDS"></Trans>,
    icon: 'lock',
    url: '/password'
  },
  {
    title: <Trans i18nKey="CALENDAR"></Trans>,
    icon: 'calendar',
    url: '/calendar'
  },
  {
    title: <Trans i18nKey="Location"></Trans>,
    icon: 'environment',
    url: '/location',
    key: 'location'
  },
  {
    title: <Trans i18nKey="TRASH"></Trans>,
    icon: 'delete',
    url: '/trash',
    key: 'trash'
  },
  {
    title: <Trans i18nKey="SETTINGS"></Trans>,
    icon: 'setting',
    url: '/settings',
    key: 'settings'
  },
]

const deputyOnlyMenuItems = [
  {
    title: <Trans i18nKey="DEPUTIES"></Trans>,
    icon: 'user',
    url: '/deputy'
  }
]

const sharedMenuItem = {
  title: <Trans i18nKey="Shared data"></Trans>,
  icon: 'share-alt',
  url: '/shared',
  key: 'shared'
}

const otherMenuItems = (
  userId,
  pendingAssetsLiabilities,
  pendingContacts,
  pendingDocuments,
  isShowPending = false
) => [
  // {
  //   title: isShowPending ? (
  //     <Badge
  //       className="custom-badge-size"
  //       style={{ marginRight: -15 }}
  //       count={pendingAssetsLiabilities?.length || 0}
  //     >
  //       <Trans i18nKey="REGISTRY"></Trans>
  //     </Badge>
  //   ) : (
  //     <Trans i18nKey="REGISTRY"></Trans>
  //   ),
  //   icon: 'home',
  //   url: `/${userId}`
  // },
  {
    title: isShowPending ? (
      <Badge
        className="custom-badge-size"
        style={{ marginRight: -15 }}
        count={pendingDocuments?.length || 0}
      >
        <Trans i18nKey="FILES"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="FILES"></Trans>
    ),
    icon: 'file',
    url: `/files/${userId}`,
    subMenu: [
      ...[
        {
          title: <Trans i18nKey="FAVOURITES"></Trans>,
          icon: 'star',
          url: `/files/${userId}/starred`
        },
        {
          title: <Trans i18nKey="ALL_FILES"></Trans>,
          icon: 'folder',
          url: `/files/${userId}`
        }
      ],
      ...(isShowPending
        ? [
            {
              title: isShowPending ? (
                <Badge
                  className="custom-badge-size"
                  style={{ marginRight: -15 }}
                  count={pendingDocuments?.length || 0}
                >
                  <Trans i18nKey="PENDING"></Trans>
                </Badge>
              ) : (
                <Trans i18nKey="PENDING"></Trans>
              ),
              icon: 'question-circle',
              url: `/files/${userId}/pending`,
              key: 'pending'
            },
            {
              title: <Trans i18nKey="REJECTED"></Trans>,
              icon: 'close-circle',
              url: `/files/${userId}/rejected`,
              key: 'rejected'
            }
          ]
        : [])
    ]
  },
  {
    title: isShowPending ? (
      <Badge
        className="custom-badge-size"
        style={{ marginRight: -15 }}
        count={pendingContacts?.length || 0}
      >
        <Trans i18nKey="CONTACTS"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="CONTACTS"></Trans>
    ),
    icon: 'contacts',
    url: `/contacts/${userId}`
  },
  {
    title: <Trans i18nKey="PASSWORDS"></Trans>,
    icon: 'lock',
    url: `/password/${userId}`
  },
  {
    title: <Trans i18nKey="CALENDAR"></Trans>,
    icon: 'calendar',
    url: `/calendar/${userId}`
  },
  {
    title: <Trans i18nKey="Location"></Trans>,
    icon: 'environment',
    url: `/location/${userId}`,
    key: 'location'
  }
  // {
  //   title: <Trans i18nKey="TRASH"></Trans>,
  //   icon: 'delete',
  //   url: `/trash/${userId}`
  // }
]

function broadcastTimerStatus(isTimerActive) {
  localStorage.setItem('isTimerActive', isTimerActive)
  localStorage.removeItem('isTimerActive')
}

var timeoutModal

export default function AuthLayout({ children, isPrimaryRoute, ...rest }) {
  const { resetAppStates, location, history } = rest
  const { user, isDeputyOnly, isProfessionalDeputy } = useContext(AuthContext)
  const theme = useContext(ThemeContext)

  const [recordIds, setRecordIds] = useState([])
  const [permissions, setPermissions] = useState([])
  const [fullName, setFullName] = useState('')
  const [emailVerified, setEmailVerified] = useState('')
  // const [primaryUserEmail, setPrimaryUserEmail] = useState('')
  const [primaryUserFullName, setPrimaryUserFullName] = useState('')
  // const [isHidingDeputiesReminder, setIsHidingDeputiesReminder] =
  //   useState(false)
  const [siderCollapsed, setSiderCollapsed] = useState(false)
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [isEditAvatarMode, setIsEditAvatarMode] = useState(false)
  const { t } = useTranslation()

  const { deputies } = useSelector(state => state.deputies)
  // const { hideDeputiesSetupReminder, sharesThreshold } = useSelector(
  //   state => state.user
  // ).user
  const { customer, subscription } = useSelector(state => state.customer)

  //const { baseCurrency, accessLevel } = useSelector(state => state.settings)
  const [tourRun, setTourRun] = useState(false)
  const [tourModalVisible, setTourModalVisible] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [masterKey, setMasterKey] = useState('')
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [addLogoModalVisible, setaddLogoModalVisible] = useState(false)
  // const [trialEndModalVisible, setTrialEndModalVisible] = useState(false)
  const [curentFilesModalVisible, setCurentFilesModalVisible] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [file, setFile] = useState('')
  const [isSharedData, setIsSharedData] = useState(false)
  // const [sharesThresholdModalVisible, setSharesThresholdModalVisible] =
  //   useState(false)

  // const [disconectUserModalVisible, setDisconectUserModalVisible] =
  //   useState(false)
  const [planId, setPlanId] = useState('')
  const [appliedPromo, setAppliedPromo] = useState()

  const [cardModalVisible, setCardModalVisible] = useState(false)
  const dispatch = useDispatch()
  let { userId } = useParams()
  const isValidUserId = !!localStorage.getItem(userId)
  const isFirstRender = useRef(true)
  const { data } = useSubscription(onCreateByUserId, {
    variables: {
      userId: userId
    }
  })

  const [removeS3Change] = useMutation(deleteS3Change)

  // Context readonly access: deputy accessing primary files => the userId taken from params (primary) is different from id of current login user (deputy)
  const isReadonly = userId && user.username !== userId && isValidUserId
  // when userId is not specified in the params, need to get it from current login user instead
  userId = userId || user.username
  const { pendingAssetsLiabilities } = useSelector(state =>
    isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { pendingContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )

  const { pendingDocuments, usedStorage, documents } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )

  const { activeContacts } = useSelector(state => state.otherContacts)
  const { activeAssetsLiabilities } = useSelector(
    state => state.otherAssetsLiabilities
  )
  const { activeFolders, activeFiles } = useSelector(
    state => state.otherDocuments
  )
  const { activeLocations } = useSelector(state =>
    isReadonly ? state.otherLocations : state.locations
  )

  const { activePasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )

  const { activeEvents } = useSelector(state =>
    isReadonly ? state.otherEvents : state.events
  )

  const { assistants } = useSelector(state => state.assistants)
  // const showReminder =
  //   deputies.length < 2 &&
  //   !hideDeputiesSetupReminder &&
  //   !localStorage.getItem('hideDeputiesSetupReminder')

  // const acceptedDeputies = deputies.filter(d => d.publicKey) || []

  // const hasDisconectPDRequest = acceptedDeputies.some(d => d.pendingDisconect)

  const handleDownloadVaultbox = async data => {
    try {
      setIsDownloading(true)
      await downloadAllDataAsZip(
        userId,
        activeAssetsLiabilities,
        activeContacts,
        activeFiles,
        activeFolders,
        masterKey,
        isReadonly ? primaryUserFullName : fullName
      )
      setIsDownloading(false)
    } catch (error) {
      message.error(t('FAILED_TO_DOWNLOAD_VAULTBOX'))
      onError(error)
    }
  }

  const handleOnIdle = () => {
    user.signOut()
    destroyAllDbs()
    localStorage.clear()

    if (!timeoutModal) {
      timeoutModal = Modal.info({
        title: t('SESSION_TIMEOUT'),
        content: t('SESSION_TIMEOUT_SUMMARY'),
        keyboard: false,
        onOk() {
          Modal.destroyAll()
          window.location.reload()
        }
      })
    }
  }

  const handleOnAction = e => {
    broadcastTimerStatus(true)
  }

  const { reset } = useIdleTimer({
    timeout: 30 * 60 * 1000,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 2000
  })

  const receiveTimerStatus = useCallback(
    event => {
      if (event.key !== 'isTimerActive') return
      if (event.newValue === 'true') {
        reset()
      }
    },
    [reset]
  )

  useEffect(() => {
    // automatically distribute share keys if an accepted assistant exists without shareKey
    const handleDistributeShareKeys = async () => {
      const acceptedAssistants = assistants.filter(
        assistant => assistant.publicKey && !assistant.shareKey
      )
      const assistantEmails = acceptedAssistants.map(d => d.email)
      const res = await api.getUsersByEmails(assistantEmails.join(','))
      const assistantInfo = res?.data?.map(d => {
        return {
          userId: d.id,
          email: d.email,
          publicKey: d.publicKey
        }
      })

      dispatch(
        distributeRecoveryShareKey(user.username, masterKey, assistantInfo)
      )
    }

    if (
      !isReadonly &&
      assistants?.length &&
      assistants?.find(d => d.publicKey && !d.shareKey)
    ) {
      handleDistributeShareKeys()
    }
  }, [assistants, dispatch, isReadonly, masterKey, user])

  useEffect(() => {
    const getSharedData = async () => {
      try {
        const res = await api.getDeputies(userId)
        const { deputies } = res.data

        const data = deputies?.find(sd => sd.id === user.username)?.records

        let sharedRecords = []
        const convertArrToObj = (arr = []) => {
          arr.forEach(e => {
            if (e.linkedItems?.length) {
              sharedRecords.push({
                id: e.id,
                permission: e.permission
              })
              convertArrToObj(e.linkedItems)
            } else sharedRecords.push(e)
          })
        }

        convertArrToObj(data)

        let subItemsShared = []

        const allRecords =
          sharedRecords
            ?.map(r => {
              const item = documents.find(d => d._id === r.id)
              const parentItems = documents.filter(
                d => !d.fileName && item?.path?.indexOf(d.path) === 0
              )

              const subItems = documents
                .filter(
                  d =>
                    d.path.indexOf(item?.path) === 0 &&
                    (item.fileId ? item.fileName === d.fileName : true)
                )
                .map(item => {
                  const existedItem = sharedRecords.find(
                    sr => sr.id === item._id && sr.id !== r.id
                  )
                  if (existedItem)
                    subItemsShared.push({
                      ...existedItem,
                      path: item.path
                    })

                  return {
                    ...item,
                    permission: existedItem
                      ? existedItem.permission
                      : r.permission
                  }
                })

              const locationRecords = activeLocations
                .filter(c => c._id === r.id)
                .map(c => {
                  return {
                    ...c,
                    permission: r.permission
                  }
                })
              const passwordRecords = activePasswords
                .filter(c => c._id === r.id)
                .map(c => {
                  return {
                    ...c,
                    permission: r.permission
                  }
                })

              const eventRecords = activeEvents
                .filter(c => c._id === r.id)
                .map(c => {
                  return {
                    ...c,
                    permission: r.permission
                  }
                })

              const contactRecords = activeContacts
                .filter(c => c._id === r.id)
                .map(c => {
                  return {
                    ...c,
                    permission: r.permission
                  }
                })

              return contactRecords?.length
                ? contactRecords
                : eventRecords?.length
                ? eventRecords
                : locationRecords?.length
                ? locationRecords
                : passwordRecords?.length
                ? passwordRecords
                : uniq([...parentItems, ...subItems])
            })
            .flat() || []

        setPermissions(
          allRecords.map(ar => {
            const updatedSubItem = subItemsShared.find(
              sis => ar.path?.indexOf(sis?.path) === 0
            )

            return {
              id: ar._id,
              permission: updatedSubItem
                ? updatedSubItem.permission
                : ar.permission
            }
          })
        )
        setRecordIds(allRecords.map(r => r._id))
      } catch (err) {
        onError(err.message)
      }
    }

    if (isReadonly) {
      getSharedData()
    } else {
      setRecordIds([])
      setPermissions([])
    }
  }, [
    isReadonly,
    userId,
    user,
    documents,
    // history,
    activeContacts,
    activeLocations,
    activePasswords,
    activeEvents,
    deputies
  ])

  useEffect(() => {
    if (isDeputyOnly && usedStorage > 2147483648) {
      setCurentFilesModalVisible(true)
    } else {
      setCurentFilesModalVisible(false)
    }
  }, [isDeputyOnly, usedStorage])
  // useEffect(() => {
  //   setDisconectUserModalVisible(hasDisconectPDRequest)
  // }, [hasDisconectPDRequest])
  const getLogo = useCallback(async () => {
    try {
      const rs = await api.getAvatar(userId)
      if (rs.data?.avatar?.fileId && rs.data.avatar.sub) {
        const resBody = await s3Get(
          userId,
          `avatar/${rs.data.avatar.fileId}`,
          { sub: rs.data.avatar.sub },
          { responseType: 'blob' }
        )

        const file = new File([resBody], rs.data.avatar.name, {
          type: rs.data.avatar.type
        })
        setFile(file)
        setImageUrl(URL.createObjectURL(file))
      }
    } catch (err) {
      onError(err)
    }
  }, [userId])

  useEffect(() => {
    if (!isReadonly) getLogo()
  }, [getLogo, isReadonly])

  useEffect(() => {
    setIsSmallScreen(window.innerWidth < 992)

    const debouncedSetIsSmallScreen = debounce(() => {
      setIsSmallScreen(window.innerWidth < 992)
    }, 300)

    window.addEventListener('resize', debouncedSetIsSmallScreen)
    return () => {
      window.removeEventListener('resize', debouncedSetIsSmallScreen)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('storage', receiveTimerStatus)
    return () => {
      window.removeEventListener('storage', receiveTimerStatus)
    }
  }, [receiveTimerStatus])

  useEffect(() => {
    if (!user || !user.getUserData) {
      return
    }

    getUserData(
      user,
      (err, data) => {
        if (err) {
          console.log(`${t('FAILE_TO_GET_USER_DATA')}: `, err)
          onError(err)
          return
        }

        const userFullName = getUserAttributeValue(
          data.UserAttributes,
          'custom:full_name'
        )
        const email = getUserAttributeValue(data.UserAttributes, 'email')
        const email_verified = getUserAttributeValue(
          data.UserAttributes,
          'email_verified'
        )
        const plan = getUserAttributeValue(
          data.UserAttributes,
          'custom:plan_id'
        )
        const appliedPromo = getUserAttributeValue(
          data.UserAttributes,
          'custom:applied_promo'
        )
        setAppliedPromo(appliedPromo)
        setPlanId(plan)
        setFullName(userFullName)
        setEmailVerified(email_verified)
        setSentryUser({ id: user.username, username: user.username, email })
      },
      { bypassCache: true }
    )

    const onWindowFocus = () =>
      getUserSession(user, (err, session) => {
        if (err || !isValidSession(session)) {
          user.signOut()
          destroyAllDbs()
          localStorage.clear()

          if (!timeoutModal) {
            timeoutModal = Modal.info({
              title: t('SESSION_TIMEOUT'),
              content: t('SESSION_TIMEOUT_SUMMARY'),
              onOk() {
                Modal.destroyAll()
                window.location.reload()
              }
            })
          }
        }
      })

    window.addEventListener('focus', onWindowFocus)
    return () => {
      window.removeEventListener('focus', onWindowFocus)
    }
  }, [user, t])
  // when user signout on 1 tab, other tabs also listen to the accessToken change in localStorage to signout as well
  useEffect(() => {
    if (!user?.username) {
      return
    }

    const handleInvalidToken = e => {
      const accessTokenKey = `CognitoIdentityServiceProvider.${user.pool.getClientId()}.${
        user.username
      }.accessToken`

      if (e.key === accessTokenKey && e.oldValue && !e.newValue) {
        user.signOut()
        resetAppStates()
        dispatch(storeReset())
        destroyAllDbs()
        localStorage.clear()
        clearApiInterceptor()
      }
    }

    window.addEventListener('storage', handleInvalidToken)
    return () => {
      window.removeEventListener('storage', handleInvalidToken)
    }
  }, [user, resetAppStates, dispatch])

  const initOtherDbs = useCallback(
    async (userId, masterKey) => {
      try {
        await Promise.all([
          // loadRecords('assetsLiabilities', userId, masterKey),
          loadRecords('contacts', userId, masterKey),
          loadRecords('documents', userId, masterKey),
          loadRecords('events', userId, masterKey),
          loadRecords('passwords', userId, masterKey),
          loadRecords('locations', userId, masterKey)
          // loadRecords('pendingAssetsLiabilities', userId, masterKey),
          // loadRecords('pendingContacts', userId, masterKey)
          // loadRecords('assetsLiabilitiesValuations', userId, masterKey),
          // loadRecords('pendingDocuments', userId, masterKey)
          // loadRecords('assetsLiabilitiesHistory', userId, masterKey)
        ])

        // dispatch(fetchOtherAssetsLiabilities(userId, masterKey))
        // dispatch(fetchOtherPendingAssetsLiabilities(userId, masterKey))
        // dispatch(fetchOtherPendingContacts(userId, masterKey))
        dispatch(fetchOtherEvents(userId, masterKey))
        dispatch(fetchOtherLocations(userId, masterKey))
        dispatch(fetchOtherPasswords(userId, masterKey))
        dispatch(fetchOtherContacts(userId, masterKey))
        dispatch(fetchOtherDocuments(userId, masterKey))
        dispatch(fetchBaseCurrency(userId))
        dispatch(fetchAccessLevel(userId))
        // dispatch(fetchRates(userId, isProfessionalDeputy))
        // dispatch(fetchOtherValuations(userId, masterKey))
        // dispatch(fetchOtherPendingDocuments(userId, masterKey))
        dispatch(fetchOtherCustomer(userId))
      } catch (e) {
        onError(e)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    const fetchDataFunction = async (dbName, userId, masterKey) => {
      switch (dbName) {
        // case 'assetsLiabilities':
        //   return dispatch(fetchOtherAssetsLiabilities(userId, masterKey))
        // case 'assetsLiabilitiesValuations':
        //   return dispatch(fetchOtherValuations(userId, masterKey))
        case 'contacts':
          return dispatch(fetchOtherContacts(userId, masterKey))
        case 'documents':
          return dispatch(fetchOtherDocuments(userId, masterKey))
        // case 'pendingAssetsLiabilities':
        //   return dispatch(fetchOtherPendingAssetsLiabilities(userId, masterKey))
        // case 'pendingContacts':
        //   return dispatch(fetchOtherPendingContacts(userId, masterKey))
        // case 'pendingDocuments':
        //   return dispatch(fetchOtherPendingDocuments(userId, masterKey))
        case 'events':
          return dispatch(fetchEvents(userId, masterKey))
        case 'locations':
          return dispatch(fetchLocations(userId, masterKey))
        case 'passwords':
          return dispatch(fetchPasswords(userId, masterKey))
        default:
          return null
      }
    }

    if (isProfessionalDeputy !== undefined) {
      api
        .getPrimaryUsers(user.username)
        .then(response => {
          if (response.data && response.data.length) {
            const primaryUsers = response.data
            setIsSharedData(true)
            const currentPrimaryUser = primaryUsers.find(
              user => user.key === userId
            )
            if (currentPrimaryUser) {
              // setPrimaryUserEmail(currentPrimaryUser.email)
              setPrimaryUserFullName(currentPrimaryUser.fullName)
              // setImageUrl(currentPrimaryUser.avatar)
            }
          }
        })
        .catch(err => {
          console.log(`${t('FAILE_TO_GET_PRIMARY_USERS')}: `, err)
          onError(err)
        })

      api
        .getUser(user.username)
        .then(async res => {
          const { extraKey } = res.data
          const encryptedKey = localStorage.getItem(userId)
          const masterKey = AES.decrypt(encryptedKey, extraKey).toString(
            enc.Latin1
          )
          setMasterKey(masterKey)
          const isReadonly =
            userId && user.username !== userId && !!localStorage.getItem(userId)
          if (isReadonly && masterKey) {
            await initOtherDbs(userId, masterKey)
          }

          if (data?.onCreateByUserId?.userId === userId && isReadonly) {
            const isNotReload = localStorage.getItem('NotReload')
            if (isNotReload === 'true') {
              localStorage.setItem('NotReload', false)
            } else {
              const dbsReload = data?.onCreateByUserId?.message.split(', ')

              await Promise.all(
                dbsReload.map(dbName =>
                  reloadRecords(dbName, userId, masterKey)
                )
              )
              await Promise.all(
                dbsReload.map(dbName =>
                  fetchDataFunction(dbName, userId, masterKey)
                )
              )

              if (data?.onCreateByUserId?.id) {
                await removeS3Change({
                  variables: { id: data?.onCreateByUserId?.id }
                })
              }
            }
          }
        })
        .catch(err => {
          onError(err)
        })
    }
  }, [
    user,
    userId,
    isProfessionalDeputy,
    initOtherDbs,
    t,
    data,
    dispatch,
    removeS3Change
  ])

  const handleComplateAddCard = useCallback(async () => {
    try {
      const createData = {
        userId: user.username,
        customerId: customer?.id,
        items: [{ plan: planId }],
        coupon: appliedPromo || undefined
      }
      await api.createSubscription(JSON.stringify(createData))
      dispatch(fetchCustomer(user.username))
    } catch (error) {
      onError(error)
    }
  }, [appliedPromo, customer, user, dispatch, planId])

  useEffect(() => {
    if (
      customer?.id &&
      !subscription?.id &&
      !isFirstRender.current &&
      planId !== 'free' &&
      !isDeputyOnly
    ) {
      setCardModalVisible(true)
    } else {
      isFirstRender.current = false
      setCardModalVisible(false)
    }
  }, [
    customer,
    subscription,
    planId,
    // mainUserId,
    handleComplateAddCard,
    isDeputyOnly
  ])

  const handleSignOut = () => {
    if (user != null) {
      user.signOut()
    }
    resetAppStates()
    dispatch(storeReset())
    destroyAllDbs()
    localStorage.clear()
    clearApiInterceptor()
  }

  const getSharedMenu = (menu, item) => {
    menu.splice(2, 0, item)
    return menu
  }

  const disableAccessOfDeputy = (event, disableClick) => {
    if (isPrimaryRoute && disableClick) {
      event.target.addEventListener('click', function (e) {
        e.stopPropagation()
        e.preventDefault()
      })
    }
    return
  }

  const onMenuItemClick = () => {
    if (isSmallScreen) setSiderCollapsed(true)
  }

  return (
    <VaultContext.Provider
      value={{
        userId,
        isReadonly,
        masterKey,
        fullName: !isReadonly ? fullName : primaryUserFullName,
        recordIds,
        permissions
      }}
    >
      <TourContextProvider
        {...{
          history,
          tourModalVisible,
          setTourModalVisible,
          tourRun,
          setTourRun,
          setSiderCollapsed
        }}
      >
        <Layout
          style={{ minHeight: '100vh' }}
          className={`page-wrapper very-first-step ${
            siderCollapsed && isSmallScreen ? 'small-screen' : ''
          }`}
        >
          <Sider
            className="main-sider"
            style={{ paddingBottom: 80 }}
            collapsible={!isSmallScreen}
            breakpoint="xl"
            onCollapse={setSiderCollapsed}
            collapsed={siderCollapsed}
            collapsedWidth={isSmallScreen ? 0 : 80}
            width={240}
          >
            <div className={siderCollapsed ? 'logo-small' : 'logo'}>
              <Link to="/">
                <img
                  src={siderCollapsed ? logoSmall : logo}
                  alt="bantex logo"
                  // style={{ height: 30 }}
                />
              </Link>
            </div>
            <div className="profile">
              {!siderCollapsed &&
                (imageUrl ? (
                  <Popover
                    visible={popoverVisible}
                    placement="bottomLeft"
                    content={
                      <>
                        <Button
                          onClick={() => {
                            setaddLogoModalVisible(true)
                            setPopoverVisible(false)
                          }}
                          type="link"
                          size="small"
                        >
                          <Icon type="upload" /> Upload
                        </Button>
                        <Divider type="vertical" />
                        <Button
                          onClick={() => {
                            setaddLogoModalVisible(true)
                            setPopoverVisible(false)
                            setIsEditAvatarMode(true)
                          }}
                          type="link"
                          size="small"
                        >
                          <Icon type="edit" /> Edit
                        </Button>
                      </>
                    }
                    trigger="click"
                  >
                    <Avatar
                      style={{ background: '#fff', cursor: 'pointer' }}
                      onClick={() => setPopoverVisible(true)}
                      src={imageUrl}
                      icon="user"
                      size={54}
                    />
                  </Popover>
                ) : (
                  <Avatar
                    onClick={() => setaddLogoModalVisible(true)}
                    style={{ cursor: 'pointer' }}
                    icon="user"
                    size={54}
                  />
                ))}
              <div>
                {siderCollapsed ? (
                  <div style={{ marginBottom: 15, textAlign: 'center' }}>
                    <Tooltip title={fullName}>
                      {imageUrl ? (
                        <Popover
                          visible={popoverVisible}
                          placement="bottomLeft"
                          content={
                            <>
                              <Button
                                onClick={() => {
                                  setaddLogoModalVisible(true)
                                  setPopoverVisible(false)
                                }}
                                type="link"
                                size="small"
                              >
                                <Icon type="upload" /> Upload
                              </Button>
                              <Divider type="vertical" />
                              <Button
                                onClick={() => {
                                  setaddLogoModalVisible(true)
                                  setPopoverVisible(false)
                                  setIsEditAvatarMode(true)
                                }}
                                type="link"
                                size="small"
                              >
                                <Icon type="edit" /> Edit
                              </Button>
                            </>
                          }
                          trigger="click"
                        >
                          <Avatar
                            style={{ background: '#fff', cursor: 'pointer' }}
                            onClick={() => setPopoverVisible(true)}
                            src={imageUrl}
                            icon="user"
                            // size={54}
                          />
                        </Popover>
                      ) : (
                        <Avatar
                          onClick={() => setaddLogoModalVisible(true)}
                          icon="user"
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </Tooltip>
                  </div>
                ) : (
                  <h4>{fullName}</h4>
                )}
                <div className="profile-links">
                  <Tooltip title={t('SETTINGS')}>
                    <Icon
                      type="setting"
                      onClick={() => history.push('/settings')}
                    />
                  </Tooltip>
                  <Popconfirm
                    title={t('ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT')}
                    onConfirm={handleSignOut}
                    okText={t('YES')}
                    cancelText={t('CANCEL')}
                    placement="right"
                  >
                    <Tooltip title={t('SIGN_OUT')}>
                      <Icon type="logout" />
                    </Tooltip>
                  </Popconfirm>
                </div>
              </div>
            </div>
            {isReadonly && (
              <>
                <div style={{ paddingLeft: 10 }}>
                  <Tooltip
                    title={t('Back to my page')}
                    placement="topLeft"
                    arrowPointAtCenter
                  >
                    <span
                      style={{
                        padding: '0 10px',
                        color: '#fff',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        history.push('/')
                        dispatch(setBreadcrumb(''))
                      }}
                    >
                      <Icon type="arrow-left" />
                    </span>
                  </Tooltip>
                  Viewing of: {primaryUserFullName}
                </div>
                {/* <div style={{ paddingLeft: 20, marginBottom: 30 }}>
               
                </div> */}
              </>
            )}

            {menu(
              isReadonly
                ? otherMenuItems(
                    userId,
                    pendingAssetsLiabilities,
                    pendingContacts,
                    pendingDocuments
                    // isProfessionalDeputy &&
                    //   accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                  )
                : isProfessionalDeputy
                ? deputyOnlyMenuItems
                : isSharedData
                ? getSharedMenu(
                    primaryMenuItems(
                      pendingAssetsLiabilities,
                      pendingContacts,
                      pendingDocuments
                      //accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                    ),
                    sharedMenuItem
                  )
                : primaryMenuItems(
                    pendingAssetsLiabilities,
                    pendingContacts,
                    pendingDocuments
                    //accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                  ),
              !isReadonly && !isProfessionalDeputy,
              location,
              history,
              dispatch,
              setTourModalVisible,
              isReadonly,
              isDownloading,
              handleDownloadVaultbox,
              onMenuItemClick
            )}
            <Popconfirm
              title={t('ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT')}
              onConfirm={handleSignOut}
              okText={t('YES')}
              cancelText={t('CANCEL')}
              placement="right"
            >
              <div style={{paddingLeft: '24px', display:'inline',cursor: 'pointer' }}>
                <Icon type={'logout'} style={{marginRight: '10px'}}/>
                <span>{t('SIGN_OUT')}</span>
              </div>
            </Popconfirm>
            <div className="sider-footer">
              {siderCollapsed ? (
                <Menu mode="inline" style={{ margin: '0 -20px' }}>
                  <SubMenu title={<Icon type="ellipsis" />}>
                    <Menu.Item>
                      <a
                        href="https://www.bantexapp.com/about-us"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('ABOUT_US')}
                      </a>
                    </Menu.Item>
                    <Menu.Item>
                      <a
                        href="mailto:support@bantexapp.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('HELP')}
                      </a>
                    </Menu.Item>
                    <Menu.Item>
                      <a
                        href="https://www.bantexapp.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('PRIVACY')}
                      </a>
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              ) : (
                <div className="sider-footer-links">
                  <span>
                    <a
                      href="https://www.bantexapp.com/about-us"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('ABOUT_US')}
                    </a>
                  </span>
                  <span>&bull;</span>
                  <span>
                    <a
                      href="mailto:support@bantexapp.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('HELP')}
                    </a>
                  </span>
                  <span>&bull;</span>
                  <span>
                    <a
                      href="https://www.bantexapp.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('PRIVACY')}
                    </a>
                  </span>
                </div>
              )}
              <div>
                &copy;{new Date().getFullYear()}{' '}
                <b>Bino Digital Solutions Pte Ltd</b>
              </div>
            </div>
          </Sider>
          <Layout
            style={{
              backgroundColor: theme.white,
              overflow: `${!tourRun && 'hidden'}`,
              overflowX: isSmallScreen ? 'auto' : '',
              position: 'relative'
            }}
          >
            {(history?.location?.pathname === '/' ||
              history?.location?.pathname === '/files' ||
              history?.location?.pathname === '/files/starred') &&
              ((subscription.trial_end < moment(new Date()).unix() &&
                !customer.default_source) ||
                isDeputyOnly) &&
              usedStorage > 2147483648 && <Overlay />}
            <Content
              className="main-content"
              style={{
                pointerEvents:
                  isDeputyOnly && isPrimaryRoute && !tourRun && 'none',
                minWidth: 280
              }}
              onMouseDown={event =>
                disableAccessOfDeputy(
                  event,
                  isDeputyOnly && isPrimaryRoute && !tourRun
                )
              }
            >
              <div style={{ margin: '0 20px' }}>
                {/* {showReminder && !isReadonly && !isDeputyOnly && (
                  <Alert
                    style={{
                      margin: '10px 0',
                      backgroundColor: theme.redMinus10
                    }}
                    description={
                      <div className="appoint-deputies-alert">
                        <P style={{ flexGrow: 1 }} color={theme.red}>
                          <Trans i18nKey="PLEASE_APPOINT_YOUR_DEPUTIES"></Trans>
                        </P>
                        <div className="appoint-deputies-alert-actions">
                          <Button
                            onClick={() => history.push('/deputy')}
                            type="primary"
                          >
                            {t('APPOINT_DEPUTIES_NOW')}
                          </Button>
                          <Button
                            onClick={e => {
                              localStorage.setItem(
                                'hideDeputiesSetupReminder',
                                true
                              )
                              dispatch(fetchUser(user.username))
                            }}
                          >
                            {t('REMIND_ME_LATER')}
                          </Button>
                          <Button
                            onClick={e => {
                              setIsHidingDeputiesReminder(true)
                              api
                                .hideDeputiesSetupReminder(user.username)
                                .then(res => dispatch(fetchUser(user.username)))
                                .catch(err => onError(err))
                                .finally(() => {
                                  setIsHidingDeputiesReminder(false)
                                })
                            }}
                            loading={isHidingDeputiesReminder}
                          >
                            {t('DO_NOT_REMIND_ME_AGAIN')}
                          </Button>
                        </div>
                      </div>
                    }
                    type="error"
                    showIcon
                    icon={
                      <Icon
                        type="exclamation-circle"
                        theme="filled"
                        style={{ fontSize: 20, top: 24 }}
                      />
                    }
                  />
                )}
                {!isReadonly && !isDeputyOnly && baseCurrency === undefined && (
                  <Alert
                    style={{
                      margin: '10px 0'
                    }}
                    description={
                      <P color={theme.red}>
                        {t('YOU_HAVENT_SET_BASE_CURRENCY')}{' '}
                        <Link to="/settings/currency">{t('HERE')}</Link>.
                      </P>
                    }
                    type="error"
                    showIcon
                    icon={
                      <Icon
                        type="exclamation-circle"
                        theme="filled"
                        style={{ fontSize: 20 }}
                      />
                    }
                  />
                )}
                {subscription.status === 'trialing' && (
                  <Alert
                    style={{ margin: '10px 0' }}
                    description={
                      <>
                        <div>
                          <Trans
                            i18nKey="YOUR_TRIAL_PERIOD_WILL_END_ON"
                            values={{
                              nickname:
                                subscription.items.data[0].plan.nickname,
                              trial_end: moment(
                                subscription.trial_end * 1000
                              ).format('LL')
                            }}
                          ></Trans>
                        </div>
                        {!customer.default_source && (
                          <div>
                            <Trans i18nKey="YOU_HAVENT_ADDED_ANY_PAYMENT_METHOD">
                              You haven't added any{' '}
                              <Link to="/settings/payment">payment method</Link>
                              . Please add at least one{' '}
                              <Link to="/settings/payment">payment method</Link>{' '}
                              before the trial period expires.
                            </Trans>
                          </div>
                        )}
                      </>
                    }
                    type={customer.default_source ? 'info' : 'warning'}
                    showIcon
                  />
                )} */}
                {subscription.cancel_at && (
                  <Alert
                    style={{ margin: '10px 0' }}
                    type="warning"
                    closable
                    description={
                      <>
                        <Trans
                          i18nKey="SUBSCRIPTION_HAS_BEEN_CANCEL_AT"
                          values={{
                            nickname: subscription.items.data[0].plan.nickname,
                            cancel_at: moment(
                              subscription.cancel_at * 1000
                            ).format('LL')
                          }}
                        ></Trans>
                        .{' '}
                        <Trans i18nKey="YOU_STILL_CAN_REACTIVATE_SUBSCRIPTION">
                          You still can{' '}
                          {
                            <Button
                              type="link"
                              style={{ padding: 0 }}
                              onClick={() =>
                                Modal.confirm({
                                  title: t('REACTIVATE_SUBSCRIPTION'),
                                  content: (
                                    <Trans
                                      i18nKey="REACTIVATE_SUBSCRIPTION_CONFIRM"
                                      values={{
                                        nickname:
                                          subscription.items.data[0].plan
                                            .nickname
                                      }}
                                    ></Trans>
                                  ),
                                  async onOk(close) {
                                    try {
                                      await updateCancelSchedule(
                                        subscription?.id,
                                        user.username,
                                        dispatch
                                      )
                                      close()
                                      message.success(
                                        t('REACTIVATE_SUBSCRIPTION_SUCCESS')
                                      )
                                    } catch (err) {
                                      message.error(
                                        t('REACTIVATE_SUBSCRIPTION_FAIL')
                                      )
                                      onError(err)
                                    }
                                  },
                                  onCancel() {}
                                })
                              }
                            >
                              reactivate
                            </Button>
                          }{' '}
                          your subscription before it ends.
                        </Trans>
                      </>
                    }
                  />
                )}

                {subscription.status === 'past_due' &&
                  !customer.default_source && (
                    <Alert
                      style={{
                        margin: '10px 0',
                        backgroundColor: theme.redMinus10,
                        color: theme.red
                      }}
                      description={
                        <>
                          <div>
                            <Trans
                              i18nKey="THE_PAYMENT_BECAME_PAST_DUE"
                              values={{
                                billing_cycle_anchor: moment(
                                  subscription.billing_cycle_anchor * 1000
                                ).format('LL')
                              }}
                            ></Trans>
                          </div>
                          <div>
                            <Trans i18nKey="UPDATE_PAYMENT_INFORMATION">
                              Please update your{' '}
                              <Link to="/settings/payment">
                                payment information
                              </Link>{' '}
                              within one week, otherwise your current
                              subscription will be canceled and downgraded to a
                              free plan.
                            </Trans>
                          </div>
                        </>
                      }
                      type="error"
                      showIcon
                      icon={
                        <Icon
                          type="exclamation-circle"
                          theme="filled"
                          style={{ fontSize: 20, top: 24 }}
                        />
                      }
                    />
                  )}
                {/* {!isReadonly && isDeputyOnly && !isProfessionalDeputy && (
                    <Alert
                      style={{
                        margin: '10px 0'
                      }}
                      description={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <P style={{ flexGrow: 1 }}>
                            Free accounts can only act as Deputy for other{' '}
                            <b>bantex</b> users. Upgrade your subscription plan to
                            have use of the other features of <b>bantex</b>.
                          </P>
                          <Button
                            onClick={() => history.push('/settings/payment')}
                            style={{ marginLeft: 75 }}
                            type="primary"
                          >
                            Upgrade
                          </Button>
                        </div>
                      }
                      type="info"
                      showIcon
                    />
                  )} */}
                {/* {sharesThreshold > 2 &&
                  sharesThreshold > acceptedDeputies.length && (
                    <Alert
                      style={{
                        margin: '10px 0'
                      }}
                      description={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <P style={{ flexGrow: 1 }}>
                            <Trans
                              i18nKey="YOU_NEED_TO_ADD_MORE_DEPUTIES"
                              values={{
                                sharesThreshold,
                                acceptedDeputies: acceptedDeputies.length
                              }}
                            ></Trans>
                          </P>
                          <Button
                            onClick={() => setSharesThresholdModalVisible(true)}
                            style={{ marginLeft: 75 }}
                            type="primary"
                          >
                            {t('UPDATE')}
                          </Button>
                        </div>
                      }
                      type="warning"
                      showIcon
                    />
                  )} */}
              </div>
              {children}
            </Content>
          </Layout>
          {!isReadonly && isProfessionalDeputy === false && (
            <SpeedDials
              // disabled={isDeputyOnly || tourRun}
              hidden={isSmallScreen}
            />
          )}
          {/* <SharesThresholdModal
            visible={sharesThresholdModalVisible}
            setVisible={setSharesThresholdModalVisible}
            maxSharesThreshold={
              sharesThreshold > acceptedDeputies.length
                ? sharesThreshold
                : acceptedDeputies.length
            }
          /> */}
          {/* <DisconectUserRequestModal
            visible={disconectUserModalVisible}
            setVisible={setDisconectUserModalVisible}
          /> */}
          <EmailVerificationModal
            visible={emailVerified === 'false'}
            setVisible={() => {}}
            getUserInfo={() => {}}
            onVerificationComplete={() => setEmailVerified('true')}
          />
          <AddLogoModal
            visible={addLogoModalVisible}
            setVisible={setaddLogoModalVisible}
            file={file}
            // setImage={s}
            getLogo={getLogo}
            isEditMode={isEditAvatarMode}
            setIsEditMode={setIsEditAvatarMode}
          />
          <CurrentFilesModal
            visible={curentFilesModalVisible}
            setVisible={setCurentFilesModalVisible}
            isDeputyOnly={isDeputyOnly}
          />
        </Layout>
        <StripeProvider apiKey={config.stripe.PUBLISHABLE_KEY}>
          <Elements>
            <CardModal
              visible={cardModalVisible}
              setVisible={setCardModalVisible}
              customerId={customer.id}
              handleOkComplete={handleComplateAddCard}
              handleCancelComplete={() => {}}
              cancelText={null}
              stripe
              isRequired
            />
          </Elements>
        </StripeProvider>
      </TourContextProvider>
    </VaultContext.Provider>
  )
}
