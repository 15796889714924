import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'
import api from '../../lib/api'

const initialState = {
  events: [],
  deletedEvents: [],
  activeEvents: [],
  isLoading: false,
  error: null
}

const events = createSlice({
  name: 'events',
  initialState,
  reducers: {
    getOtherEventsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getOtherEventsSuccess(state, action) {
      const { events } = action.payload
      state.events = events
      state.deletedEvents = events.filter(item => item.deleted)
      state.activeEvents = events.filter(item => !item.deleted)
      state.isLoading = false
      state.error = null
    },
    getOtherEventsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const {
  getOtherEventsStart,
  getOtherEventsSuccess,
  getOtherEventsFailure
} = events.actions

export default events.reducer

export const fetchOtherEvents = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getOtherEventsStart())
    let events = []
    const eventsFromPouchDB = await getRecords(userId, 'events', masterKey)
    const eventsFromDynamoDB = await api.getEvents(userId)
    if (eventsFromDynamoDB.data?.events) {
      events = eventsFromPouchDB
        .map(event => {
          const results = eventsFromDynamoDB.data.events.filter(
            e => e.id === event._id
          )
          return results.map(e => {
            return {
              ...event,
              ...e
            }
          })
        })
        .flat()
    }
    dispatch(getOtherEventsSuccess({ events }))
  } catch (err) {
    onError(err)
    dispatch(getOtherEventsFailure(err.toString()))
  }
}
