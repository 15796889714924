import React, { useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  Modal,
  Icon,
  Alert,
  Menu,
  DatePicker,
  Divider,
  Spin,
  Layout,
  Row,
  Col,
  message,
  Checkbox,
  Dropdown,
  Input
} from 'antd'
import moment from 'moment'
import { getAuthenticationDetails } from '../../lib/cognito'
//import { StringResources } from '../../share/StringResources'
import {
  destroyAllDbs
  // removeAllDocs,
  // uploadEncryptedData
} from '../../lib/pouchDb'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import api from '../../lib/api'
import {
  errorMessage,
  renderDateTime,
  disabledDate,
  downloadAllDataAsZip,
  downloadFiles,
} from '../../share/helpers'
import WrappedForm from '../common/WrappedForm'
import FormItem from '../override/FormItem'
import { Span, P, StatusText } from '../override/Typography'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'
import CustomIcon from '../override/Icon'
import { ThemeContext } from 'styled-components'
import { AUTH_FLOW, DATE_FORMAT } from '../../share/Constants'
import Button from '../override/Button'
import MfaForm from '../common/MfaVerificationForm'
import { MFA_TYPES } from '../../share/Constants'
import { onError } from '../../lib/sentry'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import PouchDB from 'pouchdb'
import { useTranslation, Trans } from 'react-i18next'
// import { fetchPendingAssetsLiabilities } from '../../features/assets-liabilities/assetsLiabilitiesSlice'
// import { useMutation } from 'react-apollo-hooks'
// import { createS3Change } from '../../graphql/mutations'
const { Content, Sider } = Layout

const menuItem = menuItem => (
  <Menu.Item key={menuItem.url}>
    <Link to={menuItem.url}>
      {menuItem.icon}
      <span className="menu-item-title">{menuItem.title}</span>
    </Link>
  </Menu.Item>
)

const menu = (menuItems, location, mode) => (
  <Menu selectedKeys={[(location && location.pathname) || '/']} mode={mode}>
    {menuItems.map(item => menuItem(item))}
  </Menu>
)

const settingMenuItems = [
  {
    title: <Trans i18nKey="PROFILE_INFORMATION"></Trans>,
    url: '/settings',
    icon: <CustomIcon type="personal" />
  },
  {
    title: <Trans i18nKey="SUBSCRIPTION"></Trans>,
    url: '/settings/payment',
    icon: <Icon type="credit-card" />
  },
  {
    title: <Trans i18nKey="Recovery"></Trans>,
    url: '/settings/recovery',
    icon: <Icon type="redo" />
  }
]

let passwordFormRef, callbacks, formRef, cognitoUser, authDetails

const Settings = ({ location, children }) => {
  const { user, isDeputyOnly } = useContext(AuthContext)
  const { masterKey, fullName, userId } = useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const [errMsg, setErrMsg] = useState('')
  const [accessAttempts, setAccessAttempts] = useState([])
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isSharedUserAccessOnly, setIsSharedUserAccessOnly] = useState(false)
  const [mfaType, setMfaType] = useState()
  const [mfaModalVisible, setMfaModalVisible] = useState(false)
  const [passwordModalVisible, setPasswordModalVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleteUser, setIsDeleteUser] = useState(false)
  const [isDownloadFile, setIsDownloadFile] = useState(false)
  const [canDownloadFiles, setCanDownloadFiles] = useState(false);
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t, i18n } = useTranslation()
  // const dispatch = useDispatch()

  const { activeContacts } = useSelector(state => state.contacts)

  // const { activeAssetsLiabilities } = useSelector(
  //   state => state.assetsLiabilities
  // )
  const { activeFolders, activeFiles } = useSelector(state => state.documents)

  const { customer } = useSelector(state => state.customer)

  const { currentFlow } = useSelector(state => state.user).user
  const [isResendEmailCode, setIsResendEmailCode] = useState(false)

  // const [addS3Change] = useMutation(createS3Change)

  useEffect(() => {
    const getIsAllowDownloadFiles = async () => {
      const {data} = await api.getUser(userId);
      setCanDownloadFiles(data?.canDownloadRawFiles);
    };
    getIsAllowDownloadFiles();
  },[userId])

  useEffect(() => {
    setIsLoading(true)

    // when there is endDate with no startDate,
    // startDate is still required in the QLDB query, so just use moment 0
    let params = {
      ...(startDate
        ? { startTime: startDate.startOf('day').toISOString() }
        : endDate
        ? { startTime: new Date(0).toISOString() }
        : {})
    }

    if (endDate) {
      const now = new Date()
      // endTime in QLDB query can't be after now
      const endTime = endDate.endOf('day').isAfter(now)
        ? now
        : endDate.endOf('day')

      params = { ...params, endTime: endTime.toISOString() }
    }

    api
      .getAccessAttempts(user.username, params)
      .then(res => {
        const data = res.data.length
          ? res.data
              .filter(
                // still need to add the filter on the client
                // since the QLDB query would still include the record
                // if the transaction's active period includes the start date
                r =>
                  (!startDate ||
                    startDate
                      .startOf('day')
                      .isSameOrBefore(r.metadata.txTime)) &&
                  !r.data.DocumentId
              )
              .map(record => ({
                ...record.data,
                Id: record.metadata.txId,
                Time: record.metadata.txTime
              }))
              .reverse()
          : []

        setAccessAttempts(data)
      })
      .catch(err => onError(err))
      .finally(() => setIsLoading(false))
  }, [user, startDate, endDate])

  const deleteUserData = async (resolve, reject) => {
    // if (!isDeputyOnly) {
    // download all data as a zip file
    await downloadAllDataAsZip(
      user.username,
      // activeAssetsLiabilities,
      activeContacts,
      activeFiles,
      activeFolders,
      masterKey,
      fullName,
      true
    )
    // }

    // delete user's account and all related data (S3 folder, DB records, Stripe customer, access attempts on QLDB, etc)
    const deletionTasks = [
      api.deleteAccount(user.username),
      api.deleteAccessAttempts(user.username)
    ]
    if (customer.id) deletionTasks.push(api.deleteCustomer(customer.id))
    await Promise.all(deletionTasks)

    // delete user account
    user.deleteUser(function (err, res) {
      if (err) {
        reject(err)
      } else {
        resolve(t('SUCCESSFULLY_DELETED'))
        user.signOut()

        // delete all Pouch Dbs
        destroyAllDbs()
        localStorage.clear()

        window.location.href = `/goodbye?lang=${i18n.language}`
      }
    })
  }

  const authenticateUser = (resolve, reject) => {
    const authenticationDetails = getAuthenticationDetails(
      authDetails.username,
      authDetails.password
    )

    if (currentFlow === AUTH_FLOW.CUSTOM_FLOW) {
      setIsResendEmailCode(true)
      user.setAuthenticationFlowType('CUSTOM_AUTH')
    }

    user.authenticateUser(authenticationDetails, {
      onSuccess: async function (result) {
        try {
          if (isDeleteUser) {
            await deleteUserData(resolve, reject)
          } else {
            // await resetRegistry()
            // addS3Change({
            //   variables: {
            //     message:
            //       'assetsLiabilities, pendingAssetsLiabilities, assetsLiabilitiesHistory, assetsLiabilitiesValuations, contacts, pendingContacts, documents, pendingDocuments, events',
            //     userId: user.username
            //   }
            // })
            // resolve(t('SUCCESSFULLY_RESET_REGISTRY'))
            // setMfaModalVisible(false)
            // resetFields()
          }
        } catch (err) {
          reject(
            new Error(
              isDeleteUser
                ? t('FAILED_TO_DELETE_USER')
                : t('FAILED_TO_RESET_REGISTRY')
            )
          )
          onError(err)
        } finally {
          setIsSubmitting(false)
        }
      },

      onFailure: function (err) {
        setErrMsg(t('FAILED_TO_AUTHENTICATE_USER'))
        setIsSubmitting(false)
        reject(err)
      },
      customChallenge: function () {
        callbacks = this
        setMfaType(MFA_TYPES.EMAIL)
        setIsSubmitting(false)
        setMfaModalVisible(true)
        setIsResendEmailCode(false)
      },
      selectMFAType: function () {
        user.sendMFASelectionAnswer(MFA_TYPES.TOTP, this)
      },

      mfaRequired: function () {
        callbacks = this
        setMfaType(MFA_TYPES.SMS)
        setMfaModalVisible(true)
      },

      // Require if user have setup MFA successfully
      totpRequired: function () {
        callbacks = this
        setMfaType(MFA_TYPES.TOTP)
        setMfaModalVisible(true)
      }
    })
  }

  const handleConfirmPassword = () => {
    setErrMsg('')

    return new Promise((resolve, reject) => {
      passwordFormRef.props.form.validateFields((err, values) => {
        if (err) {
          // TODO: this will close the modal immediately and can't see the validation error
          resolve(t('INVALID_FORM'))
          return
        }

        cognitoUser = user
        authDetails = { username: user.username, password: values.password }

        authenticateUser(resolve, reject)
      })
    })
  }

  const handleDownloadVaultbox = async () => {
    try {
      setIsDownloading(true)
      await downloadAllDataAsZip(
        user.username,
        // activeAssetsLiabilities,
        activeContacts,
        activeFiles,
        activeFolders,
        masterKey,
        fullName
      )
    } catch (err) {
      message.error(t('FAILED_TO_DOWNLOAD_VAULTBOX'))
      onError(err)
    } finally {
      setIsDownloading(false)
    }
  }

  const getFullObjects = async (listObjects, isContinue, nextToken) => {
    const response = (await api.getListObjects(userId,{isContinue, nextToken})).data;
    const {objects, isTruncated, nextContinuationToken} = response
    listObjects.push(...objects);
    if (isTruncated) {
      await getFullObjects(listObjects, isTruncated, nextContinuationToken);
    }
  }

  const handleDownloadFiles = async () => {

    try {
      setIsDownloadFile(true)
      const listObjects = [];
      await getFullObjects(listObjects, null , null)
      await downloadFiles(userId, masterKey, listObjects);
    } catch (error) {
      message.error('Failed to download files from server')   
    } finally {
      setIsDownloadFile(false)
    }
  }

  const resetFields = () => {
    setErrMsg('')
    formRef && formRef.props.form.resetFields()
    passwordFormRef && passwordFormRef.props.form.resetFields()
    passwordModalVisible && setPasswordModalVisible(false)
    mfaModalVisible && setMfaModalVisible(false)
  }

  const Activities = () => (
    <>
      <H4 style={{ marginBottom: 20 }}>{t('ACTIVITIES')}</H4>
      <Row gutter={10} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <DatePicker
            onChange={setStartDate}
            placeholder={t('FROM')}
            value={startDate}
            disabledDate={startDate => disabledDate(startDate, endDate)}
            format={DATE_FORMAT}
          />
        </Col>
        <Col span={12}>
          <DatePicker
            onChange={setEndDate}
            placeholder={t('TO')}
            value={endDate}
            disabledDate={endDate => disabledDate(startDate, endDate)}
            format={DATE_FORMAT}
          />
        </Col>
      </Row>
      <Checkbox
        checked={isSharedUserAccessOnly}
        onChange={e => setIsSharedUserAccessOnly(e.target.checked)}
        style={{ marginBottom: 20 }}
      >
        {t('DEPUTY_ACCESSES_ONLY')}
      </Checkbox>
      <Spin spinning={isLoading}>
        {accessAttempts
          .filter(
            a => !isSharedUserAccessOnly || a.AccessedByUserId !== user.username
          )
          .map(a => (
            <div
              key={a.Id}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 20
              }}
            >
              <div
                style={{
                  height: 36,
                  width: 36,
                  minWidth: 36,
                  backgroundColor: theme.dark3,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 14
                }}
              >
                {a.AccessedByUserId === user.username ? (
                  <Icon
                    type="login"
                    style={{
                      fontSize: 16,
                      color: theme.dark2
                    }}
                  />
                ) : (
                  <CustomIcon
                    type="unlock"
                    style={{
                      fontSize: 16,
                      color: theme.dark2
                    }}
                  />
                )}
              </div>
              <div>
                <P>
                  {a.AccessedByUserId === user.username ? (
                    t('LOGIN')
                  ) : (
                    <Trans
                      i18nKey="ACCESSED_YOUR_VAULTBOX"
                      values={{
                        name: a.Name,
                        email: a.Email
                      }}
                    ></Trans>
                  )}
                </P>
                <StatusText color={theme.dark2}>
                  {renderDateTime(a.Time)}
                </StatusText>
              </div>
            </div>
          ))}
      </Spin>
    </>
  )

  // const unlinkAssetsLiabilitiesFromAllItems = async dbName => {
  //   const db = new PouchDB(`${user.username}_${dbName}`)
  //   db.crypto(masterKey)
  //   const allDocs = await db.allDocs({ include_docs: true })
  //   const docsToUpdate = allDocs.rows?.filter(
  //     row => row.doc.assetsLiabilities?.length
  //   )
  //   if (docsToUpdate?.length) {
  //     const updatedDocs = docsToUpdate.map(row => {
  //       const { doc } = row
  //       return { ...doc, assetsLiabilities: [] }
  //     })

  //     await db.bulkDocs(updatedDocs)
  //     await uploadEncryptedData(db, user.username, dbName)
  //   } else {
  //     db.removeCrypto()
  //   }
  // }

  // const resetRegistry = async () => {
  //   try {
  //     await Promise.all(
  //       [
  //         'assetsLiabilities',
  //         'assetsLiabilitiesHistory',
  //         'assetsLiabilitiesValuations',
  //         'pendingAssetsLiabilities'
  //       ].map(
  //         async dbName => await removeAllDocs(user.username, masterKey, dbName)
  //       )
  //     )
  //     await Promise.all(
  //       [
  //         'contacts',
  //         'documents',
  //         'events',
  //         'passwords',
  //         'pendingContacts',
  //         'pendingDocuments'
  //       ].map(async dbName => {
  //         await unlinkAssetsLiabilitiesFromAllItems(dbName)
  //       })
  //     )

  //     dispatch(fetchPendingAssetsLiabilities(user.username, masterKey))
  //     message.success(t('SUCCESSFULLY_RESET_REGISTRY'))
  //   } catch (err) {
  //     throw err
  //   }
  // }

  const SettingActions = () => (
    <div className="setting-actions">
      {!isDeputyOnly && 
          <Button
            type="link"
            icon="download"
            style={{ margin: '8px 0' }}
            loading={isDownloading}
            onClick={handleDownloadVaultbox}
          >
            {t('DOWNLOAD_VAULTBOX')}
          </Button>
      }
      {canDownloadFiles && <Button
        type="link"
        icon="download"
        style={{ margin: '8px 0' }}
        loading={isDownloadFile}
        onClick={handleDownloadFiles}
      >
        {t('DOWNLOAD_FILES')}
      </Button>}
      <div
        style={{
          marginLeft: 16,
          marginTop: 12,
          cursor: 'pointer'
        }}
      >
        <Span
          onClick={e => {
            setIsDeleteUser(true)
            setPasswordModalVisible(true)
          }}
          color={theme.red}
        >
          <CustomIcon
            type="trash"
            style={{ fontSize: 16, color: theme.red, marginRight: 8 }}
          />
          {t('DELETE_ACCOUNT')}
        </Span>
      </div>
    </div>
  )

  return (
    <>
      <Layout style={{ height: '100%' }}>
        <Content style={{ backgroundColor: '#fff', padding: '0 20px 20px' }}>
          <div>
            <SimpleHeader
              title={<H4>{t('SETTINGS')}</H4>}
              extra={
                !isMdUp && (
                  <Dropdown
                    trigger={['click', 'hover']}
                    overlay={
                      <Menu>
                        {!isDeputyOnly && (
                          <Menu.Item>
                            <Span
                              style={{ color: theme.primary }}
                              onClick={handleDownloadVaultbox}
                            >
                              <Icon
                                type="download"
                                style={{ marginRight: 8 }}
                              />
                              {t('DOWNLOAD_VAULTBOX')}
                            </Span>
                          </Menu.Item>
                        )}
                        {!isDeputyOnly && (
                          <Menu.Item>
                            <Span
                              style={{ color: theme.primary }}
                              onClick={() => {
                                setIsDeleteUser(false)
                                setPasswordModalVisible(true)
                              }}
                            >
                              <Icon type="reload" style={{ marginRight: 8 }} />
                              {t('RESET_REGISTRY')}
                            </Span>
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          <Span
                            style={{ color: theme.red }}
                            onClick={e => {
                              setIsDeleteUser(true)
                              setPasswordModalVisible(true)
                            }}
                          >
                            <CustomIcon
                              type="trash"
                              style={{ marginRight: 8 }}
                            />
                            {t('DELETE_ACCOUNT')}
                          </Span>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Icon type="more" />
                  </Dropdown>
                )
              }
            />
            <div className="setting-content">
              <div className="setting-menu">
                {menu(
                  isDeputyOnly
                    ? settingMenuItems.filter(
                        item => item.url !== '/settings/currency'
                      )
                    : settingMenuItems,
                  location,
                  isMdUp ? 'vertical' : 'horizontal'
                )}

                {isMdUp && <SettingActions />}
              </div>

              <div className="setting-outlet">
                {children}

                {!isLgUp && location.pathname === '/settings' && (
                  <>
                    <Divider />
                    <Activities />
                  </>
                )}
              </div>
            </div>
          </div>
        </Content>
        {location.pathname === '/settings' && isLgUp && (
          <Sider
            width={320}
            theme="light"
            style={{
              background: 'rgba(242, 243, 247, 0.5)',
              padding: '20px 20px 80px'
            }}
          >
            <Activities />
          </Sider>
        )}
      </Layout>

      {/* mfa authentication modal */}
      <Modal
        visible={mfaModalVisible}
        footer={null}
        closable
        onCancel={resetFields}
      >
        <MfaForm
          wrappedComponentRef={ref => (formRef = ref)}
          cognitoUser={cognitoUser}
          mfaType={mfaType}
          callbacks={callbacks}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          handleResend={authenticateUser}
          isResending={isResendEmailCode}
        />
      </Modal>

      {/* Password modal */}
      <Modal
        visible={passwordModalVisible}
        title={isDeleteUser ? t('CLOSE_ACCOUNT') : t('RESET_REGISTRY')}
        okText={
          isDeleteUser
            ? isDeputyOnly
              ? t('DELETE_ACCOUNT')
              : t('DOWNLOAD_DATA_AND_DELETE_ACCOUNT')
            : t('RESET_REGISTRY')
        }
        okButtonProps={{
          style: {
            backgroundColor: theme.red,
            color: theme.white,
            borderColor: theme.red
          }
        }}
        onOk={async e => {
          try {
            await handleConfirmPassword()
          } catch (e) {
            onError(e)
            setErrMsg(errorMessage(e))
          }
        }}
        onCancel={resetFields}
      >
        <WrappedForm wrappedComponentRef={fr => (passwordFormRef = fr)}>
          {getFieldDecorator => (
            <>
              <P style={{ marginBottom: '1em' }}>
                {isDeleteUser
                  ? t('CONFIRM_CLOSE_ACCOUNT_MSG')
                  : t('ARE_YOU_SURE_YOU_WANT_TO_RESET_YOUR_REGISTRY')}
              </P>
              <P>{t('ENTER_YOUR_PASSWORD_TO_PROCEED')}: </P>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Password is required!'
                    }
                  ]
                })(<Input.Password placeholder={t('ENTER_PASSWORD')} />)}
              </FormItem>
            </>
          )}
        </WrappedForm>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            style={{ marginBottom: 16 }}
          />
        )}
      </Modal>
    </>
  )
}

export default withRouter(Settings)
