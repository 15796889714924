import React, { useState } from 'react'
import { Form, Alert, Modal, Input, Tooltip, Icon, message, Radio } from 'antd'
//import { StringResources } from '../../share/StringResources'
import { validateInputCode, compareToPassword } from '../../share/formHelpers'
import { P, P1, Span } from '../override/Typography'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import PasswordInputWithRules from '../common/PasswordInputWithRules'
import {
  getAuthenticationDetails
  // getUserAttributeValue
} from '../../lib/cognito'
import api, { clearApiInterceptor, setApiInterceptor } from '../../lib/api'
import { resetAccount } from '../../share/helpers'
import MfaForm from '../common/MfaVerificationForm'
import { AUTH_FLOW, MFA_TYPES, RECOVERY_OPTION } from '../../share/Constants'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import TextInput from './../common/TextInput'
import { AES, enc } from 'crypto-js'
import { logAccessAttempt } from '../../share/logs'
import WrappedForm from '../common/WrappedForm'

let formRef,
  callbacks,
  cognitoUser,
  authDetails,
  recoveryKey,
  encryptedMasterKey,
  recoveryKeyFormRef

function ResetPasswordForm(props) {
  const {
    user,
    form,
    errMsg,
    setErrMsg,
    setCurrentStep,
    setWithAssistantApprovals,
    setIsAuthenticated,
    setUser,
    isLoading,
    setIsLoading
  } = props

  const { getFieldDecorator } = form
  const [confirmDirty, setConfirmDirty] = useState(false)
  const [mfaModalVisible, setMfaModalVisible] = useState(false)
  const [totpErr, setTotpErr] = useState('')
  const [mfaType, setMfaType] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isResendEmailCode, setIsResendEmailCode] = useState(false)
  const [emailOTPLoading, setEmailOTPLoading] = useState(false)
  const [recoveryKeyModalVisible, setRecoveryKeyModalVisible] = useState(false)
  const [isRecovering, setIsRecovering] = useState(false)
  const [isResetting, setIsResetting] = useState(false)
  const [recoveryOption, setRecoveryOption] = useState('')
  const [isIncorrectRecoveryKey, setIsIncorrectRecoveryKey] = useState(false)
  const { t } = useTranslation()

  useState(() => {
    setRecoveryOption(RECOVERY_OPTION.BY_RECOVERY_KEY)
  }, [])

  const handleConfirmBlur = e => {
    const { value } = e.target
    setConfirmDirty(confirmDirty || !!value)
  }

  const confirmAction = async () => {
    try {
      setIsLoading(true)
      const res = await api.checkAuthFlow(cognitoUser.username)

      if (res.data) {
        if (res.data === AUTH_FLOW.CUSTOM_FLOW) {
          cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')
        }
      }

      authenticateUser()
    } catch (err) {
      setErrMsg(t('FAILED_TO_GET_AUTHENTICATION_FLOW_TYPE'))
    }
  }

  const onFinish = () => {
    setMfaModalVisible(false)
    setRecoveryKeyModalVisible(false)
    setCurrentStep(2)
    setIsLoading(false)
    setIsSubmitting(false)
    setIsRecovering(false)
    setIsResetting(false)
  }

  const authenticateUser = () => {
    const authenticationDetails = getAuthenticationDetails(
      authDetails.username,
      authDetails.password
    )
    setIsResendEmailCode(true)
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (session) {
        cognitoUser.getUserData(async (err, data) => {
          if (err) {
            setIsLoading(false)
            setIsRecovering(false)
            return
          }

          setApiInterceptor(user)
          try {
            if (recoveryKey) {
              const getUserRes = await api.getUser(cognitoUser.username)
              encryptedMasterKey = getUserRes.data.encryptedMasterKey
              const extraKey = getUserRes.data.extraKey

              const masterKey = AES.decrypt(
                encryptedMasterKey,
                recoveryKey
              ).toString(enc.Latin1)

              const ascii = /^[ -~]+$/
              if (masterKey && ascii.test(masterKey)) {
                const newEncryptedMasterKey = AES.encrypt(
                  masterKey,
                  authDetails.password
                ).toString()

                const encryptedKey = AES.encrypt(masterKey, extraKey).toString()

                // update new encrypted master key to user pool
                cognitoUser.updateAttributes(
                  [
                    {
                      Name: 'custom:master_key',
                      Value: newEncryptedMasterKey
                    }
                  ],
                  async err => {
                    if (err) {
                      throw err
                    } else {
                      setIsLoading(false)
                      resetFields()
                      localStorage.setItem(cognitoUser.username, encryptedKey)
                      setUser(cognitoUser)

                      cognitoUser.getUserData(async (err, data) => {
                        if (err) {
                          return
                        }

                        logAccessAttempt(
                          data.Username,
                          data.UserAttributes,
                          data.Username
                        )
                      })
                    }
                  }
                )

                await api.clearResetData(
                  cognitoUser.username,
                  JSON.stringify({
                    isRecoveryKey: true
                  })
                )

                onFinish()
              } else {
                message.error('Incorrect recovery key!')
                setIsIncorrectRecoveryKey(true)
                setRecoveryKeyModalVisible(true)
                setIsRecovering(false)
                setIsLoading(false)
              }
            } else if (recoveryOption === RECOVERY_OPTION.BY_ASSISTANT) {
              const userResponse = await api.getUser(cognitoUser.username)
              const { assistants } = userResponse.data
              const approvedAssistants = assistants?.filter(d => d.publicKey)
              if (approvedAssistants?.length) {
                await api.sendRecoveryResetRequestEmails(data.Username)
                setWithAssistantApprovals(true)
                cognitoUser.signOut()
                clearApiInterceptor()
                onFinish()
              } else {
                setIsLoading(false)
                Modal.confirm({
                  title: 'Recovery confirm',
                  content:
                    "You don't have any assistant, please use recovery key to recover your data or reset your account.",
                  okText: 'Recovery key',
                  cancelText: 'Reset account',
                  onCancel: async () => {
                    await resetAccount(
                      data,
                      authDetails.password,
                      user,
                      setIsAuthenticated,
                      setUser
                    )
                    onFinish()
                  },
                  onOk: async () => {
                    setRecoveryKeyModalVisible(true)
                  }
                })
              }
            } else {
              await resetAccount(
                data,
                authDetails.password,
                user,
                setIsAuthenticated,
                setUser
              )
              onFinish()
            }
          } catch (err) {
            onError(err)
            setIsLoading(false)
            setIsSubmitting(false)
            setIsRecovering(false)
            setIsResetting(false)
          }
        })
      },
      onFailure: function (err) {
        setTotpErr(t('FAILED_TO_AUTHENTICATE_USER'))
        setIsSubmitting(false)
        setIsLoading(false)
      },
      selectMFAType: function () {
        cognitoUser.sendMFASelectionAnswer(MFA_TYPES.TOTP, this)
      },
      customChallenge: function () {
        callbacks = this
        setMfaType(MFA_TYPES.EMAIL)
        setIsSubmitting(false)
        setMfaModalVisible(true)
        setIsResendEmailCode(false)
      },
      totpRequired: function () {
        callbacks = this
        setIsLoading(false)
        setMfaType(MFA_TYPES.TOTP)
        setMfaModalVisible(true)
      },
      mfaRequired: function () {
        callbacks = this
        setIsLoading(false)
        setMfaType(MFA_TYPES.SMS)
        setMfaModalVisible(true)
      }
    })
  }

  const resetFields = () => {
    setErrMsg('')
    formRef && formRef.props.form.resetFields()
    mfaModalVisible && setMfaModalVisible(false)
    setIsSubmitting(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setErrMsg('')
    cognitoUser = user
    form.validateFields((err, values) => {
      if (err) {
        return
      } else {
        setIsLoading(true)
        recoveryKey = values.recoverKey

        authDetails = {
          username: cognitoUser.getUsername(),
          password: values.password
        }

        cognitoUser.confirmPassword(values.verificationCode, values.password, {
          onSuccess: () => {
            confirmAction()
          },
          onFailure: err => {
            setIsLoading(false)
            setErrMsg(t('FAILED_TO_RESET_PASSWORD'))
            form.resetFields()
          }
        })
      }
    })
  }

  const handleRecover = () => {
    recoveryKeyFormRef.props.form.validateFields((err, values) => {
      if (err) {
        onError(err)
        return
      }
      setIsRecovering(true)
      recoveryKey = values.recoveryKey
      confirmAction()
    })
  }

  return (
    <>
      <P1 style={{ margin: '10px 0 10px' }}>{t('RESET_PASSWORD_TITLE')}</P1>
      <Form layout="vertical" hideRequiredMark>
        <FormItem label={t('VERIFICATION_CODE')}>
          {getFieldDecorator('verificationCode', {
            rules: [
              {
                required: true,
                message: t('INPUT_VERIFICATION_CODE_MSG')
              },
              {
                validator: validateInputCode
              }
            ]
          })(<TextInput type="text" />)}
        </FormItem>

        <PasswordInputWithRules
          confirmDirty={confirmDirty}
          title={t('NEW_PASSWORD')}
          form={form}
        />
        <FormItem label={t('CONFIRM_NEW_PASSWORD')} hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              { required: true, message: t('CONFIRM_PASSWORD_MSG') },
              {
                validator: (rule, value, callback) =>
                  compareToPassword(rule, value, callback, form)
              }
            ]
          })(
            <Input.Password
              onBlur={handleConfirmBlur}
              placeholder=""
              maxLength={30}
            />
          )}
        </FormItem>

        <Span>Recover your data by using: </Span>
        <Span style={{ marginLeft: 5 }}>
          <Radio.Group
            onChange={e => {
              setRecoveryOption(e.target.value)
              form.resetFields(['recoverKey'])
            }}
            value={recoveryOption}
          >
            <Radio value={RECOVERY_OPTION.BY_RECOVERY_KEY}>
              <span>
                {t('Recovery key')}&nbsp;
                <Tooltip title="Please enter the key to recover your data otherwise your data will be clear ">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            </Radio>
            <Radio value={RECOVERY_OPTION.BY_ASSISTANT}>Assistant</Radio>
          </Radio.Group>
        </Span>

        {recoveryOption === RECOVERY_OPTION.BY_RECOVERY_KEY && (
          <FormItem style={{ marginTop: 8 }}>
            {getFieldDecorator(
              'recoverKey',
              {}
            )(<Input placeholder="Recovery key" />)}
          </FormItem>
        )}

        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            style={{ marginBottom: 16 }}
          />
        )}
      </Form>
      <div className="steps-actions">
        <Button
          size="large"
          type="primary"
          onClick={handleSubmit}
          loading={isLoading}
        >
          Next
        </Button>
      </div>

      {/* mfa authentication modal */}
      <Modal
        visible={mfaModalVisible}
        footer={null}
        closable
        onCancel={resetFields}
      >
        <MfaForm
          wrappedComponentRef={ref => (formRef = ref)}
          cognitoUser={user}
          mfaType={mfaType}
          callbacks={callbacks}
          errMsg={totpErr}
          setErrMsg={setTotpErr}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          handleResend={authenticateUser}
          isResending={isResendEmailCode}
          isReset={true}
          emailOTPLoading={emailOTPLoading}
          setEmailOTPLoading={setEmailOTPLoading}
        />
      </Modal>

      <Modal
        title={t('Recovery Key')}
        visible={recoveryKeyModalVisible}
        maskClosable={false}
        closable={false}
        footer={[
          <Button
            key="recover"
            type="primary"
            loading={isRecovering}
            onClick={handleRecover}
          >
            Recover data
          </Button>,
          <Button
            key="reset"
            loading={isResetting}
            onClick={() => {
              recoveryKey = undefined
              setIsResetting(true)
              setRecoveryOption(RECOVERY_OPTION.BY_RECOVERY_KEY)
              authenticateUser()
            }}
          >
            Reset Account
          </Button>
        ]}
      >
        <WrappedForm wrappedComponentRef={fr => (recoveryKeyFormRef = fr)}>
          {getFieldDecorator => (
            <>
              <P style={{ marginBottom: '1em' }}>
                {isIncorrectRecoveryKey && (
                  <Span>Your recovery key is not correct!</Span>
                )}{' '}
                Please input your recovery key to process or click on Reset
                Account button to reset your account without recover your data
              </P>
              <FormItem>
                {getFieldDecorator('recoveryKey', {
                  rules: [
                    {
                      required: true,
                      message: 'Recovery key is required!'
                    }
                  ]
                })(<Input placeholder="Recovery key" />)}
              </FormItem>
            </>
          )}
        </WrappedForm>
      </Modal>
    </>
  )
}
const WrappedResetPasswordForm = Form.create({ name: 'resetPassword' })(
  ResetPasswordForm
)
export default WrappedResetPasswordForm
