import React, { useState, useContext, useEffect } from 'react'
import { Table, Empty, Icon, Dropdown, Menu, Alert } from 'antd'
import AuthContext from '../../contexts/AuthContext'
import Button from '../override/Button'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'
import { currencyFormat } from '../../share/helpers'
import api from '../../lib/api'
import moment from 'moment'
import { onError } from '../../lib/sentry'
import { useMediaQuery } from '@material-ui/core'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

const outputAmount = amount => {
  return amount >= 0
    ? currencyFormat(amount)
    : `-${currencyFormat(Math.abs(amount))}`
}

export default function Payment() {
  const { user } = useContext(AuthContext)
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)
  const [viewingReceipt, setViewingReceipt] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const theme = useContext(ThemeContext)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  useEffect(() => {
    setLoading(true)
    api
      .getInvoices(user.username)
      .then(res => {
        if (res.data.message) {
          throw Error(res.data.message)
        }
        setInvoices(res.data.data || [])
      })
      .catch(err => {
        onError(err)
        setErrMsg(t('FAILED_TO_GET_INVOICES'))
      })
      .finally(() => setLoading(false))
  }, [user, t])

  const columns = [
    {
      key: 'amount_due',
      dataIndex: 'amount_due',
      title: t('AMOUNT'),
      render: (text, record) =>
        `${record.currency.toUpperCase()} ${outputAmount(text / 100)}`
    },
    {
      key: 'created',
      dataIndex: 'created',
      title: t('CREATED'),
      render: text => moment(text * 1000).format('LLL')
    },
    {
      key: 'actions',
      align: 'right',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <Button
                  type="link"
                  onClick={() => window.open(record.hosted_invoice_url)}
                >
                  {t('VIEW_INVOICE')}
                </Button>
              </Menu.Item>
              {record.charge && (
                <Menu.Item>
                  <Button
                    type="link"
                    loading={viewingReceipt}
                    onClick={() => {
                      setViewingReceipt(true)

                      api
                        .getCharge(record.charge)
                        .then(res => {
                          if (res.data.message) {
                            throw Error(res.data.message)
                          }

                          window.open(res.data.receipt_url)
                        })
                        .catch(err => onError(err))
                        .finally(() => setViewingReceipt(false))
                    }}
                  >
                    {t('VIEW_RECEIPT')}
                  </Button>
                </Menu.Item>
              )}
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Icon type="ellipsis" style={{ fontWeight: 'bold', fontSize: 18 }} />
        </Dropdown>
      )
    }
  ]

  return (
    <>
      <SimpleHeader title={<H4>Invoices</H4>} size="small" />
      {errMsg && (
        <Alert
          message={errMsg}
          showIcon
          type="error"
          closable
          afterClose={() => setErrMsg('')}
          style={{ marginBottom: 16 }}
        />
      )}
      <Table
        dataSource={invoices}
        columns={columns}
        rowKey="id"
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No invoices"
            />
          )
        }}
        size={isMdUp ? '' : 'middle'}
      />
    </>
  )
}
