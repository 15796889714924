import React, { useState, useRef } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Button from '../override/Button'
import { Checkbox, Tooltip, Icon } from 'antd'
//import { StringResources } from '../../share/StringResources'
import { useTranslation } from 'react-i18next'

export default function SiderActionButtons({
  handleOk,
  handleCancel,
  isSaving,
  width,
  isEdit,
  isMinor,
  setIsMinor,
  preExtra,
  style,
  isScrolled = true
}) {
  const [currPosY, setCurrPosY] = useState(0)
  const actionButtonsRef = useRef()
  const { t } = useTranslation()

  useScrollPosition(
    ({ currPos }) => {
      if (isScrolled) {
        setCurrPosY(currPos.y)
      }
    },
    [currPosY],
    actionButtonsRef,
    false,
    100
  )

  return (
    <div
      ref={actionButtonsRef}
      style={{ position: isScrolled ? 'relative' : 'static', ...style }}
    >
      <div
        style={
          isScrolled
            ? {
                width,
                position: 'absolute',
                top: currPosY < 20 ? 20 - currPosY : 0
              }
            : {}
        }
      >
        {preExtra}
        {isEdit && (
          <Checkbox checked={isMinor} onChange={() => setIsMinor(!isMinor)}>
            {t('MINOR_EDIT')}
            <Tooltip title={t('MINOR_EDIT_TOOLTIP')}>
              <Icon type="question-circle-o" style={{ paddingLeft: 8 }} />
            </Tooltip>
          </Checkbox>
        )}
        <div
          style={{
            display: 'flex',
            marginTop: isEdit ? 20 : 0
          }}
        >
          <Button
            type="primary"
            size="large"
            onClick={handleOk}
            loading={isSaving}
            style={{ flexGrow: 1, marginRight: 8 }}
            className="tour-action-buttons"
          >
            {t('SAVE')}
          </Button>
          <Button size="large" onClick={handleCancel} style={{ flexGrow: 1 }}>
            {t('CANCEL')}
          </Button>
        </div>
      </div>
    </div>
  )
}
