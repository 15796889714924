import React, { useState, useContext, useEffect, useCallback } from 'react'
import {
  Icon,
  Modal,
  message,
  Divider,
  Table,
  Button,
  Popconfirm,
  Tooltip,
  Radio,
  Upload,
  Form
} from 'antd'
import { H4, Span, StatusText } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import { onError } from '../../lib/sentry'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import VaultContext from './../../contexts/VaultContext'
import PouchDB from 'pouchdb'
import {
  getRecords,
  uploadEncryptedData,
  permanentlyDeleteItems,
  reloadRecords,
  permanentlyDeleteDocuments,
  permanentlyDeleteLocations
} from './../../lib/pouchDb'
import uuidv4 from 'uuid/v4'
import { renderDateTime } from './../../share/helpers'
import api from '../../lib/api'
import { s3Get, s3Put } from '../../lib/awsSDK'
import { saveAs } from 'file-saver'
import FormItem from '../override/FormItem'
import { fetchContacts } from '../../features/contacts/contactsSlice'
import { fetchDocuments } from '../../features/documents/documentsSlice'
import { fetchLocations } from '../../features/location/LocationSlice'
import { fetchPasswords } from '../../features/passwords/PasswordSlice'
import { fetchEvents } from '../../features/events/eventsSlice'
import { fetchUser } from '../../features/user/userSlice'
import JSZip from 'jszip'

const { Dragger } = Upload

function BackupInfo(props) {
  const theme = useContext(ThemeContext)
  const { userId, masterKey } = useContext(VaultContext)
  const recurringEvents = useSelector(state => state.user).user?.events || []
  const { documents, usedStorage } = useSelector(state => state.documents)
  const { contacts } = useSelector(state => state.contacts)
  const { locations } = useSelector(state => state.locations)
  const { passwords } = useSelector(state => state.passwords)
  const { events } = useSelector(state => state.events)
  const { backupSize } = useSelector(state => state.user).user
  const { allowedStorage } = useSelector(state => state.customer)

  const { form } = props
  const dispatch = useDispatch()

  const [restoreModalVisible, setRestoreModalVisible] = useState(false)
  const [backupModalVisible, setBackupModalVisible] = useState(false)
  const [dataBackup, setDataBackup] = useState()
  // const [isLoading, setIsLoading] = useState(false)
  const [isRestoring, setIsRestoring] = useState()
  const [storageType, setStorageType] = useState('From cloud')
  const [backupType, setBackupType] = useState('Upload')
  const [isSaving, setIsSaving] = useState(false)

  const { t } = useTranslation()

  const getData = useCallback(async () => {
    try {
      const data = await getRecords(userId, 'backupData', masterKey)
      setDataBackup(data)
    } catch (error) {
      onError(error)
    }
  }, [userId, masterKey])

  useEffect(() => {
    getData()
  }, [getData, restoreModalVisible])

  const columns = [
    {
      key: 'time',
      dataIndex: 'time',
      title: t('Date'),
      render: text => renderDateTime(text),
      sorter: (a, b) => new Date(a.time) - new Date(b.time),
      defaultSortOrder: 'descend'
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      width: 80,
      render: (text, record) => (
        <>
          <Popconfirm
            title={t('Are you sure to delete this record?')}
            onConfirm={() => handleDelete(record)}
            okText={t('YES')}
            cancelText={t('NO')}
            arrowPointAtCenter
            placement="bottomRight"
          >
            <Tooltip title={t('DELETE')}>
              <Icon type="delete" />
            </Tooltip>
          </Popconfirm>

          <Divider type="vertical" />
          <Popconfirm
            title={t('Are you sure to restore data?')}
            onConfirm={() => showWarningRestore(record)}
            okText={t('YES')}
            cancelText={t('NO')}
            arrowPointAtCenter
            placement="bottomRight"
          >
            <Tooltip title={t('RESTORE')}>
              <Icon type="redo" spin={isRestoring === record._id} />
            </Tooltip>
          </Popconfirm>
        </>
      )
    }
  ]

  const handleDelete = async record => {
    try {
      await permanentlyDeleteItems('backupData', userId, [record], masterKey)
      await getData()
      if (record.backupKeys?.length) {
        const deletedIds = record.backupKeys.map(rc => rc.newFileId)
        const deletedSize = record.backupKeys
          .map(rc => rc.size)
          .reduce((a, b) => a + b, 0)

        await api.bulkDeleteFiles(
          userId,
          JSON.stringify({
            keys: deletedIds
          })
        )

        const newBackupSize = backupSize - deletedSize
        await api.updateBackupSize(
          userId,
          JSON.stringify({
            size: newBackupSize
          })
        )
      }
      dispatch(fetchUser(userId))
      message.success('Successfully deleted record.')
    } catch (error) {
      onError(error)
      message.error('Failed to delete record.')
    }
  }

  const backupToLocal = async () => {
    setIsSaving(true)
    try {
      const backupKeys = await getBackupFileKeys()

      const documentsRes = await s3Get(userId, 'documents')
      const contacts = await s3Get(userId, 'contacts')
      const events = await s3Get(userId, 'events')
      const passwords = await s3Get(userId, 'passwords')
      const locations = await s3Get(userId, 'locations')
      const contactsHistory = await s3Get(userId, 'contactsHistory')
      const backupData = {
        documents: documentsRes,
        events,
        contacts,
        passwords,
        locations,
        contactsHistory,
        recurringEvents,
        backupKeys
      }
      //download files and zip files

      const currentDate = new Date().toLocaleString()
      const fileName = `bantex-backup-data.json`
      const file = new Blob([JSON.stringify(backupData)])

      let zip = new JSZip()

      zip.file(fileName, file)
      //zip s3 file

      await Promise.all(
        backupKeys.map(async item => {
          const resBody = await s3Get(
            userId,
            item.id,
            { sub: item.sub },
            { responseType: 'blob' }
          )
          zip.file(item.id, resBody)
        })
      )

      zip.generateAsync({ type: 'blob' }).then(function (content) {
        //Save to zip file
        saveAs(content, `bantex-backup-data-${currentDate}.zip`)
      })
      dispatch(fetchUser(userId))

      message.success('Successfully backup data')
      setIsSaving(false)
      setBackupModalVisible(false)
      setBackupType('Upload')
    } catch (error) {
      setIsSaving(false)
      message.error('Failed to backup data')
    }
  }

  const sumSizeStoraged = async () => {
    const filesBackup = documents
      .filter(doc => doc.fileName)
      .map(doc => doc.file[0].size)

    const totalSize = filesBackup.reduce((a, b) => a + b, 0)
    const totalBackupSize = totalSize + (backupSize || 0)
    return totalBackupSize
  }

  const showWarningRestore = async (record, isLocal, files, zip) => {
    Modal.confirm({
      content: (
        <>
          {t(
            'All your data will be overwritten with the content of the backup. Are you sure to continue?'
          )}
          <div></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <b>Current data</b>
              <div>Documents: {documents.length || 0}</div>
              <div>Contacts: {contacts.length || 0}</div>
              <div>Events: {recurringEvents.length || 0}</div>
              <div>Locations: {locations.length || 0}</div>
              <div>Passwords: {passwords.length || 0}</div>
            </div>
            <div>
              <b>Restore data</b>
              <div>
                Documents:{' '}
                {isLocal
                  ? record?.documents?.docs?.length || 0
                  : record?.documents?.length || 0}
              </div>
              <div>
                Contacts:{' '}
                {isLocal
                  ? record?.contacts?.docs?.length || 0
                  : record?.contacts?.data?.length || 0}
              </div>
              <div>
                Events:{' '}
                {isLocal
                  ? record?.recurringEvents?.length || 0
                  : record?.events?.dynamoDB?.length || 0}
              </div>
              <div>
                Locations:{' '}
                {isLocal
                  ? record?.locations?.docs?.length || 0
                  : record?.locations?.length || 0}
              </div>
              <div>
                Passwords:{' '}
                {isLocal
                  ? record?.passwords?.docs?.length || 0
                  : record?.passwords?.length || 0}
              </div>
            </div>
          </div>
        </>
      ),
      okText: t('YES'),
      cancelText: t('NO'),
      onOk: () =>
        !isLocal
          ? handleRestore(record)
          : handleRestoreLocal(record, files, zip)
    })
  }

  const getBackupFileKeys = async () => {
    const filesBackup = documents
      .filter(doc => doc.fileName)
      .map(doc => {
        return {
          id: doc.fileId,
          sub: doc.sub,
          size: doc.file[0].size
        }
      })

    const loctionImagesBackup = locations
      .filter(lc => lc.image?.length)
      .map(lc => lc.image)
      .flat()
      .map(img => {
        return {
          id: img.fileId,
          sub: img.sub,
          size: img.size
        }
      })

    const allBackupFiles = [...filesBackup, ...loctionImagesBackup]
    return allBackupFiles
  }

  const cloneS3Files = async () => {
    const allBackupFiles = await getBackupFileKeys()

    let backupKeys = []
    allBackupFiles.forEach(async item => {
      // copy s3 object
      const newId = uuidv4()

      const key = {
        sourceFileId: item.id,
        newFileId: newId,
        size: item.size
      }

      backupKeys.push(key)
      await api.bulkCopyFiles(
        userId,
        JSON.stringify({
          normalFileData: backupKeys
        })
      )
    })
    return backupKeys
  }

  const restoreFromLocal = () => {
    setIsRestoring(true)
    form.validateFields(async (err, values) => {
      if (err) {
        setIsRestoring(false)
        return
      }
      const file = values.file[0].originFileObj

      const reader = new FileReader()
      reader.onload = async () => {
        try {
          // const data = JSON.parse(reader.result)

          // await showWarningRestore(data, true)
          JSZip.loadAsync(reader.result)
            .then(async zip => {
              const data = JSON.parse(
                await zip.file('bantex-backup-data.json').async('string')
              )
              await showWarningRestore(data, true, zip.files, zip)
              setIsRestoring(false)
            })
            .catch(function (err) {
              message.error('Failed to open ZIP file')
            })
        } catch (error) {
          message.error('Failed to restore data')
          setIsRestoring()
        }
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const backupFilesToS3 = async (files, keys, zip) => {
    await Promise.all(
      Object.keys(files).map(async filename => {
        const fileInfo = keys.find(key => key.id === filename)

        if (fileInfo?.id) {
          const fileContent = await zip.file(filename).async('nodebuffer')
          await s3Put(userId, fileInfo.id, fileContent, {
            sub: fileInfo.sub
          })
        }
      })
    )
  }

  const handleRestoreLocal = async (data, files, zip) => {
    try {
      //delete old data
      await deleteOldData()

      //restore data
      await restoreData('documents', data.documents)
      await restoreData('contacts', data.contacts)
      await restoreData('events', data.events)
      await restoreData('locations', data.locations)
      await restoreData('passwords', data.passwords)
      await restoreData('contactsHistory', data.contactsHistory)
      if (data.recurringEvents?.length) {
        await api.updateEvents(
          userId,
          JSON.stringify({
            events: data.recurringEvents
          })
        )
      }

      //upload files to s3
      if (data.backupKeys?.length && files) {
        await backupFilesToS3(files, data.backupKeys, zip)
      }
      dispatch(fetchUser(userId))
      message.success('Successfully restored data')
      setIsRestoring()
      setRestoreModalVisible(false)
      form.resetFields()
    } catch (error) {
      onError(error)
    }
  }

  const restoreData = async (dbName, data) => {
    const urlRes = await api.getSignedUrl(userId, dbName, 'putObject', {})
    if (urlRes.data.message) throw Error(urlRes.data.message)

    await api.uploadToS3Url(urlRes.data, data, {})
    await reloadRecords(dbName, userId, masterKey)
    await fetchDataFunction(dbName, userId, masterKey)
  }

  const fetchDataFunction = async (dbName, userId, masterKey) => {
    switch (dbName) {
      case 'contacts':
        return dispatch(fetchContacts(userId, masterKey))
      case 'documents':
        return dispatch(fetchDocuments(userId, masterKey))
      case 'locations':
        return dispatch(fetchLocations(userId, masterKey))
      case 'passwords':
        return dispatch(fetchPasswords(userId, masterKey))
      case 'events':
        return dispatch(fetchEvents(userId, masterKey))
      default:
        return null
    }
  }

  const handleBackup = () => {
    if (backupType === 'Download') {
      backupToLocal()
    } else {
      backupToCloud()
    }
  }

  const deleteOldData = async () => {
    try {
      const contactsHistory = await getRecords(
        userId,
        'contactsHistory',
        masterKey
      )

      if (events?.length)
        await permanentlyDeleteItems('events', userId, events, masterKey)
      if (contacts?.length)
        await permanentlyDeleteItems('contacts', userId, contacts, masterKey)

      if (documents?.length)
        await permanentlyDeleteDocuments(
          userId,
          documents,
          documents,
          masterKey
        )
      if (locations?.length)
        await permanentlyDeleteLocations(userId, locations, masterKey)
      if (passwords?.length)
        await permanentlyDeleteItems('passwords', userId, passwords, masterKey)
      if (contactsHistory?.length)
        await permanentlyDeleteItems(
          'contactsHistory',
          userId,
          contactsHistory,
          masterKey
        )
    } catch (error) {
      onError(error)
    }
  }

  const backupToCloud = async () => {
    setIsSaving(true)
    const db = new PouchDB(`${userId}_${'backupData'}`)
    db.crypto(masterKey)

    try {
      const totalBackupSize = await sumSizeStoraged()

      if (totalBackupSize + usedStorage > allowedStorage) {
        Modal.warning({
          title: t('Cannot backup files'),
          content:
            'Your space has been full. Please clear your space to continue backup'
        })
        setIsSaving(false)
        return
      }
      const contactsHistory = (
        await getRecords(userId, 'contactsHistory', masterKey)
      ).map(cth => {
        delete cth._rev
        return cth
      })

      const backupKeys = await cloneS3Files(totalBackupSize)

      const data = {
        _id: uuidv4(),
        time: new Date(),
        documents: documents.map(doc => {
          return {
            ...doc,
            _rev: undefined
          }
        }),
        contacts: {
          data: contacts.map(contact => {
            return { ...contact, _rev: undefined }
          }),
          history: contactsHistory
        },
        passwords: passwords.map(password => {
          return { ...password, _rev: undefined }
        }),
        locations: locations.map(location => {
          return { ...location, _rev: undefined }
        }),
        events: {
          pouchDB: events.map(event => {
            return { ...event, _rev: undefined }
          }),
          dynamoDB: recurringEvents
        },
        backupKeys
      }

      await db.put(data)
      await uploadEncryptedData(db, userId, 'backupData')
      if (totalBackupSize > 0) {
        await api.updateBackupSize(
          userId,
          JSON.stringify({
            size: totalBackupSize
          })
        )
      }
      message.success('Backup data successfully')
      setIsSaving(false)
      setBackupModalVisible(false)
      dispatch(fetchUser(userId))
    } catch (error) {
      setIsSaving(false)
      onError(error)
      message.error('Failed to backup data')
    }
  }
  const handleRestore = async selectedRecord => {
    setIsRestoring(selectedRecord._id)

    try {
      //delete all old data
      await deleteOldData()

      //restore data to database

      if (selectedRecord.documents?.length) {
        const documentDb = new PouchDB(`${userId}_documents`)
        documentDb.crypto(masterKey)
        await documentDb.bulkDocs(selectedRecord.documents)
        await uploadEncryptedData(documentDb, userId, 'documents')
      }
      if (selectedRecord.contacts?.data?.length) {
        const contactDb = new PouchDB(`${userId}_contacts`)
        contactDb.crypto(masterKey)
        await contactDb.bulkDocs(selectedRecord?.contacts?.data)
        await uploadEncryptedData(contactDb, userId, 'contacts')
      }

      if (selectedRecord.contacts?.history?.length) {
        const contactHistoryDb = new PouchDB(`${userId}_contactsHistory`)
        contactHistoryDb.crypto(masterKey)
        await contactHistoryDb.bulkDocs(selectedRecord?.contacts?.history)
        await uploadEncryptedData(contactHistoryDb, userId, 'contactsHistory')
      }

      if (selectedRecord.events?.pouchDB?.length) {
        const eventDb = new PouchDB(`${userId}_events`)
        eventDb.crypto(masterKey)
        await eventDb.bulkDocs(selectedRecord.events.pouchDB)
        await uploadEncryptedData(eventDb, userId, 'events')
        await api.updateEvents(
          userId,
          JSON.stringify({
            events: selectedRecord.events.dynamoDB
          })
        )
      }

      if (selectedRecord.locations?.length) {
        const locationDb = new PouchDB(`${userId}_locations`)
        locationDb.crypto(masterKey)
        await locationDb.bulkDocs(selectedRecord.locations)
        await uploadEncryptedData(locationDb, userId, 'locations')
      }

      if (selectedRecord.passwords?.length) {
        const passwordDb = new PouchDB(`${userId}_passwords`)
        passwordDb.crypto(masterKey)
        await passwordDb.bulkDocs(selectedRecord.passwords)
        await uploadEncryptedData(passwordDb, userId, 'passwords')
      }

      if (selectedRecord.backupKeys?.length) {
        await api.bulkCopyFiles(
          userId,
          JSON.stringify({
            normalFileData: selectedRecord.backupKeys.map(k => {
              return {
                sourceFileId: k.newFileId,
                newFileId: k.sourceFileId
              }
            })
          })
        )
      }
      dispatch(fetchUser(userId))

      message.success('Restore data successfully')
      setIsRestoring()
      setRestoreModalVisible(false)
      form.resetFields()
    } catch (error) {
      onError(error)
      setIsRestoring()
      message.error('Failed to restore data')
    }
  }

  const draggerProps = {
    beforeUpload: () => {
      // this is for preventing the upload action, so we can customize the upload flow and upload to S3 later when the form is saved
      return false
    }
  }

  const normFile = e => {
    // Temporary only allow uploading a single file at once (the last selected file) for simplicity,
    // may consider adding support for uploading multiple files later
    if (Array.isArray(e)) {
      return e.slice(-1)
    }
    return e && e.fileList.slice(-1)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <H4>{t('Backup/Restore')}:</H4>
        <div>
          <Span
            color={theme.primary}
            style={{ cursor: 'pointer' }}
            onClick={() => setBackupModalVisible(true)}
          >
            <Icon type="save" style={{ marginRight: 8 }} />
            {t('Backup')}
          </Span>
          <Divider type="vertical" />
          <Span
            color={theme.primary}
            style={{ cursor: 'pointer' }}
            onClick={() => setRestoreModalVisible(true)}
          >
            <Icon type="redo" style={{ marginRight: 8 }} />
            {t('Restore')}
          </Span>
        </div>
      </div>
      <StatusText color={theme.dark2}>
        {t('Backup your data and restore them when needed')}
      </StatusText>
      <Modal
        title={t('RESTORE')}
        visible={restoreModalVisible}
        okText={t('OK')}
        onCancel={() => setRestoreModalVisible(false)}
        footer={
          storageType === 'From local'
            ? [
                <Button
                  type="primary"
                  loading={isRestoring}
                  onClick={() => restoreFromLocal()}
                >
                  {t('RESTORE')}
                </Button>,
                <Button onClick={() => setRestoreModalVisible(false)}>
                  {t('CANCEL')}
                </Button>
              ]
            : [
                <Button
                  type="primary"
                  onClick={() => setRestoreModalVisible(false)}
                >
                  {t('OK')}
                </Button>
              ]
        }
        width={700}
      >
        <Radio.Group
          onChange={e => setStorageType(e.target.value)}
          value={storageType}
        >
          <Radio
            key="From cloud"
            value="From cloud"
            style={{ marginBottom: 30 }}
          >
            From cloud
          </Radio>
          <Radio key="From local" value="From local">
            From local
          </Radio>
        </Radio.Group>
        {storageType === 'From cloud' ? (
          <Table rowKey={'_id'} columns={columns} dataSource={dataBackup} />
        ) : (
          <Form className="upload-form">
            <FormItem label={t('FILE')}>
              {form.getFieldDecorator('file', {
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
                rules: [
                  {
                    required: true,
                    message: t('SELECT_FILE_TO_UPLOAD_MSG')
                  },
                  {
                    validator: (rule, value, callback) => {
                      const fileType = value && value[0]?.originFileObj.type
                      if (
                        fileType &&
                        fileType !== 'application/x-zip-compressed'
                      ) {
                        callback(t('Please input zip file!'))
                      } else {
                        callback()
                      }
                    }
                  }
                ]
              })(
                <Dragger {...draggerProps}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="upload" />
                  </p>
                  <p className="ant-upload-hint">
                    {t('UPLOAD_FILE_INSTRUCTION')}
                  </p>
                </Dragger>
              )}
            </FormItem>
          </Form>
        )}
      </Modal>
      <Modal
        title="Backup your data"
        visible={backupModalVisible}
        setVisible={setBackupModalVisible}
        onOk={() => handleBackup()}
        onCancel={() => {
          setBackupModalVisible(false)
          setBackupType('Upload')
        }}
        okText="Continue"
        okButtonProps={{ loading: isSaving }}
      >
        <span>
          Your data will be saved and you can restore if you need. Please choose
          a option to storage your backup file?
        </span>
        <Radio.Group
          onChange={e => setBackupType(e.target.value)}
          value={backupType}
        >
          <Radio key="Upload" value="Upload" style={{ marginBottom: 30 }}>
            Upload to Bantex account
          </Radio>
          <Radio key="Download" value="Download">
            Download
          </Radio>
        </Radio.Group>
      </Modal>
    </>
  )
}

const WrappedBackupInfoForm = Form.create({ name: 'BackupInfo' })(BackupInfo)
export default WrappedBackupInfoForm
