import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Modal, Divider, Alert, message } from 'antd'
import { P } from '../override/Typography'
import { LEGACY_ACCESSED_BY } from '../../share/Constants'
import Button from '../override/Button'
import LegacyAccessedBy from './LegacyAccessedBy'
import LegacyPermissions from './LegacyPermissions'
import LegacyReleaseUpon from './LegacyReleaseUpon'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { useSelector } from 'react-redux'
import api from '../../lib/api'
import LegacyInfoSummary from './LegacyInfoSummary'
import NodeRSA from 'node-rsa'
import { randomBytes } from 'crypto'
import { s3Put, s3Get } from '../../lib/awsSDK'
import { AES } from 'crypto-js'
import { encryptFilePromise, decryptFile } from '../../lib/crypto'
import { getUserData, getUserAttributeValue } from '../../lib/cognito'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'

const LegacyConfigurationModal = props => {
  const {
    visible,
    setVisible,
    legacyInfo,
    fetchLegacyStatus,
    fetchLegacyInfo
  } = props

  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const { deputies } = useSelector(state => state.deputies)
  const { activeContacts } = useSelector(state => state.contacts)
  const {
    inactivityReminderResponseDuration,
    inactivityDuration
  } = useSelector(state => state.user).user
  const inactivityStateConfig = {
    inactivityReminderResponseDuration,
    inactivityDuration
  }

  const [accessedBy, setAccessedBy] = useState('')
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedFilesFolders, setSelectedFilesFolders] = useState([])
  const [errMsg, setErrMsg] = useState('')
  const [instructionData, setInstructionData] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [otherPersonId, setOtherPersonId] = useState('')
  const [permissions, setPermissions] = useState({})
  const [effectiveUpon, setEffectiveUpon] = useState()
  const [instructionFileName, setInstructionFileName] = useState('')

  const otherPerson = activeContacts.find(ac => ac._id === otherPersonId)

  const professionalDeputies = deputies.filter(
    d => d.professionalDeputyId && d.publicKey
  )

  const setLegacyInfoCallback = useCallback(() => {
    setOtherPersonId(legacyInfo.otherPersonId)
    setPermissions(legacyInfo.permissions)
    setEffectiveUpon(legacyInfo.effectiveUpon)
    setAccessedBy(
      legacyInfo.accessedBy || LEGACY_ACCESSED_BY.PROFESSIONAL_DEPUTY
    )
    setSelectedFilesFolders(legacyInfo.selectedFilesFolders || [])
  }, [legacyInfo])

  useEffect(() => {
    setLegacyInfoCallback()
  }, [setLegacyInfoCallback])

  useEffect(() => {
    const getInstructionFileData = async (fileId, fileName) => {
      try {
        const userId = user.username

        const statusRes = await api.getFileStatus(userId, fileId)
        if (statusRes.data && statusRes.data.isLocked) {
          setErrMsg(t('THE_SELECTED_FILE_IS_LOCK_AND_CANNOT_BE_USED'))
          return
        }

        const resBody = await s3Get(
          userId,
          fileId,
          {},
          { responseType: 'blob' }
        )

        decryptFile(resBody, masterKey, uint8Array => {
          const blob = new Blob([uint8Array])

          setInstructionData({
            id: fileId,
            name: fileName,
            content: blob
          })
        })
      } catch (err) {
        setErrMsg(t('FAILED_TO_GET_INSTRUCTION_FILE'))
        onError(err)
      }
    }

    if (legacyInfo.instructionFileId && masterKey) {
      getInstructionFileData(
        legacyInfo.instructionFileId,
        legacyInfo.instructionFileName
      )
    }
  }, [user, legacyInfo, masterKey, t])

  const nextStep = () => {
    setErrMsg('')
    setCurrentStep(currentStep + 1)
  }

  const prevStep = () => {
    setErrMsg('')
    setCurrentStep(currentStep - 1)
  }

  const handleSave = async () => {
    setErrMsg('')
    setIsSaving(true)

    const otherPerson =
      accessedBy === LEGACY_ACCESSED_BY.PROFESSIONAL_DEPUTY
        ? {}
        : activeContacts.find(ac => ac._id === otherPersonId)

    const newLegacyInfo = {
      ...legacyInfo,
      selectedFilesFolders:
        accessedBy === LEGACY_ACCESSED_BY.PROFESSIONAL_DEPUTY
          ? []
          : selectedFilesFolders,
      accessedBy,
      effectiveUpon,
      permissions,
      otherPersonId,
      otherPerson,
      instructionFileName
    }
    try {
      if (professionalDeputies.length) {
        getUserData(user, async (err, userData) => {
          if (err) {
            onError(err)
            return
          }
          const fullName = getUserAttributeValue(
            userData.UserAttributes,
            'custom:full_name'
          )

          let instructionFileKeys = []
          await Promise.all(
            professionalDeputies.map(async deputy => {
              const { id, publicKey } = deputy
              const key = new NodeRSA()
              key.importKey(publicKey, 'public')

              let putPromises = []

              const encryptedLegacyInfo = key.encrypt(
                JSON.stringify(newLegacyInfo),
                'base64'
              )

              putPromises.push(
                s3Put(
                  id,
                  `legacy/${user.username}/details`,
                  encryptedLegacyInfo.toString()
                )
              )

              if (instructionData.id) {
                const instructionFileKey = randomBytes(20).toString('hex')
                instructionFileKeys.push({
                  deputyId: id,
                  key: key.encrypt(instructionFileKey, 'base64')
                })

                const encryptedInstruction = await encryptFilePromise(
                  instructionData.content,
                  instructionFileKey
                )

                putPromises.push(
                  s3Put(
                    id,
                    `legacy/${user.username}/instruction`,
                    encryptedInstruction
                  )
                )
              }

              await Promise.all(putPromises)
            })
          )
          const fileKeys = [
            {
              fileId: `legacy/${user.username}/instruction`,
              keys: instructionFileKeys
            }
          ]

          const fileKeysRes = await api.saveFileKeys(
            user.username,
            JSON.stringify({ fileKeys })
          )
          if (fileKeysRes.data.message) throw Error(fileKeysRes.data.message)

          const encryptedLegacyInfo = AES.encrypt(
            JSON.stringify({
              ...newLegacyInfo,
              instructionFileId: instructionData.id
            }),
            masterKey
          ).toString()

          await s3Put(user.username, `legacy/details`, encryptedLegacyInfo)

          if (!Object.keys(legacyInfo).length) {
            await api.updateLegacyManagementStatus(
              user.username,
              JSON.stringify({
                legacyManagementEnabled: true
              })
            )

            await api.notifyLegacyManagementSetup(
              user.username,
              JSON.stringify({ fullname: fullName })
            )
          }

          setIsSaving(false)
          resetModal(true)
          fetchLegacyStatus()
          fetchLegacyInfo(masterKey)
          message.success(
            t('SUCCESSFULLY_SAVED_LEGACY_MANAGEMENT_CONFIGURATION')
          )
        })
      }
    } catch (error) {
      onError(error)
      setErrMsg(t('FAILED_TO_SAVE_LEGACY_MANAGEMENT_CONFIGURATION'))
    }
  }

  const resetModal = (isSaved = false) => {
    setErrMsg('')
    setVisible(false)
    setCurrentStep(0)

    if (!isSaved) {
      setLegacyInfoCallback()
    }
    setInstructionData({})
  }

  const steps = [
    <LegacyAccessedBy
      accessedBy={accessedBy}
      setAccessedBy={setAccessedBy}
      otherPersonId={otherPersonId}
      setOtherPersonId={setOtherPersonId}
      resetModal={resetModal}
      nextStep={nextStep}
    />,
    <LegacyPermissions
      selectedFilesFolders={selectedFilesFolders}
      setSelectedFilesFolders={setSelectedFilesFolders}
      instructionData={instructionData}
      setInstructionData={setInstructionData}
      prevStep={prevStep}
      accessedBy={accessedBy}
      nextStep={nextStep}
      setErrMsg={setErrMsg}
      permissions={permissions}
      setPermissions={setPermissions}
      instructionFileName={instructionFileName}
      setInstructionFileName={setInstructionFileName}
    />,
    <LegacyReleaseUpon
      nextStep={nextStep}
      prevStep={prevStep}
      setErrMsg={setErrMsg}
      effectiveUpon={effectiveUpon}
      setEffectiveUpon={setEffectiveUpon}
      inactivityStateConfig={inactivityStateConfig}
    />,
    <>
      <P>
        <strong>{t('SUMMARY')}</strong>
      </P>
      <P>{t('HERE_IS_A_SUMMARY_OF_YOUR_CONFIGURATION')}</P>
      <Divider type="horizontal" />
      <LegacyInfoSummary
        permissions={permissions}
        selectedFilesFolders={selectedFilesFolders}
        accessedBy={accessedBy}
        effectiveUpon={effectiveUpon}
        otherPerson={otherPerson}
      />
      <div className="steps-actions">
        <Button size="large" onClick={prevStep}>
          {t('BACK')}
        </Button>
        <div style={{ float: 'right' }}>
          <Button
            loading={isSaving}
            size="large"
            type="primary"
            onClick={handleSave}
          >
            {t('SAVE')}
          </Button>
        </div>
      </div>
    </>
  ]

  return (
    <Modal
      visible={visible}
      width={750}
      title={t('LEGACY_MANAGEMENT_CONFIGURATION')}
      maskClosable={false}
      onCancel={() => resetModal()}
      footer={null}
    >
      {errMsg && (
        <Alert
          style={{ marginTop: 10 }}
          description={errMsg}
          closable
          afterClose={() => setErrMsg('')}
          type="error"
        />
      )}
      {steps[currentStep]}
    </Modal>
  )
}

export default LegacyConfigurationModal
