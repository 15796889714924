import React, { forwardRef, useState, useEffect, useContext } from 'react'
import { Select, Divider, Icon, message } from 'antd'
import { CRYPTO_CURRENCIES } from '../../share/CommonCryptos'
import { useDispatch, useSelector } from 'react-redux'
import AuthContext from '../../contexts/AuthContext'
import { fetchUser } from '../../features/user/userSlice'
import api from '../../lib/api'
import { onError } from '../../lib/sentry'
import AddCryptoCurrencyModal from '../modals/AddCryptoCurrencyModal'
import { useTranslation } from 'react-i18next'
import { removeHtmlTags } from './../../share/helpers'

const { Option } = Select

const CryptoCurrencySelect = forwardRef(
  ({ value, onChange, onAddComplete }, ref) => {
    const { user } = useContext(AuthContext)
    const { otherCryptos } = useSelector(state => state.user).user
    const dispatch = useDispatch()

    const [addCryptoModalVisible, setAddCryptoModalVisible] = useState(false)
    const [isAddingCrypto, setIsAddingCrypto] = useState(false)
    const [cryptoCurrencies, setCryptoCurrencies] = useState([])
    const { t } = useTranslation()

    let addItemFormRef

    useEffect(() => {
      setCryptoCurrencies(
        otherCryptos?.length
          ? [...CRYPTO_CURRENCIES, ...otherCryptos]
          : CRYPTO_CURRENCIES
      )
    }, [otherCryptos])

    const handleAddCrypto = () => {
      const { form } = addItemFormRef.props
      form.validateFields((err, values) => {
        if (err) return

        removeHtmlTags(values)

        setIsAddingCrypto(true)
        const newOtherCryptos = [...(otherCryptos || []), values]
        api
          .saveOtherCryptos(user.username, JSON.stringify(newOtherCryptos))
          .then(() => {
            form.resetFields()
            setAddCryptoModalVisible(false)
            dispatch(fetchUser(user.username))
            onAddComplete(values.code)
          })
          .catch(err => {
            onError(err)
            message.error(t('FAILED_TO_ADD_OTHER_CRYPTO'))
          })
          .finally(() => {
            setIsAddingCrypto(false)
          })
      })
    }

    return (
      <span ref={ref}>
        <Select
          allowClear
          showSearch
          showAction={['click', 'focus']}
          placeholder={t('SELECT_CRYPTOCURRENCY')}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children
              .toString()
              .toLowerCase()
              .includes(input.trim().toLowerCase())
          }
          style={{ width: '100%' }}
          value={value}
          onChange={onChange}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: 0 }} />
              <div
                style={{ padding: '5px 12px', cursor: 'pointer' }}
                onMouseDown={e => e.preventDefault()}
                onClick={() => setAddCryptoModalVisible(true)}
              >
                <Icon type="plus" /> {t('OTHER')}
              </div>
            </div>
          )}
        >
          {cryptoCurrencies.map(c => (
            <Option key={c.code} value={c.code}>
              {c.code} - {c.name}
            </Option>
          ))}
        </Select>
        <AddCryptoCurrencyModal
          wrappedComponentRef={fr => (addItemFormRef = fr)}
          visible={addCryptoModalVisible}
          handleOk={handleAddCrypto}
          handleCancel={() => setAddCryptoModalVisible(false)}
          existingItems={cryptoCurrencies}
          isAddingCrypto={isAddingCrypto}
        />
      </span>
    )
  }
)
export default CryptoCurrencySelect
