import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import AuthContext from '../../contexts/AuthContext'
// import { Modal } from 'antd'
// import SubscriptionModal from '../payment/SubscriptionModal'
// import { Trans } from 'react-i18next'
import SubscriptionTrialEndModal from '../modals/SubscriptionTrialEndModal'
import CurrentFilesModal from './../modals/CurrentFilesModal'

const Overlay = ({ className }) => {
  const [visible, setVisible] = useState()
  const { isDeputyOnly } = useContext(AuthContext)

  const handleClick = () => {
    if (!visible) {
      setVisible(true)
    }
  }

  return (
    <div className={className} onClick={handleClick}>
      {isDeputyOnly ? (
        <CurrentFilesModal
          visible={visible}
          setVisible={setVisible}
          isDeputyOnly={isDeputyOnly}
        />
      ) : (
        <SubscriptionTrialEndModal visible={visible} setVisible={setVisible} />
      )}
    </div>
  )
}

const StyledOverlay = styled(Overlay)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.6;
`
export default StyledOverlay
