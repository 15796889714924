import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { Resizable } from 'react-resizable'
import ReactDragListView from 'react-drag-listview'
import styled from 'styled-components'

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}

const CustomTable = props => {
  const { dataSource, columns, ...restProps } = props
  const [columnState, setColumnState] = useState([])
  useEffect(() => {
    setColumnState(columns)
  },[columns])

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const nextcolumns = [...columnState]
      const item = nextcolumns.splice(fromIndex, 1)[0]
      nextcolumns.splice(toIndex, 0, item)
      setColumnState(nextcolumns)
    },
    nodeSelector: 'th',
    handleSelector: '.dragHandler'
  }

  const handleResize =
    index =>
    (e, { size }) => {
      const nextColumns = [...columnState]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      setColumnState(nextColumns)
    }

  const newColumns = columnState.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index)
    })
  }))

  return (
    <ReactDragListView.DragColumn {...dragProps}>
      <Table
        {...restProps}
        components={{
          header: {
            cell: ResizableTitle
          }
        }}
        columns={newColumns}
        dataSource={dataSource}
      />
    </ReactDragListView.DragColumn>
  )
}

const StyledCustomTable = styled(CustomTable)`
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: ew-resize;
    z-index: 1;
  }

  .dragHandler:hover {
    cursor: move;
    background-color: #ccc;
  }
`

export default StyledCustomTable
