import React, { useState, useContext } from 'react'
import { Table, Empty, Alert, Tooltip } from 'antd'
import Button from '../override/Button'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'
import SendGiftModal from './SendGiftModal'
import { useSelector } from 'react-redux'
import { renderDateTime } from '../../share/helpers'
import { ThemeContext } from 'styled-components'
import { useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function Gifts() {
  const [errMsg, setErrMsg] = useState('')
  const [sendGiftModalVisible, setSendGiftModalVisible] = useState(false)
  const { customer } = useSelector(state => state.customer)
  const giftsSent = useSelector(state => state.user).user?.giftsSent || []
  const { t } = useTranslation()

  const theme = useContext(ThemeContext)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const columns = [
    {
      key: 'receiver',
      dataIndex: 'receiver',
      title: t('RECEIVER')
    },
    {
      key: 'plan',
      dataIndex: 'plan',
      title: t('SUBSCRIPTION_PLAN')
    },
    {
      key: 'quantity',
      dataIndex: 'quantity',
      title: t('QUANTITY')
    },
    {
      key: 'sentDate',
      dataIndex: 'sentDate',
      title: t('SENT_DATE'),
      render: text => renderDateTime(text)
    },
    {
      key: 'status',
      dataIndex: 'hasReceived',
      title: t('STATUS'),
      render: value => (value ? t('RECEIVED') : t('PENDING'))
    }
  ]

  return (
    <>
      <SimpleHeader
        title={<H4>{t('GIFTS_I_HAVE_SENT')}</H4>}
        size="small"
        extra={
          <Tooltip
            title={
              customer?.default_source
                ? null
                : t('YOU_NEED_TO_ADD_A_PAYMENT_METHOD_TO_SENT_GIFT')
            }
            placement="topRight"
            arrowPointAtCenter
          >
            <Button
              disabled={!customer?.default_source}
              type="primary"
              onClick={() => setSendGiftModalVisible(true)}
            >
              {t('SEND_GIFT')}
            </Button>
          </Tooltip>
        }
      />
      {errMsg && (
        <Alert
          message={errMsg}
          showIcon
          type="error"
          closable
          afterClose={() => setErrMsg('')}
          style={{ marginBottom: 16 }}
        />
      )}
      <Table
        dataSource={giftsSent}
        scroll={{ x: true }}
        columns={columns}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_GIFTS')}
            />
          )
        }}
        showHeader={!!giftsSent.length}
        size={isMdUp ? '' : 'middle'}
      />

      <SendGiftModal
        visible={sendGiftModalVisible}
        setVisible={setSendGiftModalVisible}
        giftsSent={giftsSent}
      />
    </>
  )
}
