import React, { useState, useContext, useEffect } from 'react'
import { Modal, Alert, message, Select } from 'antd'
import VaultContext from '../../contexts/VaultContext'
//import { StringResources } from '../../share/StringResources'
import FormItem from '../override/FormItem'
import WrappedForm from '../common/WrappedForm'
import AuthContext from '../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import api from '../../lib/api'
import { useDispatch } from 'react-redux'
import { fetchDeputies } from '../../features/deputies/deputiesSlice'
import { compareWithCurrentUserEmail } from '../../share/formHelpers'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import { onError } from '../../lib/sentry'
import NodeRSA from 'node-rsa'
import { SHARE_PERMISSIONS } from '../../share/Constants'
import { useSelector } from 'react-redux'
import SubscriptionModal from '../payment/SubscriptionModal'
import { logDocumentActivity } from '../../share/logs'
import { uniq } from 'lodash'
import AddMultipleEmails from '../common/AddMultipleEmails'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
// import { useMutation } from 'react-apollo-hooks'
// import { createS3Change } from '../../graphql/mutations'

function ShareDataModal(props) {
  const { allowSharePersons } = useSelector(state => state.customer)
  const { deputies } = useSelector(state => state.deputies)
  const { activeContacts } = useSelector(state => state.contacts)

  const { visible, setVisible, selectedDoc, viewDetail, setSelectedDoc } = props
  const { user } = useContext(AuthContext)
  const { masterKey, userId } = useContext(VaultContext)
  const [errMsg, setErrMsg] = useState('')
  const [isSharing, setIsSharing] = useState(false)
  const [email, setEmail] = useState('')
  const [subscriptionModalVisible, setSubscriptionModalVisible] =
    useState(false)
  const { activeFolders, activeFiles, breadcrumb } = useSelector(
    state => state.documents
  )

  const { t } = useTranslation()
  const dispatch = useDispatch()
  let formRef

  const listEmailFromContact = uniq(
    activeContacts.reduce((accumulator, currentValue) => {
      return [
        ...accumulator,
        ...(currentValue.emails?.map(email => email.value) || [])
      ]
    }, [])
  )

  useEffect(() => {
    getUserData(
      user,
      async (err, data) => {
        if (err) {
          onError(err)
          return
        }
        setEmail(getUserAttributeValue(data.UserAttributes, 'email'))
      },
      { bypassCache: true }
    )
  }, [user])

  const handleCancel = e => {
    formRef && formRef.props.form.resetFields()
    setErrMsg('')
    setVisible(false)
    setIsSharing(false)
  }

  const [addS3Change] = useMutation(createS3Change)

  const handleSave = () => {
    formRef.props.form.validateFields(async (err, values) => {
      if (err) return
      if (!values.emails?.length) {
        setErrMsg(t('Please add at least one email!'))
        return
      }

      if (
        (deputies?.length || 0) + values.emails.length >
        allowSharePersons - 1 //inlude current user
      ) {
        Modal.confirm({
          title: t('Limited person'),
          content: t(
            'The number of people shared is more than the number of people allowed. Please upgrade plan to continue'
          ),
          onCancel: () => {},
          okText: 'Upgrade',
          onOk: () => {
            setSubscriptionModalVisible(true)
          }
        })
        return
      }
      setIsSharing(true)
      try {
        const res = await api.getUsersByEmails(
          //filter to remove empty email which user remove from multiple field
          uniq(values.emails.filter(e => e).map(e => e.email)).join(',')
        )
        const childrenFiles =
          selectedDoc.type === 'folder'
            ? activeFiles.filter(file => file.path.includes(selectedDoc.path))
            : []
        const linkedItems = [
          ...(selectedDoc.contacts || []),
          ...(selectedDoc.documents || []),
          ...(selectedDoc.links || []),
          ...(selectedDoc.events || []),
          ...(selectedDoc.location && selectedDoc.location?.length
            ? [selectedDoc.location]
            : []),
          ...(selectedDoc.passwords || []),
          ...childrenFiles
            .map(file => [
              ...(file.contacts || []),
              ...(file.documents || []),
              ...(file.links || []),
              ...(file.events || []),
              ...(file.location?.length ? [file.location] : []),
              ...(file.passwords || [])
            ])
            .flat()
        ].map(id => {
          return {
            id,
            permission: values.permission
          }
        })

        //uniq to prevent create duplicate shared user
        const notExistedAccounts = uniq(
          values.emails
            ?.filter(e => e && !res?.data?.map(d => d.email).includes(e.email))
            .map(e => e.email)
        )

        const notExistedData =
          (await Promise.all(
            notExistedAccounts.map(e => {
              // generating public & private keys
              const key = new NodeRSA()
              key.generateKeyPair(1024) // while it's recommended to have key size as 2048, need to use this size to fit the max length of Cognito's user attribute
              const publicKey = key.exportKey('public')
              const privateKey = key.exportKey('pkcs8')

              const enKey = new NodeRSA()
              enKey.importKey(publicKey, 'public')
              const shareKey = enKey.encrypt(masterKey, 'base64')

              return {
                email: e,
                publicKey,
                privateKey,
                shareKey,
                records: [
                  {
                    id: selectedDoc?.id || selectedDoc?._id,
                    permission: values.permission,
                    isParent: true,
                    linkedItems
                  }
                ]
              }
            })
          )) || []

        const shareInfo = res?.data
          ? await Promise.all(
              res?.data?.map(d => {
                const enKey = new NodeRSA()
                enKey.importKey(d.publicKey, 'public')
                const shareKey = enKey.encrypt(masterKey, 'base64')
                return {
                  userId: d.id,
                  email: d.email,
                  publicKey: d.publicKey,
                  shareKey,
                  records: [
                    {
                      id: selectedDoc?.id || selectedDoc?._id,
                      permission: values.permission,
                      isParent: true,
                      linkedItems
                    }
                  ]
                }
              })
            )
          : []

        const data = [...shareInfo, ...notExistedData]
        await api.shareData(user.username, JSON.stringify(data))
        if (selectedDoc && Object.keys(selectedDoc).includes('path')) {
          getUserData(
            user,
            async (err, data) => {
              if (err) {
                onError(err)
                return
              }

              const parentFolders = await activeFolders.filter(folder =>
                selectedDoc.type === 'folder'
                  ? selectedDoc.path.includes(folder.path) &&
                    folder.path !== selectedDoc.path
                  : selectedDoc.path.includes(folder.path)
              )

              await logDocumentActivity(
                userId,
                data.UserAttributes,
                user.username,
                selectedDoc.id,
                {
                  id: selectedDoc.id,
                  type: selectedDoc.type === 'folder' ? 'folder' : 'file',
                  name: selectedDoc.name,
                  action: 'shared',
                  from: breadcrumb || '',
                  to: values.emails.map(e => e.email).join(',')
                }
              )

              if (parentFolders.length) {
                for (const folder of parentFolders) {
                  await logDocumentActivity(
                    userId,
                    data.UserAttributes,
                    user.username,
                    folder._id,
                    {
                      id: selectedDoc.id,
                      type: selectedDoc.type === 'folder' ? 'folder' : 'file',
                      name: selectedDoc.name,
                      action: 'shared',
                      from: breadcrumb || '',
                      to: values.emails.map(e => e.email).join(',')
                    }
                  )
                }
              }
            },
            { bypassCache: true }
          )
        }
        addS3Change({
          variables: {
            message: ' documents, pendingDocuments',
            userId: userId
          }
        })
        message.success('Successfully shared data')
        handleCancel()
        viewDetail(selectedDoc.id || selectedDoc._id)
        dispatch(fetchDeputies(user.username))
        setSelectedDoc && setSelectedDoc()
      } catch (err) {
        onError(err)
        setErrMsg(err.message)
      }
    })
  }

  return (
    <Modal
      title={t('Sharing Data')}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSave}
      className="add-deputy-modal"
      okButtonProps={{
        loading: isSharing
      }}
    >
      <div className="steps-content">
        {/* <P style={{ marginBottom: 20 }}>
          To share your data, enter email address below. If they already have a
          Bantex Hybrid eFiling account, this appointment will be saved in their
          Bantex Hybrid eFiling. Otherwise, they will be able to create a free
          limited account to be shared your data.
        </P> */}

        <WrappedForm wrappedComponentRef={fr => (formRef = fr)}>
          {(getFieldDecorator, getFieldValue) => (
            <>
              <FormItem label={t('EMAILS')}>
                <AddMultipleEmails
                  name="emails"
                  title={t('EMAIL')}
                  fields={[{ key: 'email', placeholder: 'email@example.com' }]}
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  sourceAutoComplete={listEmailFromContact}
                  customRules={[
                    {
                      fieldKey: 'email',
                      rules: [
                        {
                          type: 'email',
                          message: t('INVALID_EMAIL_MSG')
                        },
                        {
                          validator: (rule, value, callback) =>
                            compareWithCurrentUserEmail(
                              rule,
                              value,
                              callback,
                              email
                            )
                        }
                      ]
                    }
                  ]}
                />
              </FormItem>
              <FormItem label={t('Permission')}>
                {getFieldDecorator('permission', {
                  initialValue: SHARE_PERMISSIONS.READ_ACCESS
                })(
                  <Select showAction={['click', 'focus']} allowClear>
                    {Object.values(SHARE_PERMISSIONS).map(p => (
                      <Select.Option key={p} value={p}>
                        {p}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </>
          )}
        </WrappedForm>
        {errMsg && (
          <Alert
            type="error"
            message={errMsg}
            showIcon
            afterClose={() => setErrMsg('')}
            closable
            style={{ marginTop: '1em' }}
          />
        )}
        <SubscriptionModal
          visible={subscriptionModalVisible}
          setVisible={setSubscriptionModalVisible}
        />
      </div>
    </Modal>
  )
}

export default ShareDataModal
