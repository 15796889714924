import React, { useState, useEffect } from 'react'
import { Modal, Radio, message, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { onError } from '../../lib/sentry'
import { useSelector, useDispatch } from 'react-redux'
import { permanentlyDeleteItems, uploadEncryptedData } from '../../lib/pouchDb'
import api from '../../lib/api'
import { checkFolderStructure, predefinedFolders } from '../../share/helpers'
import { FOLDER_STRUCTURE } from '../../share/Constants'
import uuidv4 from 'uuid/v4'
import PouchDB from 'pouchdb'
import { fetchDocuments } from './../../features/documents/documentsSlice'
import { Tree } from 'antd'

const { TreeNode, DirectoryTree } = Tree

const UpdateFolderStructureModal = props => {
  const { visible, setVisible, userId, masterKey } = props
  const { documents } = useSelector(state => state.documents)

  const dispath = useDispatch()
  const { t } = useTranslation()
  const [selectedStructure, setSelectedStructure] = useState(
    FOLDER_STRUCTURE.BUSINESS
  )
  const [mockFolders, setMockFolders] = useState([])

  useEffect(() => {
    const updateFoldersFromKeys = (key, keys, folders, level = 1) => {
      let folderIndex = folders.findIndex(f => f.name === keys[0])

      if (folderIndex === -1) {
        folderIndex =
          folders.push({
            name: keys[0],
            key,
            level,
            folders: []
          }) - 1
      }

      if (keys.length > 1) {
        updateFoldersFromKeys(
          key,
          keys.slice(1),
          folders[folderIndex].folders,
          level + 1
        )
      }
    }

    const allFolders = predefinedFolders(
      checkFolderStructure(selectedStructure)
    )

    const updatedFolders = []
    allFolders
      .map(f => f.path)
      .sort((a, b) => a.localeCompare(b))
      .forEach(folder => {
        const keys = folder.split('/').filter(s => s !== '')

        updateFoldersFromKeys(folder, keys, updatedFolders)
      })

    setMockFolders(updatedFolders)
  }, [selectedStructure])

  const renderTreeNodes = (nodes, parentIndex) => {
    return nodes.map((node, index) => {
      const currentIndex =
        parentIndex !== undefined ? parentIndex + '-' + index : index

      return (
        <TreeNode title={node.name} key={node.key}>
          {node.folders.length && renderTreeNodes(node.folders, currentIndex)}
        </TreeNode>
      )
    })
  }
  const [loading, setLoading] = useState(false)

  const handleOk = async () => {
    setLoading(true)
    const db = new PouchDB(`${userId}_documents`)
    try {
      if (!!documents.length) {
        await permanentlyDeleteItems('documents', userId, documents, masterKey)
      }
      const data = predefinedFolders(checkFolderStructure(selectedStructure))

      await api.updateFolderStructure(
        userId,
        JSON.stringify({ folderStructure: selectedStructure })
      )

      db.crypto(masterKey)
      const initialDocs = data.map(record => {
        const id = uuidv4()
        const doc = {
          ...record,
          _id: record.idPrefix ? `${record.idPrefix}_${id}` : id
        }
        // delete as we don't need to store this
        delete doc.idPrefix
        return doc
      })

      await db.bulkDocs(initialDocs)
      await uploadEncryptedData(db, userId, 'documents')
      dispath(fetchDocuments(userId, masterKey))
      message.success('updated successfully!')
      setVisible(false)
    } catch (error) {
      onError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      width={500}
      visible={visible}
      title={t('Folder structure')}
      okText={t('SAVE')}
      maskClosable={false}
      closable={false}
      footer={
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Button loading={loading} type="primary" onClick={handleOk}>
            {t('OK')}
          </Button>
        </div>
      }
    >
      <Radio.Group
        onChange={e => setSelectedStructure(e.target.value)}
        value={selectedStructure}
      >
        {Object.values(FOLDER_STRUCTURE).map(type => (
          <Radio key={type} value={type}>
            {type}
          </Radio>
        ))}

        <DirectoryTree
          style={{ marginTop: 20 }}
          expandAction="doubleClick"
          className="file-directory"
        >
          {renderTreeNodes(mockFolders)}
        </DirectoryTree>
      </Radio.Group>
    </Modal>
  )
}
export default UpdateFolderStructureModal
