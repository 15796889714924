export const StringResourcesVn = {
  INPUT_TOTP_CODE: 'Nhập mã xác thực từ ứng dụng Authenticator của bạn.',
  INPUT_SMS_CODE:
    'Nhập mã xác minh trong SMS được gửi đến số điện thoại đã đăng ký của bạn.',
  SETUP_MFA: 'Thiết lập xác thực đa yếu tố',
  SAME_PASSWORD_MSG: 'Mật khẩu mới phải khác với mật khẩu cũ!',
  PASSWORDS_DO_NOT_MATCH_MSG: 'Mật khẩu bạn nhập không khớp.',
  INPUT_CURRENT_PASSWORD_MSG: 'Nhập mật khẩu cũ!',
  INPUT_NEW_PASSWORD_MSG: 'Nhập mật khẩu mới!',
  CONFIRM_NEW_PASSWORD_MSG: 'Vui lòng xác nhận mật khẩu mới của bạn!',
  CHANGE_PASSWORD: 'Đổi mật khẩu',
  CURRENT_PASSWORD: 'Mật khẩu hiện tại',
  NEW_PASSWORD: 'Mật khẩu mới',
  CONFIRM_NEW_PASSWORD: 'Xác nhận mật khẩu mới',
  CLOSE_ACCOUNT: 'Xóa tài khoản',
  CONFIRM_CLOSE_ACCOUNT_MSG:
    'Bạn có chắc rằng bạn muốn xóa tài khoản? Tất cả dữ liệu của bạn sẽ bị xóa và không thể lấy lại được trong tương lai.',
  SETUP_MFA_INSTRUCTION:
    'Bạn cần thiết lập xác thực đa yếu tố để bảo vệ bantex của bạn.',
  INPUT_PASSWORD_OR_EMAIL_MSG: 'Nhập tên người dùng hoặc email của bạn!',
  INPUT_PASSWORD_MSG: 'Nhập mật khẩu của bạn!',
  INPUT_EXISTING_DEPUTY_EMAIL_MSG:
    'Email này đã tồn tại trong danh sách người đại diện!',
  INPUT_CURRENT_USER_EMAIL_MSG:
    'Nhập một email khác, không phải email của bạn!',
  FORGOT_PASSWORD: 'Quên mật khẩu',
  LOGIN: 'Đăng nhập',
  REMEMBER_ME: 'Ghi nhớ',
  SECRET_CODE: 'Mã xác thực',
  FRIENDLY_DEVICE_NAME: 'Thiết bị TOTP của tôi',
  USERNAME_OR_EMAIL: 'Tên đăng nhập/Email',
  SETTINGS: 'Cài đặt',
  FILES: 'Tệp',
  SIGN_OUT: 'Đăng xuất',
  INVALID_CODE_TYPE: 'Mã phải là số có 6 chữ số!',
  PASSWORD_RULES:
    'Mật khẩu có tối thiểu tám ký tự, ít nhất một chữ hoa, một chữ thường, một chữ số và một ký tự đặc biệt.',
  ACTION: 'Hành động',
  PENDING_ACCEPTANCE_OF_DEPUTY: 'Chờ chấp thuận',
  DEPUTY_MANAGAMENT: 'Quản lý người đại diện',
  PROFILE_AND_SETTINGS: 'Hồ sơ và thiết lập',
  REQUESTS: 'Các yêu cầu',
  PRIMARY_USERS: 'Người dùng chính',
  RESEND: 'Gửi lại',
  REVOKE: 'Thu hồi',
  REACTIVATE: 'Kích hoạt lại',
  PAYMENT: 'Thanh toán',
  SIGN_IN: 'Đăng nhập',
  INPUT_FULLNAME_MSG: 'Nhập tên đầy đủ của bạn!',
  USE_SAME_NAME_AS_PASSPORT_TOOLTIP: 'Sử dụng tên đầy đủ theo CMND / hộ chiếu',
  INPUT_PHONE_NUMBER_MSG: 'Nhập sô điện thoại!',
  INVALID_EMAIL_MSG: 'Nhập địa chỉ email hợp lệ!',
  INPUT_EMAIL_MSG: 'Nhập email của bạn!',
  CONFIRM_PASSWORD_MSG: 'Vui lòng xác nhận mật khẩu của bạn!',
  FULLNAME: 'Tên đầy đủ',
  PREFERRED_NAME: 'Tên thường gọi',
  PHONE_NUMBER: 'Số điện thoại',
  EMAIL: 'Địa chỉ email',
  SHARE_KEY_STATUS: 'Chia sẻ trạng thái khóa',
  UNLOCK_STATUS: 'Mở khóa trạng thái',
  PASSWORD: 'Mật khẩu',
  CONFIRM_PASSWORD: 'Xác nhận mật khẩu',
  VERIFICATION_CODE: 'Mã xác thực',
  CONTACT_NUMBER: 'Số điện thoại',
  ID_PASSPORT_NUMBER: 'Số CMND / Hộ chiếu',
  NATIONALITY: 'Quốc tịch',
  RESIDENTIAL_ADDRESS: 'Địa chỉ cư trú',
  COUNTRY_TERRITORY: 'Quốc gia / Vùng lãnh thổ',
  RESET_PASSWORD: 'Đặt lại mật khẩu',
  RESET_PASSWORD_INSTRUCTION:
    'Để đặt lại mật khẩu của bạn, vui lòng nhập mã xác minh đã được gửi đến email của bạn, sau đó nhập mật khẩu mới của bạn.',
  INPUT_VERIFICATION_CODE_MSG: 'Nhập mã xác minh',
  FILE_NAME: 'Tên tệp',
  DESCRIPTION: 'Mô tả',
  TAGS: 'Gắn thẻ',
  DOWNLOAD: 'Tải xuống',
  VIEW: 'Xem',
  EDIT: 'Chỉnh sửa',
  SAVE: 'Lưu lại',
  CANCEL: 'Bỏ qua',
  UPLOAD: 'Tải lên',
  OR: 'hoặc',
  INPUT_FILE_NAME_MSG: 'Nhập tên tệp',
  SAVE_AS_TYPE: 'Lưu thành',
  INPUT_SHORT_DESCRIPTION_MSG: 'Nhập mô tả ngắn gọn',
  FILE_DETAILS: 'Chi tiết tệp',
  PATH: 'Đường dẫn',
  RENAME: 'Đổi tên',
  SEARCH: 'Tìm kiếm',
  RESET: 'Đặt lại',
  START_TIME: 'Thời gian bắt đầu',
  END_TIME: 'Thời gian kết thúc',
  SELECT_START_DATE: 'Chọn ngày bắt đầu',
  SELECT_END_DATE: 'Chọn ngày kết thúc',
  FILL_IN_SEARCH_FIELD_MSG: 'Vui lòng điền vào ít nhất một trường tìm kiếm',
  SEARCH_RESULTS: 'Kết quả',
  UPLOAD_FILE: 'Tải lên',
  FILE: 'Tệp',
  SELECT_FILE_TO_UPLOAD_MSG: 'Chọn một tệp để tải lên',
  UPLOAD_FILE_INSTRUCTION: 'Nhấp hoặc kéo tệp vào khu vực này để tải lên',
  MOVE: 'Di chuyển',
  MOVE_FILE: 'Di chuyển tệp tin',
  BROWSE: 'Duyệt',
  DESTINATION: 'Nơi đến',
  LINK: 'Liên kết',
  UNLINK: 'Hủy liên kết',
  FAVOURITES: 'Yêu thích',
  TITLE: 'Tiêu đề',
  TYPE: 'Loại',
  ASSET: 'Tài sản',
  LIABILITY: 'Nợ',
  DESCRIPTION_COL: 'Mô tả',
  PERCENTAGE_OWNERSHIP: 'Tỷ lệ sở hữu',
  PURCHASE_DATE: 'Ngày mua',
  OK: 'Ok',
  ACTIONS: 'Hành động',
  EDIT_ASSET_LIABILITY: 'Chỉnh sửa tài sản / nợ',
  REMOVE_ASSET_LIABILITY: 'Xóa tài sản / nợ',
  LINK_TO_ASSETS_LIABILITIES_TOOLTIP:
    'Liên kết với một hoặc nhiều tài sản hoặc nợ khác',
  LINK_TO_DOCUMENTS_TOOLTIP: 'Liên kết đến một hoặc nhiều tài liệu',
  VIEW_PREVIOUS_VERSIONS_ASSET_LIABILITY:
    'Xem các phiên bản trước của tài sản / nợ',
  ADD_ASSET_LIABILITY_SUCCESS: 'Thêm tài sản / nợ thành công',
  UPDATE_ASSET_LIABILITY_SUCCESS: 'Cập nhật tài sản / nợ thành công',
  REACTIVATE_SUBSCRIPTION_SUCCESS: 'Tái kích hoạt thanh toán thành công',
  REACTIVATE_SUBSCRIPTION_FAIL: 'Không thể tái kích hoạt thanh toán',
  ADD_ASSET_LIABILITY: 'Thêm tài sản / nợ',
  INPUT_TITLE_MSG: 'Nhập tiêu đề!',
  INPUT_DESCRIPTION_MSG: 'Nhập mô tả!',
  INPUT_PURCHASE_DATE_MSG: 'Nhập ngày mua!',
  CURRENCY: 'Đơn vị tiền tệ',
  CCY: 'Đvị',
  VALUATION_ASSET_LIABILITY_CURRENCY: 'Định giá bằng đơn vị tài sản / nợ',
  ASSETS_CURRENCY: 'Đơn vị giá trị tài sản',
  VALUATION_ASSET_CURRENCY: 'Định giá bằng đơn vị tài sản',
  VALUATION_BASE_CURRENCY: 'Định giá bằng đơn vị mặc định',
  START_DATE: 'Ngày bắt đầu',
  INPUT_START_DATE: 'Nhập ngày bắt đầu!',
  MATURITY_DATE: 'Ngày đáo hạn',
  LIABILITIES_CURRENCY: 'Đơn vị giá trị nợ',
  SUCCESSFULLY_SAVED_COLUMNS: 'Lưu thành công',
  SHOW_HIDE_COLUMNS: 'Ẩn/hiện cột',
  FAILED_TO_SAVE_COLUMNS: 'Không thể lưu',
  SAVE_COLUMNS_TOOLTIP: 'Lưu cấu hình cột làm mặc định',
  SAVE_COLUMNS: 'Lưu cột',
  RESET_TOOLTIP: 'Đặt lại về mặc định',
  OUTSTANDING_VALUE_LIABILITY_CURRENCY: 'Tiền chưa thanh toán theo đơn vị nợ',
  OUTSTANDING_VALUE_BASE_CURRENCY: 'Tiền chưa thanh toán theo đơn vị mặc định',
  LINK_ASSETS_LIABILITIES: 'Liên kết đến tài sản / nợ',
  LINK_DOCUMENTS: 'Liên kết đến tài liệu',
  SELECT_ASSETS_LIABILITIES_TO_LINK: 'Lựa chọn tài sản / nợ để liên kết',
  ASSETS_LIABILITIES: 'Tài sản / Nợ',
  SELECT_ASSETS_LIABILITIES: 'Lựa chọn tài sản / nợ',
  LINK_ASSETS_LIABILITIES_SUCCESS:
    'Cập nhật liên kết đến tài sản / nợ thành công',
  VIEW_LINKED_RECORDS: 'Xem bản ghi đã liên kết',
  SET_BASE_CURRENCY: 'Thiết lập đơn vị tiền tệ mặc định',
  SELECT_CURRENCY: 'Chọn đồng tiền',
  BASE_CURRENCY: 'Đồng tiền cơ bản',
  GROSS_ASSET_VALUE: 'Tổng giá trị tài sản',
  GROSS_OUTSTANDING_VALUE: 'Tổng giá trị chưa thanh toán',
  NET_VALUE: 'Giá trị ròng',
  GROUP_BY_CURRENCY: 'Nhóm theo đơn vị tiền tệ',
  TIME: 'Thời gian',
  ASSET_PURCHASE_DATE: 'Ngày mua tài sản',
  LIABILITY_START_DATE: 'Ngày bắt đầu trách nhiệm pháp lý',
  LIABILITY_MATURITY_DATE: 'Ngày đáo hạn trách nhiệm',
  VALUATION_IN_ASSET_LIABILITY_CURRENCY: 'Định giá theo đơn vị tài sản / nợ',
  OTHER_ASSET_TYPE: 'Loại tài sản khác',
  VALUATION_DATE: 'Ngày định giá',
  TYPE_OF_INTEREST_RATE: 'Loại lãi suất',
  SECURITY_COLLATERAL: 'Tài sản bảo đảm / thế chấp',
  RECURRING_PAYMENT_DUE_DAY: 'Ngày đến hạn thanh toán định kỳ',
  ISSUING_BANK: 'Ngân hàng phát hành',
  CREDIT_LIMIT: 'Giới hạn tín dụng',
  STATEMENT_DATE: 'Ngày sao kê',
  CLASS_OF_SHARES: 'Loại cổ phiếu',
  SHARE_CERTIFICATE_NUMBER: 'Chia sẻ số chứng chỉ',
  PERCENTAGE_OF_SHARES: 'Phần trăm cổ phiếu trong loại này',
  DISPOSAL_DATE: 'Ngày loại bỏ',
  ACCOUNT_TYPE: 'Loại tài khoản',
  BALANCE_AMOUNT: 'Số dư',
  BUILDING_TYPE: 'Loại công trình',
  BUILD_DATE: 'Ngày xây dựng',
  FLOOR_SIZE: 'Kích thước sàn',
  FLOOR_SIZE_VALUE: 'Giá trị kích thước sàn',
  FLOOR_SIZE_UNIT: 'Đơn vị kích thước sàn',
  PROPERTY_ADDRESS: 'Địa chỉ bất động sản',
  PROPERTY_ADDRESS_MSG: 'Nhập địa chỉ bất động sản',
  HOLDING_TYPE: 'Hình thức sở hữu',
  LEASEHOLD_DURATION: 'Thời hạn thuê',
  TENANT: 'Người thuê nhà',
  RENT_AMOUNT: 'Số tiền thuê',
  RENT_AMOUNT_VALUE: 'Số tiền thuê',
  RENT_AMOUNT_UNIT: 'Đơn vị tiền thuê',
  RENTAL_START_DATE: 'Ngày bắt đầu cho thuê',
  RENTAL_END_DATE: 'Ngày kết thúc thuê',
  EXCHANGE: 'Sàn giao dịch',
  NUMBER_OF_SHARES: 'Số lượng cổ phiếu',
  EXPIRY_DATE: 'Ngày hết hạn',
  PAYMENT_DUE_DATE: 'Ngày đến hạn thanh toán',
  LENDER: 'Bên cho vay',
  BORROWER: 'Bên vay',
  LOAN_PERIOD: 'Thời gian cho vay',
  LOAN_PERIOD_VALUE: 'Giá trị thời gian vay',
  LOAN_PERIOD_UNIT: 'Đơn vị thời gian vay',
  LENGTH_OF_LOAN_VALUE: 'Giá trị thời gian vay',
  LENGTH_OF_LOAN_UNIT: 'Đơn vị thời gian vay',
  NUMBER_OF_REPAYMENTS: 'Số lần hoàn trả',
  MONTHLY_PAYMENT: 'Thanh toán hàng tháng',
  ACCOUNT_NUMBER: 'Số tài khoản',
  LENGTH_OF_LOAN: 'Thời gian vay',
  YEAR_OF_LOAN: 'Năm vay',
  OTHER_NOTES: 'Các ghi chú khác',
  COMPANY: 'Công ty',
  BENEFICIARIES: 'Người thụ hưởng',
  INSURANCE_ADVISOR: 'Cố vấn bảo hiểm',
  ENTITLEMENT: '% Quyền lợi',
  POLICY_NUMBER: 'Số chính sách',
  INSURER: 'Bên bảo hiểm',
  PREMIUM: 'Tiền thưởng',
  PREMIUM_VALUE: 'Giá trị thưởng',
  PREMIUM_UNIT: 'Đơn vị thưởng',
  SUM_ASSURED_IN_BASE_CURRENCY: 'Số tiền bảo hiểm theo đơn vị mặc định',
  SUM_ASSURED_IN_ASSET_CURRENCY: 'Số tiền bảo hiểm theo đơn vị tài sản',
  TYPE_OF_POLICY: 'Loại chính sách',
  LIFE_ASSURED: 'Người được bảo hiểm',
  LIFE_ASSURED_TOOLTIP: 'Người được bảo hiểm trong hợp đồng chính sách',
  POLICY_OWNER: 'Người sở hữu hợp đồng',
  POLICY_OWNER_TOOLTIP:
    'Người thanh toán phí bảo hiểm chính sách và có quyền sở hữu hợp đồng chính sách',
  BANK: 'Ngân hàng',
  INTEREST_RATE: 'Lãi suất',
  INTEREST_RATE_VALUE: 'Giá trị lãi suất',
  INTEREST_RATE_UNIT: 'Đơn vị lãi suất',
  SUBTYPE: 'Loại phụ',
  AMOUNT_BORROWED: 'Số tiền đã vay',
  ASSET_LINKS: 'Liên kết tài sản',
  SEARCH_ASSETS: 'Tìm kiếm tài sản',
  LIABILITY_LINKS: 'Liên kết nợ',
  SEARCH_LIABILITIES: 'Tìm kiếm nợ',
  POSITION_DATE: 'Ngày sở hũu',
  BALANCE_DATE: 'Ngày khởi tạo',
  ASSETS: 'Tài sản',
  LIABILITIES: 'Nợ',
  INPUT_BALANCE_DATE_MSG: 'Nhập ngày khởi tạo',
  INPUT_POSITION_DATE_MSG: 'Nhập ngày sở hữu',
  QUANTITY: 'Số lượng',
  BROKERAGE_NAME: 'Tên môi giới',
  JOINT_ACCOUNT: 'Tài khoản chung',
  VEHICLE_REGISTRATION_NUMBER: 'Số đăng ký xe',
  RETIREMENT_ACCOUNT_TYPE: 'Loại tài khoản hưu trí',
  EXPORT_TO_PDF: 'Xuất sang PDF',
  EXPORT_TO_PDF_WARNING:
    'Bạn đã chọn hơn 10 cột. Để dữ liệu được trình bày đẹp mắt trong tệp PDF đã xuất, bạn nên chọn ít hơn 10 cột.',
  INCLUDE_IN_NETWORTH: 'Bao gồm giá trị trong Tổng giá trị tài sản ròng',
  TRUST_TYPE: 'Loại ủy thác',
  TYPE_OF_TRUST_INTEREST: 'Loại lãi suất ủy thác',
  TRUST_REVOCABILITY: 'Khả năng thu hồi ủy thác',
  CONTACTS: 'Danh bạ',
  ADD_CONTACT: 'Thêm liên hệ',
  NAME: 'Tên',
  ADD_CONTACT_SUCCESS: 'Đã thêm liên hệ thành công',
  INPUT_NAME_MSG: 'Nhập tên!',
  INPUT_GIVEN_NAME_MSG: 'Nhập tên!',
  INPUT_DISPLAY_NAME_MSG: 'Nhập tên hiển thị!',
  INPUT_LEGAL_NAME_MSG: 'Nhập tên hợp pháp!',
  ORGANISATION: 'Tổ chức',
  INDIVIDUAL: 'Cá nhân',
  GIVEN_NAME: 'Tên',
  SURNAME: 'Họ',
  DATE_OF_BIRTH: 'Ngày sinh',
  DISPLAY_NAME: 'Tên hiển thị',
  LEGAL_NAME: 'Tên hợp pháp',
  EMAILS: 'Emails',
  CONTACT_INFO: 'Contact info',
  ADDRESSES: 'Địa chỉ',
  REFERENCES: 'Thông tin liên quan',
  URL: 'Đường dẫn',
  EDIT_CONTACT: 'Chỉnh sửa liên hệ',
  REMOVE_CONTACT: 'Xóa liên hệ',
  EDIT_CONTACT_SUCCESS: 'Đã chỉnh sửa thành công địa chỉ liên hệ',
  REMOVE_CONTACT_SUCCESS: 'Đã xóa liên hệ thành công',
  REMOVE_CONTACT_CONFIRM: 'Bạn có chắc chắn xóa liên hệ này không?',
  VIEW_PREVIOUS_VERSIONS_CONTACT: 'Xem các phiên bản trước của liên hệ',
  CREATE_NEW_FOLDER: 'Tạo thư mục mới',
  RENAME_FOLDER: 'Đổi tên thư mục',
  INPUT_FOLDER_NAME_MSG: 'Nhập tên thư mục',
  FOLDER_NAME: 'Tên thư mục',
  NEW_FOLDER: 'Thư mục mới',
  DELETE: 'Xóa',
  CONFIRM_DELETE: 'Xác nhận xóa',
  CONFIRM_DELETE_ITEM_MSG: 'Bạn có chắc chắn xóa mục này không?',
  CONFIRM_DELETE_ITEMS_MSG: 'Bạn có chắc chắn xóa những mục này không?',
  PERCENTAGE_OWNERSHIP_TOOLTIP: 'Nhập tổng tỷ lệ sở hữu',
  VALUATION_IN_BASE_CURRENCY_TOOLTIP:
    'Nếu bạn để trống phần này, giá trị sẽ tự động được chuyển đổi từ đơn vị tính tài sản sang đơn vị tiền tệ mặc định của bạn theo tỷ giá hối đoái bạn đã đặt trong cài đặt của mình',
  OUTSTANDING_AMOUNT_IN_BASE_CURRENCY_TOOLTIP:
    'Nếu bạn để trống phần này, giá trị sẽ tự động được chuyển đổi từ đơn vị tính nợ sang đơn vị tiền tệ mặc định của bạn theo tỷ giá hối đoái bạn đã đặt trong cài đặt của mình',
  LIABILITY_VALUATION_DATE_TOOLTIP:
    'Đây là ngày cụ thể của số tiền chưa thanh toán dưới đây',
  LOW_STORAGE_SPACE: 'Dung lượng lưu trữ sắp hết',
  LOW_STORAGE_SPACE_CONTENT:
    'Không có đủ dung lượng trống. Vui lòng xóa các tệp không sử dụng hoặc nâng cấp gói đăng ký của mình để có thêm dung lượng.',
  COUNTRY_TERRITORY_OF_INCORPORATION: 'Quốc gia / lãnh thổ thành lập',
  COMPANY_REGISTRATION_NUMBER: 'Số đăng ký công ty',
  PHONE: 'Điện thoại',
  REGISTRY: 'Đăng ký',
  DEPUTY: 'Đại diện',
  TRASH: 'Thùng rác',
  DOCUMENTS: 'Tài liệu',
  DETAILS: 'Chi tiết',
  EXPORT_TO_EXCEL: 'Xuất sang Excel',
  PASSPORT_NUMBER: 'Số hộ chiếu',
  PROFILE_INFORMATION: 'Thông tin tài khoản',
  EXCHANGE_RATES: 'Tỷ giá hối đoái',
  MY_DEPUTIES: 'Người đại diện',
  PHONE_NUMBERS: 'Số điện thoại',
  ALL_FILES: 'Tất cả các tệp',
  CRYPTO_CURRENCY: 'Tiền điện tử',
  WALLET_ADDRESS: 'Địa chỉ ví',
  PD_NAME: 'Được ủy nhiệm bởi Phó chuyên môn',
  PRICE: 'Giá',
  WALLET_PROVIDER: 'Nhà cung cấp ví',
  PENDING_RECOVERY: 'Đang khôi phục',
  RESET_ACCOUNT_NOW: 'Đặt lại ngay',
  WILL_WAIT: 'Vâng, Tôi sẽ đợi',
  RECOVER_DATA_FIRST_CONTENT:
    'Bạn cần đợi sự chấp thuận của người đại diện để khôi phục lại dữ liệu.',
  RECOVER_DATA_SECOND_CONTENT:
    'Nếu bạn không thiết lập người đại diện hoặc người đại diện của bạn không còn nữa, dữ liệu hiện có của bạn sẽ không thể khôi phục được và bạn cần đặt lại tài khoản của mình.',
  RESET_ACCOUNT_CONFIRMATION: 'Xác nhận đặt lại tài khoản',
  RESET_ACCOUNT_MODAL_CONTENT: 'Bạn có chắc chắn muốn đặt lại tài khoản không?',
  SUCCESSFULLY_RESET_ACCOUNT: 'Đặt lại tài khoản thành công.',
  FAILED_TO_RESET_ACCOUNT: 'Có lỗi khi đặt lại tài khoản',
  WELL_COME_TO_BANTEX: 'Chào mừng đến với',
  BY_CONTINUING: 'Để tiếp tục bạn cần đồng ý với',
  TERMS_OF_SERVICE: 'Điều khoản dịch vụ',
  RESET_MFA: 'Đặt lại xác thực đa yếu tố',
  RESET_MFA_FIRST_CONTENT:
    'Nếu bạn bị mất hoặc đặt lại điện thoại của mình và không thể lấy mã xác thực từ ứng dụng Authenticator nữa, bạn có thể đặt lại xác thực đa yếu tố để thiết lập lại.',
  RESET_MFA_SECOND_CONTENT:
    'Khi bạn đặt lại xác thực đa yếu tố, một tin nhắn chứa mã OTP sẽ được gửi đến số điện thoại đã đăng ký của bạn. Dùng mã đó để hoàn tất việc đặt lại.',
  RESET_MFA_WITH_PHONE_NUMBER:
    'Đặt lại xác thực đa yếu tố bằng số điện thoại mới',
  RESET_MFA_WITH_PHONE_NUMBER_CONTENT:
    'Nếu bạn bị mất điện thoại và số điện thoại, bạn có thể đặt lại xác thực đa yếu tố bằng số điện thoại mới. Hành động này cần được sự chấp thuận của người đại diện.',
  FAIL_TO_GET_COUNTRY_CODE: 'Không lấy được mã quốc gia',
  GIFT_INFO_NOT_FOUND: 'Không tìm thấy thông tin quà tặng',
  GIFT_RECEIVER_EMAIL_MISSMATCH:
    'Địa chỉ email của người nhận quà tặng không khớp. Vui lòng đăng ký bằng địa chỉ email nhận quà.',
  GIFT_PLAN_MISSMATCH: 'Gói quà tặng không khớp',
  SUBSCRIPTION_PLAN_EMPTY_MSG: 'Vui lòng chọn gói đăng ký',
  INVALID_PROMOTION_CODE: 'Mã khuyến mại không hợp lệ',
  PROMOTION_CODE_MSG: 'Không thể áp dụng mã khuyến mãi này cho gói đã chọn.',
  FAILED_TO_CHECK_CODE_VALIDATE: 'Không thể kiểm tra tính hợp lệ của mã',
  PERSONAL_INFO_DESCRIPTION:
    'Vui lòng ghi nhớ mật khẩu này vì không ai khác ngoài bạn có thể truy cập vào <strong>bantex</strong> của bạn cho đến khi bạn đăng nhập và chỉ định người đại diện của mình.',
  SUBSCRIPTION_PLANS: 'Gói đăng ký',
  REVIEW: 'Xem lại',
  PERSONAL_INFORMATION: 'Thông tin cá nhân',
  SUBSCRIPTION: 'Đăng ký',
  PLAN: 'Kế hoạch',
  FREE: 'Miễn phí',
  APPLIED_REFERRAL_CODE: 'Đã áp dụng mã giới thiệu ',
  APPLIED_PROMOTION: 'Đã áp dụng khuyến mãi',
  PROMOTION_NAME: 'Tên khuyến mại',
  PERCENTAGE_DISCOUNT: 'Chiết khấu phần trăm',
  FIXED_AMOUNT_DISCOUNT: 'Chiết khấu cố định',
  DURATION: 'Thời hạn',
  ONCE: 'Một lần',
  FOREVER: 'Mãi mãi',
  READ_AND_AGREE_TERM_OF_SERVICE: 'Tôi đã đọc và đồng ý với ',
  MARKETING_CONSENT_FROM_VAULTBOX:
    'Tôi đồng ý nhận quảng cáo liên quan đến <strong>bantex</strong> từ Sircured, các chi nhánh và đối tác của Sircured.',
  MARKETING_CONSENT_FROM_THIRT_PARTY:
    'Tôi đồng ý nhận quảng cáo về các vấn đề mà tôi có thể quan tâm từ Sircured, các chi nhánh, đối tác và bên thứ ba của Sircured.',
  NOT_AGREE_TERMS_OF_SERVICE_MSG: 'Vui lòng đồng ý với điều khoản dịch vụ',
  PREVIOUS: 'Quay lại',
  SUBMIT: 'Gửi',
  NEXT: 'Tiếp theo',
  REGISTER: 'Đăng ký',
  REGISTER_DESCRIPTION: 'Quản lý và bảo vệ những điều quan trọng',
  CREATE_A_NEW_ACCOUNT: 'Tạo mới một tài khoản',
  ACCOUNT_CREATED: 'Đã tạo tài khoản',
  ACCOUNT_CREATED_SUCCESSFULLY_MSG:
    'Một email đã được gửi đến <strong>{{email}}</strong> để xác minh địa chỉ email của bạn. Vui lòng làm theo hướng dẫn trong email để kích hoạt tài khoản.',
  RETURN_TO_HOMEPAGE: 'Trở về trang chủ',
  INPUT_PASSPORT_NUMBER_MSG: 'Vui lòng nhập số hộ chiếu!',
  MOBLIE_NUMBER_WITH_CODE: 'Điện thoại di động (chọn mã quốc gia)',
  INPUT_BASE_CURRENCY_MSG: 'Chọn đơn vị tiền tệ mặc định!',
  BASE_CURRENCY_TOOLTIP:
    'Đây là đơn vị tiền tệ mặc định dùng cho tài sản và nợ của bạn. Bạn có thể thay đổi điều này sau trong cài đặt.',
  MONTHS: 'tháng',
  SELECT_COUNTRY_CODE: 'Chọn mã quốc gia',
  INVALID_MOBLIE_NUMBER: 'Số điện thoại không hợp lệ',
  iS_LONG_ENOUGHT: 'Ít nhất 8 ký tự',
  HAS_NUMBER: 'Ít nhất 1 chữ số',
  HAS_SPECIAL_CHAR: 'Ít nhất 1 ký tự đặc biệt',
  HAS_UPPERCASE: 'Ít nhất 1 chữ hoa',
  HAS_LOWERCASE: 'Ít nhất 1 chữ thường',
  INVALID_PASSWORD: 'Mật khẩu không hợp lệ',
  THANKS_AND_GOODBYE: 'Cảm ơn và tạm biệt',
  DELETED_ACCOUNT_SUCCESSFULLY_MSG:
    'Tài khoản <strong>bantex</strong> của bạn đã bị xóa.',
  RESET_PASSWORD_BY_EAMIL: 'Đặt lại mật khẩu bằng email',
  COMPLETE: 'Hoàn thành',
  ENTER_YOUR_EMAIL_ADDRESS: 'Nhập địa chỉ email của bạn',
  RESET_PASSWORD_WITH_DEPUTIES_DESCRIPTION:
    'Yêu cầu khôi phục tài khoản đã được gửi đến người đại diện của bạn. Bạn có thể đăng nhập bằng mật khẩu mới sau khi tất cả người đại diện mở khóa tài khoản.',
  RESET_PASSWORD_DESCRIPTION:
    'Tài khoản của bạn đã được đặt lại vì bạn không có đủ người đại diện để giúp khôi phục dữ liệu của mình.',
  CLICK_TO_GO_TO_HOMEPAGE: 'Nhấn <i>Hoàn thành</i> để tới trang chủ.',
  ACCOUNT_RECOVERY: 'Khôi phục tài khoản',
  RECOVER_ACCESS_TO_YOUR_ACCOUNT:
    'Khôi phục quyền truy cập vào tài khoản của bạn',
  FINISH: 'Hoàn thành',
  ABOUT_US: 'Về chúng tôi',
  HELP: 'Trợ giúp',
  PRIVACY: 'Riêng tư',
  ALREADY_HAVE_AN_ACCOUNT: 'Đã có tài khoản?',
  NEW_TO_VAULTBOX: 'Lần đầu sử dụng <strong>bantex</strong>?',
  RESET_PASSWORD_TITLE:
    'Nhập mã xác nhận được gửi tới email của bạn và nhập mật khẩu mới.',
  ACCOUNT_ACTIVATION_FAILED: 'Kích hoạt tài khoản không thành công',
  ACCOUNT_ACTIVATION_SUCCESSED:
    'Tài khoản <strong>bantex</strong> của bạn đã được kích hoạt thành công.',
  ACTIVATING: 'Đang kích hoạt...',
  DATA_RECOVERY: 'Khôi phục dữ liệu',
  DATA_RECOVERY_SUMMARY: 'Nhập mật khẩu để cập nhật mã hóa dữ liệu',
  SUCCESSFULLY_RECOVERED_DATA: 'Khôi phục dữ liệu thành công',
  FAILED_TO_RECOVER_DATA: 'Không khôi phục được dữ liệu',
  UPDATE_PHONE_NUMBER_REQUEST_SUCCESSFULLY:
    'Yêu cầu đã được gửi đến người đại diện. Vui lòng chờ xác nhận.',
  YOU_DO_NOT_HAVE_ANY_DEPUTIES: 'Bạn không có người đại diện nào.',
  RESET_PHONE_NUMBER: 'Đặt lại số điện thoại',
  RESET_PHONE_NUMBER_SUMMARY: 'Sửa số điện thoại thông qua người đại diện',
  NEW_PHONE_NUMBER: 'Số điện thoại mới (Lựa chọn mã quốc gia)',
  SUCCESSFULLY_RESTORED_ITEMS: 'Khôi phục thành công.',
  FAILED_TO_RESTORE_ITEMS: 'Không thể khôi phục.',
  PERMANENTLY_DELETE: 'Xóa vĩnh viễn',
  PERMANENTLY_DELETE_ITEM_CONFIRM: 'Bạn có chắc muốn xóa mục này vĩnh viễn?',
  PERMANENTLY_DELETE_ITEMS_CONFIRM:
    'Bạn có chắc muốn xóa các mục này vĩnh viễn?',
  SUCCESSFULLY_DELETED_ITEM: 'Xóa thành công',
  SUCCESSFULLY_DELETED_ITEMS: 'Xóa thành công {{count}} mục',
  FAILED_TO_DELETE_ITEM: 'Không thể xóa mục',
  FAILED_TO_DELETE_ITEMS: 'Không thể xóa các mục',
  PERMANENTLY_DELETE_ALL: 'Xóa vĩnh viễn toàn bộ',
  PERMANENTLY_DELETE_ALL_CONFIRM:
    'Bạn có chắc muốn xóa vĩnh viễn toàn bộ các mục?',
  SUCCESSFULLY_DELETED_ALL_ITEMS: 'Xóa toàn bộ thành công',
  RESTORE_ALL: 'Khôi phục toàn bộ',
  RESTORE_ALL_CONFIRM: 'Bạn có chắc muốn khôi phục toàn bộ các mục?',
  DELETE_ASSETS_AND_LIABILITIES: 'Xóa Tài sản và Nợ',
  DELETED_CONTACTS: 'Xóa Liên hệ',
  DELETED_FILE_AND_FOLDERS: 'Xóa Tệp và Thư mục',
  SELECTED_RECORDS: 'Xóa bản ghi',
  BULK_DELETE: 'Xóa hàng loạt',
  SEARCH_FIL_OR_FOLDER: 'Tìm kiếm tệp và thư mục',
  SEARCH_CONTACT: 'Tìm kiếm liên hệ',
  SEARCH_ASSET_OR_LIABILITY: 'Tìm kiếm tài sản hoặc nợ',
  TOUR_INTRO:
    '<strong>bantex</strong> - két an toàn kỹ thuật số của bạn. Giúp bạn quản lý tài sản và nợ, theo dõi giá trị tài sản ròng, tải lên các tài liệu quan trọng và hơn thế nữa. Hãy bắt đầu quản lý và bảo vệ những thứ quan trọng của mình.',
  GENERAL_MENU:
    'Bạn có thể dễ dàng thêm tài sản và nợ vào <strong>Sổ đăng ký</strong>, tải lên tài liệu vào <strong>Tệp</strong>, thêm thông tin bạn bè, dịch vụ hay thông tin gia đình vào <strong>Danh bạ</strong>, quản lý người được ủy quyền để truy cập <strong>bantex</strong> khi bạn tử vong hoặc mất khả năng nhận thức trong <strong>Người đại diện</strong> và sử dụng <strong>Quản lý thừa kế</strong> để lên kế hoạch thừa kế.',
  ASSET_MENU:
    'Chuyển đến <strong>Sổ đăng ký</strong> để hợp nhất, xem và quản lý tất cả tài sản và nợ của bạn.',
  CREATE_NEW_ASSET_BUTTON:
    'Nhấp "Tạo mới" để thêm tài sản hoặc nợ. Hoặc bạn có thể nhấp vào nút + màu xanh lá ở dưới cùng bên phải.',
  CREATE_ASSET_SELECT_TYPE:
    'Chọn các loại tài sản hoặc nợ để đưa vào <strong>Sổ đăng ký</strong>.',
  CREATE_ASSET_FORM:
    'Thông tin mẫu đã được điền trước vào các trường dữ liệu. Hãy thay thế bằng thông tin của mình và nhấp "Tiếp theo" khi hoàn tất.',
  SAVE_ASSET_BUTTON:
    'Khi đã điền dữ liệu xong trong <strong>Sổ đăng ký</strong>, nhấp nút "Lưu lại". Nếu bạn muốn chỉnh sửa thông tin, nhấp nút "Quay lại".',
  SAVE_ASSET_SUCCESS: 'Thêm mới tài sản thành công.',
  FILE_MENU:
    'Chuyển đến <strong>Tệp</strong> để lưu trữ và săp xếp các tệp của bạn một cách an toàn và có hệ thống.',
  CREATE_FILE_BUTTON: 'Nhấp "Tải lên" để bắt đầu tải lên tệp mới.',
  ADD_FILE_UPLOAD:
    'Nhấp hoặc kéo thả tệp của bạn vào đây để tải lên thư viện <strong>Tệp</strong>',
  ADD_FILE_INFO:
    'Gắn nhãn, phân loại và gắn thẻ các tệp của bạn hoặc tạo các thư mục mới một cách dễ dàng. Các thư mục mặc định trong <strong>Tệp</strong> giúp bạn sắp xếp nhanh chóng.',
  SAVE_FILE_BUTTON:
    'Khi bạn đã sẵn sàng lưu tệp của mình, nhấp vào "Lưu". Nếu bạn muốn thay đổi thông tin, nhấp "Quay lại".',
  UPLOAD_FILE_SUCCESS: 'Bạn đã tải tệp lên thành công.',
  CONTACT_MENU:
    'Chuyển đến <strong>Danh bạ</strong> để thêm thông tin liên hệ của thành viên gia đình, đối tác kinh doanh hoặc nhà cung cấp dịch vụ của bạn.',
  CREATE_CONTACT_BUTTON: 'Nhấp "Tạo mới" để tạo liên hệ.',
  CREATE_CONTACT_FORM:
    'Điền các thông tin liên hệ cá nhân / tổ chức của bạn tại đây. Bạn có thể gắn thẻ một liên hệ vào tài sản hoặc nợ trong mục <strong>Sổ đăng ký</strong>.',
  SAVE_CONTACT_BUTTON:
    'Khi sẵn sàng để lưu liên hệ, nhấp "Lưu lại". Nếu bạn cần thay đổi thông tin chi tiết, nhấp "Quay lại".',
  SAVE_CONTACT_SUCCESS: 'Thêm liên hệ thành công.',
  REVIEW_REGISTRY:
    'Chuyển đến <strong>Sổ đăng ký</strong> để xem lại hoặc cập nhật thông tin tài khoản.',
  CREATED_ASSET: 'Chọn tài sản bất kì để xem xét và cập nhật chi tiết.',
  ASSET_OVERVIEW: 'Xem tài sản của bạn thay đổi thế nào theo thời gian.',
  EDIT_ASSET_BUTTON: 'Nhấp "Chỉnh sửa" để thay đổi thông tin.',
  LINK_EXISTED_DOCS: 'Nhấp nút "+" để liên kết tệp liên quan đến tài sản này.',
  SELECT_DOCS_TO_LINK:
    'Từ thư viện <strong>Tệp</strong>, chọn bất kì tệp nào để liên kết. Nhấp vào tiếp theo để thiết lập liên kết.',
  SAVE_SELECTED_DOCS:
    'Khi đã hoàn thành, nhấp "Chọn". Để chọn tệp khác, nhấp "Quay lại".',
  NEW_DOCS_LINK: 'Liên kết tệp và tài sản hoàn tất.',
  LINK_EXISTED_CONTACTS:
    'Để liên kết một liên hệ với tài sản, nhấp "+" để liên kết liên hệ bất kì với tài sản này.',
  CONTACTS_LINK_MODAL:
    'Chọn một liên hệ từ <strong>Danh bạ</strong>, sau đó nhấp "Lưu lại".',
  NEW_CONTACTS_LINK: 'Liên kết với liên hệ hoàn tất.',
  SAVE_LINKED_ASSET: 'Khi đã sẵn sàng, hãy nhấp "Lưu lại".',
  SAVE_LINKED_ASSET_SUCCESS: 'Đã lưu thay đổi.',
  DEPUTIES_MENU:
    'Bạn có thể chỉ định Người đại diện truy cập vào <strong>bantex</strong>. Người đại diện sẽ hỗ trợ bạn quản lý việc thừa kế.',
  CURRENT_DEPUTIES: 'Xem, thu hồi hoặc cập nhật thông tin Người đại diện.',
  PENDING_DEPUTIES:
    'Kiểm tra xem những Người đại diện được chỉ định đã chấp thuận yêu cầu chưa tại đây.',
  PENDING_FILE_UNLOCK:
    'Bạn có thể đánh dấu tất cả các tệp của mình là bí mật trong <strong>Tệp</strong>. Chỉ có thể mở các tệp này khi được Người đại diện mở khóa. Xem trạng thái mở khóa tệp tại đây.',
  COMPLETE_TOUR_MODAL:
    'Chuyến thăm <strong>bantex</strong> đã kết thúc. Bạn có thể xem lại bằng cách vào "Truy cập nhanh", sau đó bắt đầu lại.',
  VAULTBOX_TOUR_STOPPED: 'Chuyến thăm Vaultbox đã kết thúc',
  VAULTBOX_TOUR_STOPPED_SUMMARY:
    "Bạn đã kết thúc chuyến tham quan bantex. Bắt đầu lại bất cứ lúc nào tại 'Truy cập nhanh > Tham quan Vaultbox'.",
  COMPLETE_TOUR: 'Hoàn thành chuyến đi',
  START_TOUR: 'Bắt đầu chuyến đi',
  STOP_TOUR: 'Dừng chuyến đi',
  SKIP: 'Bỏ qua',
  VAULTBOX_TOUR: 'Tham quan Vaultbox',
  CONTINUE_WHERE_YOU_LEFT_OFF: 'Tiếp tục từ điểm cuối',
  START_FROM_BEGINNING: 'Bắt đầu',
  FAILED_TO_LOAD_YOUR_VAULTBOX_DATA: 'Không thể tải dữ liệu Vaultbox của bạn',
  VIEW_DETAILS: 'Xem chi tiết',
  PLEASE_LOG_IN_AGAIN_TO_RETRY: 'Đăng nhập để thử lại.',
  LOG_OUT: 'Đăng xuất',
  SHOWED_MOBILE_PROMPT:
    'Cảm ơn bạn đã truy cập bantex trên thiết bị di động. Để có được trải nghiệm tối ưu và thông tin chi tiết hơn, vui lòng đăng nhập bằng trình duyệt trên máy tính cá nhân.',
  BACK_DATED_AMEND_CONFIRMATION: 'Xác nhận thay đổi',
  BACK_DATED_AMEND_SUMMARY:
    'Các thay đổi về vị trí và định giá sẽ được lưu lại cho mục được chọn',
  ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE: 'Bạn có chắc muốn tiếp tục?',
  UPLOAD_DOCUMENT: 'Tải lên tài liệu',
  INFORMATION: 'Thông tin',
  CREATE_NEW: 'Tạo mới',
  ASSET_LIABILITY_RECORD_NOT_FOUND: 'Không tìm được bản ghi tài sản / nợ.',
  SUCCESSFULLY_LINKED_OR_UNLINKED_DOCUMENT:
    '{{linkedOrUnlinked}} tài liệu thành công',
  LINKED: 'Kết nối',
  UNLINKED: 'Hủy kết nối',
  SUCCESSFULLY_LINKED_ASSET_LIABILITY: 'Kết nối thành công',
  SUCCESSFULLY_UNLINKED_ASSET_LIABILITY:
    'Hủy liên kết đến tài sản/nợ thành công',
  SUCCESSFULLY_UNLINKED_CONTACT: 'Hủy liên kết đến liên hệ thành công',
  FAILED_TO_UNLINK_CONTACT: 'Không thể hủy liên kết đến liên hệ',
  SUCCESSFULLY_LINKED_CONTACT: 'Liên kết đến liên hệ thành công',
  FAILED_TO_LINK_CONTACT: 'Không thể liên kết đến liên hệ',
  DETAIL: 'Chi tiết',
  ASSET_NAME: 'Tên tài sản',
  LIABILITY_NAME: 'Tên khoản nợ',
  OVERVIEW: 'Tổng quan',
  FAILED_TO_EXPORT_TO_PNG: 'Không thể xuất tệp PNG',
  EXPORT_TO_PNG: 'Xuất ra tệp PNG',
  TIME_RANGE: 'Khoảng thời gian',
  DATA: 'Ngày',
  FILES_AND_FOLDERS: 'Tệp & Thư mục',
  FOLDERS_COUNT_AND_FILES_COUNT:
    '{{foldersCount}} thư mục & {{filesCount}} tệp',
  SEARCH_FILE_OR_FOLDER_NAME: 'Tìm kiếm theo tên tệp / thư mục',
  LINK_NEW_FILE: 'Kết nối với tệp mới',
  LINK_ASSETS: 'Kết nối với tài sản',
  LINK_LIABILITIES: 'Kết nối với nợ',
  CUSTOM: 'Tùy chỉnh',
  SELECT_EXCHANGE: 'Chọn sàn giao dịch',
  EXCHANGE_NAME: 'Tên sàn',
  EXCHANGE_CODE: 'Mã sàn',
  CURRENCY_NAME: 'Tên đơn vị',
  CURRENCY_CODE: 'Mã đơn vị',
  ASSET_LIABILITY_CURRENCY: 'Đơn vị tính tài sản / nợ',
  TYPE_CURRENCY: 'Đơn vị tính {{type}}',
  LINK_TO_DOCUMENTS: 'Kết nối đến tài liệu',
  LINK_TO_ASSETS_LIABILITIES: 'Kết nối đến tài sản / nợ',
  INCLUDE_VAULE_IN_NETWORTH: 'Tính vào Tài sản ròng',
  BACK_DATED_AMEND: 'Sửa chữa cũ',
  FAILED_TO_GET_REGISTRY_COLUMNS:
    'Không thể lấy thông tin cột trong Sổ đăng ký',
  DELETE_VALUATION_CHANGE: 'Xóa thay đổi',
  DELETE_VALUATION_CHANGE_CONFIRM:
    'Bạn có chắc muốn xóa các thay đổi giá trị trong mục này vào ngày',
  DELETE_VALUATION_BEFORE_THIS_DATE:
    'Xóa các thay đổi <strong>trước</strong> ngày này',
  DELETE_VALUATION_AFTER_THIS_DATE:
    'Xóa các thay đổi <strong>sau</strong> ngày này',
  SUCCESSFULLY_DELETED_ASSET_LIABILITY: 'Xóa tài sản / nợ thành công',
  FAILED_TO_DELETE_ASSET_LIABILITY: 'Không thể xóa tài sản / nợ',
  VALUATION_IN_BASE_CURRENCY_WITH_PERCENTAGE_OWNERSHIP_TOOLTIP:
    'Tổng các định giá theo đơn vị mặc đinh của bạn, tính cả tỷ lệ sở hữu.',
  TOTAL: 'Tổng',
  NO_ASSETS: 'Chưa có tài sản',
  ENTER_THE_SEARCH_KEYWORD: 'Điền từ khóa tìm kiếm',
  CREATE: 'Tạo mới',
  NO_LIABILITIES: 'Chưa có nợ',
  BALANCE_SHEET: 'Bảng cân đối kế toán',
  GROUP: 'Nhóm',
  GROUP_BY: 'Nhóm theo',
  NET_WORTH: 'Giá trị ròng',
  NO_ASSETS_LIABILITIES: 'Không có tài sản / nợ',
  REPORTS: 'Báo cáo',
  NO_DOCUMENTS: 'Không có tài liệu',
  ENTER_LABEL: 'Điền {{label}}',
  SELECT_LABEL: 'Chọn {{label}}',
  ADD_NEW: 'Thêm mới',
  TO_EXCEL: 'Xuất ra Bảng tính',
  TO_PDF: 'Xuất ra PDF',
  EXPORT: 'Xuất',
  TOTAL_LIABILITIES: 'Tổng nợ',
  EXCLUDED_FROM_THE_TOTAL_NET_WORTH: 'Không tính vào Tổng tài sản ròng',
  CONTACT_LINKS: 'Liên hệ đã kết nối',
  UNLINK_CONTACT_CONFIRM: 'Bạn có chắc muốn gỡ kết nối với liên hệ?',
  YES: 'Có',
  NO: 'Không',
  LINK_CONTACTS: 'Kết nối với liên hệ',
  SELECT_CONTACT: 'Chọn liên hệ',
  ADD_NEW_CONTACT: 'Tạo mới liên hệ',
  DOCUMENT_LINKS: 'Tài liệu đã kết nối',
  UNLINK_DOCUMENT_CONFIRM: 'Bạn có chắc muốn gỡ kết nối với tài liệu?',
  Select: 'Chọn',
  MORE_THAN_TEN_COLUMNS_SELECTED: 'Đã chọn nhiều hơn 10 cột',
  NO_DATA: 'Không có dữ liệu',
  INVALID_FILE_MISSING_THE_HEADER_ROW: 'Tệp không hợp lệ: thiếu hàng tiêu đề',
  INVALID_FILE_MISSING_THE_STATEMENT_HEADER_ROW:
    'Tệp không hợp lệ: missing the statement header row',
  INVALID_FILE_MISSING_COLUMN: 'Tệp không hợp lệ: thiếu cột {{name}}',
  INVALID_FILE_MISSING_VALUATION_DATE: 'Tệp không hợp lệ: thiếu ngày định giá',
  UPDATING_ASSETS_LIABILITIES: 'Bạn có chắc muốn cập nhật tài sản / nợ?',
  UPDATING_ASSETS_LIABILITIES_SUMMARY:
    '{{updatedRecordsCount}} mục trong sổ đăng ký sẽ được cập nhật.',
  SUCCESSFULLY_IMPORTED_DATA: 'Nhập dữ liệu thành công',
  FAILED_TO_IMPORT_DATA: 'Không thể nhập dữ liệu',
  IMPORT_FROM_CSV: 'Nhập dữ liệu từ tệp CSV',
  TEMPLATE: 'Biểu mẫu',
  DOWNLOAD_SAMPLE_TEMPLATE: 'Tải về biểu mẫu có sẵn',
  SELECT_A_TEMPLATE: 'Chọn biểu mẫu',
  FROM_CSV: 'Từ tệp CSV',
  FROM_EXCEL: 'Từ tệp Excel',
  IMPORT: 'Nhập dữ liệu',
  IMPORT_FROM_EXCEL: 'Nhập dữ liệu từ Bảng tính',
  INVALID_FILE_TYPE: 'Loại tệp không hợp lệ',
  ASSET_LIABILITY_RECORDS: 'bản ghi tài sản / nợ',
  CONTACT_RECORDS: 'liên hệ',
  EVENT_RECORDS: 'sự kiện',
  DOCUMENT_RECORDS: 'tài liệu',
  FILE_RECORDS: 'Tệp',
  WILL_BE_UNLINKED_FROM_THIS_ITEM: 'sẽ được hủy kết nối với mục này',
  DELETE_ALL_HISTORICAL_VALUATIONS_DATA: 'Xóa lịch sử định giá',
  ENTER: 'Nhập',
  SELECT: 'Chọn',
  SELECTED: 'Đã chọn',
  ADD: 'Thêm',
  AUTHENTICATOR_APP: 'Trình xác thực',
  BRIEF_STEPS: 'Các bước',
  GOOGLE_AUTHENTICATOR: 'Google Authenticator',
  OPEN_THE_APP: 'Mở ứng dụng.',
  TAP_BEGIN_SETUP: 'Chọn "Bắt đầu cài đặt".',
  TAP_SCAN_BARCODE: 'Chọn "Quét mã QR".',
  SCAN_THE_QR_CODE: 'Quét mã QR',
  TAP_PLUS_BUTTON: 'Bấm bút "+"',
  TAP_OTHER: 'Chọn "Khác (Google, Facebook, etc.)"',
  ENTER_THE_AUTHENTICATION_CODE: 'Nhập mã xác thực vào ô bên dưới.',
  LASTPASS_AUTHENTICATOR: 'LastPass Authenticator',
  MICROSOFT_AUTHENTICATOR: 'Microsoft Authenticator',
  MFA_FIRST_CONTENT:
    'Sau đây là các bước để quét mã QR bằng một số ứng dụng xác thực thường được sử dụng.',
  MFA_SECOND_CONTENT:
    'Thông tin được cung cấp ở trên chỉ dựa vào tính thuận tiện và có thể khác với thực tế sử dụng.',
  IPHONE: 'iPhone',
  ANDROID: 'Android',
  APP_STORE: 'App store',
  PLAY_STORE: 'Play store',
  MFA_TABLE_SUMMARY:
    'Các ứng dụng và liên kết này chỉ được cung cấp vì tính thuận tiện, không phải vì sự chứng thực hoặc bảo đảm về tính phù hợp của chúng.',
  CODE: 'Mã',
  CODE_IS_REQUIRED: 'Hãy nhập mã!',
  FAILED_TO_ADD_OTHER_CRYPTO: 'Không thêm được tiền điện tử khác',
  SELECT_CRYPTOCURRENCY: 'Chọn tiền điện tử',
  OTHER: 'Khác',
  AN_ERROR_HAS_OCCURRED: 'Có lỗi xảy ra.',
  MULTI_FACTOR_AUTHENTICATION: 'Xác thực đa yếu tố',
  DID_NOT_RECEIVE_THE_SMS: 'Chưa nhận được tin nhắn?',
  RESEND_SMS: 'Gửi lại',
  SETUP_MFA_AUTHENTICATION_APP_FISRT_CONTENT:
    'Để thiết lập xác thực đa yếu tố trên bantex, bạn cần có ứng dụng xác thực tương thích trên điện thoại của mình.',
  SETUP_MFA_AUTHENTICATION_APP_SECOND_CONTENT:
    'Nếu chưa có, bạn có thể cân nhắc cài đặt một ứng dụng từ cửa hàng ứng dụng dành riêng cho loại điện thoại của bạn.',
  SETUP_MFA_AUTHENTICATION_APP_THIRD_CONTENT:
    'Khi bạn đã có ứng dụng xác thực, nhấp vào Tiếp theo.',
  SETUP_MFA_AUTHENTICATION_APP_HEADER:
    'Xem danh sách các ứng dụng xác thực thường được sử dụng',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP:
    'Thêm bantex vào ứng dụng xác thực của bạn',
  USE_YOUR_AUTHENTICATOR_APP_TO_SCAN_THIS_QR_CODE:
    'Sử dụng ứng dụng xác thực của bạn để quét mã QR này.',
  NEED_AN_AUTHENTICATOR_APP: 'Cần một ứng dụng xác thực?',
  HOW_DO_I_SCAN_THE_QR_CODE_FROM_MY_AUTHENTICATOR_APP:
    'Làm cách nào để quét mã QR từ ứng dụng xác thực của tôi?',
  SHOW_CODE_FOR_MANUAL_CONFIGURATION: 'Hiển thị mã để cấu hình bằng tay',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP_FIRST_CONTENT:
    'Đảm bảo rằng bạn chỉ có một hồ sơ bantex trong ứng dụng xác thực của mình. Nếu có nhiều hơn, bạn nên xóa chúng và bắt đầu lại.',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP_SECOND_CONTENT:
    'Để tiếp tục, hãy nhập mã xác thực từ ứng dụng xác thực của bạn. Mã này sẽ tự động làm mới, vì vậy hãy nhập mã và sau đó nhấp vào Tiếp theo để chúng tôi có thể xác minh mã.',
  BACK: 'Quay lại',
  SETUP_MFA_REVIEW_SUMMARY:
    'Bạn đã thiết lập ứng dụng xác thực của mình cho bantex. Trong lần đăng nhập tiếp theo, bạn sẽ được yêu cầu nhập mã xác thực từ ứng dụng xác thực, ngoài địa chỉ email và mật khẩu của bạn.',
  SETUP_MFA_SUMMARY:
    'Để bảo vệ bantex của mình, bạn cần thiết lập xác thực đa yếu tố trước khi tiếp tục.',
  LEARN_MORE_ABOUT_SETTING_UP_MULTI_FACTOR_AUTHENTICATION:
    'Tìm hiểu thêm về cách thiết lập xác thực đa yếu tố.',
  MINOR_EDIT: 'Chỉnh sửa nhỏ',
  MINOR_EDIT_TOOLTIP:
    'Một chỉnh sửa nhỏ sẽ không hiển thị trong danh sách phiên bản',
  GENERAL_INFO: 'Thông tin chung',
  ADDRESS: 'Địa chỉ',
  LABEL: 'Nhãn',
  DOCUMENTS_DETAIL: '{{foldersCount}} thư mục & {{filesCount}} tệp',
  CONTACT_USED_IN_LEGACY:
    'Không thể xóa liên hệ này vì đang sử dụng trong quản lý thừa kế.',
  FAILED_TO_DELETE_CONTACT: 'Không thể xóa liên hệ',
  WILL_BE_UNLINKED_FROM_THIS_CONTACT: 'sẽ được ngắt kết nối khỏi liên hệ này.',
  GROUP_INFORMATION: 'Thông tin nhóm',
  PEOPLE: 'người',
  CONTACT_INFORMATION: 'Thông tin liên hệ',
  NOTE: 'Ghi chú',
  VERSION: 'Phiên bản',
  SELECT_CONTACTS_TO_IMPORT: 'Chọn các liên hệ để nhập dữ liệu',
  FAILED_TO_IMPORT_CONTACTS: 'Không thể nhập dữ liệu danh bạ',
  INVALID_RECORDS_CANT_BE_IMPORTED:
    'không thể nhập các bản ghi không hợp lệ (thiếu tên).',
  ALL: 'Tất cả',
  NO_CONTACTS_TO_IMPORT: 'Không có liên hệ để nhập',
  REFERENCE_LABEL: 'Nhãn tham chiếu',
  REFERENCE_INFO: 'Thông tin tham chiếu',
  FROM_VCARD: 'Từ vCard',
  FAILED_TO_EXPORT_TO_EXCEL: 'Không thể xuất ra Bảng tính',
  FAILED_TO_EXPORT_TO_PDF: 'Không thể xuất ra PDF',
  TAG: 'Nhãn',
  NO_CONTACTS_RESULTS: 'Không có kết quả',
  SEARCH_CONTACT_FROM_LEFT_SIDEBAR:
    'Vui lòng tìm kiếm tên liên hệ hoặc chọn liên hệ từ thanh bên trái để lấy thông tin',
  SEARCH_CONTACT_FROM_CONTACTS_LIST:
    'Vui lòng tìm kiếm theo tên liên hệ hoặc chọn liên hệ từ danh bạ để nhận thông tin',
  NO_CONTACTS: 'Không có liên hệ',
  INVALID_TEMPLATE: 'Biểu mẫu không hợp lệ',
  SHEET: 'Trang',
  ROW: 'Hàng',
  MISSING_COLUMN: 'Thiếu cột',
  IS_MANDATORY: 'là bắt buộc',
  SUCCESSFULLY_IMPORT_CONTACTS: 'Nhập thành công {{selectedContacts}} liên hệ',
  IMPORT_FROM_VCARD: 'Nhập từ vCard',
  UPDATE_PENDING_ACCEPTANCE_FROM_DEPUTY:
    'Cập nhật trạng thái chấp thuận từ Người đại diện',
  UPDATE_MY_INFO: 'Cập nhật tiểu sử',
  STATUS: 'Trạng thái',
  BACKUP_KEY_DISTRIBUTED: 'Đã phân phối khóa dự phòng',
  ACCEPTED: 'Đã đồng ý',
  CANNOT_REVOKE_DEPUTY: 'Không thể thu hồi quyền đại diện',
  ARE_YOU_SURE_TO_REVOKE_THIS_DEPUTY:
    'Bạn có chắc muốn thu hồi quyền đại diện của người này?',
  CANNOT_REVOKE_DEPUTY_SUMMARY:
    'Cần <strong>{sharesThreshold}</strong> người đại diện để mở khóa bantex của bạn. Để có thể thu hồi quyền đại diện, bạn cần giảm con số này.',
  WHY_DO_I_NEED_TO_APPOINT_DEPUTIES: 'Tại sao tôi cần chỉ định Người đại diện?',
  DEPUTY_SUMMARY:
    'Chỉ định đại biểu là không bắt buộc. Tuy nhiên trong trường hợp mất mật khẩu hoặc quyền truy cập <strong>bantex</strong>, bạn cần Người đại diện đặt lại mật khẩu hoặc khôi phục quyền truy cập <strong>bantex</strong> của bạn. Chúng tôi thực hiện việc này bằng cách tạo mã khôi phục cho Người đại diện của bạn.',
  ADD_A_DEPUTY: 'Thêm Người đại diện',
  SHARES_THRESHOLD_SUMMARY:
    'Số lượng đại biểu cần thiết để mở khóa bantex của bạn',
  CLICK_HERE_TO_RETRY: 'Nhấp để thử lại',
  MY_CURRENT_DEPUTIES: 'Người đại diện của tôi',
  NO_DEPUTIES: 'Không có Người đại diện',
  MY_PENDING_DEPUTIES: 'Người đại diện đang chờ',
  NO_PENDING_DEPUTIES: 'Không có Người đại diện đang chờ',
  MY_PENDING_FILE_UNLOCKS: 'Các tệp chờ mở khóa',
  NO_PENDING_FILE_UNLOCKS: 'Không có tệp chờ mở khóa',
  DEPUTIES: 'Người đại diện',
  PRIMARY_USERS_WHO_I_AM_A_DEPUTY_FOR: 'Tôi đại diện cho',
  YOU_HAVE_BEEN_ENTRUSTED_AS_DEPUTY:
    'Bạn đã làm đại diện cho những người dùng sau',
  LEARN_MORE_ABOUT_BEING_A_DEPUTY: 'Tìm hiểu về việc trở thành Người đại diện.',
  ID_CONTAINS_RESIDENTIAL_ADDRESS: 'CMND chứa địa chỉ.',
  ADDRESS_PROOF: 'Minh chứng địa chỉ',
  ID_PASSPORT: 'CMND / Hộ chiếu',
  PRIMARY_USER_DETAILS: 'Chi tiết người dùng chính',
  DECLINE: 'Từ chối',
  ACCEPT: 'Đồng ý',
  REJECT: 'Từ chối',
  APPROVE: 'Đồng ý',
  CURRENT_DETAILS: 'Chi tiết hiện có',
  PENDING_UPDATE_DETAILS: 'Chi tiết chờ cập nhật',
  LEGACY_MANAGEMENT: 'Quản lý thừa kế',
  INSTRUCTION: 'Hướng dẫn',
  RECEIVED_BACKUP_KEY: 'Đã nhận khóa dự phòng',
  NOT_YET_RECEIVED_BACKUP_KEY: 'Chưa nhận được khóa dự phòng',
  LEGACY_MANAGEMENT_ENABLED: 'Kích hoạt quản lý thừa kế',
  FROM: 'Từ',
  PENDING: 'Đang chờ',
  REQUEST_ACCEPTED: 'Đã chấp nhận yêu cầu',
  REQUEST_REJECTED: 'Đã từ chối yêu cầu',
  FAILED_TO_ACCEPT_REQUEST: 'Không thể chấp nhận yêu cầu',
  FAILED_TO_APPROVE_REQUEST: 'Không thể chấp nhận yêu cầu',
  FAILED_TO_REJECT_REQUEST: 'Không thể từ chối yêu cầu',
  FAILED_TO_HANDLE_DEPUTY_REQUEST: 'Không thể xử lý yêu cầu Người đại diện',
  FAILED_TO_HANDLE_UPDATE_USER_DETAILS_REQUEST:
    'Không xử lý yêu cầu cập nhật chi tiết người dùng',
  PROFESSIONAL_DEPUTY_ACCESS_DELEGATIONS: 'Nhóm Người đại diện chuyên nghiệp',
  PENDING_MY_ACCEPTANCE_TO_BE_DEPUTY:
    'Đang chờ xác nhận của tôi để trở thành Người đại diện',
  NO_PENDING_REQUESTS: 'Không có yêu cầu đang chờ',
  I_AM_CURRENTLY_A_DEPUTY_FOR_THESE_PRIMARY_USERS:
    'Tôi là Người đại diện cho những người dùng chính này',
  NO_PRIMARY_USERS: 'Không có người dùng chính',
  REQUEST_TO_UPDATE_THEIR_DETAILS: 'Yêu cầu cập nhật chi tiết',
  NO_REQUESTS: 'Không có yêu cầu',
  APPROVAL_SATUS: 'Trạng thái chấp nhận',
  NEW_PHONE_NUMBER_DEPUTY: 'Số điện thoại mới',
  REQUEST_APPROVED: 'Đã chấp nhận yêu cầu',
  REQUESTS_TO_RESET_THEIR_PASSWORD: 'Yêu cầu đặt lại mật khẩu',
  REQUEST_TO_UNLOCK_THEIR_FILES: 'Yêu cầu mở khóa tệp',
  REQUEST_TO_CHANGE_THEIR_PHONE_NUMBER: 'Yêu cầu thay đổi số điện thoại',
  SUCCESSFULLY_UPDATED_NUMBER_OF_DEPUTIES_REQUIRED:
    'Đã cập nhật thành công số lượng người đại diện yêu cầu',
  FAILED_TO_UPDATE_NUMBER_OF_DEPUTIES_REQUIRED:
    'Không thể cập nhật số lượng người đại diện yêu cầu',
  NUMBER_OF_DEPUTIES_REQUIRED_TO_UNLOCK_VAULTBOX:
    'Số lượng người đại diện yêu cầu để mở khóa bantex',
  ENTER_NUMBER_OF_DEPUTIES: 'Điền số người đại diện',
  FAILDED_TO_LOAD_USER_DETAILS: 'Không tải được chi tiết người dùng',
  SUCCESSFULLY_UPDATE_DETAILS: 'Cập nhật chi tiết thành công',
  FAILED_TO_UPDATE_DETAILS: 'Không thể cập nhật chi tiết',
  USER_DETAILS: 'Chi tiết người dùng',
  OWNER: 'Người sở hữu',
  UNLOCK: 'Mở khóa',
  LOCK: 'Khóa',
  SUCCESSFULLY_UNLOCKED_VAULT: 'Mở khóa vault thành công',
  FAILED_TO_UNLOCK_VAULT: 'Không thể mở khóa vault',
  SUCCESSFULLY_LOCKED_VAULT: 'Khóa vault thành công',
  FAILED_TO_LOCK_VAULT: 'Không thể khóa vault',
  VAULTBOXES_OF_PRIMARY_USERS: 'Vaultbox của Người dùng chính',
  NO_VAULTBOXES: 'Không có bantex',
  FOLDER_NAME_EXISTS: 'Tên thư mục đã tồn tại',
  FAILED_TO_GET_FILE: 'Không thể mở tệp.',
  MARKING_FILE_AS_SECRET: 'Đánh dấu tệp là bí mật?',
  MARKING_FILE_AS_SECRET_SUMMARY:
    'Sau khi được đánh dấu là bí mật, tệp chỉ có thể được xem hoặc tải xuống sau khi được Người đại diện mở khóa.',
  SUCCESSFULLY_MARKED_DOCUMENT_AS_SECRET: 'Đánh dấu tài liệu thành công',
  FAILED_TO_MARK_DOCUMENT_AS_SECRET: 'Không thể đánh dấu thành bí mật.',
  REQUEST_TO_UNLOCK_HAS_BEEN_SENT: 'Đã gửi yêu cầu mở khóa',
  RESEND_UNLOCK_REQUEST: 'Gửi lại yêu cầu mở khóa',
  REQUEST_TO_UNLOCK: 'Yêu cầu mở khóa',
  MARK_SECRET_FILE_FIRST_TOOLTIP:
    'Nếu một tệp được đánh dấu là bí mật, tệp chỉ có thể được xem hoặc tải xuống sau khi được Người đại diện mở khóa.',
  MARK_SECRET_FILE_SECOND_TOOLTIP:
    'Phải có ít nhất một Người đại diện để có thể đánh dấu một tệp là bí mật.',
  MARK_SECRET: 'Đánh dấu là bí mật',
  SECRET_FILE: 'Tệp bí mật',
  SECRET_FILE_SUMMARY:
    'Tệp này đã được đánh dấu là bí mật và không thể xem hoặc tải xuống. Để lấy lại quyền truy cập tệp, vui lòng yêu cầu Người đại diện trợ giúp để mở khóa.',
  PENDING_UNLOCK: 'Đang chờ mở khóa',
  PENDING_UNLOCK_SUMMARY: 'Yêu cầu mở khóa tệp đã được gửi đến Người đại diện.',
  FILE_INFORMATION: 'Thông tin tệp',
  ORIGINAL_PATH: 'Đường dẫn gốc',
  CREATED: 'Ngày tạo',
  SIZE: 'Kích thước',
  SUCCESSFULLY_UPLOADED_FILE: 'Tải tệp lên thành công',
  FAILED_TO_UPLOAD_FILE: 'Không thể tải tệp lên',
  REMOVE_FROM_FAVOURITES: 'Gỡ khỏi Yêu thích',
  ADD_TO_FAVOURITES: 'Thêm vào Yêu thích',
  FILE_USED_IN_LEGACY_MANAGEMENT:
    'Không thể xóa tệp này vì nó được sử dụng trong quản lý thừa kế.',
  SUCCESSFULLY_DELETED: 'Xóa thành công',
  FAILED_TO_DELETE: 'Không thể xóa',
  FOLDER: 'Thư mục',
  CANNOT_DELETE_FOLDERS_WITH_FILES_INSIDE:
    'Không thể xóa thư mục đang chứa tệp.',
  CANNOT_BE_DELETED_BECAUSE_IT_USED_IN_LEGACY_MANAGEMENT:
    'Không thể xóa tệp "{{name}}" vì tệp được sử dụng trong quản lý thừa kế.',
  SUCCESSFULLY_RENAMED_FOLDER: 'Đổi tên thư mục thành công',
  FAILED_TO_RENAME_FOLDER: 'Không thể đổi tên thư mục',
  FAILED_TO: 'Không thể',
  THE_DESTINATION_IS_A_SUBFOLDER: 'Thư mục đích là con của thư mục nguồn.',
  SUCCESSFULLY_COPIED: 'Sao chép thành công',
  ITEM: 'Mục',
  ITEMS: 'Mục',
  FAILED_TO_COPY: 'Không thể sao chép',
  SUCCESSFULLY_MOVED_ITEM: 'Di chuyển mục thành công',
  FAILED_TO_MOVE_ITEM: 'Không thể di chuyển mục',
  SUCCESSFULLY_MOVED_ITEMS: 'Di chuyển các mục thành công',
  FAILED_TO_MOVE_ITEMS: 'Không thể di chuyển các mục',
  COPY: 'Sao chép',
  EDIT_CONTENT: 'Chỉnh sửa nội dung',
  CANT_DELETE_FOLDER_WITH_FILES_INSIDE: 'Không thể xóa thư mục đang chứa tệp',
  FOLDERS: 'Thư mục',
  SUCCESSFULLY_CREATED_FOLDER: 'Tạo thư mục thành công',
  FAILED_TO_CREATE_FOLDER: 'Không thể tạo thư mục',
  TEXT_FILE: 'Tệp văn bản',
  HOME: 'Trang chủ',
  SELECT_FOLDER: 'Chọn thư mục',
  SHOW_FOLDER_TREE: 'Hiện cây thư mục',
  STORAGE: 'Bộ nhớ',
  YOUR_STORAGE_INCLUDES_IN_TRASH:
    'Bộ nhớ của bạn bao gồm {{deleteStorage}} trong Thùng rác. Dọn sạch thùng rác của bạn để giải phóng thêm bộ nhớ.',
  OF: 'của',
  USED: 'Đã sử dụng',
  SELECT_FILE_OR_FOLDER_TO_SEE_DETAILS:
    'Chọn một tệp hoặc thư mục để xem chi tiết',
  NEW_TAG: 'Nhãn mới',
  CANT_BE_ENCRYPTED_AND_UPLOADED: 'không thể mã hóa và tải lên',
  FILE_SIZE_LIMIT_IS: 'Giới hạn kích thước tệp là',
  SAVE_TO_VAULTBOX_FOLDER: 'Lưu vào thư mục <strong>bantex</strong>',
  SAVE_TEXT_FILE: 'Lưu tệp văn bản',
  FILE_NAME_IS_REQUIRED: 'Tên tệp là bắt buộc!',
  SUCCESSFULLY_CREATED_FILE: 'Tạo tệp thành công',
  FAILED_TO_CREATE_FILE: 'Không thể tạo tệp',
  THE_SELECTED_FILE_IS_LOCK: 'Tệp đã chọn bị khóa và không thể sử dụng được',
  REMOVE_FILE: 'Xóa tệp',
  SELECT_FROM_UPLOADED_FILES: 'Chọn trong các Tệp đã tải lên',
  UPLOAD_OR_SELECT_FROM_UPLOADED_FILES:
    'Tải lên {{labelForErrorMsg}} hoặc chọn từ các Tệp đã tải lên',
  QUICK_ACCESS: 'Truy cập nhanh',
  DOWNLOAD_VAULTBOX: 'Tải bantex',
  IMPORT_TEMPLATE: 'Nhập biểu mẫu',
  FAILED_TO_DOWNLOAD_VAULTBOX: 'Không thể tải bantex',
  SESSION_TIMEOUT: 'Phiên làm việc kết thúc',
  SESSION_TIMEOUT_SUMMARY: 'Vui lòng đăng nhập lại để tiếp tục',
  FAILE_TO_GET_USER_DATA: 'Không lấy được dữ liệu người dùng',
  FAILE_TO_GET_PRIMARY_USERS: 'Không lấy được dữ liệu người dùng chính',
  ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT: 'Bạn có chắc chắn muốn đăng xuất không?',
  BACK_TO_MY_VAULTBOX: 'Quay lại bantex của tôi',
  VIEWING_VAULTBOX_OF: 'Xem bantex của',
  PLEASE_APPOINT_YOUR_DEPUTIES:
    'Vui lòng chỉ định Người đại diện của bạn, người có thể giúp mở khóa <strong>bantex</strong> của bạn nếu bạn quên mật khẩu. Nếu không, tất cả nội dung <strong>bantex</strong> của bạn sẽ bị xóa khi bạn đặt lại mật khẩu của mình.',
  APPOINT_DEPUTIES_NOW: 'Chỉ định Người đại diện',
  REMIND_ME_LATER: 'Nhắc tôi sau',
  DO_NOT_REMIND_ME_AGAIN: 'Không nhắc lại',
  YOU_HAVENT_SET_BASE_CURRENCY:
    'Bạn chưa chọn đơn vị tiền tệ mặc định. Hãy bắt đầu thiết lập',
  HERE: 'ở đây',
  YOUR_TRIAL_PERIOD_WILL_END_ON:
    'Thời gian dùng thử của bạn cho gói <strong>{{nickname}}</strong> sẽ kết thúc vào {{trial_end}}.',
  SUBSCRIPTION_HAS_BEEN_CANCEL_AT:
    'Đăng ký sử dụng <strong>bantex</strong> đã bị hủy. Gói {{nickname}} của bạn sẽ kết thúc vào <strong>{{cancel_at}}</strong>',
  YOU_STILL_CAN_REACTIVATE_SUBSCRIPTION:
    'Bạn vẫn có thể <2>tái kích hoạt</2> đăng ký của mình trước khi kết thúc.',
  REACTIVATE_SUBSCRIPTION: 'Tái kích hoạt đăng ký',
  REACTIVATE_SUBSCRIPTION_CONFIRM:
    'Bạn có chắc chắn muốn kích hoạt lại gói {{nickname}} của mình không?',
  THE_PAYMENT_BECAME_PAST_DUE:
    'Thanh toán cho đăng ký của bạn không thành công và đã quá hạn vào {{billing_cycle_anchor}}.',
  UPDATE_PAYMENT_INFORMATION:
    'Vui lòng cập nhật <2>thông tin thanh toán</2> của bạn trong vòng một tuần, nếu không đăng ký hiện tại của bạn sẽ bị hủy và hạ cấp xuống gói miễn phí.',
  YOU_HAVENT_ADDED_ANY_PAYMENT_METHOD:
    'Bạn chưa thêm <2>phương thức thanh toán</2> nào. Vui lòng thêm ít nhất một <4>phương thức thanh toán</4> trước khi hết thời gian dùng thử.',
  YOU_NEED_TO_ADD_MORE_DEPUTIES:
    'Số lượng người đại diện được yêu cầu để mở khóa bantex của bạn là <strong>{{sharedThreshold}}</strong> nhưng bạn hiện chỉ có <strong>{{acceptDeputies}}</strong>. Bạn cần thêm người đại diện hoặc giảm số lượng người đại diện cần thiết.',
  UPDATE: 'Cập nhật',
  WHO_WILL_RECEIVE_THE_ACCESS_TO_YOUR_VAULTBOX:
    'Ai có thể truy cập bantex của bạn?',
  LEGACY_ACCESSED_BY_PROFESSIONAL_DEPUTY:
    'Người đại diện sẽ mở khóa bantex của bạn và có quyền truy cập vào toàn bộ nội dung. Họ sẽ quyết định phải làm gì với nội dung dựa trên hướng dẫn của bạn (đã tải lên ở "Quyền đối với nội dung")',
  LEGACY_ACCESSED_BY_OTHER_SPECIFIC_PERSON:
    'Người đại diện sẽ mở khóa bantex của bạn và trao cho người này nội dung được chỉ định sẵn trong "Quyền nội dung". Người này sẽ cần chứng minh danh tính và mối liên hệ với bạn.',
  OTHER_PERSON: 'Người khác',
  THE_SELECTED_FILE_IS_LOCK_AND_CANNOT_BE_USED:
    'Tệp đã chọn bị khóa và không thể sử dụng',
  SUCCESSFULLY_SAVED_LEGACY_MANAGEMENT_CONFIGURATION:
    'Đã lưu thành công cấu hình quản lý Thừa kế',
  SUMMARY: 'Tóm lược',
  HERE_IS_A_SUMMARY_OF_YOUR_CONFIGURATION:
    'Đây là tóm lược thông tin cấu hình của bạn',
  LEGACY_MANAGEMENT_CONFIGURATION: 'Tùy chọn quản lý thừa kế',
  REQUEST_BY_USER: 'Yêu cầu từ người dùng',
  REQUEST_BY_YOU: 'Yêu cầu từ bạn',
  USER_DEATH_OR_INCAPACITATED_STATE:
    'Người dùng chết hoặc mất khả năng lao động',
  YOUR_DEATH_OR_INCAPACITATED_STATE: 'Bạn chết hoặc mất khả năng lao động',
  WE_WILL_SHARE_USER: 'Chúng tôi sẽ chia sẻ thông tin sau đây của người dùng:',
  WE_WILL_SHARE_YOUR: 'Chúng tôi sẽ chia sẻ thông tin sau đây của bạn:',
  FULL_VAULTBOX: 'Toàn bộ bantex',
  FINAL_REGISTRY_SNAPSHOT_AND_SELECTED_FILES_FOLDERS:
    'Final Registry snapshot and Selected files/folders',
  FINAL_REGISTRY_SNAPSHOT: 'Final Registry snapshot',
  FINAL_SNAPSHOT_OF_YOUR_REGISTRY: 'Final snapshot of your Registry',
  SELECTED_FILES_FOLDERS: 'Chọn tệp / thư mục',
  WITH: 'Với',
  UPON: 'Trên',
  USER_PROFESSIONAL_DEPUTY: 'Người đại diện chuyên nghiệp của người dùng',
  YOUR_PROFESSIONAL_DEPUTY: 'Người đại diện chuyên nghiệp của bạn',
  FAILED_TO_GET_LEGACY_MANAGEMENT_INFO:
    'Không thể lấy thông tin quản lý thừa kế',
  FAILED_TO_GET_LEGACY_MANAGEMENT_STATUS:
    'Không thể lấy trạng thái quản lý thừa kế',
  FAILED_TO_CHANGE_LEGACY_MANAGEMENT: 'Không thể sửa thông tin quản lý thừa kế',
  DISABLE_THE_LEGACY_MANAGEMENT: 'Tắt quản lý thừa kế?',
  ENABLE_THE_LEGACY_MANAGEMENT: 'Bật quản lý thừa kế',
  SUCCESSFULLY_SENT_REQUEST_TO_RELEASE_YOUR_VAULTBOX:
    'Đã gửi thành công yêu cầu công khai bantex của bạn',
  FAILED_TO_SENT_REQUEST_TO_RELEASE_YOUR_VAULTBOX:
    'Không gửi được yêu cầu công khai bantex của bạn',
  REQUEST_TO_RELEASE_YOUR_VAULTBOX: 'Yêu cầu công khai bantex của bạn?',
  REQUEST_TO_RELEASE_YOUR_VAULTBOX_SUMNARY:
    'Người đại diện chuyên nghiệp sẽ được yêu cầu mở khóa bantex của bạn và công khai nội dung của nó theo cấu hình quản lý thừa kế hiện tại của bạn. Bạn có chắc chắn muốn tiếp tục?',
  LEGACY_MANAGEMENT_SUMMARY:
    'Với tư cách là khách hàng của <strong>bantex</strong>, bạn sẽ đưa ra các hướng dẫn khi bạn qua đời hoặc trạng thái mất khả năng lao động để Người đại diện chuyên nghiệp mà bạn đã chỉ định sẽ biết cách tiếp tục với nội dung <strong>bantex</strong> của bạn.',
  START: 'Bắt đầu',
  TOGGLE_THE_LEGACY_MANAGEMENT: 'Mở quản lý thừa kế',
  REQUEST_TO_RELEASE_NOW: 'Yêu cầu công khai ngay',
  UPDATE_CONFIGURATION: 'Cập nhật cấu hình',
  YOU_ARE_PENDING_ACCEPTANCE_FROM_YOUR_DEPUITES:
    'Đang chờ Người đại diện của bạn chấp nhận.',
  YOU_NEED_TO_HAVE_A_PROFESSIONAL_DEPUTY_TO_USE_THIS_FEATURE:
    'Bạn cần có một Người đại diện chuyên nghiệp để sử dụng tính năng này.',
  APPOINT_PROFESSIONAL_DEPUTY_NOW: 'Chỉ định Người đại diện chuyên nghiệp',
  PLEASE_SELECT_AT_LEAST_ONE_OPTION: 'Vui lòng chọn ít nhất một tùy chọn',
  PLEASE_SELECT_AT_LEAST_ONE_FILE_FOLDER:
    'Vui lòng chọn ít nhất một tệp / thư mục',
  CONTENT_PERMISSION: 'Content permission',
  ACCESSED_BY_OTHER_SPECIFIC_PERSON_FIRST_SUMMARY:
    'Bạn đã chọn cung cấp quyền truy cập cho <strong>Người khác</strong>',
  ACCESSED_BY_OTHER_SPECIFIC_PERSON_SECOND_SUMMARY:
    'Bạn có thể cung cấp toàn quyền truy cập <strong>bantex</strong> của mình hoặc chỉ nội dung Sổ đăng ký của bạn và / hoặc các tệp và thư mục đã chọn.',
  ACCESSED_BY_PROFESSIONAL_DEPUTY_FIRST_SUMMARY:
    'Bạn đã chọn cung cấp quyền truy cập cho <strong>Người đại diện chuyên nghiệp</strong>.',
  ACCESSED_BY_PROFESSIONAL_DEPUTY_SECOND_SUMMARY:
    'Người đại diện chuyên nghiệp sẽ có toàn quyền truy cập vào <strong>bantex</strong> của bạn.',
  CHOOSE_FILES_OR_FOLDERS_TO_SHARE: 'Chọn tệp hoặc thư mục để chia sẻ',
  ADD_FILE_FOLDERS: 'Thêm tệp / thư mục',
  INSTRUCTIONS: 'Hướng dẫn',
  INSTRUCTIONS_SUMMARY:
    'Tải lên văn bản về bất kỳ hướng dẫn nào khác cho Người đại diện chuyên nghiệp của bạn. Ví dụ, tệp README.TXT về các tài liệu cụ thể nằm ở đâu, Người đại diện cần chuyển các tệp đó cho ai.',
  INSTRUCTION_FILE: 'Tài liệu hướng dẫn',
  WHEN_DO_YOU_WANT_TO_RELEASE_YOUR_VAULTBOX_CONTENT:
    'Khi nào bạn muốn công khai nội dung bantex của mình?',
  UPON_REQUEST_BY_ME: 'Khi tôi yêu cầu',
  UPON_MY_DEATH_OR_INCAPACITATED_SATE:
    'Khi tôi chết hoặc mất khả năng nhận thức',
  INACTIVITY_STATE_CONFIGURATION:
    'Để chọn tùy chọn này, bạn cần hoàn thành cấu hình cho trạng thái không hoạt động.',
  CONFIGURE: 'Cấu hình',
  CHOSSE_FILES_FOLDERS: 'Chọn tệp / thư mục',
  NEW_CRYPTOCURRENCY: 'Tiền điện tử mới',
  THE_SAME_NAME_ALREADY_EXISTS: 'Tên tương tự đã tồn tại!',
  SYMBOL: 'Ký hiệu',
  ENTER_SYMBOL: 'Thêm ký hiệu',
  THE_SAME_SYMBOL_ALREADY_EXISTS: 'Ký hiệu tương tự đã tồn tại!',
  NEW_ITEM: '{{item}} mới',
  ENTER_NEW_ITEM: 'Thêm {{item}} mới!',
  THE_SAME_ITEM_ALREADY_EXISTS: '{{item}} tương tự đã tồn tại!',
  SUCCESSFULLY_ADDED_CARD: 'Thêm thẻ thành công',
  FAILED_TO_ADD_CARD: 'Không thể thêm thẻ',
  ADD_A_CARD: 'Thêm thẻ',
  SUPPORTED_CARD_TYPES: 'Các loại thẻ được hỗ trợ:',
  TESTING_NOTE: 'Lưu ý kiểm thử: Bạn có thể nhập số thẻ sau để kiểm tra',
  SUCCESSFULLY_DELETED_CARD: 'Xóa thẻ thành công',
  FAILED_TO_DELETED_CARD: 'Không xóa được thẻ',
  ARE_YOU_SURE_TO_DELETE_THIS_CARD: 'Bạn có chắc chắn xóa thẻ này không?',
  DELETE_CARD: 'Xóa thẻ',
  FAILED_TO_GET_INVOICES: 'Không lấy được hóa đơn',
  AMOUNT: 'Số tiền',
  VIEW_INVOICE: 'Xem hóa đơn',
  VIEW_RECEIPT: 'Xem biên lai',
  CARD_NO: 'Số thẻ',
  EXPIRES_ON: 'Hạn đến',
  CURRENT_SUBSCRIPTION: 'Gói đăng ký hiện tại',
  CURRENT_SUBSCRIPTION_APPLIED_PROMOTION_CODE:
    'Gói đăng ký hiện tại của bạn đã áp dụng mã khuyến mại và không thể áp dụng mã khác.',
  PROMOTION_CODE: 'Mã khuyến mãi',
  CHANGE_SUBSCRIPTION: 'Thay đổi đăng ký',
  APPLIED_DATE: 'Ngày áp dụng',
  PAYMENT_METHOD: 'Phương thức thanh toán',
  NO_CARDS: 'Không có thẻ',
  PROMOTION_CODE_CANT_APPLIED:
    'Không thể áp dụng mã khuyến mãi này cho gói hiện tại.',
  SUCCESSFULLY_APPLIED_PROMOTION_CODE: 'Đã áp dụng thành công mã khuyến mãi',
  FAILED_TO_APPLY_PROMOTION_CODE: 'Không áp dụng được mã khuyến mãi',
  PROMOTION_CODE_IS_REQUIRED: 'Mã khuyến mại là bắt buộc',
  ENTER_PROMOTION_CODE: 'Nhập mã khuyến mãi',
  SUCCESSFULLY_SET_DEFAULT_CARD: 'Đặt thành công thẻ mặc định',
  FAIL_TO_SET_DEFAULT_CARD: 'Không đặt được thẻ mặc định',
  ARE_YOU_SURE_TO_SET_THIS_CARD_AS_DEFAULT:
    'Bạn có chắc chắn đặt thẻ này làm mặc định không?',
  SET_DEFAULT_CARD: 'Đặt thẻ làm mặc định',
  INVALID_SUBSCRIPTION: 'Đăng ký không hợp lệ',
  INVALID_SUBSCRIPTION_SUMMARY:
    'Dung lượng bộ nhớ đã sử dụng của bạn đã vượt quá dung lượng cho phép của gói này. Vui lòng mở rộng bằng cách xóa các tệp bạn không cần thiết.',
  INVALID_REFERRAL_CODE: 'Mã giới thiệu không hợp lệ',
  CODE_USED:
    'Bạn đã sử dụng mã này trước đây. Vui lòng thử một mã khác hoặc để trống để tiếp tục.',
  TRY_ANOTHER_ONE: 'Vui lòng thử một mã khác hoặc để trống để tiếp tục.',
  FAILED_TO_SUBSCRIBE: 'Không thể đăng ký',
  FAILED_TO_GET_PROMOTION_CODE_INFO: 'Không lấy được thông tin khuyến mại',
  CHANGE_SUBSCRIPTION_PLAN: 'Thay đổi gói đăng ký',
  CHANGE_SUBSCRIPTION_PLAN_FIRST_SUMMARY:
    'Bạn sẽ tiếp tục gói hiện tại cho đến {{current_period_end}} và sẽ chuyển sang gói mới khi gia hạn.',
  CHANGE_SUBSCRIPTION_PLAN_SECOND_SUMMARY:
    'Mã khuyến mại <strong>{{removePromoCode}}</strong> của bạn chỉ có thể được áp dụng cho gói <strong>{{nickname}}</strong>. Nếu bạn thay đổi gói đăng ký của mình, chiết khấu khuyến mại sẽ bị xóa.',
  TRIAL_EXPIRES:
    'Bản dùng thử hết hạn. Không thể tạo đăng ký mà không có thông tin thanh toán',
  FAILED_TO_GET_DEPUTIES_LIST_TO_CHECK_PROFESSTIONAL_DEPUTY_PLAN:
    'Không lấy được danh sách đại diện để kiểm tra kế hoạch Đại diện chuyên nghiệp. Vui lòng thử lại.',
  FAILED_TO_SAVE_SUBSCRIPTION: 'Không lưu được đăng ký',
  CANCEL_SUBSCRIPTION: 'Hủy đăng ký?',
  CANCEL_SUBSCRIPTION_FIRST_SUMMARY:
    'Hành động này sẽ hạ cấp tài khoản của bạn xuống gói miễn phí khi kết thúc giai đoạn đăng ký hiện tại của bạn.',
  CANCEL_SUBSCRIPTION_SECOND_SUMMARY:
    'Sau khi đăng ký của bạn kết thúc, bạn sẽ không có quyền truy cập vào Sổ đăng ký và Tệp của mình nữa, chúng có thể bị xóa sau đó và không thể khôi phục được. <2><strong>Hãy nhớ tải xuống bản sao</strong></2>.',
  YOU_CAN_STILL_USE_YOUR_ACCOUNT_AS_DEPUTY:
    'Bạn vẫn có thể sử dụng tài khoản của mình để làm Người đại diện cho những người dùng <strong>bantex</strong> khác.',
  ARE_YOU_SURE_YOU_WISH_TO_CANCEL_SUBSCRIPTION:
    'Bạn có chắc chắn muốn hủy đăng ký của mình?',
  KEEP_SUBSCRIPTION: 'Giữ đăng ký',
  SUBSCRIPTION_CANCELLED: 'Đăng ký đã bị hủy',
  SUBSCRIPTION_CANCELLED_SUMMARY:
    'Đăng ký {{nickname}} của bạn sẽ kết thúc vào {{current_period_end}}',
  FAILED_TO_CANCEL_SUBSCRIPTION: 'Không thể hủy đăng ký',
  SUCCESSFULLY_CANCELLED_SUBSCRIPTION_CHANGE:
    'Đã hủy thành công thay đổi đăng ký',
  FAILED_TO_CANCEL_SUBSCRIPTION_CHANGE: 'Không thể hủy thay đổi đăng ký',
  CANCEL_CHANGE: 'Hủy thay đổi',
  SUBSCRIPTION_TESTING_NOTE:
    'Lưu ý kiểm thử: Các kế hoạch dưới đây chỉ là dữ liệu mẫu. Bạn có thể chọn bất kỳ mục nào để tiếp tục và không cần phải trả tiền.',
  SUBSCRIBE: 'Subscribe',
  CHANGE_SUBSCRIPTION_PLAN_ON:
    'Đăng ký của bạn sẽ thay đổi thành gói này vào {{changeDate}}. Hủy thay đổi này nếu bạn muốn giữ gói đăng ký hiện tại.',
  REFERRAL_CODE: 'Mã giới thiệu',
  REFERRAL_CODE_TOOLTIP_FIRST_SUMMARY:
    'Nếu bạn được người dùng bantex khác giới thiệu, hãy nhập mã giới thiệu của họ vào đây.',
  REFERRAL_CODE_TOOLTIP_SECOND_SUMMARY:
    'Để đăng ký thành công, bạn sẽ được giảm giá 5 USD và người giới thiệu của bạn sẽ nhận được khoản tín dụng 5 USD vào tài khoản của họ, có thể được áp dụng trong lần gia hạn tiếp theo.',
  THIS_IS_NOT_APPLIED_TO_THE_FREE_PLAN:
    'Điều này không áp dụng cho gói miễn phí (Chỉ dành cho Người đại diện).',
  ENTER_REFERRAL_CODE: 'Nhập mã giới thiệu (tùy chọn)',
  PLEASE_SELECT_A_CURRENCY: 'Vui lòng chọn đơn vị tiền tệ!',
  SUCCESSFULLY_CHANGED_PASSWORD: 'Đổi mật khẩu thành công',
  SUCCESSFULLY_SAVED_INACTIVIY_STATE:
    'Lưu cấu hình trạng thái không hoạt động thành công',
  FAILED_TO_SAVE_INACTIVIY_STATE:
    'Không thể lưu cấu hình trạng thái không hoạt động',
  INACTIVITY_STATE_CONFIG: 'Cấu hình trạng thái không hoạt động',
  WHEN_YOU_ARE_INACTIVE_FOR: 'Khi bạn không hoạt động trong',
  WE_WILL_SEND_YOU_REMINDER: 'chúng tôi sẽ gửi cho bạn lời nhắc',
  BUT_IF_YOU_DONOT_RESPOND_WITHIN: ' nhưng nếu bạn không trả lời trong vòng',
  OF_THE_REMINDER: 'tính từ ngày gửi sự kiện,',
  WE_WILL_INFORM_YOUR_DEPUTIES_ABOUT_YOUR_INACTIVITY:
    'chúng tôi sẽ thông báo cho người đại diện của bạn về việc bạn không hoạt động.',
  INACTIVITY_STATE_CONFIGURATION_TITLE: 'Cấu hình Trạng thái Không hoạt động',
  INACTIVITY_STATE_CONFIGURATION_FIRST_SUMMARY:
    'Thiết lập để thông báo cho Người đại diện của bạn khi bạn không hoạt động trong một thời gian dài.',
  INACTIVITY_STATE_CONFIGURATION_SECOND_SUMMARY:
    'Chúng tôi khuyên bạn nên thỉnh thoảng xem lại cấu hình của mình bất cứ khi nào có sự thay đổi lớn trong cuộc đời hoặc ít nhất một lần mỗi năm.',
  YOU_NEED_TO_HAVE_DEPUTIES_TO_ABLE_TO_USE_THIS_FEATURE:
    'Bạn cần có Người đại diện để có thể sử dụng tính năng này.',
  EMAIL_ADDRESS_UPDATED_AND_VERIFIED:
    'Địa chỉ email của bạn đã được cập nhật và xác minh. Vui lòng thiết lập lại xác thực đa yếu tố để cập nhật địa chỉ email hiển thị trong ứng dụng xác thực của bạn.',
  EMAIL_ADDRESS_VERIFICATION: 'Xác minh địa chỉ email',
  ENTER_THE_VERIFICATION_CODE:
    'Nhập mã xác minh đã được gửi đến địa chỉ email mới của bạn.',
  RECEIVER: 'Người nhận',
  SUBSCRIPTION_PLAN: 'Gói đăng ký',
  SENT_DATE: 'Ngày gửi',
  RECEIVED: 'Đã nhận',
  GIFTS_I_HAVE_SENT: 'Quà tôi đã gửi',
  YOU_NEED_TO_ADD_A_PAYMENT_METHOD_TO_SENT_GIFT:
    'Bạn cần thêm phương thức thanh toán để có thể gửi quà',
  SEND_GIFT: 'Gửi quà',
  NO_GIFTS: 'Không có quà tặng',
  SETUP_AGAIN: 'Thiết lập lại',
  MULTI_FACTOR_AUTHENTICATION_FIRST_SUMMARY:
    'Bạn đã thiết lập xác thực đa yếu tố bantex bằng ứng dụng xác thực của mình vào <strong>{{setupMfaDate}}</strong>.',
  MULTI_FACTOR_AUTHENTICATION_SECOND_SUMMARY:
    'Nếu bạn đang thay đổi điện thoại, trình xác thực hoặc loại MFA, bạn cần thiết lập lại. Khi đó các mã xác thực cũ sẽ không sử dụng được nữa.',
  RESEND_CODE: 'Gửi lại mã',
  ENTER_YOUR_PASSWORD_TO_PROCEED: 'Nhập mật khẩu để tiếp tục',
  FAILED_TO_EDIT_PROFILE: 'Không thể chỉnh sửa hồ sơ',
  SUCCESSFULLY_EDITED_PROFILE: 'Đã chỉnh sửa hồ sơ thành công',
  VERIFY_EMAIL_ADDRESS: 'Xác nhận địa chỉ email',
  SET_A_STRONG_PASSWORD_TO_PROTECT_YOUR_ACCOUNT:
    ' Đặt mật khẩu mạnh để bảo vệ tài khoản của bạn',
  QUOTE_CURRENCY: 'Đồng đối ứng',
  RATE: 'Tỉ giá',
  LAST_UPDATE: 'Cập nhật lần cuối',
  FAILE_TO_SET_CURRENCY: 'Không đặt được đơn vị tiền tệ',
  FAILED_TO_UPDATE_BASE_CURRENCY: 'Không đặt được đơn vị tiền tệ mặc định',
  BASE_CURRENCY_MEANS_YOUR_REPORTING_CURRENCY_OR_FUNCTIONAL_CURRENCY:
    'Tiền tệ mặc định là đơn vị tiền tệ dùng cho báo cáo hoặc các chức năng',
  SELECT_QUOTE_CURRENCY: 'Chọn đồng đối ứng',
  PLEASE_ENTER_AT_LEAST_ONE_EMAIL_ADDRESS:
    'Vui lòng nhập ít nhất 1 địa chỉ email.',
  SUCCESSFULLY_SENT_INVITATION: 'Đã gửi lời mời thành công',
  THIS_EMAIL_HAS_APPLIED_YOUR_REFERRAL_CODE:
    'Tài khoản bantex có địa chỉ email này đã áp dụng mã giới thiệu của bạn.',
  REFER: 'Giới thiệu',
  REFERRAL_CODE_SUMMARY:
    'Mã giới thiệu là duy nhất cho bạn và đã được chỉ định cho tài khoản của bạn. Bạn có thể giới thiệu đối tác, gia đình và bạn bè của mình tham gia bantex và nhận phần thưởng. Để đăng ký thành công, họ sẽ nhận được giảm giá US$ 5 và bạn sẽ nhận được khoản tín dụng US$ 5 vào tài khoản của mình để có thể áp dụng trong lần gia hạn tiếp theo.',
  SEND_REFERRAL_LINK: 'Gửi liên kết giới thiệu',
  SEND: 'Gửi',
  EMAIL_ADDRESSES: 'Địa chỉ email',
  YOU_HAVE_SENT_A_GIFT_TO_THIS_EMAIL:
    'Bạn đã gửi một món quà đến địa chỉ email này trước đây và nó vẫn đang chờ được chấp nhận.',
  EXISTING_VAULTBOX_USER_CAN_NOT_RECEIVE_GIFT:
    'Người dùng bantex có địa chỉ email này đã tồn tại. Người dùng bantex hiện tại không thể nhận quà.',
  SUCCESS_PURCHASED: 'Đã mua thành công! Món quà đang được chuyển đi!',
  FAILED_TO_PURCHASE_GIFT: 'Không mua được quà',
  PURCHASE: 'Mua',
  ENTER_EMAIL_ADDRESS: 'Nhập địa chỉ email',
  SELECT_A_PLAN: 'Chọn một kế hoạch',
  ENTER_QUANTITY: 'Nhập số lượng',
  GIFT_MESSAGE: 'Lời nhắn',
  GIFT_MESSAGE_SUMMARY:
    'Tôi đang sử dụng bantex và tôi nghĩ nó cũng rất hữu ích cho bạn! Đây là đăng ký bantex từ tôi để giúp bạn bắt đầu.',
  INPUT_GIFT_MESSAGE: 'Nhập lời nhắn',
  GIFTS: 'Quà tặng',
  RESET_REGISTRY: 'Đặt lại sổ đăng ký',
  SUCCESSFULLY_RESET_REGISTRY: 'Đặt lại sổ đăng ký thành công',
  FAILED_TO_DELETE_USER: 'Không xóa được người dùng',
  FAILED_TO_RESET_REGISTRY: 'Không thể đặt lại sổ đăng ký',
  INVALID_FORM: 'Biểu mẫu không hợp lệ',
  ACTIVITIES: 'Hoạt động',
  DEPUTY_ACCESSES_ONLY: ' Chỉ người được chia sẻ có quyền truy cập',
  ACCESSED_YOUR_VAULTBOX: '{{name}} ({{email}}) đã truy cập bantex của bạn',
  DELETE_ACCOUNT: 'Xóa tài khoản',
  DOWNLOAD_DATA_AND_DELETE_ACCOUNT: 'Tải xuống dữ liệu và xóa tài khoản',
  ARE_YOU_SURE_YOU_WANT_TO_RESET_YOUR_REGISTRY:
    'Bạn có chắc chắn muốn đặt lại sổ đăng ký của mình không? Tất cả dữ liệu đăng ký của bạn sẽ bị xóa và không thể lấy lại được trong tương lai.',
  ENTER_PASSWORD: 'Nhập mật khẩu',
  ENTER_A_NEW_EMAIL_WHICH_WAS_NOT_SPECIFIED_BEFORE:
    'Nhập một email chưa được sử dụng trước đây.',
  SUCCESSFULLY_SPECIFIED_DEPUTIES: 'Chỉ định Người đại diện thành công',
  FAILED_TO_SPECIFY_DEPUTY: 'Không thể chỉ định Người đại diện',
  PLEASE_ADD_AT_LEAST_ONE_DEPUTY: 'Vui lòng thêm ít nhất 1 Người đại diện',
  SELECT_A_DEPUTY_TYPE: 'Chọn loại đại diện',
  APPOINT_SOMEBODY_ELSE: 'Chỉ định người khác',
  WATIGA_TRUST: 'Watiga Trust',
  WATIGA_TRUST_FIRST_SUMMARY:
    'Chúng tôi đã hợp tác với Watiga Trust Ltd (“Watiga Trust”), một công ty ủy thác được cấp phép tại Singapore, để cung cấp các dịch vụ ủy thác bảo mật và chuyên nghiệp cho bạn.',
  WATIGA_TRUST_SECOND_SUMMARY:
    'Watiga Trust sẽ lưu giữ khóa khôi phục an toàn và họ có thể khôi phục quyền truy cập vào <strong>bantex</strong> cho bạn hoặc người đại diện hợp pháp sau khi bạn qua đời.',
  WATIGA_TRUST_TERMS_OF_SERVICE:
    'Điều khoản dịch vụ của Watiga Trust Professional Vice',
  OTHER_PD_TERMS_OF_SERVICE: 'Điều khoản dịch vụ của Đại diện chuyên nghiệp',
  PROFESSIONAL_DEPUTY_SETUP_IS_NOT_YET_COMPLETED:
    'Chưa hoàn thành thiết lập Đại diện chuyên nghiệp.',
  YOU_CANT_HAVE_BOTH_NOMARL_AND_PD_DEPUTY:
    'Bạn không thể có cả Đại diện bình thường và Đại diện chuyên nghiệp. Để có thể thêm một Đại diện chuyên nghiệp, vui lòng hủy bỏ tất cả các Đại diện bình thường của bạn.',
  YOU_HAVE_ADDED_PD:
    'Bạn đã thêm một Người đại diện chuyên nghiệp trước đó và không thể thêm người khác.',
  PD_ACCOUNTS_ARE_NOT_YET_AVAILABLE:
    'Tài khoản Người đại diện Chuyên nghiệp chưa có sẵn.',
  YOU_CANT_HAVE_BOTH_PD_AND_NOMARL_DEPUTY:
    'Bạn không thể có cả Đại diện bình thường và Đại diện chuyên nghiệp. Để có thể thêm một Đại diện thông thường, vui lòng hủy bỏ Đại diện chuyên nghiệp của bạn',
  WATIGA_TRUST_DETAIL_FIRST_SUMMARY:
    'Watiga Trust là một công ty ủy thác được cấp phép do Cơ quan tiền tệ Singapore quản lý. Là một tổ chức tài chính được cấp phép ở Singapore, Watiga Trust phải tuân thủ các yêu cầu nghiêm ngặt, bao gồm chống rửa tiền và chống tài trợ khủng bố.',
  WATIGA_TRUST_DETAIL_SECOND_SUMMARY:
    'Để Watiga Trust hoạt động với tư cách là Người đại diện của bạn, vui lòng cung cấp thông tin sau',
  OTHER_PD_SUMMARY:
    'Để <strong>{{professionalDeputyName}}</strong> hoạt động với tư cách là Người đại diện của bạn, vui lòng cung cấp thông tin sau:',
  WATIGA_TRUST_DETAIL_THIRD_SUMMARY:
    'Chúng tôi sẽ lưu thông tin và tệp này trong <strong>bantex</strong> của bạn và truyền đến Watiga Trust một cách an toàn. Watiga Trust sẽ giữ bí mật thông tin và sử dụng chúng cho các mục đích xác minh thẩm định theo <5>Điều khoản dịch vụ của Người đại diện chuyên nghiệp Watiga Trust</5>. Nếu các tài liệu hoặc thông tin không chính xác hoặc không đầy đủ, Watiga Trust có thể liên hệ với bạn để xác minh.',
  NORMAL_DEPUTY_SUMMARY:
    'Để chọn người khác làm Người đại diện của bạn, nhập địa chỉ email của họ vào bên dưới. Họ sẽ nhận được một liên kết để thiết lập Người đại diện. Nếu họ đã có tài khoản <strong>bantex</strong>, ấn định này sẽ được lưu trong <strong>bantex</strong> của họ. Nếu không, họ sẽ có thể tạo một tài khoản giới hạn miễn phí để làm Người địa diện của bạn. Bạn cần ít nhất 2 người đại diện.',
  EMAIL_ADDRESS_OF_DEPUTY: 'Email của Người đại diện',
  DOCUMENTS_UPLOADED: 'Tài liệu đã tải lên',
  MY_ID_CONTAINS_MY_RESIDENTIAL_ADDRESS: 'CMND chứa địa chỉ của tôi.',
  WATIGA_TRUST_REVIEW_SUMMARY:
    'Tôi đồng ý chỉ định Watiga Trust làm Người đại diện chuyên nghiệp của tôi theo <2>Điều khoản dịch vụ của Người đại diện chuyên nghiệp Watiga Trust</2>',
  OTHER_PD_REVIEW_SUMMARY:
    'Tôi đồng ý chỉ định {{professionalDeputyName}} làm Người đại diện chuyên nghiệp của tôi theo <5>Điều khoản dịch vụ của Người đại diện chuyên nghiệp</5>',
  WE_WILL_EMAIL_YOUR_DEPUTY_FOR_THEIR_ACCEPTANCE:
    'Chúng tôi sẽ gửi email cho Người đại diện của bạn để yêu cầu chấp thuận.',
  ENTER_FULL_NAME: 'Điền tên đầy đủ',
  ENTER_CONTACT_NUMBER: 'Điền số điện thoại',
  ENTER_ID_PASSPORT_NUMBER: 'Điền số CMND / Hộ chiếu',
  SELECT_NATIONALITY: 'Chọn quốc tịch',
  ENTER_RESIDENTIAL_ADDRESS: 'Điền địa chỉ',
  YOUR_ID_PASSPORT_FILENAME_IS: 'Tên tệp CMND / Hộ chiếu của bạn là',
  YOUR_PROOF_OF_ADDRESS_FILENAME_IS: 'Tên tệp Chứng minh Địa chỉ của bạn là',
  UPLOAD_ADDRESS_PROOF_DATED_WITHIN_THE_LAST_SIX_MONTHS:
    'Tải lên bằng chứng địa chỉ trong vòng sáu (6) tháng qua:',
  ID_CARD: 'CMND',
  UTILITY_BILL: 'Hóa đơn điện nước',
  CORRESPONDENCE_FROM_TAX_OFFICE_OR_OTHER_GOVERMENT_AUTHORITY:
    'Thư từ của cơ quan thuế hoặc cơ quan chính phủ khác',
  USERNAME_IS_NULL: 'Tên người dùng rỗng. Không thể truy xuất phiên mới',
  INVALID_SESSION: 'Phiên làm việc không hợp lệ. Vui lòng xác thực.',
  LOCAL_STORAGE_IS_MISSING_AN_ID_TOKEN:
    'Bộ nhớ cục bộ thiếu ID. Vui lòng xác thực',
  ENTER_ANOTHER_EAMIL_NOT_YOUR_OWN_ONE:
    'Nhập một email khác, không phải email của bạn.',
  ENTER_AN_EMAIL_WHICH_IS_DEFFETENT_FROM_PD_EMAIL:
    'Nhập một email khác với các email Người đại diện chuyên nghiệp',
  FILE_NAME_ALREADY_EXISTS:
    'Tên tệp đã tồn tại trong thư mục hiện tại, vui lòng đổi tên',
  VALUE_MUST_BE_GREATER_THAN: 'Giá trị phải lớn hơn {{min}}',
  NRIC_IS_INVALID: 'NRIC không hợp lệ',
  FIN_IS_INVALID: 'FIN không hợp lệ',
  REFERENCES_TOOLTIP_SUMMARY:
    'Các liên hệ có thể được sử dụng cho nhiều mục đích. Mỗi liên hệ có một nhãn và thông tin phù hợp của nó.',
  ASSET_LIABILITY_USE_CASE_EXAMPLES: 'Ví dụ về trường hợp sử dụng tài sản / nợ',
  EQITY_LISTED_INFORMATION_OF_TICKER_ISIN:
    'Vốn chủ sở hữu được liệt kê: thông tin về Mã chứng khoán hoặc mã ISIN',
  INSURANCE_SURRENDER_VALUE: 'Bảo hiểm: giá trị giải ước',
  CONTACT_USE_CASE_EXAMPLES: 'Ví dụ về trường hợp sử dụng danh bạ',
  INDIVIDUAL_CONTACT_INFORMATION:
    'Liên hệ cá nhân: thông tin của các thành viên gia đình hoặc đồng nghiệp có thể được sử dụng để liên lạc khẩn cấp (ví dụ: Nhãn: Vợ, Thông tin: +6512345678)',
  ORGANISATION_CONTACT_INFORMATION:
    'Liên hệ tổ chức: thông tin của Người phụ trách (ví dụ: Nhãn: John (PIC), Thông tin: +6512345678 - john@example.com)',
  ENTER_ALPHANUMERIC_CHARACTERS_AND_DASH_ONLY:
    'chỉ nhập các ký tự chữ, số và gạch ngang',
  INVALID_CODE_PLEASE_TRY_AGAIN: 'Mã không hợp lệ. Vui lòng thử lại.',
  INVALID_CODE_FAILED_TO_SETUP_MFA:
    'Mã không hợp lệ. Không thiết lập được xác thực đa yếu tố. Vui lòng thử lại.',
  USER_IS_NOT_CONFIRMED:
    'Người dùng chưa được xác nhận - vui lòng kiểm tra email kích hoạt của bạn.',
  UNKNOWN_ERROR_DETECTED: 'Đã phát hiện lỗi không xác định',
  DOWNLOADING_VAULTBOX: 'Đang tải bantex',
  DOWNLOAD_STEP: 'Đã tải {{index}} trên {{size}} tệp',
  SAVING_ZIP_PART: 'Đang lưu phần nén',
  SAVING_ZIP: 'Đang lưu',
  FAILED_TO_DOWNLOAD_THE_FOLLOWING_FILES: 'Không tải xuống được các tệp sau',
  PLEASE_DOWNLOAD_THEM_MANUALLY: 'Vui lòng tải chúng xuống theo cách thủ công.',
  DOWNLOADED_VAULTBOX: 'Đã tải bantex',
  FAILED_TO_DOWNLOAD_ALL_VAULTBOX: 'Không tải xuống được toàn bộ bantex',
  FILE_NAMES: 'Tên tệp',
  ALREADY_EXISTS_IN_THE_CURRENT_FOLDER:
    'đã tồn tại trong thư mục hiện tại, vui lòng đổi tên',
  DEPUTY_ONLY: 'Chỉ Người đại diện',
  THIRTY_DAYS_FREE_TRIAL: '30 ngày dùng thử miễn phí',
  ASSETS_AND_LIABILITY_REGISTRY: 'Đăng ký tài sản và nợ',
  TRACKING_NETWORTH: 'Theo dõi tài sản ròng',
  QUANTITY_OF_SECURE_FILE_STORAGE: '50 GB lưu dữ liệu',
  SAVE_YOUR_IMPORTANT_CONTACT: 'Lưu liên hệ quan trọng',
  SAVE_YOUR_UPLOADED_FILE_PASSWORD: 'Lưu mật khẩu tệp đã tải lên của bạn',
  APPOINT_DEPUTIES: 'Chỉ định Người đại diện',
  BE_APPOINTED_AS_A_DEPUTY: 'Trở thành Người đại diện',
  ABILITY_TO_APPOINT_PD_AS_ADDON_AT:
    'Khả năng làm việc với người đại diện chuyên nghiệp với giá ',
  INCLUDE_PROPERTY_LOAN: 'Bao gồm khoản cho vay tài sản',
  PROPERTY_LOAN_INFORMATION: 'Thông tin cho vay tài sản',
  LINKED_DOCUMENTS: 'Tài liệu được liên kết',
  SECRET_FILE_ALERT_CONTENT:
    'Tệp này đã được đánh dấu là bí mật và không thể xem hoặc tải xuống. Để lấy lại quyền truy cập tệp, vui lòng yêu cầu Người đại diện trợ giúp để mở khóa.',
  LINKS: 'Liên kết',
  TOTAL_ASSETS: 'Tổng tài sản',
  TODAY: 'Hôm nay',
  ALL_TIME: 'Mọi lúc',
  W_AS_WEEK: 'T',
  M_AS_MONTH: 'Th',
  Y_AS_YEAR: 'N',
  SECURITY_CODE: 'Mã Bảo Vệ',
  MARKET_VALUE: 'Giá Thị Trường',
  LAST_PRICE_DATE: 'Ngày Cập Nhật Giá',
  REFERENCE_NAME_WITH_INDEX: 'Tên tham chiếu {{index}}',
  REFERENCE_LABEL_WITH_INDEX: 'Nhãn tham chiếu {{index}}',
  REFERENCE_VALUE_WITH_INDEX: 'Chi tiết liên hệ {{index}}',
  EMAIL_LABEL_WITH_INDEX: 'Nhãn Email {{index}}',
  EMAIL_VALUE_WITH_INDEX: 'Chi tiết Email {{index}}',
  ADDRESS_LABEL_WITH_INDEX: 'Nhãn địa chỉ {{index}}',
  ADDRESS_VALUE_WITH_INDEX: 'Chi tiết địa chỉ {{index}}',
  PHONE_NUMBER_LABEL_WITH_INDEX: 'Nhãn SĐT {{index}}',
  PHONE_NUMBER_VALUE_WITH_INDEX: 'Chit tiết SĐT {{index}}',
  FIELD_NAME: 'Tên Trường',
  FIELD_VALUE: 'Giá Trị Trường',
  OTHER_PROFESSIONAL_DEPUTY: 'Người đại diện khác',
  ENTER_PROFESSIONAL_DEPUTY_REFERRAL_CODE: 'Nhập mã giới thiệu người đại diện',
  PROFESSIONAL_DEPUTY_NOT_FOUND: 'Không tìm thấy người đại diện',
  THE_PROFESSIONAL_DEPUTY_IS_DEFAULT:
    'Người đại diện bạn đang tìm kiếm đã là mặc định của bạn',
  FAILED_TO_LOAD_DEPUTIES: 'Không thể tải được người đại diện',
  FAILED_TO_LOAD_DISTRIBUTE_KEYS:
    'Không thể phân phối khóa cho người đại diện của bạn.',
  SUCCESSFULLY_REVOKED_DEPUTY: 'Đã thu hồi người đại diện thành công',
  FAILED_TO_REVOKE_DEPUTY: 'Không thể thu hồi người đại diện',
  SUCCESSFULLY_RESENT_DEPUTY_REQUEST:
    'Đã gửi lại thành công yêu cầu người đại diện',
  FAILED_TO_RESENT_DEPUTY_REQUEST: 'Không thể gửi lại yêu cầu người đại diện',
  SUCCESSFULLY_RESENT_UNLOCK_REQUEST: 'Đã gửi lại thành công yêu cầu mở khóa',
  FAILED_TO_RESENT_UNLOCK_REQUEST: 'Không thể gửi lại được yêu cầu mở khóa',
  FETCHING_LASTEST_EXCHANGE_RATES: 'Tìm nạp tỷ giá hối đoái mới nhất...',
  FAILED_TO_FETCH_LASTEST_EXCHANGE_RATES:
    'Không thể tìm nạp được tỷ giá hối đoái mới nhất.',
  RETRY: 'Thử lại',
  SUCCESSFULLY_FETCHED_LASTEST_EXCHANGE_RATES:
    'Đã tìm nạp thành công tỷ giá hối đoái mới nhất',
  FAILED_TO_FETCH_SAVE_EXCHANGE_RATES:
    'Không thể lưu được tỷ giá hối đoái mới nhất.',
  UPDATING_VALUATION_IN_BASE_CURRENCY:
    'Cập nhật giá trị tài sản / nợ phải trả bằng tiền tệ cơ bản ...',
  SUCCESS_UPDATED_VALUATION_IN_BASE_CURRENCY:
    'Đã cập nhật thành công giá trị tài sản / nợ phải trả bằng tiền tệ cơ bản.',
  FAILED_TO_UPDATE_VALUATION_IN_BASE_CURRENCY:
    'Không thể cập nhật được giá trị tài sản / nợ phải trả bằng đơn vị tiền tệ cơ bản.',
  FAILED_TO_GET_INSTRUCTION_FILE: 'Không lấy được tệp hướng dẫn.',
  FAILED_TO_SAVE_LEGACY_MANAGEMENT_CONFIGURATION:
    'Không thể lưu cấu hình quản lý kế thừa kế.',
  FAILED_TO_SET_USER_MFA_PREFERENCE:
    'Không đặt được tùy chọn MFA của người dùng.',
  FAILED_TO_RESET_PASSWORD: 'Không đặt lại được mật khẩu.',
  FAILED_TO_REGISTER_USER: 'Không thể đăng ký người dùng hoặc người dùng có thể đã tồn tại.',
  FAILED_TO_VERIFY_EMAIL_ADDRESS: 'Không xác minh được địa chỉ email.',
  FAILED_TO_AUTHENTICATE_USER: 'Không thể xác thực người dùng.',
  FAILED_TO_GET_PLAN: 'Không thể lấy được kế hoạch',
  FAILED_TO_SENT_INVITATION: 'Không thể gửi được lời mời',
  FAILED_TO_UPDATE_USER_ATTRIBUTES:
    'Không thể cập nhật được thuộc tính người dùng',
  FAILED_TO_CHANGE_PASSWORD: 'Không thể thay đổi được mật khẩu',
  INVALID_PARAMETER_EXCEPTION: 'Tham số không hợp lệ. Vui lòng thử lại',
  DID_NOT_RECEIVE_THE_EMAIL: 'Không nhận được email?',
  RESEND_EMAIL: 'Gửi lại email',
  INPUT_EMAIL_CODE: 'Nhập mã xác minh trong trong email của bạn.',
  FAILED_TO_UPDATE_AUTH_FLOW: 'Không thể cập nhật được quy trình xác thực.',
  AUTHENTiCATION_FLOW_TYPE: 'Quy trình xác thực',
  SELECT_A_TYPE_TO_SETUP_FLOW: 'Chọn một loại để thiết lập quy trình',
  CHANGE_DEFAULT_LANGUAGE: 'Thay đổi ngôn ngữ mặc định',
  CHANGE: 'Thay đổi',
  FAIILED_TO_CHANGE_DEFAULT_LANGUAGE:
    'Không thể thay đổi được ngôn ngữ mặc định',
  YOUR_DEFAULT_LANGUAGE_IS: 'Ngôn ngữ mặc định của bạn là',
  CHANGE_DEFAULT_LANGUAGE_SUMMARY:
    'Ngôn ngữ mặc định của bạn là {{defaultLanguage}}. Nhưng ngôn ngữ đang được chọn là {{selectedLanguage}}. Bạn muốn sử dụng ngôn ngữ nào?',
  ENGLISH: 'Tiếng Anh',
  VIETNAMESE: 'Tiếng Việt',
  SELECTED_LANGUAGE: 'Ngôn ngữ đã chọn',
  DEFAULT_LANGUAGE: 'Ngôn ngữ mặc định',
  MFA_CODE: 'Digital OTP',
  EMAIL_CODE: 'Email OTP',
  FAILED_TO_GET_AUTHENTICATION_FLOW_TYPE: 'Không lấy được quy trình xác thực',
  INCORRECT_USERNAME_OR_PASSWORD:
    'Tên đăng nhập hoặc mật khẩu không chính xác.',
  EMAIL_OTP_SUMMARY:
    'Bạn đã thiết lập xác thực đa yếu tố bantex bằng địa chỉ email của mình vào <strong>{{setupMfaDate}}</strong>',
  CHANGE_AUTH_FLOW_TYPE: 'Thay đổi kiểu xác thực',
  PENDING_ASSETS: 'Tài sản đang chờ xử lý',
  PENDING_LIABILITIES: 'Nợ đang chờ xử lý',
  ACTIVE: 'Hoạt động',
  REJECTED_LIABILITIES: 'Nợ bị từ chối',
  REJECTED_ASSETS: 'Tài sản bị từ chối',
  REJECTED: 'Từ chối',
  REJECTED_REASON: 'Lý do từ chối',
  REJECTED_REASON_INPUT_MSG: 'Vui lòng nhập lý do từ chối',
  BAHASA_INDONESIA: 'Tiếng Indonesia',
  RESTORE: 'Khôi phục',
  PENDING_CONTACT_WARNING_COUNT:
    '{{type}} này đang được liên kết với {{contactsCount}} liên hệ đang chờ xử lý',
  DELETE_ASSET_LIABILITY: 'Xóa tài sản / nợ phải trả',
  ARE_YOU_SURE_TO_DELETE_THIS_RECORD: 'Bạn có chắc chắn xóa bản ghi này không?',
  FAILED_TO_UPDATE_ACCESS_LEVEL: 'Không thể cập nhật cấp độ truy cập',
  PROFESSIONAL_DEPUTY_ACCESS_LEVEL:
    'Cấp độ truy cập của người đại diện chuyên nghiệp',
  CHANGE_ACCESS_LEVEL: 'Thay đổi cấp độ truy cập',
  REJECTION_REASON: 'Lý do từ chối',
  ALLOW:
    'Được chấp thuận trước để thực hiện tất cả các thay đổi đối với dữ liệu của tôi',
  NEED_APPROVAL: 'Cần được chấp thuận',
  DENY: 'Không được phép thực hiện bất kỳ thay đổi nào',
  DEPUTY_ACCESS: 'Người đại diện truy cập',
  CALENDAR: 'Lịch',
  FAILED_TO_SAVE_THE_EVENT: 'Lưu sự kiện không thành công',
  EVENTS: 'Danh sách sự kiện',
  ADD_EVENT: 'Thêm sự kiện',
  EVENT_DETAILS: 'Chi tiết sự kiện',
  EDIT_EVENT: 'Chỉnh sửa sự kiện',
  SUCCESSFULLY_SAVE_THE_EVENT: 'Thêm sự kiện thành công',
  EVENT_LINKS: 'Liên kết sự kiện',
  EVENT: 'Sự kiện',
  UNLINK_EVENT_CONFIRM: 'Bạn có chắc chắn hủy liên kết sự kiện này không?',
  SELECT_DOCUMENTS: 'Chọn tài liệu',
  PENDING_FOLDES: 'thư mục đang chờ xử lý',
  PENDING_FILES: 'tệp đang chờ xử lý',
  SUCCESSFULLY_DELETED_DOCUMENTS: 'Đã xóa thành công tài liệu',
  FAILED_TO_DELETE_DOCUMENTS: 'Không thể xóa được tài liệu',
  FAILED_TO_UNLINK_FROM_FILE: 'Không thể hủy liên kết khỏi tệp',
  SUCCESSFULLY_UPDATED_PENDING_DOCUMENTS:
    'Đã cập nhật thành công tài liệu đang chờ xử lý',
  FAILED_TO_UPDATE_PENDING_DOCUMENTS:
    'Không cập nhật được tài liệu đang chờ xử lý',
  SUCCESSFULLY_REJECTED_DOCUMENTS: 'Đã từ chối tài liệu thành công',
  FAILED_TO_REJECT_DOCUMENTS: 'Không thể từ chối tài liệu',
  SUCCESSFULLY_APPROVED_DOCUMENTS: 'Tài liệu được phê duyệt thành công',
  FAILED_TO_APPROVE_DOCUMENTS: 'Không thể phê duyệt tài liệu',
  FREE_ACCOUNT_SUMMARY:
    'Tài khoản miễn phí chỉ có thể hoạt động như Người đại diện cho những người dùng <strong> bantex </strong> khác. Nâng cấp gói đăng ký của bạn để sử dụng các tính năng khác của <strong> bantex </strong>',
  NOT_NOW: 'Không phải bây giờ',
  UPGRADE: 'Nâng cấp',
  PENDING_DOCUMENT_WARNING_COUNT:
    '{{type}} này đang được liên kết với {{documentCount}} tài liệu đang chờ xử lý',
  PENDING_ASSET_LIABILITY_WARNING_COUNT:
    '{{type}} đang được liên kết với {{assetLiabilityCount}} tài sản / nợ đang chờ xử lý',
  CONTACT: 'Liên hệ',
  UNLINK_CONFIRM: 'Xác nhận hủy liên kết',
  DATE: 'Ngày',
  ASSET_LIABILITY: 'Tài sản/Nợ',
  REMINDER_DAYS: 'Số ngày nhắc nhở',
  SUCCESSFULLY_UNLINKED_EVENT: 'Sự kiện được hủy liên kết thành công',
  RECURRING_EVENT: 'Lặp lại sự kiện',
  END_DATE: 'Ngày kết thúc',
  ACCESS_LEVEL: 'Cấp độ truy cập',
  UPCOMING_EVENTS: 'Sự kiện sắp tới',
  DONT_SHOW_AGAIN: 'Không hiển thị lại',
  RECURRING_TIMES: 'Số lần lặp',
  END_DATE_OF_RECURRING: 'Ngày kết thúc lặp',
  SELECT_DATE: 'Vui lòng chọn ngày',
  SELECT_RECURRING_TIMES: 'Vui lòng chọn số lần lặp',
  THIS_EVENT: 'Sự kiện này',
  THIS_AND_FOLLOWING_EVENTS: 'Sự kiện này và các sự kiện tiếp theo',
  ALL_EVENTS: 'Tất cả các sự kiện',
  EDIT_RECURRING_EVENT: 'Chỉnh sửa sự kiện lặp lại',
  DELETE_RECURRING_EVENT: 'Xóa sửa sự kiện lặp lại',
  CONFIRM_DOWNLOAD_VAULTBOX: 'Xác nhận tải xuống bantex',
  CONFIRM_DOWNLOAD_BANTEX_FILES: 'Xác nhận tải xuống tất cả các tệp',
  CONFIRM_DOWNLOAD_VAULTBOX_MSG: 'Bạn có chắc chắn tải xuống bantex không?',
  WARNING_DOWNLOAD_VAULTBOX_MSG: 'Quá trình tải xuống có thể mất vài phút',
  CONFIRM_DOWNLOAD_BANTEX_FILES_MSG:
    'Bạn có chắc chắn tải xuống tất cả các tệp không?',
  DOWNLOAD_FILES: 'Tải tập tin xuống',
  TOTAL_SIZE: 'Kích thước',
  WAITING_FOR_DISCONNECTION: 'Đang chờ ngắt kết nối',
  FAILED_TO_DISCONNECT_USER: 'Không thể ngắt kết nối người dùng',
  ARE_YOU_SURE_TO_DISCONNECT_THIS_USER:
    'Bạn có chắc chắn ngắt kết nối người dùng này không?',
  DISCONNECT: 'Ngắt kết nối',
  DISCONNECT_USER_REQUESTS: 'Yêu cầu ngắt kết nối người dùng',
  REQUEST_TO_DISCONNECT_ACCOUNT:
    'Người đại diện của bạn đã gửi yêu cầu ngắt kết nối người dùng.',
  APPROVE_TO_DISCONNECT: 'Chấ nhận ngắt kết nối',
  TRANSFER_TO_AN_OTHER_DEPUTY: 'Chuyển sang người đại diện khác',
  THE_PD_IS_WAITING_FOR_DISCONNECTION:
    'Người đại diện chuyên nghiệp đang chờ ngắt kết nối',
  NO_OF_YEARS_REPAYMENT_COMPLETED: 'Số năm / khoản hoàn trả đã hoàn thành',
  USE_EMAIL_OTP_CODE: 'Sử dụng mã OTP email',
  COLOR: 'Màu sắc',
  YOUR_PASSWORD_HAS_BEEN_RESET: 'Đặt lại mật khẩu thành công.',
  PASSWORDS: 'Mật khẩu',
  USERNAME: 'Tên đăng nhập',
  INPUT_USERNAME_MSG: 'Nhập tên đăng nhập của bạn!',
  INPUT_URL_MSG: 'Hãy nhập URL hợp lệ (ví dụ: http://example.com)',
  EDIT_PASSWORD: 'Sửa mật khẩu',
  REMOVE_PASSWORD: 'Xóa mật khẩu',
  SUCCESSFULLY_SAVED_THE_PASSWORD: 'Lưu mật khẩu thành công',
  FAILED_TO_SAVE_THE_PASSWORD: 'Lưu mật khẩu thất bại',
  PASSWORD_LINKS: 'Liên kết mật khẩu',
  SELECT_PASSWORD: 'Chọn mật khẩu',
  ADD_NEW_PASSWORD: 'Tạo mới mật khẩu',
  LINK_PASSWORD: 'Liên kết mật khẩu',
  SUCCESSFULLY_DELETED_THE_PASSWORD: 'Xóa mật khẩu thành công',
  FAILED_TO_DELETE_THE_PASSWORD: 'Xóa mật khẩu thất bại',
  UNLINK_PASSWORD: 'Unlink password',
  SUCCESSFULLY_UNLINKED_PASSWORD: 'Hủy liên kết với mật khẩu thành công',
  FAILED_TO_UNLINK_PASSWORD: 'Hủy liên kết với mật khẩu thất bại',
  PASSWORD_DETAILS: 'Chi tiết mật khẩu',
  UNLINK_PASSWORD_CONFIRM: 'Bạn có muốn hủy liên kết với mật khẩu không?',
  PASSWORD_RECORDS: 'mật khẩu',
  SELECT_PASSWORDS: 'Chọn mật khẩu',
  TOO_SHORT: 'Quá ngắn',
  TOO_WEAK: 'Rất yếu',
  WEAK: 'Yếu',
  FAIR: 'Trung bình',
  GOOD: 'Tốt',
  STRONG: 'Rất tốt',
  SUN: 'Chủ nhật',
  MON: 'Thứ 2',
  TUE: 'Thứ 3',
  WED: 'Thứ 4',
  THU: 'Thứ 5',
  FRI: 'Thứ 6',
  SAT: 'Thứ 7',
  SUN_MD: 'CN',
  MON_MD: 'TH2',
  TUE_MD: 'TH3',
  WED_MD: 'TH4',
  THU_MD: 'TH5',
  FRI_MD: 'TH6',
  SAT_MD: 'TH7',
  JAN: 'Tháng 1',
  FEB: 'Tháng 2',
  MAR: 'Tháng 3',
  APR: 'Tháng 4',
  MAY: 'Tháng 5',
  JUN: 'Tháng 6',
  JUL: 'Tháng 7',
  AUG: 'Tháng 8',
  SEP: 'Tháng 9',
  OCT: 'Tháng 10',
  NOV: 'Tháng 11',
  DEC: 'Tháng 12',
  JAN_MD: 'T1',
  FEB_MD: 'T2',
  MAR_MD: 'T3',
  APR_MD: 'T4',
  MAY_MD: 'T5',
  JUN_MD: 'T6',
  JUL_MD: 'T7',
  AUG_MD: 'T8',
  SEP_MD: 'T9',
  OCT_MD: 'T10',
  NOV_MD: 'T11',
  DEC_MD: 'T12',
  MONTH: 'Tháng',
  YEAR: 'Năm',
  SELECT_EVENT: 'Chọn sự kiện',
  ADD_NEW_EVENT: 'Thêm mới sự kiện',
  TO: 'Đến',
  WEBSITE: 'Trang web',
  None: 'Không lặp lại',
  INCORRECT_USERNAME_OR_PASSWORD_ERROR_MSG:
    'Tên người dùng hoặc mật khẩu không chính xác hoặc yêu cầu kích hoạt tài khoản.',
  LOGOUT_AND_ADD_CARD_LATER: 'Đăng xuất và thêm thẻ sau',
  Weekly: 'Theo tuần',
  Monthly: 'Theo tháng',
  Quarterly: 'Theo quý',
  Yearly: 'Theo năm',
  Annually: 'Theo năm',
  day: 'ngày',
  month: 'tháng',
  year: 'năm',
  days: 'ngày',
  Months: 'Tháng',
  months: 'tháng',
  Years: 'Năm',
  years: 'năm',
  Cash: 'Tiền mặt',
  Property: 'Bất động sản',
  Vehicle: 'Phương tiện',
  Insurance: 'Bảo hiểm',
  Crypto: 'Tiền điện tử',
  Trust: 'Lòng tin',
  NRIC: 'NRIC',
  FIN: 'FIN',
  Passport: 'Hộ chiếu',
  Personal: 'Cá nhân',
  Other: 'Khác',
  Mobile: 'Di động',
  Work: 'Công việc',
  Home: 'Nhà riêng',
  Hospitalisation: 'Nhập viện',
  Travel: 'Du lịch',
  House: 'Nhà ở',
  Apartment: 'Căn hộ',
  Commercial: 'Thương mại',
  Freehold: 'Miễn phí',
  Leasehold: 'Cho thuê',
  name: 'tên',
  value: 'giá trị',
  Currency: 'Tiền tệ',
  Items: 'Mục',
  Valuation: 'Định giá',
  Ticker: 'Ticker',
  ISIN: 'ISIN',
  Individual: 'Cá nhân',
  Organisation: 'Cơ quan',
  Savings: 'Tiết kiệm',
  Current: 'Hiện hành',
  Jewellery: 'Đồ kim hoàn',
  Furnishing: 'Trang trí nội thất',
  Antiques: 'Đồ cổ',
  Art: 'Nghệ thuật',
  Collectible: 'Sưu tầm',
  Settlor: 'Người định cư',
  Trustee: 'Người được ủy thác',
  Beneficiary: 'Người thụ hưởng',
  Protector: 'Người bảo vệ',
  Settlors: 'Người định cư',
  Trustees: 'Người được ủy thác',
  Beneficiaries: 'Người thụ hưởng',
  Protectors: 'Người bảo vệ',
  Irrevocable: 'Không thể thu hồi',
  Revocable: 'Có thể thu hồi',
  Private: 'Riêng tư',
  Bare: 'Trần',
  Charitable: 'Từ thiện',
  Investment: 'Đầu tư',
  Fixed: 'Cố định',
  Variable: 'Biến đổi',
  Ordinary: 'Bình thường',
  Preference: 'Sở thích',
  Convertible: 'Có thể chuyển đổi',
  CPF: 'CPF',
  SRS: 'SRS',
  Superannuation: 'Hưu bổng',
  Pension: 'Lương hưu',
  Education: 'Giáo dục',
  DELETED_PASSWORDS: 'Mật khẩu đã xóa',
  SEARCH_PASSWORD: ' Tìm kiếm mật khẩu',
  DELETED_LOCATIONS: 'Vị trí đã xóa',
  SEARCH_LOCATION: 'Tìm kiếm vị trí',
  PASSWORD_INFORMATION: 'Thông tin mật khẩu',
  LOCATION_INFORMATION: 'Thông tin vị trí',
  DELETED_EVENTS: 'Sự kiện đã xóa',
  SEARCH_EVENT: 'Tìm kiếm sự kiện',
  EVENT_INFORMATION: 'Thông tin sự kiện',
  'Total and Permanent Disability': 'Thương tật toàn bộ và vĩnh viễn',
  'Investment-Linked Policy': 'Chính sách liên kết đầu tư',
  'Income/Endowment/Savings': 'Thu nhập/Từ thiện/Tiết kiệm',
  'Annuity/Retirement': 'Niên kim/Hưu trí',
  'Life – Whole': 'Đời sống - Trọn đời',
  'Personal Accident': 'Tai nạn con người',
  'Precious stone': 'Đá quý',
  'Structured deposit': 'Tiền gửi có cấu trúc',
  'Fixed deposit': 'Tiền gửi cố định',
  'Plain Text': 'Văn bản thô',
  'Word Document': 'Tài liệu văn bản',
  'Title with icon': 'Tiêu đề có biểu tượng',
  'Other medical': 'Y tế khác',
  'Critical Illness': 'Bệnh hiểm nghèo',
  'Life - Universal': 'Đời sống - Phổ cập',
  'Life - Term': 'Đời sống - Kỳ hạn',
  'Other assets': 'Các tài sản khác',
  'Property loan': 'Vay bất động sản',
  'Vehicle loan': 'Vay phương tiện',
  'Credit card': 'Thẻ tín dụng',
  'Loan from others': 'Khoản vay từ người khác',
  'Loan to others': 'Cho người khác vay',
  'Other liability': 'Nợ khác',
  'Retirement account': 'Tài khoản hưu trí',
  'Brokerage account': 'Tài khoản môi giới',
  'Equity - listed': 'Vốn chủ sở hữu - niêm yết',
  'Equity - unlisted': 'Vốn chủ sở hữu - chưa niêm yết',
  'per month': 'mỗi tháng',
  'per year': 'mỗi năm',
  '30-day free trial': 'Dùng thử 30 ngày',
  'No payment details required': 'Không yêu cầu chi tiết thanh toán'
}
