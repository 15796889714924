import React, { useEffect, useState } from 'react'
import { Button, Modal, message, Icon, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { onError } from '../../lib/sentry'
import api from '../../lib/api'
import copy from 'copy-to-clipboard'
import { randomBytes } from 'crypto'
import { AES } from 'crypto-js'

const SharedRecoverKey = props => {
  const {
    visible,
    setVisible,
    masterKey,
    userId,
    isChangedPassword,
    isResetAccount,
    isRecoverData
  } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [recoveryKey, setRecoveryKey] = useState('')

  useEffect(() => {
    if (visible) {
      const recoveryKey = randomBytes(20).toString('hex')
      setRecoveryKey(recoveryKey)
    }
  }, [visible])

  const copyToClipboard = () => {
    try {
      copy(recoveryKey)
      message.success(t('Copied!'))
    } catch (error) {
      onError(error)
    }
  }

  const handleOk = async () => {
    setLoading(true)
    try {
      await api.updateSharedRecoverKey(
        userId,
        JSON.stringify({
          sharedRecoverKey: true,
          encryptedMasterKey: AES.encrypt(masterKey, recoveryKey).toString(),
          encryptedRecoveryKey: AES.encrypt(recoveryKey, masterKey).toString(),
          recoveryKey: recoveryKey
        })
      )
      setVisible(false)
    } catch (error) {
      onError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      width={700}
      visible={visible}
      title={t('Recovery key')}
      okText={t('OK')}
      cancelText={t('CANCEL')}
      maskClosable={false}
      closable={false}
      footer={
        <Button loading={loading} type="primary" onClick={handleOk}>
          {t('OK')}
        </Button>
      }
    >
      {isChangedPassword && (
        <p>
          Your recovery key has been changed because you changed your password.
        </p>
      )}
      {isResetAccount && (
        <p>Your recovery key has been changed after you reset your account.</p>
      )}
      {isRecoverData && (
        <p>Your recovery key has been changed after recovering data.</p>
      )}
      <p>
        Please store this key securely. It can be used to recover your data when
        you forget your password.
      </p>
      <span>
        <b>{recoveryKey} </b>
        <span>
          <Tooltip
            title={t('Copy recovery key')}
            arrowPointAtCenter
            placement="topRight"
          >
            <Icon
              onClick={() => copyToClipboard()}
              type="copy"
              style={{ fontSize: 20, marginLeft: 10 }}
            />
          </Tooltip>{' '}
        </span>
      </span>
      <p style={{ marginTop: 15 }}>
        The recovery key will be sent to your email address.
      </p>
    </Modal>
  )
}
export default SharedRecoverKey
