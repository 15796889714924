import { Editor } from '@tinymce/tinymce-react'
import { Breadcrumb, Layout, message, Modal } from 'antd'
import { fromString } from 'html-to-text'
import PouchDB from 'pouchdb'
import React, { useContext, useState } from 'react'
import uuidv4 from 'uuid/v4'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { uploadEncryptedData } from '../../lib/pouchDb'
import { TEXT_FILE_TYPES } from '../../share/Constants'
import Button from '../override/Button'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'
import SaveTextFileModal from './SaveTextFileModal'
import config from '../../config'
import { s3Put } from '../../lib/awsSDK'
import { useSelector, useDispatch } from 'react-redux'
//import { StringResources } from '../../share/StringResources'
import { encryptFile } from '../../lib/crypto'
import { onError } from '../../lib/sentry'
import ResponsiveBreadCrumb from './ResponsiveBreadCrumb'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ThemeContext } from 'styled-components'
import Flex from '../override/Flex'
import { useTranslation } from 'react-i18next'
import { sanitizeValue } from '../../share/helpers'
import { ACCESS_LEVEL } from './../../share/Constants'
import { fetchOtherPendingDocuments } from '../../features/documents/otherDocumentsSlice'
import { fetchOtherDocuments } from './../../features/documents/otherDocumentsSlice'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import { getUserData } from '../../lib/cognito'
import { logDocumentActivity } from '../../share/logs'

const { Content } = Layout

export default function TextFileEditor(props) {
  const { isProfessionalDeputy, isDelegateByPD, user } = useContext(AuthContext)
  const { masterKey, userId, isReadonly } = useContext(VaultContext)
  const {
    breadcrumb,
    setBreadcrumb,
    setEnableCreateFile,
    selectedTextFile,
    setSelectedTextFile
  } = props
  const [isSaving, setIsSaving] = useState(false)
  const [editorContent, setEditorContent] = useState('')
  const [saveModalVisible, setSaveModalVisible] = useState(false)

  const { usedStorage, activeFiles, activeFolders } = useSelector(
    state => state.documents
  )
  const { accessLevel } = useSelector(state => state.settings)
  const { allowedStorage } = useSelector(state => state.customer)
  const theme = useContext(ThemeContext)
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [addS3Change] = useMutation(createS3Change)

  let formRef
  const initialContent =
    selectedTextFile.fileExtension === TEXT_FILE_TYPES.TXT.extension
      ? '<p>' +
        selectedTextFile.fileContent
          .replace(/\n{2,}/g, '</p><p>')
          .replace(/\n/g, '<br>') +
        '</p>'
      : selectedTextFile.fileContent

  const handleSave = (callback) => {
    formRef.props.form.validateFields(async (err, values) => {
      if (err) return

      try {
        const fileType = Object.values(TEXT_FILE_TYPES).find(
          k => k.extension === values.saveAsType
        )

        const blobProps = {
          type: fileType.mimeType,
          name: values.name
        }

        const htmlContent = `
              <html 
                xmlns:o='urn:schemas-microsoft-com:office:office'
                xmlns:w='urn:schemas-microsoft-com:office:word'
                xmlns='http://www.w3.org/TR/REC-html40'
              >
                <head>
                  <meta charset='utf-8'>
                  <title></title>
                </head>
                <body>${editorContent}</body>
              </html>`

        const content =
          fileType.extension === TEXT_FILE_TYPES.DOC.extension
            ? htmlContent
            : fromString(editorContent)

        const file = new Blob([content], blobProps)

        const id = selectedTextFile.id || uuidv4() // PouchDB record id, is not encrypted by crypto-pouch
        const fileId = selectedTextFile.fileId || uuidv4() // used as S3 file path, is encrypted
        const sub = selectedTextFile.sub || uuidv4() // used for matching with S3 file metadata, is encrypted

        if (!allowedStorage || file.size + usedStorage > allowedStorage) {
          Modal.warn({
            title: t('LOW_STORAGE_SPACE'),
            content: t('LOW_STORAGE_SPACE_CONTENT')
          })
          return
        }

        setIsSaving(true)

        // File upload
        encryptFile(file, masterKey, async encryptedFile => {
          try {
            await s3Put(userId, fileId, encryptedFile, { sub })

            const db =
              (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? new PouchDB(`${userId}_pendingDocuments`)
                : new PouchDB(`${userId}_documents`)
            db.crypto(masterKey)
            const doc = selectedTextFile.id
              ? await db.get(selectedTextFile.id)
              : {}

            await db.put({
              ...doc,
              _id: id,
              path: breadcrumb,
              fileName: `${sanitizeValue(values.name, {
                allowedTags: [],
                allowedAttributes: {}
              })}${fileType.extension}`,
              file: [{ ...blobProps, size: file.size }],
              uploadTime: new Date(),
              tags: doc.tags || [],
              contacts: doc.contacts || [],
              assetsLiabilities: doc.assetsLiabilities || [],
              descriptionWithMarkup: doc.descriptionWithMarkup || '',
              isContentEditable: true,
              fileId,
              location: values.location,
              sub,
              status:
                (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
                accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                  ? 'Draft'
                  : undefined
            })
            await uploadEncryptedData(
              db,
              userId,
              (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
                accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? 'pendingDocuments'
                : 'documents'
            )

            getUserData(
              user,
              async (err, data) => {
                if (err) {
                  onError(err)
                  return
                }

                const parentFolders = await activeFolders.filter(folder =>
                  breadcrumb?.includes(folder.path)
                )

                await logDocumentActivity(
                  userId,
                  data.UserAttributes,
                  user.username,
                  id,
                  {
                    id: id,
                    type: 'file',
                    name: `${sanitizeValue(values.name, {
                      allowedTags: [],
                      allowedAttributes: {}
                    })}${fileType.extension}`,
                    action: selectedTextFile?.id ? 'edited content' : 'created',
                    in: breadcrumb || ''
                  }
                )

                if (parentFolders.length) {
                  for (const folder of parentFolders) {
                    await logDocumentActivity(
                      userId,
                      data.UserAttributes,
                      user.username,
                      folder._id,
                      {
                        id: id,
                        type: 'file',
                        name: `${sanitizeValue(values.name, {
                          allowedTags: [],
                          allowedAttributes: {}
                        })}${fileType.extension}`,
                        action: selectedTextFile?.id
                          ? 'edited content'
                          : 'created',
                        in: breadcrumb || ''
                      }
                    )
                  }
                }
              },
              { bypassCache: true }
            )

            if (
              (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ) {
              dispatch(fetchOtherPendingDocuments(userId, masterKey))
            } else {
              dispatch(fetchOtherDocuments(userId, masterKey))
            }
            setIsSaving(false)
            setSaveModalVisible(false)
            setEnableCreateFile(false)
            setSelectedTextFile({})
            localStorage.setItem('NotReload', true)
            addS3Change({
              variables: {
                message: 'documents, pendingDocuments, locations',
                userId: userId
              }
            })
            message.success(t('SUCCESSFULLY_CREATED_FILE'))
          } catch (err) {
            setIsSaving(false)
            message.error(t('FAILED_TO_CREATE_FILE'))
            onError(err)
          }
        })
        callback()
      } catch (err) {
        setIsSaving(false)
        message.error(t('FAILED_TO_CREATE_FILE'))
        onError(err)
      }
    })
  }

  const navigateTo = (link, e) => {
    e?.preventDefault && e.preventDefault()
    const end = link ? breadcrumb.indexOf(link) + link.length + 1 : 0
    const newBreadcrumb = breadcrumb.substring(0, end)
    setEnableCreateFile(false)
    setBreadcrumb(newBreadcrumb)
  }

  const ActionButtons = ({ style }) => (
    <Flex style={style} justifyContent="flex-end">
      <Button
        onClick={() => {
          setSelectedTextFile({})
          setEnableCreateFile(false)
        }}
        style={{ marginRight: 15 }}
      >
        {t('CANCEL')}
      </Button>
      <Button
        type="primary"
        onClick={() => setSaveModalVisible(true)}
        disabled={!editorContent}
      >
        {t('SAVE')}
      </Button>
    </Flex>
  )

  // TODO: Might need to seperate Breadcrumb to another component for sharing purpose
  return (
    <Layout style={{ height: '100%' }}>
      <Content style={{ backgroundColor: '#fff', padding: '0 20px 20px' }}>
        <SimpleHeader
          className="file-nav file-nav--flow-row"
          title={
            <ResponsiveBreadCrumb
              path={breadcrumb}
              navigateTo={navigateTo}
              linkStyle={{
                color: '#9AA0B3',
                fontWeight: 600,
                fontSize: 18,
                lineHeight: '26px',
                marginBottom: 0,
                display: 'inline-block'
              }}
              homeLink={
                <Breadcrumb.Item
                  href=""
                  onClick={e => {
                    navigateTo('', e)
                  }}
                >
                  <H4 display="inline-block" style={{ color: '#9AA0B3' }}>
                    {t('FILES')}
                  </H4>
                </Breadcrumb.Item>
              }
              suffix={
                <Breadcrumb.Item>
                  <H4 display="inline-block">
                    {selectedTextFile.id
                      ? 'Edit text file'
                      : 'Create new text file'}
                  </H4>
                </Breadcrumb.Item>
              }
            />
          }
          extra={isSmUp && <ActionButtons />}
        />
        <Editor
          apiKey={config.tinyMce.API_KEY}
          init={{
            height: 560,
            branding: false,
            menubar: true,
            forced_root_block: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help'
            ],
            toolbar:
              'undo redo | formatselect | bold italic underline backcolor | alignleft aligncenter alignright alignjustify |bullist numlist outdent indent | removeformat |help'
          }}
          onEditorChange={setEditorContent}
          initialValue={initialContent}
        />
        <SaveTextFileModal
          wrappedComponentRef={fr => (formRef = fr)}
          visible={saveModalVisible}
          handleOk={handleSave}
          handleCancel={() => setSaveModalVisible(false)}
          isSaving={isSaving}
          fileName={selectedTextFile.fileName}
          fileExtension={selectedTextFile.fileExtension}
          location={selectedTextFile.location}
          activeFiles={activeFiles}
          destination={breadcrumb}
        />
        {!isSmUp && <ActionButtons style={{ marginTop: 20 }} />}
      </Content>
    </Layout>
  )
}
