import React, { Fragment } from 'react'
import { Divider } from 'antd'
import ByRecoveryKey from './ByRecoveryKey'
import ByAssistant from './ByAssistant'

const Recovery = () => {
  return (
    <Fragment>
      <ByRecoveryKey />
      <Divider />
      <ByAssistant />
    </Fragment>
  )
}

export default Recovery
