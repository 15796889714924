import React from 'react'
import { GAevent } from '../../lib/ga'
import { Experiment, Variant } from 'react-optimize'
import config from '../../config'
import logo from '../../assets/bantex-logo-white.png'
// import LanguageSelector from './../pages/LanguageSelector'
import { Trans } from 'react-i18next'
// import { Divider } from 'antd'

const UnauthContent = ({ children, header }) => (
  <>
    <div className="unauth-content">
      <div className="unauth-content-header">
        {/* <LanguageSelector /> */}
        {/* <div style={{ width: '35%' }}></div> */}
        {/* <a
          style={{ width: '20%' }}
          className="logo"
          href="https://www.bantexapp.com"
          target="blank"
        >
          <img style={{ width: '150px' }} src={logo} alt="bantex logo" />
        </a> */}
        <div>{header}</div>
      </div>
      {children}
      <div className="unauth-content-footer">
        <div>
          &copy; {new Date().getFullYear()}{' '}
          <b>Bino Digital Solutions Pte Ltd</b>
        </div>
        <div className="unauth-content-footer-links">
          <span>
            <a
              href="https://www.bantexapp.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked Website'
                })
              }}
            >
              <Trans i18nKey="WEBSITE"></Trans>
            </a>
          </span>
          <span>&bull;</span>
          <span>
            <a
              href="https://www.bantexapp.com/about-us"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked About us'
                })
              }}
            >
              <Trans i18nKey="ABOUT_US"></Trans>
            </a>
          </span>
          <span>&bull;</span>
          <span>
            <a
              href="mailto:support@bantexapp.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked Help'
                })
              }}
            >
              <Trans i18nKey="HELP"></Trans>
            </a>
          </span>
          <span>&bull;</span>
          <span>
            <a
              href="https://www.bantexapp.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked Privacy'
                })
              }}
            >
              <Trans i18nKey="PRIVACY"></Trans>
            </a>
          </span>
          <span>&bull;</span>
          <span>
            <a
              href="https://www.bantexapp.com/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked Terms of Service',
                  label: 'Footer link'
                })
              }}
            >
              <Trans i18nKey="TERMS_OF_SERVICE"></Trans>
            </a>
          </span>
        </div>
      </div>
    </div>
    <div className="unauth-bg">
      <div className="unauth-bg-img"></div>
    </div>
    {/* <div className="banner">
      <Trans i18nKey="30-day free trial"></Trans>
      <Divider type="vertical" />
      <Trans i18nKey="No payment details required"></Trans>
    </div> */}
    <a className="logo" href="https://www.bantexapp.com" target="blank">
      <img width={200} src={logo} alt="bantex logo" />
    </a>
  </>
)
export default function UnauthLayout({ children, ...rest }) {
  const { header } = rest

  return (
    <Experiment id={config.optimize.LAYOUT_EXP_ID}>
      <Variant id="0">
        <div className="unauth-wrapper unauth-wrapper--row">
          <UnauthContent children={children} header={header} />
        </div>
      </Variant>
      <Variant id="1">
        <div className="unauth-wrapper">
          <UnauthContent children={children} header={header} />
        </div>
      </Variant>
    </Experiment>
  )
}
