import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Modal, Button, Empty, Dropdown, Menu, Table, Spin, Alert } from 'antd'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import CustomIcon from '../override/Icon'
import TableHeader from '../common/TableHeader'
import AuthContext from '../../contexts/AuthContext'
import {
  distributeRecoveryShareKey,
  resendAssistantRequest,
  revokeAssistant
} from '../../features/deputies/assistantsSlice'
import VaultContext from '../../contexts/VaultContext'
import api from '../../lib/api'

const Assistant = () => {
  const theme = useContext(ThemeContext)
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const [acceptedAssistants, setAcceptedAssistants] = useState([])
  const [pendingAssistants, setPendingAssistants] = useState([])

  const { assistants, isLoading, error, distributeError } = useSelector(
    state => state.assistants
  )

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (assistants?.length) {
      const accepted = assistants.filter(d => d.publicKey)
      const pending = assistants.filter(d => !d.publicKey)
      setAcceptedAssistants(accepted)
      setPendingAssistants(pending)
    } else {
      setAcceptedAssistants([])
      setPendingAssistants([])
    }
  }, [assistants])

  const handleDistributeShareKeys = useCallback(async () => {
    const assistantEmails = assistants.map(d => d.email)
    const res = await api.getUsersByEmails(assistantEmails.join(','))
    const assistantInfo = res?.data?.map(d => {
      return {
        userId: d.id,
        email: d.email,
        publicKey: d.publicKey
      }
    })

    dispatch(
      distributeRecoveryShareKey(user.username, masterKey, assistantInfo)
    )
  }, [dispatch, masterKey, assistants, user.username])

  useEffect(() => {
    // automatically distribute share keys if an accepted assistant exists without shareKey
    if (
      assistants?.length &&
      assistants?.find(d => d.publicKey && !d.shareKey)
    ) {
      handleDistributeShareKeys()
    }
  }, [assistants, handleDistributeShareKeys])

  const handleRevokeAssistant = async assistant =>
    dispatch(revokeAssistant(user.username, assistant))

  const handleResend = async email =>
    dispatch(resendAssistantRequest(user.username, email))

  const acceptedAssistantsColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('Assistant')
    },
    {
      key: 'status',
      title: t('STATUS'),
      render: (text, record) =>
        record.shareKey ? t('ACCEPTED') : t('BACKUP_KEY_DISTRIBUTED')
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  Modal.confirm({
                    content: t('Are you sure to revoke this assistant?'),
                    okText: t('YES'),
                    cancelText: t('NO'),
                    onOk: () => handleRevokeAssistant(record)
                  })
                }}
              >
                <Button style={{ color: theme.red }} type="link">
                  <CustomIcon type="trash" style={{ fontSize: 12 }} />{' '}
                  {t('REVOKE')}
                </Button>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <CustomIcon type="more" />
        </Dropdown>
      )
    }
  ]

  const pendingAssistantsColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('DEPUTY')
    },
    {
      key: 'status',
      title: t('STATUS'),
      render: () => t('Pending acceptance of assistant')
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <Button
                  type="link"
                  icon="reload"
                  onClick={() => {
                    handleResend(record.email)
                  }}
                >
                  {t('RESEND')}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  type="link"
                  icon="minus-circle"
                  style={{ color: theme.red }}
                  onClick={() => handleRevokeAssistant(record)}
                >
                  {t('REVOKE')}
                </Button>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <CustomIcon type="more" />
        </Dropdown>
      )
    }
  ]

  return (
    <Spin spinning={isLoading}>
      {error && (
        <Alert
          message={error}
          type="error"
          closable
          style={{ marginBottom: 16 }}
        />
      )}
      {distributeError && (
        <Alert
          message={
            <>
              <div>{distributeError}</div>
              <Button
                type="link"
                onClick={handleDistributeShareKeys}
                style={{ padding: 0, height: 22 }}
              >
                {t('CLICK_HERE_TO_RETRY')}
              </Button>
            </>
          }
          type="warning"
          closable
          style={{ marginBottom: 16 }}
        />
      )}
      <TableHeader
        title={t('My current assistants')}
        count={acceptedAssistants.length}
      />
      <Table
        rowKey="id"
        dataSource={acceptedAssistants}
        scroll={{ x: true }}
        columns={acceptedAssistantsColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('No assistants')}
            />
          )
        }}
        showHeader={!!acceptedAssistants.length}
      />

      <TableHeader
        title={t('My pending assistants')}
        count={pendingAssistants.length}
      />
      <Table
        rowKey="email"
        dataSource={pendingAssistants}
        scroll={{ x: true }}
        columns={pendingAssistantsColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('No pending assistants')}
            />
          )
        }}
        showHeader={!!pendingAssistants.length}
      />
    </Spin>
  )
}

export default Assistant
