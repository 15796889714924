import React, { useContext, useState } from 'react'
import { Popconfirm, Icon } from 'antd'
import CustomIcon from '../override/Icon'
import { H4 } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import PasswordLinksModal from './PasswordLinksModal'
import SimpleList from '../override/SimpleList'
import { useTranslation } from 'react-i18next'

export default function PasswordLinks({
  linkedPasswords,
  handleUnlinkPassword,
  handleLinkPasswords
}) {
  const theme = useContext(ThemeContext)
  const [passwordLinkModalVisibled, setPasswordLinkModalVisibled] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <SimpleList
        header={<H4>{t('PASSWORD_LINKS')}</H4>}
        extra={
          <CustomIcon
            type="add"
            style={{ fontSize: 30, color: theme.primary, cursor: 'pointer' }}
            onClick={() => setPasswordLinkModalVisibled(true)}
          />
        }
        data={linkedPasswords}
        itemKey="_id"
        icon={() => (
          <Icon type="lock" style={{ marginRight: 10, fontSize: 24 }} />
        )}
        mainInfo={password => password.title}
        subInfo={password => password.username}
        action={password => (
          <Popconfirm
            title={t('UNLINK_PASSWORD_CONFIRM')}
            onConfirm={() => handleUnlinkPassword(password._id)}
            okText={t('YES')}
            cancelText={t('NO')}
            arrowPointAtCenter
            placement="bottomRight"
          >
            <CustomIcon
              type="trash"
              style={{ color: theme.red, fontSize: 12 }}
            />
          </Popconfirm>
        )}
      />
      <PasswordLinksModal
        visible={passwordLinkModalVisibled}
        setVisible={setPasswordLinkModalVisibled}
        handleLinkPasswords={handleLinkPasswords}
        linkedPasswords={linkedPasswords}
      />
    </>
  )
}
