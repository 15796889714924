import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { sum } from 'lodash'
import api from '../../lib/api'
import { onError } from '../../lib/sentry'

const initialState = {
  documents: [],
  activeDocuments: [],
  deletedDocuments: [],
  pendingDocuments: [],
  rejectedDocuments: [],
  pendingFolders:[],
  pendingFiles:[],
  activeFiles: [],
  activeFolders: [],
  usedStorage: 0,
  breadcrumb: '',
  showStarredDocsOnly: false,
  isLoading: false,
  error: null,
  pendingUnlockFiles: [],
  deletedStorage: 0
}

const documents = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    getDocumentsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getDocumentsSuccess(state, action) {
      const { documents } = action.payload
      state.documents = documents
      state.activeDocuments = documents.filter(
        doc => !doc.deleted && !doc.status
      )
      state.deletedDocuments = documents.filter(doc => doc.deleted)

      state.activeFiles = state.activeDocuments.filter(doc => doc.fileName)
      state.activeFolders = state.activeDocuments.filter(doc => !doc.fileName)
      state.usedStorage = sum(
        documents.filter(doc => doc.fileName).map(doc => doc.file[0].size)
      )
      state.deletedStorage = sum(
        state.deletedDocuments
          .filter(doc => doc.fileName)
          .map(doc => doc.file[0].size)
      )

      state.isLoading = false
      state.error = null
    },
    getDocumentsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getPendingDocumentsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getPendingDocumentsSuccess(state, action) {
      const { documents } = action.payload
      state.documents = documents
      state.pendingDocuments = documents.filter(doc => doc.status === 'Draft')
      state.rejectedDocuments = documents.filter(
        doc => doc.status === 'Rejected'
      )
      state.pendingFiles = state.pendingDocuments.filter(doc => doc.fileName)
      state.pendingFolders = state.pendingDocuments.filter(doc => !doc.fileName)
      state.isLoading = false
      state.error = null
    },
    getPendingDocumentsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    setBreadcrumb(state, action) {
      state.breadcrumb = action.payload
    },
    setShowStarredDocsOnly(state, action) {
      state.showStarredDocsOnly = action.payload
    },
    getPendingUnlockFilesSuccess(state, action) {
      const { pendingUnlockFiles } = action.payload
      state.pendingUnlockFiles = pendingUnlockFiles || []
    }
  }
})

export const {
  getDocumentsStart,
  getDocumentsSuccess,
  getDocumentsFailure,
  setBreadcrumb,
  setShowStarredDocsOnly,
  getPendingUnlockFilesSuccess,
  getPendingDocumentsStart,
  getPendingDocumentsSuccess,
  getPendingDocumentsFailure
} = documents.actions

export default documents.reducer

export const fetchDocuments = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getDocumentsStart())
    const documents = await getRecords(userId, 'documents', masterKey)
    dispatch(getDocumentsSuccess({ documents }))
  } catch (err) {
    dispatch(getDocumentsFailure(err.toString()))
  }
}

export const fetchPendingUnlockFiles = userId => async dispatch => {
  try {
    const fileRes = await api.getPendingUnlockFiles(userId)
    if (fileRes.data.message) throw Error(fileRes.data.message)

    dispatch(
      getPendingUnlockFilesSuccess({
        pendingUnlockFiles: fileRes.data.pendingUnlockFiles
      })
    )
  } catch (err) {
    onError(err)
  }
}

export const fetchPendingDocuments = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getPendingDocumentsStart())
    const documents = await getRecords(userId, 'pendingDocuments', masterKey)
    dispatch(getPendingDocumentsSuccess({ documents }))
  } catch (err) {
    dispatch(getPendingDocumentsFailure(err.toString()))
  }
}
