import React from 'react'
import expandingBlueFolder from '../../assets/ic/folder/expanding_blue.png'
import expandingBlackFolder from '../../assets/ic/folder/expanding_black.png'
import expandingRedFolder from '../../assets/ic/folder/expanding_red.png'
import expandingGreenFolder from '../../assets/ic/folder/expanding_green.png'
import expandingYellowFolder from '../../assets/ic/folder/expanding_yellow.png'
import disPlayBlueFolder from '../../assets/ic/folder/display_blue.png'
import disPlayBlackFolder from '../../assets/ic/folder/display_black.png'
import disPlayRedFolder from '../../assets/ic/folder/display_red.png'
import disPlayGreenFolder from '../../assets/ic/folder/display_green.png'
import disPlayYellowFolder from '../../assets/ic/folder/display_yellow.png'
import lafBlueFolder from '../../assets/ic/folder/laf_blue.png'
import lafBlackFolder from '../../assets/ic/folder/laf_black.png'
import lafRedFolder from '../../assets/ic/folder/laf_red.png'
import lafGreenFolder from '../../assets/ic/folder/laf_green.png'
import lafYellowFolder from '../../assets/ic/folder/laf_yellow.png'
import ringBlueFolder from '../../assets/ic/folder/ring_blue.png'
import ringBlackFolder from '../../assets/ic/folder/ring_black.png'
import ringRedFolder from '../../assets/ic/folder/ring_red.png'
import ringGreenFolder from '../../assets/ic/folder/ring_green.png'
import ringYellowFolder from '../../assets/ic/folder/ring_yellow.png'
import suspensionBlueFolder from '../../assets/ic/folder/suspension_blue.png'
import suspensionBlackFolder from '../../assets/ic/folder/suspension_black.png'
import suspensionRedFolder from '../../assets/ic/folder/suspension_red.png'
import suspensionGreenFolder from '../../assets/ic/folder/suspension_green.png'
import suspensionYellowFolder from '../../assets/ic/folder/suspension_yellow.png'
import { FOLDER_TYPES } from '../../share/Constants'
import { Icon } from 'antd'

export const folderIcons = [
  {
    label: 'Generic',
    value: 'folderGeneric',
    type: FOLDER_TYPES.FOLDER,
    icon: <Icon type="folder" />,
    customIcon: size => (
      <Icon
        type="folder"
        style={{ fontSize: size, color: '#0061D4' }}
        theme={'filled'}
      />
    )
  },
  {
    label: 'Black',
    value: 'folderBlack',
    type: FOLDER_TYPES.EXPANDING,
    icon: (
      <img
        className="folder-icon"
        src={expandingBlackFolder}
        alt="Black icon"
      />
    ),
    customIcon: () => (
      <img
        src={expandingBlackFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Black icon"
      />
    )
  },
  {
    label: 'Red',
    value: 'folderRed',
    type: FOLDER_TYPES.EXPANDING,
    icon: (
      <img className="folder-icon" src={expandingRedFolder} alt="Red icon" />
    ),
    customIcon: () => (
      <img
        src={expandingRedFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Red icon"
      />
    )
  },
  {
    label: 'Blue',
    value: 'folderBlue',
    type: FOLDER_TYPES.EXPANDING,
    icon: (
      <img className="folder-icon" src={expandingBlueFolder} alt="Blue icon" />
    ),
    customIcon: () => (
      <img
        src={expandingBlueFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Blue icon"
      />
    )
  },
  {
    label: 'Green',
    value: 'folderGreen',
    type: FOLDER_TYPES.EXPANDING,
    icon: (
      <img
        className="folder-icon"
        src={expandingGreenFolder}
        alt="Green icon"
      />
    ),
    customIcon: () => (
      <img
        src={expandingBlueFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Green icon"
      />
    )
  },
  {
    label: 'Yellow',
    value: 'folderYellow',
    type: FOLDER_TYPES.EXPANDING,
    icon: (
      <img
        className="folder-icon yellow-folder"
        src={expandingYellowFolder}
        alt="Yellow icon"
      />
    ),
    customIcon: () => (
      <img
        className="yellow-folder"
        src={expandingYellowFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Green icon"
      />
    )
  },
  {
    label: 'Black',
    value: 'folderBlack',
    type: FOLDER_TYPES.DISPLAY_BOOK,
    icon: (
      <img className="folder-icon" src={disPlayBlackFolder} alt="Black icon" />
    ),
    customIcon: () => (
      <img
        src={disPlayBlackFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Black icon"
      />
    )
  },
  {
    label: 'Red',
    value: 'folderRed',
    type: FOLDER_TYPES.DISPLAY_BOOK,
    icon: <img className="folder-icon" src={disPlayRedFolder} alt="Red icon" />,
    customIcon: () => (
      <img
        src={disPlayRedFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Red icon"
      />
    )
  },
  {
    label: 'Blue',
    value: 'folderBlue',
    type: FOLDER_TYPES.DISPLAY_BOOK,
    icon: (
      <img className="folder-icon" src={disPlayBlueFolder} alt="Blue icon" />
    ),
    customIcon: () => (
      <img
        src={disPlayBlueFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Blue icon"
      />
    )
  },
  {
    label: 'Green',
    value: 'folderGreen',
    type: FOLDER_TYPES.DISPLAY_BOOK,
    icon: (
      <img className="folder-icon" src={disPlayGreenFolder} alt="Green icon" />
    ),
    customIcon: () => (
      <img
        src={disPlayGreenFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Green icon"
      />
    )
  },
  {
    label: 'Yellow',
    value: 'folderYellow',
    type: FOLDER_TYPES.DISPLAY_BOOK,
    icon: (
      <img
        className="folder-icon yellow-folder"
        src={disPlayYellowFolder}
        alt="Yellow icon"
      />
    ),
    customIcon: () => (
      <img
        className="yellow-folder"
        src={disPlayYellowFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Green icon"
      />
    )
  },
  {
    label: 'Black',
    value: 'folderBlack',
    type: FOLDER_TYPES.LAF,
    icon: <img className="folder-icon" src={lafBlackFolder} alt="Black icon" />,
    customIcon: () => (
      <img
        src={lafBlackFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Black icon"
      />
    )
  },
  {
    label: 'Red',
    value: 'folderRed',
    type: FOLDER_TYPES.LAF,
    icon: <img className="folder-icon" src={lafRedFolder} alt="Red icon" />,
    customIcon: () => (
      <img
        src={lafRedFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Red icon"
      />
    )
  },
  {
    label: 'Blue',
    value: 'folderBlue',
    type: FOLDER_TYPES.LAF,
    icon: <img className="folder-icon" src={lafBlueFolder} alt="Blue icon" />,
    customIcon: () => (
      <img
        src={lafBlueFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Blue icon"
      />
    )
  },
  {
    label: 'Green',
    value: 'folderGreen',
    type: FOLDER_TYPES.LAF,
    icon: <img className="folder-icon" src={lafGreenFolder} alt="Green icon" />,
    customIcon: () => (
      <img
        src={lafGreenFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Green icon"
      />
    )
  },
  {
    label: 'Yellow',
    value: 'folderYellow',
    type: FOLDER_TYPES.LAF,
    icon: (
      <img
        className="folder-icon yellow-folder"
        src={lafYellowFolder}
        alt="Yellow icon"
      />
    ),
    customIcon: () => (
      <img
        className="yellow-folder"
        src={lafYellowFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Green icon"
      />
    )
  },
  {
    label: 'Black',
    value: 'folderBlack',
    type: FOLDER_TYPES.RING_BINDER,
    icon: (
      <img className="folder-icon" src={ringBlackFolder} alt="Black icon" />
    ),
    customIcon: () => (
      <img
        src={ringBlackFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Black icon"
      />
    )
  },
  {
    label: 'Red',
    value: 'folderRed',
    type: FOLDER_TYPES.RING_BINDER,
    icon: <img className="folder-icon" src={ringRedFolder} alt="Red icon" />,
    customIcon: () => (
      <img
        src={ringRedFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Red icon"
      />
    )
  },
  {
    label: 'Blue',
    value: 'folderBlue',
    type: FOLDER_TYPES.RING_BINDER,
    icon: <img className="folder-icon" src={ringBlueFolder} alt="Blue icon" />,
    customIcon: () => (
      <img
        src={ringBlueFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Blue icon"
      />
    )
  },
  {
    label: 'Green',
    value: 'folderGreen',
    type: FOLDER_TYPES.RING_BINDER,
    icon: (
      <img className="folder-icon" src={ringGreenFolder} alt="Green icon" />
    ),
    customIcon: () => (
      <img
        src={ringGreenFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Green icon"
      />
    )
  },
  {
    label: 'Yellow',
    value: 'folderYellow',
    type: FOLDER_TYPES.RING_BINDER,
    icon: (
      <img
        className="folder-icon yellow-folder"
        src={ringYellowFolder}
        alt="Yellow icon"
      />
    ),
    customIcon: () => (
      <img
        className="yellow-folder"
        src={ringYellowFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Green icon"
      />
    )
  },
  {
    label: 'Black',
    value: 'folderBalck',
    type: FOLDER_TYPES.SUSPENSION,
    icon: (
      <img
        className="folder-icon"
        src={suspensionBlackFolder}
        alt="Purple icon"
      />
    ),
    customIcon: () => (
      <img
        src={suspensionBlackFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Purple icon"
      />
    )
  },
  {
    label: 'Red',
    value: 'folderRed',
    type: FOLDER_TYPES.SUSPENSION,
    icon: (
      <img className="folder-icon" src={suspensionRedFolder} alt="Red icon" />
    ),
    customIcon: () => (
      <img
        src={suspensionRedFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Purple icon"
      />
    )
  },
  {
    label: 'Blue',
    value: 'folderBlue',
    type: FOLDER_TYPES.SUSPENSION,
    icon: (
      <img className="folder-icon" src={suspensionBlueFolder} alt="Blue icon" />
    ),
    customIcon: () => (
      <img
        src={suspensionBlueFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Blue icon"
      />
    )
  },
  {
    label: 'Green',
    value: 'folderGreen',
    type: FOLDER_TYPES.SUSPENSION,
    icon: (
      <img
        className="folder-icon"
        src={suspensionGreenFolder}
        alt="Green icon"
      />
    ),
    customIcon: () => (
      <img
        src={suspensionGreenFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Green icon"
      />
    )
  },
  {
    label: 'Yellow',
    value: 'folderYellow',
    type: FOLDER_TYPES.SUSPENSION,
    icon: (
      <img
        className="folder-icon yellow-folder"
        src={suspensionYellowFolder}
        alt="Yellow icon"
      />
    ),
    customIcon: () => (
      <img
        className="yellow-folder"
        src={suspensionYellowFolder}
        style={{ width: '40px', marginRight: 5 }}
        alt="Yellow icon"
      />
    )
  }
]

export const folderWithIcon = (
  icon = 'folderGeneric',
  type = FOLDER_TYPES.FOLDER,
  size = 40
) => {
  const iconMap = folderIcons?.find(
    item => item.value === icon && item.type === type
  )
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {iconMap?.customIcon(size) ||
        folderIcons
          ?.find(
            item =>
              item.value === 'folderGeneric' &&
              item.type === FOLDER_TYPES.FOLDER
          )
          ?.customIcon(size)}
    </div>
  )
}
