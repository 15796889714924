import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Modal, List } from 'antd'
import { useTranslation } from 'react-i18next'
import { renderDate } from '../../share/helpers'
import { onError } from '../../lib/sentry'
import api from '../../lib/api'
import { useSelector } from 'react-redux'

const EventsModal = props => {
  const { reminderEvents, visible, setVisible, userId } = props
  const { activeEvents } = useSelector(state => state.events)

  const { t } = useTranslation()
  const [reminders, setReminders] = useState([])
  const [isDontShowAgain, setIsDontShowAgain] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setReminders(
      activeEvents.filter(
        ae =>
          reminderEvents.map(e => e.id)?.includes(ae._id) &&
          reminderEvents.map(e => e.date)?.includes(ae.date)
      )
    )
  }, [reminderEvents, activeEvents, visible])

  const handleOk = async () => {
    setLoading(true)
    try {
      if (isDontShowAgain) {
        const updatedIds = reminders.map(event => event._id)
        const res = await api.getUser(userId)
        const newEvents = res.data?.events.map(event => {
          return updatedIds.includes(event.id)
            ? {
                ...event,
                dontShowAgain: isDontShowAgain
              }
            : event
        })

        await api.updateEvents(userId, {
          events: newEvents
        })
      }
      localStorage.setItem('dontShowEventsModal', true)
      setVisible(false)
    } catch (error) {
      onError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      width={700}
      visible={visible}
      title={t('UPCOMING_EVENTS')}
      okText={t('OK')}
      cancelText={t('CANCEL')}
      maskClosable={false}
      closable={false}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Checkbox onChange={e => setIsDontShowAgain(e.target.checked)}>
            {t('DONT_SHOW_AGAIN')}
          </Checkbox>
          <Button loading={loading} type="primary" onClick={handleOk}>
            {t('OK')}
          </Button>
        </div>
      }
    >
      {!!reminders?.length && (
        <List
          dataSource={reminders}
          renderItem={item => (
            <List.Item>
              {renderDate(item.date)} - {item.description}
            </List.Item>
          )}
        />
      )}
    </Modal>
  )
}
export default EventsModal
