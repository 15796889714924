import React from 'react'
import { Row, Col, Divider } from 'antd'
import { H4, Span, B } from '../override/Typography'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT } from '../../share/Constants'
import moment from 'moment'

function Field({ title, value }) {
  return (
    <Row style={{ fontSize: 12, padding: '3px 0' }} gutter={8}>
      <Col span={8}>
        <Span>{title}</Span>
      </Col>
      <Col span={16} style={{ textAlign: 'left' }}>
        <B>{value}</B>
      </Col>
    </Row>
  )
}

export default function LocationInformation({ record, extra }) {
  const { t } = useTranslation()
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10
        }}
      >
        <H4>{t('EVENT_INFORMATION')}</H4>
        {extra}
      </div>
      <Divider />
      <Field title={t('DESCRIPTION')} value={record.description} />
      <Field
        title={t('DATE')}
        value={moment(record.date).format(DATE_FORMAT)}
      />
    </>
  )
}
