const fileSignatures = [

  // Video
  { signature: [0x00, 0x00, 0x00, 0x18], extension: 'mp4' },
  { signature: [0x00, 0x00, 0x00, 0x20], extension: 'mp4' },
  { signature: [0x00, 0x00, 0x00, 0x14], extension: 'mp4' },
  { signature: [0x1A, 0x45, 0xDF, 0xA3], extension: 'webm' },
  { signature: [0x4F, 0x67, 0x67, 0x53], extension: 'ogg' },
  { signature: [0x52, 0x49, 0x46, 0x46], extension: 'avi' },
  { signature: [0x1A, 0x45, 0xDF, 0xA3], extension: 'mkv' },
  { signature: [0xFF, 0xFB], extension: 'mpeg' },

  // Audio
    { signature: [0x52, 0x49, 0x46, 0x46], extension: 'wav' },
    { signature: [0x66, 0x4C, 0x61, 0x43], extension: 'flac' },
    { signature: [0xFF, 0xF1], extension: 'aac' },
    { signature: [0x49, 0x44, 0x33], extension: 'mp3' },
    { signature: [0x0B, 0x77], extension: 'ac3' },

    // Document
    { signature: [0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1], extension: 'doc' },
    { signature: [0x50, 0x4B, 0x03, 0x04], extension: 'docx' },
    { signature: [0x09, 0x08, 0x10, 0x00, 0x00, 0x06, 0x01, 0x00], extension: 'xls' },
    { signature: [0x50, 0x4B, 0x03, 0x04], extension: 'xlsx' },
    { signature: [0x23, 0x5B, 0x47, 0x42, 0x40, 0x03, 0x00], extension: 'ppt' },
    { signature: [0x50, 0x4B, 0x03, 0x04], extension: 'pptx' },
    { signature: [0x7B, 0x5C, 0x72, 0x74, 0x66, 0x31, 0x2E, 0x32], extension: 'rtf' },
    { signature: [0x25, 0x50, 0x44, 0x46, 0x2D, 0x31], extension: 'pdf' },
    
    // Archive
    { signature: [0x50, 0x4B, 0x03, 0x04], extension: 'zip' },
    { signature: [0x52, 0x61, 0x72, 0x21], extension: 'rar' },
    { signature: [0x37, 0x7A, 0xBC, 0xEF], extension: '7z' },
    { signature: [0xC7, 0x71], extension: 'cpio' },
    { signature: [0x60, 0xEA], extension: 'arj' },
    { signature: [0x1F, 0x8B, 0x8], extension: 'gz' },
    { signature: [0x42, 0x5A, 0x68], extension: 'bz2' },
    { signature: [0x1F, 0xA0], extension: 'tar' },
    
    // Image
    { signature: [0xFF, 0xD8, 0xFF], extension: 'jpeg' },
    { signature: [0xFF, 0xD8, 0xFF, 0xE0], ext: 'jpg' },
    { signature: [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A], extension: 'png' },
    { signature: [0x47, 0x49, 0x46, 0x38, 0x39, 0x61], extension: 'gif' },
    { signature: [0x42, 0x4D], extension: 'bmp' },
    { signature: [0x52, 0x49, 0x46, 0x46, 0x57, 0x45, 0x42, 0x50], extension: 'webp' },
    { signature: [0xFF, 0xD8, 0xFF, 0xE1], extension: 'heic' },
    { signature: [0x49, 0x49, 0xBC], extension: 'jxr' },
    { signature: [0x49, 0x49, 0x2A, 0x00], extension: 'tiff' },
    
    
    // Other
    { signature: [0x7B], extension: 'json' },
    { signature: [0x78, 0x01], extension: 'dmg' },
    { signature: [0x4D, 0x5A], extension: 'exe' },
    { signature: [0x7B, 0x22, 0x74, 0x65, 0x73, 0x74, 0x22, 0x3A], extension: 'js' },
    { signature: [0x3C, 0x3F, 0x78, 0x6D, 0x6C, 0x20, 0x76, 0x65], extension: 'xml' },
    { signature: [0x3C, 0x21, 0x44, 0x4F, 0x43, 0x54, 0x59, 0x50, 0x45], extension: 'html' },
  ];

export const getFileExt = (uint8Array) => {
  // console.log('uint8Array',uint8Array);
    for (const fileSignature of fileSignatures) {
      const signatureBytes = fileSignature.signature;
      const signatureMatch = signatureBytes.every((byte, index) => byte === uint8Array[index]);
    
      if (signatureMatch) {
        return fileSignature.extension;
      }
    }
}