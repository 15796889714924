const formItemLayout = {
  labelCol: {
    xs: 24,
    sm: 8
  },
  wrapperCol: {
    xs: 24,
    sm: 16
  }
}

const filesGridLayout = {
  gutter: 20,
  xs: 1,
  sm: 2,
  md: 3,
  xxl: 4
}

const filesGridLayoutInModal = {
  gutter: 20,
  xs: 1,
  sm: 2,
  md: 3
}

export { formItemLayout, filesGridLayout, filesGridLayoutInModal }
