import { createSlice } from '@reduxjs/toolkit'
import api from '../../lib/api'
import { onError } from '../../lib/sentry'

const initialState = {
  user: {},
  isLoading: false,
  error: null
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserStart(state) {
      state.isLoading = true
      state.error = null
    },
    getUserSuccess(state, action) {
      state.user = action.payload
      state.isLoading = false
      state.error = null
    },
    getUserFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const { getUserStart, getUserSuccess, getUserFailure } = user.actions

export default user.reducer

export const fetchUser = userId => async dispatch => {
  try {
    dispatch(getUserStart())
    const res = await api.getUser(userId)
    dispatch(getUserSuccess(res.data || {}))
  } catch (err) {
    onError(err)
    dispatch(getUserFailure(err.toString()))
  }
}

// export const fetchReminders = async (userId, masterKey) => {
//   try {
//     const records = await getRecords(userId, 'reminders', masterKey)
//     return records
//   } catch (err) {
//     onError(err)
//   }
// }
