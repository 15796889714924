import AssetModel, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class EquityUnlisted extends AssetModel {
  /**
   * Construct a new EquityUnlisted instance
   * @param {object} params - EquityUnlisted creation options:
   * @param {string} [params.company]
   * @param {string} [params.quantity]
   * @param {string} [params.classOfShares]
   * @param {number} [params.shareCertificateNumber]
   * @param {number} [params.percentageOfShares]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    valuationDate,
    currency,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    contacts,
    references,
    company,
    quantity,
    classOfShares,
    shareCertificateNumber,
    percentageOfShares,
    links,
    documents,
    includeValueInNetWorth,
    price,
    status,
    events,
    passwords
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      currency,
      valuationDate,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords
    })

    this.subType = ASSET_TYPES.EQUITY_UNLISTED
    this.company = company
    this.quantity = quantity
    this.classOfShares = classOfShares
    this.shareCertificateNumber = shareCertificateNumber
    this.percentageOfShares = percentageOfShares
    this.price = price
  }
}

export default EquityUnlisted

export const equityUnlistedColumns = () => [
  ...assetColumns(),
  {
    key: 'disposalDate',
    title: i18next.t('DISPOSAL_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'price',
    title: i18next.t('PRICE'),
    type: IMPORT_COL_TYPES.CURRENCY
  },
  {
    key: 'quantity',
    title: i18next.t('NUMBER_OF_SHARES'),
    type: IMPORT_COL_TYPES.NUMBER
  },
  {
    key: 'classOfShares',
    title: i18next.t('CLASS_OF_SHARES'),
    type: IMPORT_COL_TYPES.LIST,
    isCustomList: true
  },
  {
    key: 'shareCertificateNumber',
    title: i18next.t('SHARE_CERTIFICATE_NUMBER')
  },
  {
    key: 'percentageOfShares',
    title: i18next.t('PERCENTAGE_OF_SHARES'),
    type: IMPORT_COL_TYPES.PERCENTAGE
  },
  ...referenceColumns()
]
