import React from 'react'
import styled from 'styled-components'

const SimpleHeader = ({ className, title, extra, size }) => (
  <div className={`${className} ${size || ''}`}>
    <span className="simple-header-title">{title}</span>
    <span className="simple-header-extra">{extra}</span>
  </div>
)

const StyledSimpleHeader = styled(SimpleHeader)`
  min-height: 80px;
  background: ${props => props.theme.white};
  box-shadow: ${props => props.theme.underlineShadow};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  &.small {
    height: 46px;
    box-shadow: none;
    margin-bottom: 10px;
  }
`

export default StyledSimpleHeader
