import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { sum } from 'lodash'

const initialState = {
  documents: [],
  activeDocuments: [],
  deletedDocuments: [],
  pendingDocuments: [],
  rejectedDocuments: [],
  pendingFolders: [],
  pendingFiles: [],
  activeFiles: [],
  activeFolders: [],
  isLoading: false,
  usedStorage: 0,
  error: null
}

const otherDocuments = createSlice({
  name: 'otherDocuments',
  initialState,
  reducers: {
    getOtherDocumentsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getOtherDocumentsSuccess(state, action) {
      const { documents } = action.payload
      state.documents = documents
      state.activeDocuments = documents.filter(doc => !doc.deleted)
      state.deletedDocuments = documents.filter(doc => doc.deleted)
      state.usedStorage = sum(
        documents.filter(doc => doc.fileName).map(doc => doc.file[0].size)
      )
      state.activeFiles = state.activeDocuments.filter(doc => doc.fileName)
      state.activeFolders = state.activeDocuments.filter(doc => !doc.fileName)

      state.isLoading = false
      state.error = null
    },
    getOtherDocumentsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getOtherPendingDocumentsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getOtherPendingDocumentsSuccess(state, action) {
      const { documents } = action.payload
      state.documents = documents
      state.pendingDocuments = documents.filter(doc => doc.status === 'Draft')
      state.rejectedDocuments = documents.filter(
        doc => doc.status === 'Rejected'
      )
      state.pendingFiles = state.pendingDocuments.filter(doc => doc.fileName)
      state.pendingFolders = state.pendingDocuments.filter(doc => !doc.fileName)

      state.isLoading = false
      state.error = null
    },
    getOtherPendingDocumentsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const {
  getOtherDocumentsStart,
  getOtherDocumentsSuccess,
  getOtherDocumentsFailure,
  getOtherPendingDocumentsStart,
  getOtherPendingDocumentsSuccess,
  getOtherPendingDocumentsFailure
} = otherDocuments.actions

export default otherDocuments.reducer

export const fetchOtherDocuments = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getOtherDocumentsStart())
    const documents = await getRecords(userId, 'documents', masterKey)
    dispatch(getOtherDocumentsSuccess({ documents }))
  } catch (err) {
    dispatch(getOtherDocumentsFailure(err.toString()))
  }
}
export const fetchOtherPendingDocuments = (
  userId,
  masterKey
) => async dispatch => {
  try {
    dispatch(getOtherPendingDocumentsStart())
    const documents = await getRecords(userId, 'pendingDocuments', masterKey)
    dispatch(getOtherPendingDocumentsSuccess({ documents }))
  } catch (err) {
    dispatch(getOtherPendingDocumentsFailure(err.toString()))
  }
}
