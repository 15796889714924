import React, { useState, useEffect, useContext } from 'react'
import {
  Modal,
  Form,
  Input,
  message,
  Radio,
  DatePicker,
  Select,
  Divider,
  Icon,
  Tooltip
} from 'antd'
//import { StringResources } from '../../share/StringResources'
import uuidv4 from 'uuid/v4'
import PouchDB from 'pouchdb'
import { uploadEncryptedData } from '../../lib/pouchDb'
import {
  DATE_FORMAT,
  FIELDS,
  EMAIL_DEFAULT_OPTIONS,
  ADDRESS_DEFAULT_OPTIONS,
  PHONE_DEFAULT_OPTIONS,
  ENTITY_TYPES
} from '../../share/Constants'
import ContactModel from '../../model/ContactModel'
import AddMultipleFields from '../common/AddMultipleFields'
import {
  filterEmptyEls,
  getOptions,
  getReferencesTooltip,
  sanitizeValue
} from '../../share/helpers'
import moment from 'moment'
import VaultContext from '../../contexts/VaultContext'
import AddItemModal from '../modals/AddItemModal'
import FormItem from '../override/FormItem'
import FileTags from '../file/FileTags'
import { CONTACT_TYPES } from '../../share/Constants'
import { countries } from 'country-data'
import { onError } from '../../lib/sentry'
import TextInput from '../common/TextInput'
import { useTranslation } from 'react-i18next'
import { removeHtmlTags } from './../../share/helpers'
import { PHONE_NUMBER_REGEX } from '../../share/Constants'
import AuthContext from '../../contexts/AuthContext'
import {
  fetchOtherPendingContacts,
  fetchOtherContacts
} from './../../features/contacts/otherContactsSlice'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../lib/api'
import { ACCESS_LEVEL } from './../../share/Constants'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import { isEmpty } from 'lodash'

const ContactModal = props => {
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const [selectedType, setSelectedType] = useState('')
  const [activeDynamicField, setActiveDynamicField] = useState({ name: '' })
  const [dynamicFieldsValue, setDynamicFieldsValue] = useState({
    emails: [],
    addresses: [],
    references: [],
    phoneNumbers: []
  })
  const [tags, setTags] = useState([])
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [dynamicFieldsOptions, setDynamicFieldsOptions] = useState({
    emails: [],
    addresses: [],
    phoneNumbers: []
  })
  const [isSaving, setIsSaving] = useState(false)
  const { accessLevel } = useSelector(state => state.settings)

  const {
    visible,
    setVisible,
    form,
    selectedRecord,
    onAddComplete,
    contactType,
    email
  } = props
  const { userId, masterKey, isReadonly } = useContext(VaultContext)
  const { TextArea } = Input
  const { getFieldDecorator, setFieldsValue } = form
  const db = new PouchDB(`${userId}_contacts`)
  const pendingDb = new PouchDB(`${userId}_pendingContacts`)
  const {
    _id,
    // type,
    givenName,
    surName,
    displayName,
    dateOfBirth,
    description,
    legalName,
    url,
    companyRegistrationNumber
  } = selectedRecord

  let addItemFormRef

  const [addS3Change] = useMutation(createS3Change, {
    variables: {
      message: 'contacts, pendingContacts, events',
      userId: userId
    }
  })

  useEffect(() => {
    setSelectedType(contactType || CONTACT_TYPES.INDIVIDUAL)
  }, [contactType])

  useEffect(() => {
    const { emails, addresses, references, phoneNumbers } = selectedRecord
    if (email) {
      setDynamicFieldsValue({
        emails: [{ name: 'Personal', value: email }],
        addresses: [],
        references: [],
        phoneNumbers: []
      })
    }

    if (!isEmpty(selectedRecord)) {
      setDynamicFieldsValue({
        emails: emails || [],
        addresses: addresses || [],
        references: references || [],
        phoneNumbers: phoneNumbers || []
      })
    }
    
    setDynamicFieldsOptions({
      emails: getOptions(EMAIL_DEFAULT_OPTIONS, emails),
      addresses: getOptions(ADDRESS_DEFAULT_OPTIONS, addresses),
      phoneNumbers: getOptions(PHONE_DEFAULT_OPTIONS, phoneNumbers)
    })
  }, [selectedRecord, email])

  const handleOk = e => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      removeHtmlTags(values)

      setIsSaving(true)
      const contact = new ContactModel({
        ...values,
        emails: filterEmptyEls(values.emails),
        addresses: filterEmptyEls(values.addresses),
        references: filterEmptyEls(values.references),
        phoneNumbers: filterEmptyEls(values.phoneNumbers),
        status:
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? 'Draft'
            : ''
      })

      const record = {
        ...selectedRecord,
        ...contact,
        tags: removeHtmlTags(tags),
        _id: _id || uuidv4()
      }

      try {
        ;(isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
        accessLevel === ACCESS_LEVEL.NEED_APPROVAL
          ? pendingDb.crypto(masterKey)
          : db.crypto(masterKey)
        const putResult =
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? await pendingDb.put(record)
            : await db.put(record)
        // const putResult = await db.put(record)
        const historyDb = new PouchDB(`${userId}_contactsHistory`)
        const versionId = `${putResult.id}_${putResult.rev}`
        const versionRecord = {
          ...record,
          _id: versionId,
          time: new Date()
        }
        delete versionRecord._rev

        historyDb.crypto(masterKey)
        await historyDb.put(versionRecord)

        await Promise.all([
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? uploadEncryptedData(pendingDb, userId, 'pendingContacts')
            : uploadEncryptedData(db, userId, 'contacts'),
          uploadEncryptedData(historyDb, userId, 'contactsHistory')
        ])
        setIsSaving(false)
        if (isProfessionalDeputy || (isDelegateByPD && isReadonly)) {
          if (accessLevel === ACCESS_LEVEL.NEED_APPROVAL) {
            await api.sendAddRecordNotification(
              JSON.stringify({
                userId,
                recordType: 'contact'
              })
            )
            dispatch(fetchOtherPendingContacts(userId, masterKey))
          } else {
            dispatch(fetchOtherContacts(userId, masterKey))
          }
        }
        message.success(
          selectedRecord._id
            ? t('EDIT_CONTACT_SUCCESS')
            : t('ADD_CONTACT_SUCCESS')
        )

        onAddComplete && onAddComplete(record._id)
        localStorage.setItem('NotReload', true)
        addS3Change()
      } catch (err) {
        setIsSaving(false)
        onError(err)
      } finally {
        resetModal()
      }
    })
  }

  const handleAddNewOption = () => {
    const { name, rowId } = activeDynamicField
    addItemFormRef.props.form.validateFields((err, values) => {
      if (err) return

      removeHtmlTags(values)

      setDynamicFieldsOptions({
        ...dynamicFieldsOptions,
        [name]: [...dynamicFieldsOptions[name], values.itemName]
      })
      setActiveDynamicField({ name: '' })
      addItemFormRef.props.form.resetFields()
      form.setFieldsValue({
        [`${name}[${rowId}][name]`]: values.itemName
      })
    })
  }

  const resetModal = () => {
    form.resetFields()
    setSelectedType(contactType || CONTACT_TYPES.INDIVIDUAL)
    setVisible(false)
    setTags([])
  }

  const fieldsWithDefaultNames = FIELDS.map(field =>
    field.key === 'name'
      ? {
          ...field,
          node: (name, rowId) => (
            <Select
              key={`${field.key}`}
              style={{ minWidth: 180 }}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: 0 }} />
                  <div
                    style={{ padding: '5px 12px', cursor: 'pointer' }}
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setActiveDynamicField({ name, rowId })}
                  >
                    <Icon type="plus" /> {t('CUSTOM')}
                  </div>
                </div>
              )}
            >
              {dynamicFieldsOptions[name].map(option => (
                <Select.Option key={option} value={option}>
                  {t(option)}
                </Select.Option>
              ))}
            </Select>
          ),
          default: name => dynamicFieldsOptions[name][0]
        }
      : field
  )

  return (
    <Modal
      title={t('ADD_CONTACT')}
      visible={visible}
      okText={t('SAVE')}
      cancelText={t('CANCEL')}
      onOk={handleOk}
      onCancel={resetModal}
      okButtonProps={{ loading: isSaving }}
      maskClosable={false}
    >
      <Form>
        <FormItem label={t('TYPE')}>
          {getFieldDecorator('type', {
            initialValue: selectedType
          })(
            <Radio.Group
              disabled={!!contactType}
              onChange={e => setSelectedType(e.target.value)}
            >
              <Radio value={CONTACT_TYPES.INDIVIDUAL}>
                {t(CONTACT_TYPES.INDIVIDUAL)}
              </Radio>
              <Radio value={CONTACT_TYPES.ORGANISATION}>
                {t(CONTACT_TYPES.ORGANISATION)}
              </Radio>
            </Radio.Group>
          )}
        </FormItem>
        {selectedType === CONTACT_TYPES.INDIVIDUAL ? (
          <>
            <FormItem label={t('GIVEN_NAME')}>
              {getFieldDecorator('givenName', {
                rules: [
                  {
                    required: true,
                    message: t('INPUT_GIVEN_NAME_MSG')
                  }
                ],
                initialValue: givenName
              })(
                <TextInput name="givenName" setFieldsValue={setFieldsValue} />
              )}
            </FormItem>
            <FormItem label={t('SURNAME')}>
              {getFieldDecorator('surName', {
                initialValue: surName
              })(<TextInput name="surName" setFieldsValue={setFieldsValue} />)}
            </FormItem>

            <FormItem label={t('DATE_OF_BIRTH')}>
              {getFieldDecorator('dateOfBirth', {
                initialValue: dateOfBirth ? moment(dateOfBirth) : null
              })(
                <DatePicker
                  placeholder={t('SELECT_DATE')}
                  format={DATE_FORMAT}
                />
              )}
            </FormItem>

            <FormItem label={t('ID_PASSPORT_NUMBER')}>
              {getFieldDecorator('idPassportNumber', {
                initialValue: selectedRecord.idPassportNumber
              })(
                <TextInput
                  name="idPassportNumber"
                  setFieldsValue={setFieldsValue}
                />
              )}
            </FormItem>
          </>
        ) : (
          <>
            <FormItem label={t('DISPLAY_NAME')}>
              {getFieldDecorator('displayName', {
                rules: [
                  {
                    required: true,
                    message: t('INPUT_GIVEN_NAME_MSG')
                  }
                ],
                initialValue: displayName
              })(
                <TextInput name="displayName" setFieldsValue={setFieldsValue} />
              )}
            </FormItem>
            <FormItem label={t('LEGAL_NAME')}>
              {getFieldDecorator('legalName', {
                rules: [
                  {
                    required: true,
                    message: t('INPUT_GIVEN_NAME_MSG')
                  }
                ],
                initialValue: legalName
              })(
                <TextInput name="legalName" setFieldsValue={setFieldsValue} />
              )}
            </FormItem>
            <FormItem label={t('COMPANY_REGISTRATION_NUMBER')}>
              {getFieldDecorator('companyRegistrationNumber', {
                initialValue: companyRegistrationNumber
              })(
                <TextInput
                  name="companyRegistrationNumber"
                  setFieldsValue={setFieldsValue}
                />
              )}
            </FormItem>

            <FormItem label={t('COUNTRY_TERRITORY_OF_INCORPORATION')}>
              {getFieldDecorator(
                'countryTerritoryOfIncorporation',
                {}
              )(
                <Select allowClear showSearch>
                  {countries.all
                    .filter(c => c.status === 'assigned')
                    .map(c => (
                      <Select.Option key={c.name} value={c.name}>
                        {c.name}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </FormItem>
          </>
        )}

        <FormItem label={t('DESCRIPTION')}>
          {getFieldDecorator('description', {
            initialValue: description
          })(
            <TextArea
              onBlur={e =>
                setFieldsValue({
                  description: sanitizeValue(e.target.value)
                })
              }
              rows={4}
              maxLength={2000}
            />
          )}
        </FormItem>
        <FormItem label={t('URL')}>
          {getFieldDecorator('url', {
            initialValue: url
          })(<TextInput name="url" setFieldsValue={setFieldsValue} />)}
        </FormItem>

        {/* Dynamic Fields */}
        <FormItem label={t('EMAILS')}>
          <AddMultipleFields
            name="emails"
            fields={fieldsWithDefaultNames}
            customPlaceholders={{ value: 'email@example.com' }}
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            value={dynamicFieldsValue.emails}
            customRules={[
              {
                fieldKey: 'value',
                rules: [
                  {
                    type: 'email',
                    message: t('INVALID_EMAIL_MSG')
                  }
                ]
              }
            ]}
          />
        </FormItem>
        <FormItem label={t('ADDRESSES')}>
          <AddMultipleFields
            name="addresses"
            fields={fieldsWithDefaultNames}
            customPlaceholders={{ value: t('ADDRESS') }}
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            value={dynamicFieldsValue.addresses}
            isTextArea={true}
          />
        </FormItem>
        <FormItem label={t('PHONE_NUMBERS')}>
          <AddMultipleFields
            name="phoneNumbers"
            title="phone numbers"
            fields={fieldsWithDefaultNames}
            customPlaceholders={{ value: t('PHONE_NUMBER') }}
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            value={dynamicFieldsValue.phoneNumbers}
            customRules={[
              {
                fieldKey: 'value',
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (value && !value.match(PHONE_NUMBER_REGEX)) {
                        callback(t('INVALID_MOBLIE_NUMBER'))
                      } else {
                        callback()
                      }
                    }
                  }
                ]
              }
            ]}
          />
        </FormItem>
        <FormItem
          label={
            <span>
              {t('REFERENCES')}&nbsp;
              <Tooltip
                title={getReferencesTooltip(ENTITY_TYPES.CONTACT)}
                overlayClassName="references-tooltip"
              >
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          <AddMultipleFields
            name="references"
            fields={FIELDS}
            customPlaceholders={{
              name: t('REFERENCE_LABEL'),
              value: t('REFERENCE_INFO')
            }}
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            value={dynamicFieldsValue.references}
          />
        </FormItem>
        <FormItem label={t('TAGS')}>
          <FileTags tags={tags} setTags={setTags} />
        </FormItem>
      </Form>

      <AddItemModal
        wrappedComponentRef={fr => (addItemFormRef = fr)}
        visible={!!activeDynamicField.name}
        handleOk={handleAddNewOption}
        handleCancel={() => setActiveDynamicField({ name: '' })}
        item="Label"
        existingItems={dynamicFieldsOptions[activeDynamicField.name]}
      />
    </Modal>
  )
}

const WrappedContactForm = Form.create({ name: 'ContactModal' })(ContactModal)
export default WrappedContactForm
