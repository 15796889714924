import React from 'react'
import { Form, Modal, Select } from 'antd'
import FormItem from '../override/FormItem'
import { TEXT_FILE_TYPES } from '../../share/Constants'
//import { StringResources } from '../../share/StringResources'
import { validateFilename } from '../../share/formHelpers'
import { withTranslation } from 'react-i18next'
import TextInput from './../common/TextInput'
import LocationSelect from './../common/LocationSelect'
import ShowImage from '../common/ShowImage'

class SaveTextFileModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    return {
      locationRecord: {}
    }
  }

  componentWillReceiveProps = nextProps => {
    const locationId = !!nextProps.form.getFieldValue('location')
      ? nextProps.form.getFieldValue('location')
      : nextProps.location
    const activeLocations = nextProps.activeLocations
    if (locationId && activeLocations?.length) {
      const locationRecord = activeLocations.find(
        item => item._id === locationId
      )
      if (
        locationRecord &&
        locationRecord._id !== this.state.locationRecord._id
      ) {
        this.setState({ locationRecord: locationRecord })
      }
    }
  }

  render() {
    const {
      form,
      visible,
      handleOk,
      handleCancel,
      isSaving,
      activeFiles,
      destination,
      fileName,
      location,
      fileExtension,
      t
    } = this.props
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form
    const { locationRecord } = this.state

    return (
      <Modal
        title={t('SAVE_TEXT_FILE')}
        visible={visible}
        onOk={() => {
          handleOk(() => {
            this.setState({ locationRecord: {} })
          })
        }}
        okText={t('OK')}
        cancelText={t('CANCEL')}
        onCancel={() => {
          handleCancel()
          this.setState({ locationRecord: {} })
        }}
        okButtonProps={{ loading: isSaving }}
        maskClosable={false}
      >
        <Form hideRequiredMark={true}>
          <FormItem label={t('FILE_NAME')}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: t('FILE_NAME_IS_REQUIRED')
                },
                {
                  validator: (rule, value, callback) => {
                    // when edit don't need to validate this
                    if (fileName && fileExtension) {
                      callback()
                    } else {
                      const extension = getFieldValue('saveAsType')

                      const filename = `${value}${extension}`
                      validateFilename(
                        rule,
                        filename,
                        callback,
                        activeFiles,
                        destination
                      )
                    }
                  }
                }
              ],
              initialValue: fileName
            })(
              <TextInput
                name="name"
                setFieldsValue={setFieldsValue}
                placeholder={t('INPUT_FILE_NAME_MSG')}
              />
            )}
          </FormItem>
          <LocationSelect
            label={t('Location')}
            placeholder={t('Select location')}
            required={false}
            getFieldDecorator={getFieldDecorator}
            fieldName="location"
            initialValue={location}
            onAddComplete={locationId =>
              setFieldsValue({
                location: getFieldValue('location') || locationId
              })
            }
            setLocation={value => this.setState({ locationRecord: value })}
          />

          {locationRecord && (
            <>
              {locationRecord?.image?.find(image => image.level === 1) && (
                <>
                  <div style={{ marginBottom: 5 }}>{locationRecord.level1}</div>
                  <ShowImage
                    recordId={locationRecord._id}
                    level={1}
                    width="100%"
                  />
                  <div style={{ marginBottom: 10 }}></div>
                </>
              )}
              {locationRecord?.image?.find(image => image.level === 2) && (
                <>
                  <div style={{ marginBottom: 5 }}>{locationRecord.level2}</div>
                  <ShowImage
                    recordId={locationRecord._id}
                    level={2}
                    width="100%"
                  />
                  <div style={{ marginBottom: 10 }}></div>
                </>
              )}
              {locationRecord?.image?.find(image => image.level === 3) && (
                <>
                  <div style={{ marginBottom: 5 }}>{locationRecord.level3}</div>
                  <ShowImage
                    recordId={locationRecord._id}
                    level={3}
                    width="100%"
                  />
                </>
              )}
            </>
          )}

          <FormItem label={t('SAVE_AS_TYPE')}>
            {getFieldDecorator('saveAsType', {
              initialValue: fileExtension || TEXT_FILE_TYPES.DOC.extension
            })(
              <Select>
                {Object.values(TEXT_FILE_TYPES).map(value => (
                  <Select.Option key={value.extension} value={value.extension}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const WrappedSaveTextFileModal = Form.create({ name: 'saveTextFileModal' })(
  SaveTextFileModal
)
export default withTranslation()(WrappedSaveTextFileModal)
