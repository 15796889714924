import React, { useState, useEffect } from 'react'
import { Modal, Radio } from 'antd'

import { useTranslation } from 'react-i18next'

import { EDIT_DELETE_EVENT_TYPE, RECURRING } from './../../share/Constants'

const EditDeleteEventTypeModal = props => {
  const {
    visible,
    setVisible,
    handleOk,
    eventDetails,
    isEditMode,
    type,
    setType
  } = props

  const { t } = useTranslation()

  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setType(
      eventDetails?.recurringValue &&
        eventDetails?.recurringValue !== RECURRING.NONE
        ? EDIT_DELETE_EVENT_TYPE.THIS_EVENT
        : ''
    )
  }, [setType, eventDetails])

  const handleSave = async () => {
    setIsSaving(true)
    await handleOk(eventDetails, type)
    setVisible(false)
    setIsSaving(false)
  }

  const handleCancel = () => {
    setVisible(false)
    setType(EDIT_DELETE_EVENT_TYPE.THIS_EVENT)
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  }

  return (
    <>
      <Modal
        width={550}
        visible={visible}
        title={t(
          isEditMode ? t('EDIT_RECURRING_EVENT') : t('DELETE_RECURRING_EVENT')
        )}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={handleSave}
        onCancel={handleCancel}
        maskClosable={false}
        okButtonProps={{ loading: isSaving }}
      >
        <div style={{ marginBottom: 10 }}>
          <Radio.Group
            name="type"
            value={type || EDIT_DELETE_EVENT_TYPE.THIS_EVENT}
            onChange={e => setType(e.target.value)}
          >
            <Radio style={radioStyle} value={EDIT_DELETE_EVENT_TYPE.THIS_EVENT}>
              {t('THIS_EVENT')}
            </Radio>

            <Radio
              style={radioStyle}
              value={EDIT_DELETE_EVENT_TYPE.THIS_AND_FOLLOWING_EVENTS}
            >
              {t('THIS_AND_FOLLOWING_EVENTS')}
            </Radio>
            <Radio style={radioStyle} value={EDIT_DELETE_EVENT_TYPE.ALL_EVENTS}>
              {t('ALL_EVENTS')}
            </Radio>
          </Radio.Group>
        </div>
      </Modal>
    </>
  )
}

export default EditDeleteEventTypeModal
