import React, { useContext, useEffect, useState } from 'react'
import {
  Layout,
  Table,
  message,
  Popconfirm,
  Icon,
  Tooltip,
  Modal,
  Divider
} from 'antd'
import { H4 } from '../override/Typography'
import { useTranslation } from 'react-i18next'
import SimpleHeader from '../override/SimpleHeader'
import { useSelector, useDispatch } from 'react-redux'
import { renderDate } from '../../share/helpers'
import { SharingDetailMapping, UserWithIcon } from './UserHelper'
import { onError } from '../../lib/sentry'
import api from '../../lib/api'
import VaultContext from './../../contexts/VaultContext'
import { fetchDeputies } from './../../features/deputies/deputiesSlice'
import LogModal from './LogModal'
import moment from 'moment'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ThemeContext } from 'styled-components'

const Users = () => {
  const { userId, isReadonly } = useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { deputies, loading } = useSelector(state => state.deputies)
  const { activeContacts } = useSelector(state => state.contacts)
  const { activeDocuments } = useSelector(state => state.documents)
  const [logModalVisible, setLogModalVisible] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(null)
  const [userRecordId, setUserRecordId] = useState('')
  const [documentActivities, setDocumentActivities] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    const getDocumentActivities = () => {
      setIsLoading(true)
      let params = {
        ...(startDate
          ? { startTime: startDate.startOf('day').toISOString() }
          : endDate
          ? { startTime: new Date(0).toISOString() }
          : {})
      }

      if (endDate) {
        const now = new Date()
        const endTime = endDate.endOf('day').isAfter(now)
          ? now
          : endDate.endOf('day')

        params = { ...params, endTime: endTime.toISOString() }
      }

      api
        .getAccessAttempts(userId, params)
        .then(res => {
          const data = res.data.length
            ? res.data
                .filter(
                  r =>
                    (!startDate ||
                      startDate
                        .startOf('day')
                        .isSameOrBefore(r.metadata.txTime)) &&
                    r.data.DocumentId
                )
                .map(record => ({
                  ...record.data,
                  Detail: JSON.parse(record.data.Detail),
                  Id: record.metadata.txId,
                  Time: record.metadata.txTime
                }))
                .reverse()
            : []

          if (userRecordId) {
            const documentActivities = data.filter(
              item =>
                item.AccessedByUserId === userRecordId &&
                item.DocumentId === item.Detail.id
            )
            setDocumentActivities(documentActivities)
          }
        })
        .catch(err => {
          setDocumentActivities([])
          onError(err)
        })
        .finally(() => setIsLoading(false))
    }

    if (userId && !isReadonly) {
      getDocumentActivities()
    }
  }, [userId, startDate, endDate, isReadonly, userRecordId])

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) =>
        text ? <UserWithIcon id={text} index={index} /> : <i>Pending</i>
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email'
    },
    // {
    //   title: t('Permissions'),
    //   dataIndex: 'accessLevel',
    //   key: 'accessLevel',
    //   render: text => (text ? text : 'Read only')
    // },
    {
      title: t('Date Added'),
      dataIndex: 'sharedDate',
      key: 'sharedDate',
      render: renderDate
    },
    {
      title: t('Added To'),
      dataIndex: 'addedTo',
      key: 'addedTo',
      render: text => 'Bantex eFiling'
    },
    {
      key: 'actions',
      width: 40,
      dataIndex: 'actions',
      render: (text, record) => (
        <div
          style={{ textAlign: 'end', minWidth: 60 }}
          onClick={e => e.stopPropagation()}
        >
          <Tooltip
            title="View activities"
            arrowPointAtCenter
            placement="topRight"
          >
            <Icon
              type="clock-circle"
              onClick={e => {
                e.stopPropagation()
                setLogModalVisible(true)
                setUserRecordId(record.id)
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to revoke?"
            onConfirm={() => revoke(record.email)}
            okText="Yes"
            cancelText="No"
            arrowPointAtCenter
            placement="bottomRight"
          >
            <Tooltip
              title="Revoke sharing"
              arrowPointAtCenter
              placement="topRight"
            >
              <Icon style={{ color: 'red' }} type="delete" />
            </Tooltip>
          </Popconfirm>
        </div>
      )
    }
  ]

  const revoke = async (email, recordId?) => {
    try {
      const linkedItems = [...activeContacts, ...activeDocuments]?.filter(
        i => i.documents?.includes(recordId) || i.contacts?.includes(recordId)
      )

      const data = {
        email,
        recordId
      }

      if (linkedItems?.length) {
        Modal.confirm({
          title: t('Revoke linked item'),
          content: `${linkedItems.length} item(s) will be unshared. Are you want to revoke?`,
          onCancel: () => {},
          onOk: async () => {
            await Promise.all(
              linkedItems.map(
                async item =>
                  await api.revokeSharing(
                    userId,
                    JSON.stringify({
                      email,
                      recordId: item._id
                    })
                  )
              )
            )
            await api.revokeSharing(userId, JSON.stringify(data))
            dispatch(fetchDeputies(userId))
            message.success('Successfully revoke sharing!')
          }
        })
      } else {
        await api.revokeSharing(userId, JSON.stringify(data))
        dispatch(fetchDeputies(userId))
        message.success('Successfully revoke sharing!')
      }
    } catch (error) {
      onError(error)
    }
  }

  return (
    <>
      <Layout
        style={{
          height: '100%',
          padding: '0 20px 20px',
          backgroundColor: '#fff'
        }}
      >
        <SimpleHeader
          title={<H4 display="inline-block">{t('Users management')}</H4>}
        />
        <Table
          columns={columns}
          dataSource={deputies}
          loading={loading}
          expandedRowRender={item => (
            <SharingDetailMapping
              records={item.records}
              email={item.email}
              revoke={revoke}
            />
          )}
          rowKey="email"
          scroll={{ x: !isMdUp }}
        />
      </Layout>
      <LogModal
        visible={logModalVisible}
        setVisible={setLogModalVisible}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        documentActivities={documentActivities}
        isLoading={isLoading}
      />
    </>
  )
}

export default Users
