import AssetModel, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class EquityListed extends AssetModel {
  /**
   * Construct a new EquityListed instance
   * @param {object} params - EquityListed creation options:
   * @param {string} [params.exchange]
   * @param {number} [params.quantity]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    valuationDate,
    currency,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    contacts,
    references,
    exchange,
    quantity,
    links,
    documents,
    includeValueInNetWorth,
    price,
    status,
    events,
    passwords
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      currency,
      valuationDate,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords
    })

    this.subType = ASSET_TYPES.EQUITY_LISTED
    this.exchange = exchange
    this.quantity = quantity
    this.price = price
  }
}

export default EquityListed

export const equityListedColumns = () => [
  ...assetColumns(),
  {
    key: 'disposalDate',
    title: i18next.t('DISPOSAL_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'exchange',
    title: i18next.t('EXCHANGE'),
    type: IMPORT_COL_TYPES.LIST
  },
  {
    key: 'price',
    title: i18next.t('PRICE'),
    type: IMPORT_COL_TYPES.CURRENCY
  },
  {
    key: 'quantity',
    title: i18next.t('NUMBER_OF_SHARES'),
    type: IMPORT_COL_TYPES.NUMBER
  },
  ...referenceColumns()
]
