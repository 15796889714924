import React, { useState, useEffect } from 'react'
import { Result, Icon, Spin } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { getUserPool } from '../../lib/cognito'
import Button from '../override/Button'
import { useTranslation, Trans } from 'react-i18next'

const ConfirmSignUp = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [errMsg, setErrMsg] = useState('')
  const query = new URLSearchParams(useLocation().search)
  const { i18n, t } = useTranslation()
  const lang = query.get('lang')
  const email = query.get('email')
  const code = query.get('code')

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [lang, i18n])

  useEffect(() => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: getUserPool()
    })

    cognitoUser.confirmRegistration(code, false, (err, result) => {
      if (err) {
        setErrMsg('ACCOUNT_ACTIVATION_FAILED')
        return
      }
      setIsLoading(false)
    })
  }, [code, email])

  return (
    <div className="submitted-result">
      {isLoading ? (
        <>
          {errMsg ? (
            <Result
              status="error"
              title={t('ACCOUNT_ACTIVATION_FAILED')}
              subTitle={t(`${errMsg}`)}
            />
          ) : (
            <Result icon={<Spin size="large" tip={t('ACTIVATING')} />} />
          )}
        </>
      ) : (
        <Result
          icon={
            <Icon
              type="check-circle"
              theme="filled"
              style={{ color: '#0061d4' }}
            />
          }
          subTitle={
            <span>
              <Trans i18nKey="ACCOUNT_ACTIVATION_SUCCESSED"></Trans>
            </span>
          }
          extra={
            <Button size="large" type="primary">
              <Link to="/login">{t('SIGN_IN')}</Link>
            </Button>
          }
        />
      )}
    </div>
  )
}

export default ConfirmSignUp
