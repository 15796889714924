import { createSlice } from '@reduxjs/toolkit'
import api from '../../lib/api'

const initialState = {
  customer: {},
  subscription: {},
  allowedStorage: 2 * 1024 * 1024 * 1024,
  isLoading: false,
  error: null,
  schedule: null
}

const otherCustomer = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getOtherCustomerStart(state) {
      state.isLoading = true
      state.error = null
    },
    getOtherCustomerSuccess(state, action) {
      const { customer, schedule } = action.payload
      state.customer = customer || {}
      state.subscription = customer.subscriptions?.data[0] || {}
      state.schedule = schedule
      const storageFromPlan =
        state.subscription?.items?.data[0].plan.metadata?.storage
      if (storageFromPlan) {
        state.allowedStorage = storageFromPlan * 1024 * 1024 * 1024
      }

      state.isLoading = false
      state.error = null
    },
    getOtherCustomerFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const { getOtherCustomerStart, getOtherCustomerSuccess, getOtherCustomerFailure } =
otherCustomer.actions

export default otherCustomer.reducer

export const fetchOtherCustomer = userId => async dispatch => {
  try {
    dispatch(getOtherCustomerStart())
    const res = await api.getCustomer(userId)
    dispatch(getOtherCustomerSuccess(res.data.message ? {} : res.data))
  } catch (err) {
    dispatch(getOtherCustomerFailure(err.toString()))
  }
}
