import React from 'react'
import { Row, Col, Divider, Tag } from 'antd'
import { H4, P, Span, B } from '../override/Typography'
import { DATE_TIME_FORMAT } from '../../share/Constants'
import moment from 'moment'
import { formatBytes } from '../../share/helpers'
//import { StringResources } from '../../share/StringResources'
import { useTranslation } from 'react-i18next'

function Field({ title, value }) {
  return (
    <Row style={{ fontSize: 12, padding: '3px 0' }} gutter={8}>
      <Col span={8}>
        <Span>{title}</Span>
      </Col>
      <Col span={16} style={{ textAlign: 'left' }}>
        <B>{value}</B>
      </Col>
    </Row>
  )
}

export default function FileInformation({ record, extra }) {
  const { t } = useTranslation()
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10
        }}
      >
        <H4>{t('FILE_INFORMATION')}</H4>
        {extra}
      </div>
      <P>{record.description}</P>
      <Divider />
      <Field title={t('SIZE')} value={formatBytes(record.size)} />
      <Field
        title={t('CREATED')}
        value={moment(record.uploadTime).format(DATE_TIME_FORMAT)}
      />
      <Field
        title={t('TAGS')}
        value={record.tags?.map(tag => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      />
      <Field title={t('ORIGINAL_PATH')} value={record.path} />
    </>
  )
}
