import PouchDB from 'pouchdb'
import { uniq } from 'lodash'
import { uploadEncryptedData, getRecords } from '../../lib/pouchDb'
import i18next from 'i18next'
import { renderLinkedItemsPlainText } from '../../share/helpers'

export const updatePasswordsOnDb = async (
  userId,
  masterKey,
  record,
  newRecord,
  fieldName
) => {
  const db = new PouchDB(`${userId}_${fieldName}`)
  db.crypto(masterKey)

  const linkedList = record[fieldName]?.length
    ? await getRecords(userId, fieldName, masterKey, {
        keys: record[fieldName]
      })
    : []

  const removedList = linkedList
    .map(item => item._id)
    .filter(itemId => !newRecord[fieldName]?.includes(itemId))

  const addedList =
    newRecord[fieldName]?.filter(
      itemId => !linkedList.map(item => item._id).includes(itemId)
    ) || []

  const updatedList = [...addedList, ...removedList]

  if (updatedList?.length) {
    const docs = await db.allDocs({
      keys: updatedList,
      include_docs: true
    })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row

        if (addedList.includes(doc._id)) {
          const newPasswords = uniq([...(doc.passwords || []), newRecord._id])

          return { ...doc, passwords: newPasswords }
        } else if (removedList.includes(doc._id)) {
          const newPasswords = doc.passwords
            ? doc.passwords.filter(passwordId => passwordId !== newRecord._id)
            : []
          return { ...doc, passwords: newPasswords }
        } else {
          return { ...doc }
        }
      })

    await db.bulkDocs(updatedDocs)
    await uploadEncryptedData(db, userId, fieldName)
  }
}

export const removePasswordFromDb = async (
  userId,
  masterKey,
  record,
  fieldName
) => {
  const db = new PouchDB(`${userId}_${fieldName}`)
  db.crypto(masterKey)
  const linkedList = record[fieldName]?.length
    ? await getRecords(userId, fieldName, masterKey, {
        keys: record[fieldName]
      })
    : []
  const updatedList = linkedList.map(item => {
    return {
      ...item,
      passwords: item.passwords.filter(passwordId => passwordId !== record._id)
    }
  })

  await db.bulkDocs(updatedList)
  await uploadEncryptedData(db, userId, fieldName)
}

export const deletePassword = async (userId, record, masterKey) => {
  const db = new PouchDB(`${userId}_passwords`)
  const deletedPassword = {
    ...record,
    documents: [],
    assetsLiabilities: [],
    events: [],
    deleted: true
  }
  try {
    db.crypto(masterKey)
    await db.put(deletedPassword)
    await uploadEncryptedData(db, userId, 'passwords')
  } catch (error) {
    throw error
  }
}

export const passwordColumns = (documents, events) => {
  return [
    {
      key: 'title',
      dataIndex: 'title',
      exportWidth: 40,
      title: i18next.t('TITLE'),
      style: { alignment: { wrapText: true } },
      render: title => title
    },
    {
      key: 'url',
      dataIndex: 'url',
      exportWidth: 50,
      title: i18next.t('URL'),
      style: { alignment: { wrapText: true } },
      render: url => url
    },
    {
      key: 'username',
      exportWidth: 40,
      dataIndex: 'username',
      title: i18next.t('USERNAME'),
      style: { alignment: { wrapText: true } },
      render: username => username
    },
    {
      key: 'password',
      dataIndex: 'password',
      exportWidth: 40,
      title: i18next.t('PASSWORD'),
      style: { alignment: { wrapText: true } },
      render: password => password
    },
    {
      key: 'note',
      dataIndex: 'note',
      exportWidth: 40,
      title: i18next.t('NOTE'),
      style: { alignment: { wrapText: true } },
      render: note => note
    },
    {
      key: 'documents',
      dataIndex: 'documents',
      exportWidth: 40,
      title: i18next.t('DOCUMENTS'),
      style: { alignment: { wrapText: true } },
      render: items => {
        return renderLinkedItemsPlainText(items, documents, 'fileName')
      }
    },
    {
      key: 'events',
      dataIndex: 'events',
      exportWidth: 40,
      title: i18next.t('EVENTS'),
      style: { alignment: { wrapText: true } },
      render: items => {
        return renderLinkedItemsPlainText(items, events, 'description')
      }
    }
  ]
}
