import React from 'react'
import { Form, Modal } from 'antd'
import FormItem from '../override/FormItem'
import { withTranslation, Trans } from 'react-i18next'
import TextInput from '../common/TextInput'
class AddItemModal extends React.Component {
  render() {
    const {
      form,
      visible,
      handleOk,
      handleCancel,
      item,
      existingItems,
      t
    } = this.props
    const { getFieldDecorator, setFieldsValue } = form

    return (
      <Modal
        visible={visible}
        title={<Trans i18nKey="NEW_ITEM" values={{ item }}></Trans>}
        onCancel={() => {
          form.resetFields()
          handleCancel()
        }}
        onOk={handleOk}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        maskClosable={false}
      >
        <Form layout="vertical" hideRequiredMark>
          <FormItem>
            {getFieldDecorator('itemName', {
              rules: [
                {
                  required: true,
                  message: (
                    <Trans i18nKey="ENTER_NEW_ITEM" values={{ item }}></Trans>
                  )
                },
                {
                  validator: (rule, value, callback) => {
                    if (value && existingItems.includes(value)) {
                      callback(
                        <Trans
                          i18nKey="THE_SAME_ITEM_ALREADY_EXISTS"
                          values={{ item }}
                        ></Trans>
                      )
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(
              <TextInput
                type="text"
                name="itemName"
                setFieldsValue={setFieldsValue}
              />
            )}
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const WrappedAddItemModal = Form.create({ name: 'addItemModal' })(AddItemModal)
export default withTranslation()(WrappedAddItemModal)
