import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { Modal, Button, Input, Alert, Icon, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { onError } from '../../lib/sentry'
import { H3, P, Span } from '../override/Typography'
import WrappedForm from '../common/WrappedForm'
import FormItem from '../override/FormItem'
import AuthContext from '../../contexts/AuthContext'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import { compareWithCurrentUserEmail } from '../../share/formHelpers'
import api from '../../lib/api'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  distributeRecoveryShareKey,
  fetchAssistants
} from '../../features/deputies/assistantsSlice'
import VaultContext from '../../contexts/VaultContext'
import AssistedUsers from './AssistedUsers'
import Assistant from './Assistant'
import { fetchUser } from '../../features/user/userSlice'
import RecoveryRequest from './RecoveryRequest'

let formRef
const ByAssistant = () => {
  const theme = useContext(ThemeContext)
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const [errMsg, setErrMsg] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [resetRequests, setResetRequests] = useState([])
  const [resetRequestsLoading, setResetRequestsLoading] = useState(false)
  const [addAssistantModalVisible, setAddAssistantModalVisible] = useState(false)
  const [userInfo, setUserInfo] = useState('')

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { assistants } = useSelector(state => state.user).user

  useEffect(() => {
    getUserData(
      user,
      async (err, data) => {
        if (err) {
          onError(err)
          return
        }
        const email = getUserAttributeValue(data.UserAttributes, 'email')
        const fullname = getUserAttributeValue(
          data.UserAttributes,
          'custom:full_name'
        )
        setUserInfo({ email, fullname })
      },
      { bypassCache: true }
    )
  }, [user])

  const fetchResetRequests = useCallback(async () => {
    setResetRequestsLoading(true)
    try {
      const res = await api.getRecoveryResetRequests(user.username)
      setResetRequests(res.data)
    } catch (err) {
      console.log('Failed to get reset requests: ', err)
      onError(err)
    }
    setResetRequestsLoading(false)
  }, [user])

  useEffect(() => {
    fetchResetRequests()
  }, [fetchResetRequests])

  const handleAddAssistant = () => {
    setIsSaving(true)
    try {
      formRef.props.form.validateFields(async (err, values) => {
        if (err) return

        const response = await api.requestAssistant(
          user.username,
          JSON.stringify({
            emails: [values.email]
          })
        )

        if (response.data && response.data.success) {
          // distribute share keys to deputies if they are existing bantex users
          const res = await api.getUsersByEmails(values.email)

          const assistantInfo = res?.data?.map(d => {
            return {
              userId: d.id,
              email: d.email,
              publicKey: d.publicKey
            }
          })

          if (assistantInfo?.length) {
            dispatch(
              distributeRecoveryShareKey(
                user.username,
                masterKey,
                assistantInfo
              )
            )
          }

          dispatch(fetchAssistants(user.username))
          dispatch(fetchUser(user.username))
          formRef && formRef.props.form.resetFields()
          setAddAssistantModalVisible(false)
          setIsSaving(false)
          message.success(t('SUCCESSFULLY_SPECIFIED_DEPUTIES'))
        }
      })
    } catch (error) {
      onError(error)
      setIsSaving(false)
      setErrMsg(error.message)
    }
  }

  const handleCancel = () => {
    setAddAssistantModalVisible(false)
    setErrMsg('')
    formRef && formRef.props.form.resetFields()
  }

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <H3>{t('By assistant')}</H3>
        {!assistants?.length && (
          <div>
            <Span
              color={theme.primary}
              style={{ cursor: 'pointer' }}
              onClick={() => setAddAssistantModalVisible(true)}
            >
              <Icon type="user-add" style={{ marginRight: 8 }} />
              {t('Add assistant')}
            </Span>
          </div>
        )}
      </div>

      <Assistant />
      <AssistedUsers resetRequests={resetRequests} userInfo={userInfo} />
      <RecoveryRequest
        resetRequests={resetRequests}
        fetchResetRequests={fetchResetRequests}
        resetRequestsLoading={resetRequestsLoading}
        setResetRequestsLoading={setResetRequestsLoading}
      />

      {/* Add assistant modal */}
      <Modal
        width={540}
        visible={addAssistantModalVisible}
        title={t('Add assistant')}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t('CANCEL')}
          </Button>,
          <Button
            key="save"
            loading={isSaving}
            type="primary"
            onClick={handleAddAssistant}
          >
            {t('SAVE')}
          </Button>
        ]}
      >
        <WrappedForm wrappedComponentRef={fr => (formRef = fr)}>
          {getFieldDecorator => (
            <>
              <P style={{ marginBottom: '1em' }}>
                {t(
                  'Assistant will help you recover your data when you forget your password'
                )}
              </P>
              <FormItem>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      required: true,
                      message: 'Email is required!'
                    },
                    {
                      type: 'email',
                      message: 'Email is not valid!'
                    },
                    {
                      validator: (rule, value, callback) =>
                        compareWithCurrentUserEmail(
                          rule,
                          value,
                          callback,
                          userInfo?.email
                        )
                    }
                  ]
                })(<Input placeholder="Email address" />)}
              </FormItem>
            </>
          )}
        </WrappedForm>
        {errMsg && (
          <Alert
            type="error"
            message={errMsg}
            showIcon
            afterClose={() => setErrMsg('')}
            closable
            style={{ marginTop: '1em' }}
          />
        )}
      </Modal>
    </Fragment>
  )
}

export default ByAssistant
